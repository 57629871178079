import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments/FeatureFlag";
import { useAppSelector } from "store/hooks";

export const useIsFlightsEnabled = () => {
  const isFlightsEnabled = useIsFeatureFlagOn("hilo_shop_and_checkout");
  const user = useAppSelector((store) => store.Auth.user);
  const hideFlights = user?.business.hideFlights;

  return isFlightsEnabled && !hideFlights;
};
