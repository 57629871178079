import { Form } from "formik";
import styled, { createGlobalStyle } from "styled-components";

import Button from "@hotel-engine/common/Button";
import { Icon } from "@hotel-engine/common/Icon";
import { Modal } from "@hotel-engine/common/Modal";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import TextAreaField from "@hotel-engine/common/FormikFields/TextAreaField";
import { buttonReset, colors, zIndex } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

// required to display bottom sheet on top of the Complete Booking button
export const GlobalBottomSheetStyles = createGlobalStyle`
  [data-rsbs-overlay] {
    ${zIndex.modal}
  }
`;

export const BottomSheetContentContainer = styled.div`
  padding: 16px;
  margin-bottom: 16px;
`;

export const FeedbackModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.lg};

    .ant-modal-body {
      ${flexbox({
        justifyContent: "center",
        alignItems: "flex-start",
        direction: "column",
      })};
      padding: 16px;
    }
  }
`;

export const CloseButtonContainer = styled.div`
  width: 100%;
  ${flexbox({ justifyContent: "flex-end" })};
`;

export const CloseButton = styled.button`
  background: transparent;
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.x100};
  border: none;
  height: 32px;
  width: 32px;
  ${flexbox({ alignItems: "center", justifyContent: "center" })};
  transition: 0.2s ease background;
  z-index: ${zIndex.backdrop + zIndex.above};

  svg {
    font-size: 20px;
  }

  &:hover {
    cursor: pointer;
    background: ${colors.grey[50]};
  }
`;

export const ModalTitle = styled.h2`
  font-weight: ${({ theme }) => theme.legacy.fontWeight.bold};
  font-size: ${typographyMixin("large")};
  margin-bottom: 24px;
  color: ${colors.black};
`;

export const ModalForm = styled(Form)`
  width: 100%;
`;

export const RadioContainer = styled.div`
  ${flexbox({
    justifyContent: "center",
    alignItems: "flex-start",
    direction: "column",
  })};
  color: ${colors.black};
`;

export const MoodContainer = styled.div`
  ${flexbox({
    justifyContent: "space-between",
    alignItems: "start",
  })};
  width: 100%;
`;

export const MoodWrapper = styled.button`
  ${buttonReset};
  ${flexbox({
    justifyContent: "center",
    alignItems: "center",
    direction: "column",
  })};
  position: relative;
  width: 20%;
`;

export const MoodLabel = styled.p<{ selected: boolean }>`
  text-align: center;
  font-size: ${typographyMixin("body-medium")};
  color: ${(props) => (props.selected ? colors.black : "transparent")};
`;

export const MoodIcon = styled(Icon)<{
  selected: boolean;
  $activeColor: string;
}>`
  margin: 8px 0;
  font-size: 36px;
  color: ${(props) => (props.selected ? props.$activeColor : colors.slate[400])};
`;

export const CommentContainer = styled.div`
  margin-top: 16px;

  .ant-form-item-no-colon {
    color: ${colors.black};
  }
`;

export const CommentText = styled(TextAreaField)`
  border-color: ${colors.grey[300]};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.lg};
  padding: 12px;
  margin-top: 4px;
  color: ${colors.black};
`;

export const SubmitButton = styled(Button)`
  &.ant-btn-primary:disabled {
    background-color: ${colors.grey[200]};
    color: ${colors.kiwiGrey};
    border-color: transparent;
  }
  ${flexbox({
    justifyContent: "center",
    alignItems: "center",
  })};
  margin: 32px 0 0;
  height: 40px;
  padding: 0 16px;
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.lg};
  font-size: ${typographyMixin("body-large")};

  ${mediaQuery("md", "max")(`float: none; width: 100%;`)};
`;

export const CancelButton = styled(Button)`
  ${flexbox({
    justifyContent: "center",
    alignItems: "center",
  })};
  padding: 20px 42px;
  margin: 10px 0;
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.lg};
  font-size: ${typographyMixin("body-large")};

  ${mediaQuery("md", "max")(`width: 100%`)}
`;

export const ButtonsContainer = styled.div`
  ${flexbox({
    justifyContent: "center",
    alignItems: "flex-end",
    direction: "column",
  })};
`;
