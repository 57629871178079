import styled from "styled-components";

import { Menu } from "@hotel-engine/common/Menu";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";

import { Tag } from "../Tag/styles";

export const MobileMenuItem = styled(Menu.Item)`
  ${flexbox({
    alignItems: "center",
  })}
  ${Tag} {
    margin-left: 8px;
  }
`;
