import styled from "styled-components";

import Drawer from "@hotel-engine/common/Drawer";

export const NotificationsDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    box-shadow: none !important;
    min-height: calc(100% - 48px);
  }

  .ant-drawer-wrapper-body {
    display: flex;
  }

  .ant-drawer-mask {
    background: transparent;
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
    width: 100%;
  }
`;
