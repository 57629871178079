import { useQueryClient } from "react-query";

import { endpoints } from "../constants";
import { Unsafe } from "@hotel-engine/data";

export const useRefetchFavoriteProperties = () => {
  /**
   * Invalidate the react-query cache, which will trigger the refetch
   */
  const queryClient = useQueryClient();

  const refetchFavoriteProperties = () => {
    queryClient
      .invalidateQueries(endpoints.favoritePropertiesBusinessSummary)
      .then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    queryClient
      .invalidateQueries(endpoints.favoriteProperties)
      .then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
  };

  return refetchFavoriteProperties;
};
