import styled from "styled-components";

// TODO: Update this antD, as soon as we have a replacement in Atlas. For now we will continue using the AntD
import Image from "@hotel-engine/common/Image";
import { Link } from "@hotel-engine/lib/react-router-dom";

interface IPropertyInfoProps {
  disabled: boolean;
}

const PropertyContainer = styled.div<{ $isPreview: boolean }>`
  display: grid;
  grid-template-columns: 96px 1fr;
  gap: ${({ theme }) => theme.spacing[16]};
  padding: ${({ $isPreview }) => `${$isPreview ? 12 : 24}px`} 0 0;
`;

const PropertyImage = styled(Image)`
  width: 96px;
  height: 96px;
  border-radius: ${({ theme }) => theme.borderRadius.lg} !important;
`;

const PropertyDetails = styled.div`
  display: grid;
  row-gap: ${({ theme }) => theme.spacing[8]};
  grid-template-rows: auto 1fr;

  > a {
    color: ${({ theme }) => theme.colors.neutralN900};
  }
`;

const PropertyName = styled(Link)`
  width: 100%;
`;

const PropertyInfo = styled.span<IPropertyInfoProps>`
  height: fit-content;
  &:hover {
    cursor: ${({ disabled }) => (disabled ? "initial" : "pointer")};
    text-decoration: ${({ disabled }) => (disabled ? "initial" : "underline")} !important;
  }
`;

const PropertyPhoneNumber = styled.div`
  > a {
    color: ${({ theme }) => theme.colors.neutralN900};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export {
  PropertyContainer,
  PropertyDetails,
  PropertyName,
  PropertyInfo,
  PropertyImage,
  PropertyPhoneNumber,
};
