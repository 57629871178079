import { useExperiment } from "@hotel-engine/react-query/amplitude/useExperimentQuery";
import type { IVariantWithPayload } from "@hotel-engine/types/amplitude";

export interface ISystemOutagePayload {
  title: string;
  body: string;
}

type SystemOutageResponse = IVariantWithPayload<
  "something-isnt-working" | "system-maintenance-banner" | "our-site-is-down" | "control",
  ISystemOutagePayload
>;

export const useSystemOutages = (payload: ISystemOutagePayload) => {
  return useExperiment<SystemOutageResponse>("system_outages", {
    value: "control",
    payload,
  }).data;
};
