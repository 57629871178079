import { useEffect, useState } from "react";

import type { IReservationRoom } from "@hotel-engine/types/booking";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";

import { useCancelFormValues } from "../../hooks/useCancelFormValues";

export const useMultiCancelRoomsTracker = () => {
  const [cancelAllRooms, setCancelAllRooms] = useState(true);
  /** Used to retain value after refetch to pass to success modal */
  const [numberOfRoomsCancelled, setNumberOfRoomsCancelled] = useState(0);
  const [showValidationMessage, setShowValidationMessage] = useState(false);

  const { reservation } = useModificationsContext();

  const { formValues, genValueSetter } = useCancelFormValues({
    reservation,
  });

  const nonCancellableRooms: IReservationRoom[] = reservation.rooms.filter(
    (room) => !room.modificationActions.cancellable
  );
  const showCancelAllRooms = !nonCancellableRooms.length;
  const checkCertainRooms = !cancelAllRooms || !showCancelAllRooms;
  const checkAllRooms = cancelAllRooms && showCancelAllRooms;

  const numberOfRoomsToCancel = Object.values(formValues.cancelRooms).filter(
    (room) => room.enabled
  ).length;

  useEffect(() => {
    if (showValidationMessage && checkCertainRooms && numberOfRoomsToCancel > 0) {
      setShowValidationMessage(false);
    }
  }, [showValidationMessage, checkCertainRooms, numberOfRoomsToCancel]);

  const switchToChangeAllRooms = () => {
    setRoomsToCancel(null, true);
    setCancelAllRooms(true);
  };

  const switchToChangeCertainRooms = () => {
    setRoomsToCancel(null, false);
    setCancelAllRooms(false);
  };

  /** Pass null for confirmation number to set all rooms */
  const setRoomsToCancel = (contractNumber: string | null, enabled: boolean) => {
    if (!contractNumber) {
      // Set date for all rooms
      const allCancelRooms = reservation.rooms.reduce((accum, room) => {
        accum[room.contractNumber] = { enabled };
        return accum;
      }, {});
      genValueSetter("cancelRooms")(allCancelRooms);
    } else {
      genValueSetter("cancelRooms")({
        ...formValues.cancelRooms,
        [contractNumber]: { enabled },
      });
    }
  };

  return {
    formValues,
    numberOfRoomsCancelled,
    numberOfRoomsToCancel,
    radioStatuses: {
      checkAllRooms,
      checkCertainRooms,
    },
    setNumberOfRoomsCancelled,
    setRoomsToCancel,
    showCancelAllRooms,
    showValidationMessage,
    setShowValidationMessage,
    switchToChangeAllRooms,
    switchToChangeCertainRooms,
  };
};
