import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import { FormItem as AntFormItem } from "@hotel-engine/common/Form";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

export const FormItem = styled(AntFormItem)`
  width: 100%;
  .ant-select {
    width: 100%;
    border: 1px solid ${colors.grey[300]};
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.lg};
  }
  .ant-select-arrow {
    color: ${colors.grey[800]};
  }
  .ant-select-selection-selected-value {
    color: ${colors.grey[800]};
  }
  .ant-select-selection__placeholder {
    color: ${colors.kiwiGrey};
  }
`;

export const OtherOptionBox = styled.div`
  width: 100%;
  ${flexbox({ alignItems: "center" })}
  margin: 32px 0px;
`;

export const OtherOptionText = styled.span`
  ${typographyMixin("body-medium")}
  width: 56px;
  height: 20px;
  text-align: center;
  color: ${colors.grey[500]};
`;

export const Doted = styled.div`
  flex: 1;
  height: 0px;
  border: 1px dashed ${colors.grey[200]};
`;

export const SSOButton = styled(Button)`
  ${typographyMixin("body-large")}
  margin-top: 8px;
  min-width: 100%;
  background-color: ${colors.white};
  color: ${({ disabled }) => (disabled ? colors.grey[300] : colors.black)};
  ${mediaQuery("md", "max")(`margin-bottom: 16px;`)}
`;

export const LoadingContainer = styled.div`
  margin: 32px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
