import { positionElement } from "@hotel-engine/styles/helpers/mixins";

import styled from "styled-components";
import { Button } from "@hotelengine/atlas-web";

export const MapStyleButtonsCentered = styled.div<{
  updateSearchControlPosition?: "top" | "bottom";
}>`
  ${({ theme }) =>
    positionElement({
      bottom: "unset",
      top: "16px",
      left: "50%",
      x: "-50%",
      z: Number(theme.zIndex.backdropAbove) + Number(theme.zIndex.backdropAbove),
    })};
`;

export const SearchButton = styled(Button)`
  border-radius: ${({ theme }) => theme.borderRadius.full};
  color: ${({ theme }) => theme.colors.uiAccent};
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  box-shadow: ${({ theme }) => theme.elevation.medium};

  &:hover:enabled {
    background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  }
`;
