import Button from "@hotel-engine/common/Button";
import { Checkbox } from "@hotel-engine/common/Checkbox";
import { Dropdown } from "@hotel-engine/common/Dropdown";
import { Icon } from "@hotel-engine/common/Icon";
import { Menu, MenuItem } from "@hotel-engine/common/Menu";

import { capitalize } from "../../../../helpers";
import { Tag } from "../Tag";
import * as Styled from "./styles";

export const MobileTripsDropdown = ({
  showOnlyMyTripsDropdownCheckbox,
  counts,
  handleOnlyMyTrips,
  handleStatusClick,
  onlyMyTrips,
  status,
}) => {
  const filterStatuses = ["upcoming", "active", "cancelled", "past", "all"];

  const mobileTripsMenu = (
    <Menu>
      {filterStatuses.map((filterStatus) => {
        return (
          <Styled.MobileMenuItem
            key={`${filterStatus}-mobile`}
            onClick={() => handleStatusClick(filterStatus)}
          >
            <div>{capitalize(filterStatus)}</div>
            <Tag active={status === filterStatus}>{counts[filterStatus]}</Tag>
          </Styled.MobileMenuItem>
        );
      })}
      {!!showOnlyMyTripsDropdownCheckbox && <Menu.Divider />}
      {!!showOnlyMyTripsDropdownCheckbox && (
        <MenuItem>
          <Checkbox onClick={handleOnlyMyTrips} checked={Boolean(onlyMyTrips)}>
            Only my trips
          </Checkbox>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={mobileTripsMenu} trigger={["click"]}>
      <Button type="ghost">
        {`${capitalize(status)} Trips`}{" "}
        <Icon icon={["far", "chevron-down"]} className="icon" style={{ marginLeft: 8 }} />
      </Button>
    </Dropdown>
  );
};
