import { useMutation } from "react-query";

import { endpoints } from "@hotel-engine/react-query/constants";
import { useApi } from "@hotel-engine/react-query/useApi";
import { useRefetchFavoriteProperties } from "@hotel-engine/react-query/favoriteProperties/useRefetchFavoriteProperties";
import { useRefetchHiddenProperties } from "@hotel-engine/react-query/hiddenProperties/useRefetchHiddenProperties";
import { useRefetchPropertyReviews } from "@hotel-engine/react-query/propertyReviews/useRefetchPropertyReviews";
import type { IErrorResponse } from "@hotel-engine/types/errors";

export const useDeleteHiddenProperty = () => {
  const del = useApi("delete");
  const refetchPropertyReviews = useRefetchPropertyReviews();
  const refetchFavoriteProperties = useRefetchFavoriteProperties();
  const refetchHiddenProperties = useRefetchHiddenProperties();

  return useMutation<null, IErrorResponse, number>(
    (id) => del(`${endpoints.hiddenProperties}/${id}`),
    {
      onSuccess: () => {
        refetchHiddenProperties();
        refetchPropertyReviews();
        refetchFavoriteProperties();
      },
    }
  );
};
