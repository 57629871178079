import { useNavigate } from "@hotel-engine/lib/react-router-dom";

import { routes } from "@hotel-engine/constants";

import { useExpressBook } from "pages/Checkout/LegacyLodging/ExpressBookModal/hooks/useExpressBook";
import { TripsListItemLegacy } from "pages/Trips/components";
import * as Styled from "./styles";

const OverlappingContractModal = ({ contracts, onChangeGuest, onChangeDates }) => {
  const navigate = useNavigate();

  const { closeExpressBookModal } = useExpressBook();

  const tripsList = contracts[1] ? [contracts[0], contracts[1]] : [contracts[0]];

  const tripsListUI = tripsList.map((contract) => {
    const item = {
      ...contract,
      pendingModification: false,
    };

    return (
      <TripsListItemLegacy
        key={item.contractNumber}
        loading={false}
        item={item}
        view="map"
        selected={false}
        displayChevron={true}
        onSelection={() => {
          closeExpressBookModal("redirect");
          navigate({
            pathname: routes.itinerary,
            search: `?contract_number=${item.contractNumber}`,
          });
        }}
      />
    );
  });

  return (
    <Styled.OverlappingContractModal title={null} closable={false} footer={null} visible={true}>
      <Styled.Header>Whoops! This reservation overlaps with your existing booking:</Styled.Header>
      {tripsListUI}
      <Styled.TextWrapper>
        <Styled.Banner>
          <Styled.BannerIcon icon={["far", "info-circle"]} />
          <Styled.BannerText>
            If you would like to continue with booking, please contact your administrator so they
            can book it on your behalf.
          </Styled.BannerText>
        </Styled.Banner>
        <Styled.OrContainer>
          <Styled.OrLine />
          <Styled.OrText>OR</Styled.OrText>
          <Styled.OrLine />
        </Styled.OrContainer>
        <Styled.OptionsText>
          You can also change the dates or book for someone else.
        </Styled.OptionsText>
      </Styled.TextWrapper>
      <Styled.ButtonContainer>
        <Styled.ButtonOptions type="default" onClick={onChangeGuest}>
          Change Guest
        </Styled.ButtonOptions>
        <Styled.ButtonOptions type="primary" onClick={onChangeDates}>
          Change Dates
        </Styled.ButtonOptions>
      </Styled.ButtonContainer>
    </Styled.OverlappingContractModal>
  );
};

export default OverlappingContractModal;
