import type { ReactNode } from "react";

import {
  BottomSheet,
  BottomSheetClose,
  BottomSheetContent,
  BottomSheetHeader,
  type BottomSheetProps,
  BottomSheetTitle,
  BottomSheetTrigger,
  Dialog,
  DialogClose,
  DialogContent,
  type DialogProps,
  DialogTitle,
  DialogTrigger,
} from "@hotelengine/atlas-web";
import type { breakpoints } from "@hotel-engine/styles/variables/sizes";
import { useBreakpoint } from "@hotel-engine/hooks";

type SharedDialogBottomSheetProps =
  | Pick<BottomSheetProps, "isModal" | "isOpen" | "isOpenByDefault">
  | Pick<DialogProps, "isModal" | "isOpen" | "isOpenByDefault">;

interface IResponsiveAtlasDialogProps {
  /** An optional override for the breakpoint used to switch between Dialog and BottomSheet (default is "md", "max") */
  breakpointOverride?: [keyof typeof breakpoints, "max" | "min"];
  /** The content to be displayed within the body of the Dialog and BottomSheet */
  content: ReactNode;
  /** Whether to show the close icon using the DialogClose or BottomSheetClose Atlas component */
  showClose?: boolean;
  /** An optional title for the Dialog/BottomSheet */
  title?: string;
  /** The trigger component to be clicked to show the Dialog/BottomSheet */
  triggerComponent: ReactNode;
}

export type ResponseAtlasDialogProps = IResponsiveAtlasDialogProps & SharedDialogBottomSheetProps;

/** A common component that incorporates a modal/bottomsheet pattern using Atlas components. At desktop widths it will use the Dialog,
 * and at mobile web the BottomSheet. The default breakpoint is ("md", "max") but can be set manually using the breakpointOverride prop.
 */
const ResponsiveAtlasDialog = ({
  breakpointOverride = ["md", "max"],
  content,
  showClose = true,
  title,
  triggerComponent,
  ...props
}: ResponseAtlasDialogProps) => {
  const isMobile = useBreakpoint(breakpointOverride[0], breakpointOverride[1]);

  if (isMobile) {
    return (
      <BottomSheet {...props}>
        <BottomSheetTrigger asChild>{triggerComponent}</BottomSheetTrigger>
        <BottomSheetContent>
          <BottomSheetHeader>
            {!!title && <BottomSheetTitle>{title}</BottomSheetTitle>}
            {!!showClose && <BottomSheetClose />}
          </BottomSheetHeader>
          {content}
        </BottomSheetContent>
      </BottomSheet>
    );
  } else {
    return (
      <Dialog {...props}>
        <DialogTrigger>{triggerComponent}</DialogTrigger>
        <DialogContent style={{ overflowY: "scroll" }}>
          {!!title && <DialogTitle>{title}</DialogTitle>}
          {!!showClose && <DialogClose />}
          {content}
        </DialogContent>
      </Dialog>
    );
  }
};

export default ResponsiveAtlasDialog;
