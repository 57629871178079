import { Box } from "@hotelengine/atlas-web";
import ModificationStatusModal from "@hotel-engine/app/ItineraryContent/shared/ModficationViews/components/ModificationStatusModal";
import ModificationViews from "@hotel-engine/app/ItineraryContent/shared/ModficationViews";
import { useCarModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useBreakpoint } from "@hotel-engine/hooks";
import { PaddedItineraryContainer } from "@hotel-engine/app/ItineraryContent/styles";

import { useNavigationBlocker } from "../../../shared/ModficationViews/components/NavigationModal/useNavigationBlocker";
import CarDetails from "../CarDetails";
import Travelers from "../Travelers";
import BookingDetails from "../BookingDetails";
import Location from "../Location";
import HelpAndPolicies from "../HelpAndPolicies";
import PaymentInfo from "../PaymentInfo";
import ManageTrip from "../ManageTrip";

const CarItineraryContentBody = () => {
  const isMobile = useBreakpoint("md", "max");

  const {
    state: { modificationView, showStatusModal },
    reservation,
    isPreview,
  } = useCarModificationsContext();

  useNavigationBlocker();

  /** This component makes up the body of the ItineraryContent that we will replace with "internal" modification flows.
   * When a dispatch is made from the ModificationsContext to update ModificationViews (defaults to undefined) we will
   * render the appropriate modification flow instead of the body content.
   */

  if (!reservation) return null;

  return (
    <>
      {!!showStatusModal && <ModificationStatusModal />}

      {!!modificationView ? (
        <Box backgroundColor="backgroundSecondary">
          <PaddedItineraryContainer $isPreview={isPreview || isMobile}>
            <Box style={{ minHeight: "100vh" }}>
              <ModificationViews />
            </Box>
          </PaddedItineraryContainer>
        </Box>
      ) : (
        <>
          <Box backgroundColor="backgroundSecondary">
            <PaddedItineraryContainer $isPreview={isPreview || isMobile}>
              <CarDetails />
              <Travelers
                travelers={reservation.travelers || []}
                logoSrc={reservation.rentalCompany?.logo}
                padTop={false}
              />
              <BookingDetails logoSrc={reservation.rentalCompany?.logo} details={reservation} />
              <Location
                location={reservation.location}
                rentalCompany={reservation.rentalCompany}
                startTime={reservation.startTime}
                endTime={reservation.endTime}
              />
              <HelpAndPolicies />
              <PaymentInfo />
            </PaddedItineraryContainer>
          </Box>
          <ManageTrip />
        </>
      )}
    </>
  );
};

export default CarItineraryContentBody;
