import { Typography } from "@hotelengine/atlas-web";
import * as Styled from "./styles";
import { useAirportInfo } from "pages/Flights/hooks/useAirportInfo";
import { useCityInfo } from "pages/Flights/hooks/useCityInfo";

interface ILayoverBlockProps {
  layoverDuration: string;
  layoverAirportIata: string;
}

export const LayoverBlock = ({ layoverDuration, layoverAirportIata }: ILayoverBlockProps) => {
  const { getIataCityCodeByIataAirportCode } = useAirportInfo();
  const { getCityNameByIataCityCode } = useCityInfo();
  const cityCode = getIataCityCodeByIataAirportCode(layoverAirportIata);
  const cityName = getCityNameByIataCityCode(cityCode);

  const copy =
    cityName === cityCode ? `at ${layoverAirportIata}` : `in ${cityName} (${layoverAirportIata})`;

  return (
    <Styled.LayoverContainer
      display="flex"
      flexDirection="row"
      gap={8}
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="body/sm-strong">{layoverDuration}</Typography>
      <Typography variant="body/sm-strong">&bull;</Typography>
      {/* Ternary used to prevent weird state it our i18n can't decode the iata  */}
      <Typography variant="body/sm" style={{ textAlign: "right" }}>
        Layover {copy}
      </Typography>
    </Styled.LayoverContainer>
  );
};
