import { useCallback, useEffect, useState } from "react";

import { useGoogleGeocoder, usePrevious } from "@hotel-engine/hooks";
import GooglePlacesService, { type Response } from "@hotel-engine/services/GooglePlacesService";

import { useAirportInfo } from "pages/Flights/hooks/useAirportInfo";

interface IMapPositionWithAddress {
  lat: number;
  lng: number;
  address: string | undefined;
}

type Locations = {
  origin: void | IMapPositionWithAddress | undefined;
  destination: void | IMapPositionWithAddress | undefined;
};

const placesService = GooglePlacesService();

const useGoogleFlightLocations = (originAirportCode: string, destinationAirportCode: string) => {
  const { getLocation } = useGoogleGeocoder();
  const { getAirportNameFromIataAirportCode } = useAirportInfo();

  const originAirport = getAirportNameFromIataAirportCode(originAirportCode);
  const destinationAirport = getAirportNameFromIataAirportCode(destinationAirportCode);

  const previousOrigin = usePrevious(originAirportCode);
  const previousDestination = usePrevious(destinationAirportCode);

  const isNewListItem =
    previousOrigin !== originAirportCode || previousDestination !== destinationAirportCode;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [originAirportResult, setOriginAirport] = useState<Response>({
    predictions: [],
  });
  const [destinationAirportResult, setDestinationAirport] = useState<Response>({
    predictions: [],
  });
  const [locations, setLocations] = useState<Locations>({
    origin: undefined,
    destination: undefined,
  });

  /** If the origin or destination has no results and there is no error from Google, hit the Places
   * service to get results for the origin and destination airports
   */
  useEffect(() => {
    if (
      !originAirportResult.predictions.length ||
      !destinationAirportResult.predictions.length ||
      (isNewListItem && !isError)
    ) {
      placesService.request(originAirport, setIsLoading, setOriginAirport);
      placesService.request(destinationAirport, setIsLoading, setDestinationAirport);
    }
  }, [
    destinationAirport,
    destinationAirportResult.predictions.length,
    isError,
    isNewListItem,
    originAirport,
    originAirportResult.predictions.length,
  ]);

  const getLocations = useCallback(async () => {
    const originLocation = await getLocation(originAirportResult.predictions[0].place_id);
    const destinationLocation = await getLocation(destinationAirportResult.predictions[0].place_id);

    return { origin: originLocation, destination: destinationLocation };
  }, [destinationAirportResult.predictions, getLocation, originAirportResult.predictions]);

  /** Once we have predictions for origin and destination, we will grab the first one, which if we search by
   * the exact name of the airport from the bundles should be the correct airport nearly 100% of the time,
   * and hit the Geocoder API using the above helper to get the lat/lng of each airport which gets returned
   * from the hook
   */
  useEffect(() => {
    if (originAirportResult.predictions.length && destinationAirportResult.predictions.length) {
      getLocations()
        .then((res) => {
          setLocations({
            origin: {
              lat: res.origin?.latitude || 0,
              lng: res.origin?.longitude || 0,
              address: res.origin?.address,
            },
            destination: {
              lat: res.destination?.latitude || 0,
              lng: res.destination?.longitude || 0,
              address: res.destination?.address,
            },
          });
        })
        .catch(() => setIsError(true));
    }
  }, [originAirportResult.predictions, destinationAirportResult.predictions, getLocations]);

  return {
    locations,
    originLocation: locations.origin?.address,
    destinationLocation: locations.destination?.address,
    isLoading,
    isError,
  };
};

export default useGoogleFlightLocations;
