const generateGuestList = (allOccupants) =>
  allOccupants.map((guest, i) => {
    return (
      <div key={`${guest.name}-${i}`}>
        {guest.name} {i === 0 ? "(Primary)" : ""}
      </div>
    );
  });

const generateGuestNames = (allOccupants, maxNames) => {
  const occupants = maxNames ? [...allOccupants].slice(0, maxNames) : allOccupants;

  return occupants.map((guest) => `${guest.name}`).join(", ");
};

export { generateGuestList, generateGuestNames };
