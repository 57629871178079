import { useRef, useState, useEffect } from "react";

import { dateTimeRangePickerShortFormats } from "@hotel-engine/constants/dateRangePickerSelections";
import { useFormatDate } from "@hotel-engine/hooks/useFormatDate";
import { Icon, SelectItem } from "@hotelengine/atlas-web";
import { useFormikContext } from "formik";
import type { Moment } from "moment";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import type { END_DATE, START_DATE } from "react-dates/constants";
import { HORIZONTAL_ORIENTATION } from "react-dates/constants";
import type { ISearchModel } from "../../../../types";
import * as Styled from "./styles";

type FocusedInput = END_DATE | START_DATE | null;

export interface IDateSelectorInputProps {
  className?: string;
  inputWidth?: string;
  isDashboardSearchForm?: boolean;
  margin?: string;
  handleAmplitude?: (newCheckIn: string | undefined, newCheckOut: string | undefined) => void;
}

const allPossibleTimes = Array.from({ length: 48 }).map((_, i) => {
  const hours =
    Math.floor(i / 2 - (i > 23 ? 12 : 0)) === 0 ? 12 : Math.floor(i / 2 - (i > 23 ? 12 : 0));
  const minutes = i % 2 === 0 ? "00" : "30";
  const amPm = i < 24 ? "AM" : "PM";

  return `${hours < 10 ? "0" : ""}${hours}:${minutes} ${amPm}`;
});
const DEFAULT_TIME = moment().set({ hour: 12, minute: 0 }).format("HH:mm A");

export const DateTimeRangePicker = ({
  className,
  inputWidth,
  isDashboardSearchForm,
  margin,
  handleAmplitude,
}: IDateSelectorInputProps) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInput>(null);

  const [lastPickupTime, setLastPickupTime] = useState<string>(DEFAULT_TIME);
  const [lastDropoffTime, setLastDropoffTime] = useState<string>(DEFAULT_TIME);
  const [preventClosing, setPreventClosing] = useState<boolean>(false);

  const today = moment();
  const oneHourInTheFuture = today.clone().add(1, "hour");

  useEffect(() => {
    const originalWidth = document.body.style.width;
    globalThis.document.body.style.width = "calc(100% - var(--removed-body-scroll-bar-size, 0px))";
    return () => {
      globalThis.document.body.style.width = originalWidth;
    };
  }, []);

  const picker = useRef<HTMLDivElement & { onOutsideClick: () => void }>(null);

  const { values, setFieldValue, errors, submitCount } = useFormikContext<ISearchModel>();

  const pickupError = !!errors.pickupDate && submitCount > 0;
  const dropoffError = !!errors.dropoffDate && submitCount > 0;

  const pickupDate = useFormatDate(values.pickupDate, "MM/DD/YY");
  const dropoffDate = useFormatDate(values.dropoffDate, "MM/DD/YY");

  const pickupFriendlyDate = useFormatDate(values.pickupDate, "ddd, MMM D");
  const dropoffFriendlyDate = useFormatDate(values.dropoffDate, "ddd, MMM D");

  const pickupTime = useFormatDate(values.pickupTime, "hh:mm A");
  const dropoffTime = useFormatDate(values.dropoffTime, "hh:mm A");

  const pickupDateTime = pickupDate
    ? moment(pickupDate + " " + pickupTime, "MM/DD/YYYY hh:mm A")
    : null;

  const dropoffDateTime = dropoffDate
    ? moment(dropoffDate + " " + dropoffTime, "MM/DD/YYYY hh:mm A")
    : null;

  /* Disable any dates that are:
    - Before today's date
    - Outside the max search range
    - Any checkout date past the max number of nights
    */
  const isDateOutOfRange = (day: Moment) => {
    return moment(day.format("MM-DD-YYYY")).isBefore(moment(today).format("MM-DD-YYYY"), "day");
  };

  const calendarChangeHandler = (startDate: Moment | null, endDate: Moment | null) => {
    const hypotethicalPickupDateTime = moment(
      values.pickupTime?.format("MM/DD/YYYY") + " " + values?.pickupTime?.format("hh:mm A") ||
        lastPickupTime,
      "MM/DD/YYYY hh:mm A"
    );
    const hypotethicalDropoffDateTime = moment(
      values.dropoffDate?.format("MM/DD/YYYY") + " " + values?.dropoffTime?.format("hh:mm A") ||
        lastDropoffTime,
      "MM/DD/YYYY hh:mm A"
    );

    if (!endDate?.isSame(values.dropoffDate, "day")) {
      if (hypotethicalPickupDateTime.isBefore(oneHourInTheFuture)) {
        setLastPickupTime(
          moment()
            .add((moment().minutes() < 30 ? 30 : 60) - moment().minutes(), "minutes")
            .add(1, "hour")
            .format("hh:mm A")
        );
      } else {
        setLastPickupTime(values?.pickupTime?.format("hh:mm A") || lastPickupTime);
      }

      if (hypotethicalDropoffDateTime.isBefore(hypotethicalPickupDateTime)) {
        setLastDropoffTime(hypotethicalPickupDateTime.clone().add(1, "hour").format("hh:mm A"));
      } else {
        setLastDropoffTime(values?.dropoffTime?.format("hh:mm A") || lastDropoffTime);
      }
    }

    if (
      moment(
        endDate?.format("MM/DD/YYYY") +
          " " +
          (values?.dropoffTime?.format("hh:mm A") || DEFAULT_TIME),
        "MM/DD/YYYY hh:mm A"
      )
        .clone()
        .isBefore(
          moment(
            startDate?.format("MM/DD/YYYY") +
              " " +
              (values?.pickupTime?.format("hh:mm A") || DEFAULT_TIME),
            "MM/DD/YYYY hh:mm A"
          ).add(1, "minute"),
          "seconds"
        )
    ) {
      setFieldValue("dropoffTime", moment(values.pickupTime, "hh:mm A").clone().add(1, "hour"));
    }

    if (
      focusedInput === "startDate" ||
      !values.pickupDate ||
      (startDate && values.pickupDate && startDate.isBefore(values.pickupDate, "days")) ||
      (values.pickupDate && values.dropoffDate)
    ) {
      setFieldValue("pickupDate", startDate);
      setFieldValue("dropoffDate", null);
      setFocusedInput("endDate");
    }

    if (endDate) {
      setFieldValue("dropoffDate", endDate);
      setFocusedInput("startDate");
    }
    setPreventClosing(true);
  };

  return (
    <Styled.DateSelectorInput className={className} inputWidth={inputWidth} margin={margin}>
      <Styled.DateRangePicker checkInError={pickupError} checkOutError={dropoffError}>
        <DateRangePicker
          startDate={pickupDateTime}
          ref={picker}
          startDateId="date-range-picker-start"
          startDateAriaLabel="date-range-picker-start"
          startDatePlaceholderText={
            values.pickupDate || values.dropoffDate ? "Pick-up date" : "When"
          }
          endDatePlaceholderText={values.pickupDate || values.dropoffDate ? "Drop-off date" : null}
          endDate={dropoffDateTime}
          endDateId="date-range-picker-end"
          endDateAriaLabel="date-range-picker-end"
          hideKeyboardShortcutsPanel
          isOutsideRange={isDateOutOfRange}
          displayFormat={dateTimeRangePickerShortFormats["mdy"]}
          noBorder
          minimumNights={0}
          onClose={() => {
            if (preventClosing) {
              setFieldValue("pickupTime", moment(lastPickupTime, "hh:mm A"));
              setFieldValue("dropoffTime", moment(lastDropoffTime, "hh:mm A"));
            }

            if (!preventClosing && values.pickupTime && values.dropoffTime && values.dropoffDate) {
              setFocusedInput(null);
            }
            setPreventClosing(false);
          }}
          onDatesChange={({ startDate, endDate }) => {
            handleAmplitude?.(startDate?.toJSON(), endDate?.toJSON());
            calendarChangeHandler(startDate, endDate);
          }}
          focusedInput={focusedInput}
          onFocusChange={(focus: FocusedInput) => {
            setFocusedInput((prevFocus: FocusedInput) => {
              return focus || prevFocus;
            });
            setPreventClosing(false);
          }}
          verticalSpacing={84}
          orientation={HORIZONTAL_ORIENTATION}
          keepOpenOnDateSelect={
            !(
              values.pickupDate &&
              values.dropoffDate &&
              values.pickupTime &&
              values.dropoffTime &&
              preventClosing
            )
          }
          customArrowIcon={
            values.pickupDate || values.dropoffDate ? <Styled.SeparatorArrow /> : <></>
          }
          customInputIcon={
            isDashboardSearchForm ? (
              <Icon name="calendar-day" />
            ) : (
              <Styled.DateSelectorIcon name="calendar" />
            )
          }
          renderCalendarInfo={() => {
            return (
              <Styled.BottomPanel>
                <Styled.PanelColumn>
                  <div>Pick-up</div>
                  <Styled.Title>
                    {values.pickupDate ? pickupFriendlyDate : "Select a date"}
                  </Styled.Title>
                  <Styled.SelectMenu
                    data-testid="pickup-time"
                    role="menu"
                    isDisabled={!values.pickupDate || !values.dropoffDate}
                    defaultValue={pickupTime || DEFAULT_TIME}
                    size="md"
                    onFocus={(e) => {
                      if (!values.pickupTime) {
                        setFieldValue("pickupTime", moment(lastPickupTime, "hh:mm A"));
                      }
                      e.target.blur();
                    }}
                    onValueChange={(value) => {
                      setLastPickupTime(value);
                      setLastDropoffTime(values.dropoffTime?.format("hh:mm A") || DEFAULT_TIME);

                      if (
                        values.pickupDate?.isSame(values.dropoffDate, "day") &&
                        values.dropoffTime?.isBefore(
                          moment(value, "hh:mm A").add(1, "minute"),
                          "minutes"
                        )
                      ) {
                        setFieldValue(
                          "dropoffTime",
                          moment(value, "hh:mm A").add(1, "hour").format("hh:mm A")
                        );
                        setLastDropoffTime(
                          moment(value, "hh:mm A").add(1, "hour").format("hh:mm A") || DEFAULT_TIME
                        );
                      } else {
                        setFieldValue("dropoffTime", null);
                      }
                      setFieldValue("pickupTime", moment(value, "hh:mm A"));
                      setPreventClosing(true);
                    }}
                  >
                    {allPossibleTimes.map((time) => {
                      const dateTime = moment(
                        values.pickupDate?.format("MM/DD/YYYY") + " " + time,
                        "MM/DD/YYYY hh:mm A"
                      );
                      return (
                        <SelectItem
                          key={`${time}-pickup`}
                          data-testid={`cars-pickup-time-${time}`}
                          isDisabled={dateTime.isBefore(oneHourInTheFuture)}
                          value={time}
                        >
                          {time}
                        </SelectItem>
                      );
                    })}
                  </Styled.SelectMenu>
                </Styled.PanelColumn>
                <Styled.PanelColumn>
                  <div>Drop-off</div>
                  <Styled.Title>
                    {values.dropoffDate ? dropoffFriendlyDate : "Select a date"}
                  </Styled.Title>
                  <Styled.SelectMenu
                    size="md"
                    isDisabled={!values.pickupDate || !values.dropoffDate}
                    data-testid="dropoff-time"
                    role="menu"
                    defaultValue={dropoffTime || lastDropoffTime || DEFAULT_TIME}
                    key={values.dropoffTime + "-dropoff-time"}
                    onValueChange={(value) => {
                      setFieldValue("dropoffTime", moment(value, "hh:mm A"));
                      setPreventClosing(false);
                      setFocusedInput(null);
                    }}
                    onFocus={(e) => {
                      if (!values.dropoffTime) {
                        setFieldValue("dropoffTime", moment(lastDropoffTime, "hh:mm A"));
                        setFocusedInput(null);
                      }
                      e.target.blur();
                    }}
                  >
                    {allPossibleTimes.map((time) => {
                      const dateTime = moment(
                        values.dropoffDate?.format("MM/DD/YYYY") + " " + time,
                        "MM/DD/YYYY hh:mm A"
                      );

                      return (
                        <SelectItem
                          key={`${time}-dropoff`}
                          data-testid={`cars-dropoff-time-${time}`}
                          aria-label={time}
                          isDisabled={
                            dateTime.isBefore(oneHourInTheFuture) ||
                            dateTime.isBefore(pickupDateTime?.clone().add(1, "hour"))
                          }
                          value={time}
                        >
                          {time}
                        </SelectItem>
                      );
                    })}
                  </Styled.SelectMenu>
                </Styled.PanelColumn>
              </Styled.BottomPanel>
            );
          }}
        />
      </Styled.DateRangePicker>
    </Styled.DateSelectorInput>
  );
};
