import type { DefaultTheme } from "styled-components";
import styled, { css } from "styled-components";
import { Icon } from "@hotelengine/atlas-web";

import { searchHeight } from "@hotel-engine/constants";
import { buttonReset } from "@hotel-engine/styles";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

interface IDateRangeProps {
  checkInError: boolean;
  checkOutError: boolean;
  size?: "md" | "lg";
}

interface IInputProps {
  inputWidth?: string;
  margin?: string;
}

// Using a color config for now until we complete building out our new theme
const dateColors = (theme: DefaultTheme) =>
  ({
    primary: theme.colors.uiPrimary,
    hover: theme.colors.uiPrimaryHover,
    secondary: theme.colors.backgroundPrimary,
    secondaryHover: theme.colors.backgroundPrimaryHover,
    active: theme.colors.backgroundSecondary,
    activeHover: theme.colors.backgroundSecondaryHover,
    text: theme.colors.foregroundPrimary,
    contrastText: theme.colors.foregroundInverse,
    error: theme.colors.sentimentCriticalDefault,
    border: theme.colors.borderMinimal,
    tooltip: theme.colors.backgroundInverse,
  }) as const;

const mobileDateSizes = {
  titleHeight: 56,
  dateSectionMargin: 12,
  selectedDatesHeight: 63,
  weekHeight: 40,
} as const;

export const DateSelectorInput = styled.div<IInputProps>`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const MobileDateSelectorInput = styled(DateSelectorInput)<{ size: "md" | "lg" }>`
  background-color: ${({ theme }) => theme.colors.inputBackground};
  border: solid 1px ${({ theme }) => theme.colors.borderDefault};
  border-radius: ${({ theme }) => theme.borderRadius.nsActionMd};
  height: ${({ size }) => (size === "lg" ? 48 : 40)}px;
  max-width: none;
  padding: 0 16px;
  width: ${({ inputWidth }) => inputWidth || "50%"};
  margin: ${({ margin }) => margin || "0 8px 0 0"};
  cursor: pointer;
  width: 100%;

  &:focus,
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.uiPrimary};
  }
`;

export const ButtonReset = styled.button<{ isDashboardSearchForm?: boolean }>`
  ${buttonReset}
  color: ${({ theme }) => theme.colors.inputForeground};
  font-size: ${({ theme }) => theme.typography.desktop["body/md"]};
`;

export const SeparatorArrow = styled(Icon).attrs(() => ({
  size: "md",
  name: "arrow-right",
  color: "foregroundPrimary",
}))`
  margin: 0 16px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 0 8px;
  }
`;

export const DateCalendar = styled.div<{ isFocused?: boolean }>`
  ${({ isFocused }) =>
    isFocused &&
    css`
      // subtract margin + height of top section
      height: calc(
        100% - ${mobileDateSizes.selectedDatesHeight}px -
          ${mobileDateSizes.dateSectionMargin}px
      );
    `}

  .DayPicker_weekHeader {
    height: ${mobileDateSizes.weekHeight}px;
    border-top: 1px solid ${({ theme }) => dateColors(theme).border};

    ${mediaQuery("xl")(`
                top: 55px;
                height: unset;
                border: none;
            `)}
  }

  ${mediaQuery(
    "xl",
    "max"
  )(`
        .DayPicker_weekHeader_ul {
            line-height: ${mobileDateSizes.weekHeight - 2}px;
            margin: 0;
        }

    `)}

  .CalendarMonth, .DayPicker, .DateRangePicker_picker, .DayPickerNavigation_button__default, .CalendarMonthGrid, .DayPicker_weekHeader__verticalScrollable {
    background: ${({ theme }) => theme.colors.backgroundPrimary};
  }

  .CalendarMonth_caption {
    color: ${({ theme }) => dateColors(theme).text};
    font-size: 16px;
  }

  .DayPickerNavigation_button__default {
    border: none;

    &:hover, &:focus {
      background: ${({ theme }) => theme.colors.backgroundPrimaryHover} !important;
    }
  }

  .DateRangePicker_picker__directionLeft {
    transform: translate(-25px, -${searchHeight});
    border-radius: ${({ theme }) => theme.borderRadius.md};
    top: 119px !important;
  }

  .DayPicker_transitionContainer {
    background: ${({ theme }) => theme.colors.backgroundPrimary};
    border-radius: ${({ theme }) => theme.borderRadius.md};
    ${mediaQuery(
      "xl",
      "max"
    )(`
            padding-top: 53px;

        `)}
  }

  .DayPicker__withBorder {
    border-radius: ${({ theme }) => theme.borderRadius.md};
    box-shadow:
      0px 8px 24px 1px rgba(0, 0, 0, 0.12),
      0px 1px 4px rgba(0, 0, 0, 0.04);
  }

  .CalendarMonth_table {
    border-collapse: unset;
  }

  // Style the date picker days
  .CalendarDay {
    border: 1px solid transparent;

    &.CalendarDay__default {
      background: ${({ theme }) => dateColors(theme).secondary};
      &:hover,
      &:focus {
        background: ${({ theme }) => dateColors(theme).secondaryHover};
        border: 1px solid ${({ theme }) => theme.colors.borderStrong};
      }
    }

    // The two selected days
    &.CalendarDay__selected {
      background: ${({ theme }) => dateColors(theme).primary};
      color: ${({ theme }) => dateColors(theme).contrastText};

      &:hover,
      &:focus {
        background: ${({ theme }) => dateColors(theme).hover};
      }
    }

    // The selected range between check in and check out
    &.CalendarDay__selected_span {
      background: ${({ theme }) => dateColors(theme).active};
      color: ${({ theme }) => dateColors(theme).text};

      &:hover,
      &:focus {
        background: ${({ theme }) => dateColors(theme).activeHover};
      }
    }

    // Selected check in
    &.CalendarDay__selected_start {
      border-radius: ${({
        theme: {
          legacy: {
            shape: { borderRadius },
          },
        },
      }) => `${borderRadius.sm} 0 0 ${borderRadius.sm}`};
    }

    // Selected check out
    &.CalendarDay__selected_end {
      border-radius: ${({
        theme: {
          legacy: {
            shape: { borderRadius },
          },
        },
      }) => `0 ${borderRadius.sm} ${borderRadius.sm} 0`};
    }

    // The non-finalized selected range between check in and check out
    &.CalendarDay__hovered_span {
      background: ${({ theme }) => dateColors(theme).active};
      color: ${({ theme }) => dateColors(theme).text};

      &:hover,
      &:focus {
        background: ${({ theme }) => dateColors(theme).active};
        border: 1px solid ${({ theme }) => theme.colors.borderStrong};
        border-radius: ${({
          theme: {
            legacy: {
              shape: { borderRadius },
            },
          },
        }) => `0 ${borderRadius.sm} ${borderRadius.sm} 0`};
      }
    }

    // Build the tooltip when hovering over a date
    &[data-active-tooltip="true"] {
      position: relative;

      &:hover::before {
        position: absolute;
        width: 75px;
        top: -36px;
        left: 50%;
        transform: translateX(-50%);
        content: attr(data-number-of-nights);
        background-color: ${({ theme }) => dateColors(theme).tooltip};
        color: ${({ theme }) => dateColors(theme).contrastText};
        z-index: 100 /* disable theming (do not replicate) */;
        font-size: 12px;
        padding: 8px;
        border-radius: ${({ theme }) => theme.borderRadius.sm};
      }
      &:hover::after {
        position: absolute;
        top: -3px;
        transform: translateX(-50%);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid ${({ theme }) => dateColors(theme).tooltip};
        content: " ";
        font-size: 0;
        line-height: 0;
        margin-left: -25%;
        width: 0;
      }
    }
  }

  // Month title
  .CalendarMonth_caption {
    color: ${({ theme }) => dateColors(theme).text};
    font-size: 16px;
    padding-top: 13px;

    strong {
      font-weight: normal;
    }
  }

  .CalendarMonthGrid_month__hidden {
    visibility: visible;
  }
`;

export const DateRangePicker = styled(DateCalendar)<IDateRangeProps>`
  height: 100%;

  &:has(.DateInput_input[id="date-range-picker-start"]:hover), &:has(.DateInput_input[id="date-range-picker-start"]:focus) {
    /** fixes the gap caused by the transparent border-right/left on the date-range-picker-start and date-range-picker-end */
    .DateInput_input[id="date-range-picker-start"] {
      z-index: ${({ theme }) => theme.zIndex.backdropAbove + theme.zIndex.backdropAbove};
    }

    .DateRangePickerInput_calendarIcon {
      z-index: ${({ theme }) =>
        theme.zIndex.backdropAbove + theme.zIndex.backdropAbove + theme.zIndex.backdropAbove};
    }
  }

  .DateRangePicker {
    &,
    > div {
      height: 100%;
    }
  }

  .DateRangePickerInput {
    display: flex;
    align-items: stretch;
    height: 100%;
    background: none;

    .DateRangePickerInput_arrow {
      /** fixes the gap caused by the transparent border-right/left on the date-range-picker-start and date-range-picker-end */
      z-index: ${({ theme }) => theme.zIndex.backdropAbove};
    }
  }

  .DateInput_fang {
    display: none;
  }

  .DateInput {
    height: 100%;
    background: none;

    .DateInput_input {
      background-color: ${({ theme }) => theme.colors.inputBackground};
      color: ${({ theme }) => dateColors(theme).text};
      font-weight: normal;
      font-size: ${({ theme }) => theme.typography.desktop["body/md"]};
      margin-bottom: 0;
      text-align: center;
      line-height: unset;
      cursor: pointer;
      height: ${({ size = "md" }) => (size === "lg" ? 48 : 40)}px;
      border-radius: ${({ theme }) => theme.borderRadius.nsActionMd};
      padding: 0 ${({ theme }) => theme.spacing[16]};
      border: 1px solid ${({ theme }) => theme.colors.borderDefault};

      &:focus,
      &:hover {
        border: 1px solid ${({ theme }) => theme.colors.uiPrimary};
      }
    }

    .DateInput_input[id="date-range-picker-start"] {
      border-right: none;
      border-top-right-radius: ${({ theme }) => theme.borderRadius.none};
      border-bottom-right-radius: ${({ theme }) => theme.borderRadius.none};
      padding-left: ${({ theme }) => theme.spacing[32]};
      /** fixes the gap caused by the transparent border-right/left on the date-range-picker-start and date-range-picker-end */
      position: relative;
      right: -1px;

      &:not(:hover, :focus)  {
        border-right: 1px solid transparent !important;
      }

      &:focus,
      &:hover {
        border-right: 1px solid ${({ theme }) => theme.colors.uiPrimary};
      }
    }

    .DateInput_input[id="date-range-picker-end"] {
      border-left: none;
      border-top-left-radius: ${({ theme }) => theme.borderRadius.none};
      border-bottom-left-radius: ${({ theme }) => theme.borderRadius.none};
      /** fixes the gap caused by the transparent border-right/left on the date-range-picker-start and date-range-picker-end */
      position: relative;
      left: -1px;

      &:not(:hover, :focus)  {
        border-left: 1px solid transparent !important;
      }

      &:focus,
      &:hover {
        border-left: 1px solid ${({ theme }) => theme.colors.uiPrimary};
        z-index: ${({ theme }) => theme.zIndex.backdropAbove};
      }
    }
  }

  .DateRangePickerInput_calendarIcon {
    margin: 0;
    border: 1px solid transparent;
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    width: 40px;
    height: 100%;
    align-self: center;
    position: absolute;
    z-index: ${({ theme }) => theme.zIndex.backdropAbove};
    top: 0;
    left: 4px;

    &:hover,
    &:focus {
      border: ${({ theme }) => theme.colors.uiPrimary};
    }
  }

  ${({ checkInError, theme }) =>
    checkInError &&
    css`
      .DateInput_input[id="date-range-picker-start"] {
        color: ${dateColors(theme).error};

        &::placeholder {
          color: ${dateColors(theme).error};
          opacity: 1; // Firefox
        }

        &:hover,
        &:focus {
          border-color: ${dateColors(theme).error};
        }
      }
    `}

  ${({ checkOutError, theme }) =>
    checkOutError &&
    css`
      .DateInput_input[id="date-range-picker-end"] {
        color: ${dateColors(theme).error};

        &::placeholder {
          color: ${dateColors(theme).error};
          opacity: 1; // Firefox
        }

        &:hover,
        &:focus {
          border-color: ${dateColors(theme).error};
        }
      }
    `}

  .DateRangePickerInput_arrow {
    display: flex;
    align-items: center;
    color: inherit;
    background-color: ${({ theme }) => theme.colors.inputBackground};
    border-top: 1px solid ${({ theme }) => theme.colors.borderDefault};
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderDefault};
    height: ${({ size = "md" }) => (size === "lg" ? 48 : 40)}px;
  }

  .DayPickerNavigation_button {
    &:hover,
    &:focus {
      background: ${({ theme }) => theme.colors.backgroundSecondary};
    }
  }

  .DayPickerNavigation_button__horizontalDefault {
    padding: 0 9px;
    top: 16px;
  }
`;

export const CheckOutDate = styled(ButtonReset)<{
  $checkOutError?: boolean;
}>`
  color: ${({ theme, $checkOutError }) =>
    $checkOutError ? dateColors(theme).error : theme.colors.inputForeground};
  font-size: ${({ theme }) => theme.typography.desktop["body/md"]};
`;

export const TitleSection = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderSubtle};
  position: relative;
  height: ${mobileDateSizes.titleHeight}px;
`;

export const CloseButton = styled(ButtonReset)`
  font-size: 18px;
  margin-left: 18px;
  height: ${mobileDateSizes.titleHeight}px;
  position: absolute;
`;

export const SelectedDatesSection = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: ${mobileDateSizes.selectedDatesHeight}px;
  margin-bottom: ${mobileDateSizes.dateSectionMargin}px;
  background: ${({ theme }) => theme.colors.backgroundPrimary};
`;

export const CalendarIcon = styled(Icon)`
  margin-left: 16px;
`;

export const SelectedDate = styled(ButtonReset)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 110px;
  margin-right: 8px;
`;

export const Dates = styled.div`
  margin: auto;
`;

export const Hyphen = styled.span`
  font-size: 16px;
  font-weight: 600;
  margin-top: 18px;
  margin-right: 8px;
`;
