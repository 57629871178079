import { useEffect } from "react";

import { Icon } from "@hotelengine/atlas-web";
import type { IconProps } from "@hotelengine/atlas-web";

import type { MapPosition } from "../../../types";
import OverlayView from "../../OverlayView";
import BaseMarker from "../BaseMarker";
import * as Styled from "../styles";

type GoogleMapsCarLocationMarkerProps = {
  /** the map object this marker is being applied to */
  map: google.maps.Map;
  /** the lat, lng of the marker */
  position: MapPosition;
  /** Icon to use depending on whether its pick up or dropoff */
  icon: IconProps["name"];
};

const GoogleMapsCarLocationMarker = ({ map, position, icon }: GoogleMapsCarLocationMarkerProps) => {
  /** If position is updated pan the map */
  useEffect(() => {
    if (!!map && !!position) {
      map.panTo(position);
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  return (
    <OverlayView position={position} map={map}>
      <BaseMarker>
        <Styled.CarMarker data-testid="property-marker">
          <Icon name={icon} size="lg" />
        </Styled.CarMarker>
      </BaseMarker>
    </OverlayView>
  );
};
export default GoogleMapsCarLocationMarker;
