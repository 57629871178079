import type { UseQueryOptions } from "react-query";

import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { IRecentSearch } from "@hotel-engine/types/search";

import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";
import { endpoints } from "../constants";

export const useSearchesQuery = (
  limit?: number,
  options?: UseQueryOptions<IRecentSearch[], IErrorResponse, IRecentSearch[]>
) => {
  const get = useApi("get");
  const queryKey = [endpoints.searches, limit];
  return useExtendedQuery<IRecentSearch[], IErrorResponse>(
    queryKey,
    () => get<IRecentSearch[]>(endpoints.searches, { limit }),
    options
  );
};
