import useAccountToken from "@hotel-engine/app/ItineraryContent/lodging/components/TripInfo/TripExtensions/useAccountToken";
import type { IError } from "@hotel-engine/types/errors";
import type { ITripItinerary } from "@hotel-engine/types/itinerary";
import type { UseQueryOptions } from "react-query";
import { endpoints } from "../constants";
import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";

export const useItineraryQuery = (
  params: { id: number | string },
  options?: UseQueryOptions<ITripItinerary, IError>
) => {
  const get = useApi("get", true, "v2");

  const token = useAccountToken();

  return useExtendedQuery(
    [endpoints.trips, params.id],
    async () => {
      const data = await get(
        `trips/${params.id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data as ITripItinerary;
    },
    options
  );
};
