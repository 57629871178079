import styled from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const TripDetails = styled.div<{ $isPreview: boolean }>`
  margin-top: ${({ $isPreview, theme }) => (!$isPreview ? theme.spacing[40] : theme.spacing[24])};
  margin-bottom: ${({ $isPreview, theme }) => (!$isPreview ? theme.spacing[40] : theme.spacing[24])};
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const DetailsList = styled.div`
  ${flexbox({
    direction: "column",
  })}

  gap: ${({ theme }) => theme.spacing[8]};
`;

export const ListItem = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: ${({ theme }) => theme.spacing[16]};
`;
