import AlertBanner from "@hotel-engine/common/AlertBanner";
import { useSystemOutages } from "@hotel-engine/hooks/useSystemOutages";

const onFailure = {
  title: "",
  body: "",
};

const SystemOutageBanner = () => {
  const { payload } = useSystemOutages(onFailure);
  const { title, body } = payload;

  if (!title || !body) {
    return null;
  }

  return (
    <AlertBanner
      message={title}
      description={
        <p className="body-text">
          {body + " "}Please check back later. Need immediate assistance? Contact Member Support at{" "}
          <a href={"mailto: support@hotelengine.com"}>support@hotelengine.com</a>.
        </p>
      }
      variant="system-outage"
      closable
      banner
      customIcon={"exclamation-triangle"}
      showIcon
      type={"error"}
      outage
    />
  );
};

export { SystemOutageBanner };
