import { useTheme } from "styled-components";

import { Typography } from "@hotelengine/atlas-web";
import { routes } from "@hotel-engine/constants";
import { formatPhoneNumber } from "@hotel-engine/utilities/formatters";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";

import * as Styled from "./styles";

export interface IPropertyDetailsProps {
  onPushMapView(): void;
  canSwitchToMapView: boolean;
}

const PropertyDetails: React.FC<IPropertyDetailsProps> = ({
  onPushMapView,
  canSwitchToMapView,
}) => {
  const { typography, colors } = useTheme();
  const { reservation, isPreview } = useModificationsContext();

  const propertyAddress = [
    reservation.propertyCity,
    reservation.propertyState,
    reservation.propertyCountry,
    reservation.propertyPostalCode,
  ];

  return (
    <Styled.PropertyContainer $isPreview={isPreview}>
      <Styled.PropertyImage alt={reservation.propertyName} src={reservation.propertyPhotoUrl} />
      <Styled.PropertyDetails>
        <Styled.PropertyName
          to={routes.properties + "/" + reservation.propertyId}
          style={{
            ...typography.desktop[isPreview ? "heading/lg" : "heading/xl"],
            color: colors.foregroundPrimary,
          }}
          data-testid="property-name"
        >
          {reservation.propertyName}
        </Styled.PropertyName>
        <Styled.PropertyInfo
          style={{
            ...typography.desktop[isPreview ? "body/sm" : "body/md"],
            color: colors.neutralN900,
          }}
          onClick={canSwitchToMapView ? onPushMapView : undefined}
          disabled={!canSwitchToMapView}
          data-testid="property-info"
        >
          {reservation.propertyStreet}
          <br />
          {propertyAddress.filter(Boolean).join(", ")}
          {!!reservation.propertyPhone && (
            <Styled.PropertyPhoneNumber>
              <Typography
                as="a"
                href={`tel:${formatPhoneNumber(reservation.propertyPhone)}`}
                variant={isPreview ? "body/sm" : "body/md"}
                data-testid="property-phoneNumber"
              >
                {formatPhoneNumber(reservation.propertyPhone)}
              </Typography>
            </Styled.PropertyPhoneNumber>
          )}
        </Styled.PropertyInfo>
      </Styled.PropertyDetails>
    </Styled.PropertyContainer>
  );
};

export default PropertyDetails;
