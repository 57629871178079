type OmittedKeys<T extends object, K extends keyof T> = Omit<T, K>;

function isValidObject(input: unknown): input is object {
  return typeof input === "object" && input !== null && !Array.isArray(input);
}

function omitKeys<T extends object, K extends keyof T>(source: T, ...keys: K[]): OmittedKeys<T, K> {
  if (!isValidObject(source)) {
    throw new Error("The input must be an object");
  }

  const isolatedObject = { ...source };

  const updatedObject = keys.reduce((result, key) => {
    if (key in result) {
      delete result[key];
    }

    return result;
  }, isolatedObject);

  return updatedObject as OmittedKeys<T, K>;
}

export default omitKeys;
