import styled from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const TripDetails = styled.div`
  margin-top: ${({ theme }) => theme.spacing[2]};
  margin-bottom: ${({ theme }) => theme.spacing[24]};
  padding-left: ${({ theme }) => theme.spacing[24]};
  padding-right: ${({ theme }) => theme.spacing[24]};
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const DetailsList = styled.div`
  ${flexbox({
    direction: "column",
  })}

  gap: ${({ theme }) => theme.spacing[8]};
`;

export const ListItem = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: ${({ theme }) => theme.spacing[16]};

  > :last-child {
    text-align: right;
  }
`;
