import styled from "styled-components";
import type { TypographyProps } from "@hotelengine/atlas-web";
import { Alert as AtlasAlert, Typography } from "@hotelengine/atlas-web";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const GridContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing[24]};
  margin-bottom: ${({ theme }) => theme.spacing[24]};
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing[8]};
`;

export const NoMarginGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing[8]};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FieldGridLine = styled.div`
  padding-top: ${({ theme }) => theme.spacing[4]};
  display: flex;
  column-gap: ${({ theme }) => theme.spacing[8]};
  flex-direction: row;
  justify-content: center;

  span:not(:first-child) {
    border-left: 1px solid ${({ theme }) => theme.colors.foregroundPrimary};
    padding-left: ${({ theme }) => theme.spacing[8]};
  }
`;

export const FieldLine = styled.div`
  display: grid;
  column-gap: ${({ theme }) => theme.spacing[16]};
  flex-direction: column;

  span:nth-child(2) {
    text-align: right;
  }
`;

export const Divider = styled.div<{ dashed?: boolean }>`
  height: 1px;
  border-top: ${({ theme, dashed }) => {
    if (dashed) {
      return `1px dashed ${theme.colors.borderSubtle}`;
    } else {
      return `1px solid ${theme.colors.borderMinimal}`;
    }
  }};
`;

export const Alert = styled(AtlasAlert)`
  margin-top: ${({ theme }) => theme.spacing[12]};
`;

export const Link = styled<React.FC<TypographyProps>>(Typography)`
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const Rewards = styled.div`
  ${flexbox({ alignItems: "center", justifyContent: "flex-start" })}
  background: ${({ theme }) => theme.colors.backgroundTertiary};
  border-radius: ${({ theme }) => theme.borderRadius[8]};
  margin-top: ${({ theme }) => theme.spacing[8]};
  padding: ${({ theme }) => theme.spacing[12]} ${({ theme }) => theme.spacing[16]};

  img {
    height: ${({ theme }) => theme.spacing[16]};
    margin-right: 10px;
    width: ${({ theme }) => theme.spacing[16]};
  }
`;
