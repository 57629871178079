import { useEffect } from "react";
import CancelRouter from "./components/CancelRouter";
import NavigationModal from "./components/NavigationModal";
import { useBaseModificationsContext } from "../../ModificationsContext";

/** This is the primary router for different types of modifications, when a value is set to modificationView, it will hit
 * the switch here and render the appropriate router for that specific modification type, the subsequent router will then
 * handle any variations specific to that modfication type, such as Flex, single vs multi room, etc
 */
const ModificationViews = () => {
  const {
    state: { modificationStatus, modificationView, showNavigationModal },
    refetchReservation,
    dispatch,
  } = useBaseModificationsContext();

  useEffect(() => {
    if (modificationStatus.isSubmitted) {
      void refetchReservation();

      dispatch({
        type: "SET_MODIFICATION_VIEW",
        payload: undefined,
      });

      document.querySelector("#itinerary-scroll-container")?.scrollTo(0, 0);
    }

    /** This is here to prevent infinitely refetching the reservation once we hit this useEffect */
    dispatch({
      type: "SET_MODIFICATION_STATUS",
      payload: { isSubmitted: false },
    });
  }, [modificationStatus.isSubmitted, refetchReservation, dispatch]);

  const getModificationRouter = () => {
    switch (modificationView) {
      case "cancel": {
        return <CancelRouter />;
      }
      // Other routers for change dates, change guests, special request, etc will go here based on which modificationView case it is
      default:
        return null;
    }
  };

  return (
    <>
      {getModificationRouter()}
      {!!showNavigationModal && <NavigationModal />}
    </>
  );
};

export default ModificationViews;
