import type { ReactNode } from "react";

import Button from "@hotel-engine/common/Button";
import { Icon } from "@hotel-engine/common/Icon";
import { formatCurrency, formatDate } from "@hotel-engine/utilities";
import type { IFilters, TTripsDispatchContext } from "pages/Trips/contextLegacy";
import { useAppSelector } from "store/hooks";

import * as Styled from "./styles";

const ignoreFilters = ["status", "checkInLt", "totalGt", "travelers", "locations", "onlyMyTrips"];

interface IPillProps {
  children: ReactNode;
  id: string;
  onClose?: () => void;
}

const Pill = ({ children, id, onClose }: IPillProps) => (
  <Styled.Pill data-testid={`filterPill-${id}`}>
    <span>{children}</span>
    <button onClick={onClose} data-testid={id}>
      <Icon icon={["fal", "times"]} />
    </button>
  </Styled.Pill>
);

interface IFilterPillsProps {
  filters: IFilters;
  handleFilterSelection: (e: unknown, value?: unknown | unknown[]) => void;
  setSearchValue: (value: string) => void;
  tripsDispatch: TTripsDispatchContext;
}

export const FilterPills = ({
  filters,
  handleFilterSelection,
  setSearchValue,
  tripsDispatch,
}: IFilterPillsProps) => {
  const user = useAppSelector((state) => state.Auth.user);

  const preferredDateFormat = user?.business?.preferredDateFormat ?? "mdy";

  const currencyCode = user?.business?.currencyCode || "USD";

  const toDisplayText = (key) => {
    const keys = {
      pendingModificationOnly: "Pending Modification",
      manual_bookings: "Assisted Bookings",
      contractType: "Group Bookings Only",
      refundableOnly: "Refundable",
      checkInGt: formatDate(filters.checkInGt, "ddd, MMM D", preferredDateFormat),
      checkInLt: formatDate(filters.checkInLt, "ddd, MMM D", preferredDateFormat),
      totalLt: `${filters.totalLt}`,
      totalGt: `${filters.totalGt}`,
      search: filters.search,
      unverified: "Unverified Checkout",
    };

    return keys[key];
  };

  const handleOnPillClick = (key) => {
    if (key === "search") {
      setSearchValue("");
    }

    handleFilterSelection({ key });
  };

  const handleOnDatePillClick = () => {
    tripsDispatch({
      type: "filter",
      filters: [{ key: "checkInGt" }, { key: "checkInLt" }],
    });
  };

  const handleOnTotalPillClick = () => {
    tripsDispatch({
      type: "filter",
      filters: [{ key: "totalGt" }, { key: "totalLt" }],
    });
  };

  const handleOnSearchArrayPillClick = (term) => {
    const updatedSearch = [...(filters.search ?? [])].filter((s) => s !== term);
    tripsDispatch({
      type: "filter",
      filters: [{ key: "search", value: updatedSearch }],
    });
  };

  const handleDepartmentPillClick = (value: string) => {
    handleFilterSelection({ key: "department" }, [value]);
  };

  const handleClearFilters = () => {
    tripsDispatch({ type: "clearFilters" });
  };

  return (
    <Styled.PillBox>
      {Object.keys(filters).map((key) => {
        if (
          ignoreFilters.includes(key) ||
          !filters[key] ||
          (Array.isArray(filters[key]) && !filters[key].length)
        ) {
          return null;
        }

        if (key === "checkInGt") {
          return (
            <Pill key={key} id="checkIn" onClose={() => handleOnDatePillClick()}>
              {`${toDisplayText("checkInGt")} - ${toDisplayText("checkInLt")}`}
            </Pill>
          );
        }

        if (key === "totalLt") {
          return (
            <Pill key={key} id="total" onClose={() => handleOnTotalPillClick()}>
              {`${formatCurrency(toDisplayText("totalGt"), false, currencyCode)} - ${formatCurrency(
                toDisplayText("totalLt"),
                false,
                currencyCode
              )}`}
            </Pill>
          );
        }

        if (key === "search" && Array.isArray(filters.search)) {
          return filters.search.map((term) => (
            <Pill key={term} id={term} onClose={() => handleOnSearchArrayPillClick(term)}>
              {term}
            </Pill>
          ));
        }

        if (key === "department" && !!filters.department?.length) {
          return filters.department.map((department) => (
            <Pill
              key={department}
              id={department}
              onClose={() => handleDepartmentPillClick(department)}
            >
              {department}
            </Pill>
          ));
        }

        return (
          <Pill key={key} id={key} onClose={() => handleOnPillClick(key)}>
            {toDisplayText(key)}
          </Pill>
        );
      })}
      {!!Object.keys(filters).filter((key) =>
        Array.isArray(filters[key]) ? filters[key].length : filters[key]
      ).length && (
        <Button id="clearFilters" type="link" onClick={handleClearFilters}>
          Clear Filters
        </Button>
      )}
    </Styled.PillBox>
  );
};
