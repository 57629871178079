import moment from "moment";

export const getLayoverDurationString = (
  layoverStartTime: string,
  nextFlightDepartureTime: string
): string => {
  const departMoment = moment(layoverStartTime);
  const arrivalMoment = moment(nextFlightDepartureTime);

  const diffTotal = arrivalMoment.diff(departMoment, "minutes");

  const diffHours = Math.floor(diffTotal / 60);
  let diffMin: string | number = Math.floor(diffTotal % 60);
  if (diffMin <= 9) {
    diffMin = `0${diffMin}`;
  }
  return `${diffHours ? diffHours + "h " : ""}${diffMin + "m"}`;
};
