/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from "react";

import { Link, useNavigate, useLocation } from "@hotel-engine/lib/react-router-dom";

import { Icon } from "@hotel-engine/common/Icon";
import { routes, GROUPS, ORDERS } from "@hotel-engine/constants";
import type { ISearchAndRoomRateID } from "@hotel-engine/hooks";
import { useSearchParams, useURLParams, useWindowSize } from "@hotel-engine/hooks";
import { useAccountMode } from "@hotel-engine/react-query/users/useAccountMode";
import { triggerAmplitudeEvent } from "@hotel-engine/scripts/hooks";
import { isUserEligibleToViewAndUseRewards } from "@hotel-engine/utilities";
import { ampli } from "ampli";
import config from "config";
import { PersonalAccountCreationModal } from "pages/DashboardLegacy/components/PersonalAccountCreationCta/PersonalAccountCreationModal";
import SelectedProperties from "pages/ShareOptions/components/SelectedProperties/SelectedProperties";
import { UserPrefsActions } from "store/UserPrefs/UserPrefsRedux";
import { AuthActions } from "store/Auth/AuthRedux";

import { InviteUserModal } from "../modals/InviteUserModal/InviteUserModal";
import { SignInModal } from "../modals/SignInModal";
import type { IEmailLoginDid403 } from "../modals/EmailConfirmationWarningModal";
import { EmailConfirmationWarningModal } from "../modals/EmailConfirmationWarningModal";
import HeaderNotifications from "./components/HeaderNotifications";
import HeaderRewards from "./components/HeaderRewards";
import HeaderUser from "./components/HeaderUser";
import MobileDropdown from "./components/MobileDropdown";
import MobileMenuIcon from "./components/MobileMenuIcon";
import MobileNotificationsDrawer from "./components/MobileNotificationsDrawer";
import { SwitchPACButton } from "./components/SwitchPACButton";
import type { ILinks } from "./constants";
import {
  buildQueryString,
  getCurrentState,
  getNavLinks,
  removeParameter,
  getUserRoleEnabledFeatures,
} from "./helpers";
import * as Styled from "./styles";
import { Menu } from "@hotel-engine/common/Menu";
import { Dropdown } from "@hotel-engine/common/Dropdown";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { Unsafe } from "@hotel-engine/data";

import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { BrandLogo } from "@hotelengine/atlas-web";
import { useCompanyName } from "../../hooks/useCompanyName";

export interface IHeader {
  isHidden?: boolean;
  /** Removes the header divider if passed true */
  isHeaderDividerHidden?: boolean;
  /** Indicates that the links (i.e. Find Hotels, Trips, etc.) in the header should be hidden */
  areLinksHidden?: boolean;
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: (value: boolean) => void;
}

const Header = ({
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  isHidden = false,
  isHeaderDividerHidden = false,
  areLinksHidden = false,
}: IHeader) => {
  const { COMPANY_LOGO, COMPANY_ABBREVIATION } = useCompanyName();
  const user = useAppSelector((state) => state.Auth.user);
  const options = useAppSelector((state) => state.ShareOptions.options);
  const dispatch = useAppDispatch();
  const clearUserPrefs = () => dispatch(UserPrefsActions.clearUserPrefs());
  const signOut = () => dispatch(AuthActions.signOut());
  const currencyCode = user?.business?.currencyCode || "USD";
  const navigate = useNavigate();
  const urlParams = useSearchParams<{
    resetPassword: string;
    toggleAccount: "personal" | "business";
  }>();
  const rrLocation = useLocation();
  const { mobile, tablet } = useWindowSize();
  const hasOrdersAccess = user?.hasGroupsSpecialization;

  const isFlightsDashboardRefreshOn = useIsFeatureFlagOn("flights-dashboard-refresh");
  const isDashboardRebrandOn = useIsFeatureFlagOn("rebrand_logo_copy");

  const isPsamOn = Boolean(hasOrdersAccess);

  // Grabs necessary info for usage tracking //
  const {
    params: { propertyId },
    search: { s: searchId, roomRateId },
  } = useURLParams<ISearchAndRoomRateID>();

  // Indicates that the personal account that the user attempted to login with is not email-confirmed and to render the corresponding modal //
  const [emailLoginDid403, setEmailLoginDid403] = useState({
    did403: false,
    email: "",
  });

  const [showSignInForSwitch, setShowSignInForSwitch] = useState(!!urlParams?.resetPassword);
  const handleFailedSwitch = () => {
    setShowSignInForSwitch(true);
  };
  const [mode, toggleAccountMode] = useAccountMode({
    onFailure: handleFailedSwitch,
  });

  const [selectedLink, setSelectedLink] = useState<ILinks["id"] | "">("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showUnreadNotifications, setShowUnreadNotifications] = useState<boolean>(false);
  const [showNotificationsDrawer, setShowNotificationsDrawer] = useState<boolean>(false);

  const [showPersonalAccountModal, setShowPersonalAccountModal] = useState<boolean>(false);

  useEffect(() => {
    const linkSelected = getCurrentState(rrLocation.pathname);
    setSelectedLink(linkSelected);
  }, [rrLocation.pathname]);

  useEffect(() => {
    document.body.style.overflow = showSignInForSwitch ? "hidden" : "initial";
  }, [showSignInForSwitch]);

  const handleNotificationDrawer = () => {
    setShowNotificationsDrawer((prev) => !prev);
  };

  /** Hide the sign-in modal for account switching and render the "Whoops" modal for unverified accounts */
  const handleEmailLoginDid403 = (data: IEmailLoginDid403) => {
    setShowSignInForSwitch(false);
    setEmailLoginDid403(data);
  };

  /** Remove resetPassword=true from url when comes from a
   * reset password redirect */
  if (urlParams?.resetPassword) {
    const newParams = removeParameter(urlParams, "resetPassword");
    const newQueryString = buildQueryString(newParams);
    navigate(newQueryString ? `/?${newQueryString}` : "/", {
      replace: true,
    });
  }

  if (!user) {
    return null;
  }

  const {
    personalTravelEnabled,
    userIsActuallyPersonalTraveler,
    canCreatePersonalAccount,
    canSwitchToPersonal,
    canSwitchToBusiness,
    shouldShowReferAFriend,
    shouldShowMyProperties,
    shouldShowTravelCredits,
  } = getUserRoleEnabledFeatures(user);

  const navLinks = getNavLinks(user);

  const isMobileDevice = mobile || tablet;
  const cappedFirstName = user.firstName ? capped(user.firstName) : "";
  const cappedLastName = user.lastName ? capped(user.lastName) : "";

  const handleOnSwitchEvent = (accountType: "personal" | "business") => {
    triggerAmplitudeEvent(
      accountType === "personal" ? "clickSwitchToPersonalAccount" : "clickSwitchToBusinessAccount",
      { userId: user.id }
    );
  };

  const handleSwitchAccounts = (accountType: "personal" | "business") => {
    handleOnSwitchEvent(accountType);
    toggleAccountMode().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    setIsMobileMenuOpen(false);
  };

  if (urlParams?.toggleAccount) {
    handleSwitchAccounts(urlParams.toggleAccount);
    navigate("/", { replace: true });
  }

  const handleTrackLocation = () => {
    if (location.pathname === routes.search) {
      ampli.clickHomepageFromSearchResults({
        searchId: Number(searchId),
      });
    }

    // eslint-disable-next-line no-useless-escape
    if (new RegExp(`${routes.properties}\/.*\/book`).test(location.pathname)) {
      ampli.clickHomepageFromCheckout({
        propertyId: Number(propertyId),
        searchId: Number(searchId),
        roomRateId: Number(roomRateId),
      });
    }

    if (/\/properties\/\d+/g.test(location.pathname)) {
      ampli.clickHomepageFromPropertyDetails({
        searchId: Number(searchId),
        propertyId: propertyId ? Number(propertyId) : undefined,
      });
    }
  };

  const menu = (
    <Styled.DropdownMenu>
      <Menu.Item>
        <Link
          data-testid={`header-route--${GROUPS}`}
          to={`${routes.groups.dashboard}/open`}
          onClick={() => {
            ampli.clickGroupsSubNavSelection({
              selectedPage: "groups",
            });
          }}
        >
          Groups
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          data-testid={`header-route--${ORDERS}`}
          to={`${routes.orders}`}
          onClick={() => {
            ampli.clickGroupsSubNavSelection({
              selectedPage: "orders",
            });
          }}
        >
          Orders
        </Link>
      </Menu.Item>
    </Styled.DropdownMenu>
  );

  const renderGroupsMenu = () => {
    return (
      <Dropdown overlay={menu} trigger={["hover"]} placement="bottomCenter">
        <Styled.GroupsMenu data-testid={`header-route--${GROUPS}-dropdown`}>
          <Styled.StyledTitle variant="small">Groups</Styled.StyledTitle>
          <Icon icon={["far", "chevron-down"]} />
        </Styled.GroupsMenu>
      </Dropdown>
    );
  };

  return (
    <Styled.Wrapper
      isMobileDevice={isMobileDevice}
      isHidden={isHidden}
      isHeaderDividerHidden={isHeaderDividerHidden}
      className="header-wrapper"
    >
      <div className="left">
        <div className="menu">
          <Link to={routes.dashboard} onClick={handleTrackLocation}>
            {user.business.logoUrl && !isMobileDevice ? (
              <Styled.Logo
                src={user.business.logoUrl}
                alt={`${user.business.name}-logo`}
                isMobileDevice={isMobileDevice}
              />
            ) : isDashboardRebrandOn ? (
              <BrandLogo name={COMPANY_LOGO} />
            ) : (
              <Styled.HELogo isMobileDevice={isMobileDevice}>
                <img
                  className="logo"
                  src={`${config.cdnHost}/assets/HE-logo-mark.svg`}
                  alt={`${COMPANY_ABBREVIATION}-logo`}
                />
                <p className="name">
                  {userIsActuallyPersonalTraveler
                    ? `${cappedFirstName} ${cappedLastName}`
                    : user.business.name}
                </p>
              </Styled.HELogo>
            )}
          </Link>
          {!areLinksHidden && !isMobileDevice && (
            <Styled.NavWrapper
              isDashboardRebrandOn={isDashboardRebrandOn}
              selectedKeys={[selectedLink]}
              mode="horizontal"
              overflowedIndicator={
                <Styled.overflowedIndicator>
                  <span>More</span>
                  <Icon icon={["far", "chevron-down"]} />
                </Styled.overflowedIndicator>
              }
            >
              {navLinks.map(({ id, name, route }) => {
                return (
                  <Styled.NavLink key={id} id={id}>
                    {id === GROUPS && !!isPsamOn ? (
                      renderGroupsMenu()
                    ) : (
                      <Link data-testid={`header-route--${id}`} to={route}>
                        {name}
                      </Link>
                    )}
                  </Styled.NavLink>
                );
              })}
            </Styled.NavWrapper>
          )}
        </div>
        <SelectedProperties
          options={options}
          showSelectedProperties={!isMobileDevice && options?.length > 0}
        />
      </div>
      <div className="right">
        {!!user.isDemo && <Styled.DemoBadge id="demo-badge">Demo</Styled.DemoBadge>}
        {isMobileDevice ? (
          <>
            <MobileMenuIcon
              hasUnreadNotifications={showUnreadNotifications}
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              isOpen={!!isMobileMenuOpen && !showNotificationsDrawer}
            />

            <MobileNotificationsDrawer
              accountType={user.accountType}
              onClose={handleNotificationDrawer}
              setShowUnreadNotifications={setShowUnreadNotifications}
              visible={showNotificationsDrawer}
            />
          </>
        ) : (
          <>
            {!!(canSwitchToPersonal || canSwitchToBusiness) && !!mode && (
              <SwitchPACButton
                onSwitchFail={handleFailedSwitch}
                onSwitch={handleOnSwitchEvent}
                $visualRefreshActive={isFlightsDashboardRefreshOn}
              />
            )}

            <HeaderNotifications
              accountType={user.accountType}
              hasUnreadNotifications={showUnreadNotifications}
              setShowUnreadNotifications={setShowUnreadNotifications}
            />

            <HeaderRewards
              showRewards={isUserEligibleToViewAndUseRewards(user)}
              rewardsProfile={user.rewardsProfile!}
              onNavigateToRewards={() => navigate(routes.rewards)}
              currencyCode={currencyCode}
              $visualRefreshActive={isFlightsDashboardRefreshOn}
            />

            <HeaderUser
              user={user}
              signOut={signOut}
              onInviteTraveler={() => setShowModal(true)}
              onPersonalAccountCreation={() => {
                setShowPersonalAccountModal(true);
                triggerAmplitudeEvent("clickAddPersonalAccountInMenu", {
                  userId: user.id,
                });
              }}
              onSwitchToPersonal={() => {
                handleSwitchAccounts("personal");
              }}
              onSwitchToBusiness={() => {
                handleSwitchAccounts("business");
              }}
              userTokenMode={mode}
              showSwitch={!!canSwitchToBusiness || !!canSwitchToPersonal}
              shouldShowTravelCredits={shouldShowTravelCredits}
              shouldShowReferAFriend={shouldShowReferAFriend}
              shouldShowMyProperties={shouldShowMyProperties}
              canCreatePersonalAccount={canCreatePersonalAccount}
            />
          </>
        )}
      </div>
      <InviteUserModal
        visible={showModal}
        onCancel={(resetForm) => {
          setShowModal(false);
          resetForm();
        }}
      />
      {!!canCreatePersonalAccount && (
        <PersonalAccountCreationModal
          visible={showPersonalAccountModal}
          onClose={() => {
            setShowPersonalAccountModal(false);
          }}
          triggeredBy="HEADER_SETTINGS"
        />
      )}
      <SignInModal
        visible={showSignInForSwitch}
        onCancel={() => {
          if (urlParams.toggleAccount) {
            navigate("/", { replace: true });
          }
          setShowSignInForSwitch(false);
        }}
        setEmailLoginDid403={handleEmailLoginDid403}
      />
      <EmailConfirmationWarningModal
        email={emailLoginDid403.email}
        onClose={() => setEmailLoginDid403({ ...emailLoginDid403, did403: false })}
        visible={emailLoginDid403.did403}
      />
      {!!isMobileDevice && (
        <MobileDropdown
          handleNotificationOpen={handleNotificationDrawer}
          hasUnreadNotifications={showUnreadNotifications}
          isOpen={isMobileMenuOpen}
          onPersonalAccountCreation={() => {
            personalTravelEnabled && setShowPersonalAccountModal(true);
            setIsMobileMenuOpen(false);
            triggerAmplitudeEvent("clickAddPersonalAccountInMenu", {
              userId: user.id,
            });
          }}
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          navigate={navigate}
          {...{
            user,
            signOut,
            shouldShowTravelCredits,
            shouldShowReferAFriend,
          }}
          onSwitchToPersonal={() => {
            handleSwitchAccounts("personal");
          }}
          onSwitchToBusiness={() => {
            handleSwitchAccounts("business");
          }}
          userTokenMode={mode}
          showSwitch={!!canSwitchToBusiness || !!canSwitchToPersonal}
          canCreatePersonalAccount={canCreatePersonalAccount}
          clearUserPrefs={clearUserPrefs}
        />
      )}
    </Styled.Wrapper>
  );
};

const capped = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export default Header;
