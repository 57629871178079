import type { UseQueryOptions } from "react-query";

import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { IUser } from "@hotel-engine/types/user";

import { endpoints } from "../constants";
import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";

interface IUserQueryOptions {
  /**
   * will fetch the user with this accessToken. This accessToken overrides the default bearer token on requests
   */
  accessToken?: string;
}

export const useUserQuery = (
  params?: IUserQueryOptions,
  options?: UseQueryOptions<IUser, IErrorResponse>
) => {
  const get = useApi("get");
  return useExtendedQuery(
    [endpoints.user],
    () =>
      get<IUser>(
        endpoints.user,
        {},
        {
          ...(params?.accessToken && {
            headers: {
              Authorization: `Bearer ${params?.accessToken}`,
            },
          }),
        }
      ),
    options
  );
};
