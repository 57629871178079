import AutoCompleteSuggestion from "../AutoCompleteSuggestion";
import { Typography, Checkbox } from "@hotelengine/atlas-web";
import { useFormikContext } from "formik";
import type { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";
import type { ISearchModel } from "pages/Cars/Search/types";
import { useGoogleAutoCompleteResults } from "@hotel-engine/hooks/useGoogleAutocompleteResults";
import FullscreenModal from "@hotel-engine/common/FullscreenModal";
import type { TFormattedGoogleSuggestion } from "../SearchForm";
import { CheckboxWrapper, StateContainer } from "./styles";
import LocationInputs from "./components/LocationInputs";
import { getIconType } from "pages/Cars/Search/helpers";

export type InputFocused = "pickup" | "dropoff" | undefined;

interface ILocationModalProps {
  showModal: boolean;
  onClose: (location?: TFormattedGoogleSuggestion) => void;
  focusedInput?: InputFocused;
  setInputFocused: Dispatch<SetStateAction<InputFocused>>;
  sameLocation: boolean;
  setSameLocation: Dispatch<SetStateAction<boolean>>;
}

export const LocationModal = ({
  showModal,
  onClose,
  focusedInput,
  setInputFocused,
  sameLocation,
  setSameLocation,
}: ILocationModalProps) => {
  const { values, setFieldValue } = useFormikContext<ISearchModel>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentQuery, setCurrentQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<TFormattedGoogleSuggestion[]>([]);

  const { autoCompleteDataState, isAutocompleteLoading, predictions } =
    useGoogleAutoCompleteResults(currentQuery);

  useEffect(() => {
    if (!isAutocompleteLoading) {
      setSearchResults(
        predictions.map((result) => {
          const usRegex = new RegExp("United States", "ig");
          const description = result.description.replace(usRegex, () => "USA");

          return {
            ...result,
            description,
            icon: getIconType(result),
          } as TFormattedGoogleSuggestion;
        })
      );

      setIsLoading(false);
      return;
    }
    setIsLoading(true);
  }, [isAutocompleteLoading, predictions]);

  const handleSameLocationChange = (checked) => {
    setSameLocation(checked);
    setFieldValue("dropoffLocationName", checked ? values.pickupLocationName : "");
    setFieldValue("dropoffLocationId", checked ? values.pickupLocationId : "");
  };

  return (
    <FullscreenModal
      title="Select locations"
      hasDoneButton
      onClose={() => {
        setCurrentQuery("");
        setSearchResults([]);
        if (!values.dropoffLocationName) {
          handleSameLocationChange(true);
        }
        onClose();
      }}
      visible={showModal}
      padding="16px"
      headerReverse
    >
      <LocationInputs
        sameLocation={sameLocation}
        values={values}
        onChange={(value, inputType: InputFocused) => {
          setCurrentQuery(value);
          if (!value) {
            setFieldValue(
              inputType === "pickup" ? "pickupLocationName" : "dropoffLocationName",
              ""
            );
            setFieldValue(inputType === "pickup" ? "pickupLocationId" : "dropoffLocationId", "");
            setSearchResults([]);
          }
        }}
        inputFocused={focusedInput}
        setInputFocused={setInputFocused}
      />

      <CheckboxWrapper>
        <Checkbox isChecked={sameLocation} onCheckedChange={handleSameLocationChange}>
          Return vehicle to the same location
        </Checkbox>
      </CheckboxWrapper>

      {autoCompleteDataState === "loading" || isLoading ? (
        <StateContainer>
          <Typography variant="heading/sm">Loading...</Typography>
        </StateContainer>
      ) : autoCompleteDataState === "no-results" ? (
        <StateContainer>
          <Typography variant="heading/sm">No results found</Typography>
        </StateContainer>
      ) : autoCompleteDataState === "show-locations" ? (
        <div style={{ height: "100%", overflow: "scroll" }}>
          {searchResults.map((location) => (
            <AutoCompleteSuggestion
              location={location}
              query={currentQuery}
              key={location.place_id}
              onClick={() => {
                if (focusedInput === "pickup") {
                  setFieldValue("pickupLocationName", location.description);
                  setFieldValue("pickupLocationId", location.place_id);

                  if (sameLocation) {
                    setFieldValue("dropoffLocationName", location.description);
                    setFieldValue("dropoffLocationId", location.place_id);
                  }
                } else {
                  setFieldValue("dropoffLocationName", location.description);
                  setFieldValue("dropoffLocationId", location.place_id);
                }

                setCurrentQuery("");
                setSearchResults([]);
              }}
            />
          ))}
        </div>
      ) : null}
    </FullscreenModal>
  );
};
