import type { ReactChild } from "react";

import * as Styled from "./styles";
import type { SearchResultMarkerAttributes } from "../../../types";

type BaseMarkerProps = {
  /** The content associated with the marker itself */
  children: ReactChild;
  /** Has the child content finished loading */
  loaded?: boolean;
  /** Is the parent component waiting for more content */
  loading?: boolean;
  /** Search Results specific prop to hide the marker itself but show the child content */
  isHidden?: boolean;
  /** Object of params to set the appropriate colors, icon, and zIndex based on the result object */
  markerAttr?: SearchResultMarkerAttributes;
  /** has this marker or the related property been clicked on or hovered  */
  selected?: boolean;
  /** Used for inverted flight pin to avoid overlap of flight path */
  inverted?: boolean;
  /** Used for flight path to provide padding between pin and flight path end cap */
  padded?: boolean;
};

const BaseMarker = ({
  children,
  loaded = true,
  loading = false,
  isHidden = false,
  markerAttr,
  selected = false,
  inverted = false,
  padded = false,
}: BaseMarkerProps) => {
  return isHidden ? (
    <>{children}</>
  ) : (
    <Styled.Marker
      $loaded={loaded}
      $loading={loading}
      $markerAttr={markerAttr}
      $selected={selected}
      $inverted={inverted}
      $padded={padded}
      data-testid="base-marker"
    >
      {children}
    </Styled.Marker>
  );
};
export default BaseMarker;
