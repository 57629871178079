import type { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";
import { Icon } from "@hotelengine/atlas-web";
import type { ISearchModel } from "pages/Cars/Search/types";
import type { InputFocused } from "../..";
import { LocationInput, LocationInputsWrapper } from "../../../styles";

export interface ILocationInputsProps {
  inputFocused: InputFocused;
  setInputFocused: Dispatch<SetStateAction<InputFocused | undefined>>;
  values: ISearchModel;
  sameLocation: boolean;
  onChange: (value: string, inputType: InputFocused) => void;
}

const LocationInputs = ({
  inputFocused,
  setInputFocused,
  values,
  sameLocation,
  onChange,
}: ILocationInputsProps) => {
  const [pickupLocationValue, setPickupLocationValue] = useState<string>(
    values.pickupLocationName ?? ""
  );
  const [dropoffLocationValue, setDropoffLocationValue] = useState<string>(
    values.dropoffLocationName ?? ""
  );

  useEffect(() => {
    setPickupLocationValue(values.pickupLocationName ?? "");
    setDropoffLocationValue(values.dropoffLocationName ?? "");
  }, [values.pickupLocationName, values.dropoffLocationName, inputFocused]);

  return (
    <LocationInputsWrapper key={inputFocused}>
      <LocationInput
        $isSameLocation={sameLocation}
        autoFocus={inputFocused === "pickup"}
        autoComplete="nope"
        data-testid="location-dropoff-input"
        isClearable
        defaultValue={values.pickupLocationName ?? ""}
        value={pickupLocationValue}
        placeholder="Pick-up location"
        onChange={(e) => {
          setPickupLocationValue(e.target.value);
          onChange(e.target.value, "pickup");
        }}
        onFocus={() => setInputFocused("pickup")}
        leadingAdornment={<Icon name="car-circle-arrow-up--solid" color="inputForegroundHelper" />}
      />
      {!sameLocation && (
        <LocationInput
          $isSameLocation={sameLocation}
          autoComplete="nope"
          autoFocus={inputFocused === "dropoff"}
          data-testid="location-dropoff-input"
          isClearable
          defaultValue={values.dropoffLocationName ?? ""}
          value={dropoffLocationValue}
          placeholder="Drop-off location"
          onChange={(e) => {
            setDropoffLocationValue(e.target.value);
            onChange(e.target.value, "dropoff");
          }}
          onFocus={() => setInputFocused("dropoff")}
          leadingAdornment={
            <Icon name="car-circle-arrow-down--solid" color="inputForegroundHelper" />
          }
        />
      )}
    </LocationInputsWrapper>
  );
};

export default LocationInputs;
