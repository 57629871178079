import styled, { css } from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import type { BoxProps } from "@hotelengine/atlas-web";
import { Box } from "@hotelengine/atlas-web";

export const ModificationActions = styled.div`
  ${flexbox({
    direction: "column",
  })}
  row-gap: ${({ theme }) => theme.spacing[16]};

  ${({ theme }) => css`
    @media screen and (max-width: ${theme.breakpoints.md}) {
        margin-bottom: ${theme.spacing[16]};
    }
  `}
`;

export const ButtonGroup = styled<React.FC<BoxProps>>(Box)`
    ${flexbox({
      direction: "column",
    })}

    gap: ${({ theme }) => theme.spacing[16]};
`;

export const FlexMessage = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[4]};
`;
