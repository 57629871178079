import type { CustomIconName, IconName } from "@hotelengine/atlas-core";
import type { TokensSchema } from "@hotelengine/atlas-web";
import { Icon, Typography } from "@hotelengine/atlas-web";
import type { TypographyVariant } from "@hotelengine/atlas-web/dist/theme/tokens";
import React from "react";
import * as Styled from "./styles";

interface ISectionSubtitle {
  icon: {
    name: IconName | CustomIconName;
    color?: keyof TokensSchema["colors"];
  };
  title: string;
  children?: React.ReactNode;
  titleVariant?: TypographyVariant;
  testID?: string;
}

export function SectionSubtitle({
  icon,
  title,
  titleVariant = "body/md-strong",
  children,
  testID,
}: ISectionSubtitle) {
  return (
    <Styled.SectionWrapper data-testid={testID}>
      <Styled.SectionIcon>
        <Icon {...icon} color="foregroundPrimary" />
      </Styled.SectionIcon>

      <Styled.SectionContent>
        <Typography variant={titleVariant} color="foregroundPrimary" marginBottom={4}>
          {title}
        </Typography>

        {children}
      </Styled.SectionContent>
    </Styled.SectionWrapper>
  );
}
