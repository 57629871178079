import { useCallback, useState, Fragment } from "react";
import type { ConnectedProps } from "react-redux";
import { connect } from "react-redux";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import type { ActionCreator, AnyAction } from "redux";

import { supportCenterRouteSources, routes } from "@hotel-engine/constants";
import type { ITokenMode } from "@hotel-engine/react-query/users/useAccountMode";
import { User } from "@hotel-engine/services";
import { colors } from "@hotel-engine/styles";
import type { IUser } from "@hotel-engine/types/user";
import { isUserEligibleToViewAndUseRewards } from "@hotel-engine/utilities";
import { UserPrefsActions } from "store/UserPrefs/UserPrefsRedux";

import { userRoleMap, getUserNavLinks, getInternalBusinessNavLinks } from "../constants";
import { handleSignOut } from "../helpers";
import * as Styled from "./userStyles";
import { useIsFeatureFlagOn } from "../../Experiments";
import { useSupportCaseUpdatesCountQuery } from "@hotel-engine/react-query/supportCenter/useSupportCaseUpdatesCountQuery";
import { Unsafe } from "@hotel-engine/data";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  Typography,
  Divider,
  Checkbox,
  Icon,
} from "@hotelengine/atlas-web";

const HeaderUser = ({
  user,
  onInviteTraveler,
  onPersonalAccountCreation,
  signOut,
  showEngines,
  showRatelessProperties,
  showSearchRadiusCircle,
  toggleEnginePreferences,
  toggleRatelessProperties,
  toggleSearchRadiusCircle,
  clearUserPrefs,
  shouldShowReferAFriend,
  shouldShowTravelCredits,
  shouldShowMyProperties,
  onSwitchToBusiness,
  onSwitchToPersonal,
  userTokenMode,
  showSwitch,
  canCreatePersonalAccount,
}: IHeaderRewardsProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const { firstName, lastName, role, business, businessTravelUserId } = user;
  const userIsActuallyPersonalTraveler = businessTravelUserId && role === "user";
  const currentUser = new User(user);
  const hasCoordinatorAccess = currentUser.hasRole("coordinator");
  const hePerkBusinessId = 260129;
  const isInviteTravelerEnabled =
    hasCoordinatorAccess && (business.id === hePerkBusinessId || !business.isInternal);
  const { data, refetch } = useSupportCaseUpdatesCountQuery();

  const handleSupportCaseClick = useCallback(() => {
    const cb = setTimeout(async () => {
      refetch().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    }, 3000);

    navigate(`${routes.supportCenter}?source=${supportCenterRouteSources.dropDown}`);

    return () => {
      clearTimeout(cb);
    };
  }, [refetch, navigate]);

  const supportCenterFeatureOn = useIsFeatureFlagOn("support-center");

  const supportCaseUpdatesCount = data?.supportCaseUpdatesCount;

  const toggleDropdown = useCallback(() => setIsDropdownOpen((prev) => !prev), []);
  const openDropdown = useCallback(() => setIsDropdownOpen(true), []);
  const closeDropdown = useCallback(() => setIsDropdownOpen(false), []);
  const handleEscKey = useCallback((e) => {
    if (e.key === "Escape") {
      setIsDropdownOpen(false);
    }
  }, []);

  const userNavLinks = getUserNavLinks({
    navigate,
    shouldShowReferAFriend,
    shouldShowTravelCredits,
    shouldShowRewards: isUserEligibleToViewAndUseRewards(user),
    shouldShowMyProperties,
    onInviteTraveler,
    isInviteTravelerEnabled,
    supportCenterFeatureOn,
    handleSupportCaseClick,
    canCreatePersonalAccount,
    onPersonalAccountCreation,
    userTokenMode,
    showSwitch,
    onSwitchToBusiness,
    onSwitchToPersonal,
  });

  const internalBusinessNavLinks = getInternalBusinessNavLinks({
    showEngines,
    showRatelessProperties,
    showSearchRadiusCircle,
    toggleEnginePreferences,
    toggleRatelessProperties,
    toggleSearchRadiusCircle,
  });

  return (
    <DropdownMenu isOpen={isDropdownOpen}>
      <DropdownMenuTrigger
        asChild
        onMouseEnter={openDropdown}
        onMouseLeave={closeDropdown}
        onClick={toggleDropdown}
      >
        <Styled.UserNavWrapper id="userNav">
          <Styled.AvatarBadge
            name={[firstName, lastName].filter((v) => !!v).join(" ")}
            backgroundColor={colors.black}
          />
          <Icon name="chevron-down" />
          {!!isDropdownOpen && <Styled.Triangle id="triangle" />}
        </Styled.UserNavWrapper>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        style={{ maxWidth: "unset" }}
        onMouseEnter={openDropdown}
        onKeyDown={handleEscKey}
        data-testid="user-dropdown-menu"
      >
        <Styled.ContentWrapper>
          <Styled.UserInfoItem>
            <Typography variant="body/sm" as="div">
              {firstName} {lastName}
            </Typography>
            <Typography variant="body/xs" as="div">
              {userIsActuallyPersonalTraveler ? "Personal Account" : userRoleMap[role]}
            </Typography>
          </Styled.UserInfoItem>
          <Divider variant="default" isFlexItem />
          {userNavLinks.map(({ label, onClick, icon, shouldShow }) => (
            <Fragment key={label}>
              {!!shouldShow && (
                <>
                  <DropdownMenuItem onClick={() => onClick(user)}>
                    <Icon name={icon} />
                    <Typography variant="body/sm" as="span" padding={8}>
                      {label}{" "}
                      {label === "Support Center" && !!supportCaseUpdatesCount && (
                        <Styled.NotificationBadge
                          variant="filled"
                          data-testid="support-case-notification-badge"
                        >
                          {supportCaseUpdatesCount < 100 ? String(supportCaseUpdatesCount) : "99+"}
                        </Styled.NotificationBadge>
                      )}
                    </Typography>
                  </DropdownMenuItem>
                </>
              )}
            </Fragment>
          ))}
          <Divider variant="default" isFlexItem />
          <DropdownMenuItem
            onClick={async () => {
              await handleSignOut(navigate, signOut);
              clearUserPrefs();
            }}
          >
            <Icon name="arrow-right-from-bracket" />
            <Typography variant="body/sm" as="span" padding={8}>
              Sign Out
            </Typography>
          </DropdownMenuItem>
          {!!business.isInternal && (
            <Styled.InternalSettingsList>
              <span>Show</span>
              {internalBusinessNavLinks.map(({ label, isChecked, onChange }) => (
                <Styled.InternalSettingsItem key={label}>
                  <Checkbox isChecked={isChecked} onCheckedChange={onChange}>
                    {label}
                  </Checkbox>
                </Styled.InternalSettingsItem>
              ))}
            </Styled.InternalSettingsList>
          )}
        </Styled.ContentWrapper>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const connector = connect(
  ({ UserPrefs }) => ({
    showEngines: UserPrefs.showEngines,
    showRatelessProperties: UserPrefs.showRatelessProperties,
    showSearchRadiusCircle: UserPrefs.showSearchRadiusCircle,
  }),
  {
    toggleEnginePreferences: UserPrefsActions.toggleEnginePreferences,
    toggleRatelessProperties: UserPrefsActions.toggleRatelessProperties,
    toggleSearchRadiusCircle: UserPrefsActions.toggleSearchRadiusCircle,
    clearUserPrefs: UserPrefsActions.clearUserPrefs,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HeaderUser);

interface IHeaderRewardsProps extends PropsFromRedux {
  /** User's rewards profile object */
  user: IUser;
  /** Callback to fire when user wants to invite traveler */
  onInviteTraveler: () => void;
  /** call back to fire when user wants to create a personal account */
  onPersonalAccountCreation?: () => void;
  /** Callback to sing out */
  signOut: ActionCreator<AnyAction>;
  /** boolean on whether or not to show travel credit links */
  shouldShowMyProperties: boolean;
  /** boolean on whether or not to show My Properties links */
  shouldShowTravelCredits: boolean;
  /** is Refer A Friend enabled for this business */
  shouldShowReferAFriend: boolean;
  /** members app should switch to business token*/
  onSwitchToPersonal: () => void;
  /** members app should switch to personal token*/
  onSwitchToBusiness: () => void;
  /** is it a personal or business token we are currently using? */
  userTokenMode: ITokenMode;
  /** determines whether to show the switching logic */
  showSwitch: boolean;
  /** determines whether to the user can create a personal account */
  canCreatePersonalAccount: boolean;
}
