import type { TableProps } from "antd/lib/table/interface";

import * as Styled from "./styles";

export type { SortOrder, PaginationConfig, ColumnProps } from "antd/lib/table";

/**
 * The `Table` component wraps the antd `<Table />` component.
 *
 * @see {@link https://3x.ant.design/components/table/ AntD Table Documentation}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Table = ({ ...tableProps }: TableProps<any>) => (
  <Styled.Table size="middle" {...tableProps} />
);

export const TableColumn = Styled.TableColumn;
