import { ampli } from "ampli";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "@hotel-engine/lib/react-router-dom";

import LateCheckInModal from "@hotel-engine/app/modals/LateCheckInModal";
import TripExtensions from "@hotel-engine/app/TripExtensions";
import type { ILocationState as ITripExtensionLocationState } from "@hotel-engine/app/TripExtensions";
import { routes } from "@hotel-engine/constants";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import { ModalSteps } from "@hotel-engine/app/TripExtensions/constants";
import { useSearchParams } from "@hotel-engine/hooks";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { useTrackBookingModifications } from "@hotel-engine/app/ItineraryContent/hooks/useTrackBookingModifications";
import { useSalesforceCaseQuery } from "@hotel-engine/react-query/salesforceCase/useSalesforceCaseQuery";
import { useDownloadItineraryDocument } from "@hotel-engine/react-query/downloadItineraryDocument/useDownloadItineraryDocument";
import isStateOfTexasUser from "@hotel-engine/utilities/helpers/isStateOfTexasUser";
import {
  useModificationsContext,
  ModificationTypes,
} from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import useIsContractExtendable from "@hotel-engine/hooks/useIsContractExtendable";
import { notification } from "@hotel-engine/common/Notifications";
import { captureMessage } from "@hotel-engine/utilities";
import { Icon } from "@hotelengine/atlas-web";

import { useAppSelector } from "store/hooks";
import { useTripsContextLegacy } from "pages/Trips/contextLegacy";
import { useTripsContext } from "pages/Trips/context";

import type { IButtonListItem } from "../ActionButtonLayout";
import ActionButtonLayout from "../ActionButtonLayout";
import { useUpcomingActiveActions } from "./useUpcomingActiveActions";

export type IItineraryActionsProps = {
  itinerary: IReservationBase;
  isPreview: boolean;
};

interface IItineraryActionParams {
  lateCheckin?: string;
}

const UpcomingActiveActions = ({ itinerary, isPreview }: IItineraryActionsProps) => {
  const navigate = useNavigate();
  const params = useSearchParams<IItineraryActionParams>();
  const user = useAppSelector((state) => state.Auth.user);
  const { state: locationState, search } = useLocation<ITripExtensionLocationState>();
  const isFlightsPostbookingOn = useIsFeatureFlagOn("flights-postbooking");
  const isSot = isStateOfTexasUser(user);

  const isViewOnlyTraveler = user?.role === "view_only_traveler";
  const showFlexMessage = itinerary.flexEnabled && !isViewOnlyTraveler;

  const [showLateCheckInModal, setShowLateCheckInModal] = useState(params?.lateCheckin === "true");
  const [modalStep, setModalStep] = useState(ModalSteps.Invisible);
  const [extendTrip, setExtendTrip] = useState(!!locationState?.startTripExtensionFlow);

  const { dispatch: modificationsDispatch, refetchReservation } = useModificationsContext();
  const { dispatch: tripsLegacy } = useTripsContextLegacy();
  const { dispatch: trips } = useTripsContext();
  const tripsDispatch = isFlightsPostbookingOn ? trips : tripsLegacy;

  const { isLoading, data } = useSalesforceCaseQuery(
    {
      contractNumber: itinerary.contractNumber,
      categories: ["special"],
      subcategories: ["late_checkin"],
    },
    {
      enabled: !!itinerary.contractNumber,
    }
  );

  const hasLateCheckInRequest = !isLoading && data && data.length > 0;

  const {
    refetch: fetchFolioPdf,
    isLoading: loadingFolioPdf,
    isFetching: fetchingFolioPdf,
  } = useDownloadItineraryDocument({
    contractNumber: itinerary.id,
    documentType: "folio",
  });

  const isLoadingFolio = loadingFolioPdf || fetchingFolioPdf;

  const {
    showModifyReservationButton,
    showLateCheckInAction,
    showExtendReservationAction,
    showCancelBooking,
  } = useUpcomingActiveActions(itinerary);

  const { trackEnterModificationFlow } = useTrackBookingModifications();

  const { canExtendAllRoomsTogether, extendableRoomCount, isGroupContract } =
    useIsContractExtendable();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);

    if (!extendTrip && queryParams.has("extendedContractId")) {
      queryParams.delete("extendedContractId");
      navigate(
        {
          search: queryParams.toString(),
        },
        {
          replace: true,
        }
      );
    }
  }, [extendTrip, navigate, search]);

  const toggleLateCheckInModal = () => {
    setShowLateCheckInModal(!showLateCheckInModal);
  };

  const handleModifyReservation = () => {
    ampli.clickModifyTripAtCheckout({
      propertyId: Number(itinerary.propertyId),
    });

    navigate(`${routes.modifyReservation}/${itinerary.id}`, {
      state: { itinerary },
    });
    ampli.clickModifyThisReservation({
      bookingId: itinerary.id,
    });
  };

  const handleSetActiveModification = (type: ModificationTypes) => {
    trackEnterModificationFlow(type);
    modificationsDispatch({
      type: "SET_MODIFICATION_VIEW",
      payload: type,
    });

    /** If we are within the TripsContext we need to set isActive to set up the management flow for clicking other
     * Trips while a modification is active.
     */
    if (isPreview) {
      tripsDispatch({
        type: "setActiveModification",
        activeModification: {
          isActive: true,
          isNavigating: false,
        },
      });
    }
  };

  const handleExtendTrip = () => {
    if (isPreview) {
      handleExtendTripItineraryPanel();
    } else {
      handleExtendTripItinerary();
    }
  };

  const handleExtendTripItinerary = () => {
    setModalStep(ModalSteps.Calendar);
    setExtendTrip(true);
  };

  const handleExtendTripItineraryPanel = () => {
    if (isGroupContract && (!canExtendAllRoomsTogether || extendableRoomCount === 1)) {
      navigate(`${routes.extendTrips}/${itinerary.id}`);
    } else {
      navigate(
        {
          search: `?booking_number=${itinerary.id}&extendedContractId=${itinerary.id}`,
        },
        {
          state: {
            startTripExtensionFlow: true,
          },
        }
      );
      setExtendTrip(true);
    }
  };

  const handleDocument = async () => {
    try {
      await fetchFolioPdf();
    } catch (err) {
      notification.open({
        message: "There was an error getting your document.",
        duration: 8,
        icon: <Icon name="circle-exclamation" />,
        description: "Please try again later or contact support for assistance.",
      });

      captureMessage("Error fetching document", {
        error: new Error(`Could not fetch folio: ${err}`),
      });
    }
  };

  const buttonList: IButtonListItem[] = [
    {
      action: handleModifyReservation,
      dataTestId: "modify-or-cancel",
      displayCriteria: showModifyReservationButton && !!showCancelBooking,
      text: "Modify trip",
    },
    {
      action: handleExtendTrip,
      dataTestId: "extend-reservation",
      displayCriteria: showExtendReservationAction,
      text: "Extend reservation",
    },
    {
      action: toggleLateCheckInModal,
      dataTestId: "late-check-in",
      displayCriteria: showLateCheckInAction && !hasLateCheckInRequest,
      text: "Late check-in",
    },
    {
      action: handleDocument,
      dataTestId: "action-view-folio",
      displayCriteria: !isSot,
      text: "View folio",
      isLoading: isLoadingFolio,
    },
    {
      action: handleModifyReservation,
      dataTestId: "modify-or-cancel",
      displayCriteria: showModifyReservationButton && !showCancelBooking,
      text: "Modify trip",
    },
    {
      action: () => handleSetActiveModification(ModificationTypes.Cancel),
      dataTestId: "cancel-booking",
      displayCriteria: showCancelBooking,
      text: "Need to cancel?",
    },
  ];

  return (
    <>
      <ActionButtonLayout showFlexMessage={showFlexMessage} renderList={buttonList} />
      <LateCheckInModal
        contract={itinerary}
        onClose={toggleLateCheckInModal}
        visible={showLateCheckInModal}
        refetchReservation={() => refetchReservation()}
      />
      {!!extendTrip && (
        <TripExtensions requestedModalStep={modalStep} setIsSearching={setExtendTrip} />
      )}
    </>
  );
};

export default UpcomingActiveActions;
