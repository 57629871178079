import type { Dispatch, SetStateAction } from "react";
import { useEffect } from "react";

import { ampli } from "ampli";

import { usePrevious } from "@hotel-engine/hooks";
import { useAppSelector } from "store/hooks";
import { selectSearchId } from "store/Search/SearchResults/selectors";

type TrackerMapZoomUpdateParam = {
  map: google.maps.Map;
  isSearchResults: boolean | undefined;
  setZoomControlClicked: Dispatch<SetStateAction<boolean>>;
  zoomControlClicked: boolean;
};

export function useTrackMapZoomUpdate({
  map,
  isSearchResults,
  setZoomControlClicked,
  zoomControlClicked,
}: TrackerMapZoomUpdateParam) {
  const currentMapZoom = map?.get("zoom");
  const previousMapZoom = usePrevious(currentMapZoom);
  const searchId = useAppSelector(selectSearchId) ?? undefined;

  useEffect(() => {
    if (currentMapZoom !== undefined && previousMapZoom !== currentMapZoom && isSearchResults) {
      ampli.zoomMapUpdate({
        searchId,
        isZoomControl: zoomControlClicked,
        zoomDifference: currentMapZoom - (previousMapZoom ?? 0),
      });

      if (zoomControlClicked) {
        setZoomControlClicked(false);
      }
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMapZoom, previousMapZoom]);
}
