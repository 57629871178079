import styled from "styled-components";
import { flexbox } from "../../styles/helpers/mixins";
import { sizes } from "../../styles";

export const StaticImageWrapper = styled.div<{ $isMobile: boolean }>`
  position: relative;
  flex: 1 1 0;
  height: auto;
  overflow: hidden;
  ${flexbox({
    alignItems: "center",
    justifyContent: "center",
  })};

  @media (min-width: ${sizes.breakpoints.xl}) {
    min-height: 100%;
  }
`;

export const StaticImage = styled.img`
  width: 100%;
  height: auto;

  @media (min-width: ${sizes.breakpoints.xl}) {
    max-width: calc(100vw - 540px);
  }
`;

export const MarkerWrapper = styled.div`
  position: absolute;
`;
