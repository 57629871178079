import { Icon, Typography } from "@hotelengine/atlas-web";
import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import { useFlightModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";

import { useAirportInfo } from "pages/Flights/hooks/useAirportInfo";
import { useCityInfo } from "pages/Flights/hooks/useCityInfo";
import * as Styled from "./styles";

const SlicesHeader = () => {
  const { isPreview, typographyVariants } = useTypographyScaling();
  const { reservation } = useFlightModificationsContext();
  const { getIataCityCodeByIataAirportCode } = useAirportInfo();

  const { getCityNameByIataCityCode } = useCityInfo();

  if (!reservation) return null;

  const isOneWay = reservation.slices.length === 1;
  // This will change slightly w multi city but will work for now and ultimately get replaced with flightType from the reservation
  const isRoundTrip = reservation.slices.length === 2;

  const lastSlice = reservation.slices[reservation.slices.length - 1];
  const lastSegment = lastSlice.segments[lastSlice.segments.length - 1];
  const arrivalCityBase = isRoundTrip ? lastSlice.segments[0].origin : lastSegment.destination;

  const departureCityCode = getIataCityCodeByIataAirportCode(
    reservation?.slices[0].segments[0].origin.airportCode
  );
  const departureCity = getCityNameByIataCityCode(departureCityCode);
  const arrivalCityCode = getIataCityCodeByIataAirportCode(arrivalCityBase.airportCode);
  const arrivalCity = getCityNameByIataCityCode(arrivalCityCode);

  return (
    <Styled.Container>
      <Styled.DepartureContainer>
        <Typography
          variant={typographyVariants.largeTitle}
          color="foregroundPrimary"
          data-testid="departure-city"
          style={{ textAlign: "start" }}
        >
          {departureCity}
        </Typography>
      </Styled.DepartureContainer>
      <Styled.ArrowContainer>
        <Icon
          size={isPreview ? "sm" : "md"}
          name={isOneWay ? "arrow-right" : "arrow-right-arrow-left"}
          color="foregroundPrimary"
        />
      </Styled.ArrowContainer>
      <Styled.ArrivalContainer>
        <Typography
          variant={typographyVariants.largeTitle}
          color="foregroundPrimary"
          data-testid="arrival-city"
          style={{ textAlign: "end" }}
        >
          {arrivalCity}
        </Typography>
      </Styled.ArrivalContainer>
    </Styled.Container>
  );
};

export default SlicesHeader;
