import { useEffect } from "react";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";

import config from "config";
import { ampli } from "ampli";
import type { Ampli } from "ampli";

export const getSessionId = (_: Ampli) => (_.isLoaded ? _.client?.getSessionId() : undefined);

const ALLOWED_ENVS = ["production", "staging", "development"];

export function useInitSentry() {
  const amplitudeSessionId = getSessionId(ampli);

  useEffect(() => {
    Sentry.setContext("amplitude", { sessionId: amplitudeSessionId });
  }, [amplitudeSessionId]);

  useEffect(() => {
    Sentry.init({
      dsn: ALLOWED_ENVS.includes(config.stage) ? config.sentryClientDsn : "",
      environment: config.stage,
      release: config.release,
      integrations: [new BrowserTracing()],
      ignoreErrors: [
        "ResizeObserver loop limit exceeded",
        "ResizeObserver loop completed with undelivered notifications.", // https://hotelengine.atlassian.net/wiki/spaces/DEVELOPMEN/pages/1814986893
      ],
      denyUrls: [/abtasty\.com/i], // Should no longer be an issue, as abtasty has been removed. But keeping for defensive sake
    });

    return () => {
      (async () => Sentry.close(2000))();
    };
  }, []);
}
