import { useState } from "react";

import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";

import { Modal } from "@hotel-engine/common/Modal";
import { routes } from "@hotel-engine/constants";
import { useBreakpoint, useSearchParams } from "@hotel-engine/hooks";
import { useAccountMode } from "@hotel-engine/react-query/users/useAccountMode";
import { toggleSalesForceChat } from "@hotel-engine/scripts/hooks";
import { User } from "@hotel-engine/services";
import { EnterVerificationCode } from "pages/PersonalTravel/PersonalTravel2FV/EnterVerificationCode";
import { VerifyTwoFactorStep } from "pages/PersonalTravel/PersonalTravel2FV/VerifyTwoFactor";
import { AuthActions } from "store/Auth/AuthRedux";
import { useAppDispatch } from "store/hooks";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";

import type { PersonalAccountCreationStatus } from "../../../PersonalTravelLanding";
import {
  ConfirmEmailMessageStep,
  PackYourBagsStep,
  SignUpOptionsStep,
  SignUpWithEmailStep,
} from "./Steps";
import ClaimYourPersonalAccount from "./Steps/ClaimYourPersonalAccount";
import * as Styled from "./styles";
import { Unsafe } from "@hotel-engine/data";
import SignUp from "../SignUp";

type TriggerBy = "PERSONAL_ACCOUNT_BANNER" | "HEADER_SETTINGS";

export interface IPersonalAccountCreationProps {
  visible: boolean;
  onClose: () => void;
  startAt?: IPersonalAccountCreationScreen;
  triggeredBy: TriggerBy;
}

/**
 *                                       -> sign-up-with-email -> confirm-email-message
 * value-proposition -> sign-up-options
 *                                       -> confirm-email-message
 */
export type IPersonalAccountCreationScreen =
  | "value-proposition"
  | "sign-up-options"
  | "sign-up-with-email"
  | "confirm-email-message"
  | "request-2fv-code"
  | "submit-2fv-code"
  | "sign-up";

export const PersonalAccountCreationModal = ({
  visible,
  onClose,
  startAt,
  triggeredBy,
}: IPersonalAccountCreationProps) => {
  const params = useSearchParams<{
    confirmPersonal2fv?: boolean;
    launchPersonalFlow?: PersonalAccountCreationStatus;
  }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isPersonalPromotion = useIsFeatureFlagOn("personal-account-promotion");

  const [, toggleAccountMode] = useAccountMode({
    onFailure: () => navigate(routes.dashboard.concat(`?toggleAccount=personal`)),
  });
  const isMobile = useBreakpoint("md", "max");
  const [screen, setScreen] = useState<IPersonalAccountCreationScreen>(
    startAt || "value-proposition"
  );
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const onCloseModal = async (escapeToBusiness?: boolean) => {
    if (escapeToBusiness) {
      toggleAccountMode().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    }

    if (
      params?.confirmPersonal2fv &&
      (screen === "request-2fv-code" || screen === "submit-2fv-code")
    ) {
      // refetch user to reflect latest updates //
      await User.getSelf();
      dispatch(AuthActions.setUser(User.current()));
      // Stops the 2FV modal from re-rendering //
      navigate("/", { replace: true });
    }

    Modal.destroyAll();
    onClose();
    setScreen(startAt || "value-proposition");
  };

  if (isMobile && visible) {
    toggleSalesForceChat(false);
  } else {
    toggleSalesForceChat(true);
  }

  const showNewSignUpFlow = params.launchPersonalFlow === "default" || !params.launchPersonalFlow;

  const handlePushToSignUp = () => setScreen("sign-up");

  const renderSteps = () => {
    const isPersonalAccountBanner = triggeredBy === "PERSONAL_ACCOUNT_BANNER";
    /**
     * Remove "sign-up-options" and "sign-up-with-email" case step from the below switch
     * when FF "personal-account-promotion" is 100% rolled up! FEN-7211.
     */
    const currentStep =
      isPersonalPromotion && isPersonalAccountBanner && screen === "value-proposition"
        ? "sign-up"
        : screen;

    switch (currentStep) {
      case "value-proposition":
        return isPersonalPromotion ? (
          <ClaimYourPersonalAccount onContinue={handlePushToSignUp} />
        ) : (
          <PackYourBagsStep
            onClose={onCloseModal}
            toggleAccountMode={toggleAccountMode}
            goSignUpOptions={() => setScreen("sign-up-options")}
            personalCTAStatus={params.launchPersonalFlow ?? "default"}
          />
        );
      case "sign-up":
        return showNewSignUpFlow ? (
          <SignUp onNavigate={setScreen} onClose={onCloseModal} onSetEmail={setEmail} />
        ) : (
          <PackYourBagsStep
            onClose={onCloseModal}
            toggleAccountMode={toggleAccountMode}
            goSignUpOptions={() => setScreen("sign-up-options")}
            personalCTAStatus={params.launchPersonalFlow ?? "default"}
          />
        );
      case "sign-up-options":
        return (
          <SignUpOptionsStep
            onClose={onCloseModal}
            goContinueWithEmail={() => setScreen("sign-up-with-email")}
          />
        );
      case "sign-up-with-email":
        return (
          <SignUpWithEmailStep
            onClose={onCloseModal}
            onGoBack={() => setScreen("sign-up-options")}
            goConfirmEmailMessage={(myEmail: string) => {
              setEmail(myEmail);
              setScreen("confirm-email-message");
            }}
          />
        );
      case "confirm-email-message":
        return <ConfirmEmailMessageStep email={email} />;
      case "request-2fv-code":
        return (
          <VerifyTwoFactorStep
            onSubmit={(submittedPhone) => {
              setPhone(submittedPhone);
              setScreen("submit-2fv-code");
            }}
            isInModal
            onClose={onCloseModal}
          />
        );
      case "submit-2fv-code":
        return (
          <EnterVerificationCode
            phone={phone}
            isInModal
            onClose={onCloseModal}
            onGoBack={() => setScreen("request-2fv-code")}
          />
        );
      default:
        return null;
    }
  };

  if (isMobile) {
    return (
      <>
        <Styled.GlobalBottomSheetStyles />
        <BottomSheet
          onDismiss={onClose}
          open={visible}
          aria-label="personal-account-creation-bottom-sheet"
          style={{ zIndex: 999, position: "absolute" }}
        >
          <Styled.BottomSheetContent>{renderSteps()}</Styled.BottomSheetContent>
        </BottomSheet>
      </>
    );
  }

  return (
    <Styled.PersonalAccountCreationModal
      $screen={screen}
      visible={visible}
      closable={false}
      maskClosable
      destroyOnClose
      onCancel={() => onCloseModal(false)}
      footer={null}
      aria-label="personal-account-creation-modal"
    >
      {renderSteps()}
    </Styled.PersonalAccountCreationModal>
  );
};
