import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments/FeatureFlag";
import { useAppSelector } from "store/hooks";

export const useIsCarsEnabled = () => {
  const isCarsEnabled = useIsFeatureFlagOn("cars-search-shop");
  const user = useAppSelector((store) => store.Auth.user);
  const hideCars = user?.business.hideCars;

  return isCarsEnabled && !hideCars;
};
