import { useTranslation } from "react-i18next";

export const useAirlineSupportUrl = () => {
  const { t } = useTranslation("iata_airline_support_url");

  // If we have a miss, return an empty string so that we can correctly fall back to the homepage
  return {
    getAirlineSupportUrlFromIataAirlineCode: (iataAirlineKey: string) =>
      t(`${iataAirlineKey}`, { defaultValue: "" }),
  };
};
