import CancellationPolicy from "./CancellationPolicy";
import NeedHelp from "./NeedHelp";
import PolicyInformation from "./PolicyInformation";

const HelpAndPolicies = () => {
  return (
    <>
      <NeedHelp />
      <PolicyInformation />
      <CancellationPolicy />
    </>
  );
};

export default HelpAndPolicies;
