import styled from "styled-components";
import { Button, RadioGroup } from "@hotelengine/atlas-web";

export const ReasonGroup = styled(RadioGroup)`
  width: 100%;
`;

export const ActionButton = styled(Button)`
  flex: 1;
`;
