import styled from "styled-components";

export const FieldLine = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const Divider = styled.div`
  height: 1px;
  border-top: 1px dashed ${({ theme }) => theme.colors.borderSubtle};
`;
