import { useState } from "react";
import { ampli } from "ampli";

import { Button } from "@hotelengine/atlas-web";
import { useAppSelector } from "store/hooks";

import { useCarModificationsContext } from "../../../ModificationsContext";
import * as Styled from "./styles";
import ManageTripModal from "./ManageTripModal";

const ManageTrip = () => {
  const user = useAppSelector((state) => state.Auth?.user);
  const {
    isPreview,
    reservation,
    state: { modificationView },
  } = useCarModificationsContext();
  const [isManageTripOpen, setIsManageTripOpen] = useState(false);

  const openManageTripModal = () => {
    setIsManageTripOpen(true);

    ampli.clickManageTrip({
      userId: user?.id,
      flightBookingID: reservation?.bookingNumber,
    });
  };

  const closeManageTripModal = () => {
    setIsManageTripOpen(false);
  };

  return (
    <>
      {!modificationView && (
        <>
          <Styled.ManageTripContainer $isPreview={isPreview}>
            <Button style={{ width: "100%" }} size="xl" onClick={openManageTripModal}>
              Manage trip
            </Button>
          </Styled.ManageTripContainer>
          {!!isManageTripOpen && (
            <ManageTripModal
              onCancel={closeManageTripModal}
              rentalCompany={reservation.rentalCompany}
            />
          )}
        </>
      )}
    </>
  );
};

export default ManageTrip;
