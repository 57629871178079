import { useAppSelector } from "store/hooks";
import { useAccountMode } from "@hotel-engine/react-query/users/useAccountMode";

import * as Styled from "./styles";
import { Unsafe } from "@hotel-engine/data";

export type ISwitchButtonOptions = "personal" | "business";

interface ISwitchPACButtonProps {
  onSwitchFail?: () => void;
  onSwitch: (accountType: "personal" | "business") => void;
  $visualRefreshActive?: boolean;
}

export const SwitchPACButton = ({
  onSwitchFail,
  onSwitch,
  $visualRefreshActive,
}: ISwitchPACButtonProps) => {
  const accountType = useAppSelector((state) => state.Auth?.user?.accountType);
  const [, toggleAccountMode] = useAccountMode({ onFailure: onSwitchFail });

  if (!accountType) {
    return null;
  }

  const handleAccountSwitch = () => {
    onSwitch(accountType === "business" ? "personal" : "business");
    toggleAccountMode().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
  };

  return (
    <>
      <Styled.SwitchButton
        variant={$visualRefreshActive ? "plain" : "outlined"}
        aria-label={"switch-between-personal-and-business"}
        data-testid="switch-between-personal-and-business"
        onClick={handleAccountSwitch}
      >
        Switch to {accountType === "business" ? "Personal" : "Business"}
      </Styled.SwitchButton>
    </>
  );
};
