import type { IFlightItinerarySlice } from "@hotel-engine/types/itinerary";
import { Box, Typography } from "@hotelengine/atlas-web";

import FlightSlice from "./components/FlightSlice";

export const getFlightSlices = (slices: IFlightItinerarySlice[]) => {
  const flightSlices = slices.map((slice, i) => {
    const isLastSlice = i === slices.length - 1;

    return (
      <Box key={slice.segments[0].origin.flightTime} display="flex" flexDirection="column" gap={8}>
        {i === 0 && (
          <Typography variant="body/xs-strong" color="foregroundSecondary">
            FLIGHTS
          </Typography>
        )}
        <FlightSlice slice={slice} isLastSlice={isLastSlice} sliceIndex={i} />
      </Box>
    );
  });

  return flightSlices;
};
