import { useEffect, useState } from "react";

import { FixedSizeList } from "react-window";

import { Select, SelectOption } from "@hotel-engine/common/Select";

import Button from "@hotel-engine/common/Button";
import { Checkbox } from "@hotel-engine/common/Checkbox";
import { Dropdown } from "@hotel-engine/common/Dropdown";
import type { SliderValue } from "@hotel-engine/common/Slider";
import { Slider } from "@hotel-engine/common/Slider";
import { useDebounce, usePrevious } from "@hotel-engine/hooks";
import { useDepartmentsQuery } from "@hotel-engine/react-query/departments/useDepartmentsQuery";
import type { IDepartmentRecord } from "@hotel-engine/types/department";
import type { IUser } from "@hotel-engine/types/user";

import type { IFilters, TTripsDispatchContext } from "pages/Trips/contextLegacy";
import { sortCalendarOptions } from "pages/Trips/TripsCalendarView/helpers";
import * as Styled from "./styles";
import { Icon } from "@hotel-engine/common/Icon";

interface IFiltersDropdownProps {
  filters: IFilters;
  handleFilterSelection: (e: unknown, value?: unknown | unknown[]) => void;
  isCalendarView: boolean;
  mostExpensiveTrip: number;
  tripsDispatch: TTripsDispatchContext;
  userRole: IUser["role"];
  showCheckoutVerification: boolean;
}

export const FiltersDropdown = ({
  filters,
  handleFilterSelection,
  isCalendarView,
  mostExpensiveTrip,
  tripsDispatch,
  userRole,
  showCheckoutVerification,
}: IFiltersDropdownProps) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [minValue, setMinValue] = useState(0);
  const [displayMin, setDisplayMin] = useState(0);
  const [displayMax, setDisplayMax] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [defaultMax, setDefaultMax] = useState(0);
  const [departmentSearchValue, setDepartmentSearchValue] = useState("");
  const [filteredDepartments, setFilteredDepartments] = useState<IDepartmentRecord[]>([]);
  const userIsAdmin = userRole === "admin";
  // Only admin level users are authorized to use the departments endpoint
  const { data: departmentsData } = useDepartmentsQuery({
    options: { enabled: userIsAdmin },
  });

  useEffect(() => {
    /** Filter department list by search value */
    if (!!departmentsData) {
      setFilteredDepartments(
        departmentsData.filter(
          (department) => !!department.name.match(new RegExp(departmentSearchValue, "gi"))
        )
      );
    }
  }, [departmentsData, departmentSearchValue]);

  const debouncedMin = useDebounce(minValue, 1000);
  const debouncedMax = useDebounce(maxValue, 1000);
  const prevMin = usePrevious(debouncedMin);
  const prevMax = usePrevious(debouncedMax);

  useEffect(() => {
    if (
      (prevMin !== undefined && debouncedMin !== prevMin) ||
      (prevMax !== undefined && debouncedMax !== prevMax)
    ) {
      tripsDispatch({
        type: "filter",
        filters: [
          { key: "totalGt", value: minValue },
          { key: "totalLt", value: maxValue || defaultMax },
        ],
      });
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedMin, debouncedMax]);

  useEffect(() => {
    setDisplayMin(filters.totalGt || 0);
    setDisplayMax(filters.totalLt || defaultMax);
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.totalGt, filters.totalLt]);

  useEffect(() => {
    if (!defaultMax) {
      setDefaultMax(mostExpensiveTrip);
      setDisplayMax(mostExpensiveTrip);
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mostExpensiveTrip]);

  const handleMinMaxInput = ({ name, value }) => {
    if (name === "min") {
      setMinValue(value || 0);
    }

    if (name === "max") {
      setMaxValue(value || mostExpensiveTrip);
    }
  };

  const handleSliderChange = (value: SliderValue) => {
    if (Array.isArray(value)) {
      const [min, max] = value;
      if (prevMin !== min) {
        setMinValue(min);
        setDisplayMin(min);
      }

      if (prevMax !== max) {
        setMaxValue(max);
        setDisplayMax(max);
      }
    }
  };

  const handleAfterSliderChange = (value: SliderValue) => {
    if (Array.isArray(value)) {
      const [min, max] = value;
      tripsDispatch({
        type: "filter",
        filters: [
          { key: "totalGt", value: min },
          { key: "totalLt", value: max },
        ],
      });
    }
  };

  const DepartmentFilterRow = ({ data, index, style }) => {
    if (!data) return null;
    const department = data[index];
    const selected = filters.department?.includes(department.name);

    return (
      <Styled.DepartmentButton
        data-testid={`departmentFilter-${department.name}`}
        key={`department-${index}`}
        onClick={(e) => handleFilterSelection({ ...e, key: "department" }, [department.name])}
        style={style}
      >
        <Checkbox aria-labelledby={department.id} checked={selected} />
        <Styled.CheckboxLabel id={department.id} className="dropdown-checkbox-label">
          {department.name}
        </Styled.CheckboxLabel>
      </Styled.DepartmentButton>
    );
  };

  const departmentHeight = 28;
  const departmentContainerHeight =
    filteredDepartments.length < 5 ? filteredDepartments.length * departmentHeight : 140;

  const filterMenu = (
    <Styled.FilterMenu>
      {isCalendarView ? (
        /** Show the sort options only in Calendar View */
        <Styled.DropdownMenuSection>
          <h3>Sort By</h3>
          <Select
            aria-label="Sort Trip Results"
            data-testid="calendar-sort"
            placeholder="First Name: A-Z"
            style={{ width: "100%" }}
            onChange={(val) =>
              tripsDispatch({
                type: "sortCalendar",
                sortCalendar: `${val}`,
              })
            }
          >
            {sortCalendarOptions.map(({ label, sort }) => (
              <SelectOption data-testid={sort} value={sort} key={label}>
                {label}
              </SelectOption>
            ))}
          </Select>
        </Styled.DropdownMenuSection>
      ) : null}
      <Styled.DropdownMenuSection>
        <h3>Price Range</h3>
        <Styled.PriceRange>
          <Styled.InputNumber
            data-testid="min"
            name="min"
            type="number"
            value={displayMin}
            onChange={(value) => handleMinMaxInput({ name: "min", value })}
          />
          <Styled.InputNumber
            data-testid="max"
            name="max"
            type="number"
            value={displayMax}
            onChange={(value) => handleMinMaxInput({ name: "max", value })}
          />
        </Styled.PriceRange>
        <Slider
          range
          step={1}
          min={0}
          max={defaultMax}
          value={[displayMin, displayMax]}
          onChange={handleSliderChange}
          onAfterChange={handleAfterSliderChange}
        />
      </Styled.DropdownMenuSection>
      <Styled.DropdownMenuSection>
        <h3>Trip Type</h3>
        <Styled.SubMenu>
          <Styled.TripTypeItem key="pendingModificationOnly" onClick={handleFilterSelection}>
            <Checkbox
              checked={!!filters.pendingModificationOnly}
              aria-labelledby="pendingModificationFilter"
            />
            <Styled.CheckboxLabel id="pendingModificationFilter">
              Pending Modification
            </Styled.CheckboxLabel>
          </Styled.TripTypeItem>
          <Styled.TripTypeItem key="contractType" onClick={handleFilterSelection}>
            <Checkbox checked={!!filters.contractType} aria-labelledby="groupBookingsFilter" />
            <Styled.CheckboxLabel id="groupBookingsFilter">
              Group Bookings Only
            </Styled.CheckboxLabel>
          </Styled.TripTypeItem>
          <Styled.TripTypeItem key="refundableOnly" onClick={handleFilterSelection}>
            <Checkbox checked={!!filters.refundableOnly} aria-labelledby="refundableFilter" />
            <Styled.CheckboxLabel id="refundableFilter">Refundable</Styled.CheckboxLabel>
          </Styled.TripTypeItem>
          {!!showCheckoutVerification && (
            <Styled.TripTypeItem key="unverified" onClick={handleFilterSelection}>
              <Checkbox checked={!!filters.unverified} aria-labelledby="unverifiedTripsFilter" />
              <Styled.CheckboxLabel id="unverified">Unverified Checkout</Styled.CheckboxLabel>{" "}
            </Styled.TripTypeItem>
          )}
          <Styled.TripTypeItem key="manual_bookings" onClick={handleFilterSelection}>
            <Checkbox checked={!!filters.manual_bookings} aria-labelledby="manual_bookingsFilter" />
            <Styled.CheckboxLabel id="manual_bookingsFilter">
              Assisted Bookings
            </Styled.CheckboxLabel>
          </Styled.TripTypeItem>
        </Styled.SubMenu>
      </Styled.DropdownMenuSection>
      {!!userIsAdmin && (
        <Styled.DropdownMenuSection>
          <Styled.DropdownSectionTitle>
            <h3>Departments</h3>
            {!!filters.department?.length && (
              <Styled.ClearButton
                onClick={(e) => {
                  handleFilterSelection({ ...e, key: "department" }, filters.department);
                  setDepartmentSearchValue("");
                }}
                className="dropdown-clear-button"
              >
                Clear
              </Styled.ClearButton>
            )}
          </Styled.DropdownSectionTitle>
          <Styled.SubMenu>
            {!!(filteredDepartments.length > 5 || departmentSearchValue) && (
              <Styled.DepartmentSearch
                data-testid="departmentSearch"
                onChange={(e) => setDepartmentSearchValue(e.target.value)}
                placeholder="Search"
                value={departmentSearchValue}
              />
            )}
            <FixedSizeList
              height={departmentContainerHeight}
              itemCount={filteredDepartments.length}
              itemData={filteredDepartments}
              itemKey={(index, data) => `${data[index].id}-${index}`}
              itemSize={departmentHeight}
              overscanCount={30}
              width={"100%"}
            >
              {DepartmentFilterRow}
            </FixedSizeList>
          </Styled.SubMenu>
        </Styled.DropdownMenuSection>
      )}
    </Styled.FilterMenu>
  );

  return (
    <Dropdown
      overlay={filterMenu}
      trigger={["click"]}
      visible={dropdownVisible}
      onVisibleChange={(flag) => setDropdownVisible(flag)}
      className="filters-dropdown"
    >
      <Button id="trips_filterDropdown" type="ghost">
        Filters
        <Icon icon={["fas", "sliders"]} className="icon" />
      </Button>
    </Dropdown>
  );
};
