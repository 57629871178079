import { ampli } from "ampli";

import { Box, Checkbox, Typography } from "@hotelengine/atlas-web";
import { useAppSelector } from "store/hooks";

import { useTripsContext } from "../../../../context";

const TripGroups = () => {
  const user = useAppSelector((state) => state.Auth?.user);
  const { state: tripsState, dispatch: tripsDispatch } = useTripsContext();
  const { filters, canViewOthersTrips } = tripsState;

  if (!user) return null;

  const handleOnlyMyTrips = () => {
    if (user) {
      ampli.clickOnlyMyTripsCheckbox({
        selectedFilter: !filters.onlyMyTrips,
        userId: user.id,
      });
    }

    tripsDispatch({
      type: "filter",
      filters: [{ key: "onlyMyTrips", value: true }],
    });
  };

  const handleOnlyCancelledTrips = () => {
    tripsDispatch({
      type: "filter",
      filters: [{ key: "onlyCancelledTrips", value: true }],
    });
  };

  return (
    <Box>
      <Typography variant="uppercase/xs-strong" color="foregroundPrimary">
        Trip details
      </Typography>
      <Box display="flex" flexDirection="column" gap={8} marginTop={8}>
        {!!canViewOthersTrips && (
          <Checkbox
            onCheckedChange={handleOnlyMyTrips}
            isChecked={!!filters.onlyMyTrips}
            data-testid="only-my-trips-checkbox"
          >
            Only my trips
          </Checkbox>
        )}
        <Checkbox
          onCheckedChange={handleOnlyCancelledTrips}
          isChecked={!!filters.onlyCancelledTrips}
          data-testid="only-cancelled-trips-checkbox"
        >
          Only cancelled trips
        </Checkbox>
      </Box>
    </Box>
  );
};

export default TripGroups;
