import { Icon } from "@hotel-engine/common/Icon";
import type { TripsFilterStatus } from "pages/Trips/contextLegacy";

import * as Styled from "./styles";

interface INoTripsMessageLegacyProps {
  status: TripsFilterStatus;
}

const NoTripsMessageLegacy = ({ status }: INoTripsMessageLegacyProps) => (
  <Styled.NoTripsMessageLegacy data-testid="no-trips-results">
    <Icon icon={["fal", "suitcase"]} size="3x" />
    <Styled.Headline>{`No ${status === "all" ? "" : status} Trips`}</Styled.Headline>
    <span>{`Any ${status === "all" ? "" : status} trip will display here.`}</span>
  </Styled.NoTripsMessageLegacy>
);

export default NoTripsMessageLegacy;
