import type { KeyboardEvent } from "react";
import type { InputNumberProps } from "antd/lib/input-number";

import * as Styled from "./styles";

type IInputNumber = {
  prefix?: string;
} & InputNumberProps;

const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
  const matchNumberRegex = /^[0-9]+$/;
  const pressedKey = e.key;
  const allowedKeys = [
    "Tab",
    "Backspace",
    "Delete",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
  ];
  // Test if the pressed key is not a number and not an allowed key
  if (!matchNumberRegex.test(pressedKey) && !allowedKeys.includes(pressedKey)) {
    // Prevent the default behavior of the event
    e.preventDefault();
    // Return false to stop the input from changing
    return false;
  }
  // Return true to allow the input to change
  return true;
};

export const InputNumber = (props: IInputNumber) => {
  return (
    <Styled.InputNumberContainer>
      {!!props.prefix && <Styled.Prefix>{props.prefix}</Styled.Prefix>}
      <Styled.InputNumber onKeyDown={onKeyDown} {...props} />
    </Styled.InputNumberContainer>
  );
};
