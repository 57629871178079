import styled, { createGlobalStyle, css } from "styled-components";

import { Modal } from "@hotel-engine/common/Modal";
import { Box, type BoxProps } from "@hotelengine/atlas-web";

export const GlobalBottomSheetStyles = createGlobalStyle`
  [data-rsbs-overlay] {
    z-index: ${({ theme }) => theme.zIndex.modal}
  }
`;

const BottomSheetContentContainer = styled<React.FC<BoxProps>>(Box)`
  text-align: left;
  padding: ${({ theme }) => `${theme.spacing[24]} ${theme.spacing[16]}`};
  margin-bottom: ${({ theme }) => theme.spacing[6]};

  .ant-btn {
    height: ${({ theme }) => theme.spacing[48]};
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius[12]};
    text-transform: capitalize;

    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing[16]};
    }
  }
`;

const WebModal = styled(Modal)`
  text-align: left;
  margin-bottom: 200px;

  ${({ theme }) => {
    return css`
      @media screen and (max-width: ${`${theme.breakpoints.md}`}) {
        .ant-modal-body {
          padding: ${({ theme: t }) => `${t.spacing[32]} ${t.spacing[16]} !important`};
        }
      }
    `;
  }}

  .ant-modal-header > .ant-modal-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: ${({ theme }) => theme.colors.foregroundPrimary};
  }
`;

const WebModalRefreshed = styled(Modal)`
    &.ant-modal {
        padding-bottom: 0 !important;
    }

  ${({ theme }) => {
    return css`
      @media screen and (max-width: ${`${theme.breakpoints.md}`}) {
        .ant-modal-body {
          padding: ${({ theme: t }) => `${t.spacing[32]} ${t.spacing[16]} !important`};
        }
      }
    `;
  }}
  .ant-modal-header > .ant-modal-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
  }
	${({ theme }) => `
		background-color: ${theme.colors.backgroundPrimary};
    border: solid ${theme.borderWidth.normal} ${theme.colors.borderMinimal};
    border-radius: ${theme.borderRadius.lg};
    overflow: hidden;
  `}
	.ant-modal-header {
		margin: ${({ theme }) => theme.spacing[6]};
        padding-bottom: ${({ theme }) => theme.spacing[0]};
	}
	.ant-modal-content {
		box-shadow: initial;
	}
	.ant-modal-body {
		padding: ${({ theme }) => theme.spacing[32]} ${({ theme }) => theme.spacing[32]} ${({
      theme,
    }) => theme.spacing[0]} ${({ theme }) => theme.spacing[32]};
	}
`;

export { BottomSheetContentContainer, WebModal, WebModalRefreshed };
