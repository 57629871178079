import type { IFlightItinerarySlice } from "@hotel-engine/types/itinerary";
import moment from "moment";

export const useIsWithin24HrsOfFlight = (slice: IFlightItinerarySlice) => {
  const isWithin24HoursOfFlight = moment().isAfter(
    moment(slice.segments[0].origin.flightTime).subtract(24, "hours")
  );
  const isNotAfterStartOfFlight = moment().isBefore(moment(slice.segments[0].origin.flightTime));
  const isAfterStartOfFlight = moment().isAfter(moment(slice.segments[0].origin.flightTime));
  const isWithinCheckInWindow = isWithin24HoursOfFlight && isNotAfterStartOfFlight;
  const isTooLateForCancellation = isWithin24HoursOfFlight || isAfterStartOfFlight;

  return { isWithinCheckInWindow, isTooLateForCancellation };
};
