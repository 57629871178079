import { useCarModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { CollapsibleContent } from "@hotel-engine/app/ItineraryContent/shared/CollapsibleContent";
import ResponsiveAtlasDialog from "@hotel-engine/app/ResponsiveAtlasDialog";
import { Box, Button, Typography } from "@hotelengine/atlas-web";
import { sanitizeHtml } from "@hotel-engine/utilities";
import { useBreakpoint } from "@hotel-engine/hooks";

import * as Styled from "./styles";
import { RentalCompanyImage } from "../../../styles";
import { ampli } from "ampli";
import { useAppSelector } from "store/hooks";

const PolicyInformation = () => {
  const user = useAppSelector((state) => state.Auth.user);
  const isMobile = useBreakpoint("md", "max");
  const { reservation } = useCarModificationsContext();
  const rentalCompany = reservation?.rentalCompany;
  const showArrayPolicies = !!reservation?.policyInformations?.length;

  const content = (
    <Box marginTop={24} marginBottom={isMobile ? 12 : 0}>
      {!rentalCompany?.logo ? (
        <Typography variant="body/md-strong" color="foregroundPrimary">
          {rentalCompany?.name}
        </Typography>
      ) : (
        <RentalCompanyImage src={rentalCompany?.logo} $size={146} width={146} />
      )}
      <Typography variant="body/xs-strong" color="foregroundPrimary">
        {showArrayPolicies ? (
          reservation?.policyInformations?.map((policy) => (
            <>
              <Typography variant="heading/md" color="foregroundPrimary" as="h2" marginTop={16}>
                {policy.textNotice.title.slice(0, 1).toUpperCase()}
                {policy.textNotice.title.slice(1)}
              </Typography>
              <Styled.PolicyDescriptionContainer
                marginTop={false}
                key={policy.textNotice.title}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(policy.textNotice.text),
                }}
              />
            </>
          ))
        ) : (
          <Styled.PolicyDescriptionContainer
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(reservation.policyInformation || ""),
            }}
          />
        )}
      </Typography>
    </Box>
  );

  const dialogSheetWrapper = (
    <ResponsiveAtlasDialog
      title="View rental policy"
      content={content}
      triggerComponent={
        <Button variant="outlined" size="lg">
          View rental policy
        </Button>
      }
      isModal
    />
  );

  return (
    <CollapsibleContent
      variant="sm"
      toggleTestID="policy-information"
      toggleBtnLabel={<Typography variant="heading/lg">Policy Information</Typography>}
      onChangeState={() => {
        if (user) {
          ampli.clickPolicyInformation({
            carBookingID: reservation.bookingNumber,
            userId: user.id,
          });
        }
      }}
    >
      <Box display="flex" flexDirection="column" gap={16} padding={16}>
        <Typography variant="body/md" color="foregroundPrimary">
          Please review the policies and terms applicable to your rental car agreement.
        </Typography>
        {dialogSheetWrapper}
      </Box>
    </CollapsibleContent>
  );
};

export default PolicyInformation;
