// eslint-disable-next-line no-control-regex
const CHAR_CONTROL_REGEX = /[\u0000\u000A\u000D]/g;
const EXTRA_SLASHES_REGEX = /\/+/g;
const BACK_SLASHES_REGEX = /\\+/g;
const TRAVERSAL_REGEX = /(\.|%2E)+[/\\]+/g;
const REMAINING_TRAVERSAL_REGEX = /(^|\/)\.\.\/+/g;

function safeInputDecode(input: string) {
  try {
    return decodeURIComponent(input);
  } catch (exception) {
    return input;
  }
}

function preventPathTraversal(input: string) {
  const decodedInput = safeInputDecode(input);
  const sanitizedInput = decodedInput
    .replace(EXTRA_SLASHES_REGEX, "/")
    .replace(BACK_SLASHES_REGEX, "\\")
    .replace(TRAVERSAL_REGEX, "")
    .replace(CHAR_CONTROL_REGEX, "")
    .replace(REMAINING_TRAVERSAL_REGEX, "$1");

  return sanitizedInput;
}

export default preventPathTraversal;
