import { Button, Icon, Typography } from "@hotelengine/atlas-web";
import type { IModifications } from "@hotel-engine/types/reservation";
import { routes, supportCenterRouteSources } from "@hotel-engine/constants";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";

import * as Styled from "./styles";
import { useModificationsLocalStorage } from "./useModificationsLocalStorage";

export interface IModificationStatusesProps {
  modifications: IModifications;
  propertyName: string;
}

const ModificationStatuses = ({ modifications, propertyName }: IModificationStatusesProps) => {
  const navigate = useNavigate();
  const modificationTypes = Object.keys(modifications.all);
  const modificationCount = modificationTypes.reduce((acc, curr) => {
    return (acc += modifications.all[curr]);
  }, 0);

  const { showList, setShowList } = useModificationsLocalStorage(modificationCount);

  const getTypeCopy = (type: string) => {
    const typeCount = modifications.all[type];
    return `${typeCount} ${type}${typeCount > 1 ? "s" : ""}`;
  };

  const goToSupportCenter = () => {
    navigate(
      `${routes.supportCenter}?source=${supportCenterRouteSources.modificationStatuses}&property_query=${propertyName}`
    );
  };

  return (
    <Styled.AlertContainer>
      <Styled.ToggleContainer onClick={() => setShowList(!showList)}>
        <Typography variant="heading/md" color="foregroundPrimary">
          Trip modification requests
        </Typography>
        <Styled.IconHoverContainer>
          <Icon name={showList ? "chevron-up" : "chevron-down"} />
        </Styled.IconHoverContainer>
      </Styled.ToggleContainer>
      {!!showList && (
        <>
          <Styled.SectionDivider />
          <Styled.ListContainer>
            {modificationTypes.map((type) => (
              <Typography variant="body/sm" color="foregroundPrimary" as="li" key={type}>
                {getTypeCopy(type)}
              </Typography>
            ))}
          </Styled.ListContainer>
          <Styled.SectionDivider />
          <Styled.ButtonContainer>
            <Button
              variant="outlined"
              color="secondary"
              style={{ width: "100%" }}
              onClick={goToSupportCenter}
            >
              View in Support Center
            </Button>
          </Styled.ButtonContainer>
        </>
      )}
    </Styled.AlertContainer>
  );
};

export default ModificationStatuses;
