import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import { Typography } from "@hotelengine/atlas-web";
import * as Styled from "./styles";

export const InfoItem = ({
  leftText,
  rightText,
  dataTestId,
}: {
  leftText: string;
  rightText?: string;
  dataTestId?: string;
}) => {
  const { typographyVariants } = useTypographyScaling();

  /** When there is no rightText we treat this as a section header (used for the All rooms title when we don't show
   * the rooms breakdown)
   */
  return (
    <Styled.InfoItemContainer data-testid={dataTestId}>
      <Typography
        variant={!!rightText ? typographyVariants.body : typographyVariants.heading}
        color={!!rightText ? "foregroundSecondary" : "foregroundPrimary"}
      >
        {leftText}
      </Typography>
      {!!rightText && (
        <Typography variant={typographyVariants.body} color="foregroundPrimary">
          {rightText}
        </Typography>
      )}
    </Styled.InfoItemContainer>
  );
};
