import { useQueryClient } from "react-query";

import { endpoints } from "../constants";

export const useRefetchHiddenProperties = () => {
  /**
   * Invalidate the react-query cache, which will trigger the refetch
   */
  const queryClient = useQueryClient();
  const refetchHiddenProperties = () => {
    void queryClient.invalidateQueries(endpoints.hiddenProperties);
  };

  return refetchHiddenProperties;
};
