import type { ICarItineraryCar } from "@hotel-engine/types/itinerary";
import type { IconProps } from "@hotelengine/atlas-web";
import { Box, Icon, Typography } from "@hotelengine/atlas-web";
import { useAcrissInfo } from "pages/Cars/hooks/useAcrissInfo";
import { useTransmission } from "pages/Cars/hooks/useTransmission";
import * as Styled from "./styles";

export interface ICarDetailsProps {
  car: ICarItineraryCar;
}

interface IOptionalCarDetailItemProps {
  label: string;
  icon: IconProps["name"];
  iconColor?: IconProps["color"];
  show: boolean;
}

const OptionalCarDetailItem = ({ label, icon, iconColor, show }: IOptionalCarDetailItemProps) => {
  return !show ? null : (
    <Box display="flex" alignItems="center" gap={8} marginBottom={8}>
      <Icon name={icon} color={iconColor ?? "foregroundPrimary"} />
      <Typography variant="body/sm" color="foregroundPrimary">
        {label}
      </Typography>
    </Box>
  );
};

const CarDetails = ({ car }: ICarDetailsProps) => {
  const { getAcrissCategory } = useAcrissInfo();
  const { getTransmission } = useTransmission();

  if (!car) return null;

  const carVariant = car?.variant ? `${car?.variant} or similar` : "";

  return (
    <Box marginTop={20}>
      <Box display="flex" flexDirection="column" gap={4}>
        <Typography variant="body/md-strong" color="foregroundPrimary">
          {getAcrissCategory(car.type) || "Rental"} car
        </Typography>
        <Typography variant="body/sm" color="foregroundSecondary">
          {carVariant}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop={16}
        marginBottom={16}
      >
        <Styled.CarImage src={car.image} />
      </Box>
      <Box marginBottom={8}>
        <OptionalCarDetailItem
          label={`${car.passengerCapacity} passengers`}
          icon="user-group--solid"
          show={!!car.passengerCapacity}
        />
        <OptionalCarDetailItem
          label={`${car.luggageCapacity} bag${car.luggageCapacity > 1 ? "s" : ""}`}
          icon="suitcase--solid"
          show={!!car.luggageCapacity}
        />
        <OptionalCarDetailItem
          label={getTransmission(car.transmission)}
          icon="gearshift"
          show={!!car.transmission}
        />
        <OptionalCarDetailItem
          label={`${car.unlimitedKm ? "Unlimited mileage" : "Limited mileage"}`}
          icon="check"
          iconColor={car.unlimitedKm ? "accentGreen" : "foregroundPrimary"}
          show={!!car.unlimitedKm}
        />
      </Box>
    </Box>
  );
};

export default CarDetails;
