import type { ILocationRecord } from "@hotel-engine/types/locations";
import type { IWorksite } from "@hotel-engine/types/worksite";

export type LocationType = "name" | "address" | "city" | "worksite" | "property";

/** Determines type of passed location */
export const getLocationType = (location: ILocationRecord | IWorksite): LocationType => {
  const { city, postalCode, streetAddress, types } = location as ILocationRecord;
  const { name } = location as IWorksite;
  const isAddressLocation = types?.length === 1 && types[0] === "address";
  const isWorksiteLocation = name || (types?.length === 1 && types[0] === "worksite");
  const isCityLocation = !city && !postalCode && !streetAddress;

  if (isAddressLocation) {
    return "address";
  } else if (isWorksiteLocation) {
    return "worksite";
  } else if (isCityLocation) {
    return "city";
  } else {
    return "name";
  }
};

/** Return the first line of result information based on type of passed location */
export const getLocationLineOne = (type: LocationType, location: ILocationRecord | IWorksite) => {
  const { locationName, description, streetAddress } = location as ILocationRecord;
  const { name } = location as IWorksite;
  switch (type) {
    case "name":
    case "city":
    case "property":
      return locationName;
    case "worksite":
      return name || description;
    case "address":
      return streetAddress;
  }
};

/** Return the second line of result information based on type of passed location */
export const getLocationLineTwo = (type: LocationType, location: ILocationRecord | IWorksite) => {
  const { streetAddress, city, region } = location as ILocationRecord;
  const { location: worksiteLocation } = location as IWorksite;
  switch (type) {
    case "city":
      return region;
    case "name":
    case "property":
      return [streetAddress, city, region].filter(Boolean).join(", ");
    case "worksite":
      return worksiteLocation || streetAddress;
    case "address":
      return [city, region].filter(Boolean).join(", ");
  }
};

/** Returns locations endpoint result description */
export const getLocationDescription = (location: ILocationRecord | IWorksite) => {
  const locationType = getLocationType(location);

  return {
    lineOne: getLocationLineOne(locationType, location),
    lineTwo: getLocationLineTwo(locationType, location),
  };
};
