import styled, { createGlobalStyle } from "styled-components";

import Button from "@hotel-engine/common/Button";
import Image from "@hotel-engine/common/Image";
import { Table } from "@hotel-engine/common/Table";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors, ellipsisText } from "@hotel-engine/styles";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";

import { TripsPreviewPanel } from "../styles";

export const DesktopContainer = styled.div`
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.md};
  overflow: hidden;
  ${mediaQuery("lg")(`
    display: flex;
  `)}
`;

export const TableContainer = styled.div`
  .Table_table-wrapper {
    padding-bottom: 0;
  }

  ${mediaQuery("lg")(`
    flex: 1;
    flex-basis: 70%;
  `)}
`;

export const RefundabilityContainer = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  margin-right: 4px;
  width: 10.88px;
  height: 12px;
`;

export const RefundabilityText = styled.span`
  line-height: 20px;
`;

export const TableGlobalStyles = createGlobalStyle`
  .ant-table-default {
    border: none;
  }

  .ant-table-small {
    border: none;
  }

  .ant-table-column-title {
    color: ${colors.grey[500]};
  }

  .ant-table-selection-column {
    height: 40px;
    padding: 8px 8px;
    margin: 0;
    background-color: #fff;
    width: 5%;
  }

  .ant-table-pagination.ant-pagination {
    float: left;
  }
`;

export const ListViewTableLegacy = styled(Table)`
  tbody {
    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }

  .hotel-col,
  .location-col,
  .travelers-col,
  .check-in-col,
  .check-out-col,
  .price-col,
  .more-col {
    height: 40px;
    padding: 8px 8px;
    margin: 0;
  }

  .hotel-col {
    width: 32%;
  }

  .location-col {
    width: 20%;
  }

  .travelers-col {
    width: 10%;
  }

  .check-in-col {
    width: 10%;
  }

  .check-out-col {
    width: 10%;
  }

  .price-col {
    width: 13%;
  }

  .more-col {
    width: 60px;
  }

  .selected-row {
    background-color: ${colors.placeboBlue};
  }
`;

export const ListViewTable = styled(Table)`
  thead {
    tr {
      th:first-of-type {
        border-left: 1px solid ${colors.grey[100]};
      }
      th:last-of-type {
        border-right: 1px solid ${colors.grey[100]};
      }

      th {
        background-color: ${colors.grey[50]} !important;
        border-top: 1px solid ${colors.grey[100]};
        border-bottom: 1px solid ${colors.grey[100]};
        font-size: 14px;
        font-style: normal;
        font-weight: 700 !important;
        line-height: 20px;
        cursor: default;
        span {
          color: #222 !important;
        }
        > span {
          width: 100%;
        }
        &:not(.check-in-out-dash-col) {
          &:hover {
            cursor: pointer;
            background-color: #e1e7ea !important;
          }
        }
      }
    }
  }

  tbody {
    tr {
      td:first-of-type {
        border-left: 1px solid ${colors.grey[100]};
      }
      td:last-of-type {
        border-right: 1px solid ${colors.grey[100]};
      }
    }

    tr:last-of-type {
      td:first-of-type {
        border-bottom-left-radius: ${({ theme }) => theme.legacy.shape.borderRadius.md};
      }
      td:last-of-type {
        border-bottom-right-radius: ${({ theme }) => theme.legacy.shape.borderRadius.md};
      }
    }
  }

  .ant-table-thead > tr > th:not(.check-in-out-dash-col) {
    padding: 0 8px;
    height: 36px;
  }

  .ant-table-tbody > tr > td:not(.check-in-out-dash-col) {
    padding: 16px 8px;
  }

  .ant-table-thead > tr > th:first-child,
  .ant-table-tbody > tr > td:first-child {
    padding-left: 16px;
  }

  .ant-table-thead > tr > th:last-child,
  .ant-table-tbody > tr > td:last-child {
    padding-right: 16px;
  }

  .ant-table-tbody > tr > td {
    color: ${colors.grey[800]};
  }

  .check-in-col > div,
  .check-out-col > div:first-of-type,
  .location-col > div {
    color: ${colors.grey[800]};
  }

  .check-in-col > div,
  .check-out-col > div {
    text-overflow: unset;
  }

  .check-in-col {
    text-align: center;
    width: 80px;
  }

  .check-out-col {
    text-align: center;
    width: 100px;
    text-overflow: unset;
    padding-right: 24px !important;
  }

  .hotel-col,
  .location-col,
  .travelers-col,
  .price-col,
  .more-col {
    height: 40px;
    padding: 16px;
    margin: 0;
  }

  .hotel-col {
    width: auto;
    min-width: 230px;
  }

  .price-col,
  .location-col {
    width: 12%;
  }

  .travelers-col {
    width: 25%;
  }

  .check-in-out-dash-col {
    width: 7px;
    padding: 0;
    text-align: center;
  }

  .rooms-col {
    text-align: center;
    padding: 0;
    width: 8%;
  }

  .price-col {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .selected-row {
    background-color: ${colors.placeboBlue};
  }
`;

export const Property = styled.div`
  ${flexbox({
    alignItems: "center",
  })}
  width: 100%;
`;

export const PropertyImageContainerLegacy = styled.div`
  margin-right: 16px;
`;

export const PropertyImageContainer = styled.div`
  margin-right: 8px;
`;

export const PropertyImageLegacy = styled(Image)`
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
`;

export const PropertyImage = styled(Image)`
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
  height: 32px;
  width: 32px;
  min-height: 32px;
  min-width: 32px;
`;

export const PropertyDetails = styled.div`
  ${ellipsisText};
  width: 100%;
  ${flexbox({
    direction: "column",
  })}
`;

export const PendingContainer = styled.div`
  display: flex;
`;

export const PropertyNameLegacy = styled.span`
  margin-bottom: 0;
  ${ellipsisText};
  ${typographyMixin("small")};
  font-weight: 500;
  color: ${colors.grey[800]};
`;

export const PropertyName = styled.div`
  margin-bottom: 0;
  ${ellipsisText};
  ${typographyMixin("small")};
  font-weight: normal;
  color: ${colors.grey[800]};
`;

export const PendingMod = styled.div`
  ${ellipsisText};
  ${flexbox({
    alignItems: "center",
  })}
`;

export const OrangeBull = styled.div`
  color: ${colors.carrotCurl};
  font-weight: bold;
  margin-right: 8px;
`;

export const PrimaryText = styled.div`
  ${ellipsisText};
  ${typographyMixin("body-medium")};
  color: ${colors.blackPanther};
`;

export const SubText = styled.div<{
  isSuccess?: boolean;
  $isVerified?: boolean;
}>`
  ${ellipsisText};
  ${typographyMixin("body-small")};
  color: ${({ isSuccess, $isVerified }) =>
    isSuccess || $isVerified ? colors.green[600] : colors.grey[500]};
`;

export const ListTripsPreviewPanel = styled(TripsPreviewPanel)<{
  showPreview: boolean;
}>`
  display: ${({ showPreview }) => (showPreview ? "flex" : "none")};

  ${mediaQuery("lg")(`
    position: relative;
    flex: 0;
    margin-left: 32px;
    min-width: 350px;
  `)}
`;

export const MoreHeaderButton = styled.div`
  cursor: pointer;

  svg {
    color: ${colors.black};

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

export const MenuButton = styled(Button)`
  padding: 0;
  font-weight: 500;
  cursor: pointer;
`;

export const LoadMore = styled.div`
  ${flexbox({
    justifyContent: "center",
  })}
`;

export const TableFooterButtons = styled.div`
  ${flexbox({
    justifyContent: "space-between",
    alignItems: "center",
  })}
  margin-top: 40px;
`;

export const FooterButton = styled(Button)<{ $active: boolean }>`
  margin-right: 8px;
  color: ${({ $active }) => $active && `${colors.white} !important`}; // override antd styles
  background-color: ${({ $active }) => $active && `${colors.black} !important`};

  &:last-child {
    margin-right: 0;
  }
`;

export const TripsColumnHeader = styled.div<{ sortHeader: boolean }>`
  span:last-child {
    margin-left: 8px;
    display: ${({ sortHeader }) => !sortHeader && "none"};
  }
`;

export const FlexText = styled.span`
  ${typographyMixin("xsmall")}
  color: ${colors.grey[800]};
`;

export const FlexProText = styled.span`
  ${typographyMixin("xsmall")}
  color: ${colors.green[600]};
  font-weight: normal;
`;
