import Loader from "@hotel-engine/common/Loader";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import styled from "styled-components";

export const Spinner = styled(Loader)`
    ${flexbox({ alignItems: "center", justifyContent: "center" })}
    height: 80vh;
    width: 100vw;
`;

export const MobileWrapper = styled.div`
    position: relative;
    z-index: ${({ theme }) => theme.legacy.zIndex.aboveBackdrop};
    height: 260px;
    width: 100%;
`;
