import styled, { createGlobalStyle } from "styled-components";

import { Card, Icon } from "@hotelengine/atlas-web";

import type { IImageProps } from "pages/DashboardLegacy/components/TripActivity/components/TripCard/styles";

export const LodgingCardInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LodgingCardImage = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing[8]};
  align-items: center;
`;

export const Image = styled.div<IImageProps>`
  background-image: ${(props) => `url('${props.image}')`};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.md};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.md};
  height: 120px;
  min-height: 120px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const LodgingCardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
`;

export const ImageInner = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing[8]};
  padding: ${({ theme }) => `${theme.spacing[16]} ${theme.spacing[24]}`};
`;

export const LodgingCard = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.md};
`;

export const FlightCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.md};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing[8]};
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing[12]};
  padding-right: ${({ theme }) => theme.spacing[12]};
  padding-top: ${({ theme }) => theme.spacing[16]};
  padding-bottom: ${({ theme }) => theme.spacing[16]};
`;

export const FlightSegmentsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing[8]};
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing[12]};
  padding-right: ${({ theme }) => theme.spacing[12]};
  padding-top: ${({ theme }) => theme.spacing[16]};
  padding-bottom: ${({ theme }) => theme.spacing[16]};
`;

export const RowFlightInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const TravelersFooter = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing[8]};
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing[12]};
  padding-right: ${({ theme }) => theme.spacing[12]};
  padding-top: ${({ theme }) => theme.spacing[16]};
  padding-bottom: ${({ theme }) => theme.spacing[16]};
  background-color: ${({ theme }) => theme.colors.neutralN500};
`;

export const HorizontalDivider = styled.div`
  height: 3px;
  flex: 1;
  display: flex;
  background-color: ${({ theme }) => theme.colors.neutralN100};
  border-radius: ${({ theme }) => theme.borderRadius.md};
`;

export const CardDivider = styled.div`
  background-color: ${({ theme }) => theme.colors.neutralN100};
  height: 1px;
`;

export const Ticket = styled.div`
 display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[8]};

  width: 100%;
  margin: ${({ theme }) => theme.spacing[12]};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: ${({ theme }) => theme.spacing[12]};
`;

export const IconBackground = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.circle};
  width: 16px;
  height: 16px;
  padding: ${({ theme }) => theme.spacing[4]};
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.accentGrayLightest};
`;

export const OriginDestination = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing[8]};
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing[12]};
  padding-right: ${({ theme }) => theme.spacing[12]};
  padding-top: ${({ theme }) => theme.spacing[16]};
`;

export const GlobalListItemStyles = createGlobalStyle`
  .ant-skeleton-header {
    padding-left: ${({ theme }) => theme.spacing[8]};
    padding-top: ${({ theme }) => theme.spacing[8]};
  }

  .ant-skeleton-content {
    padding-right: ${({ theme }) => theme.spacing[8]};
  }
`;

export const Chevron = styled(Icon)`
  margin-left: auto;
  margin-right: ${({ theme }) => theme.spacing[6]};
  min-width: 6px;
`;

export const TripsListItem = styled(Card)<{
  selected: boolean;
}>`
  overflow: hidden;
  cursor: pointer;
  border: none !important;
  width: 100%;
`;
