import styled from "styled-components";

export const PolicyDescriptionContainer = styled.div<{ marginTop?: boolean }>`
margin-top: ${({ theme, marginTop = true }) => theme.spacing[marginTop ? 24 : 0]};

  ul {
    padding: ${({ theme }) => theme.spacing[4]} ${({ theme }) => theme.spacing[24]};
    margin: 0;
  }
`;
