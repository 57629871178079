import { Fragment, useMemo } from "react";

import createMatchingPattern from "./helpers/createMatchingPattern";

export interface IWrapInStrong {
  term: string;
  target: string;
}

const WHITESPACE_ONLY_PATTERN = /^\s*$/u;

function isValidInput(term: string, target: string) {
  return (
    typeof term === "string" && typeof target === "string" && !WHITESPACE_ONLY_PATTERN.test(term)
  );
}

const WrapInStrong: React.FC<IWrapInStrong> = ({ term, target }) => {
  const segments = useMemo(() => {
    if (!isValidInput(term, target)) return [[target]];

    const pattern = createMatchingPattern(term);

    if (!target.match(pattern)) return [[target]];

    const result = [] as [string, string | undefined][];
    let lastIndex = 0;

    for (const { index, 0: matchedSubstring } of target.matchAll(pattern)) {
      // IGNORE-REASON ENS-2599 ⭐ fix me for good karma
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const unmatchedSegment = target.substring(lastIndex, index!);

      result.push([unmatchedSegment, matchedSubstring]);
      // IGNORE-REASON ENS-2599 ⭐ fix me for good karma
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      lastIndex = index! + matchedSubstring.length;
    }

    const remainingUnmatched = target.substring(lastIndex);

    return [...result, [remainingUnmatched]];
  }, [term, target]);

  return (
    <>
      {segments.map(([unmatchedSegment, matchedSubstring], index) => (
        <Fragment key={`${unmatchedSegment + matchedSubstring}-${index}`}>
          {unmatchedSegment}
          {!!matchedSubstring && <strong>{matchedSubstring}</strong>}
        </Fragment>
      ))}
    </>
  );
};

export default WrapInStrong;
