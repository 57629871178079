import type { Dispatch, SetStateAction } from "react";
import { useEffect } from "react";

import { useInbox } from "@trycourier/react-inbox";

import type { IUser } from "@hotel-engine/types/user";

import NotificationInbox from "../HeaderNotifications/NotificationInbox";
import * as Styled from "./styles";
import { ampli } from "ampli";

interface IMobileNotificationsDrawerProps {
  accountType: IUser["accountType"];
  visible: boolean;
  setShowUnreadNotifications: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}

const MobileNotificationsDrawer = ({
  onClose,
  setShowUnreadNotifications,
  visible,
  accountType,
}: IMobileNotificationsDrawerProps) => {
  const { getUnreadMessageCount, unreadMessageCount, ...rest } = useInbox();

  useEffect(() => {
    if (visible) {
      ampli.viewNotifications();
    }
  }, [visible]);

  useEffect(() => {
    getUnreadMessageCount();
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType]);

  useEffect(() => {
    setShowUnreadNotifications(unreadMessageCount > 0);
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadMessageCount, accountType]);

  return (
    <Styled.NotificationsDrawer
      closable={false}
      onClose={onClose}
      placement="bottom"
      visible={visible}
    >
      <NotificationInbox {...rest} accountType={accountType} />
    </Styled.NotificationsDrawer>
  );
};

export default MobileNotificationsDrawer;
