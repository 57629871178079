import { Field } from "formik";
import styled, { css } from "styled-components";
import { Typography } from "@hotelengine/atlas-web";

export const BatchEmailField = styled(Field)<{
  $isRefresh?: boolean;
  $hasRoundedBorders?: boolean;
}>`
  color: ${({ theme }) => theme.colors.neutralN700};
  margin-bottom: ${({ theme, $isRefresh }) => ($isRefresh ? theme.spacing[0] : theme.spacing[16])} !important;
  height: 100%;

  ${({ $isRefresh, theme }) =>
    !!$isRefresh &&
    css`
    background-color: ${theme.colors.backgroundSecondary};
    `}

    ${({ $hasRoundedBorders, theme }) =>
      !!$hasRoundedBorders &&
      css`
    border-radius: ${theme.spacing[32]};
    background-color: ${theme.colors.backgroundSecondary};
    `}
   
`;

export const InputContainer = styled.div`
  height: 50px;
`;

export const AutoCompleteNameAndRole = styled(Typography).attrs({
  variant: "heading/md",
  color: "foregroundPrimary",
})`
`;

export const AutoCompleteEmail = styled(Typography).attrs({
  variant: "body/sm",
  color: "foregroundPrimary",
})`
`;

export const Separator = styled.pre`
  display: inline;
`;
