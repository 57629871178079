import styled from "styled-components";

import AlertBanner from "@hotel-engine/common/AlertBanner";
import Button from "@hotel-engine/common/Button";
import { Icon } from "@hotel-engine/common/Icon";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { centerElementY } from "@hotel-engine/styles/helpers/mixins";
import { colors } from "@hotel-engine/styles";

export const Title = styled.p`
  ${typographyMixin("xlarge")};
  margin: 0;
`;

export const ContinueWithGoogleButton = styled(Button)`
  position: relative;
  width: 100%;
  min-width: 200px;
  margin-top: ${({ theme }) => theme.legacy.space(3)};
  text-align: center;

  &:disabled,
  &:disabled:hover {
    background-color: ${colors.white};
    border-color: ${colors.azureHint};
  }
`;

export const ContinueWithGoogleIcon = styled.img`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 16px;
`;

export const ContinueWithEmailButton = styled(Button)`
  position: relative;
  width: 100%;
  margin-top: ${({ theme }) => theme.legacy.space(2)};
  text-align: center;

  &:disabled.continueWithButton,
  &:disabled:hover {
    background-color: ${colors.white};
    border-color: ${colors.azureHint};
    color: ${colors.azureHint};
  }
`;

export const ContinueWithEmailIcon = styled(Icon)`
  ${centerElementY({ position: "absolute" })}
  left: 16px;
`;

export const ErrorMessage = styled(AlertBanner)`
  margin: 8px 0 0;
`;
