import styled from "styled-components";
import { Icon as AtlasIcon, Box, type BoxProps } from "@hotelengine/atlas-web";

const SuccessContainer = styled<React.FC<BoxProps>>(Box)`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.colors.accentGreenLightest};
  padding: ${({ theme }) => theme.spacing[16]};
  flex-direction: column;
  justify-content: center;
`;

const InfoContainer = styled<React.FC<BoxProps>>(Box)`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const Icon = styled(AtlasIcon)`
  color: ${({ theme }) => theme.colors.accentGreen};
`;

const IconContainer = styled<React.FC<BoxProps>>(Box)`
  width: ${({ theme }) => theme.spacing[24]};
  margin-left: ${({ theme }) => theme.spacing[8]};
  display: flex;
  align-items: baseline;
`;

const DetailContainer = styled<React.FC<BoxProps>>(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export { SuccessContainer, IconContainer, Icon, InfoContainer, DetailContainer };
