import { Box, type BoxProps } from "@hotelengine/atlas-web";
import styled, { css } from "styled-components";

export const ButtonContainer = styled<React.FC<BoxProps>>(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing[12]};
  margin-top: ${({ theme }) => theme.spacing[24]};

  ${({ theme }) => {
    return css`
      @media screen and (max-width: ${theme.breakpoints.md}) {
        flex-direction: column;
        align-items: center;

        button {
          max-width: 350px;
          width: 100%;
        }
      }
    `;
  }}
`;
