import { useLocation } from "@hotel-engine/lib/react-router-dom";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";

import TopActions from "./components/TopActions";
import UpcomingActiveActions from "./components/UpcomingActiveActions";
import PastCancelledActions from "./components/PastCancelledActions";
import * as Styled from "./styles";
import type { ILocationState } from "../..";
import { useAppSelector } from "store/hooks";
import ModificationStatuses from "./components/ModificationStatuses";

const ItineraryActions = () => {
  const user = useAppSelector((state) => state.Auth.user);
  const isViewOnlyTraveler = user?.role === "view_only_traveler";
  const rrLocation = useLocation<ILocationState>();
  // ⚠️ This can be undefined. It was suppressed during the
  // react router 6 upgrade. Please remove the ! and handle undefined.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const locationState = rrLocation.state!;
  const { reservation: itinerary, isPreview } = useModificationsContext();

  const isModificationsNotificationsOn = useIsFeatureFlagOn("modification-notifications-v1");

  const isReservationUpcomingOrActive = ["booked", "visiting"].includes(itinerary.status);

  const isReservationPastOrCancelled = ["completed", "cancelled"].includes(itinerary.status);

  const itineraryHasModifications = !!Object.keys(itinerary.modifications?.all).length;

  const showModificationStatuses = isModificationsNotificationsOn && itineraryHasModifications;

  return (
    <Styled.ItineraryActionContainer $isPreview={isPreview}>
      {!isViewOnlyTraveler && <TopActions itinerary={itinerary} locationState={locationState} />}
      {!!showModificationStatuses && (
        <ModificationStatuses
          modifications={itinerary.modifications}
          propertyName={itinerary.propertyName}
        />
      )}
      {!!isReservationUpcomingOrActive && (
        <UpcomingActiveActions itinerary={itinerary} isPreview={isPreview} />
      )}
      {!!isReservationPastOrCancelled && <PastCancelledActions itinerary={itinerary} />}
    </Styled.ItineraryActionContainer>
  );
};

export default ItineraryActions;
