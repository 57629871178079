import { Button } from "@hotelengine/atlas-web";
import styled from "styled-components";

export const ListContainer = styled.div`
    width: 100%;
    padding-right: ${({ theme }) => theme.spacing[24]};
    padding-left: ${({ theme }) => theme.spacing[24]};
    padding-bottom: ${({ theme }) => theme.spacing[24]};
`;

export const Container = styled.div<{
  isFirstItem: boolean;
  isLastItem: boolean;
  isCollapsed: boolean;
}>`
     background-color: ${({ theme }) => theme.colors.backgroundSecondary};
    padding: ${({ theme }) => theme.spacing[8]};
    padding-left: ${({ theme }) => theme.spacing[12]};
    border-width: ${({ theme }) => theme.borderWidth.normal};
    border-bottom-width: ${({ theme, isLastItem }) => (isLastItem ? theme.borderWidth.normal : 0)};
    border-color: ${({ theme }) => theme.colors.backgroundPrimary};
    border-style: solid;
    border-top-left-radius: ${({ theme, isFirstItem }) => (isFirstItem ? theme.borderRadius.md : 0)};
    border-top-right-radius: ${({ theme, isFirstItem }) =>
      isFirstItem ? theme.borderRadius.md : 0};
    border-bottom-left-radius: ${({ theme, isLastItem }) =>
      isLastItem ? theme.borderRadius.md : 0};
    border-bottom-right-radius: ${({ theme, isLastItem }) =>
      isLastItem ? theme.borderRadius.md : 0};
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`
    padding-bottom:${({ theme }) => theme.spacing[12]};
    padding-left:${({ theme }) => theme.spacing[12]};
    padding-right:${({ theme }) => theme.spacing[12]};
`;

export const Header = styled(Button)`
    background-color: ${({ theme }) => theme.colors.backgroundSecondary};
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: ${({ theme }) => theme.spacing[0]};
`;
