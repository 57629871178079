import type { FunctionComponent } from "react";
import { colors } from "@hotel-engine/styles";
import moment from "moment";

import * as Styled from "./styles";
import { Typography } from "@hotelengine/atlas-web";

interface IModification {
  id: number;
  HcErr: string | null;
  HcLastop: string;
  accountid: string;
  casenumber: string;
  caseSubTypeC: string;
  contactid: string;
  createddate: string;
  closeddate: null;
  csCustomerConfirmedC: string | null;
  description: string;
  heContractC: string;
  isdeleted: boolean;
  origin: string;
  priority: string;
  reason: string | null;
  requestStatusC: string | null;
  sfid: string;
  status: string;
  subject: string;
  systemmodstamp: string;
  tsCustomerEmailedC: string | null;
  type: string;
}

export interface IModificationHistoryProps {
  modifications: IModification[];
  isBilling?: boolean;
}

const statusValues = {
  pending: {
    bullColor: colors.carrotCurl,
    text: "Trip modification pending",
    dateType: "createddate",
  },
  approved: {
    bullColor: colors.finePine,
    text: "Trip updated",
    dateType: "systemmodstamp",
  },
  denied: {
    bullColor: colors.khmerCurry,
    text: "Modification unavailable",
    dateType: "closeddate",
  },
};

const ModificationHistory: FunctionComponent<IModificationHistoryProps> = ({
  modifications,
  isBilling,
}) => {
  // does not include "Needs Help" or null requestStatusC
  const sanitizedModifications = modifications?.filter(
    (mod) =>
      mod &&
      (mod.requestStatusC?.toLowerCase() === "approved" ||
        mod.requestStatusC?.toLowerCase() === "denied" ||
        mod.requestStatusC?.toLowerCase() === "pending")
  );

  if (!sanitizedModifications || sanitizedModifications.length === 0) {
    return null;
  }

  const listTitle = !isBilling ? "Modification Information" : "";

  return (
    <div>
      <Typography
        variant="heading/md"
        paddingTop={20}
        paddingRight={0}
        paddingBottom={8}
        paddingLeft={0}
      >
        {listTitle}
      </Typography>
      <div>
        {sanitizedModifications.map((mod) => {
          const { casenumber, status, requestStatusC } = mod;

          // if its NOT closed then its always pending
          const modificationStatus =
            status !== "Closed" ? "pending" : (requestStatusC as string).toLowerCase();
          const { bullColor, text, dateType } = statusValues[modificationStatus];
          const date = mod[dateType] ? moment(mod[dateType]).format("MM/DD/YY") : null;
          const billing = isBilling ? "Billing Issue Reported" : "";
          const missingData = (
            <Typography variant="body/sm" color="foregroundPrimary">
              N/A
            </Typography>
          );

          return (
            <Styled.ModificationInfo key={casenumber}>
              <Styled.Bull bullColor={bullColor}>&bull;</Styled.Bull>
              {/* when a modification is just created date and casenumber are null */}
              <Typography variant="body/sm">
                {billing || text} - {date || <>Date:{missingData}</>} - ID:
                {casenumber || missingData}
              </Typography>
            </Styled.ModificationInfo>
          );
        })}
      </div>
    </div>
  );
};

export default ModificationHistory;
