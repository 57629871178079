import { Box, Typography } from "@hotelengine/atlas-web";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import sortObjectsByKey from "@hotel-engine/utilities/helpers/sortObjectsByKey";

import * as Styled from "./styles";
import type { ICustomField } from "@hotel-engine/types/customField";
import type { IEditTripCustomFieldsProps } from "./EditTripCustomFields";
import EditTripCustomFields from "./EditTripCustomFields";
import { CollapsibleContent } from "../../../shared/CollapsibleContent";

export interface ICompanyDetailsProps {
  allCustomFields: ICustomField[] | undefined;
  isPreview: boolean;
  openEditCustomFields: boolean;
  reservation: IReservationBase;
  setReservation(value: IReservationBase): void;
  verificationRequired: boolean;
}

const CompanyDetails: React.FC<ICompanyDetailsProps> = ({
  allCustomFields,
  isPreview,
  reservation,
  openEditCustomFields,
  setReservation,
  verificationRequired,
}) => {
  if (!allCustomFields?.length) {
    return null;
  }

  const customFields = reservation.customFields
    ? sortObjectsByKey(reservation.customFields, "name")
    : [];

  return (
    <CollapsibleContent
      toggleTestID="toggle-company-details"
      toggleBtnLabel={
        <Box display="flex" gap={12} alignItems="center">
          <Typography variant="heading/lg">Company details</Typography>
        </Box>
      }
    >
      <Styled.DetailsContainer>
        {!customFields.length ? (
          "No custom fields entered"
        ) : (
          <Styled.FieldsContainer>
            {customFields.map((field) => (
              <Styled.FieldLine key={field.id}>
                <Typography variant="body/sm" color="foregroundSecondary">
                  {field.name}
                </Typography>
                <Typography variant="body/sm" color="foregroundPrimary">
                  {field.value}
                </Typography>
              </Styled.FieldLine>
            ))}
            {!!reservation.directBillCode && !!verificationRequired && (
              <Styled.FieldLine>
                <Typography variant="body/sm" color="foregroundSecondary">
                  Direct Bill Verification
                </Typography>
                <Typography variant="body/sm" color="foregroundPrimary">
                  {reservation.directBillCode}
                </Typography>
              </Styled.FieldLine>
            )}
          </Styled.FieldsContainer>
        )}
        <EditTripCustomFields
          isPreview={isPreview}
          allCustomFields={allCustomFields}
          forceOpen={openEditCustomFields}
          reservation={reservation}
          setReservation={setReservation as IEditTripCustomFieldsProps["setReservation"]}
        />
      </Styled.DetailsContainer>
    </CollapsibleContent>
  );
};

export default CompanyDetails;
