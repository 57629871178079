import * as yup from "yup";

import { emailRegEx } from "@hotel-engine/utilities";
import { getBatchEmailValidationSchema } from "../../BatchEmailAutoCompleteInput/validation";

export const MAX_EMAILS = 25;

export const SendItineraryModalSchema = yup.object().shape({
  emails: getBatchEmailValidationSchema(MAX_EMAILS, true),
  email: yup
    .string()
    .matches(emailRegEx, { message: "Invalid Email" })
    .required("Please enter valid email addresses"),
  additionalEmails: yup
    .array()
    .of(yup.string())
    .test("len", `Only ${MAX_EMAILS} emails allowed`, (val = []) => val.length <= MAX_EMAILS - 1), // -1 because `email` holds the first
  note: yup.string(),
  subject: yup.string().required("Please enter subject line for email"),
});
