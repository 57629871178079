import { useState } from "react";

import { useQueryClient } from "react-query";

import { ErrorMessage } from "@hotel-engine/app/ErrorMessage";
import { message } from "@hotel-engine/common/Message";
import { endpoints } from "@hotel-engine/react-query/constants";
import { useCreateHiddenProperty } from "@hotel-engine/react-query/hiddenProperties/useCreateHiddenProperty";
import { HiddenPropertyReasons } from "@hotel-engine/types/hiddenProperty";
import type { HidePropertyScope, HidePropertyScreen } from "@hotel-engine/types/hiddenProperty";
import type { IPropertyLegacy } from "@hotel-engine/types/property";
import { captureException } from "@hotel-engine/utilities";
import { PropertyActions } from "store/containers/Property/PropertyRedux";
import { useAppDispatch } from "store/hooks";

import * as Styled from "./styles";
import { Unsafe } from "@hotel-engine/data";
import {
  Box,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  RadioGroupItem,
  TextArea,
  Typography,
} from "@hotelengine/atlas-web";
import { useBreakpoint } from "@hotel-engine/hooks";

/**
 * `modalConfig` contains the differences in UI text that is
 * displayed by the modal. It corresponds to the
 * {@link IHidePropertyProps.scope} prop passed to {@link HidePropertyModal}.
 */
const modalConfig: {
  [key in HidePropertyScope]: {
    titleText: string;
    contentText: string;
    primaryBtnText: string;
  };
} = {
  business: {
    titleText: "Hide Property For All",
    contentText:
      "Are you sure you want to hide this property for future searches for your entire organization?",
    primaryBtnText: "Hide For All",
  },
  user: {
    titleText: "Hide Property",
    contentText:
      "Are you sure you want to hide this property? If so, the property will no longer appear in your search results.",
    primaryBtnText: "Hide",
  },
} as const;

export interface IHidePropertyProps {
  propertyId: number;
  onClose: () => void;
  onHideSuccessful: () => void;
  scope?: HidePropertyScope;
}

const HidePropertyModal = ({
  propertyId,
  onClose,
  onHideSuccessful,
  scope = "user",
}: IHidePropertyProps) => {
  const isMobile = useBreakpoint("md", "max");
  const queryClient = useQueryClient();

  const dispatch = useAppDispatch();

  const [screen, setScreen] = useState<HidePropertyScreen>("form");
  const [reason, setReason] = useState<(typeof HiddenPropertyReasons)[number] | undefined>();
  const [comments, setComments] = useState<string>("");

  const hideProperty = useCreateHiddenProperty();

  const onFormContinue = () => {
    if (!!reason) {
      setScreen("confirmation");
    }
  };

  const onFormSubmit = async () => {
    if (!reason) {
      return;
    }

    try {
      const { id } = await hideProperty.mutateAsync(
        {
          propertyId,
          reason,
          comments: reason === "Another reason" ? comments : undefined,
          scope,
        },
        {
          onSuccess: (res) => {
            queryClient.setQueryData<IPropertyLegacy>(
              [endpoints.properties, propertyId],
              (cacheProperty) =>
                (cacheProperty
                  ? {
                      ...cacheProperty,
                      hiddenPropertyId: res.id,
                      hiddenPropertyHiddenFromType: scope,
                      hiddenPropertyReason: reason,
                    }
                  : cacheProperty) as IPropertyLegacy
            );
          },
        }
      );

      onHideSuccessful();
      dispatch(PropertyActions.setHiddenPropertyId(id));
    } catch (e) {
      message.error(<ErrorMessage />, 0).then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
      captureException(e);
    }
  };

  return (
    <Dialog isModal isOpen>
      <DialogContent>
        <Box padding={isMobile ? 0 : 16}>
          <DialogTitle>
            <Box display="flex" justifyContent="center" marginBottom={16}>
              <Typography
                as="h2"
                color="foregroundPrimary"
                marginBottom={0}
                variant="heading/xl"
                style={{ textAlign: "center" }}
              >
                {screen === "form"
                  ? "Why are you hiding this property?"
                  : modalConfig[scope].titleText}
              </Typography>
            </Box>
          </DialogTitle>
          <DialogDescription>
            {screen === "form" ? (
              <Box padding={isMobile ? 0 : 24} marginBottom={isMobile ? 24 : 0}>
                <Typography
                  as="p"
                  color="foregroundPrimary"
                  marginBottom={16}
                  paddingLeft={20}
                  paddingRight={20}
                  style={{ textAlign: "center" }}
                  variant="body/sm"
                >
                  This helps us establish better search results for all our members.
                </Typography>
                <Styled.ReasonGroup
                  isBoxed
                  onValueChange={(value) =>
                    setReason(value as (typeof HiddenPropertyReasons)[number])
                  }
                >
                  {HiddenPropertyReasons.map((r) => (
                    <RadioGroupItem key={r} value={r}>
                      <Typography color="foregroundPrimary" variant="body/sm" data-testid={r}>
                        {r}
                      </Typography>
                    </RadioGroupItem>
                  ))}
                </Styled.ReasonGroup>
                {reason === "Another reason" && (
                  <Box marginTop={16}>
                    <Typography color="foregroundPrimary" variant="body/md">
                      Please Tell Us More
                    </Typography>
                    <TextArea
                      maxLength={255}
                      onChange={(e) => setComments(e.target.value)}
                      rows={4}
                      value={comments}
                    />
                  </Box>
                )}
              </Box>
            ) : (
              <Typography
                as="p"
                color="foregroundPrimary"
                style={{ textAlign: "center" }}
                variant="body/md"
              >
                {modalConfig[scope].contentText}
              </Typography>
            )}
          </DialogDescription>
          <Box display="flex" gap={16} justifyContent="center">
            <DialogClose>
              <Styled.ActionButton
                color="secondary"
                key="hide-close"
                onClick={onClose}
                variant="outlined"
              >
                Close
              </Styled.ActionButton>
            </DialogClose>
            {screen === "form" ? (
              <Styled.ActionButton
                isDisabled={!reason}
                key="hide-continue"
                onClick={onFormContinue}
              >
                Continue
              </Styled.ActionButton>
            ) : (
              <Styled.ActionButton
                color="destructive"
                isDisabled={hideProperty.isLoading}
                isLoading={hideProperty.isLoading}
                key="hide-hide"
                onClick={onFormSubmit}
                data-testid="property-modal-action-button"
              >
                {hideProperty.isLoading ? "Hiding Property" : modalConfig[scope].primaryBtnText}
              </Styled.ActionButton>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default HidePropertyModal;
