import { useState } from "react";

import type { FormikTouched } from "formik";
import { Field, Formik } from "formik";

import { Icon } from "@hotel-engine/common/Icon";
import Image from "@hotel-engine/common/Image";
import InputField from "@hotel-engine/common/FormikFields/InputField";
import { useWindowSize } from "@hotel-engine/hooks";
import { useTextMessagesMutation } from "@hotel-engine/react-query/textMessages/useTextMessagesMutation";
import { PhoneNumberSchema } from "@hotel-engine/utilities";

import * as Styled from "./styles";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";
import { forceNavigateToAppStore } from "@hotel-engine/constants/appStore";

const DownloadAppBannerLegacy = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { mobile } = useWindowSize();
  const { COMPANY_NAME } = useCompanyName();

  const { mutate, reset: resetMutation } = useTextMessagesMutation();

  const handleOnSubmit = (values, { setFieldError }) => {
    setLoading(true);
    mutate(
      { phone: values.phone },
      {
        onSuccess: () => setSuccess(true),
        onError: (error) => setFieldError("phone", error.response?.data.title),
        onSettled: () => {
          resetMutation();
          setLoading(false);
        },
      }
    );
  };

  const getInputIcon = (
    isValid: boolean,
    touched: FormikTouched<{
      phone: string;
    }>
  ) => {
    const { phone } = touched;
    const hasError = !isValid && Boolean(phone);
    if (hasError || success) {
      return (
        <Styled.InputIcon
          icon={["far", hasError ? "exclamation-circle" : "check-circle"]}
          $hasError={hasError}
        />
      );
    } else {
      return undefined;
    }
  };

  return (
    <Styled.DownloadAppBanner>
      <Styled.InstructionsContainer>
        <h4>Make Travel a Breeze</h4>
        <h5>Bookings at your fingertips</h5>
        {mobile ? (
          <Styled.MobileButton role="button" onClick={() => forceNavigateToAppStore()}>
            Get the App
            <Icon icon={["far", "chevron-right"]} className="icon" />
          </Styled.MobileButton>
        ) : (
          <>
            <Formik
              initialValues={{ phone: undefined }}
              onSubmit={handleOnSubmit}
              validationSchema={PhoneNumberSchema("Please enter a valid US phone number")}
            >
              {({ handleSubmit, isValid, touched }) => (
                <Styled.DownloadForm onSubmit={handleSubmit} data-testid="download-form">
                  <Field
                    component={InputField}
                    size="default"
                    name="phone"
                    type="phone"
                    placeholder="Phone Number"
                    className="phone-input"
                    disabled={success}
                    suffix={getInputIcon(isValid, touched)}
                  />
                  <Styled.SubmitButton
                    htmlType="submit"
                    type="primary"
                    disabled={success}
                    loading={loading}
                    data-testid="submit"
                  >
                    {loading ? "Sending" : "Get the App"}
                  </Styled.SubmitButton>
                </Styled.DownloadForm>
              )}
            </Formik>
            {!!success && (
              <Styled.InstructionalCopy>
                We’ve sent you a text message with a link to download the app
              </Styled.InstructionalCopy>
            )}
            <Styled.InstructionalCopy>
              Standard text message rates may apply
            </Styled.InstructionalCopy>
          </>
        )}
      </Styled.InstructionsContainer>
      <Styled.ImageContainer>
        <Image
          setContainerSize={false}
          src={"assets/App-download-image.jpg"}
          alt={`Preview of a customer using the ${COMPANY_NAME} app`}
        />
      </Styled.ImageContainer>
    </Styled.DownloadAppBanner>
  );
};

export default DownloadAppBannerLegacy;
