import styled, { css } from "styled-components";

export const ContactInformationWrapper = styled.div<{ $visible: boolean }>`
  background: ${({ theme }) => theme.colors.backgroundOverlay};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndex.modal};
  transition: opacity 0.3s;
  opacity: 0;
  pointer-events: none;

  ${({ $visible }) =>
    $visible &&
    css`
    opacity: 1;
    pointer-events: all;
  `};
`;

export const ModalContent = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.xl};
  background: ${({ theme }) => theme.colors.backgroundPrimary};
  padding: ${({ theme }) => theme.spacing[24]};
  max-width: calc(100vw - ${({ theme }) => theme.spacing[32]});
  max-height: calc(100vh - ${({ theme }) => theme.spacing[32]});
  overflow-y: auto;
  width: 450px;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing[24]};
`;

export const ModalContactLogo = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[24]};
`;

export const ButtonsContainer = styled.div<{ padTop?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing[8]};
  margin-bottom: ${({ theme }) => theme.spacing[24]};
  margin-top: ${({ theme, padTop }) => theme.spacing[padTop ? 24 : 0]};

  > * {
    flex-grow: 1;
  }
`;

export const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
`;

export const TimeTable = styled.div`
  flex-direction: row;
  display: flex;
  gap: ${({ theme }) => theme.spacing[4]};

  span {
    line-height: 1.5;
  }

  &:last-of-type {
    margin-bottom: ${({ theme }) => theme.spacing[4]};
  }
`;
