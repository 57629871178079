import { noop } from "lodash";
import { ampli } from "ampli";

import {
  BILLING,
  COMPANY_SETTINGS,
  DASHBOARD,
  GROUPS,
  MEMBERS,
  TRENDS,
  TRIPS,
  SUPPORT_CENTER,
  routes,
  supportCenterRouteSources,
} from "@hotel-engine/constants";
import { isUserEnrolledInRewards } from "@hotel-engine/utilities";

import type { IRole } from "@hotel-engine/types/role";
import type { IUser } from "@hotel-engine/types/user";
import type { NavigateFunction } from "@hotel-engine/lib/react-router-dom";
import type { ITokenMode } from "@hotel-engine/react-query/users/useAccountMode";
import type { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import type { SubMenuCards } from "@hotel-engine/app/Header/components/redesignComponents/TopNavMenu";

export const userRoleMap: Record<IRole, string> = {
  admin: "Administrator",
  coordinator: "Coordinator",
  user: "Traveler",
  view_only_traveler: "View Only Traveler",
};

export const roleLinks: Record<IRole, ILinks["id"][]> = {
  admin: [DASHBOARD, TRIPS, BILLING, MEMBERS, TRENDS, GROUPS, COMPANY_SETTINGS, SUPPORT_CENTER],
  coordinator: [DASHBOARD, TRIPS, TRENDS, GROUPS, SUPPORT_CENTER],
  user: [DASHBOARD, TRIPS, GROUPS, SUPPORT_CENTER],
  view_only_traveler: [TRIPS, SUPPORT_CENTER],
};

type LinksValueId =
  | typeof DASHBOARD
  | typeof TRIPS
  | typeof MEMBERS
  | typeof BILLING
  | typeof COMPANY_SETTINGS
  | typeof TRENDS
  | typeof GROUPS
  | typeof SUPPORT_CENTER;

type LinkValueLinkType = {
  id: LinksValueId;
  name: string;
  route: string;
  type: "link";
  redesignName?: string;
  subMenuItems?: SubMenuCards;
};

type LinkValueMenuTriggerType = Omit<LinkValueLinkType, "type"> & {
  type: "menu-trigger";
  subMenuItems: SubMenuCards;
};

type LinkValueDynamicType = Omit<LinkValueLinkType, "type"> & {
  type: "dynamic";
  subMenuItems?: SubMenuCards;
};

export type ILinks = LinkValueLinkType | LinkValueMenuTriggerType | LinkValueDynamicType;

export const LinkValues: ILinks[] = [
  {
    id: DASHBOARD,
    name: "Book",
    route: "/",
    type: "link",
  },
  {
    id: TRIPS,
    name: "Trips",
    route: routes.trips.upcoming,
    type: "link",
  },
  {
    id: BILLING,
    name: "Billing",
    route: routes.billing,
    type: "link",
  },
  {
    id: MEMBERS,
    name: "Members",
    route: routes.members.all,
    type: "link",
  },
  {
    id: TRENDS,
    name: "Trends",
    route: routes.trends.all,
    type: "link",
  },
  {
    // this can be a link or menu trigger depending on user role
    id: GROUPS,
    name: "Groups",
    route: `${routes.groups.dashboard}/open`,
    type: "dynamic",
    // TODO: need design input on what icons to use here
    subMenuItems: [
      {
        title: "Groups",
        icon: "users-rectangle",
        href: `${routes.groups.dashboard}/open`,
        onClick: () => {
          ampli.clickGroupsSubNavSelection({ selectedPage: "groups" });
        },
      },
      {
        title: "Orders",
        icon: "truck-pickup",
        href: routes.orders,
        onClick: () => {
          ampli.clickGroupsSubNavSelection({ selectedPage: "orders" });
        },
      },
    ],
  },
  {
    id: COMPANY_SETTINGS,
    name: "Company Settings",
    route: routes.companySettings.userPermissions,
    type: "menu-trigger",
    // TODO: need paths, ampli events for these links
    subMenuItems: [
      { title: "Trends", icon: "wave-pulse" },
      { title: "Direct bill", icon: "file-invoice-dollar" },
      { title: "Cards", icon: "credit-card" },
      { title: "Policies", icon: "shield-keyhole" },
      { title: "Preferences", icon: "list-check" },
      { title: "People", icon: "users-rectangle" },
    ],
    redesignName: "Company",
  },
  {
    id: SUPPORT_CENTER,
    name: "Support Center",
    route: `${routes.supportCenter}?source=${supportCenterRouteSources.menuBar}`,
    type: "link",
    redesignName: "Support",
  },
];

interface IGetUserNavLinks {
  shouldShowReferAFriend: boolean;
  shouldShowTravelCredits: boolean;
  shouldShowRewards: boolean;
  shouldShowMyProperties: boolean;
  isInviteTravelerEnabled: boolean;
  supportCenterFeatureOn: boolean;
  canCreatePersonalAccount: boolean;
  showSwitch: boolean;
  userTokenMode: ITokenMode;
  navigate: NavigateFunction;
  onInviteTraveler: () => void;
  handleSupportCaseClick: () => () => void;
  onPersonalAccountCreation?: () => void;
  onSwitchToBusiness: () => void;
  onSwitchToPersonal: () => void;
}

export const getUserNavLinks = ({
  navigate,
  shouldShowReferAFriend,
  shouldShowRewards,
  shouldShowTravelCredits,
  shouldShowMyProperties,
  onInviteTraveler,
  isInviteTravelerEnabled,
  supportCenterFeatureOn,
  handleSupportCaseClick,
  canCreatePersonalAccount,
  onPersonalAccountCreation = noop,
  userTokenMode,
  showSwitch,
  onSwitchToBusiness,
  onSwitchToPersonal,
}: IGetUserNavLinks) => {
  return [
    {
      icon: "gift",
      label: "Refer a Friend",
      shouldShow: shouldShowReferAFriend,
      onClick: () => navigate(routes.referFriends.base),
    },
    {
      icon: "money-bill-simple-wave--solid",
      label: "Travel Credit",
      shouldShow: shouldShowTravelCredits,
      onClick: () => {
        console.log("Travel Credit clicked");
        navigate(routes.travelCredits);
      },
    },
    {
      icon: "he-rewards--solid",
      label: "My Rewards",
      shouldShow: shouldShowRewards,
      onClick: (user: IUser) =>
        navigate(isUserEnrolledInRewards(user) ? routes.rewards : routes.rewardsEnrollment),
    },
    {
      icon: "heart--solid",
      label: "My Properties",
      shouldShow: shouldShowMyProperties,
      onClick: () => navigate(routes.myPropertiesPreferred),
    },
    {
      icon: "user-plus",
      label: "Invite Traveler",
      shouldShow: isInviteTravelerEnabled,
      onClick: onInviteTraveler,
    },
    {
      icon: "gear--solid",
      label: "My Settings",
      shouldShow: true,
      onClick: () => navigate(routes.settings.profile),
    },
    {
      icon: "message-lines",
      label: "Support Center",
      shouldShow: supportCenterFeatureOn,
      onClick: handleSupportCaseClick,
    },
    {
      icon: "plus",
      label: "Add Personal Account",
      shouldShow: canCreatePersonalAccount,
      onClick: onPersonalAccountCreation,
    },
    {
      icon: "arrows-rotate",
      label: `Switch to ${userTokenMode === "personal" ? "Business" : "Personal"} Account`,
      shouldShow: showSwitch,
      onClick: () => (userTokenMode === "personal" ? onSwitchToBusiness : onSwitchToPersonal),
    },
  ] as const;
};

interface IGetInternalBusinessNavLinks {
  showEngines: boolean;
  showRatelessProperties: boolean;
  showSearchRadiusCircle: boolean;
  toggleEnginePreferences: ActionCreatorWithPayload<boolean, string>;
  toggleRatelessProperties: ActionCreatorWithPayload<boolean, string>;
  toggleSearchRadiusCircle: ActionCreatorWithPayload<boolean, string>;
}

export const getInternalBusinessNavLinks = ({
  showEngines,
  showRatelessProperties,
  showSearchRadiusCircle,
  toggleEnginePreferences,
  toggleRatelessProperties,
  toggleSearchRadiusCircle,
}: IGetInternalBusinessNavLinks) => {
  return [
    {
      label: "Engines",
      isChecked: showEngines,
      onChange: () => toggleEnginePreferences(!showEngines),
    },
    {
      label: "Rateless Properties",
      isChecked: showRatelessProperties,
      onChange: () => toggleRatelessProperties(!showRatelessProperties),
    },
    {
      label: "Search Radius Circle",
      isChecked: showSearchRadiusCircle,
      onChange: () => toggleSearchRadiusCircle(!showSearchRadiusCircle),
    },
  ] as const;
};
