import styled from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import type { TypographyProps } from "@hotelengine/atlas-web";
import { Typography } from "@hotelengine/atlas-web";

export const RoomDetails = styled.div`
  margin-top: ${({ theme }) => theme.spacing[2]};
  margin-bottom: ${({ theme }) => theme.spacing[24]};
  padding-left: ${({ theme }) => theme.spacing[24]};
  padding-right: ${({ theme }) => theme.spacing[24]};
  `;

export const DetailsList = styled.div<{ $isLast?: boolean; $isOnly?: boolean }>`
  ${flexbox({
    direction: "column",
  })}

  padding-bottom: ${({ theme, $isLast, $isOnly }) => theme.spacing[$isOnly ? 0 : $isLast ? 24 : 40]};
  gap: ${({ theme }) => theme.spacing[12]};
`;

export const ListItem = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: ${({ theme }) => theme.spacing[8]};
`;

export const ShowRoomsContainer = styled.div`
  width: 100%;
`;

export const ShowRoomsLink = styled<React.FC<TypographyProps>>(Typography)`
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const RoomSeparator = styled.div`
  height: 1px;
  border-top: 1px dashed ${({ theme }) => theme.colors.borderSubtle};
  margin: ${({ theme }) => `${theme.spacing[24]} ${theme.spacing[0]} ${theme.spacing[0]}`};
`;
