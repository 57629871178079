import { useNavigate } from "@hotel-engine/lib/react-router-dom";

import { Icon, Typography } from "@hotelengine/atlas-web";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import type { ILocationState } from "@hotel-engine/app/ItineraryContent";
import { formatUrl } from "@hotel-engine/react-router";
import { Reservation } from "@hotel-engine/services";

import * as Styled from "./styles";
import isStateOfTexasUser from "@hotel-engine/utilities/helpers/isStateOfTexasUser";
import { useAppSelector } from "store/hooks";

export interface ITopActionsProps {
  itinerary: IReservationBase;
  locationState?: ILocationState;
}

const TopActions = ({ itinerary, locationState }: ITopActionsProps) => {
  const navigate = useNavigate();
  const itineraryId = itinerary.id;
  const propertyId = itinerary.propertyId;

  const user = useAppSelector((state) => state.Auth.user);
  const isSot = isStateOfTexasUser(user);

  const onBookAgain = () => {
    navigate(`/properties/${propertyId}${formatUrl(locationState || {})}`);
  };

  const handleDocument = () => {
    void Reservation.getDocument(itineraryId, "folio");
  };

  return (
    <Styled.TopActionContainer>
      {!isSot && (
        <Styled.TopActionButton onClick={handleDocument} data-testid="action-view-folio">
          <Typography variant="body/md" style={{ display: "flex", alignItems: "center" }}>
            <Icon name="file-lines" style={{ marginRight: 10 }} />
            View folio
          </Typography>
        </Styled.TopActionButton>
      )}

      <Styled.TopActionButton onClick={onBookAgain} data-testid="action-book-again">
        <Typography variant="body/md" style={{ display: "flex", alignItems: "center" }}>
          <Icon name="arrow-rotate-right" style={{ marginRight: 10 }} />
          Book again
        </Typography>
      </Styled.TopActionButton>
    </Styled.TopActionContainer>
  );
};

export default TopActions;
