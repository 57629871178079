import styled from "styled-components";

export const DownloadAppBanner = styled.div`
  margin-top: ${({ theme }) => theme.spacing[24]};
  border-radius: ${({ theme }) =>
    `${theme.borderRadius.lg} ${theme.borderRadius.lg} ${theme.borderRadius.lg} ${theme.borderRadius.lg}`};
  overflow: hidden;
  border-color: ${({ theme }) => theme.colors.borderSubtle};
  border-width: ${({ theme }) => theme.borderWidth.normal};
  border-style: solid;
  display: flex;
  justify-content: space-between;
  min-height: 180px;
`;

export const InstructionsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundInverse};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 70%;
  padding: ${({ theme }) => theme.spacing[20]};
`;

export const ImageContainer = styled.div`
  width: 30%;
  position: relative;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;
