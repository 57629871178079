import { useAppSelector } from "store/hooks";
import { Icon } from "@hotel-engine/common/Icon";
import { Progress } from "@hotel-engine/common/Progress";
import type { ICurrency } from "@hotel-engine/constants";
import type { IRewardsProfile } from "@hotel-engine/types/user";
import { formatCurrency, formatDate, formatNumber } from "@hotel-engine/utilities/formatters";
import config from "config";
import upcomingRewardsDates from "pages/Rewards/helpers";
import { Chip } from "@hotelengine/atlas-web";

import { setRewardsBarWidth } from "../helpers";
import * as Styled from "./rewardsStyles";

export interface IHeaderRewards {
  /** Callback to fire when user navigates to rewards */
  onNavigateToRewards: () => void;
  /** User's rewards profile object */
  rewardsProfile: IRewardsProfile;
  /** Boolean to return rewards or not */
  showRewards: boolean;
  /** currencyCode for business */
  currencyCode: ICurrency;
  /** show new visual update or not */
  $visualRefreshActive?: boolean;
}

const HeaderRewards = ({
  showRewards,
  rewardsProfile,
  onNavigateToRewards,
  currencyCode,
  $visualRefreshActive,
}: IHeaderRewards) => {
  const preferredDateFormat =
    useAppSelector((state) => state.Auth.user?.business.preferredDateFormat) || "mdy";
  if (!showRewards) return null;

  const { bookings, nextTier, spend, currentTier, points } = rewardsProfile;
  const { throughDate } = upcomingRewardsDates(rewardsProfile);

  const renderRewardsMenu = () => {
    // initial array of menu items
    const items = [
      <Styled.RewardsDropdownHeaderItem
        className={currentTier.name?.toLowerCase()}
        key="current-tier"
      >
        <div>
          <span>{currentTier.name || "N/A"}</span>
          <span>Through {formatDate(throughDate, "MMM DD YYYY", preferredDateFormat)}</span>
        </div>
        <div>
          <span>{formatNumber(points)}</span>
          <span className="right-align">Points</span>
        </div>
      </Styled.RewardsDropdownHeaderItem>,
    ];
    // check if next tier exists to add progress section to items array
    if (nextTier) {
      items.push(
        <Styled.RewardsProgressItem key="next-tier">
          <div className="progress-title">
            Unlock <strong>{nextTier.name}</strong>
          </div>
          <Styled.ProgressItem>
            <div className="sub-text">
              <span>Amount Spent</span>
              <span>
                {formatCurrency(spend, false, currencyCode)} /{" "}
                {formatCurrency(nextTier.spendUnlock, false, currencyCode)}
              </span>
            </div>
            <Progress
              percent={setRewardsBarWidth("spend", nextTier, spend, bookings)}
              showInfo={false}
            />
          </Styled.ProgressItem>
          <Styled.OrWrapper className={"orWrapper"}>
            <div />
            <span>or</span>
            <div />
          </Styled.OrWrapper>
          <Styled.ProgressItem>
            <div className="sub-text">
              <span>Completed Bookings</span>
              <span>
                {bookings} / {nextTier.bookingCountUnlock}
              </span>
            </div>
            <Progress
              percent={setRewardsBarWidth("bookings", nextTier, spend, bookings)}
              showInfo={false}
            />
          </Styled.ProgressItem>
        </Styled.RewardsProgressItem>
      );
    } else {
      // add vip section to items array if no nextTier
      items.push(
        <Styled.RewardsProgressItem key="vip-support">
          <div className="progress-title">
            Enjoy <strong>Maximum Perks</strong>
          </div>
          <Styled.PerkWrapper>
            <img src={config.cdnHost + "/assets/rewards/VIP_new.png"} alt="VIP" />
            <span className="support">Support</span>
          </Styled.PerkWrapper>
          <Styled.PerkWrapper>
            <Icon icon={["fas", "phone"]} />
            <span className="copy">720-409-3729</span>
          </Styled.PerkWrapper>
          <Styled.PerkWrapper>
            <Icon icon={["fas", "envelope"]} />
            <span className="copy">support@hotelengine.com</span>
          </Styled.PerkWrapper>
        </Styled.RewardsProgressItem>
      );
    }
    return (
      <Styled.RewardsDropdownMenu data-testid="rewards-dropdown-menu">
        {items}
        <Styled.RewardsDropdownItem onClick={onNavigateToRewards}>
          <span id="viewMyRewards">View my Rewards</span>
        </Styled.RewardsDropdownItem>
      </Styled.RewardsDropdownMenu>
    );
  };

  return (
    <Styled.DropdownWrapper
      overlay={renderRewardsMenu}
      getPopupContainer={() => document.getElementById("user-nav-wrapper") || document.body}
    >
      <Chip
        data-testid="rewardsNav"
        label={`${formatNumber(points)} pts`}
        leadingIcon="he-rewards--solid"
        size="lg"
        variant={$visualRefreshActive ? "outlined" : "filled"}
        color="achromatic"
      />
    </Styled.DropdownWrapper>
  );
};

export default HeaderRewards;
