import type { UseMutationOptions } from "react-query";
import { useMutation } from "react-query";

import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { ISearchRatesServiceOutput } from "@hotel-engine/types/search";
import config from "config";

import { endpoints } from "../constants";
import { useApi } from "../useApi";
import { useEffect, useRef } from "react";
import type { SearchTypeOptions } from "@hotel-engine/constants/search";

export interface ISearchMutationParams {
  checkIn: string;
  checkOut: string;
  guestCount: number;
  latitude?: number;
  linkedSearchId?: string;
  location?: string;
  locationId?: string;
  longitude?: number;
  market?: string;
  propertyIds?: number[];
  radius?: number;
  reqId?: string;
  retrySearchWithExpandedRadius?: boolean;
  roomCount: number;
  salesChannel?: string;
  showPropertyDataOnce?: boolean;
  userAgent?: string;
  searchType: SearchTypeOptions;
}

type UseSearchMutationOptions = Omit<
  UseMutationOptions<ISearchRatesServiceOutput, IErrorResponse, ISearchMutationParams, unknown>,
  "mutationFn"
>;

const REQUEST_CANCEL_REASON = "Members: Request aborted by user.";

export const useSearchMutation = (options: UseSearchMutationOptions = {}) => {
  const controllerRef = useRef<AbortController>();

  const post = useApi("post");

  const abort = () => {
    // Abort previous search request if it is still pending. Calling .abort() on
    // an already ended or cancelled request is a noop and will not cause an issue
    // https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
    controllerRef.current?.abort(REQUEST_CANCEL_REASON);
  };

  // Abort the request on unmount
  useEffect(() => abort, []);

  const mutation = useMutation<ISearchRatesServiceOutput, IErrorResponse, ISearchMutationParams>(
    (params) => {
      // Abort existing request
      abort();

      controllerRef.current = new AbortController();

      return post(
        endpoints.search,
        params,
        {
          baseURL: config.supplyRepoHost,
          signal: controllerRef.current.signal,
        },
        false,
        true
      );
    },
    options
  );

  return {
    mutation,
    abort,
  };
};
