import type { ReactNode } from "react";

import { formatCurrency, formatDate } from "@hotel-engine/utilities";
import type { IFilters, TTripsDispatchContext } from "pages/Trips/context";
import { useAppSelector } from "store/hooks";
import { Box, Button, Icon, Typography } from "@hotelengine/atlas-web";

import * as Styled from "./styles";

interface IPillProps {
  children: ReactNode;
  id: string;
  onClose?: () => void;
}

const Pill = ({ children, id, onClose }: IPillProps) => (
  <Styled.Pill data-testid={`filterPill-${id}`} onClick={onClose}>
    <Typography variant="body/xs" color="foregroundInverse">
      {children}
    </Typography>
    <Icon name="xmark" size="sm" />
  </Styled.Pill>
);

interface IFilterPillsProps {
  filters: IFilters;
  handleFilterSelection: (e: unknown, value?: unknown | unknown[]) => void;
  setSearchValue: (value: string) => void;
  tripsDispatch: TTripsDispatchContext;
  lodgingOnly: boolean;
}

export const FilterPills = ({
  filters,
  handleFilterSelection,
  setSearchValue,
  tripsDispatch,
  lodgingOnly,
}: IFilterPillsProps) => {
  const user = useAppSelector((state) => state.Auth.user);
  const baseIgnoreFilters = ["status", "startTimeLt", "totalGt", "travelers", "locations"];
  const ignoreFilters = lodgingOnly ? ["booking_type", ...baseIgnoreFilters] : baseIgnoreFilters;

  const preferredDateFormat = user?.business?.preferredDateFormat ?? "mdy";

  const currencyCode = user?.business?.currencyCode || "USD";

  const toDisplayText = (key) => {
    const keys = {
      pendingModificationOnly: "Pending Modification",
      manual_bookings: "Assisted Bookings",
      contractType: "Group Bookings Only",
      refundableOnly: "Refundable",
      startTimeGt: formatDate(filters.startTimeGt, "ddd, MMM D", preferredDateFormat),
      startTimeLt: formatDate(filters.startTimeLt, "ddd, MMM D", preferredDateFormat),
      totalLt: `${filters.totalLt}`,
      totalGt: `${filters.totalGt}`,
      search: filters.search,
      unverified: "Unverified Checkout",
      booking_type: "Trip type",
    };

    return keys[key];
  };

  const handleOnPillClick = (key) => {
    if (key === "search") {
      setSearchValue("");
    }

    handleFilterSelection({ key });
  };

  const handleOnDatePillClick = () => {
    tripsDispatch({
      type: "filter",
      filters: [{ key: "startTimeGt" }, { key: "startTimeLt" }],
    });
  };

  const handleOnTotalPillClick = () => {
    tripsDispatch({
      type: "filter",
      filters: [{ key: "totalGt" }, { key: "totalLt" }],
    });
  };

  const handleOnSearchArrayPillClick = (term) => {
    const updatedSearch = [...(filters.search ?? [])].filter((s) => s !== term);
    tripsDispatch({
      type: "filter",
      filters: [{ key: "search", value: updatedSearch }],
    });
  };

  const handleDepartmentPillClick = (value: string) => {
    handleFilterSelection({ key: "department" }, [value]);
  };

  const handleOnlyMyTripsPillClick = () => {
    tripsDispatch({
      type: "filter",
      filters: [{ key: "onlyMyTrips" }],
    });
  };

  const handleOnlyCancelledTripsPillClick = () => {
    tripsDispatch({
      type: "filter",
      filters: [{ key: "onlyCancelledTrips" }],
    });
  };

  /**  If we are only showing lodging, we don't want to show the filter for booking_type, and we also need to make sure
   * that if we select other filters and clear them, we don't clear the booking_type filter
   */
  const filterKeys = Object.keys(filters);
  const hideClear = lodgingOnly && filterKeys.length === 1 && filterKeys.includes("booking_type");

  const handleClearFilters = () => {
    if (lodgingOnly) {
      const clearKeys = filterKeys.filter((key) => key !== "booking_type");
      const filtersToClear = clearKeys.map((key) => ({ key: key as keyof IFilters }));
      tripsDispatch({
        type: "filter",
        filters: filtersToClear,
      });
    } else {
      tripsDispatch({ type: "clearFilters" });
    }
  };

  return (
    <Styled.PillBox>
      {Object.keys(filters).map((key) => {
        if (
          ignoreFilters.includes(key) ||
          !filters[key] ||
          (Array.isArray(filters[key]) && !filters[key].length)
        ) {
          return null;
        }

        if (key === "startTimeGt") {
          return (
            <Pill key={key} id="startTime" onClose={() => handleOnDatePillClick()}>
              {`${toDisplayText("startTimeGt")} - ${toDisplayText("startTimeLt")}`}
            </Pill>
          );
        }

        if (key === "totalLt") {
          return (
            <Pill key={key} id="total" onClose={() => handleOnTotalPillClick()}>
              {`${formatCurrency(toDisplayText("totalGt"), false, currencyCode)} - ${formatCurrency(
                toDisplayText("totalLt"),
                false,
                currencyCode
              )}`}
            </Pill>
          );
        }

        if (key === "search" && Array.isArray(filters.search)) {
          return filters.search.map((term) => (
            <Pill key={term} id={term} onClose={() => handleOnSearchArrayPillClick(term)}>
              {term}
            </Pill>
          ));
        }

        if (key === "booking_type") {
          return (
            <Pill key={key} id={key} onClose={() => handleOnPillClick(key)}>
              {`${toDisplayText(key)} - ${filters[key]}`}
            </Pill>
          );
        }

        if (key === "onlyMyTrips") {
          return (
            <Pill key={key} id={key} onClose={() => handleOnlyMyTripsPillClick()}>
              Only my trips
            </Pill>
          );
        }

        if (key === "onlyCancelledTrips") {
          return (
            <Pill key={key} id={key} onClose={() => handleOnlyCancelledTripsPillClick()}>
              Only cancelled trips
            </Pill>
          );
        }

        if (key === "department" && !!filters.department?.length) {
          return filters.department.map((department) => (
            <Pill
              key={department}
              id={department}
              onClose={() => handleDepartmentPillClick(department)}
            >
              {department}
            </Pill>
          ));
        }

        return (
          <Pill key={key} id={key} onClose={() => handleOnPillClick(key)}>
            {toDisplayText(key)}
          </Pill>
        );
      })}
      {!hideClear &&
        !!Object.keys(filters).filter((key) =>
          Array.isArray(filters[key]) ? filters[key].length : filters[key]
        ).length && (
          <Box marginTop={4}>
            <Button size="sm" id="clearFilters" variant="plain" onClick={handleClearFilters}>
              Clear Filters
            </Button>
          </Box>
        )}
    </Styled.PillBox>
  );
};
