import styled from "styled-components";

const DetailsContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing[2]};
  margin-bottom: ${({ theme }) => theme.spacing[24]};
  padding-left: ${({ theme }) => theme.spacing[24]};
  padding-right: ${({ theme }) => theme.spacing[24]};
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing[20]};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FieldLine = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: ${({ theme }) => theme.spacing[16]};
`;

const FieldsContainer = styled.div`
  display: grid;
  row-gap: ${({ theme }) => theme.spacing[8]};
`;

export { DetailsContainer, HeaderContainer, FieldLine, FieldsContainer };
