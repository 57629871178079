import * as yup from "yup";

import { CarsSearchFormSchema } from "@hotel-engine/utilities";

export const yupValidate = () => {
  return yup
    .object()
    .shape(CarsSearchFormSchema.shape)
    .test(CarsSearchFormSchema.rules.pickupValidLocation())
    .test(CarsSearchFormSchema.rules.dropoffValidLocation())
    .test(CarsSearchFormSchema.rules.pickupDateTimeMin())
    .test(CarsSearchFormSchema.rules.dropoffBeforePickup())
    .test(CarsSearchFormSchema.rules.validAge());
};
