import { ampli } from "ampli";

import { useLocation, useNavigate } from "@hotel-engine/lib/react-router-dom";
import { Box, Divider, Typography } from "@hotelengine/atlas-web";
import { routes } from "@hotel-engine/constants";

import { useAppSelector } from "store/hooks";
import { PriceBreakdownField } from "pages/Checkout/shared/PriceBreakdownField";

import SummaryOfChargesItem from "../../../shared/SummaryOfChargesItem";
import { useCarModificationsContext } from "../../../ModificationsContext";
import { useCarSummaryOfCharges } from "./useCarSummaryOfCharges";
import * as Styled from "./styles";
import TaxesAndFees from "./TaxesAndFees";

const PaymentInfo = () => {
  const navigate = useNavigate();
  const rrLocation = useLocation();
  const user = useAppSelector((state) => state.Auth?.user);
  const isViewOnlyTraveler = user?.role === "view_only_traveler";

  const { isPreview, reservation } = useCarModificationsContext();
  const { items, totalCharge } = useCarSummaryOfCharges(reservation?.charges);

  const handleReportBillingIssue = () => {
    navigate(`${routes.billingIssue}/${reservation?.bookingNumber}`, {
      state: {
        reservation,
        prevPath: rrLocation.pathname + rrLocation.search,
      },
    });
    ampli.clickReportBillingIssue({ bookingId: reservation?.bookingNumber });
  };

  if (!reservation?.charges) return null;

  return (
    <Styled.SummaryOfChargesPaddedContainer $isPreview={isPreview}>
      <Typography variant="uppercase/xs-strong" marginBottom={8}>
        Payment info
      </Typography>

      {items.map((item) => {
        if (item.label === "Taxes and fees") {
          return (
            <Box key={`basic-${item.label}`} marginTop={4} marginBottom={4}>
              <PriceBreakdownField
                name="Taxes and fees"
                cost={item.value}
                details={
                  <TaxesAndFees charges={reservation.charges} formattedTaxesFees={item.value} />
                }
              />
            </Box>
          );
        }

        return (
          <div key={`basic-${item.label}`}>
            <Box marginTop={4} marginBottom={4}>
              <SummaryOfChargesItem {...item} typography="body/md" />
            </Box>
          </div>
        );
      })}
      <Box marginTop={8} marginBottom={8}>
        <Divider />
      </Box>
      <SummaryOfChargesItem
        label="Total charges"
        labelColor="foregroundPrimary"
        value={totalCharge}
        typography="body/md-strong"
        isBold
      />
      {!isViewOnlyTraveler && (
        <Box marginTop={16} display="flex" justifyContent="center">
          <Typography
            variant="link/sm"
            color="foregroundPrimary"
            onClick={handleReportBillingIssue}
            style={{ cursor: "pointer" }}
          >
            Report billing issue
          </Typography>
        </Box>
      )}
    </Styled.SummaryOfChargesPaddedContainer>
  );
};

export default PaymentInfo;
