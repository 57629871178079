import type { ILodgingBooking, ISupportCancelRequest } from "@hotel-engine/types/supportCase";
import * as Styled from "../../styles";
import Typography from "@hotel-engine/common/Typography";
import { formatCurrency } from "@hotel-engine/utilities";
import { useAppSelector } from "store/hooks";
import type { ISupportAlertBannerStatus } from "../../types";

export interface ISupportCancelRequestProps {
  details: ISupportCancelRequest;
  booking: ILodgingBooking;
  bannerStatus: ISupportAlertBannerStatus;
}

function CancelRequest({ details, booking, bannerStatus }: ISupportCancelRequestProps) {
  const user = useAppSelector((state) => state.Auth.user);

  const { refundableTotal, refundType } = details;
  const isMultiRoom = booking.rooms.length > 1;
  const currencyCode = user?.business?.currencyCode || "USD";
  return (
    <>
      {!!isMultiRoom &&
        booking.rooms.map((room, idx: number) => (
          <Styled.Row key={`room-${room.contractNumber}`}>
            <Typography variant="small">Room {idx + 1}</Typography>
            <Typography variant="body-medium">{room.contractNumber}</Typography>
          </Styled.Row>
        ))}
      {bannerStatus === "success" && !!refundableTotal && (
        <>
          <Styled.Row>
            <Typography variant="body-medium">Refunded:</Typography>
            <Typography variant="body-medium">
              -{formatCurrency(refundableTotal || 0, true, currencyCode)}
            </Typography>
          </Styled.Row>
        </>
      )}
      {bannerStatus === "success" && !!refundType && (
        <>
          <Styled.Row>
            <Typography variant="body-medium">Refunded to:</Typography>
            <Typography variant="body-medium">{refundType}</Typography>
          </Styled.Row>
        </>
      )}
    </>
  );
}

export default CancelRequest;
