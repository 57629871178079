import styled from "styled-components";

import { colors } from "@hotel-engine/styles";

export const NoTripsMessageLegacy = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.grey[50]};
  min-height: 500px;
  height: 100%;

  & > svg {
    width: 35px;
  }
`;

export const Headline = styled.span`
  margin-top: 24px;
  font-weight: ${({ theme }) => theme.legacy.fontWeight.bold};
  text-transform: capitalize;
`;
