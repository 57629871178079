import { Box, Divider, Typography } from "@hotelengine/atlas-web";

import type { ICurrency } from "@hotel-engine/constants";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import { formatCurrency } from "@hotel-engine/utilities";

import * as Styled from "../../styles";

interface IPopoverContentProps {
  reservation: IReservationBase;
  currencyCode: ICurrency;
}

const PopoverContent = ({ reservation, currencyCode }: IPopoverContentProps) => (
  <Box padding={4}>
    <Styled.PopoverLine>
      <Typography variant="body/sm" color="foregroundPrimary">
        Flex coverage
      </Typography>
      <Typography variant="body/sm" color="foregroundPrimary">
        {formatCurrency(reservation?.charges?.flexCostPerRoom, true, currencyCode)} x{" "}
        {reservation.roomCount} rooms
      </Typography>
    </Styled.PopoverLine>
    <Divider />
    <Styled.PopoverLine>
      <Typography variant="body/sm-strong" color="foregroundPrimary">
        Total
      </Typography>
      <Typography variant="body/sm-strong" color="foregroundPrimary">
        {formatCurrency(reservation?.charges?.flexCost, true, currencyCode)}
      </Typography>
    </Styled.PopoverLine>
  </Box>
);

export default PopoverContent;
