import Button from "@hotel-engine/common/Button";
import { Icon } from "@hotel-engine/common/Icon";

import * as Styled from "./styles";

export enum NuclearErrorRedirectOption {
  DEFAULT,
  HOMEPAGE,
}
interface INuclearErrorModal {
  /** should show modal */
  hasPageError: boolean;
  redirectOption?: NuclearErrorRedirectOption;
}

const handleRedirect = (option) => {
  if (!option) {
    globalThis.location.reload();
    return;
  }

  // Don't let users redirect outside of flight seatmap, ever
  if (globalThis.location.pathname === "/flights/seatmap-portal") {
    globalThis.location.reload();
    return;
  }

  if (option === NuclearErrorRedirectOption.HOMEPAGE) {
    globalThis.location.assign("/");
  } else {
    globalThis.location.reload();
  }
};

const NuclearErrorModal = ({ hasPageError, redirectOption }: INuclearErrorModal) => {
  return (
    <Styled.NuclearErrorModal
      visible={hasPageError}
      closable={false}
      maskClosable={false}
      footer={null}
      width={"auto"}
      title={
        <header>
          <Icon icon={["far", "exclamation-circle"]} size="lg" />
          <h2>Sorry, we are unable to retrieve your information.</h2>
        </header>
      }
    >
      <p>Something went wrong. Please reload this page to try again.</p>
      <footer>
        <Button type="primary" onClick={() => handleRedirect(redirectOption)}>
          Reload
        </Button>
      </footer>
    </Styled.NuclearErrorModal>
  );
};

export default NuclearErrorModal;
