import { useMemo } from "react";

import useIsContractExtendable from "@hotel-engine/hooks/useIsContractExtendable";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import isTripLateCheckinAvailable from "@hotel-engine/utilities/helpers/isTripLateCheckinAvailable";
import { shouldShowModificationActions } from "@hotel-engine/utilities/helpers/shouldShowModificationActions";

import { useAppSelector } from "store/hooks";

export const useUpcomingActiveActions = (itinerary: IReservationBase) => {
  const user = useAppSelector((state) => state.Auth.user);
  const isViewOnlyTraveler = user?.role === "view_only_traveler";
  const isCancelled = itinerary.status === "cancelled";
  const {
    modifiable: isModifiable,
    cancellable: isCancellable,
    flexShortenable: isFlexShortenable,
  } = itinerary.modificationActions;

  const { isContractExtendable: showExtendTripOption } = useIsContractExtendable();

  const showModificationActions = shouldShowModificationActions(itinerary, user);

  const canModifyOrCancel = !isCancelled && (isModifiable || isCancellable || isFlexShortenable);

  const canCancel = !isCancelled && isCancellable;

  const showLateCheckInFeature = useMemo(
    () =>
      isTripLateCheckinAvailable(itinerary.status, itinerary.checkIn, itinerary.propertyTimezone),
    [itinerary.status, itinerary.checkIn, itinerary.propertyTimezone]
  );

  const isEligibleToModifyOrCancel =
    showModificationActions && canModifyOrCancel && !isViewOnlyTraveler;
  const showModifyReservationButton = isEligibleToModifyOrCancel;
  const showLateCheckInAction = showModificationActions && showLateCheckInFeature;
  const showExtendReservationAction =
    showModificationActions && showExtendTripOption && !isViewOnlyTraveler;
  const showVOTViewFolio = !!isViewOnlyTraveler;
  const showCancelBooking = showModificationActions && canCancel && !isViewOnlyTraveler;

  const buttonArray = [
    showModifyReservationButton,
    showLateCheckInAction,
    showExtendReservationAction,
    showVOTViewFolio,
    showCancelBooking,
  ];

  const onlyOneItemExists = buttonArray.filter(Boolean).length === 1;

  return {
    showModifyReservationButton,
    showLateCheckInAction,
    showExtendReservationAction,
    showVOTViewFolio,
    showCancelBooking,
    onlyOneItemExists,
  };
};
