import { Skeleton } from "@hotel-engine/common/Skeleton";

import * as Styled from "./styles";
import { TripsListItemContent } from "../TripsListItemContent";
import type { ITrip } from "@hotel-engine/types/trips";
import { useAppSelector } from "store/hooks";
import { ampli } from "ampli";

interface ITripsListItemProps {
  item: ITrip;
  loading: boolean;
  selected?: boolean;
  onSelection: (item: ITrip) => void;
  displayChevron?: boolean;
}

const TripsListItem = ({
  item,
  loading,
  selected,
  onSelection,
  displayChevron,
}: ITripsListItemProps) => {
  const user = useAppSelector((state) => state.Auth.user);

  return (
    <Styled.TripsListItem
      onClick={() => {
        ampli.clickTripCard({
          tripId: item.id,
          userId: user?.id,
        });
        onSelection(item);
      }}
      selected={!!selected}
    >
      <Styled.GlobalListItemStyles />
      <Skeleton avatar title loading={loading} active>
        <TripsListItemContent trip={item} />
      </Skeleton>
      {!!displayChevron && <Styled.Chevron name="chevron-right" size="md" />}
    </Styled.TripsListItem>
  );
};

export default TripsListItem;
