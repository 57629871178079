import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { buttonReset, colors } from "@hotel-engine/styles";

export const Title = styled.p`
  ${typographyMixin("xlarge")};
  margin: 0;
`;

export const DescriptionText = styled.p`
  ${typographyMixin("small")};
  font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
  color: ${colors.grey[500]};
  margin-top: ${({ theme }) => theme.legacy.space(1)};
`;

export const HelpText = styled.p`
  ${typographyMixin("small")};
  font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
  color: ${colors.grey[500]};
  margin-top: ${({ theme }) => theme.legacy.space(1)};
  text-align: center;
`;

export const PhoneNumberText = styled.span`
  ${typographyMixin("small")};
  font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
  color: ${colors.black};
  margin-top: ${({ theme }) => theme.legacy.space(1)};
  text-align: center;
`;
export const TextButton = styled(Button)`
  ${buttonReset};
  ${typographyMixin("small")};
  font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
  background: none !important;
  color: ${colors.retroBlue};
  :disabled {
    color: ${colors.grey[300]};
    :hover {
      color: ${colors.grey[300]};
    }
  }
`;

export const SubmitButton = styled(Button)`
  display: block;
  width: 100%;
`;

export const ResendErrorMessage = styled.p`
  ${typographyMixin("body-medium")};
  color: ${colors.tomato};
`;
