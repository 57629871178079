export const AppStoreURL = {
  // FIXME: [WP-695] change to https://apps.apple.com/us/app/engine/id1067715122 after rebrand announcement
  iOS: "https://apps.apple.com/us/app/hotel-engine/id1067715122",
  Android: "market://details?id=com.hotelengine.app",
} as const;

const inferAppStoreUrl = (): keyof typeof AppStoreURL =>
  navigator.userAgent.match(/safari/i) ? "iOS" : "Android";

export const forceNavigateToAppStore = (deviceType?: keyof typeof AppStoreURL) => {
  globalThis.location.href = AppStoreURL[deviceType ?? inferAppStoreUrl()];
};
