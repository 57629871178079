import type { IconProp } from "@fortawesome/fontawesome-svg-core";

import { routes } from "@hotel-engine/constants";
import config from "config";

type IImage = {
  alt: string;
  src: string;
};

type IMobileNavItem = {
  icon?: IconProp | undefined;
  img?: IImage | undefined;
  route: string;
  title: string;
};

export const returnMainNav = (shouldShowReferAFriend, shouldShowTravelCredits, user) => {
  const mainNav = [
    {
      route: "/",
      icon: ["far", "search"],
      title: "Book",
    },
    {
      route: routes.trips.upcoming,
      icon: ["fal", "suitcase"] as IconProp,
      title: "Trips",
    },
  ] as IMobileNavItem[];

  if (shouldShowReferAFriend) {
    mainNav.push({
      route: routes.referFriends.base,
      icon: ["far", "gift"] as IconProp,
      title: "Refer a Friend",
    });
  }

  if (shouldShowTravelCredits) {
    mainNav.push({
      route: routes.travelCredits,
      icon: ["fas", "money-bill-wave-alt"] as IconProp,
      title: "Travel Credits",
    });
  }

  if (user.rewardsMember && !user.business?.disableRewards) {
    mainNav.push({
      route: routes.rewards,
      img: {
        src: `${config.cdnHost}/assets/rewards/Reward_Icon_12.svg`,
        alt: "Rewards icon",
      },
      title: "My Rewards",
    });
  }

  mainNav.push({
    route: routes.myPropertiesPreferred,
    icon: ["fas", "heart"] as IconProp,
    title: "My Properties",
  });

  if (user.role !== "coordinator" && user.hasReportingAccess) {
    mainNav.push({
      route: routes.trends.all,
      icon: ["fas", "chart-bar"] as IconProp,
      title: "Trends",
    });
  }
  mainNav.push({
    route: `${routes.groups.dashboard}/open`,
    icon: ["fas", "user-group"] as IconProp,
    title: "Groups",
  });

  return mainNav;
};

export const returnSettings = (isAuthenticated = false, isAccountSecurityTabEnabled = false) => {
  const settings: { route: string; icon: IconProp; title: string }[] = [
    {
      route: routes.settings.profile,
      icon: ["fas", "user"] as IconProp,
      title: "My Profile",
    },
    {
      route: routes.settings.guestInformation,
      icon: ["fas", "user-friends"] as IconProp,
      title: "Guest Information",
    },
    {
      route: routes.settings.paymentMethods,
      icon: ["fal", "credit-card"] as IconProp,
      title: "Payment Methods",
    },
  ];

  settings.push({
    route: routes.userNotifications,
    icon: ["fas", "bell"] as IconProp,
    title: "Notification Settings",
  });

  if (isAccountSecurityTabEnabled && isAuthenticated) {
    settings.push({
      route: routes.settings.accountSecurity,
      icon: ["fas", "lock"] as IconProp,
      title: "Account Security",
    });
  }

  return settings;
};
