import { useTranslation } from "react-i18next";

export const useLoyaltyInfo = () => {
  const { t } = useTranslation("iata_loyalty");

  return {
    getLoyaltyNameByIataAirlineCode: (IataAirlineKey: string) =>
      t(`iata_loyalty.name.${IataAirlineKey}`, {
        defaultValue: IataAirlineKey,
      }),
    getAllianceNameByIataAirlineCode: (IataAirlineKey: string) =>
      t(`iata_loyalty.alliance_name.${IataAirlineKey}`),
  };
};
