/* eslint-disable @typescript-eslint/no-restricted-imports */
import styled from "styled-components";
import { DatePicker } from "antd";

const RangePicker = styled(DatePicker.RangePicker)`
  & .ant-calendar-picker-input.ant-input {
    height: 40px;
    border-radius: ${({ theme }) => theme.borderRadius.pill};
  }

  &.selected .ant-calendar-range-picker-separator {
    margin-top: ${({ theme }) => theme.spacing[4]}
  }

  &.not-selected .ant-calendar-range-picker-separator {
    display: none;
  }

  & .ant-calendar-picker-icon {
    display: none;
  }
`;

export type { RangePickerValue } from "antd/lib/date-picker/interface";
export { RangePicker };
