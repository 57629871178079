import type { ReactNode } from "react";

import * as Styled from "./styles";

interface ITagProps {
  active: boolean;
  children: ReactNode;
}

export const Tag = ({ active, children }: ITagProps) => {
  return (
    <Styled.Tag active={active} variant="body/sm">
      {children}
    </Styled.Tag>
  );
};
