import { Alert, Box, Typography } from "@hotelengine/atlas-web";

import type { IReservationRoom } from "@hotel-engine/types/booking";

import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { CANCELLATION_COPY, DEFAULT_COPY } from "./constants";

export const MissingRooms = ({ rooms }: { rooms: IReservationRoom[] }) => {
  const {
    state: { modificationView },
  } = useModificationsContext();
  const { typographyVariants } = useTypographyScaling();

  const getTextByType = () => {
    switch (modificationView) {
      case "cancel": {
        return CANCELLATION_COPY;
      }
      default: {
        return DEFAULT_COPY;
      }
    }
  };

  const bannerContent = (
    <>
      <Typography variant={typographyVariants.body}>{getTextByType()}</Typography>
      <Box paddingLeft={20} paddingRight={24} paddingTop={8} marginBottom={0} as="ul">
        {rooms.map((room) => {
          const roomOccupants = room.occupants
            .map((occupant) => `${occupant.firstName} ${occupant.lastName}`)
            .join(", ");
          return (
            <Typography as="li" key={room.contractNumber} variant={typographyVariants.body}>
              Room {room.roomPosition} - {room.contractNumber} - {roomOccupants}
            </Typography>
          );
        })}
      </Box>
    </>
  );

  return (
    <Box data-testid="prev-modified-rooms" marginBottom={24} marginTop={16}>
      <Alert sentiment="helpful" title="Why are some rooms missing?">
        {bannerContent}
      </Alert>
    </Box>
  );
};
