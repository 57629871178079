import { useMemo } from "react";

import type { IFlightRefund } from "@hotel-engine/types/itinerary";
import { formatCurrency } from "@hotel-engine/utilities";

import type { ISummaryOfChargesItemProps } from "../../../shared/SummaryOfChargesItem";

interface IDetails {
  key: string;
  items: ISummaryOfChargesItemProps[];
}

const typeMap = {
  normal_cancellation: "Cancellation",
  flex_cancellation: "Cancellation",
  manual_cancellation: "Cancellation",
  guest_only_cancellation: "Cancellation",
};

const getRefundMethod = (refund: IFlightRefund) => {
  if (Number(refund.airlineCreditAmount) > 0) {
    return "Airline credit";
  } else if (Number(refund.originalFormOfPayment) > 0) {
    return "Original form of payment";
  }
  return "-";
};

export const useFlightRefundDetails = (refunds?: IFlightRefund[]) => {
  const hasAirlineCredit = useMemo(
    () => (refunds || []).some(({ airlineCreditAmount }) => Number(airlineCreditAmount) > 0),
    [refunds]
  );

  const details = useMemo(() => {
    return (refunds || []).reduce<IDetails[]>((acc, item) => {
      acc.push({
        key: item.createdAt,
        items: [
          {
            label: "Refund type",
            value: typeMap[item.action],
          },
          {
            label: "Refund method",
            value: getRefundMethod(item),
          },
          {
            label: "Refund amount",
            value: formatCurrency(item.totalRefund),
          },
        ].filter((refund) => Boolean(refund.value)),
      });

      return acc;
    }, []);
  }, [refunds]);

  return { details, hasAirlineCredit };
};
