import { Box, Typography } from "@hotelengine/atlas-web";

import * as Styled from "./styles";

interface IFlightGraphProps {
  departureIata: string;
  arrivalIata: string;
  departureTime: string;
  arrivalTime: string;
  totalFlightTime: string;
}

export const FlightGraph = ({
  departureIata,
  arrivalIata,
  departureTime,
  arrivalTime,
  totalFlightTime,
}: IFlightGraphProps) => {
  return (
    <Styled.FlightGraphContainer>
      <Box display="flex" flexDirection="column">
        <Typography variant="body/md-strong">{departureTime}</Typography>
        <Typography variant="body/md">{departureIata}</Typography>
      </Box>
      <Styled.GraphLineContainer>
        <Styled.GraphLine />
        <Styled.GraphLineDurationText>{totalFlightTime}</Styled.GraphLineDurationText>
        <Styled.GraphLine />
      </Styled.GraphLineContainer>
      <Styled.ArrivalsTextContainer display="flex" flexDirection="column">
        <Typography variant="body/md-strong">{arrivalTime}</Typography>
        <Typography variant="body/md">{arrivalIata}</Typography>
      </Styled.ArrivalsTextContainer>
    </Styled.FlightGraphContainer>
  );
};
