import styled from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import type { TypographyProps } from "@hotelengine/atlas-web";
import { Typography } from "@hotelengine/atlas-web";

export const RoomDetails = styled.div<{ $isPreview: boolean }>`
  margin-top: ${({ $isPreview, theme }) => (!$isPreview ? theme.spacing[40] : theme.spacing[24])};
  margin-bottom: ${({ $isPreview, theme }) => (!$isPreview ? theme.spacing[40] : theme.spacing[24])};
`;

export const DetailsList = styled.div`
  ${flexbox({
    direction: "column",
  })}

  padding-top: ${({ theme }) => theme.spacing[20]};
  gap: ${({ theme }) => theme.spacing[8]};
`;

export const ListItem = styled.div<{ $isPreview: boolean }>`
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: ${({ theme }) => theme.spacing[16]};
`;

export const ShowRoomsContainer = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing[20]};
  text-align: left;
`;

export const ShowRoomsLink = styled<React.FC<TypographyProps>>(Typography)`
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const RoomSeparator = styled.div`
  height: 1px;
  border-top: 1px dashed ${({ theme }) => theme.colors.borderSubtle};
  margin: ${({ theme }) => `${theme.spacing[24]} ${theme.spacing[0]} ${theme.spacing[0]}`};
`;
