import { useCallback } from "react";
import { searchPageActions } from "store/Search/SearchPage";
import { selectSpecificSearchDismissed } from "store/Search/SearchPage/selectors";
import { selectSpecificSearchResult } from "store/Search/SearchResults/selectors";
import { useAppDispatch, useAppSelector } from "store/hooks";

export const useDismissSpecificSearch = () => {
  const specificSearchDismissed = useAppSelector(selectSpecificSearchDismissed);
  const specificSearchResult = useAppSelector(selectSpecificSearchResult);

  const dispatch = useAppDispatch();

  const dismissSpecificSearch = useCallback(() => {
    /**if specific search is on & location is a property & specific search hasn't been dismissed, then dismiss it */
    if (!specificSearchDismissed && !!specificSearchResult) {
      dispatch(searchPageActions.setSpecificSearchDismissed(true));
    }
  }, [dispatch, specificSearchDismissed, specificSearchResult]);

  return dismissSpecificSearch;
};
