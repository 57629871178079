import config from "config";
import { Button, Typography } from "@hotelengine/atlas-web";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import { useAppSelector } from "store/hooks";
import * as Styled from "./styles";
import type { IFilters, TripsFilterStatus } from "../../context";
import { useTripsContext } from "../../context";
import { ampli } from "ampli";

const votNoTripsCopy = (status: TripsFilterStatus) => {
  switch (status) {
    case "all": {
      return "No trips";
    }
    case "today": {
      return "No trips today";
    }
    default: {
      return `No ${status} trips`;
    }
  }
};

const NoTripsMessage = ({ isCalendarView }: { isCalendarView?: boolean }) => {
  const user = useAppSelector((state) => state.Auth?.user);
  const isVOT = user?.role === "view_only_traveler";
  const navigate = useNavigate();
  const { state: tripsState, dispatch: tripsDispatch, setSearchValue } = useTripsContext();
  const { filters, status } = tripsState;

  const filteredFilters = isCalendarView
    ? Object.keys(filters).filter((key) => key !== "booking_type")
    : Object.keys(filters);

  const navigateToDashboard = () => {
    navigate("/");
    ampli.clickLetsBookATrip({
      userId: user?.id,
    });
  };

  const clearTripsFilters = () => {
    setSearchValue("");

    const filtersToClear = filteredFilters.map((key) => ({ key: key as keyof IFilters }));
    tripsDispatch({
      type: "filter",
      filters: filtersToClear,
    });
  };

  if (!!filteredFilters.length) {
    return (
      <Styled.NoTripsMessage data-testid="no-trips-results" $isCalendarView={isCalendarView}>
        <img src={`${config.cdnHost}/assets/trips/empty-trips-filters.svg`} alt="No Trips" />
        <Typography variant="heading/md" color="foregroundPrimary" data-testid="no-trips-filters">
          No trips due to filters
        </Typography>
        <Button id="clear-filters" onClick={clearTripsFilters}>
          Clear filters
        </Button>
      </Styled.NoTripsMessage>
    );
  }

  return (
    <Styled.NoTripsMessage data-testid="no-trips-results" $isCalendarView={isCalendarView}>
      <img src={`${config.cdnHost}/assets/trips/empty-trips.svg`} alt="No Trips" />
      <Typography variant="heading/md" color="foregroundPrimary">
        {!!isVOT ? votNoTripsCopy(status) : "Feeling grounded?"}
      </Typography>
      {!isVOT && (
        <Button id="book-trip" onClick={navigateToDashboard}>
          Let's book a trip
        </Button>
      )}
    </Styled.NoTripsMessage>
  );
};

export default NoTripsMessage;
