import type { Moment } from "moment";
import { formatDate } from "@hotel-engine/utilities";

export const getRoomAndGuestsLabel = ({
  rooms,
  guests,
}: {
  rooms: number;
  guests: number;
}) => `${rooms} ${rooms <= 1 ? "room" : "rooms"}, ${guests} ${guests <= 1 ? "guest" : "guests"}`;

export const stringifyDate = (date: Moment | null) =>
  date ? formatDate(date, "MM-DD-YYYY", "mdy") : null;
