import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { UseQueryOptions } from "react-query";

import { endpoints } from "../constants";
import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";

export interface IAutocompleteFavoriteQueryParams {
  limit?: number;
  offset?: number;
  include_preferred?: boolean;
}

export interface IAutocompleteFavoritePropertiesQueryConfig<T> {
  options?: UseQueryOptions<T, IErrorResponse, T>;
  params?: IAutocompleteFavoriteQueryParams;
}

const useAutocompleteFavoritePropertiesQuery = <T>({
  options = {},
  params = {},
}: IAutocompleteFavoritePropertiesQueryConfig<T> = {}) => {
  const get = useApi("get");

  const queryKey = [endpoints.autoCompleteFavoriteProperties, ...Object.values(params)];
  const queryFn = () => get<T>(endpoints.autoCompleteFavoriteProperties, params);

  return useExtendedQuery<T, IErrorResponse>(queryKey, queryFn, options);
};

export { useAutocompleteFavoritePropertiesQuery };
