import { createContext, useContext, useEffect, useReducer, useState } from "react";

import { useNavigate, useLocation } from "@hotel-engine/lib/react-router-dom";

import { routes } from "@hotel-engine/constants";
import { usePostCheckoutActions } from "@hotel-engine/contexts/PostCheckoutActionsContext";
import { useLocalStorage } from "@hotel-engine/hooks/useLocalStorage";
import Reservation from "@hotel-engine/services/Reservation";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import { camelToSnake } from "@hotel-engine/utilities/formatters";
import config from "config";
import { apiTripTypesLegacy, viewTypes } from "./constants";
import { Unsafe } from "@hotel-engine/data";

export const viewTripTypes = {
  all: "all",
  visiting: "active",
  cancelled: "cancelled",
  completed: "past",
  booked: "upcoming",
} as const;

export type TripsApiStatus = keyof typeof viewTripTypes;
export type TripsFilterStatus = (typeof viewTripTypes)[keyof typeof viewTripTypes];

interface ICounts {
  active: number;
  all: number;
  cancelled: number;
  past: number;
  upcoming: number;
}

export interface ISort {
  column: string;
  direction: "asc" | "desc";
}

export interface IFilters {
  checkInGt?: string;
  checkInLt?: string;
  contractType?: string;
  department?: string[];
  onlyMyTrips?: boolean;
  pendingModificationOnly?: boolean;
  refundableOnly?: boolean;
  search?: string[];
  totalGt?: number;
  totalLt?: number;
  unverified?: boolean;
  manual_bookings?: boolean;
}

export interface ITripsStateContext {
  reservations: IReservationBase[];
  loading: boolean;
  error: string | null;
  canViewOthersTrips: boolean;
  counts: ICounts;
  mostExpensiveTrip: number;
  filters: IFilters;
  showPreview: IReservationBase;
  selectedRows: unknown[];
  limit: number;
  page: number;
  offset: number;
  sort: ISort;
  sortCalendar: string;
  reset: boolean;
  status: TripsFilterStatus;
  activeModification: {
    isActive: boolean;
    isNavigating: boolean;
    deferredTripSelection?: IReservationBase | undefined;
  };
}

export interface ITripsContext {
  state: ITripsStateContext;
  dispatch: TTripsDispatchContext;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  tripsRefresh: () => void;
}

export const TripsContext = createContext({} as ITripsContext);

export interface IAction {
  type: string;
  filters?: Array<{ key: keyof IFilters; value?: IFilters[keyof IFilters] }>;
  status?: TripsFilterStatus;
  limit?: number;
  record?: IReservationBase;
  column?:
    | "checkIn"
    | "guestLastName"
    | "propertyName"
    | "propertyCity"
    | "total"
    | "firstName"
    | "lastName"
    | "department"
    | "employeeId";
  reservations?: IReservationBase[];
  rows?: unknown[];
  page?: number;
  pageSize?: number;
  key?: string;
  total?: number;
  active?: number;
  upcoming?: number;
  past?: number;
  cancelled?: number;
  sortCalendar?: string;
  values?: unknown[];
  activeModification?: {
    isActive: boolean;
    isNavigating: boolean;
    deferredTripSelection?: IReservationBase | undefined;
  };
}

export type TTripsDispatchContext = (action: IAction) => void;

export const defaultState = {
  reservations: [],
  loading: false,
  canViewOthersTrips: false,
  error: null,
  counts: {
    active: 0,
    all: 0,
    cancelled: 0,
    past: 0,
    upcoming: 0,
  },
  mostExpensiveTrip: 0,
  filters: {},
  showPreview: {},
  selectedRows: [],
  status: "upcoming",
  limit: 25,
  page: 1,
  offset: 0,
  sort: { column: "checkIn", direction: "asc" },
  sortCalendar: "first_name",
  reset: true,
  activeModification: {
    isActive: false,
    isNavigating: false,
    deferredTripSelection: undefined,
  },
};

/** These are the statuses and columns where it makes the most sense to sort differently than everywhere else
 *  b/c here we want to show the most recent trips/ latest dates instead of the future dates that are the closest
 */
const defaultDescSorts = ["past", "cancelled", "all"];
const defaultDescColumns = ["checkIn", "checkOut"];

const ignoreFilters = ["status", "totalGt", "totalLt", "checkInGt", "checkInLt", "search"];

const handleFilterChange = (state, action) => {
  const filtersToUpdate = action.filters.reduce((acc, filter) => {
    const { [filter.key]: value, ...rest } = acc;

    /**
     * only clear filter if value is empty or
     * its one of the ignore filters or
     * its from the summary panel (locations and travelers)
     */
    if (
      !value ||
      ignoreFilters.includes(filter.key) ||
      (filter.key === "locations" && filter.value !== "") ||
      (filter.key === "travelers" && filter.value !== "")
    ) {
      rest[filter.key] = filter.value;
    }

    return rest;
  }, state.filters);

  const cleanFilters = Object.keys(filtersToUpdate).reduce((acc, key) => {
    if (
      filtersToUpdate[key] === undefined ||
      (Array.isArray(filtersToUpdate[key]) && !filtersToUpdate[key].length)
    ) {
      return acc;
    }

    acc[key] = filtersToUpdate[key];

    return acc;
  }, {});

  return {
    ...state,
    filters: cleanFilters,
    selectedRows: [],
    showPreview: {},
  };
};

const handleArrayFilterChange = (state, { key, values }: { key: string; values: unknown[] }) => {
  const currentFilterValues: unknown[] = state.filters[key];
  let updatedFilterValues: unknown[] = values;

  if (!!currentFilterValues) {
    if (values.some((value: unknown) => currentFilterValues.includes(value))) {
      updatedFilterValues = currentFilterValues.filter(
        (currentValue) => !values.includes(currentValue)
      );
    } else {
      updatedFilterValues = [...currentFilterValues, ...values];
    }
  }

  return {
    ...state,
    filters: { ...state.filters, [key]: updatedFilterValues },
    selectedRows: [],
    showPreview: {},
  };
};

const handleSort = (state, action, status = state.status) => {
  const isDefaultDescOption =
    defaultDescSorts.includes(status) && defaultDescColumns.includes(action.column);

  const newSort = {
    column: action.column,
    direction: isDefaultDescOption ? "desc" : "asc",
  };

  /** This handles the "toggle" case where we want to change the sort of the already selected column */
  if (state.sort.column === action.column && state.sort.direction === newSort.direction) {
    newSort.direction = newSort.direction === "asc" ? "desc" : "asc";
  }

  return {
    ...state,
    sort: newSort,
  };
};

const handleStatusChange = (state, action) => {
  const baseObj = {
    status: action.status,
    offset: 0,
    page: 1,
    showPreview: {},
  };

  const shouldSortWhenChangingStatus = defaultDescColumns.includes(state.sort.column);

  const sortDirectionForStatus = defaultDescSorts.includes(action.status) ? "desc" : "asc";

  if (shouldSortWhenChangingStatus) {
    /** If the sort is already in the correct direction, return the base object */
    if (state.sort.direction === sortDirectionForStatus) {
      return { ...state, ...baseObj };
    }

    /** Otherwise, change the sort based on the status being selected */
    return {
      ...handleSort(
        state,
        { column: state.sort.column, direction: sortDirectionForStatus },
        action.status
      ),
      ...baseObj,
    };
  }

  /** If the column is a default column, skip everything above and return the base object */
  return { ...state, ...baseObj };
};

const handleRowSelection = (state, action) => {
  const isModificationActive = state.activeModification.isActive;
  const unselect = state.showPreview.id === action.record.id;

  /** If there is an active modification, we want to defer the selection of a new trip, but keep reference to it,
   * then set isNavigating to true, to communicate the attempted trip selection to the ModificationsContext
   */
  if (isModificationActive) {
    return {
      ...state,
      activeModification: {
        isActive: true,
        isNavigating: true,
        deferredTripSelection: action.record ?? state.activeModification.deferredTripSelection,
      },
    };
  }

  /** Either a normal trip selection (no active modification), or a modification has been abandoned and here we will
   * finish resetting modification state and update showPreview with the deferredTripSelection
   */
  return {
    ...state,
    activeModification: {
      isActive: false,
      isNavigating: false,
      deferredTripSelection: undefined,
    },
    showPreview: unselect ? {} : action.record,
    selectedRows: unselect ? state.selectedRows : [],
  };
};

const handleSetActiveModification = (state, action) => {
  const updatedState = {
    ...state,
    activeModification: action.activeModification,
  };

  /** If the modification has been abandoned but there is a deferredTripSelection, we will push the
   * updatedState to handleRowSelection along with the deferred trip, rather than just updating state here
   */
  if (!action.activeModification.isActive && !!state.activeModification.deferredTripSelection) {
    return handleRowSelection(updatedState, {
      record: state.activeModification.deferredTripSelection,
    });
  }

  return updatedState;
};

export const tripsReducer = (state, action) => {
  switch (action.type) {
    case "reservationsRequest":
      return {
        ...state,
        ...action,
        loading: true,
      };
    case "reservationsReceived":
      const counts = {
        active: action.totalVisiting,
        upcoming: action.totalBooked,
        past: action.totalCompleted,
        cancelled: action.totalCancelled,
        all: action.total,
      };

      return {
        ...state,
        loading: false,
        reservations: state.reset ? action.results : [...state.reservations, ...action.results],
        counts,
        mostExpensiveTrip: state.initial
          ? Math.ceil(action.mostExpensiveTrip)
          : Math.ceil(state.mostExpensiveTrip),
        page: Math.ceil((action.results.length + state.offset) / state.limit),
        reset: true,
        initial: false,
      };
    case "updateStatusCounts":
      return {
        ...state,
        counts: {
          active: action.active,
          upcoming: action.upcoming,
          past: action.past,
          cancelled: action.cancelled,
          all: action.total,
        },
      };
    case "status":
      return handleStatusChange(state, action);
    case "filter":
      return handleFilterChange(state, action);
    case "arrayFilter":
      return handleArrayFilterChange(state, action);
    case "pagination":
      return {
        ...state,
        page: action.page,
        offset: (action.page - 1) * action.pageSize,
      };
    case "limit":
      return {
        ...state,
        limit: action.limit,
        offset: 0,
      };
    case "sortCalendar":
      return {
        ...state,
        sortCalendar: action.sortCalendar,
      };
    case "sort":
      return handleSort(state, action);
    case "rowSelection":
      return handleRowSelection(state, action);
    case "setActiveModification":
      return handleSetActiveModification(state, action);
    case "rowChecked":
      return {
        ...state,
        showPreview: {},
        selectedRows: action.rows,
      };
    case "loadMore":
      return {
        ...state,
        offset: state.reservations.length,
        reset: false,
      };
    case "clearFilters":
      return {
        ...state,
        filters: {},
      };
    default:
      return state;
  }
};

const reservationsRequest = async (state, dispatch, user) => {
  dispatch({ type: "reservationsRequest" });

  let status = apiTripTypesLegacy[state.status];

  if (user && state.status === "all") {
    status = ["booked", "visiting", "completed", "cancelled"];
  }

  const { search, department, ...filters } = state.filters;

  if (search) {
    filters["search[]"] = search;
  }

  if (department) {
    filters["departments[]"] = department;
  }

  if (!state.canViewOthersTrips) {
    filters.onlyMyTrips = true;
  }

  const res = await Reservation.query({
    ...filters,
    "status[]": status,
    limit: state.limit || 25,
    sort: `${state.sort.direction === "desc" ? "-" : ""}${camelToSnake(state.sort.column)}`, // this value needs to be snake_case
    offset: state.offset || 0,
    url: config.apiHost + "/reservations",
  });

  dispatch({ type: "reservationsReceived", ...res });
};

const TripsProviderLegacy = ({ params, user, children, view }) => {
  const [searchValue, setSearchValue] = useState("");
  const [tripsPreferences, setTripsPreferences] = useLocalStorage<{
    sort?: { column: string; direction: string };
    limit: number;
    status: string;
  }>("tripsPreferences", {
    sort: defaultState.sort,
    limit: defaultState.limit,
    status: defaultState.status,
  });
  const [state, dispatch] = useReducer(tripsReducer, {
    ...defaultState,
    status: tripsPreferences.status
      ? tripsPreferences.status
      : params.status || defaultState.status,
    limit: tripsPreferences.limit ? tripsPreferences.limit : params.limit || defaultState.limit,
    sort: tripsPreferences.sort ? tripsPreferences.sort : params.sort || defaultState.sort,
    initial: true,
    canViewOthersTrips:
      user &&
      ((user.role === "coordinator" && user.business.showCoordinatorDashboard) ||
        user.role === "admin"),
  });

  const navigate = useNavigate();
  const rrLocation = useLocation();

  // Checkout verification context
  const { state: postCheckoutActionsState } = usePostCheckoutActions();

  useEffect(() => {
    if (state.status !== params.status) {
      dispatch({ type: "status", status: params.status });
    }
  }, [state.status, params.status]);

  const tripsRefresh = () => {
    if (view !== viewTypes.CALENDAR && !state.loading) {
      reservationsRequest(state, dispatch, user).then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    }
  };

  useEffect(() => {
    tripsRefresh();
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.status,
    state.filters,
    state.offset,
    state.limit,
    state.sort,
    postCheckoutActionsState.completedPostCheckoutActions,
    view,
  ]);

  useEffect(() => {
    if (
      tripsPreferences.limit !== state.limit ||
      tripsPreferences.sort !== state.sort ||
      tripsPreferences.status !== state.status
    ) {
      setTripsPreferences({
        limit: state.limit,
        sort: state.sort,
        status: state.status,
      });
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.limit, state.sort, state.status]);

  useEffect(() => {
    if (`/trips/${state.status}` !== rrLocation.pathname) {
      navigate(`${routes.trips.base}/${state.status}`);
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TripsContext.Provider
      value={{
        state,
        dispatch,
        searchValue,
        setSearchValue,
        tripsRefresh,
      }}
    >
      {children}
    </TripsContext.Provider>
  );
};

const useTripsContextLegacy = () => {
  const context = useContext(TripsContext);
  if (context === undefined) {
    throw new Error("useTripsContext must be used within the TripsProvider");
  }

  return context;
};

export { TripsProviderLegacy, useTripsContextLegacy };
