import type { AxiosError } from "axios";
import type { UseMutationOptions } from "react-query";
import { useMutation } from "react-query";

import type { ITextMessagesRecord } from "@hotel-engine/types/textMessage";

import { useApi } from "../useApi";
import { endpoints } from "../constants";

interface ITextMessagesConfig {
  options?: UseMutationOptions<
    ITextMessagesRecord,
    AxiosError<{ title: string }>,
    ITextMessagesMutationParams
  >;
}

interface ITextMessagesMutationParams {
  phone: number;
}

export type ITextMessagesMutationResponse = ITextMessagesRecord | void;

const useTextMessagesMutation = (config: ITextMessagesConfig = {}) => {
  const post = useApi("post");

  // Function that executes when useMutation is fired
  const mutationFn = ({ phone }: ITextMessagesMutationParams) => {
    const endpoint = endpoints.textMessages;

    const options = {
      headers: {
        Accept: "application/json",
      },
    };
    const body = {
      phone,
      type: "mobile_download",
    };

    return post<ITextMessagesRecord>(endpoint, body, options);
  };

  const options = {
    ...(config.options || {}),
  };

  // Ignored to support conversion to create-react-app.  Please fix if
  // you are here from the future.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-expect-error */
  return useMutation<
    ITextMessagesMutationResponse,
    AxiosError<{ title: string }>,
    ITextMessagesMutationParams
  >(mutationFn, options);
};

export { useTextMessagesMutation };
