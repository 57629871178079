import * as yup from "yup";

import { colors } from "@hotel-engine/styles";
import { SearchFormSchema } from "@hotel-engine/utilities";
import config from "config";
import { useGoogleGeocoder } from "@hotel-engine/hooks";
import type { ILocationRecord } from "@hotel-engine/types/locations";
import type { IWorksite } from "@hotel-engine/types/worksite";

export const yupValidate = () => {
  return yup
    .object()
    .shape({
      selectedLocation: SearchFormSchema.shape.selectedLocation,
      checkIn: SearchFormSchema.shape.checkIn,
      checkOut: SearchFormSchema.shape.checkOut,
      roomCount: SearchFormSchema.shape.roomCount,
      guestCount: SearchFormSchema.shape.guestCount,
    })
    .test(SearchFormSchema.rules.checkInBeforeCheckout())
    .test(SearchFormSchema.rules.maxNights(config.maxNights))
    .test(SearchFormSchema.rules.guestCountCantBeMoreThanRooms())
    .test(SearchFormSchema.rules.guestCountCantBeMoreThanTwiceTheRoomCount());
};

export interface IDashboardHeroCopy {
  title: string;
  text: string;
  backgroundImagePath: string;
  mobileImagePath?: string;
  color: string;
}

const backgroundImagePrefix = `${config.cdnHost}/assets/dashboard/backgrounds/bg-`;
const mobileImagePrefix = `${config.cdnHost}/assets/dashboard/backgrounds/mbg-`;

const defaultBusinessTitle = "Find a Stay";
const defaultBusinessText = "Save on thousands of places across the globe.";

export const businessUserDashboardHeroCopy: IDashboardHeroCopy[] = [
  {
    title: defaultBusinessTitle,
    text: defaultBusinessText,
    color: colors.white,
    backgroundImagePath: `${backgroundImagePrefix}${1}.jpg`,
  },
  {
    title: defaultBusinessTitle,
    text: defaultBusinessText,
    color: colors.white,
    backgroundImagePath: `${backgroundImagePrefix}${2}.jpg`,
  },
  {
    title: defaultBusinessTitle,
    text: defaultBusinessText,
    color: colors.white,
    backgroundImagePath: `${backgroundImagePrefix}${3}.jpg`,
  },
  {
    title: defaultBusinessTitle,
    text: defaultBusinessText,
    color: colors.white,
    backgroundImagePath: `${backgroundImagePrefix}${4}.jpg`,
  },
  {
    title: defaultBusinessTitle,
    text: defaultBusinessText,
    color: colors.white,
    backgroundImagePath: `${backgroundImagePrefix}${5}.jpg`,
  },
  {
    title: defaultBusinessTitle,
    text: defaultBusinessText,
    color: colors.white,
    backgroundImagePath: `${backgroundImagePrefix}${6}.jpg`,
  },
  {
    title: defaultBusinessTitle,
    text: defaultBusinessText,
    color: colors.white,
    backgroundImagePath: `${backgroundImagePrefix}${7}.jpg`,
  },
];

export const personalUserDashboardHeroCopy: IDashboardHeroCopy[] = [
  {
    title: "Your Next Adventure Awaits",
    text: "Save on thousands of places throughout the globe",
    color: colors.black,
    backgroundImagePath: `${config.cdnHost}/assets/personal-travel/images/personal-hero.svg`,
    mobileImagePath: `${mobileImagePrefix}${8}.jpg`,
  },
];

interface ISelectedLocation {
  id: string | number;
}

const checkIsGoogleResponse = (selectedLocation?: ISelectedLocation) => {
  if (!selectedLocation || typeof selectedLocation.id !== "string") {
    return false;
  }

  return !selectedLocation.id.includes("property") || !selectedLocation.id.includes("worksite");
};

export const extractLocationId = (
  locationData: string | number | undefined
): string | undefined => {
  if (typeof locationData !== "string") return locationData?.toString();
  const splitId = locationData.split("|property:");
  const specificPropertyData = !!splitId[1] ? JSON.parse(splitId[1]) : "";
  if (!!specificPropertyData) {
    return specificPropertyData.id;
  }
  return splitId[0];
};

interface ILocationData {
  id: number | string;
  latitude?: number;
  longitude?: number;
}

const useGeocodedSpecificProperty = () => {
  const { getLatLng } = useGoogleGeocoder();

  const fetchLocationData = async (
    locationData: string
  ): Promise<ILocationData | ILocationRecord> => {
    const splitId = locationData.split("|property:");
    const specificPropertyData = !!splitId[1] ? JSON.parse(splitId[1]) : false;

    if (!!specificPropertyData) return specificPropertyData;

    const googleId = splitId[0];
    const googleLatLng = await getLatLng(googleId);

    return {
      id: googleId,
      ...googleLatLng,
    };
  };

  return { fetchLocationData };
};

export const useLocationInfoWithCoordinates = () => {
  const { fetchLocationData } = useGeocodedSpecificProperty();

  const getLocationInfoWithCoordinates = async (searchLocation: ILocationRecord | IWorksite) => {
    if (checkIsGoogleResponse(searchLocation)) {
      const googleFetchedLocation = await fetchLocationData(searchLocation.id as string);

      return {
        ...searchLocation,
        ...googleFetchedLocation,
      } as ILocationRecord;
    }
    return searchLocation;
  };

  return getLocationInfoWithCoordinates;
};
