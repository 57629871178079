import { useCallback, useEffect, useRef, useState } from "react";

import { useDebounce } from "@hotel-engine/hooks";
import { Has } from "@hotel-engine/guards";

import { useWaterLine } from "./waterline";

const INIT_CLICK_STATE = {
  container: false,
  containerFocus: false,
  moreButton: false,
  deletePillButton: false,
  pillInput: false,
  pillInputFocus: false,
  emailInput: false,
  emailInputFocus: false,
};

export enum EnumPillClickState {
  EMAIL_TEXT_CLICKABLE,
  EMAIL_TEXT_EDIT,
}
export type TypeInputCloseStates = "not-ready" | "ready" | "done";

export interface IEmailPillValue {
  key: string;
  value: string;
  valid: boolean;
  clickState: EnumPillClickState;
  lastErrorMessage: string;
}

export interface IRangeIndex {
  startIndex: number;
  endIndex: number;
}

interface IClickStates {
  emailInput: boolean;
  emailInputFocus: boolean;
  deletePillButton: boolean;
  pillInput: boolean;
  pillInputFocus: boolean;
  moreButton: boolean;
  container: boolean;
  containerFocus: boolean;
}

type IEmailInputMode =
  | "default"
  | "whole-pill-selection"
  | "whole-pill-selection-next-right"
  | "whole-pill-selection-next-left";

export type IHasEmailObject = { [k: string]: IEmailPillValue };

export interface IEmailPillsRulesEngine {
  /** data for the form */
  form: {
    /** are the fields touched */
    touched: { email?: boolean; emails?: boolean; additionalEmails?: boolean };
    /** can set field for any type in the formik initialValues */
    setFieldValue: (
      k: string,
      val?: string | string[] | IEmailPillValue | IEmailPillValue[]
    ) => void;
    /** tells formik to check validation for a specific field */
    setFieldTouched: (k: string, val?: boolean) => void;
  };
  /* must be injected from the level where formik is used */
  value: IEmailPillValue[];
  maxEmails: number;
}
/**
 * The `EmailsField` component is a wrapper around a custom component. There was an attempt to make as much of it in antd as much as possible.
 * However, refs in antd do not have the expected effect so HTML elements were used in their place instead.
 * It requires the use of formik and their `<Field />` component.
 *
 * @remarks Props are spread onto the HTMLInputElement
 * @example <Field component={InputField} label="Username" placeholder="username" name="username" value={values.somefield}/>
 * @see {@link https://formik.org/docs/api/field Formik Field Documentation}
 * @see {@link https://www.figma.com/file/GVLYN60OBX188CID3YvWpSo6/Components---WEB?node-id=870%3A4 Design Specs}
 */
const usePillsRulesEngine = ({
  form: { touched, setFieldValue, setFieldTouched },
  value,
  maxEmails,
}: IEmailPillsRulesEngine) => {
  // tracks the input for new user-defined emails
  const newEmailInputRef = useRef<HTMLInputElement | null>(null);

  // track the mode of the emailInputRef. whole-pill-selection allows navigation using arrow keys
  const emailInputMode = useRef<IEmailInputMode>("default");

  // tracks the width of invisible text. formerly called `valueTrackerRef`
  const ghostTextRef = useRef<HTMLInputElement | null>(null);

  // used by event handlers to record some non-updating state, which is later used by a debounced useEffect to render any of the following actions: new-email input visibility, outer container styling, pill input behavior
  const clickStatesRef = useRef<IClickStates>(INIT_CLICK_STATE);

  const [inputCloseState, setInputCloseState] = useState<TypeInputCloseStates>("not-ready");

  // used to enforce only one timeout for logic that works with formik to enforce initial state of email input
  // should NOT be exported
  const touchedTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  // used by rules engine to tell formik if values.emails is modified at any point in the rules engine lifecycle
  const emailsTouched = useRef(false);

  // used to keep track of whether we should inject inline (border) styles into the outer container that mimic antd styling behavior
  const [hasFocus, setHasFocus] = useState(false);

  // accumulator to trigger a rules engine wrapped in a useEffect triggered by debouncedMoreButtonAcc
  const [moreButtonAcc, setMoreButtonAcc] = useState(0);

  // accumulator to trigger a rules engine wrapped in a useEffect triggered by debouncedEmailInputAcc
  const [emailInputAcc, setEmailInputAcc] = useState(0);

  // keeps track of which email pil has focus so that an input could be put into it's place
  const [keyWithFocus, setKeyWithFocus] = useState("");

  const updateMoreButtonAccumulator = () => setMoreButtonAcc(moreButtonAcc + 1);
  const updateEmailInputAccumulator = () => setEmailInputAcc(emailInputAcc + 1);
  const outerContainerShouldBeFocused = () => setHasFocus(true);
  const outerContainerShouldNotBeFocused = () => setHasFocus(false);
  const isOuterContainerFocused = hasFocus; // friendlier name
  const contractNewEmailInput = () => setInputCloseState("done");
  const expandNewEmailInput = () => setInputCloseState("ready");

  // keeps track of overflow pills where the overflow rises according to user click on the More pill
  const {
    resetWaterline,
    incrementWaterline,
    validUnderwater,
    invalidUnderwater,
    invalidOverTheWater,
  } = useWaterLine<IEmailPillValue>(maxEmails);

  // used to keep track of the selection index as pill changes from CLICKABLE state to EDITABLE staste
  const selectionIndexRef = useRef<IRangeIndex>({
    startIndex: -1,
    endIndex: -1,
  });

  // stores the emails rendered as pills
  // it is highly dependent on props.value
  const [myEmails, _setMyEmails] = useState<IEmailPillValue[]>([]);

  // tracks whethere an email is unique
  const [_hasEmailObject, setHasEmailObject] = useState<IHasEmailObject>({});
  const hasEmailObject = (email: string) => !!_hasEmailObject[email.toLowerCase()];
  const resetEmailsObject = () => setHasEmailObject({});
  const updateSingleEmailObject = (email: IEmailPillValue) => {
    const newHasEmailObject = { ..._hasEmailObject };
    newHasEmailObject[email.value.toLowerCase()] = email;
    setHasEmailObject(newHasEmailObject);
  };
  const updateEmailObject = (newEmailValue: IEmailPillValue[]) => {
    const newHasEmailObject: IHasEmailObject = {};
    newEmailValue.forEach((email) => {
      const key = email.value.toLowerCase();
      newHasEmailObject[key] = email;
      setHasEmailObject(newHasEmailObject);
    });
  };
  const removeFromEmailObject = (email: string) => {
    const newHasEmailObject = { ..._hasEmailObject };
    // remove from object
    delete newHasEmailObject[email];
    setHasEmailObject(newHasEmailObject);
  };

  // part of the rules engine for pill rendering behavior when more button is pressed
  const debouncedMoreButtonAcc = useDebounce(moreButtonAcc, 100);

  // part of the rules engine for input focus behavior when specific parts of the component are clicked
  const debouncedEmailInputAcc = useDebounce(emailInputAcc, 100);

  // listen to touched.emails, important if user loses focus on new-email input while its empty but then user decideds to comes back into focus again
  useEffect(() => {
    if (touched.emails) {
      emailsTouched.current = true;
    }
  }, [touched.emails]);

  // upon mounting find the label and insert handler to focus on email inputRef
  // formik doesn't allow me to do this without placing the name directly in the input which I cannot since that would put the whole validation lifecycle topsy-turvy
  useEffect(() => {
    const label = globalThis.document.querySelector("[for='emails']");

    label?.addEventListener("click", () => {
      newEmailInputRef?.current?.focus();
    });
    setInputCloseState("ready");
  }, []);

  // synchronizes myEmails to props.value when email has been submitted
  useEffect(() => {
    // reset field if submit count increases and preempt further reset thereafter
    // will not infinitely update
    if (value && value.length === 0) {
      if (myEmails.length !== 0) {
        setMyEmails([]);
        setHasEmailObject({});
        emailsTouched.current = false;
        resetWaterline();
      }
      setInputCloseState("ready");
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // rules engine reset water line when focus is lost when moreButtonAcc changes
  useEffect(() => {
    const {
      pillInput,
      emailInput,
      moreButton,
      container,
      pillInputFocus,
      emailInputFocus,
      containerFocus,
      deletePillButton,
    } = clickStatesRef.current;

    if (!pillInput && !emailInput && !moreButton && !container && !deletePillButton) {
      resetWaterline();

      // default antd behavior that could mark the field as invalid if there is no email specified prior to losing focus
      setFieldTouched("emails", true);
    }
    // if the more button expanded the overflow then bring focus back to new email input
    if (moreButton || deletePillButton || (!pillInput && !emailInput && !moreButton && container)) {
      newEmailInputRef?.current?.focus();
    }

    // if you lose focus, more than one email pill shown, and inputCloseState is ready
    // then make it done to close it out
    if (
      0 < myEmails.length &&
      inputCloseState === "ready" &&
      !container &&
      !pillInput &&
      !pillInputFocus &&
      !emailInputFocus &&
      !containerFocus
    ) {
      outerContainerShouldNotBeFocused();
      setInputCloseState("done");
    }
    if (
      !container &&
      !pillInput &&
      !pillInputFocus &&
      !emailInputFocus &&
      !containerFocus &&
      !deletePillButton &&
      !emailInput
    ) {
      outerContainerShouldNotBeFocused();
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedMoreButtonAcc]);

  // rules engine to place focus on email input whenever emailInputAcc changes
  useEffect(() => {
    const { pillInput } = clickStatesRef.current;
    // will trigger if user is navigating pills with arrows and will transition to new-email input
    if (pillInput && emailInputMode.current === "whole-pill-selection") {
      newEmailInputRef?.current?.focus();
    }

    // if pillcontainer was touched while emailsTouched is false
    // then touched.emails should remain false
    if (!emailsTouched.current) {
      setFieldTouched("emails", false);
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEmailInputAcc]);

  // stores the node given to emailInputRef
  const nodeEmailInputRef = useRef<HTMLInputElement | null>(null);

  // will be injected with pill input node, then place focus on that pill input
  const pillInputRef = useCallback(
    (node, key) => {
      if (node !== null) {
        nodeEmailInputRef.current = node;
        node.focus();

        // preempt multiple tries
        if (key !== keyWithFocus) {
          setKeyWithFocus(key);
          const { startIndex, endIndex } = selectionIndexRef.current;
          const [s, e] = startIndex < endIndex ? [startIndex, endIndex] : [endIndex, startIndex];
          node.setSelectionRange(s, e);
          setInputCloseState("not-ready");
        }
        if (emailInputMode.current === "whole-pill-selection") {
          node.select();
        }

        const hasOffsetWidth = Has(["current", "offsetWidth"]);

        // set width to that of the paragraph, allow this only once per selection
        if (hasOffsetWidth(ghostTextRef)) {
          const width = ghostTextRef.current.offsetWidth + 8;
          if (width !== -1) {
            node.style.width = `${width}px`;
          }
        }
      }
    },
    [keyWithFocus]
  );

  // if there is no length to emails list but there is length to inputEmailValue.value
  // then this function will not tell formik that emails has been modified
  const setMyEmails = (val: IEmailPillValue[]) => {
    // distribute the emails to the two other fields as required by the API
    if (0 < val.length) {
      const additionalEmails = [...val];
      const firstEmail = additionalEmails.shift();
      setFieldValue("email", firstEmail?.value);
      setFieldValue(
        "additionalEmails",
        additionalEmails.map((email) => email.value)
      );

      // fields are getting set only after being "touched"
      // so we need to enforce the set-then-touched ordering.
      // Is there another way other than timeout or perhaps something else entirely?
      if (touchedTimeoutRef?.current) {
        // there should only be one touchedTimeoutRef
        clearTimeout(touchedTimeoutRef.current);
        touchedTimeoutRef && (touchedTimeoutRef.current = null);
      }
      touchedTimeoutRef.current = setTimeout(() => {
        setFieldTouched("emails", emailsTouched?.current);
        setFieldTouched("email", emailsTouched?.current);
        setFieldTouched("additionalEmails", emailsTouched?.current);
      }, 150);
    }
    setFieldValue("emails", val);
    setFieldValue("emailinput", "");
    _setMyEmails(val);
  };

  // Pill management: changes a pill from a span to input and vice-versa
  const pillClickStateChange = (key: string, clickState: EnumPillClickState) => {
    const clearedState = myEmails.map((email) => ({
      ...email,
      clickState: EnumPillClickState.EMAIL_TEXT_CLICKABLE,
    }));
    const myNewEmails = clearedState.map((email) => {
      if (key === email.key) {
        return { ...email, clickState };
      }
      return email;
    });
    // the emails attribute is not touched here
    setMyEmails(myNewEmails);
  };
  const allowPillEditable = (k: string) =>
    pillClickStateChange(k, EnumPillClickState.EMAIL_TEXT_EDIT);
  const allowPillClickable = (k: string) =>
    pillClickStateChange(k, EnumPillClickState.EMAIL_TEXT_CLICKABLE);
  const recordTextSelection = () => {
    // probably works for IE 11 and IE 10 because we are not selecting from input field
    // https://stackoverflow.com/questions/35162273/window-getselection-not-working-is-not-working-in-ie11
    const [startIndex, endIndex] = getSelectionIndexes();
    if (
      startIndex === -1 ||
      endIndex === -1 ||
      startIndex === undefined ||
      endIndex === undefined
    ) {
      return;
    }
    selectionIndexRef.current = { startIndex, endIndex };
  };

  // emails data modified
  const emailsModified = () => (emailsTouched.current = true);
  const emailsNotModified = () => (emailsTouched.current = false);

  // sets a specific key of clickStatesRef to a value
  const setClickState = (k: keyof IClickStates, v: boolean) => {
    clickStatesRef.current = { ...clickStatesRef.current, [k]: v };
  };

  // reset all the data in clickStatesRef to false
  const resetClickState = () => {
    clickStatesRef.current = { ...INIT_CLICK_STATE };
  };

  const result = {
    clickState: {
      resetClickState,
      setClickState,
    },

    pillManagement: {
      emailInputMode,
      resetFocus: () => setKeyWithFocus(""),
      allowPillEditable,
      allowPillClickable,
      recordTextSelection,
    },

    emailState: {
      setMyEmails,
      myEmails,
      emailsModified,
      emailsNotModified,
      hasEmailObject,
      resetEmailsObject,
      updateSingleEmailObject,
      updateEmailObject,
      removeFromEmailObject,
    },

    nodeReferences: {
      pillInputRef,
      newEmailInputRef,
      nodeEmailInputRef,
      ghostTextRef,
    },

    updateOnRules: {
      updateMoreButtonAccumulator,
      updateEmailInputAccumulator,
    },

    antdMimic: {
      outerContainerShouldBeFocused,
      outerContainerShouldNotBeFocused,
      isOuterContainerFocused,
    },

    newEmailInputControl: {
      contractNewEmailInput,
      expandNewEmailInput,
      isNewEmailInputIsReady: inputCloseState === "ready",
    },

    waterline: {
      resetWaterline,
      incrementWaterline,
      validUnderwater,
      invalidUnderwater,
      invalidOverTheWater,
    },
  };
  return result;
};

const getSelectionIndexes = () => {
  const selection = globalThis.getSelection();
  const startIndex = selection?.anchorOffset;
  const endIndex = selection?.focusOffset;
  return [startIndex, endIndex];
};

export default usePillsRulesEngine;
