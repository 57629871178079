import { Form } from "formik";
import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors } from "@hotel-engine/styles";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

import type { I2fvModalStyling } from "../EnterVerificationCode/styles";

export const VerifyTwoStepContainer = styled.div`
  position: relative;
  align-content: stretch;
`;

export const Title = styled.p<I2fvModalStyling>`
  ${typographyMixin("xlarge")};
  margin: 0;
  text-align: ${({ $isInModal }) => ($isInModal ? "start" : "center")};
`;

export const DescriptionText = styled.p<I2fvModalStyling>`
  ${typographyMixin("small")};
  font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
  color: ${colors.grey[500]};
  margin-top: ${({ theme }) => theme.legacy.space(1)};
  text-align: ${({ $isInModal }) => ($isInModal ? "start" : "center")};
`;

export const PhoneForm = styled(Form)`
  ${mediaQuery(
    "sm",
    "max"
  )(`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  `)}
`;

export const TextRates = styled.p`
  ${typographyMixin("body-small")}
  color: ${colors.grey[400]};
`;

export const SubmitButton = styled(Button)`
  display: block;
  width: 100%;
  margin-top: auto;
`;
