import { Typography } from "@hotelengine/atlas-web";
import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";

/** Basic title used for all cancellations, only broken out to avoid having to duplicate styling, Typography props */
const CancellationTitle = ({ isFlight, isCar }: { isFlight?: boolean; isCar?: boolean }) => {
  const { typographyVariants } = useTypographyScaling();

  const isNewType = isFlight || isCar;

  const title = isFlight ? "Cancel flight" : "Cancel booking";

  return (
    <Typography
      as="h3"
      variant={typographyVariants.title}
      color="foregroundPrimary"
      style={{
        marginTop: isNewType ? 0 : 24,
        marginBottom: 24,
        paddingTop: 0,
        textAlign: isNewType ? "center" : "left",
      }}
    >
      {title}
    </Typography>
  );
};

export default CancellationTitle;
