import styled from "styled-components";

import { Checkbox } from "@hotel-engine/common/Checkbox";
import { InputNumber as _InputNumber } from "@hotel-engine/common/InputNumber";
import { Input } from "@hotel-engine/common/Inputs";
import { Menu, MenuItem } from "@hotel-engine/common/Menu";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { buttonReset, colors, ellipsisText } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { Icon } from "@hotel-engine/common/Icon";

export const FilterMenu = styled(Menu)`
  width: 209px;
`;

export const ButtonIcon = styled(Icon)`
  margin-right: 8px;
`;

export const SubMenu = styled(Menu)`
  border: none;
`;

export const DropdownMenuSection = styled.section`
  padding: 8px;

  &:not(:first-child) {
    border-top: 1px solid ${colors.grey[300]};
  }

  h3 {
    ${typographyMixin("small")};
    color: ${colors.grey[500]};
    margin-bottom: 8px;
  }

  .ant-menu-item-selected {
    color: inherit;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
  }
`;

export const PriceRange = styled.div`
  ${flexbox({
    justifyContent: "space-between",
  })};
  gap: 8px;
`;

export const InputNumber = styled(_InputNumber)`
  flex: 1;
`;

export const TripTypeItem = styled(MenuItem)`
  padding: 0 !important;
  margin: 0 !important;
  height: 28px !important;
  line-height: 1.2 !important;
  ${flexbox({ alignItems: "center" })};
`;

export const DropdownSectionTitle = styled.div`
  ${flexbox({ alignItems: "center", justifyContent: "space-between" })};
  margin-bottom: 8px;

  h3 {
    margin: 0;
  }
`;

export const ClearButton = styled.button`
  ${buttonReset};
  ${typographyMixin("body-small")};
  color: ${colors.grey[500]};
  transition: 0.2s ease all;

  :hover {
    color: ${colors.retroBlue};
  }

  &.dropdown-clear-button {
    :hover {
      color: ${colors.black};
    }
  }
`;

export const DepartmentSearch = styled(Input)`
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};
  height: 30px !important;
  margin-bottom: 8px;

  ::placeholder {
    color: ${colors.grey[400]};
  }
`;

export const DepartmentButton = styled.button`
  ${buttonReset};
  ${flexbox({ alignItems: "center" })};
  transition: 0.2s ease all;

  :active {
    background-color: ${colors.aliceBlue};
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  margin: 12px 0;
  display: flex;
  align-items: center;
`;

export const CheckboxLabel = styled.span`
  line-height: 1.2;
  text-align: left;
  ${ellipsisText};
  margin-left: 8px;
  transition: 0.2s ease all;

  :hover {
    color: ${colors.retroBlue};
  }

  &.dropdown-checkbox-label {
    :hover {
      color: ${colors.black};
    }
  }
`;
