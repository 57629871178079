import moment from "moment";

import Image from "@hotel-engine/common/Image";
import { Skeleton } from "@hotel-engine/common/Skeleton";
import { useFormatDate } from "@hotel-engine/hooks";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import { formatCurrency } from "@hotel-engine/utilities";
import { OrangeBull } from "pages/Trips/TripsListViewLegacy/styles";
import { useAppSelector } from "store/hooks";

import { viewTypes } from "../../constants";
import type { ViewType } from "../../constants";
import * as Styled from "./styles";
import { areDatesSameDay } from "pages/helpers";
import { is } from "@hotel-engine/guards";

interface ITripsListItemPropsLegacy {
  item: IReservationBase;
  loading: boolean;
  view?: ViewType;
  selected?: boolean;
  onSelection: (item: IReservationBase) => void;
  displayChevron?: boolean;
}

const TripsListItemLegacy = ({
  item,
  loading,
  view,
  selected,
  onSelection,
  displayChevron,
}: ITripsListItemPropsLegacy) => {
  const user = useAppSelector((state) => state.Auth.user);
  const isVerified = item.checkoutVerification?.verified || false;
  const isUpdated =
    item.checkoutVerification?.checkOutDate &&
    !areDatesSameDay(
      moment(item.checkoutVerification?.checkOutDate),
      moment(item.checkoutVerification?.contractCheckOut)
    );
  const currencyCode = user?.business?.currencyCode || "USD";

  const showCheckoutVerificationFeature = (reservation: IReservationBase) => {
    const verifiedEnabledTripStatuses = ["completed", "visiting"];
    return (
      user?.business.checkoutVerificationEnabled &&
      verifiedEnabledTripStatuses.includes(reservation.status)
    );
  };

  const additionalOccupants =
    Boolean(item.occupants) &&
    item.occupants.reduce((total, rooms) => {
      total += rooms.length;
      return total;
    }, -1);

  return (
    <Styled.TripsListItem
      onClick={() => onSelection(item)}
      $pendingMod={!!item.pendingModification && item.status !== "cancelled"}
      selected={!!selected}
      $viewIsMap={view === viewTypes.MAP}
    >
      <Styled.GlobalListItemStyles />
      <Skeleton avatar title loading={loading} active>
        <Styled.Left>
          <Image src={item.propertyPhotoUrl} alt={item.propertyName} width="80px" height="100px" />
        </Styled.Left>
        <Styled.Right>
          <Styled.PropertyName>
            {!!item.pendingModification && item.status !== "cancelled" && (
              <OrangeBull>&bull;</OrangeBull>
            )}
            {item.propertyName}
          </Styled.PropertyName>
          <Styled.Info>
            <Styled.Location>
              {item.propertyCity}, {!!item.propertyState && `${item.propertyState}, `}
              {item.propertyCountry}
            </Styled.Location>
            <Styled.CheckoutInfo>
              <Styled.Dates>
                {`${useFormatDate(
                  moment(item.checkIn).utc().startOf("day"),
                  "M/D"
                )} - ${useFormatDate(moment(item.checkOut).utc().startOf("day"), "M/D")}`}
              </Styled.Dates>
              {showCheckoutVerificationFeature(item) &&
              item.checkoutVerification?.id &&
              item.guestFirstName ? (
                <Styled.CheckoutVerification $isSuccess={!!isVerified}>
                  {!!isVerified
                    ? isUpdated
                      ? "Updated Checkout Date"
                      : "Checkout Verified"
                    : "Unverified Checkout"}
                </Styled.CheckoutVerification>
              ) : (
                <Styled.CheckoutVerification>
                  {!!item.guestFirstName && `${item.guestFirstName} ${item.guestLastName}`}
                  {is.number(additionalOccupants) && additionalOccupants > 0
                    ? ` +${additionalOccupants}`
                    : null}
                </Styled.CheckoutVerification>
              )}
              {view !== "map" && (
                <Styled.Total>{`${formatCurrency(item.total, true, currencyCode)}`}</Styled.Total>
              )}
            </Styled.CheckoutInfo>
          </Styled.Info>
        </Styled.Right>
      </Skeleton>
      {!!displayChevron && <Styled.Chevron name="chevron-right" size="md" />}
    </Styled.TripsListItem>
  );
};

export default TripsListItemLegacy;
