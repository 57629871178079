import type { Ref } from "react";
import { forwardRef } from "react";

import { Field, Formik } from "formik";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";

import AlertBanner from "@hotel-engine/common/AlertBanner";

import type { IFeedbackForm, RatingValues } from "../../constants";
import { calloutsMap, ratingOptions } from "../../constants";
import * as Styled from "./styles";

interface IFeedbackFormProps {
  close: () => void;
  isMobile: boolean;
  isError: boolean | undefined;
  submit: (
    val: { satisfaction: RatingValues | undefined; multiline: string },
    { setSubmitting }: { setSubmitting: (val: boolean) => void }
  ) => void;
}

const getCalloutText = (values) =>
  `${values.satisfaction ? calloutsMap[values.satisfaction] : ""} ${
    ["veryDissatisfied", "dissatisfied"].includes(values.satisfaction)
      ? "Please help us improve by sharing your experience."
      : `We'd love to hear${values.satisfaction ? " more" : ""} about your experience.`
  }`;

const FeedbackForm = forwardRef(function FeedbackForm(
  { close, isMobile, isError, submit }: IFeedbackFormProps,
  ref: Ref<IFeedbackForm>
) {
  const { COMPANY_NAME } = useCompanyName();

  return (
    <Formik innerRef={ref} onSubmit={submit} initialValues={{ satisfaction: null, multiline: "" }}>
      {({ values, isSubmitting, setFieldValue }) => {
        return (
          <Styled.ModalForm>
            <Styled.RadioContainer>
              <p>Overall, how satisfied are you with {COMPANY_NAME}?</p>
              <Styled.MoodContainer>
                {ratingOptions.map((rating) => (
                  <Styled.MoodWrapper
                    key={rating.value}
                    onClick={() => setFieldValue("satisfaction", rating.value)}
                    type="button"
                  >
                    <Styled.MoodIcon
                      $activeColor={rating.color}
                      selected={values.satisfaction === rating.value}
                      icon={["far", rating.icon]}
                    />
                    <Styled.MoodLabel selected={values.satisfaction === rating.value}>
                      {rating.rating}
                    </Styled.MoodLabel>
                  </Styled.MoodWrapper>
                ))}
              </Styled.MoodContainer>
            </Styled.RadioContainer>
            <hr />
            <Styled.CommentContainer>
              <Field
                component={Styled.CommentText}
                label={getCalloutText(values)}
                name="multiline"
                placeholder="Enter feedback comments"
                style={{ height: "100px" }}
              />

              {!!isError && (
                <AlertBanner message="Something happened on our end. Please try again." />
              )}
            </Styled.CommentContainer>
            <Styled.ButtonsContainer>
              <Styled.SubmitButton
                disabled={!values.satisfaction}
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
              >
                Submit
              </Styled.SubmitButton>
              {!!isMobile && (
                <Styled.CancelButton type="default" onClick={close}>
                  Cancel
                </Styled.CancelButton>
              )}
            </Styled.ButtonsContainer>
          </Styled.ModalForm>
        );
      }}
    </Formik>
  );
});

export default FeedbackForm;
