import styled from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const DisabledInternationalPhoneInputWrapper = styled.fieldset`
  ${flexbox({
    grow: 2,
    shrink: 1,
    basis: 0,
    justifyContent: "flex-end",
  })};

  & > .react-tel-input > input.form-control {
    background-color: transparent;
    border: 0;
    padding: 0;
    height: auto;
    width: 100%;
    cursor: default;
  }

  & > .react-tel-input > .flag-dropdown,
  & > .react-tel-input > .special-label {
    display: none;
    opacity: 0;
  }
`;
