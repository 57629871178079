import styled from "styled-components";

import { buttonReset, colors } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const PillBox = styled.div`
  ${flexbox({
    wrap: "wrap",
    alignItems: "baseline",
  })}
  ${({ theme }) => {
    return `
        min-height: initial;
        margin-bottom: initial;
        font-size: ${theme.legacy.fontSize.xs};
        .ant-btn-link {
          font-size: ${theme.legacy.fontSize.xs};
        }
      `;
  }}
`;

export const Pill = styled.div`
  margin-top: 10px;
  margin-right: 8px;
  border-radius: 100px /* disable theming (do not replicate) */;
  padding: 4px 8px;
  color: ${colors.errieBlack};
  border: 1px solid ${colors.errieBlack};
  height: 24px;

  ${flexbox({ alignItems: "center", justifyContent: "center" })}

  span:first-child {
    ${flexbox()}
    margin-right: 8px;
  }

  button {
    ${buttonReset};
    ${flexbox({ alignItems: "center", justifyContent: "center" })}
    height: 100%;
  }

  span:last-child {
    ${flexbox()}
    margin-top: 4px;
  }
`;
