import styled from "styled-components";
import { Divider } from "@hotelengine/atlas-web";
import { PaddedScrollSectionContainer } from "@hotel-engine/app/ItineraryContent/styles";

export const SummaryOfChargesDivider = styled(Divider)<{ dashed?: boolean }>`
  margin: 10px 0;
  height: 1px;
  border-top: ${({ theme, dashed }) => {
    if (dashed) {
      return `1px dashed ${theme.colors.borderSubtle}`;
    } else {
      return `1px solid ${theme.colors.borderMinimal}`;
    }
  }};
`;

export const SummaryOfChargesPaddedContainer = styled(PaddedScrollSectionContainer)`
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: ${({ theme }) => theme.spacing[24]};
`;
