import styled from "styled-components";

// TODO: Update this antD, as soon as we have a replacement in Atlas. For now we will continue using the AntD
import Image from "@hotel-engine/common/Image";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

const MarketingBannerContainer = styled.div`
  ${mediaQuery(
    "xl",
    "max"
  )(`
    background-color: ${({ theme }) => theme.colors.backgroundSecondary};
    padding: 16px;
    margin-top: 24px;
  `)}
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  padding: ${({ theme }) => theme.spacing[20]};
  margin-top: ${({ theme }) => theme.spacing[20]};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
`;

const CSRLogo = styled(Image)`
  height: ${({ theme }) => theme.spacing[80]};
  width: ${({ theme }) => theme.spacing[80]};
  margin: 0 auto ${({ theme }) => theme.spacing[12]};
  display: block;
`;

export { MarketingBannerContainer, CSRLogo };
