import { useEffect, useRef } from "react";

import { useAppSelector, useAppDispatch } from "store/hooks";

import { Icon } from "@hotel-engine/common/Icon";
import { notification } from "@hotel-engine/common/Notifications";
import { FavoriteProperty } from "@hotel-engine/services";
import { captureMessage } from "@hotel-engine/utilities/logger";

import * as Styled from "./styles";
import { PropertyActions } from "store/containers/Property/PropertyRedux";
import { equals } from "ramda";
import { Unsafe } from "@hotel-engine/data";
import { IconButton, useTheme } from "@hotelengine/atlas-web";

export interface IFavoriteToggleProps {
  className?: string;
  /** function to lift state */
  onFavoriteChange?: (favoriteId: number | null) => void;
  /** the property id */
  propertyId: number;
  /** the property name */
  propertyName: string;
  /** Used to use the functionality of this button as an Atlas IconButton */
  useAtlas?: boolean;
}

export const FavoriteToggle = ({
  className,
  onFavoriteChange,
  propertyId,
  propertyName,
  useAtlas,
}: IFavoriteToggleProps) => {
  const { tokens } = useTheme();
  const property = useAppSelector((state) => state.Property.property);
  const dispatch = useAppDispatch();
  const currentProperty = useRef(property);

  const createFavorite = async () => {
    try {
      // make POST request and wait for response
      const { id } = await FavoriteProperty.create({ propertyId });

      // update favorite property id
      dispatch(PropertyActions.setPropertyFavoriteId(id));

      // lift state if possible
      if (onFavoriteChange) {
        onFavoriteChange(id);
      }

      // show notificaiton
      notification.open({
        className: "favorite-notification",
        description: "has been added to your favorites",
        duration: 3,
        icon: <Icon icon={["fas", "heart"]} />,
        message: propertyName,
      });
    } catch (error) {
      // log error
      captureMessage("Error creating Favorite Hotel", { error });
    }
  };

  const deleteFavorite = async () => {
    try {
      // IGNORE-REASON ENS-2599 ⭐ fix me for good karma
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await FavoriteProperty.delete(property!.favoritePropertyId!);

      // update favorite property id
      dispatch(PropertyActions.setPropertyFavoriteId(null));

      // lift state if possible
      if (onFavoriteChange) {
        onFavoriteChange(null);
      }

      // show notificaiton
      notification.open({
        className: "favorite-notification",
        description: "has been removed from your favorites",
        duration: 3,
        icon: <Icon icon={["fal", "heart"]} />,
        message: propertyName,
      });
    } catch (error) {
      // log error
      captureMessage("Error deleting Favorite Hotel", { error });
    }
  };

  const handleFavoriteClick: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();

    if (property?.favoritePropertyId) {
      deleteFavorite().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    } else {
      createFavorite().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    }
  };

  useEffect(() => {
    const propertiesAreEqual = equals(property, currentProperty.current);

    if (!property || propertiesAreEqual) return;

    const favoriteId = property.favoritePropertyId;
    const performFavorite = PropertyActions.setPropertyFavoriteId(favoriteId);

    currentProperty.current = property;

    dispatch(performFavorite);
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property]);

  return useAtlas ? (
    <IconButton
      icon={property?.favoritePropertyId ? "heart--solid" : "heart"}
      variant="plain"
      color="everdark"
      className={className}
      onClick={handleFavoriteClick}
      title={property?.favoritePropertyId ? "Remove Favorite" : "Add Favorite"}
      data-testid="favorite-toggle"
      style={property?.favoritePropertyId ? { color: tokens.colors.accentRed } : {}}
    />
  ) : (
    <Styled.FavoriteToggle
      className={className}
      onClick={handleFavoriteClick}
      title={property?.favoritePropertyId ? "Remove Favorite" : "Add Favorite"}
      $isFavorited={!!property?.favoritePropertyId}
      data-testid="favorite-toggle"
    >
      <svg
        width="32"
        height="28"
        viewBox="0 0 32 28"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-hidden="true" // This is because the heart icon is strictly decorative and the button's title attribute holds the accessibility value/benefit
      >
        <title>Heart Icon</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.2449 2.6754C25.2906 0.157998 20.7926 0.527193 17.9492 3.46093L16.0004 5.46916L15.2828 4.72969L14.0503 3.45976C11.214 0.527369 6.70993 0.157903 3.75606 2.67515C0.299781 5.6251 0.0678787 10.9677 3.20647 14.2123L15.2998 26.6994C15.6882 27.1002 16.3064 27.1002 16.6945 26.6996L28.7882 14.2121C31.9318 10.9681 31.7018 5.6261 28.2449 2.6754ZM28.2449 2.6754C28.2449 2.67532 28.2448 2.67523 28.2447 2.67515L28.2452 2.67565C28.2451 2.67556 28.245 2.67548 28.2449 2.6754Z"
          stroke="white"
          strokeWidth="2"
        />
      </svg>
    </Styled.FavoriteToggle>
  );
};
