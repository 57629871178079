import { useSearchParams } from "@hotel-engine/hooks/useSearchParams";
import type { ILinkedAuthStrategy } from "@hotel-engine/types/authStrategy";
import config from "config";

import * as Styled from "./styles";

interface ISignInWithSSOButtonProps {
  selectedAuthStrategy?: ILinkedAuthStrategy;
  onlyOneStrategy: boolean;
}

const SSOButton = ({ selectedAuthStrategy, onlyOneStrategy }: ISignInWithSSOButtonProps) => {
  const { return: returnUrl } = useSearchParams<{
    return: string;
  }>();
  const returnPath = returnUrl ? new URL(returnUrl).pathname : "/";
  const ssoParams = `target=members&path=${encodeURIComponent(returnPath)}`;
  const url = `${config.ssoHost}${selectedAuthStrategy?.url}?${ssoParams}`;
  const buttonText = onlyOneStrategy ? "Continue with" : "Sign in with";
  return (
    <Styled.SSOButton
      disabled={selectedAuthStrategy ? false : true}
      size="large"
      id="auth-url"
      href={selectedAuthStrategy ? url : undefined}
      block
      target="_self"
      type="primary"
    >
      {selectedAuthStrategy
        ? `${buttonText} ${selectedAuthStrategy?.displayName}`
        : "Sign in with SSO"}
    </Styled.SSOButton>
  );
};

export default SSOButton;
