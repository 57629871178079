import styled from "styled-components";

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const SectionIcon = styled.div`
  flex-grow: 0;
  padding-top: ${({ theme }) => theme.spacing[4]};
  padding-right: ${({ theme }) => theme.spacing[12]};
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  margin-bottom: ${({ theme }) => theme.spacing[6]};
  gap: ${({ theme }) => theme.spacing[2]};
`;
