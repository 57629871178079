import { useTranslation } from "react-i18next";

export const useAirlineWebsite = () => {
  const { t } = useTranslation("iata_airline_website");

  return {
    getAirlineWebsiteFromIataAirlineCode: (iataAirlineKey: string) =>
      t(`iata_airline_website.site.${iataAirlineKey}`),
  };
};
