import styled from "styled-components";

const RefundDetailsItem = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing[8]};
`;

const FieldLine = styled.div`
  display: grid;
  grid-template-columns: 140px 1fr;
  column-gap: ${({ theme }) => theme.spacing[16]};
  flex-direction: column;
`;

export { RefundDetailsItem, FieldLine };
