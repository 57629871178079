import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";

export const useCompanyName = () => {
  const rebrandFlagEnabled = useIsFeatureFlagOn("rebrand_logo_copy");

  const COMPANY_NAME = rebrandFlagEnabled ? ("Engine" as const) : ("Hotel Engine" as const);
  const COMPANY_NAME_CONDENSED = rebrandFlagEnabled
    ? ("Engine" as const)
    : ("HotelEngine" as const);
  const COMPANY_ABBREVIATION = rebrandFlagEnabled ? ("Engine" as const) : ("HE" as const);
  const COMPANY_URL = rebrandFlagEnabled ? ("engine.com" as const) : ("hotelengine.com" as const);
  const COMPANY_LOGO = rebrandFlagEnabled
    ? ("engine--lockup" as const)
    : ("hotel-engine--lockup" as const);
  const COMPANY_BRANDMARK = rebrandFlagEnabled ? ("engine" as const) : ("hotel-engine" as const);

  return {
    COMPANY_NAME,
    COMPANY_NAME_CONDENSED,
    COMPANY_ABBREVIATION,
    COMPANY_URL,
    COMPANY_LOGO,
    COMPANY_BRANDMARK,
  };
};
