import { Typography } from "@hotelengine/atlas-web";
import * as Styled from "./styles";

export type ItineraryWalkScoreProps = {
  percentage: string;
  description: string;
};

const ItineraryWalkScore = ({ percentage, description }: ItineraryWalkScoreProps) => {
  const fullScore = +percentage === 100;
  const singleDigitScore = +percentage < 10;
  const infoMissing = !percentage || !description;

  let xOffset = "9";

  if (infoMissing) {
    xOffset = "6";
  } else if (fullScore) {
    xOffset = "4";
  } else if (singleDigitScore) {
    xOffset = "13";
  }

  return (
    <Styled.WalkScoreWrapper data-testid="property-walkscore">
      <svg viewBox="0 0 36 36" className="circular-chart">
        <path
          className="circle-bg"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
          fill="none"
        />
        <path
          className="percentage"
          strokeDasharray={`${!infoMissing ? percentage : 0}, 100`}
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
          fill="none"
        />
        <text x={xOffset} y="23" className="label">
          {!infoMissing ? percentage : "N/A"}
        </text>
      </svg>

      <Styled.WalkScoreContent>
        <Typography variant="body/xs" color="foregroundSecondary">
          Walkscore&reg;
        </Typography>
        <Typography variant="body/sm-strong" color="foregroundPrimary">
          {!infoMissing ? description : "Not Available"}
        </Typography>
      </Styled.WalkScoreContent>
    </Styled.WalkScoreWrapper>
  );
};

export default ItineraryWalkScore;
