import { IconButton, Typography } from "@hotelengine/atlas-web";

import type { FC, PropsWithChildren } from "react";

import * as Styled from "./styles";

type TMobileModalFullscreenProps = FC<
  PropsWithChildren<{
    open: boolean;
    onClose: () => void;
    title: string;
    headWithBorderBottom?: boolean;
    noClose?: boolean;
  }>
>;

const MobileModal: TMobileModalFullscreenProps = ({
  open,
  onClose,
  title,
  children,
  headWithBorderBottom,
  noClose,
}) => {
  return (
    <Styled.Modal open={open}>
      <Styled.ModalHead id="mobile-modal-title" borderBottom={headWithBorderBottom}>
        <Typography variant="heading/lg">{title}</Typography>
        {!noClose && (
          <IconButton
            name="xmark"
            role="button"
            onClick={() => onClose()}
            icon="xmark"
            aria-describedby="mobile-modal-title"
          />
        )}
      </Styled.ModalHead>

      <Styled.ModalBody>{children}</Styled.ModalBody>
    </Styled.Modal>
  );
};

export default MobileModal;
