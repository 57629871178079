import { useFlightModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { getFlightSlices } from "@hotel-engine/app/ItineraryContent/flights/components/FlightSlices/getFlightSlices";
import { TravelerInfo } from "@hotel-engine/app/ItineraryContent/flights/components/TravelerInfo";

import * as Styled from "./styles";
import { Box, Typography } from "@hotelengine/atlas-web";

const FlightInformation = () => {
  const { reservation } = useFlightModificationsContext();

  const cancellationSlices = getFlightSlices(reservation.slices);

  const numTravelers = reservation.travelers.length;
  const travelersCopy = `Traveler${numTravelers > 1 ? "s" : ""}`;

  return (
    <Styled.FlightInfoContainer>
      <Box display="flex" flexDirection="column" gap={8}>
        <Typography variant="uppercase/xs-strong" color="foregroundSecondary">
          {travelersCopy}
        </Typography>
        <TravelerInfo reservation={reservation} padTop={false} />
      </Box>
      {cancellationSlices}
    </Styled.FlightInfoContainer>
  );
};

export default FlightInformation;
