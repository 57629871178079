import type { FC, MouseEventHandler } from "react";

import { HEIcon } from "@hotel-engine/assets";
import { AutoComplete, AutoCompleteOption } from "@hotel-engine/common/AutoComplete";

import Spin from "@hotel-engine/common/Spin";
import { useGoogleAutoCompleteResults } from "@hotel-engine/hooks/useGoogleAutocompleteResults";
import { sanitizeHtml } from "@hotel-engine/utilities";
import { InputIconContainer } from "../CarsSearchHeader/styles";
import { getIconType } from "./helpers";
import * as Styles from "./styles";
import { Icon, TextInput as Input } from "@hotelengine/atlas-web";
import type { IconName } from "@fortawesome/fontawesome-svg-core";
import type { CustomIconName } from "@hotelengine/atlas-core";
import { useIsMobile } from "pages/Cars/hooks/useIsMobile";

export interface ISelectedLocation {
  id: string;
  name: string;
}

export const GoogleAutocompleteInput: FC<{
  id: string;
  onSelect: (location: ISelectedLocation) => void;
  onClear: MouseEventHandler;
  onBlur?: (value) => void;
  value: string;
  onUpdateValue: (val: string) => void;
  iconName?: string;
  placeholder?: string;
}> = ({
  id,
  onSelect,
  onClear,
  onBlur,
  value,
  onUpdateValue,
  iconName = "car",
  placeholder = "Where to?",
}) => {
  const { isAutocompleteLoading, predictions } = useGoogleAutoCompleteResults(value);
  const isMobile = useIsMobile();

  const handleSelect = (selectedValueId) => {
    const result = predictions.find((place) => place.place_id === selectedValueId);

    if (!result) return;

    onSelect({
      id: result.place_id,
      name: result.description,
    });
    onUpdateValue(result.description);
  };

  //  helpers
  const getPopupContainer = () => {
    const mainContainer = document.getElementById("location-input-container");
    return mainContainer || document.body;
  };

  // Antd prop components
  const InputSuffix = () => (
    <>
      {!!isAutocompleteLoading && <Spin size="small" />}
      {!isAutocompleteLoading && Boolean(value) && (
        <Styles.RightIcon role="button" onClick={onClear}>
          <Icon name="xmark" />
        </Styles.RightIcon>
      )}
    </>
  );

  const renderAutoCompleteOptions = () => {
    const boldQuery = (description: string) => {
      const regex = new RegExp(value, "ig");
      return description?.replace(regex, (match) => `<strong>${match}</strong>`);
    };

    return [
      ...predictions.map((location) => (
        <AutoCompleteOption
          key={`searchResults-${location.place_id}-${id}`}
          data-testid="dropdown-option"
          value={location.place_id}
        >
          <Styles.OptionContainer>
            <Styles.OptionIcon>
              <HEIcon type={getIconType(location.types)} />
            </Styles.OptionIcon>
            <Styles.OptionDescription>
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(boldQuery(location.description || "")),
                }}
              />
            </Styles.OptionDescription>
          </Styles.OptionContainer>
        </AutoCompleteOption>
      )),
    ];
  };

  return (
    <AutoComplete
      className={`location-input-autocomplete-${id}`}
      dataSource={renderAutoCompleteOptions()}
      dropdownClassName={`location-input-dropdown-${id}`}
      dropdownMatchSelectWidth={isMobile}
      getPopupContainer={getPopupContainer}
      onBlur={onBlur}
      onChange={(newValue) => onUpdateValue(newValue.toString())}
      onSelect={handleSelect}
      size="large"
      value={value}
      data-testid="location-autocomplete"
    >
      <Input
        placeholder={placeholder}
        leadingAdornment={
          <InputIconContainer>
            <Icon
              name={`${iconName}--solid` as IconName & CustomIconName}
              color="inputForegroundHelper"
            />
          </InputIconContainer>
        }
        trailingAdornment={<InputSuffix />}
        autoComplete="nope"
        data-testid={`location-input-${id}`}
      />
    </AutoComplete>
  );
};
