import type { IGooglePrediction, IRecentSearch } from "./search";
import type { IWorksite } from "./worksite";

/**
 * Represents the data coming back from the v2 /locations endpoint.
 * This is an updated version of IPlace and will remain more in sync with any endpoint changes rather than the services code
 */
export interface ILocationRecord {
  city?: string;
  country?: string;
  description: string;
  id: string;
  latitude: number;
  locationName: string;
  longitude: number;
  market?: string;
  postalCode?: string;
  region: string;
  streetAddress?: string;
  types: string[];
  iataCode?: string;
}

export type ReturnSearchResultsObj = {
  recentSearches: IRecentSearch[];
  worksites: IWorksite[];
  companyChoice: ILocationRecord[];
  favorites: ILocationRecord[];
  apiResults: ILocationRecord[];
  googleResults: IGooglePrediction[];
};

export type RemoveDuplicatePropertiesObj = Omit<
  ReturnSearchResultsObj,
  "recentSearches" | "worksites"
>;

export type V3LocationRecord = IRecentSearch | ILocationRecord | IWorksite | IGooglePrediction;

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type IOptionalLatLngLocationRecord = Optional<ILocationRecord, "latitude" | "longitude">;

/** type guard for ILocationRecords. */
export function isLocationRecord(location: unknown): location is ILocationRecord {
  return (location as ILocationRecord)?.latitude !== undefined;
}

export type IColdStateResult = ILocationRecord | IRecentSearch | IWorksite;

export interface IShortLocationRecord {
  street: string;
  city: string;
  state: string;
  country: string;
}
