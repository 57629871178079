import type { CSSProperties, PropsWithChildren, ReactNode } from "react";
import { useMemo } from "react";
import { baseConfig } from "../../../config";
import { useBreakpoint } from "../../hooks";
import { initialMapOptions } from "../GoogleMap/constants";
import * as Styled from "./styles";

type MapLocation =
  | {
      latitude: number;
      longitude: number;
      // Address shouldn't be provided if lat and lng are filled
      address?: never;
    }
  | {
      address: string;
      // Lat/Lng shouldn't be provided if address is filled
      latitude?: never;
      longitude?: never;
    };

export interface IGoogleMapStaticImage {
  location: MapLocation;
  zoom?: number;
  scale?: number;
  size: { width: number; height: number };
  title?: string;
  customStyle?: CSSProperties;
}

export const GoogleMapStaticImage = ({
  location,
  zoom = 13,
  size,
  title,
  scale = 2,
  children,
  customStyle,
}: PropsWithChildren<IGoogleMapStaticImage>) => {
  const isMobile = useBreakpoint("md", "max");

  const url = useMemo(() => {
    const center =
      location.latitude && location.longitude
        ? `${location.latitude},${location.longitude}`
        : location.address;

    const parameters = {
      center,
      zoom: zoom,
      size: `${size.width}x${size.height}`,
      scale: scale,
      map_id: initialMapOptions.mapId,
      key: baseConfig.googleAutocompleteApiKey,
    };

    const queryParameters = Object.keys(parameters)
      .map((key) => `${key}=${encodeURIComponent(parameters[key])}`)
      .join("&");

    return `https://maps.googleapis.com/maps/api/staticmap?${queryParameters}`;
  }, [location, zoom, size.width, size.height, scale]);

  return (
    <Styled.StaticImageWrapper
      className="google-static-map-wrapper"
      data-testid="itinerary-google-map-static"
      $isMobile={isMobile}
      style={customStyle}
    >
      <Styled.StaticImage src={url} alt={title} data-testid="google-static-map-image" />

      {children}
    </Styled.StaticImageWrapper>
  );
};

GoogleMapStaticImage.Marker = function Marker({
  children,
}: {
  children: ReactNode;
}) {
  return <Styled.MarkerWrapper>{children}</Styled.MarkerWrapper>;
};
