import styled from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const OptionContainer = styled.div`
  ${flexbox({ alignItems: "center" })}
  height: 36px;
  padding: 10px;
  box-sizing: border-box;
  color: inherit;
`;
export const OptionIcon = styled.div`
  ${flexbox({ justifyContent: "center", alignItems: "center" })}
  padding: 10px;
  min-width: 35px;
`;

export const OptionDescription = styled.div`
  padding: 10px;
  &:hover {
    overflow-x: auto;
  }
`;

export const RightIcon = styled.span`
  padding-right: ${({ theme }) => theme.spacing[8]};
  display: flex;
  align-items: stretch;
`;
