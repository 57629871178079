import * as Styled from "./mobileStyles";

interface IMobileMenuIconProps {
  hasUnreadNotifications: boolean;
  onClick: () => void;
  isOpen: boolean;
}

const MobileMenuIcon = ({ hasUnreadNotifications, onClick, isOpen }: IMobileMenuIconProps) => (
  <Styled.MenuIconWrap>
    {!!hasUnreadNotifications && <Styled.Indicator />}
    <Styled.MenuIcon
      className={isOpen ? "open" : "closed"}
      onClick={onClick}
      id="mobile-menu-icon"
      date-testid="mobile-menu-icon"
      aria-label={isOpen ? "close menu" : "open menu"}
    >
      <span />
      <span />
      <span />
      <span />
    </Styled.MenuIcon>
  </Styled.MenuIconWrap>
);

export default MobileMenuIcon;
