import { useEffect } from "react";
import { Modal } from "@hotel-engine/common/Modal";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";

import { useCancelBooking } from "../../hooks/useCancelBooking";
import { useCancelFormValues } from "../../hooks/useCancelFormValues";
import { useCancelRefundCalculator } from "../../hooks/useCancelRefundCalculator";
import CancelConfirmationModal from "../common/CancelConfirmationModal";
import CancellationButton from "../common/CancellationButton";
import CancellationTitle from "../common/CancellationTitle";
import CancelInfoBox from "../common/CancelInfoBox";
import { useQueryClient } from "react-query";
import { endpoints } from "@hotel-engine/react-query/constants";

const SingleRoomCancellation = () => {
  const { COMPANY_NAME } = useCompanyName();
  const queryClient = useQueryClient();
  const {
    state: { showConfirmationModal, modificationStatus },
    reservation,
    dispatch,
  } = useModificationsContext();

  const { formValues } = useCancelFormValues({ reservation });

  const {
    cancelRefundAmount,
    cancelRefundType,
    isError: isRefundCalcError,
    isLoading: isRefundCalcLoading,
  } = useCancelRefundCalculator({
    reservation,
    cancelRooms: formValues.cancelRooms,
    modificationStatus,
  });

  const setShowStatusModal = () => {
    dispatch({
      type: "SET_SHOW_STATUS_MODAL",
      payload: true,
    });
  };

  const { submitCancelSingleRoomBooking } = useCancelBooking({
    reservation,
    cancelRoomsValue: formValues.cancelRooms,
    showStatusModal: setShowStatusModal,
    refundInfo: {
      totalRefundAmount: Number(cancelRefundAmount),
      refundType: cancelRefundType,
    },
    dispatch,
  });

  useEffect(() => {
    if (isRefundCalcError) {
      Modal.info({
        title: "Oops! Something's not right.",
        onOk: () => globalThis.history.go(0),
        content: `There was an issue processing your cancellation. Please contact ${COMPANY_NAME} support at at 855-567-4683.`,
      });
    }
  }, [COMPANY_NAME, isRefundCalcError]);

  const handleCancelSingleRoom = async () => {
    await submitCancelSingleRoomBooking();
    await queryClient.invalidateQueries(endpoints.trips, { exact: false });
  };

  return (
    <>
      <CancellationTitle />
      <CancelInfoBox
        cancelRefundAmount={cancelRefundAmount}
        cancelRefundType={cancelRefundType}
        cancelRooms={formValues.cancelRooms}
        isLoading={isRefundCalcLoading}
      />
      <CancellationButton text="Cancel trip" isRefundCalcLoading={isRefundCalcLoading} />
      {!!showConfirmationModal && <CancelConfirmationModal onConfirm={handleCancelSingleRoom} />}
    </>
  );
};

export default SingleRoomCancellation;
