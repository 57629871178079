import { Field, FieldArray, useFormikContext } from "formik";

import InputField from "@hotel-engine/common/FormikFields/InputField";
import type { ICustomField } from "@hotel-engine/types/customField";

import { CustomFieldInput } from "./components/CustomFieldInput";

interface ICustomFieldsFormikContext {
  /** Array of custom fields with values */
  customFields: ICustomField[];
}

export const CustomFieldsForm = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<ICustomFieldsFormikContext>();

  return (
    <FieldArray name="customFields">
      {() => (
        <>
          {values?.customFields.map((customField, index) => {
            const { id, name, options, helpText } = customField;
            const isSelect = Boolean(customField.options?.length);
            if (isSelect) {
              return (
                <Field
                  onChange={(val) => {
                    setFieldValue(`customFields.${index}.value`, val, false);
                    setFieldTouched(`customFields.${index}.value`);
                  }}
                  key={id}
                  label={name}
                  component={CustomFieldInput}
                  options={options?.sort((a, b) => a.localeCompare(b))}
                  name={`customFields.${index}.value`}
                  placeholder={helpText || `Select ${name}`}
                  required={customField.required}
                />
              );
            } else {
              return (
                <Field
                  key={id}
                  label={name}
                  component={InputField}
                  name={`customFields.${index}.value`}
                  placeholder={helpText || `Enter ${name}`}
                  required={customField.required}
                />
              );
            }
          })}
        </>
      )}
    </FieldArray>
  );
};
