import { matchPath, useLocation } from "@hotel-engine/lib/react-router-dom";
import { routes } from "@hotel-engine/constants";
import { captureMessage } from "@hotel-engine/utilities/logger";
import { Unsafe } from "@hotel-engine/data";

import pRetry from "p-retry";

import { useEffect } from "react";

/**
 * We want the initial load to be fast,
 * but ultimately we want to make sure all javascript is loaded
 * once it's convenient to do so. This will prevent a scenario where
 * a user only fetched 1-2 chunks, and then we publish a new version
 * before they have a chance to navigate to the other pages, thus
 * resulting in them trying to fetch a hashed file that has been deleted.
 *
 * So, we'll prioritize the chunk for the user's first route, and once it's
 * finished downloading we'll fetch all the remaining chunks.
 *
 * If any of these chunks has already been fetched once, they won't be fetched
 * again. Webpack maintains an internal cache, so it won't result in duplicate
 * network requests.
 */
const preloadAllChunks = async (currentPath: string) => {
  // Belongs to chunk: "Login"
  const isLogin = matchPath(routes.login, currentPath);
  // Belongs to chunk: "FindResults"
  const isDashboard = matchPath(routes.dashboard, currentPath);
  const isSearch = matchPath(routes.results, currentPath);

  if (isLogin) {
    await import(/* webpackChunkName: "Login" */ "../../pages/Login/Login");
    return Promise.all([
      import(/* webpackChunkName: "FindResults" */ "../../pages/DashboardLegacy"),
      import(/* webpackChunkName: "Other" */ "../../pages/MyProperties"),
    ]);
  } else if (isDashboard || isSearch) {
    await import(/* webpackChunkName: "FindResults" */ "../../pages/DashboardLegacy");
    return Promise.all([
      import(/* webpackChunkName: "Login" */ "../../pages/Login/Login"),
      import(/* webpackChunkName: "Other" */ "../../pages/MyProperties"),
    ]);
  } else {
    await import(/* webpackChunkName: "Other" */ "../../pages/MyProperties");
    return Promise.all([
      import(/* webpackChunkName: "Login" */ "../../pages/Login/Login"),
      import(/* webpackChunkName: "FindResults" */ "../../pages/DashboardLegacy"),
    ]);
  }
};

/**
 * Preload all of the remaining chunks, based on the current route.
 * The idea is to force load all javascript for the site, but not do
 * it until after all the of required JS to load the initial route,
 * has already been loaded.
 */
export const usePreloadAllChunks = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    pRetry(
      () => {
        return preloadAllChunks(pathname);
      },
      {
        retries: 3,
        onFailedAttempt: (error) => {
          if (error.retriesLeft === 0) {
            // Tried to refetch this chunk a few times but it's still not
            // loading for some reason.
            // Even though preloading the chunk has failed, there's still
            // a chance that organically loading the route will succeed.
            // If that also happens, they will see the error modal allowing
            // them to refresh the page.
            const errorMessage = `Failed to preload chunk for pathname: "${pathname}"`;
            captureMessage(errorMessage, { error });
          }
        },
      }
    ).then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
  }, [pathname]);
};
