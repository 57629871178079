import { useQueryClient } from "react-query";
import { useAppSelector } from "store/hooks";
// import { useTripsContext } from "./context";
import { endpoints } from "@hotel-engine/react-query/constants";
import type { ITripsResult } from "@hotel-engine/types/trips";

function useHideCars() {
  // Commenting this until on real endpoint since this can then get removed from the context, where it's blowing up because the status is not there yet
  //   const {
  //     state: { status },
  //   } = useTripsContext();
  const queryClient = useQueryClient();
  const user = useAppSelector(({ Auth }) => Auth.user);
  const data = queryClient.getQueryData<ITripsResult>(endpoints.trips);
  const bookings = data?.trips.flatMap((trip) => trip.bookings) || [];
  const hasCarTrips = bookings.some(({ bookingType }) => bookingType === "car");
  const doesBusinessHideCars = user?.business.hideCars;
  //   const isTripsActive = /today|upcoming/.test(status);

  //   const shouldHideCars = doesBusinessHideCars && isTripsActive && !hasCarTrips;
  const shouldHideCars = doesBusinessHideCars && !hasCarTrips;

  return shouldHideCars;
}

export default useHideCars;
