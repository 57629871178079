import type { DirectBillAndIncidentalsConfigType, MaxDailyRates } from "./travelPolicies";
import type { IActivePropertyReview, IPostCheckoutAction } from "./notifications";
import type { IBusiness } from "./business";
import type { IDepartment } from "./department";
import type { IHotelLoyaltyUserRecord } from "./hotelLoyalty";
import type { IRole } from "./role";
import type { IOccupant } from "./booking";

export type UserProvider = "email" | "google_oauth2";
export type UserDistanceUnit = "mi" | "km";

export type UserAccountType = "personal" | "business";

export interface IUser {
  activePropertyReviewRequests?: IActivePropertyReview[];
  canBookWithIncidentals: boolean;
  completedBookingCount?: number;
  business: IBusiness;
  businessId: number;
  businessTravelUserBusinessId: null | number;
  businessTravelUserEmail: null | string;
  businessTravelUserId: null | number;
  confirmedAt: string | null;
  confirmationSentAt: string;
  countryCode?: string;
  courierHmac: string;
  department: IDepartment;
  departmentId: IDepartment["id"];
  departmentName: string;
  directBill: boolean;
  directBillOnboarding: {
    onboardingModal: boolean;
    checkOutPage: boolean;
    companySettings: boolean;
    paymentMethods: boolean;
  } | null;
  directBillConfiguration?: DirectBillAndIncidentalsConfigType;
  distanceUnit: UserDistanceUnit | null;
  email: string;
  employeeId?: string;
  expressBookEligibility: "allowed" | "blocked" | "payment_profile_required";
  firstName: string;
  firstTimeSignIn: boolean;
  hasReportingAccess: boolean;
  hasGroupsSpecialization: boolean;
  id: number;
  incidentalsConfiguration?: DirectBillAndIncidentalsConfigType;
  impersonatorId?: number;
  isDemo: boolean;
  lastName: string;
  lastSignInAt: string;
  maxDailyRates?: MaxDailyRates;
  mfaMode: "required" | "email-only" | "bypass";
  occupantType?: "user" | "guest";
  phone?: string;
  promptForPropertyReviews: boolean;
  postCheckoutActions: IPostCheckoutAction[];
  refundableOnly: boolean;
  rewardsMember: boolean;
  rewardsProfile: IRewardsProfile | null;
  shouldPromptForPropertyReviews: boolean;
  role: IRole;
  userNotice: string | null;
  userLoyaltyRewards?: IUserLoyaltyProgram[];
  accountType: UserAccountType;
  provider: UserProvider;
  personalTravelUserId: number | null;
  personalTravelUserEmail: string | null;
  personalTravelUserConfirmedAt?: string | null;
  phoneVerified?: boolean;
  supportCaseUpdatesCount?: number;
}

export function isIUser(input: IUser | IOccupant): input is IUser {
  return "confirmedAt" in input;
}

export interface IUserV2Response {
  limit: number;
  offset: number;
  total: number;
  users: IUser[];
}

export interface IUserLoyaltyProgram extends IHotelLoyaltyUserRecord {
  userId?: number;
}

export interface IRewardsProfile {
  /** Number of bookings completed during current term */
  bookings: number | string;
  /** Details about member's current tier */
  currentTier: IRewardsProfileCurrentTier;
  /** Date member joined rewards program */
  joinDate: string;
  /** Member's ID */
  memberId: number | string;
  /** Details about member's next tier */
  nextTier: IRewardsProfileNextTier | null;
  /** Points member has available for redemption */
  points: number;
  /** Ammount spent by member during current term */
  spend: number | string;
}

export interface IRewardsProfileCurrentTier {
  /** Beginning date of current term */
  startDate: string;
  /** Term of current tier */
  name: string;
  /** Position of term in tier sequence */
  tierNumber: number;
  /** Points earned per dollar spent */
  pointsPerDollar: number;
  /** Number of bookings required to unlock tier */
  bookingCountUnlock: number;
  /** Number of dollars required to unlock tier */
  spendUnlock: number;
}

export interface IRewardsProfileNextTier {
  /** Beginning date of next term */
  startDate: string | null;
  /** Term of next tier */
  name: string;
  /** Position of term in tier sequence */
  tierNumber: number;
  /** Points earned per dollar spent */
  pointsPerDollar: number;
  /** Number of bookings required to unlock tier */
  bookingCountUnlock: number;
  /** Number of dollars required to unlock tier */
  spendUnlock: number | string;
}

export interface IUserPrefsState {
  dashboardCalloutDismissed: boolean;
  dashboardTabPreference: "favorites" | "trips";
  favoriteCalloutDismissed: boolean;
  guestCount: number;
  lastPaymentId: number | null;
  roomCount: number;
  singleRoomGuestCount: number;
  showEngines: boolean;
  showRatelessProperties: boolean;
  showSearchRadiusCircle: boolean;
}
