import { Typography } from "@hotelengine/atlas-web";
import { formatCurrency } from "@hotel-engine/utilities";
import type { ICurrency } from "@hotel-engine/constants";
import type { IReservationBase } from "@hotel-engine/types/reservation";

import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import NightlyRateBreakdown from "./components/NightlyRateBreakdown";
import * as Styled from "./styles";

export interface INightlyRatesProps {
  reservation: IReservationBase;
  currencyCode: ICurrency;
  showBreakdown?: boolean;
}

const NightlyRates = ({ reservation, currencyCode, showBreakdown }: INightlyRatesProps) => {
  const { typographyVariants } = useTypographyScaling();

  const roomsLabel = `${reservation.roomCount} room${reservation.roomCount > 1 ? "s" : ""}`;
  const nightsLabel = `${reservation.nightCount} night${reservation.nightCount > 1 ? "s" : ""}`;
  const label = `${roomsLabel}, ${nightsLabel}`;

  const roomSubtotal = formatCurrency(
    Number(reservation.charges?.roomSubtotal),
    true,
    currencyCode
  );

  return (
    <>
      {!!showBreakdown && (
        <NightlyRateBreakdown reservation={reservation} currencyCode={currencyCode} />
      )}
      <Styled.FieldLine>
        <Typography variant={typographyVariants.body} color="foregroundSecondary">
          {label}
        </Typography>
        <Typography variant={typographyVariants.body} color="foregroundPrimary">
          {roomSubtotal}
        </Typography>
      </Styled.FieldLine>
    </>
  );
};

export default NightlyRates;
