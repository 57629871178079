import { Typography } from "@hotelengine/atlas-web";
import { useTripExtensionContext } from "../../useTripExtensionContext";
import { routes } from "@hotel-engine/constants";
import { Link } from "@hotel-engine/lib/react-router-dom";
import * as Styled from "./styles";

const ExtensionCopy = () => {
  const { previousTrip, transformDate } = useTripExtensionContext();
  const urlPath = `${routes.itinerary}?contract_number=${previousTrip?.contractNumber}`;

  return (
    <Typography variant="body/sm" color="foregroundPrimary">
      <Styled.BookingLine>
        <Link to={urlPath}>
          {`${transformDate(previousTrip?.checkIn)} - ${transformDate(previousTrip?.checkOut)}`}
        </Link>
      </Styled.BookingLine>
    </Typography>
  );
};

export default ExtensionCopy;
