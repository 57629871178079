import { useFlex } from "@hotel-engine/hooks";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import moment from "moment";
import { useAppSelector } from "store/hooks";

export const usePastCancelledActions = (itinerary: IReservationBase) => {
  const user = useAppSelector((state) => state.Auth.user);
  const { flexShortenCutoff } = useFlex();
  const isViewOnlyTraveler = user?.role === "view_only_traveler";
  const showReviewPropertyButton =
    !isViewOnlyTraveler &&
    itinerary.status === "completed" &&
    !!itinerary.activePropertyReviewRequestId;
  const showHidePropertyButton =
    !isViewOnlyTraveler && ["completed", "cancelled"].includes(itinerary.status);

  const isEligibleToReportCheckoutDate =
    itinerary.flexEnabled &&
    itinerary.nightCount > 1 &&
    itinerary.status === "completed" &&
    moment().isBetween(moment(itinerary.checkOut), flexShortenCutoff(itinerary.checkOut));

  return {
    showHidePropertyButton,
    showReviewPropertyButton,
    isEligibleToReportCheckoutDate,
  };
};
