import { CancellationType, useCancellationType } from "./hooks/useCancellationType";
import MultiRoomCancellation from "./components/MultiRoomCancellation";
import SingleRoomCancellation from "./components/SingleRoomCancellation";
import FlightCancellation from "./components/FlightCancellation";
import CarCancellation from "./components/CarCancellation";

/** This is the primary router for cancellation types, it looks at the useCancellationType hook, which returns
 * the type of cancellation begin performed, then routes to that cancellation flow. Although all the components below
 * use shared common components from CancelRouter/components/common there is a bit of repetition in them. Previously
 * we had tried to smush all the modification logic into the same place to avoid this, which was sort of a mess, so this
 * time around we're trying to embrace a bit of repetition with the goal of clarity and maintainability.
 *
 * That said, for cancellations we were able to use the same components for multi/single for both flex and refundable because
 * for cancellations we don't care which case it is, it's all the same queries, hooks, and logic. This isn't the case for other
 * modification types like shortening though, so we should still follow a pattern of having a switch case for every flow in general,
 * at least to start.
 */
const CancelRouter = () => {
  const { cancellationType } = useCancellationType();

  switch (cancellationType) {
    case CancellationType.FlexSingleRoom:
    case CancellationType.RefundableSingleRoom: {
      return <SingleRoomCancellation />;
    }
    case CancellationType.FlexMultiRoom:
    case CancellationType.RefundableMultiRoom: {
      return <MultiRoomCancellation />;
    }
    case CancellationType.Flight: {
      return <FlightCancellation />;
    }
    case CancellationType.Car: {
      return <CarCancellation />;
    }

    default:
      return null;
  }
};

export default CancelRouter;
