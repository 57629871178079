import { Box, Typography } from "@hotelengine/atlas-web";
import { useRef, useState } from "react";
import * as Styled from "./styles";
import type { RangePickerValue } from "./styles";
import { ampli } from "ampli";
import { useAppSelector } from "store/hooks";
import { useTripsContext } from "pages/Trips/context";
import moment from "moment";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { DatePickerProps } from "antd/lib/date-picker/interface";

const getRangePicker = (...dates: (string | undefined)[]) => {
  const value = dates.filter(Boolean).map((date) => moment(date));

  return value as RangePickerValue;
};

const DateRange = () => {
  const user = useAppSelector((state) => state.Auth.user);
  const {
    state: { filters },
    dispatch,
  } = useTripsContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const [dates, setDates] = useState(getRangePicker(filters.startTimeGt, filters.startTimeLt));

  const getCalendarContainer = (triggerNode: Element) => {
    return containerRef.current || (triggerNode.parentElement as HTMLElement);
  };

  const handleDateChange = (range: RangePickerValue) => {
    if (!range[0] || !range[1]) {
      setDates([]);
      dispatch({
        type: "filter",
        filters: [{ key: "startTimeGt" }, { key: "startTimeLt" }],
      });

      return;
    }

    const startDate = range[0].startOf("day").toISOString();
    const endDate = range[1].endOf("day").toISOString();

    if (user) {
      ampli.clickDatesInTrips({
        endDate,
        startDate,
        userId: user.id,
      });
    }

    setDates(range);
    dispatch({
      type: "filter",
      filters: [
        { key: "startTimeGt", value: startDate },
        { key: "startTimeLt", value: endDate },
      ],
    });
  };

  const customWeekStartEndFormat = (value) =>
    `${moment(value[0]).format("M/D")} ~ ${moment(value[1]).format("M/D")}`;

  return (
    <Box>
      <Typography variant="uppercase/xs-strong" color="foregroundPrimary">
        Date range
      </Typography>
      <Box ref={containerRef} display="flex" gap={12} flexDirection="column" marginTop={8}>
        <Styled.RangePicker
          getCalendarContainer={getCalendarContainer}
          onChange={handleDateChange}
          format={customWeekStartEndFormat(dates) as DatePickerProps["format"]}
          placeholder={["Select dates", ""]}
          className={dates.length ? "selected" : "not-selected"}
          value={dates}
        />
      </Box>
    </Box>
  );
};

export default DateRange;
