import { Icon } from "@hotelengine/atlas-web";
import FullscreenModal from "@hotel-engine/common/FullscreenModal";

import * as Styled from "../../styles";
import { SearchActionIcon } from "../SearchActionIcon";

export type MobileAutocompleteWrapperProps = {
  children: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
  className?: string;
  getToggleButtonProps: () => object;
  handleClear: () => void;
  handleClose: () => void;
  hasError: boolean;
  isPortal: boolean;
  isSearchEmpty: boolean;
  searchInput: string;
  showModal: boolean;
  placeholder?: string;
  size?: "md" | "lg";
};

export const MobileAutocompleteWrapper = ({
  children,
  className,
  getToggleButtonProps,
  handleClear,
  handleClose,
  hasError = false,
  isPortal,
  isSearchEmpty,
  searchInput,
  showModal = false,
  placeholder = "Search by city or address",
  size = "md",
}: MobileAutocompleteWrapperProps) => {
  /** In mobile safari the FullscreenModal being appended drives the user to the bottom of the page. This allows us to scroll back to the top when the search modal is opened.
   * */
  const handleScrollToTop = () => {
    globalThis.scrollTo(0, 0);
    handleClose();
  };

  return (
    <Styled.LocationInput
      hasError={hasError}
      $isFullScreenMobileWrapper
      focusStyling={false}
      className={className}
      size={size}
    >
      <FullscreenModal
        isPortal={isPortal} //downshift doesn't play well when nested inside of portals.
        title="Select location"
        onClose={handleScrollToTop}
        visible={showModal}
        padding="24px 0"
        headerReverse
      >
        {children}
      </FullscreenModal>
      <Styled.MobileTextButton type="button" {...getToggleButtonProps()}>
        <Icon name="location-dot--solid" color="foregroundSecondary" />
        {isSearchEmpty ? (
          <Styled.MobilePlaceholder>{placeholder}</Styled.MobilePlaceholder>
        ) : (
          <Styled.MobileSearchText>{searchInput}</Styled.MobileSearchText>
        )}
      </Styled.MobileTextButton>
      <SearchActionIcon handleClear={handleClear} isDirty={!!searchInput && !showModal} />
    </Styled.LocationInput>
  );
};
