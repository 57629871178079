import type { Dispatch, SetStateAction } from "react";
import { ampli } from "ampli";

import { Button } from "@hotelengine/atlas-web";
import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import { useBaseModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import type { IFlightItinerary } from "@hotel-engine/types/itinerary";
import type { IReservationBase } from "@hotel-engine/types/reservation";

import { useAppSelector } from "store/hooks";

import { isFlight } from "../../../../../../isLodging";

export interface ICancellationButtonProps {
  text: string;
  isRefundCalcLoading: boolean;
  setShowValidationMessage?: Dispatch<SetStateAction<boolean>>;
}

/** Common button used for main cancellation flow view to bring up ConfirmationModal,
 * has a dynamic title but handles loading and press action internally based on the ModificationsContext
 * @prop text - what should the button say
 * @prop isRefundCalcLoading - disables button and sets loading state if calculator query is loading
 * @prop disableButton - disables button, used if no rooms selected in multi room (optional since only used in multi room)
 */
const CancellationButton = ({
  text,
  isRefundCalcLoading,
  setShowValidationMessage,
}: ICancellationButtonProps) => {
  const user = useAppSelector((state) => state.Auth?.user);
  const { isPreview } = useTypographyScaling();
  const {
    state: { modificationStatus },
    dispatch,
    reservation,
  } = useBaseModificationsContext();

  const isFlightBooking = isFlight(reservation);

  const isPostCancellation =
    modificationStatus.isSubmitted || (reservation as IReservationBase).status === "cancelled";
  const showLoadingState = modificationStatus.isLoading || isRefundCalcLoading;
  const showDisabledState = showLoadingState || isPostCancellation;

  const handleClickCancel = () => {
    if (!!setShowValidationMessage) {
      setShowValidationMessage(true);
    } else {
      dispatch({ type: "SET_SHOW_CONFIRMATION_MODAL", payload: true });

      if (isFlightBooking) {
        ampli.clickCancelFlight({
          userId: user?.id,
          flightBookingID: (reservation as IFlightItinerary)?.bookingNumber,
        });
      }
    }
  };

  return (
    <Button
      variant="outlined"
      size={isPreview ? "md" : "lg"}
      color="destructive"
      onClick={handleClickCancel}
      data-testid="cancel-trip-button"
      style={{ width: "100%", marginTop: !!setShowValidationMessage ? 0 : 24 }}
      disabled={showDisabledState}
    >
      {showLoadingState ? "Updating..." : text}
    </Button>
  );
};

export default CancellationButton;
