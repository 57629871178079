import moment from "moment";
import { useFlightModificationsContext } from "../../ModificationsContext";

export const useActiveSliceIndex = () => {
  const { reservation } = useFlightModificationsContext();
  const slices = reservation?.slices;

  if (!reservation || !slices?.length) {
    return 0;
  }

  const activeSliceIndex = slices.findIndex((slice) => {
    const now = moment();
    const sliceIsNextActive = moment(
      slice.segments[slice.segments.length - 1].destination.arrivalTime
    ).isAfter(now);

    return sliceIsNextActive;
  });

  // Fall back to the first slice if none are upcoming
  return activeSliceIndex === -1 ? 0 : activeSliceIndex;
};
