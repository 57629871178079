import { useAirlineCheckInUrl } from "pages/Flights/hooks/useAirlineCheckInUrl";
import { useAirlineWebsite } from "pages/Flights/hooks/useAirlineWebsite";
import { useAirlineSupportUrl } from "pages/Flights/hooks/useAirlineSupportUrl";

export type AirlineLinkTypes = "main" | "checkIn" | "support";

export const useAirlineLinks = () => {
  const { getAirlineWebsiteFromIataAirlineCode } = useAirlineWebsite();
  const { getAirlineCheckInUrlFromIataAirlineCode } = useAirlineCheckInUrl();
  const { getAirlineSupportUrlFromIataAirlineCode } = useAirlineSupportUrl();

  const getAirlineLink = (requestedLink: AirlineLinkTypes, airlineCode: string) => {
    const urlGetters = {
      main: getAirlineWebsiteFromIataAirlineCode(airlineCode),
      checkIn: getAirlineCheckInUrlFromIataAirlineCode(airlineCode),
      support: getAirlineSupportUrlFromIataAirlineCode(airlineCode),
    };

    switch (requestedLink) {
      case "main": {
        return urlGetters.main;
      }
      case "checkIn": {
        // Adding a fallback to the homepage since not all check in urls are accounted for
        return urlGetters.checkIn === "" ? urlGetters.main : urlGetters.checkIn;
      }
      case "support": {
        // Adding a fallback to the homepage since not all support urls are accounted for
        return urlGetters.support === "" ? urlGetters.main : urlGetters.support;
      }
      default:
        return urlGetters.main;
    }
  };

  return { getAirlineLink };
};
