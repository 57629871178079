import styled from "styled-components";

import { Button } from "@hotelengine/atlas-web";
import AlertBanner from "@hotel-engine/common/AlertBanner";

const SocialButtonContainer = styled.div`
  margin: 20px 0 20px 0;
`;

const ErrorMessage = styled(AlertBanner)`
  margin: 8px 0 0;
`;

export const GoogleButton = styled(Button)`
  position: relative;
  width: 100%;
  min-width: 200px;
  margin-top: ${({ theme }) => theme.spacing[24]};
  text-align: center;
  background-color: ${({ theme }) => theme.colors.neutralN00};
  border-color: ${({ theme }) => theme.colors.borderDefault};

  &:hover,
  &:focus,
  &:focus-visible {
    box-shadow: none;
    background-color: ${({ theme }) => theme.colors.neutralN00}!important;
    border-color: ${({ theme }) => theme.colors.uiPrimary};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.neutralN100}!important;
    border-color: ${({ theme }) => theme.colors.borderDefault};
  }
`;

const GoogleButtonText = styled.span`
  color: ${({ theme }) => theme.colors.foregroundPrimary};

  &:hover {
    color: ${({ theme }) => theme.colors.uiPrimary};
  }
`;

const GoogleButtonIcon = styled.img`
  margin-right: 8px;
`;

export { SocialButtonContainer, ErrorMessage, GoogleButtonIcon, GoogleButtonText };
