import styled from "styled-components";

import { Dropdown } from "@hotel-engine/common/Dropdown";
import { Menu, MenuItem } from "@hotel-engine/common/Menu";
import { colors } from "@hotel-engine/styles";

export const DropdownWrapper = styled(Dropdown)`
  margin-left: 16px;
`;

export const RewardsDropdownMenu = styled(Menu)`
  width: 280px;
  padding: 0;
`;

export const RewardsDropdownHeaderItem = styled(MenuItem)`
  background-color: ${colors.retroBlue} !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: ${colors.white};
  cursor: default;
  display: flex;
  height: 87px;
  justify-content: space-between;
  padding: 24px 16px;
  &.silver {
    background-color: ${colors.frilledShark} !important;
  }
  &.gold {
    background-color: ${colors.butterfield} !important;
  }
  &.platinum {
    background-color: ${colors.chainGangGrey} !important;
  }
  > div {
    display: flex;
    flex-direction: column;
    span {
      &:first-of-type {
        font-size: 16px;
        font-weight: bold;
      }
      &:last-of-type {
        font-size: 12px;
      }
      &.right-align {
        text-align: right;
      }
    }
  }
`;

export const RewardsDropdownItem = styled(MenuItem)`
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 12px;
  color: ${colors.retroBlue};
  &:hover {
    background: ${colors.chefsHat};
  }
`;

export const RewardsProgressItem = styled(MenuItem)`
  border-bottom: 1px solid ${colors.azureHint};
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 16px 16px 0;
  cursor: default;
  &:hover {
    background: ${colors.white};
  }
  > div {
    margin-bottom: 16px;
  }
  .progress-title {
    font-size: 16px;
  }
`;

export const ProgressItem = styled.div`
  display: flex;
  flex-direction: column;
  span {
    &:last-of-type {
      color: ${colors.kiwiGrey};
    }
  }
  .sub-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const OrWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  div {
    width: 108px;
    height: 1px;
    border-top: 1px dashed ${colors.azureHint};
  }
`;

export const PerkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 31px;
    margin-right: 9px;
  }
  .support {
    color: $orange;
    font-size: 14px;
  }
  svg {
    font-size: 16px;
    margin-right: 8px;
  }
  .copy {
    font-size: 14px;
  }
  &:last-child {
    margin-bottom: 24px;
  }
`;
