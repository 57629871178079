import styled from "styled-components";

export const AdditionalInfoContainer = styled.div<{ $isPreview: boolean }>`
  margin-top: ${({ $isPreview, theme }) => (!$isPreview ? theme.spacing[40] : theme.spacing[24])};
  margin-bottom: ${({ $isPreview, theme }) => (!$isPreview ? theme.spacing[40] : theme.spacing[24])};
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing[20]};
  margin-bottom: ${({ theme }) => theme.spacing[24]};
`;

export const HotelFeeDescriptionContainer = styled.div`
  ul {
    padding: ${({ theme }) => theme.spacing[4]} ${({ theme }) => theme.spacing[24]};
    margin: 0;
  }
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[4]};
`;
