export const viewTypes = {
  SMALL_LIST: "small",
  LARGE_LIST: "default",
  MAP: "map",
  CALENDAR: "calendar",
} as const;

export type ViewType = (typeof viewTypes)[keyof typeof viewTypes];

export const apiTripTypesLegacy = {
  all: "all",
  active: "visiting",
  cancelled: "cancelled",
  past: "completed",
  upcoming: "booked",
} as const;

export const bookingTypesMap = {
  flight: {
    label: "Flight",
    icon: "plane",
  },
  lodging: {
    label: "Hotel",
    icon: "hotel",
  },
  car: {
    label: "Car",
    icon: "car",
  },
} as const;

const bookingTypes = Object.keys(bookingTypesMap).join("|");
export const VALID_BOOKING_TYPES = new RegExp(`^(${bookingTypes})$`, "i");
