import { Box } from "@hotelengine/atlas-web";
import { AirlineLogo } from "./AirlineLogo";
import * as Styled from "./styles";

export type DisplayAirlinesProps = {
  airlineCodes: string[];
  size?: number;
};

export const MultiAirlineLogo = ({ airlineCodes, size = 40 }: DisplayAirlinesProps) => {
  return (
    <Box display="flex" flexDirection="row">
      {airlineCodes.map((airlineCode, index) => (
        <Styled.MultiLogoContainer key={airlineCode} index={index}>
          <AirlineLogo iata={airlineCode} size={size} />
        </Styled.MultiLogoContainer>
      ))}
    </Box>
  );
};
