import { useEffect, useState } from "react";
import type { ICancelData } from "@hotel-engine/react-query/bookings/usePreviewCancellationQuery";
import { usePreviewCancellationQuery } from "@hotel-engine/react-query/bookings/usePreviewCancellationQuery";

export enum FlightRefundType {
  OriginalFormOfPayment = "Original form of payment",
  AirlineCredit = "Airline credit",
  Unknown = "unknown",
  Nonrefundable = "nonrefundable",
}

export const flightRefundTypeMap = {
  flight_original_form_of_payment: "Original form of payment",
  airline_credit: "Airline credit",
};

export const usePreviewFlightRefund = (tripId: number, bookingNumber: string) => {
  const [cancelData, setCancelData] = useState<ICancelData>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const flightCancelCalc = usePreviewCancellationQuery({
    id: tripId,
  });

  useEffect(() => {
    if (!cancelData && !isError && !isLoading) {
      try {
        setIsLoading(true);

        flightCancelCalc.mutate(
          { bookingNumber: bookingNumber, bookingType: "flight" },
          {
            onSuccess: (data) => {
              setCancelData(data);
              setIsLoading(false);
              setIsError(false);
            },
            onError: () => {
              setIsLoading(false);
              setIsError(true);
            },
          }
        );
      } catch {
        setIsLoading(false);
        setIsError(true);
      }
    }
  }, [bookingNumber, cancelData, flightCancelCalc, isError, isLoading]);

  const buildInfoObject = () => {
    if (!cancelData) {
      return null;
    } else {
      return {
        refundType:
          flightRefundTypeMap[
            cancelData.refundDetails.credits?.[0]?.type || "flight_original_form_of_payment"
          ],
        refundAmount: cancelData.refundDetails.credits?.[0]?.amount,
      };
    }
  };

  return { refundInfo: buildInfoObject(), isLoading, isError };
};
