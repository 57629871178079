import type { ButtonProps } from "@hotelengine/atlas-web";
import { Button } from "@hotelengine/atlas-web";
import styled from "styled-components";

export const ReviewButton = styled<React.FC<ButtonProps>>(Button)`
    width: 100%; 
    justify-content: space-between;
    padding-top: ${({ theme }) => theme.spacing[24]};
    padding-bottom: ${({ theme }) => theme.spacing[24]};
    border-radius: ${({ theme }) => theme.borderRadius.lg};
    border: ${({ theme }) => `${theme.borderWidth.normal} solid ${theme.colors.borderMinimal}`};


    &:hover {
        background-color: ${({ theme }) => theme.colors.backgroundPrimaryHover} !important;
    }
`;
