import { useCarModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { CollapsibleContent } from "@hotel-engine/app/ItineraryContent/shared/CollapsibleContent";
import { Box, Typography } from "@hotelengine/atlas-web";
import { ampli } from "ampli";
import { useAppSelector } from "store/hooks";

const CancellationPolicy = () => {
  const user = useAppSelector((state) => state.Auth.user);
  const { reservation } = useCarModificationsContext();

  return (
    <CollapsibleContent
      variant="sm"
      toggleTestID="cancellation-policy"
      toggleBtnLabel={<Typography variant="heading/lg">Cancellation policy</Typography>}
      onChangeState={() => {
        if (user) {
          ampli.clickCancellationPolicy({
            carBookingID: reservation.bookingNumber,
            userId: user.id,
          });
        }
      }}
    >
      <Box display="flex" flexDirection="column" gap={16} padding={16}>
        <Typography variant="body/md" color="foregroundPrimary">
          {reservation?.cancellationPolicy}
        </Typography>
      </Box>
    </CollapsibleContent>
  );
};

export default CancellationPolicy;
