import { useEffect, useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { DatePicker } from "antd";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { RangePickerValue } from "antd/lib/date-picker/interface";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";

import { Checkbox } from "@hotel-engine/common/Checkbox";
import { Form } from "@hotel-engine/common/Form";
import { routes } from "@hotel-engine/constants";
import { useBreakpoint } from "@hotel-engine/hooks";
import type { IUser } from "@hotel-engine/types/user";
import { ampli } from "ampli";

import { capitalize } from "../../../helpers";
import { useTripsContextLegacy } from "../../contextLegacy";
import { FilterPills } from "./FilterPills";
import { FiltersDropdown } from "./FiltersDropdown";
import { MobileTripsDropdown } from "./MobileTripsDropdown";
import * as Styled from "./styles";
import omitKeys from "./omitKeys";
import { Icon } from "@hotel-engine/common/Icon";
import useSearchForTrips from "./useSearchForTrips";

const { RangePicker } = DatePicker;

interface ITripsFiltersProps {
  isCalendarView?: boolean;
  isPreviewOpen?: boolean;
  user: IUser;
}

const TripsFilters = ({ isCalendarView = false, isPreviewOpen, user }: ITripsFiltersProps) => {
  const {
    state: tripsState,
    dispatch: tripsDispatch,
    searchValue,
    setSearchValue,
  } = useTripsContextLegacy();
  const filterStatuses = ["active", "upcoming", "past", "cancelled", "all"];
  const { filters, status, canViewOthersTrips, counts, mostExpensiveTrip } = tripsState;
  const hasUserSearched = !!searchValue;

  useEffect(() => {
    // this is to make sure that on-back that the proper
    // filter is focused, otherwise the previously selected could be
    // focused and two filters will be blue

    // the button's id is the status, as determined by antd. MenuItem won't accept an id that is useful, this
    // feels a little dangerous because the status seems like a relatively generic id,
    const filter = document.getElementById(status);
    filter?.focus();
  }, [status]);

  const isMobile = useBreakpoint("xxl", "max");
  const hideFiltersBreakpoint = isPreviewOpen ? "xxxl" : "xxl";
  const shouldHideFilters = useBreakpoint(hideFiltersBreakpoint, "max");

  const [dumbyDate, setDumbyDate] = useState(undefined);
  const navigate = useNavigate();

  const showCheckoutVerification =
    user?.business.checkoutVerificationEnabled && ["active", "all", "past"].includes(status);

  const handleFilterSelection = (e, value?: unknown | unknown[]) => {
    user &&
      ampli.clickCheckboxFilterInTrips({
        filterName: e.key,
        selectedFilter: !filters[e.key],
        userId: user.id,
      });

    const keys = {
      pendingModificationOnly: true,
      contractType: "multi_room", // this value needs to be snake_case
      refundableOnly: true,
      manual_bookings: true,
      unverified: true,
    };

    if (Array.isArray(value)) {
      tripsDispatch({
        type: "arrayFilter",
        key: e.key,
        values: value,
      });
    } else {
      tripsDispatch({
        type: "filter",
        filters: [{ key: e.key, value: keys[e.key] }],
      });
    }
  };

  const handleDateChange = (date: RangePickerValue) => {
    user &&
      ampli.clickDatesInTrips({
        endDate: String(date[1]),
        userId: user.id,
        startDate: String(date[0]),
      });
    if (!date[0] || !date[1]) {
      return;
    }
    setDumbyDate(undefined);

    tripsDispatch({
      type: "filter",
      filters: [
        { key: "checkInGt", value: date[0].startOf("day").toISOString() },
        { key: "checkInLt", value: date[1].endOf("day").toISOString() },
      ],
    });
  };

  const handleStatusClick = (nextStatus) => {
    user &&
      ampli.clickStateOfTrip({
        selectedOption: nextStatus,
        userId: user.id,
      });

    if (status !== nextStatus) {
      tripsDispatch({ type: "status", status: nextStatus });

      navigate(`${routes.trips.base}/${nextStatus}`);
    }
  };

  const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchValue(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchValue("");
  };

  const handleOnlyMyTrips = () => {
    user &&
      ampli.clickOnlyMyTripsCheckbox({
        selectedFilter: !filters.onlyMyTrips,
        userId: user.id,
      });

    tripsDispatch({
      type: "filter",
      filters: [{ key: "onlyMyTrips", value: true }],
    });
  };

  useSearchForTrips(searchValue);

  const showOnlyMyTripsDropdownCheckbox = isMobile && !!user && !!canViewOthersTrips;

  return (
    <Styled.TripsFiltersContainer style={{ marginBottom: 12 }}>
      {!!isMobile && (
        <Form>
          <Styled.MobileSearchInput
            aria-label="Search trips"
            placeholder="Search by name or location"
            suffix={
              <Styled.SearchButton
                htmlType="button"
                aria-label={hasUserSearched ? "clear" : "search"}
              >
                <Styled.SearchIcon icon={["far", hasUserSearched ? "times" : "search"]} />
              </Styled.SearchButton>
            }
            value={searchValue}
            onChange={handleOnSearchChange}
          />
        </Form>
      )}
      <Styled.TripsFilters>
        <Styled.ButtonGroup>
          {!!shouldHideFilters ? (
            <MobileTripsDropdown
              showOnlyMyTripsDropdownCheckbox={showOnlyMyTripsDropdownCheckbox}
              counts={counts}
              handleOnlyMyTrips={handleOnlyMyTrips}
              handleStatusClick={handleStatusClick}
              onlyMyTrips={filters.onlyMyTrips}
              status={status}
            />
          ) : (
            <>
              {filterStatuses.map((filterStatus) => {
                const displayName = filterStatus === "active" ? "Today" : capitalize(filterStatus);
                return (
                  <Styled.StatusButton
                    id={filterStatus}
                    name={filterStatus}
                    key={filterStatus}
                    type="ghost"
                    className={`${"status-button"} ${status === filterStatus ? "active" : ""}`}
                    onClick={() => handleStatusClick(filterStatus)}
                  >
                    {displayName} {`(${counts[filterStatus]})`}
                  </Styled.StatusButton>
                );
              })}
            </>
          )}
        </Styled.ButtonGroup>
        <Styled.ButtonGroup columnGap="12px">
          {!showOnlyMyTripsDropdownCheckbox && !!user && !!canViewOthersTrips && (
            <Checkbox
              onClick={handleOnlyMyTrips}
              checked={!!filters.onlyMyTrips}
              data-testid="only-my-trips-checkbox"
            >
              Only my trips
            </Checkbox>
          )}
          {!isMobile && !isCalendarView && (
            <>
              <Styled.RangePickerGlobalStyles />
              <RangePicker
                id="trips_datesPicker"
                placeholder={["Dates", ""]}
                style={{ width: 94 }}
                value={dumbyDate}
                onChange={handleDateChange}
                suffixIcon={<Icon icon={["far", "calendar"]} />}
              />
            </>
          )}
          <FiltersDropdown
            filters={filters}
            handleFilterSelection={handleFilterSelection}
            isCalendarView={isCalendarView}
            mostExpensiveTrip={mostExpensiveTrip}
            tripsDispatch={tripsDispatch}
            userRole={user.role}
            showCheckoutVerification={showCheckoutVerification}
          />
          {!isMobile && (
            <Form>
              <Styled.SearchInput
                id="searchTrips"
                aria-label="Search trips"
                placeholder="Search"
                suffix={
                  <Styled.SearchButton
                    htmlType="button"
                    onClick={handleClearSearch}
                    aria-label={hasUserSearched ? "clear" : "search"}
                    className="search-button"
                  >
                    <Styled.SearchIcon
                      icon={["far", hasUserSearched ? "times" : "search"]}
                      className="search-icon"
                    />
                  </Styled.SearchButton>
                }
                value={searchValue}
                onChange={handleOnSearchChange}
                className="search-input"
              />
            </Form>
          )}
        </Styled.ButtonGroup>
      </Styled.TripsFilters>
      <FilterPills
        filters={omitKeys(filters, "search")}
        handleFilterSelection={handleFilterSelection}
        setSearchValue={setSearchValue}
        tripsDispatch={tripsDispatch}
      />
    </Styled.TripsFiltersContainer>
  );
};

export default TripsFilters;
