import { Field, Form } from "formik";
import styled, { createGlobalStyle } from "styled-components";

import Button from "@hotel-engine/common/Button";
import { Icon } from "@hotel-engine/common/Icon";
import TextAreaField from "@hotel-engine/common/FormikFields/TextAreaField";
import { Modal } from "@hotel-engine/common/Modal";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors, zIndex } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

// required to display bottom sheet on top of the Complete Booking button
export const GlobalBottomSheetStyles = createGlobalStyle`
  [data-rsbs-overlay] {
    ${zIndex.modal}
  }
`;

export const BottomSheetContentContainer = styled.div`
  padding: 16px;
  margin-bottom: 16px;
`;

export const FeedbackModalGlobalStyles = createGlobalStyle`
  .ant-scrolling-effect {
    // antd modals disable scrolling on the body, these rules will allow scrolling
    overflow: initial !important;
    width: auto! important;
  }
  
  .ant-modal-wrap {
    pointer-events: none;
  }
`;

export const FeedbackModal = styled(Modal)`
  position: fixed;
  bottom: 16px;
  right: 16px;
  top: auto;
  padding: 0;

  .ant-modal-content {
    padding: 0;
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.lg};

    .ant-modal-body {
      ${flexbox({
        justifyContent: "center",
        alignItems: "flex-start",
        direction: "column",
      })};
      padding: 16px;
    }
  }
`;

export const ModalTitle = styled.h2`
  font-weight: ${({ theme }) => theme.legacy.fontWeight.bold};
  font-size: ${typographyMixin("large")};
  margin-bottom: 24px;
  color: ${colors.black};
`;

export const ModalForm = styled(Form)`
  width: 100%;
`;

export const RadioContainer = styled.div`
  ${flexbox({
    justifyContent: "center",
    alignItems: "flex-start",
    direction: "column",
  })};
  color: ${colors.black};
`;

export const MoodContainer = styled.div`
  ${flexbox({
    justifyContent: "space-between",
    alignItems: "start",
  })};
  width: 100%;
`;

export const MoodWrapper = styled.label`
  ${flexbox({
    justifyContent: "center",
    alignItems: "center",
    direction: "column",
  })};
  position: relative;
  width: 20%;
  cursor: pointer;
`;

export const MoodLabel = styled.p<{ selected: boolean }>`
  text-align: center;
  font-size: ${typographyMixin("body-medium")};
  color: ${(props) => (props.selected ? colors.black : "transparent")};
`;

export const MoodIcon = styled(Icon)<{
  selected: boolean;
  activeColor: string;
}>`
  margin: 8px 0;
  font-size: 36px;
  color: ${(props) => (props.selected ? props.activeColor : colors.slate[400])};
`;

export const MoodInput = styled(Field)`
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  width: 80px;
  opacity: 0;

  + svg,
  ~ p {
    transition: all 0.2s;
  }
`;

export const CommentContainer = styled.div`
  margin-top: 16px;
`;

export const CommentText = styled(TextAreaField)`
  border-color: ${colors.grey[300]};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.lg};
  padding: 12px;
  margin-top: 4px;
  color: ${colors.black};
`;

export const SubmitButton = styled(Button)`
  &.ant-btn-primary:disabled {
    background-color: ${colors.grey[200]};
    color: ${colors.kiwiGrey};
    border-color: transparent;
  }
  ${flexbox({
    justifyContent: "center",
    alignItems: "center",
  })};
  padding: 20px 42px;
  margin: 32px 0 0;
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.lg};
  font-size: ${typographyMixin("body-large")};

  ${mediaQuery("md", "max")(`float: none; width: 100%;`)};
`;

export const CancelButton = styled(Button)`
  ${flexbox({
    justifyContent: "center",
    alignItems: "center",
  })};
  padding: 20px 42px;
  margin: 10px 0;
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.lg};
  font-size: ${typographyMixin("body-large")};

  ${mediaQuery("md", "max")(`width: 100%`)}
`;

export const ButtonsContainer = styled.div`
  ${flexbox({
    justifyContent: "center",
    alignItems: "flex-end",
    direction: "column",
  })};
`;
