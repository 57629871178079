import { useEffect, useState } from "react";

const TIMEOUT_DURATION = 300;
let TIMEOUT_ID: NodeJS.Timeout;

function abortTimeout() {
  if (TIMEOUT_ID) {
    clearTimeout(TIMEOUT_ID);
  }
}

function useDeferredValue<S>(query: S) {
  const [deferredValue, setDeferredValue] = useState(query);

  useEffect(() => {
    abortTimeout();

    TIMEOUT_ID = setTimeout(() => {
      setDeferredValue(query);
    }, TIMEOUT_DURATION);

    return abortTimeout;
  }, [query]);

  return deferredValue;
}

export default useDeferredValue;
