import type { IPropertyLegacy } from "@hotel-engine/types/property";

import type { IAmenity } from "pages/Property/constants";
import { freebies, highlights } from "pages/Property/constants";

export const generateFeatureAmenities = (property: IPropertyLegacy) => {
  let featureAmenities: IAmenity[] = [];

  if (property && property.amenities.length) {
    const amenityIcons = [...freebies, ...highlights];
    featureAmenities = amenityIcons.filter((feature) =>
      feature.amenities.some((a) => property.amenities.map((b) => b.toUpperCase()).includes(a))
    );
  }
  return featureAmenities;
};
