import styled, { css } from "styled-components";

import { Typography } from "@hotelengine/atlas-web";
import { buttonReset, ellipsisText } from "@hotel-engine/styles";

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const List = styled.ul<{ isMobile: boolean }>`
  list-style: none;
  overflow: hidden;
  position: absolute;
  min-width: 460px;
  width: calc(100% + 16px);
  z-index: 20 /* disable theming (do not replicate) */;

  ${({ theme, isMobile }) => {
    return (
      !isMobile &&
      css`
      margin-top: 13px;
      box-shadow: 0 8px 24px 1px rgba(0, 0, 0, 0.12);
      border-radius: ${theme.borderRadius.sm};

      &:empty {
        border-top: none;
      }
    `
    );
  }}  
`;

export const NoResultsListItem = styled.li`
  color: ${({ theme }) => theme.colors.foregroundPrimary};
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  padding: ${({ theme }) =>
    `${theme.spacing[16]} ${theme.spacing[16]} ${theme.spacing[16]} ${theme.spacing[48]}`};
`;

export const LocationInput = styled.div<{
  focusStyling: boolean;
  hasError?: boolean;
  size?: "md" | "lg";
  $isFullScreenMobileWrapper?: boolean;
}>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.inputBackground};
  border: 1px solid ${({ theme, hasError }) =>
    hasError ? theme.colors.sentimentCriticalDefault : theme.colors.borderDefault};
  border-radius: ${({ theme }) => theme.borderRadius.nsActionMd};
  display: flex;
  flex-grow: 1;
  height: auto;
  height: ${({ size = "md" }) => (size === "md" ? "42px" : "48px")};
  max-width: 100%;
  position: relative;
  padding: 0 ${({ $isFullScreenMobileWrapper }) => ($isFullScreenMobileWrapper ? 16 : 0)}px;

  &:hover,
  &:focus-within {
    border: 1px solid ${({ theme, hasError }) =>
      hasError ? theme.colors.sentimentCriticalDefault : theme.colors.uiPrimary};
  }
`;

export const Clear = styled.span`
  cursor: pointer;
  position: absolute;
  right: 16px;
`;

export const CloseButton = styled.button`
  ${buttonReset};
  font-size: 18px;
`;

export const TextInputWrapper = styled.div`
  align-items: center;
  display: flex;
  padding: 0 16px;
  position: relative;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0 14px;
  }
`;

export const TextInput = styled.input`
  font-size: ${({ theme }) => theme.typography.desktop["body/md"]};
  background-color: ${({ theme }) => theme.colors.inputBackground};
  color: ${({ theme }) => theme.colors.foregroundPrimary};
  box-shadow: none;
  border: none;
  height: 38px;
  width: 100%;
  padding-left: 8px;
  ${ellipsisText};

  &::placeholder {
    color: ${({ theme }) => theme.colors.inputForegroundPlaceholder}
  }

  

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-left: 8px;
    padding-right: 16px;
  }
`;

export const MobileTextButton = styled.button.attrs(() => ({
  type: "button",
}))`
  ${buttonReset};
  width: 90%;
  padding-left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 95%;
  }
`;

export const MobileSearchText = styled.span`
  ${ellipsisText};
  color: ${({ theme }) => theme.colors.foregroundPrimary};
  height: 100%;
  font-size: ${({ theme }) => theme.typography.desktop["body/md"]};
  line-height: 38px;
  margin: 0 10px;
  text-overflow: clip;
`;

export const MobilePlaceholder = styled(MobileSearchText)`
  color: ${({ theme }) => theme.colors.inputForegroundPlaceholder};
  font-size: ${({ theme }) => theme.typography.desktop["body/md"]};
`;

export const ListItem = styled.li<{ $sectionTitle?: boolean }>`
  padding-top: ${({ $sectionTitle }) => ($sectionTitle ? "32px" : 0)};

  &:first-of-type {
    padding-top: 32px;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      padding-top: 16px;
    }
  }

  &:last-of-type {
    padding-bottom: 16px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  }
`;

export const ListItemTitle = styled(Typography).attrs(() => ({
  variant: "heading/sm",
  color: "foregroundTertiary",
}))`
  padding: 0 12px;
`;

/** Shared Results styles */
export const ListItemContent = styled.article<{
  $isSelected: boolean;
  $isHighlighted: boolean;
  $sectionTitle?: boolean;
}>`
  & > div {
    align-items: center;
    box-sizing: border-box;
    color: inherit;
    cursor: pointer;
    display: flex;
    height: 54px;
    padding: 0 12px;
    margin-top: ${({ $sectionTitle }) => ($sectionTitle ? "8px" : 0)};
    position: relative;
    transition: background-color 0.575s;
    width: 100%;

    ${({ $isHighlighted, theme }) =>
      $isHighlighted &&
      css`
        background-color: ${theme.colors.backgroundSecondary};
        transition: background-color 0.575;
    `}
  }
`;

export const LocationPickerOptionIcon = styled.div`
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  color: ${({ theme }) => theme.colors.foregroundPrimary};
  background-color: ${({ theme }) => theme.colors.backgroundStrong};
  display: flex;
  justify-content: center;
  margin-right: 16px;
  min-width: 40px;
  height: 40px;
  width: 40px;
`;

export const LocationPickerOptionValue = styled.div`
  width: 90%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 80%;
  }

  .description {
    ${ellipsisText};

    width: 100%;
    display: block;

    &--primary {
      color: ${({ theme }) => theme.colors.foregroundPrimary};
      margin-bottom: 4px;
    }

    &--primary-ff {
      color: ${({ theme }) => theme.colors.foregroundPrimary};
      font-weight: "bold";
      margin-bottom: 4px;

      .matched {
        font-weight: "normal";
        white-space: pre;
      }
    }

    &--secondary {
      color: ${({ theme }) => theme.colors.foregroundSecondary};
    }
  }
`;
