import { useCallback, useEffect, useState } from "react";

// import { datadogRum } from "@datadog/browser-rum";
import moment from "moment";
import { stringify } from "qs";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation, useParams } from "@hotel-engine/lib/react-router-dom";

import { SearchTypeOptions, routes } from "@hotel-engine/constants";
import { useSearchParams } from "@hotel-engine/hooks";
import { useCreateContractRate } from "@hotel-engine/react-query/contractRate/useCreateContractRate";
import { useRoomsQuery } from "@hotel-engine/react-query/room/useRoomsQuery";
import { useSearchMutation } from "@hotel-engine/react-query/searches/useSearchMutation";
import { useReservationQuery } from "@hotel-engine/react-query/reservation/useReservationQuery";
import { useManageTripExtensionRequests } from "@hotel-engine/react-query/tripExtensionRequests/useManageTripExtensionRequests";
import type { IReservationRoom } from "@hotel-engine/types/booking";
import type { IContractRate } from "@hotel-engine/types/contractRate";
import type { IRoom, IRoomRate } from "@hotel-engine/types/room";
import type { ITripExtensionRequest } from "@hotel-engine/types/tripExtensionRequests";
import { formatPropertyLocation } from "@hotel-engine/utilities";
import { getBusinessToken } from "@hotel-engine/utilities/auth";
import { Unsafe } from "@hotel-engine/data";
import { Modal } from "@hotel-engine/common/Modal";

import { buildRoomsCreateParams } from "pages/Property/components/ChooseARoom/helpers";

import CalendarModal from "./components/CalendarModal";
import ErrorModal from "./components/ErrorModal";
import LoadingModal from "./components/LoadingModal";
import PropertySoldOutModal from "./components/PropertySoldOutModal";
import RoomPriceIncreaseModal from "./components/RoomPriceIncreaseModal";
import { ModalSteps } from "./constants";
import useIsContractExtendable from "@hotel-engine/hooks/useIsContractExtendable";
import type { ISearchQueryParams } from "@hotel-engine/types/search";
import { ampli } from "ampli";
export interface ILocationState {
  startTripExtensionFlow: boolean;
  checkoutDate?: string;
}

interface ITripExtensionsParams {
  newCheckout?: string;
  selectedRooms?: IReservationRoom[];
  setIsSearching?: (val: boolean) => void;
  requestedModalStep?: number;
}

const TripExtensions = ({
  newCheckout,
  selectedRooms,
  setIsSearching,
  requestedModalStep,
}: ITripExtensionsParams) => {
  const { contract_number, extendedContractId } = useSearchParams<{
    contract_number: string;
    extendedContractId: string;
  }>();
  const initialModalStep = !!requestedModalStep
    ? requestedModalStep
    : extendedContractId
      ? ModalSteps.Calendar
      : ModalSteps.Invisible;
  const [modalStep, setModalStep] = useState(initialModalStep);
  const { reservationId } = useParams<{ reservationId: string }>();
  const contractNumber = contract_number || extendedContractId || String(reservationId);
  const altToken: string | undefined = extendedContractId && getBusinessToken();
  const { data: reservation, isSuccess } = useReservationQuery(
    {
      id: contractNumber,
    },
    undefined,
    altToken
  );
  const { state: locationState } = useLocation<ILocationState>();
  const [samePropertyFound, setSamePropertyFound] = useState(false);
  const [sameRoomFound, setSameRoomFound] = useState(false);
  const { mutation: searchQuery } = useSearchMutation();
  const manageTripExtensionRequests = useManageTripExtensionRequests();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [roomSearchParams, setRoomSearchParams] = useState<Omit<
    ISearchQueryParams,
    "locationId"
  > | null>(null);
  const [searchId, setSearchId] = useState("");
  const [contractRate, setContractRate] = useState<IContractRate | null>(null);
  const [selectedRoomRate, setSelectedRoomRate] = useState<IRoomRate | null>(null);
  const [sameRoom, setSameRoom] = useState<IRoom | null>(null);
  const [priceIncreaseRoomRates, setPriceIncreaseRoomRates] = useState({
    chosenRoomRate: 0,
    originalRoomRate: 0,
  });
  const [extensionRoomSavings, setExtensionRoomSavings] = useState("");
  const [acceptPriceIncrease, setAcceptPriceIncrease] = useState(false);
  const createContract = useCreateContractRate();
  const {
    extendableRoomCount: extendableCount,
    extendableRooms,
    extendableRoomsGuestCount,
    isGroupContract,
  } = useIsContractExtendable();
  const extendableRoomCount = selectedRooms?.length || extendableCount;
  const updatedGuestCount =
    (selectedRooms?.length &&
      selectedRooms?.map((room) => room.occupants.length).reduce((a, b) => a + b)) ||
    extendableRoomsGuestCount;
  const { refetch: fetchRooms } = useRoomsQuery(
    buildRoomsCreateParams(
      {
        checkIn: moment(roomSearchParams?.checkIn, "MM-DD-YYYY"),
        checkOut: moment(roomSearchParams?.checkOut, "MM-DD-YYYY"),
        searchId: Number(searchId),
        guestCount: roomSearchParams?.guestCount,
        roomCount: extendableRoomCount,
        extendedContractId: reservation?.contractNumber,
        defaultFocus: "startDate",
      },
      Number(reservation?.propertyId || "")
    ),
    { enabled: false }
  );

  // Run search if submitting from ExtendTrips page
  useEffect(() => {
    if (reservationId && newCheckout) {
      handleDateChoice(newCheckout).then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationId, newCheckout]);

  /** Closes modals and cancels search */
  const handleCancel = () => {
    queryClient.cancelMutations().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    setModalStep(ModalSteps.Invisible);
    if (setIsSearching) {
      setIsSearching(false);
    }

    // Send Amplitude event for tracking conversion on multi-date Trip Extension dropouts
    ampli.clickCancelAfterSubmittingTripExtension({
      bookingId: contractNumber,
      propertyId: reservation?.propertyId,
      userId: reservation?.userId,
    });
  };

  // Removes locationState on refresh of the page
  globalThis.history.replaceState({}, "");

  // Opens the modal on redirect from Trips Preview Panel
  useEffect(() => {
    if (locationState?.startTripExtensionFlow) {
      setModalStep(ModalSteps.Calendar);
    }
  }, [locationState]);

  const matchRooms = useCallback(async () => {
    const { data, isError: fetchRoomsError } = await fetchRooms();

    if (fetchRoomsError) {
      setModalStep(ModalSteps.Error);
      return;
    }

    const sameRoomType = data?.rooms?.find((room) => {
      return (
        room.title === reservation?.roomTitle || room.description === reservation?.roomDescription
      );
    });
    const isMatch = sameRoomType && reservation?.nightlyRate;

    if (isMatch) {
      setSameRoomFound(true);
      setSameRoom(sameRoomType);
      const chosenRoomRate = matchRefundPolicy(sameRoomType.rates, Boolean(reservation?.cancelBy));
      setSelectedRoomRate(chosenRoomRate);

      try {
        const newContractRate = (await createContract.mutateAsync({
          roomRateId: chosenRoomRate.id,
          extendedContractId: reservation?.contractNumber,
        })) as IContractRate;
        setContractRate(newContractRate);

        const roomRateDifference = chosenRoomRate.rate - reservation.nightlyRate;
        if (roomRateDifference >= 5) {
          setPriceIncreaseRoomRates({
            chosenRoomRate: chosenRoomRate.rate,
            originalRoomRate: reservation.nightlyRate,
          });
          setModalStep(ModalSteps.PriceIncrease);
        } else {
          const totalSavings = (reservation.nightlyRate - chosenRoomRate.rate).toFixed(2);
          setExtensionRoomSavings(totalSavings);
          setModalStep(ModalSteps.FauxDesign);
          setTimeout(() => setAcceptPriceIncrease(true), 1500);
        }
      } catch (err) {
        if (err instanceof Error) {
          setModalStep(ModalSteps.Error);
        }
      }
    } else {
      setModalStep(ModalSteps.FauxDesign);
      setTimeout(() => goToProperty(), 1500);
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservation, roomSearchParams, searchId]);

  // Design wants faux timeout for 1.5 seconds to show secondary modal before redirect
  useEffect(() => {
    if (samePropertyFound && searchId && modalStep > ModalSteps.Calendar) {
      matchRooms().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    }
  }, [matchRooms, modalStep, samePropertyFound, searchId]);

  const handleDateChoice = async (checkOut: string) => {
    if (isSuccess) {
      setSamePropertyFound(false);
      setSameRoomFound(false);
      try {
        // If booking a multi-room trip extension, create a trip extension request
        if ((isGroupContract && extendableRoomCount > 0) || reservation.newFormatSingleContract) {
          const tripExtensionRequests: ITripExtensionRequest[] = [];
          const roomsToBeExtended = selectedRooms || extendableRooms;

          roomsToBeExtended?.map((room) => {
            const originalCheckout = room.originalCheckOut || room.checkOut;
            const isSameExtensionDates = tripExtensionRequests.find(
              (item) => item.checkIn === originalCheckout && item.checkOut === checkOut
            );
            if (isSameExtensionDates) {
              isSameExtensionDates.contractIds.push(room.contractNumber);
            } else {
              tripExtensionRequests.push({
                checkIn: originalCheckout,
                checkOut: checkOut,
                contractIds: [room.contractNumber],
              });
            }
          });

          await manageTripExtensionRequests.mutateAsync(
            {
              groupContractId: contractNumber,
              tripExtensionRequests,
            },
            {
              onSuccess: (response) => {
                // Send Amplitude event for tracking conversion on multi-date Trip Extension searches
                ampli.clickSubmitRequestForTripExtension({
                  bookingId: contract_number || extendedContractId || String(reservationId),
                  checkOut: reservation?.checkOut,
                  newCheckOut: newCheckout,
                  propertyId: reservation?.propertyId,
                  roomCount: selectedRooms?.length,
                  tripExtensionRequestId: response.tripExtensionRequestIds[0],
                  userId: reservation?.userId,
                });
              },
            }
          );
        }
        setModalStep(ModalSteps.InitialSearch);

        const res = await searchQuery.mutateAsync({
          checkIn: reservation?.checkOut,
          checkOut,
          guestCount: updatedGuestCount || reservation?.guestCount,
          latitude: reservation?.propertyLatitude,
          longitude: reservation?.propertyLongitude,
          propertyIds: [reservation?.propertyId],
          radius: 1,
          roomCount: extendableRoomCount,
          salesChannel: "website",
          searchType: SearchTypeOptions.TripExtension,
        });
        const isPropertyAvailable = res?.results?.some((property) => {
          //return true if the same property is NOT sold out
          return property.propertyId === reservation?.propertyId && !property.isSoldOut;
        });
        setRoomSearchParams({
          checkIn: moment(reservation?.checkOut).format("MM-DD-YYYY"),
          checkOut: moment(checkOut).format("MM-DD-YYYY"),
          guestCount: updatedGuestCount || reservation?.guestCount,
          lat: String(res.latitude),
          location: formatPropertyLocation({
            propertyCountry: reservation.propertyCountry,
            propertyState: reservation.propertyState,
            propertyCity: reservation.propertyCity,
          }),
          lon: String(res.longitude),
          retrySearchWithExpandedRadius: true,
          roomCount: extendableRoomCount,
          extendedContractId: reservation?.contractNumber,
        });
        setSearchId(String(res.id));

        // const hotelIds = res.results.map((property) => property.propertyId);

        if (isPropertyAvailable) {
          setSamePropertyFound(true);
          // datadogRum.addAction("tripExtension", {
          //   extensionStatus: "originalPropertyAvailable",
          //   parentContractNumber: reservation?.contractNumber,
          //   hotelIds: hotelIds,
          // });
        } else {
          // datadogRum.addAction("tripExtension", {
          //   extensionStatus: hotelIds.length
          //     ? "originalPropertyUnavailable"
          //     : "noPropertiesAvailable",
          //   parentContractNumber: reservation?.contractNumber,
          //   hotelIds: hotelIds,
          // });
          setModalStep(ModalSteps.PropertySoldOut);
        }
      } catch (err) {
        if (err instanceof Error) {
          setModalStep(ModalSteps.Error);
        }
      }
    }
  };

  /** Handles Checkout page navigation */
  const goToCheckout = () => {
    setModalStep(ModalSteps.Invisible);
    navigate(
      {
        pathname: `${routes.properties}/${reservation?.propertyId}/book`,
        search: `?${stringify({
          ...roomSearchParams,
          contractRate: contractRate?.id,
          extensionRoomSavings,
          roomRateId: selectedRoomRate?.id,
          s: selectedRoomRate?.searchId,
        })}`,
      },
      {
        state: {
          room: sameRoom,
          contractRate,
        },
      }
    );
  };

  // Routes to the Checkout page once all search and state params are updated
  useEffect(() => {
    if (acceptPriceIncrease) {
      goToCheckout();
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptPriceIncrease]);

  /** Handles Property page navigation to pick a new room */
  const goToProperty = () => {
    setModalStep(ModalSteps.Invisible);
    navigate({
      pathname: `${routes.properties}/${reservation?.propertyId}`,
      search: `?${stringify({ ...roomSearchParams, s: searchId })}`,
    });
  };

  /** Finds a room rate that either matches or comes close to the original contract's refund policy */
  const matchRefundPolicy = (roomRates: IRoomRate[], isRefundable: boolean) => {
    // Prioritize finding an exact match
    const sameRefundPolicy = roomRates.find(
      (roomRate) =>
        (isRefundable ? roomRate.cancelBy : !roomRate.cancelBy) &&
        roomRate.cancellationPolicy === reservation?.cancellationPolicy
    );
    if (sameRefundPolicy) {
      return { ...sameRefundPolicy, refundPolicyMatch: "exact" };
    } else {
      // Otherwise, filter room rates with similar refund policies
      const similarRefundPolicyRoomRates = roomRates.filter((roomRate) =>
        isRefundable ? roomRate.cancelBy : !roomRate.cancelBy
      );
      // Either find the cheapest room rate for the original refund policy or for all policies
      const roomRatesToBeReduced = similarRefundPolicyRoomRates.length
        ? similarRefundPolicyRoomRates
        : roomRates;

      const cheapestRoomRate = roomRatesToBeReduced.reduce((prev, curr) =>
        prev.rate < curr.rate ? prev : curr
      );
      return {
        ...(cheapestRoomRate as IRoomRate),
        refundPolicyMatch: similarRefundPolicyRoomRates.length ? "similar" : "different",
      };
    }
  };

  const renderModalStep = () => {
    switch (modalStep) {
      case ModalSteps.Calendar:
        return (
          <CalendarModal
            handleDateChoice={handleDateChoice}
            preFilledCheckoutDate={locationState?.checkoutDate}
          />
        );
      case ModalSteps.InitialSearch:
      case ModalSteps.FauxDesign:
        return (
          <LoadingModal
            handleCancel={handleCancel}
            modalStep={modalStep}
            resultFound={Boolean(modalStep === ModalSteps.FauxDesign)}
            sameRoomFound={sameRoomFound}
          />
        );
      case ModalSteps.PropertySoldOut:
        return (
          <PropertySoldOutModal
            handleCancel={handleCancel}
            searchParams={
              roomSearchParams
                ? {
                    ...roomSearchParams,
                    retrySearchWithExpandedRadius: true,
                  }
                : null
            }
          />
        );
      case ModalSteps.PriceIncrease:
        return (
          <RoomPriceIncreaseModal
            goToCheckout={goToCheckout}
            goToProperty={goToProperty}
            roomRates={priceIncreaseRoomRates}
          />
        );
      case ModalSteps.Error:
        return <ErrorModal handleCancel={handleCancel} />;
      default:
        return null;
    }
  };

  return (
    <Modal visible={modalStep > ModalSteps.Invisible} onCancel={handleCancel} footer={null}>
      {renderModalStep()}
    </Modal>
  );
};

export default TripExtensions;
