import styled, { css } from "styled-components";

import { Menu } from "@hotel-engine/common/Menu";
import { colors } from "@hotel-engine/styles";
import Typography from "@hotel-engine/common/Typography";

export const navHeightDesktop = 80;
export const navHeightMobile = 56;

interface IMobile {
  isMobileDevice: boolean;
}

interface IIsHidden {
  isHidden: boolean;
  isHeaderDividerHidden: boolean;
}

interface INavWrapper {
  isDashboardRebrandOn: boolean;
}

type IWrapperProps = IMobile & IIsHidden;

export const Wrapper = styled.div<IWrapperProps>(
  ({ isMobileDevice, isHidden, isHeaderDividerHidden, theme }) => [
    css`
      display: flex;
      width: 100%;
      max-height: 80px;
      padding: 0px 24px;
      padding-left: ${theme.spacing[64]};
      opacity: ${isHidden ? 0 : 1};
      border-bottom: ${isHidden || isHeaderDividerHidden ? "none" : `1px solid ${colors.azureHint}`};
      transition: all 0.6s ease;

      .left {
        float: left;
        display: flex;
        flex: 1;

        .menu {
          flex-direction: row;
          display: flex;
          width: 100%;
          max-height: 80px;
        }

        a {
          align-self: center;
        }
      }

      .right {
        align-items: center;
        display: flex;
        justify-content: flex-end;
      }
    `,
    isMobileDevice &&
      css`
        padding: ${isHidden ? 0 : "16px"};
        height: ${isHidden ? 0 : navHeightMobile}px;

        .right {
          margin-left: 40px;
        }
      `,

    !isMobileDevice &&
      css`
        height: ${isHidden ? 0 : navHeightDesktop}px;
      `,
  ]
);

export const Logo = styled.img<IMobile>(({ isMobileDevice }) => [
  css`
    max-height: 40px;
    align-self: center;
    padding-right: 56px;
  `,
  isMobileDevice &&
    css`
      padding-right: 16px;
      height: 24px;
    `,
]);

export const HELogo = styled.div<IMobile>(({ isMobileDevice }) => [
  css`
    display: flex;
    height: 100%;
    align-items: center;
    font-weight: 500;
    color: ${colors.black};

    p {
      border-left: 1px solid ${colors.azureHint};
      padding-left: 16px;
      padding-right: 56px;
      margin-left: 16px;
      margin-bottom: 0px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    img {
      height: 32px;
    }
  `,
  isMobileDevice &&
    css`
      p {
        border-left: 0px;
        padding-left: 16px;
        padding-right: 0px;
        margin-left: 0px;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 1;
      }

      .logo {
        height: 24px;
      }
    `,
]);

export const NavWrapper = styled(Menu)<INavWrapper>`
  display: flex;
  justify-content: ${({ isDashboardRebrandOn }) => (isDashboardRebrandOn ? "center" : "flex-start")};
  flex-grow: 1;
  min-width: 55%;
  padding-top: 32px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${colors.black};
  border-bottom: unset;

  .ant-menu-submenu-title {
    height: 100%;
    color: ${colors.black};
  }
  .ant-menu-overflowed-submenu {
    color: ${colors.black};
    border-bottom: 1px solid ${colors.azureHint};
    &:hover {
      border-bottom: 2px solid ${colors.black};
    }
  }
`;

export const NavLink = styled(Menu.Item)`
  padding-bottom: 32px;
  color: ${colors.black};

  &.ant-menu-item > a,
  &.ant-menu-item > a:active {
    color: currentColor;
  }

  &.ant-menu-item-selected,
  &.ant-menu-item:hover,
  &.ant-menu-item:focus-within {
    color: currentColor;
    border-bottom: 2px solid currentColor;

    > a {
      color: currentColor;
    }
  }

  .ant-menu-vertical &:hover {
    background-color: ${colors.placeboBlue};
    border-bottom: 0px !important;
  }
`;

export const LinkContainer = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 4px;
`;

export const ChipContainer = styled.span`
  position: relative;
  top: -4px;
`;

export const DemoBadge = styled.div`
  color: ${colors.white};
  background-color: ${colors.black};
  align-items: center;
  padding: 8px 8px;
  border-radius: 100px /* disable theming (do not replicate) */;
  font-size: 14px;
`;

export const overflowedIndicator = styled.div`
  span {
    color: ${colors.black};
    padding-right: 4px;
  }
  &:hover {
    color: ${colors.black};
  }
`;

export const GroupsMenu = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

export const StyledTitle = styled(Typography)`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${colors.black};
  margin-right: 6px;
`;

export const DropdownMenu = styled(Menu)`
  width: 100px;
  .ant-dropdown-menu-item {
    padding: 12px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: ${colors.black};
    text-align: center;
    & > svg {
      width: 16px !important;
      color: ${colors.grey[700]};
    }
  }
`;
