import { useEffect } from "react";

import { useInbox } from "@trycourier/react-inbox";

import type { IUser } from "@hotel-engine/types/user";

import NotificationInbox from "./NotificationInbox";
import * as Styled from "./styles";
import { ampli } from "ampli";
import { Popover, PopoverContent, PopoverTrigger } from "@hotelengine/atlas-web";

export interface IHeaderNotificationsProps {
  hasUnreadNotifications: boolean;
  setShowUnreadNotifications: (setUnread: boolean) => void;
  accountType: IUser["accountType"];
}

const HeaderNotifications = ({
  hasUnreadNotifications,
  setShowUnreadNotifications,
  accountType,
}: IHeaderNotificationsProps) => {
  const { getUnreadMessageCount, unreadMessageCount, ...rest } = useInbox();
  const hasUnreadMessages = unreadMessageCount > 0;

  useEffect(() => {
    getUnreadMessageCount();
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType]);

  useEffect(() => {
    setShowUnreadNotifications(hasUnreadMessages);
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadMessageCount, accountType]);

  const trackNotificationsOpen = (visible: boolean) => {
    if (visible) {
      ampli.viewNotifications();
    }
  };

  return (
    <Styled.Wrapper>
      {!!hasUnreadNotifications && <Styled.Indicator isTrigger />}
      <Popover onOpenChange={trackNotificationsOpen}>
        <PopoverTrigger aria-label="notifications-menu">
          <Styled.HeaderIconWrap variant="plain" icon="bell--solid" />
        </PopoverTrigger>
        <PopoverContent align="end" style={{ maxWidth: "unset" }}>
          <NotificationInbox
            accountType={accountType}
            hasUnreadMessages={hasUnreadMessages}
            {...rest}
          />
        </PopoverContent>
      </Popover>
    </Styled.Wrapper>
  );
};

export default HeaderNotifications;
