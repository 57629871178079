import { useTranslation } from "react-i18next";

export const useAcrissInfo = () => {
  const { t } = useTranslation("cars");

  return {
    getAcrissCategory: (acrissCode: string) => t(`acriss.category.${acrissCode}`, ""),
    getAcrissCategoryType: (acrissCategoryCode: string, acrissTypeCode: string) => {
      const acrissCategory = t(`acriss.category.${acrissCategoryCode}`, "");
      const acrissType = t(`acriss.type.${acrissTypeCode}`, "car");

      return [acrissCategory, acrissType].join(" ");
    },
  };
};
