import { Popover, PopoverContent, PopoverTrigger, Box } from "@hotelengine/atlas-web";
import * as Styled from "./styles";
import DropdownContent from "./DropdownContent";
import { useTripsContext } from "pages/Trips/context";

export interface IFiltersDropdownProps {
  isCalendarView: boolean;
  isMapView: boolean;
}

const FiltersDropdown = ({ isCalendarView, isMapView }: IFiltersDropdownProps) => {
  const { state: tripsState } = useTripsContext();

  return (
    <Popover>
      <PopoverTrigger>
        <Styled.Button
          variant="filled"
          size="sm"
          leadingIcon="sliders"
          color="everlight"
          id="trips_filterDropdown"
          disabled={!!tripsState.error}
        >
          Filters
        </Styled.Button>
      </PopoverTrigger>
      <PopoverContent align="end" style={{ maxWidth: "unset" }}>
        <Box style={{ maxHeight: 500, overflow: "auto" }}>
          <DropdownContent isCalendarView={isCalendarView} isMapView={isMapView} />
        </Box>
      </PopoverContent>
    </Popover>
  );
};

export default FiltersDropdown;
