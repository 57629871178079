import styled from "styled-components";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const WalkScoreWrapper = styled.aside`

  ${flexbox({
    alignItems: "center",
    justifyContent: "flex-start",
  })};

  .circular-chart {
    height: 40px;
    width: 40px;
    margin-right: ${({ theme }) => theme.spacing[8]};

    .label {
      fill: ${({ theme }) => theme.colors.foregroundPrimary};
      font-weight: bold;
      letter-spacing: -1px;
    }

    .circle-bg {
      stroke: ${({ theme }) => theme.colors.backgroundTertiary};
      stroke-width: 2.2;
    }

    .percentage {
      stroke: ${({ theme }) => theme.colors.foregroundPrimary};
      stroke-width: 1.8;
      stroke-linecap: round;
    }
  }
`;

export const WalkScoreContent = styled.div`
  ${flexbox({
    direction: "column",
    justifyContent: "flex-start",
  })};
`;
