import * as yup from "yup";

import type { IconProps } from "@hotelengine/atlas-web";
import { tokens } from "@hotelengine/atlas-web/dist/theme/tokens/defaultRefresh";
import type { IGooglePrediction } from "@hotel-engine/types/search";
import type { ILocationRecord } from "@hotel-engine/types/locations";
import type { IWorksite } from "@hotel-engine/types/worksite";
import { SearchFormSchema } from "@hotel-engine/utilities";
import config from "config";
import type { CSSProperties } from "styled-components";

import { GUEST_COUNT, ROOM_COUNT } from "./constants";

const { maxNights } = config;

export const yupValidate = () => {
  return yup
    .object()
    .shape({
      selectedLocation: SearchFormSchema.shape.selectedLocation,
      checkIn: SearchFormSchema.shape.checkIn,
      checkOut: SearchFormSchema.shape.checkOut,
      roomCount: SearchFormSchema.shape.roomCount,
      guestCount: SearchFormSchema.shape.guestCount,
    })
    .test(SearchFormSchema.rules.checkInBeforeCheckout())
    .test(SearchFormSchema.rules.maxNights(maxNights))
    .test(SearchFormSchema.rules.guestCountCantBeMoreThanRooms())
    .test(SearchFormSchema.rules.guestCountCantBeMoreThanTwiceTheRoomCount());
};

export const updateRooms = (
  value: number,
  guestCount: number,
  setFieldValue: (key: string, value: number) => void
) => {
  setFieldValue(ROOM_COUNT, value);
  if (value > guestCount) {
    setFieldValue(GUEST_COUNT, value);
  } else if (guestCount / value > 2) {
    setFieldValue(GUEST_COUNT, value * 2);
  }
};

export const getIconType = (location: ILocationRecord | IGooglePrediction | IWorksite) => {
  let { types } = location as ILocationRecord;
  if ((location as IWorksite).name) types = ["worksite"];

  if (!types) {
    return "map-marker-alt";
  }

  if (types.includes("worksite")) {
    return "star";
  } else if (types.includes("property")) {
    return "bed";
  } else if (types.includes("airport")) {
    return "plane";
  }

  return "map-marker-alt";
};

export const getAutocompleteIconInfo = (types: string[]) => {
  // find the first type in the array that we have a specific icon for (non default)
  const locationType = types?.find((locType) => !getIconInfoFromLocationType(locType).default);

  // return the icon information for that type (or general icon information if type is undefined)
  return getIconInfoFromLocationType(locationType);
};

export const getIconInfoFromLocationType = (
  locationType: string | undefined
): {
  iconName: IconProps["name"];
  iconStyles: CSSProperties;
  default?: boolean;
} => {
  switch (locationType) {
    case "worksite":
      return {
        iconName: "star--solid",
        iconStyles: {
          backgroundColor: tokens.colors.accentYellowLighter,
          color: tokens.colors.accentYellow,
        },
      };
    case "favorite":
      return {
        iconName: "heart--solid",
        iconStyles: {
          backgroundColor: tokens.colors.accentRedLighter,
          color: tokens.colors.accentRed,
        },
      };
    case "preferred":
      return {
        iconName: "thumbs-up--solid",
        iconStyles: {
          backgroundColor: tokens.colors.accentBlueLighter,
          color: tokens.colors.accentBlue,
        },
      };
    case "property":
      return {
        iconName: "bed--solid",
        iconStyles: {},
      };
    case "hospital":
      return {
        iconName: "suitcase-medical--solid",
        iconStyles: {},
      };
    case "airport":
      return {
        iconName: "plane--solid",
        iconStyles: {},
      };
    default:
      return {
        iconName: "location-dot--solid",
        iconStyles: {},
        default: true,
      };
  }
};
