import styled from "styled-components";

import { buttonReset, colors } from "@hotel-engine/styles";
import { equalDimensions, flexbox } from "@hotel-engine/styles/helpers/mixins";

export const FavoriteToggle = styled.button<{ $isFavorited?: boolean }>`
  ${buttonReset};
  ${equalDimensions(24)}
  color: ${({ $isFavorited }) => ($isFavorited ? colors.khmerCurry : colors.grey[500])};
  transition: 0.2s ease color;
  &:hover {
    color: ${({ $isFavorited }) => ($isFavorited ? colors.fadedKhmerCurry : colors.disabledBlack)};
  }
  svg {
    ${equalDimensions(24)}
  }

  &.itinerary-panel-header {
    color: ${({ $isFavorited }) => ($isFavorited ? colors.khmerCurry : "transparent")};
    width: ${({ theme }) => theme.legacy.space(4)};

    ${flexbox({
      alignItems: "center",
      justifyContent: "center",
    })}

    svg {
      ${equalDimensions(16)};
      path {
        stroke: ${({ $isFavorited }) => ($isFavorited ? colors.khmerCurry : colors.grey[800])};
      }
    }
  }
`;
