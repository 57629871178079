import { ampli } from "ampli";
import { useState } from "react";

import { Button } from "@hotelengine/atlas-web";
import { useFlightModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useAppSelector } from "store/hooks";

import * as Styled from "./styles";
import ManageFlightModal from "./ManageFlightModal";

const ManageFlight = () => {
  const user = useAppSelector((state) => state.Auth?.user);
  const {
    isPreview,
    reservation,
    state: { modificationView },
  } = useFlightModificationsContext();
  const [isManageFlightOpen, setIsManageFlightOpen] = useState(false);

  const openManageFlightModal = () => {
    setIsManageFlightOpen(true);

    ampli.clickManageTrip({
      userId: user?.id,
      flightBookingID: reservation?.bookingNumber,
    });
  };

  const closeManageFlightModal = () => {
    setIsManageFlightOpen(false);
  };

  return (
    <>
      {!modificationView && (
        <>
          <Styled.ManageFlightContainer $isPreview={isPreview}>
            <Button style={{ width: "100%" }} size="xl" onClick={openManageFlightModal}>
              Manage trip
            </Button>
          </Styled.ManageFlightContainer>
          {!!isManageFlightOpen && <ManageFlightModal onCancel={closeManageFlightModal} />}
        </>
      )}
    </>
  );
};

export default ManageFlight;
