import type { IOption } from "@hotel-engine/types/search";

import * as Styled from "./styles";

const SelectedProperties = ({ options, showSelectedProperties }: ISelectedPropertiesProps) => {
  if (!showSelectedProperties) return null;

  return (
    <Styled.Container to="/share-options">
      <div className="count-wrapper">
        <span className="text">Selected Hotels</span>
        <div className="count">
          <span>{options.length}</span>
        </div>
      </div>
      <Styled.Tooltip className="select-tooltip">
        <p>Review your selected hotels here</p>
      </Styled.Tooltip>
    </Styled.Container>
  );
};

export default SelectedProperties;

//
// ─── INTERFACES ─────────────────────────────────────────────────────────────────
//

export interface ISelectedPropertiesProps {
  options: IOption[];
  showSelectedProperties: boolean;
}
