import config from "config";
import { useAppSelector } from "store/hooks";
import SupportChatLink from "@hotel-engine/app/SupportChatLink";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { useExperiment } from "@hotel-engine/react-query/amplitude/useExperimentQuery";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { Typography } from "@hotelengine/atlas-web";
import * as Styled from "./styles";
import { useTypographyScaling } from "../../../hooks/useTypographyScaling";
import { sanitizeHtml } from "@hotel-engine/utilities";
import { useFlex } from "@hotel-engine/hooks";
import { useFlexCutoffTime } from "@hotel-engine/hooks/useFlexCutoffTime";

export interface IAdditionalInfoProps {
  supportPhone: string | null;
  isMobile?: boolean;
}

const AdditionalInfo = ({ supportPhone }: IAdditionalInfoProps) => {
  const { reservation, isPreview } = useModificationsContext();

  const currencyCode = useAppSelector((state) => state.Auth.user?.business.currencyCode) || "USD";
  const { formatedFlexCutOffTime } = useFlexCutoffTime();
  const { flexOriginalPaymentCopy, flexTravelCreditCopy } = useFlex(formatedFlexCutOffTime);
  const supportNumber = supportPhone || config.supportNumber(currencyCode);
  const showFlexPolicy = reservation.flexEnabled;
  const policyTitle = showFlexPolicy ? "Flex Policy" : "Cancellation Policy";
  const user = useAppSelector((state) => state.Auth.user);
  const refundToOriginalPayment = user?.business.refundToOriginalFormOfPayment;
  const MIAWRollout = useExperiment("sf-miaw-rollout", { value: "off" });
  const sfMIAWFeatureFlagEnabled = MIAWRollout.data.value === "on";
  const chatLinkReplacementFeatureOn = useIsFeatureFlagOn("chat-link-replacement-members");

  const { typographyVariants } = useTypographyScaling();

  const policyContent = showFlexPolicy
    ? refundToOriginalPayment
      ? flexOriginalPaymentCopy
      : flexTravelCreditCopy
    : reservation.cancellationPolicy;

  const isViewOnlyTraveler = user?.role === "view_only_traveler";

  const showPolicy = !!(reservation.cancellationPolicy || showFlexPolicy) && !isViewOnlyTraveler;

  return (
    <Styled.AdditionalInfoContainer $isPreview={isPreview}>
      <Typography variant={typographyVariants.title} color="foregroundPrimary">
        Additional details
      </Typography>
      {!!chatLinkReplacementFeatureOn && !sfMIAWFeatureFlagEnabled ? (
        <Styled.InfoSection>
          <Typography variant={typographyVariants.heading} color="foregroundPrimary">
            Get support
          </Typography>
          <Typography variant={typographyVariants.body} color="foregroundPrimary">
            Reach out to Member Support via {<SupportChatLink text="live chat" />}, available 24/7.
          </Typography>
        </Styled.InfoSection>
      ) : (
        <Styled.InfoSection>
          <Typography variant={typographyVariants.heading} color="foregroundPrimary">
            Need assistance?
          </Typography>
          <Typography variant={typographyVariants.body} color="foregroundPrimary">
            <div>Contact support: {supportNumber}</div>
            <div>For faster support, mention #{reservation.id}</div>
          </Typography>
        </Styled.InfoSection>
      )}
      {!!showPolicy && (
        <Styled.InfoSection>
          <Typography variant={typographyVariants.heading} color="foregroundPrimary">
            {policyTitle}
          </Typography>
          <Typography variant={typographyVariants.body} color="foregroundPrimary">
            <div>{policyContent}</div>
          </Typography>
        </Styled.InfoSection>
      )}
      {!!reservation.hotelFeeDescription && (
        <Styled.InfoSection>
          <Typography variant={typographyVariants.heading} color="foregroundPrimary">
            Hotel terms
          </Typography>
          <Typography variant={typographyVariants.body} color="foregroundPrimary">
            <Styled.HotelFeeDescriptionContainer
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(reservation.hotelFeeDescription),
              }}
            />
          </Typography>
        </Styled.InfoSection>
      )}
    </Styled.AdditionalInfoContainer>
  );
};

export default AdditionalInfo;
