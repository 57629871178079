import moment from "moment";

import type { IFlightItinerarySlice } from "@hotel-engine/types/itinerary";
import type { IFlightItineraryIndicatorProps } from "@hotel-engine/app/FlightItineraryIndicator";

const getFlightDuration = (departTime: string, arrivalTime: string): string => {
  const departMoment = moment(departTime);
  const arrivalMoment = moment(arrivalTime);
  const diffTotal = arrivalMoment.diff(departMoment, "minutes");

  const diffHours = Math.floor(diffTotal / 60);
  let diffMin: string | number = Math.floor(diffTotal % 60);
  if (diffMin <= 9) {
    diffMin = `0${diffMin}`;
  }
  return `${diffHours ? diffHours + "h " : ""}${diffMin + "m"}`;
};

export const getItineraryIndicatorProps = (slice: IFlightItinerarySlice) => {
  const numberOfSegments = slice.segments.length - 1;
  const stopoverAirports = slice.segments.reduce((acc: string[], segment, i) => {
    if (i > 0) {
      acc.push(segment.origin.airportCode);
    }
    return acc;
  }, []);
  const departureTime = moment.parseZone(slice.segments[0].origin.flightTime).format("h:mm a");
  const arrivalTime = moment
    .parseZone(slice.segments[slice.segments.length - 1].destination.arrivalTime)
    .format("h:mm a");
  const totalFlightTime = getFlightDuration(
    slice.segments[0].origin.flightTime,
    slice.segments[slice.segments.length - 1].destination.arrivalTime
  );

  const itineraryIndicatorProps: IFlightItineraryIndicatorProps = {
    departureIata: slice.segments[0].origin.airportCode,
    arrivalIata: slice.segments[numberOfSegments].destination.airportCode,
    departureTime: departureTime,
    arrivalTime: arrivalTime,
    totalFlightTime: totalFlightTime,
    numberOfSegments: numberOfSegments,
    segmentsIata: stopoverAirports,
    isOutOfPolicy: false,
    padNextDayIndicator: true,
    alignCodesOuter: true,
  };

  return itineraryIndicatorProps;
};
