import { Box, Typography } from "@hotelengine/atlas-web";
import ResponsiveAtlasDialog from "@hotel-engine/app/ResponsiveAtlasDialog";
import type { IOccupant } from "@hotel-engine/types/booking";

import * as Styled from "./styles";
import {
  dedupeTravelers,
  getTravelerList,
  getTravelerSheet,
  MAX_TRAVELERS_DISPLAYED,
} from "./helpers";

interface ITravelerInfoProps {
  travelers: IOccupant[];
  padTop?: boolean;
}

const Travelers = ({ travelers, padTop = true }: ITravelerInfoProps) => {
  if (!travelers) return null;

  const dedupedTravelers = dedupeTravelers(travelers);
  const overflowTravelers = dedupedTravelers.length > MAX_TRAVELERS_DISPLAYED;
  const overflowCount = dedupedTravelers.length - MAX_TRAVELERS_DISPLAYED;

  const content = <Box paddingTop={24}>{getTravelerSheet(dedupedTravelers)}</Box>;

  const dialogTrigger = (
    <Box marginBottom={16} display="flex" flexDirection="column" alignItems="center">
      <Typography variant="link/sm" style={{ cursor: "pointer" }}>
        {overflowCount} additional guest{overflowCount > 1 ? "s" : ""}
      </Typography>
    </Box>
  );

  return (
    <>
      <Styled.TravelerCollapsibleContainer $padTop={padTop}>
        {getTravelerList(dedupedTravelers)}
      </Styled.TravelerCollapsibleContainer>
      {!!overflowTravelers && (
        <ResponsiveAtlasDialog
          triggerComponent={dialogTrigger}
          title={`Travelers (${dedupedTravelers.length})`}
          content={content}
          isModal
        />
      )}
    </>
  );
};

export default Travelers;
