import type { BoxProps } from "@hotelengine/atlas-web";
import { Box } from "@hotelengine/atlas-web";
import type React from "react";
import styled from "styled-components";

export const LayoverContainer = styled<React.FC<BoxProps>>(Box)`
  border-top: ${({ theme }) => `dashed ${theme.borderWidth.normal} ${theme.colors.borderMinimal}`};
  border-bottom: ${({ theme }) =>
    `dashed ${theme.borderWidth.normal} ${theme.colors.borderMinimal}`};

  padding: ${({ theme }) => `${theme.spacing[12]} 0`};
  margin: ${({ theme }) => `${theme.spacing[12]} 0`};
`;
