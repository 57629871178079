import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import { Form } from "@hotel-engine/common/Form";
import { Icon } from "@hotel-engine/common/Icon";
import { buttonReset, colors, sizes } from "@hotel-engine/styles";

export const DownloadAppBanner = styled.div`
  background-color: ${colors.black};
  color: ${colors.white};
  margin-top: 40px;
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};
  display: flex;
  justify-content: space-between;
  h4,
  h5,
  p {
    color: ${colors.white};
  }
  h4 {
    font-size: ${({ theme }) => theme.legacy.fontSize.lg};
    font-weight: ${({ theme }) => theme.legacy.fontWeight.bold};
    margin-bottom: ${({ theme }) => theme.legacy.space(1)};
    line-height: 1;
  }
  h5 {
    font-size: ${({ theme }) => theme.legacy.fontSize.sm};
    font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
    margin-bottom: ${({ theme }) => theme.legacy.space(2)};
  }
`;

export const InstructionsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  padding: ${({ theme }) => theme.legacy.space(2)};
  @media screen and (min-width: ${sizes.breakpoints.lg}) {
    padding: ${({ theme }) => theme.legacy.space(3)};
  }
`;

export const DownloadForm = styled(Form)`
  display: flex;
  align-items: flex-start;
  .ant-form-item-control {
    margin-bottom: 5px;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    &.has-error {
      margin-bottom: 20px;
    }
  }
  .phone-input {
    margin: 0 4px 0 0;
    width: 100%;
  }
  .ant-form-explain {
    font-size: ${({ theme }) => theme.legacy.fontSize.xs};
    position: absolute;
    width: 250px;
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: 4px;
  padding: 0px;
  min-width: 110px;
  &.ant-btn > .anticon + span {
    margin-left: 0;
  }
  /* When the loading icon appears it shifts the button contents over slightly */
  &.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(
      .ant-btn-circle-outline
    ):not(.ant-btn-icon-only) {
    padding-left: 15px;
  }
  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
  }
`;

export const InstructionalCopy = styled.p`
  font-size: ${({ theme }) => theme.legacy.fontSize.xs};
  &:last-child {
    margin-bottom: 0;
  }
`;

export const InputIcon = styled(Icon)<{ $hasError?: boolean }>`
  color: ${({ $hasError }) => ($hasError ? colors.khmerCurry : colors.finePine)};
`;

export const MobileButton = styled.button`
  ${buttonReset};
  line-height: ${({ theme }) => theme.legacy.lineHeight.xs};
  display: flex;
  align-items: center;
  .icon {
    color: ${colors.white};
    margin-left: 4px;
    height: 14px;
  }
`;

export const ImageContainer = styled.div`
  width: 30%;
  position: relative;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 0 10px 10px 0 /* disable theming (do not replicate) */;
  }
`;
