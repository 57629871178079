import { useEffect } from "react";

import { Box, Typography } from "@hotelengine/atlas-web";
import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import { useCarModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import BookingDetails from "@hotel-engine/app/ItineraryContent/cars/components/BookingDetails";
import Location from "@hotel-engine/app/ItineraryContent/cars/components/Location";
import NeedHelp from "@hotel-engine/app/ItineraryContent/cars/components/HelpAndPolicies/NeedHelp";
import Travelers from "@hotel-engine/app/ItineraryContent/cars/components/Travelers";
import { Modal } from "@hotel-engine/common/Modal";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";

import type { ICarItineraryWithId } from "pages/Trips/components/TripsItineraryPreviewPanel/car";

import CancellationTitle from "../common/CancellationTitle";
import CancellationButton from "../common/CancellationButton";
import FullRefundBanner from "../common/FullRefundBanner";
import CancelConfirmationModal from "../common/CancelConfirmationModal";
import RefundInfo from "./components/RefundInfo";
import { usePreviewCarRefund } from "../../hooks/usePreviewCarRefund";
import * as Styled from "./styles";
import { useCancelCar } from "../../hooks/useCancelCar";

const CarCancellation = () => {
  const { COMPANY_NAME } = useCompanyName();
  const { typographyVariants } = useTypographyScaling();
  const {
    isPreview,
    state: { showConfirmationModal },
    reservation,
    dispatch,
  } = useCarModificationsContext();

  const {
    refundInfo,
    isLoading,
    isError: isRefundCalcError,
  } = usePreviewCarRefund(
    (reservation as ICarItineraryWithId).tripId,
    (reservation as ICarItineraryWithId).bookingNumber
  );

  const penaltyFee = refundInfo?.cancellationFee;
  const cancelRefundAmount = refundInfo?.refundAmount;
  const cancelRefundType = refundInfo?.refundType;

  useEffect(() => {
    if (isRefundCalcError) {
      Modal.info({
        title: "We couldn't process your request.",
        onOk: () => globalThis.history.go(0),
        content: `There was an issue processing your cancellation. Please contact ${COMPANY_NAME} support at at 855-567-4683.`,
      });
    }
  }, [COMPANY_NAME, isRefundCalcError]);

  const setShowStatusModal = () => {
    dispatch({
      type: "SET_SHOW_STATUS_MODAL",
      payload: true,
    });
  };

  const { submitCancelCar } = useCancelCar({
    reservation: reservation as ICarItineraryWithId,
    showStatusModal: setShowStatusModal,
    refundInfo: {
      totalRefundAmount: Number(cancelRefundAmount),
      refundType: cancelRefundType,
      penaltyFee: refundInfo?.cancellationFee,
    },
    dispatch,
  });

  return (
    <Box backgroundColor="backgroundSecondary" paddingTop={!isPreview ? 16 : 0}>
      <CancellationTitle isCar />
      <Typography
        variant={typographyVariants.body}
        color="foregroundPrimary"
        as="p"
        style={{ textAlign: "center", marginBottom: 0 }}
      >
        Once confirmed, your cancellation is final and cannot be reversed.
      </Typography>
      <FullRefundBanner penaltyFee={penaltyFee} />
      <Travelers
        travelers={reservation.travelers || []}
        logoSrc={reservation.rentalCompany?.logo}
        padTop={false}
      />
      <BookingDetails logoSrc={reservation.rentalCompany?.logo} details={reservation} />
      <Location
        location={reservation.location}
        rentalCompany={reservation.rentalCompany}
        startTime={reservation.startTime}
        endTime={reservation.endTime}
      />
      <NeedHelp />
      <Styled.StickyContainer $isPreview={isPreview}>
        <RefundInfo isLoading={isLoading} refundInfo={refundInfo} />
        <CancellationButton text="Cancel booking" isRefundCalcLoading={isLoading} />
      </Styled.StickyContainer>
      {!!showConfirmationModal && <CancelConfirmationModal onConfirm={submitCancelCar} />}
    </Box>
  );
};

export default CarCancellation;
