import { Form } from "formik";
import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { buttonReset, colors } from "@hotel-engine/styles";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

export interface I2fvModalStyling {
  $isInModal?: boolean;
}

export const Title = styled.p<I2fvModalStyling>`
  ${typographyMixin("xlarge")};
  margin: 0;
  text-align: ${({ $isInModal }) => ($isInModal ? "start" : "center")};
`;

export const DescriptionText = styled.p<I2fvModalStyling>`
  ${typographyMixin("small")};
  font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
  color: ${colors.grey[500]};
  margin-top: ${({ theme }) => theme.legacy.space(1)};
  text-align: ${({ $isInModal }) => ($isInModal ? "start" : "center")};
`;

export const HelpText = styled.p<I2fvModalStyling>`
  ${typographyMixin("small")};
  font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
  color: ${colors.grey[500]};
  margin-top: ${({ theme }) => theme.legacy.space(1)};
  text-align: ${({ $isInModal }) => ($isInModal ? "start" : "center")};
`;

export const PhoneNumberText = styled.span<I2fvModalStyling>`
  ${typographyMixin("small")};
  font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
  color: ${colors.black};
  margin-top: ${({ theme }) => theme.legacy.space(1)};
  text-align: ${({ $isInModal }) => ($isInModal ? "start" : "center")};
`;

export const CodeForm = styled(Form)`
  ${mediaQuery(
    "sm",
    "max"
  )(`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  `)}
`;

export const TextButton = styled(Button)`
  ${buttonReset};
  ${typographyMixin("small")};
  font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
  background: none !important;
  color: ${colors.retroBlue};
  :disabled {
    color: ${colors.grey[300]};
    :hover {
      color: ${colors.grey[300]};
    }
  }
`;

export const SubmitButton = styled(Button)`
  display: block;
  width: 100%;
`;

export const ResendErrorMessage = styled.p`
  ${typographyMixin("body-medium")};
  color: ${colors.tomato};
`;
