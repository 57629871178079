import { AirlineLogo } from "@hotel-engine/assets/flights/AirlineLogo";
import { Box, Typography } from "@hotelengine/atlas-web";
import { FlightGraph } from "../FlightGraph";
import type { IFlightItinerarySegment } from "@hotel-engine/types/itinerary";
import type { IFlightItineraryIndicatorProps } from "@hotel-engine/app/FlightItineraryIndicator";

interface IFlightSegmentSummaryProps {
  departureSegment: IFlightItinerarySegment;
  itineraryIndicatorProps: IFlightItineraryIndicatorProps;
}

export const FlightSegmentSummary = ({
  departureSegment,
  itineraryIndicatorProps,
}: IFlightSegmentSummaryProps) => {
  return (
    <>
      <Box paddingBottom={12}>
        <Box display="flex" gap={6} alignItems="center">
          <AirlineLogo iata={departureSegment.airlineCode} />
          <Typography variant="body/md" color="foregroundPrimary">
            {departureSegment.airlineCode}
            {departureSegment.flightNumber}
          </Typography>
        </Box>
      </Box>
      <Box marginBottom={16}>
        <FlightGraph {...itineraryIndicatorProps} />
      </Box>
    </>
  );
};
