import styled, { css } from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";

const mobileDateSizes = {
  selectedDatesHeight: 78,
} as const;

export const LoadingBodyWrapper = styled.div`
  ${flexbox({
    direction: "column",
    justifyContent: "center",
    alignItems: "center",
  })};
`;

export const ModalImage = styled.img`
  margin-bottom: ${({ theme }) => theme.spacing[24]};
  width: 140px;
`;

export const SingleDatePickerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing[12]};

  ${({ theme }) =>
    css`
        @media screen and (max-width: ${theme.breakpoints.sm}) {
            flex-direction: column;
        }  
    `}

  & .SingleDatePicker {
    width: 100%;

    .SingleDatePickerInput {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border: ${({ theme }) => `${theme.borderWidth.normal} solid ${theme.colors.borderDefault}`};
      border-radius: ${({ theme }) => theme.borderRadius.pill};
      overflow: hidden;

      .CalendarDay__selected {
        background: ${({ theme }) => theme.colors.accentBlue};
        border: ${({ theme }) => theme.colors.accentBlue};
      }
    }

    .DateInput {
      width: 100%;

      .DateInput_input {
        font-size: ${({ theme }) => theme.legacy.fontSize.sm};
        font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
        border-bottom: 0;
        padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[12]}`};
      }

      .DateInput_input__disabled {
        font-style: normal;
      }
    }

    .SingleDatePickerInput_calendarIcon {
      margin: 0;
    }
  }
`;

export const SingleDatePickerContainer = styled.div<{
  disabled?: boolean;
}>`
  flex-basis: 48%;
  text-align: left;

  ${({ theme }) =>
    css`
        @media screen and (max-width: ${theme.breakpoints.sm}) {
            &:first-child {
                margin-bottom: ${theme.spacing[16]};
            }
        } 
`}

  & .SingleDatePicker {
    .DateInput {
      .DateInput_input {
        cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
      }
    }

    .SingleDatePickerInput:hover {
      border: ${({ disabled, theme }) =>
        disabled
          ? `${theme.borderWidth.normal} solid ${theme.colors.borderDefault}`
          : `${theme.borderWidth.normal} solid ${theme.colors.accentBlue}`};
    }

    .SingleDatePickerInput_calendarIcon {
      cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    }
  }
`;

export const CalendarPickerDatesContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: ${mobileDateSizes.selectedDatesHeight}px;
`;

export const DayPickerSingleDateControllerWrapper = styled.div<{
  isFocused?: boolean;
}>`
  height: ${({ isFocused }) => isFocused && `calc(100% - ${mobileDateSizes.selectedDatesHeight}px)`};

  .CalendarDay {
    &.CalendarDay__selected {
      background: ${({ theme }) => theme.colors.accentBlue};
      border: ${({ theme }) => theme.colors.accentBlue};
    }
  }
`;
