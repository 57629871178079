import { Icon } from "@hotel-engine/common/Icon";
import { Modal } from "@hotel-engine/common/Modal";

import * as Styled from "./styles";

export const showErrorModal = () => {
  Modal.info({
    icon: "",
    title: (
      <Styled.ErrorModalTitle>
        <Icon icon={["fal", "exclamation-circle"]} />
        <>It's not you, it's us.</>
      </Styled.ErrorModalTitle>
    ),
    content: (
      <Styled.ErrorModalBody>
        Something happened on our platform while you were trying to connect. Please contact us at{" "}
        <a href="mailto:support@hotelengine.com">support@hotelengine.com</a> to resolve.
      </Styled.ErrorModalBody>
    ),
    okText: "Close",
  });
};
