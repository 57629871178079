import type { GetPropsCommonOptions, UseComboboxGetInputPropsOptions } from "downshift";
import { type ChangeEventHandler, useEffect, useRef } from "react";

import { useSearchQueryParams } from "@hotel-engine/hooks/search/useSearchQueryParams";

import * as Styled from "../../styles";
import { SearchActionIcon } from "../SearchActionIcon";
import { Icon } from "@hotelengine/atlas-web";

export type AutoCompleteInputProps = {
  getInputProps: (
    options: UseComboboxGetInputPropsOptions,
    otherOptions?: GetPropsCommonOptions
  ) => object;
  handleFocused: () => void;
  handleBlurred: () => void;
  handleClear: () => void;
  isDirty: boolean;
  onInputValueChange: (value: string) => void;
  showList?: boolean;
  showLoadingSpinner: boolean;
  placeholder?: string;
};

export const AutoCompleteInput = ({
  getInputProps,
  handleFocused,
  handleBlurred,
  handleClear,
  isDirty,
  onInputValueChange,
  showLoadingSpinner,
  placeholder = "Search by city or address",
}: AutoCompleteInputProps) => {
  const { location } = useSearchQueryParams().queryParams;
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const handleClearRef = () => {
    inputRef.current?.focus();
    handleClear();
  };

  // Auto-focus on the input if search is for the current map area and there is no value present
  useEffect(() => {
    if (location === "Current Map Area" && !inputRef.current.value) {
      inputRef.current?.focus();
    }
  }, [location]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onInputValueChange(e.target.value);
  };

  return (
    <Styled.TextInputWrapper>
      <Icon name="location-dot--solid" color="foregroundSecondary" />
      <Styled.TextInput
        // cannot use string "false" here due to chrome "smart" autocomplete
        // https://github.com/ant-design/ant-design/issues/7659
        autoComplete="new-password"
        placeholder={placeholder}
        aria-label={placeholder}
        data-testid="search-location-input"
        maxLength={255}
        {...getInputProps(
          {
            id: "search-location-input",
            ref: inputRef,
            onFocus: handleFocused,
            onBlur: handleBlurred,
            onChange: handleChange,
          },
          { suppressRefError: true }
        )}
      />
      <SearchActionIcon
        handleClear={handleClearRef}
        isDirty={isDirty}
        showLoadingSpinner={showLoadingSpinner}
      />
    </Styled.TextInputWrapper>
  );
};
