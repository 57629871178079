import type { ReactNode } from "react";
import { isValidElement, useCallback, useMemo, useState } from "react";

import type { TypographyProps } from "@hotelengine/atlas-web";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTrigger,
  Divider,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Typography,
} from "@hotelengine/atlas-web";

type PriceBreakdownFieldProps = {
  name: string;
  cost: string;
  details?: string | string[] | ReactNode;
  costColor?: TypographyProps["color"];
};

export const PriceBreakdownField = ({
  name,
  cost,
  details,
  costColor = "foregroundPrimary",
}: PriceBreakdownFieldProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleTooltipExpansion = useCallback(
    (open: boolean) => {
      if (!!details) {
        setIsOpen(open);
      }
    },
    [details]
  );

  const detailsElement = useMemo(() => {
    const createDetailContent = () => {
      if (!!details && !isValidElement(details)) {
        const detailsAsArray = (typeof details === "string" ? [details] : details) as string[];

        return detailsAsArray?.map((desc) => (
          <Typography key={desc} variant="body/sm">
            {desc}
          </Typography>
        ));
      }

      return details;
    };

    return (
      <Box display="flex" flexDirection="column">
        {createDetailContent()}
      </Box>
    );
  }, [details]);

  const titleElement = useMemo(() => {
    if (isValidElement(details)) {
      return (
        <Dialog isOpen={isOpen} onOpenChange={handleTooltipExpansion}>
          <DialogTrigger style={{ cursor: `${!!details ? "pointer" : "auto"}` }}>
            <Typography variant="body/md" color="foregroundSecondary">
              {name}
              {!!details && <Divider variant="default" />}
            </Typography>
          </DialogTrigger>
          <DialogContent>{detailsElement}</DialogContent>
        </Dialog>
      );
    }

    return (
      <Popover isOpen={isOpen}>
        <PopoverTrigger>
          <Box
            style={{ cursor: `${!!details ? "pointer" : "auto"}` }}
            onMouseEnter={() => handleTooltipExpansion(true)}
            onMouseLeave={() => handleTooltipExpansion(false)}
          >
            <Typography variant="body/md" color="foregroundSecondary">
              {name}
            </Typography>
            {!!details && <Divider variant="dotted" />}
          </Box>
        </PopoverTrigger>
        <PopoverContent>{detailsElement}</PopoverContent>
      </Popover>
    );
  }, [details, detailsElement, handleTooltipExpansion, isOpen, name]);

  return (
    <Box data-testid={name} display="flex" justifyContent="space-between">
      {titleElement}
      <Typography variant="body/md" color={costColor}>
        {cost}
      </Typography>
    </Box>
  );
};
