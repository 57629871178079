import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import { Icon } from "@hotel-engine/common/Icon";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors, sizes } from "@hotel-engine/styles";
import config from "config";

const imagePath = `${config.cdnHost}/assets/personal-travel/images/lady.svg`;

export const PackYourBagsContainer = styled.div`
  position: relative;
`;

export const Title = styled.p`
  ${typographyMixin("xlarge")};
  margin: 0;
`;

export const SubTitle = styled.p`
  ${typographyMixin("small")};
  font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
  color: ${colors.grey[400]};
  margin-top: ${({ theme }) => theme.legacy.space(1)};
`;

export const Copy = styled(SubTitle)`
  color: ${colors.grey[800]};
`;

export const GreenCheckIcon = styled(Icon)`
  color: ${colors.green[600]};
  margin-left: ${({ theme }) => theme.legacy.space(2)};
`;

export const LetsGoButton = styled(Button)`
  width: 100%;
  margin-top: 16px;
  @media screen and (min-width: ${sizes.breakpoints.lg}) {
    margin-top: 40px;
  }
  @media screen and (max-width: ${sizes.breakpoints.md}) {
    margin-bottom: 60px;
  }
`;

export const SVGContainer = styled.div`
  position: relative;
  padding: 0px;
  margin: 0px;
  margin-left: auto;
  width: 132px;
  height: 80px;
  background-image: url("${imagePath}");
  @media screen and (min-width: ${sizes.breakpoints.lg}) {
    position: absolute;
    bottom: ${({ theme }) => theme.legacy.space(4)};
    right: 0px;
  }
`;
