import type { CSSProperties, ReactNode } from "react";
import { Popover } from "@hotel-engine/common/Popover";
import { Typography, type TypographyVariant } from "@hotelengine/atlas-web";
import * as Styled from "../../lodging/components/SummaryOfCharges/styles";
import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";

export interface ISummaryOfChargesItemProps {
  label: string;
  value: string;
  mobile?: boolean;
  popoverContent?: ReactNode;
  labelColor?: "foregroundPrimary" | "foregroundSecondary" | "sentimentPositiveDefault";
  valueColor?: "foregroundPrimary" | "sentimentPositiveDefault";
  isBold?: boolean;
  style?: CSSProperties;
  typography?: TypographyVariant;
  hideItem?: boolean;
}

const SummaryOfChargesItem = ({
  label,
  value,
  mobile,
  popoverContent,
  labelColor = "foregroundSecondary",
  valueColor = "foregroundPrimary",
  isBold,
  style,
  typography,
  hideItem = false,
}: ISummaryOfChargesItemProps) => {
  const { isPreview } = useModificationsContext();
  const { typographyVariants } = useTypographyScaling();

  if (hideItem) return null;

  return (
    <Styled.FieldLine
      style={{
        ...style,
        gridTemplateColumns: isPreview ? "180px 1fr" : "250px 1fr",
      }}
    >
      {!!popoverContent ? (
        <Popover
          overlayStyle={{
            width: mobile ? "210px" : "370px",
          }}
          placement={isPreview ? "topRight" : "topLeft"}
          content={popoverContent}
        >
          <Typography
            variant={typography || typographyVariants.body}
            color={labelColor}
            style={{
              textDecorationLine: "underline",
              textDecorationStyle: "dotted",
              fontWeight: isBold ? 600 : "normal",
              width: "fit-content",
              cursor: !!popoverContent ? "pointer" : "default",
            }}
          >
            {label}
          </Typography>
        </Popover>
      ) : (
        <Typography
          variant={typography || typographyVariants.body}
          color={labelColor}
          style={{ fontWeight: isBold ? 600 : "normal" }}
        >
          {label}
        </Typography>
      )}
      <Typography
        variant={typography || typographyVariants.body}
        color={valueColor}
        style={{ fontWeight: isBold ? 600 : "normal" }}
      >
        {value}
      </Typography>
    </Styled.FieldLine>
  );
};

export default SummaryOfChargesItem;
