import type { BoxProps, TypographyProps } from "@hotelengine/atlas-web";
import { Box, Typography } from "@hotelengine/atlas-web";
import type React from "react";
import styled from "styled-components";

export const FlightGraphContainer = styled<React.FC<BoxProps>>(Box)`
  display: grid;
  grid-template-columns: auto 1fr auto;

  align-items: center;
  justify-items: center;

  gap: ${({ theme }) => theme.spacing[8]};
`;

export const GraphLineContainer = styled<React.FC<BoxProps>>(Box)`
  height: 20px;
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  align-self: start;
  margin-top: 3px; // Not using token since this is a fixed amt to fix alignment

  gap: ${({ theme }) => theme.spacing[8]};
`;

export const GraphLine = styled<React.FC<BoxProps>>(Box)`
  height: 4px;
  width: 100%;

  background-color: #dbd8ea;

  border-radius: ${({ theme }) => theme.borderRadius.pill};
`;

export const GraphLineDurationText = styled<React.FC<TypographyProps>>(Typography).attrs(() => ({
  variant: "body/sm",
  color: "foregroundTertiary",
}))`
  white-space: nowrap;
`;

export const ArrivalsTextContainer = styled<React.FC<BoxProps>>(Box)`
  text-align: right;
`;
