import styled from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { Button } from "@hotelengine/atlas-web";

export const PillBox = styled.div`
  ${flexbox({
    wrap: "wrap",
    alignItems: "baseline",
  })}
`;

export const Pill = styled(Button).attrs({
  size: "sm",
  color: "everdark",
})`
  margin-top: ${({ theme }) => theme.spacing[8]};
  margin-right: ${({ theme }) => theme.spacing[8]};
  border-radius: ${({ theme }) => theme.borderRadius.pill};
  padding: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[8]}`};
  height: 24px;
  min-height: initial;
  ${flexbox({ alignItems: "center", justifyContent: "center" })}

  > span {
    height: 24px;
    display: flex;
    column-gap: ${({ theme }) => theme.spacing[4]};
    align-items: center;

    > span {
      display: block;
      line-height: 24px;
    }

    > button {
      min-width: initial;
      min-height: initial;
    }
  }
`;
