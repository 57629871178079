import { useState } from "react";

import { useAppDispatch } from "store/hooks";
import { PropertyActions } from "store/containers/Property/PropertyRedux";

import { FavoriteToggle } from "@hotel-engine/app/FavoriteToggle/FavoriteToggle";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import { Icon as AtlasIcon, Icon, IconButton, Typography } from "@hotelengine/atlas-web";
import { usePropertyQuery } from "@hotel-engine/react-query/property/usePropertyQuery";
import { useDownloadItineraryDocument } from "@hotel-engine/react-query/downloadItineraryDocument/useDownloadItineraryDocument";
import { AddToCalendarModal } from "@hotel-engine/app/modals/AddToCalendarModal";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import SendItineraryModal from "@hotel-engine/app/modals/SendItineraryModal";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { notification } from "@hotel-engine/common/Notifications";
import { captureMessage } from "@hotel-engine/utilities";

import type { IReservationBaseWithId } from "pages/Trips/components/TripsItineraryPreviewPanel/lodging";
import type { IFlightItineraryWithId } from "pages/Trips/components/TripsItineraryPreviewPanel/flights";
import type { ICarItineraryWithId } from "pages/Trips/components/TripsItineraryPreviewPanel/car";
import type { ITripPreview } from "pages/Trips/context";

import { useBaseModificationsContext } from "../../ModificationsContext";
import type { IFlightItinerary } from "../../../../types/itinerary";
import * as Styled from "./styles";
import { isFlight, isLodging } from "../isLodging";

interface IHeaderActionsProps {
  onClose?: ((tripPreview: ITripPreview) => void) | ((reservation: IReservationBase) => void);
  itinerary: IReservationBaseWithId | IFlightItineraryWithId | ICarItineraryWithId | undefined;
}

const HeaderActions = ({ onClose, itinerary }: IHeaderActionsProps) => {
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    state: { modificationView },
    reservation,
    isPreview,
    dispatch: modificationsDispatch,
  } = useBaseModificationsContext();

  const isFlightsPostbookingOn = useIsFeatureFlagOn("flights-postbooking");
  const isLodgingRefreshOn = useIsFeatureFlagOn("lodging-refresh");

  const isLodgingItinerary = isLodging(reservation);
  const isFlightItinerary = isFlight(reservation);

  const itineraryId = isLodgingItinerary ? reservation.id : reservation.bookingNumber;

  const {
    refetch: fetchPrintPdf,
    isLoading: loadingPrintPdf,
    isFetching: fetchingPrintPdf,
  } = useDownloadItineraryDocument({
    contractNumber: itineraryId,
    documentType: isLodgingItinerary
      ? "itinerary"
      : isFlightItinerary
        ? "flightItinerary"
        : "carItinerary",
  });

  const toggleShowShareModal = () => {
    setShowShareModal(!showShareModal);
  };

  const handleCloseAction = () => {
    if (!!modificationView) {
      modificationsDispatch({
        type: "SET_SHOW_NAVIGATION_MODAL",
        payload: true,
      });
    } else if (isPreview) {
      if (isFlightsPostbookingOn) {
        (onClose as (tripPreview: ITripPreview) => void)?.({
          id: itinerary?.tripId,
          bookingType: !!(itinerary as IFlightItinerary).slices ? "flight" : "lodging",
        });
      } else {
        (onClose as (reservation: IReservationBase) => void)?.(reservation as IReservationBase);
      }
    } else {
      navigate("/trips/upcoming");
    }
  };

  const handlePrintAction = async () => {
    try {
      await fetchPrintPdf();
    } catch (err) {
      notification.open({
        message: "There was an error getting your document.",
        duration: 8,
        icon: <Icon name="circle-exclamation" />,
        description: "Please try again later or contact support for assistance.",
      });

      captureMessage("Error fetching document", {
        error: new Error(`Could not fetch itinerary: ${err}`),
      });
    }
  };

  /** This is needed here to update the reservation properly after performing header actions like clicking the favorite button */
  usePropertyQuery((reservation as IReservationBase)?.propertyId, {
    enabled: isLodgingItinerary,
    cacheTime: 0,
    onSuccess(property) {
      dispatch(PropertyActions.getPropertySuccess(property));
    },
  });

  const getBackText = () => {
    if (!!modificationView) {
      return "Return to itinerary";
    } else {
      return isPreview ? "Close" : "Trips";
    }
  };

  return (
    <Styled.ItineraryPanelActions
      className="no-padding"
      isLodgingItinerary={!!isLodgingItinerary && !isLodgingRefreshOn}
    >
      {isPreview ? (
        !!isLodgingItinerary && (!isLodgingRefreshOn || !!modificationView) ? (
          <Styled.CloseButton data-testid="close-preview-panel" onClick={handleCloseAction}>
            <AtlasIcon
              name={!!modificationView ? "chevron-left" : "xmark"}
              color="foregroundPrimary"
            />
            <Typography variant="body/sm" color="foregroundPrimary">
              {getBackText()}
            </Typography>
          </Styled.CloseButton>
        ) : (
          <IconButton
            onClick={handleCloseAction}
            icon="xmark"
            variant="outlined"
            color="secondary"
          />
        )
      ) : (
        <Styled.NewReturnLink data-testid="close-preview-panel">
          <AtlasIcon name="chevron-left" />
          <span onClick={handleCloseAction} role="link">
            {getBackText()}
          </span>
        </Styled.NewReturnLink>
      )}
      <Styled.ButtonGroup>
        {!!isLodgingItinerary && (
          <FavoriteToggle
            className="itinerary-panel-header"
            propertyId={(reservation as IReservationBase).propertyId}
            propertyName={(reservation as IReservationBase).propertyName}
            useAtlas
          />
        )}
        <Styled.ActionItem
          icon="print"
          variant="plain"
          color="everdark"
          isDisabled={loadingPrintPdf || fetchingPrintPdf}
          isLoading={loadingPrintPdf || fetchingPrintPdf}
          onClick={handlePrintAction}
        />
        {!!isLodgingItinerary && (
          <Styled.ActionItem
            icon="calendar-plus"
            variant="plain"
            color="everdark"
            onClick={() => setShowCalendarModal(true)}
          />
        )}
        <Styled.ActionItem
          icon="share"
          variant="plain"
          color="everdark"
          onClick={() => toggleShowShareModal()}
          id="share"
        />
      </Styled.ButtonGroup>
      {!!showCalendarModal && (
        <AddToCalendarModal
          reservation={reservation as IReservationBase}
          onClose={() => setShowCalendarModal(false)}
        />
      )}
      <SendItineraryModal
        contract={reservation}
        onCancel={toggleShowShareModal}
        visible={showShareModal}
      />
    </Styled.ItineraryPanelActions>
  );
};

export default HeaderActions;
