import styled, { css } from "styled-components";
import Drawer from "@hotel-engine/common/Drawer";
import AlertBanner from "@hotel-engine/common/AlertBanner";
import { colors } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";
import Button from "@hotel-engine/common/Button";
import type { IStatus, ISupportAlertBanner } from "./types";
import { Typography } from "pages/Checkout/LegacyLodging/AdditionalServices/styles";

export const SupportCaseDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    min-width: 640px;
    width: 640px;
  }

  .ant-drawer-body {
    padding: 112px 40px 112px;
  }
`;

export const StatusLabel = styled.span<IStatus>`
  display: inline;
  border-radius: 100px /* disable theming (do not replicate) */;
  background-color: ${(props) => (props.isActive ? colors.green[600] : colors.slate[100])};
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  padding: 6px 8px;
  color: ${(props) => (props.isActive ? colors.white : colors.grey[800])};
`;

export const Row = styled.div`
  ${flexbox({
    justifyContent: "space-between",
    alignItems: "flex-start",
  })};
  margin-bottom: 8px;

  ${mediaQuery(
    "xl",
    "max"
  )(`
    &:last-child {
      margin-bottom: 0;
  }
  `)}
`;

export const SupportAlertBanner = styled(AlertBanner)<ISupportAlertBanner>`
  margin-top: 16px;

  ${({ status }) =>
    (status === "success" &&
      css`
        background-color: ${colors.green[50]};
        .ant-alert-icon {
          color: ${colors.green[500]};
        }
      `) ||
    (status === "error" &&
      css`
        background-color: ${colors.red[50]};
        .ant-alert-icon {
          color: ${colors.red[400]};
        }
      `) ||
    (status === "pending" &&
      css`
        background-color: ${colors.blue[50]};
        .ant-alert-icon {
          color: ${colors.blue[500]};
        }
      `)}
`;

export const FieldDivider = styled.hr`
  color: ${colors.grey[100]};
  margin: 24px 0;
`;

export const ItineraryButton = styled(Button)`
  margin-top: 16px;
  width: 100%;
  height: 40px;
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.md};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StrikethroughText = styled(Typography)`
  color: ${colors.grey[500]};
  margin-right: 4px;
  text-decoration: line-through;
`;
