import { getBusinessToken } from "@hotel-engine/utilities/auth/getToken";
import { useAppSelector } from "store/hooks";

function useAccountToken() {
  const user = useAppSelector((state) => state.Auth.user);
  const isPersonalAccount = user?.accountType === "personal";
  const token = isPersonalAccount ? (getBusinessToken() as string) : undefined;

  return token;
}

export default useAccountToken;
