import { Chip } from "@hotelengine/atlas-web";

interface IRoomStatusPillProps {
  modified: boolean;
  cancelled: boolean;
}

const RoomStatusPill = ({ modified, cancelled }: IRoomStatusPillProps) => {
  if (!modified && !cancelled) return null;

  if (cancelled) {
    return (
      <Chip
        label="CANCELLED"
        color="red"
        size="xs"
        leadingIcon="circle-exclamation"
        style={{ marginLeft: 8, borderRadius: "4px" }}
      />
    );
  }

  return <Chip label="Modified" color="green" size="sm" style={{ marginLeft: 8 }} />;
};

export default RoomStatusPill;
