import { useTheme } from "styled-components";

import { routes } from "@hotel-engine/constants";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";

import * as Styled from "./styles";
import { Box } from "@hotelengine/atlas-web";
import CopiableConfirmation from "../../../shared/CopiableConfirmation";

const PropertyDetails = () => {
  const { typography, colors } = useTheme();
  const { reservation, isPreview } = useModificationsContext();
  const navigate = useNavigate();

  if (!reservation) return null;

  const confirmationNumber = reservation.propertyConfirmationNumber;

  const goToProperty = () =>
    navigate({
      pathname: `${routes.properties}/${reservation.propertyId}`,
    });

  return (
    <Styled.PropertyContainer $isPreview={isPreview}>
      <Box>
        <Styled.PropertyName
          to={routes.properties + "/" + reservation.propertyId}
          style={{
            ...typography.desktop["heading/xl"],
            color: colors.foregroundPrimary,
          }}
          data-testid="property-name"
        >
          {reservation.propertyName}
        </Styled.PropertyName>
      </Box>
      <Box as="a" data-testid="property-image" onClick={goToProperty}>
        <Styled.PropertyImage alt={reservation.propertyName} src={reservation.propertyPhotoUrl} />
      </Box>
      {!!(reservation.roomCount === 1) && !!confirmationNumber && (
        <CopiableConfirmation
          confirmationNumber={confirmationNumber}
          bookingNumber={reservation.id}
        />
      )}
    </Styled.PropertyContainer>
  );
};

export default PropertyDetails;
