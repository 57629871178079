import styled from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const ScreenReadyOnly = styled.span`
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
`;

export const ElevatedContainer = styled.div`
  ${flexbox({ direction: "column" })};

  & > *:not(:last-child) {
    margin-bottom: 18px;
  }
`;

export const Row = styled.div`
  ${flexbox({ justifyContent: "space-between", alignItems: "center" })}
`;

export const GroupBookings = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  padding: ${({ theme }) => theme.spacing[16]};
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
`;
