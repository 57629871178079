import { useMutation } from "react-query";
import type { UseMutationOptions } from "react-query";

import { endpoints } from "@hotel-engine/react-query/constants";
import { convertToSnake, useApi } from "@hotel-engine/react-query/useApi";
import { useRefetchFavoriteProperties } from "@hotel-engine/react-query/favoriteProperties/useRefetchFavoriteProperties";
import { useRefetchHiddenProperties } from "@hotel-engine/react-query/hiddenProperties/useRefetchHiddenProperties";
import { useRefetchPropertyReviews } from "@hotel-engine/react-query/propertyReviews/useRefetchPropertyReviews";
import type {
  ICreateHiddenProperty,
  ICreateHiddenPropertyParams,
} from "@hotel-engine/types/hiddenProperty";
import type { IErrorResponse } from "@hotel-engine/types/errors";

export const useCreateHiddenProperty = (
  options?: UseMutationOptions<ICreateHiddenProperty, IErrorResponse, ICreateHiddenPropertyParams>
) => {
  const post = useApi("post");
  const refetchPropertyReviews = useRefetchPropertyReviews();
  const refetchFavoriteProperties = useRefetchFavoriteProperties();
  const refetchHiddenProperties = useRefetchHiddenProperties();

  return useMutation((params) => post(endpoints.hiddenProperties, convertToSnake(params)), {
    onSuccess: () => {
      refetchHiddenProperties();
      refetchPropertyReviews();
      refetchFavoriteProperties();
    },
    ...options,
  });
};
