import moment from "moment";

import type { IRewardsProfile } from "@hotel-engine/types/user";

interface IUpcomingRewardsDates {
  anniversary: moment.Moment;
  throughDate: moment.Moment;
}

const upcomingRewardsDates = ({
  currentTier,
  nextTier,
  joinDate,
}: IRewardsProfile): IUpcomingRewardsDates => {
  // calculate member's rewards anniversary date
  let anniversary = moment(joinDate);
  const rightNow = moment();
  while (anniversary.isBefore(rightNow)) {
    anniversary = anniversary.add(1, "year");
  }

  // if currentTier.tierNumber > nextTier.tierNumber: benefits end at anniversary date
  // else benefits end at anniversary date + 1 year

  // if currentTier = platinum, compare currentTier.tierNumber to the max tier level
  const condition = nextTier ? nextTier.tierNumber : 5;

  let throughDate = moment(anniversary);
  if (currentTier.tierNumber <= condition) {
    throughDate = throughDate.add(1, "y");
  }

  return {
    anniversary,
    throughDate,
  };
};

export default upcomingRewardsDates;
