import type { UseQueryOptions } from "react-query";

import type { ICalculatorData, IBookingCancelParams } from "@hotel-engine/types/booking";

import { convertToSnake, useApi } from "@hotel-engine/react-query/useApi";
import { useExtendedQuery } from "@hotel-engine/react-query/useExtendedQuery";
import { endpoints } from "@hotel-engine/react-query/constants";
import { stringifyParams } from "@hotel-engine/react-query/bookings/helpers";

export const useBookingCancelCalculatorQuery = (
  params: IBookingCancelParams,
  options?: UseQueryOptions<ICalculatorData>
) => {
  const get = useApi("get");
  const stringifiedParams = stringifyParams({
    rooms: params.rooms?.map((room) => convertToSnake(room)),
  });

  return useExtendedQuery(
    [endpoints.bookings, params],
    () =>
      get<ICalculatorData>(`${endpoints.bookings}/${params.id}/cancellation?${stringifiedParams}`),
    options
  );
};
