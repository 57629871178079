import * as yup from "yup";

export const customFieldsSchema = yup.object().shape({
  customFields: yup.array().of(
    yup.object().shape({
      value: yup
        .string()
        .when("required", ([customFieldIsRequired], schema) =>
          customFieldIsRequired ? schema.required("Required") : schema
        )
        .when("minTextLength", ([minTextLength], schema) => {
          if (minTextLength) {
            return schema.min(minTextLength, `Must contain at least ${minTextLength} characters`);
          }
          return schema;
        })
        .when("maxTextLength", ([maxTextLength], schema) => {
          if (maxTextLength) {
            return schema.max(maxTextLength, `Must be ${maxTextLength} characters or less`);
          }
          return schema;
        }),
    })
  ),
});
