import styled, { css } from "styled-components";

import { sizes } from "@hotel-engine/styles";
import type { breakpoints } from "@hotel-engine/styles/variables/sizes";

interface IPageLayout {
  isOpen?: boolean;
  noPadding: boolean;
  contentWidth: keyof typeof breakpoints | "100%";
  customPadding?: string;
}

export const PageLayoutContent = styled.main<IPageLayout>`
  display: flex;
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  min-height: 100%;
  padding: 0 16px;
  @media screen and (min-width: ${sizes.breakpoints.lg}) {
    ${({ customPadding, noPadding }) =>
      noPadding
        ? css`
            padding: 0;
          `
        : css`
            padding: ${customPadding ? customPadding : "0 40px"};
          `};
  }

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `};

  ${({ contentWidth }) =>
    contentWidth &&
    css`
      max-width: ${contentWidth};
    `};
`;

export const PageLayoutWrapper = styled.div<IPageLayout>`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  ${({ isOpen }) =>
    isOpen &&
    css`
      height: 100vh;
      overflow: hidden;
    `};

  .footer,
  .header {
    flex-grow: 0;
    flex-shrink: 0;
  }
`;
