import styled from "styled-components";

import { buttonReset } from "@hotel-engine/styles";

export const DisplayGuestsAndRooms = styled.button.attrs(() => ({
  type: "button",
}))<{ isMobile?: boolean }>`
  ${buttonReset};

  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? "flex-start" : "center")};
  align-items: center;
  width: 100%;
  height: 100%;
`;
