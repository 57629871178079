import { Field } from "formik";
import styled from "styled-components";

import TextAreaField from "@hotel-engine/common/FormikFields/TextAreaField";
import { Modal } from "@hotel-engine/common/Modal";

export const SendItineraryModal = styled(Modal)`
  .ant-modal-content {
      border-radius: ${({ theme }) => theme.borderRadius.md} !important;
  }

  .ant-modal-body {
      padding: ${({ theme }) => theme.spacing[24]} !important;
  }
  
  h2 {
    text-align: left;
    margin-bottom: ${({ theme }) => theme.spacing[24]};
  }
`;

export const EmailsField = styled(Field)`
  #emails {
    padding: 7px 8px;
    border-radius: ${({ theme }) => theme.borderRadius.pill};
    }
    `;

export const NoteFormItem = styled(TextAreaField)`
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  margin-bottom: 5px;
`;
