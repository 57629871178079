import type {
  IAutocompleteFavoriteProperty,
  IPreferredProperty,
} from "@hotel-engine/types/favoriteProperty";
import type { IGooglePrediction, IRecentSearch } from "@hotel-engine/types/search";
import type {
  ILocationRecord,
  IOptionalLatLngLocationRecord,
  RemoveDuplicatePropertiesObj,
  ReturnSearchResultsObj,
} from "@hotel-engine/types/locations";
import type { IWorksite } from "@hotel-engine/types/worksite";
import { isRecentSearch } from "./typeHelpers";
import { fuzzy, search } from "fast-fuzzy";

/**
 * This is set relatively low because we often get quite different streets back from the two different sources,
 * so we get kicked out of the comparison prematurely even though they may be the same property.
 * Since the property name threshold is still higher, this helps to still capture a better number of potential matches.
 * */
const SAME_STREET_THRESHOLD = 0.3;
const SAME_PROPERTY_THRESHOLD = 0.7;

/** Removes prediction types that we don't want to appear, like states and countries */
const INVALID_PREDICTION_TYPES = [
  "administrative_area_level_1",
  "administrative_area_level_2",
  "country",
  "continent",
  "lodging",
];

export const updateRecentSearchesForPropertySearch = (
  recentSearches: IRecentSearch[]
): IRecentSearch[] => {
  return recentSearches.map((recentSearch) => {
    const isSpecificProperty = !!recentSearch.propertyId;
    const updatedAttributes = {
      id: isSpecificProperty ? `property.${recentSearch.propertyId}` : null,
      types: isSpecificProperty ? ["property", "recentSearch"] : ["recentSearch"],
    };

    return {
      ...recentSearch,
      ...updatedAttributes,
    } as IRecentSearch;
  });
};

/** returnSearchResultsArr: Separates worksites and properties from locations endpoint and injects favorites and Google preditions between filters country, state, and county level results from array
 * @param @typedef {Object} ReturnSearchResultsObj
 * @property {array} recentSearches: IRecentSearch[];
 * @property {array} worksites: IWorksite[];
 * @property {array} companyChoice: ILocationRecord[];
 * @property {array} favorites: ILocationRecord[];
 * @property {array} apiResults: ILocationRecord[];
 * @property {array} googleResults: IGooglePrediction[]
 *
 * @returns sorted results array with recentSearches, worksites, companyChoice, favorites, googleResults, apiResults
 */

export const returnSearchResultsArr = ({
  recentSearches = [],
  worksites = [],
  companyChoice = [],
  favorites = [],
  apiResults = [],
  googleResults = [],
}: ReturnSearchResultsObj) => {
  const hasPredictions = googleResults.length;
  const propertyResults = apiResults.filter(
    (loc) => loc.id.includes("property") && loc.latitude && loc.longitude
  );
  const filteredGoogleResults = returnFilteredPredictionsList(googleResults);

  const dedupedGoogleAndLocationResults = hasPredictions
    ? removeDuplicateProperties({
        companyChoice,
        favorites,
        apiResults,
        googleResults: filteredGoogleResults,
      })
    : [...companyChoice, ...favorites, ...propertyResults];

  return [...recentSearches, ...worksites, ...dedupedGoogleAndLocationResults];
};

export const returnFilteredPredictionsList = (arr: IGooglePrediction[]) => {
  const localities = arr.filter((prediction) => prediction.types.includes("locality"));
  const nonLocalities = arr.filter((prediction) => !prediction.types.includes("locality"));

  const predictions = [...localities, ...nonLocalities].filter(
    (prediction) => !prediction.types.some((type) => INVALID_PREDICTION_TYPES.includes(type))
  );

  return predictions;
};

/** removeDuplicateProperties: Removes location results that are duplicates of google results and modifies google results to act as specific properties
 * @param @typedef {Object} RemoveDuplicatePropertiesObj - Partial ofReturnSearchResultsObj that omits  recentSearches & worksites
 * @property {array} companyChoice: ILocationRecord[];
 * @property {array} favorites: ILocationRecord[];
 * @property {array} apiResults: ILocationRecord[];
 * @property {array} googleResults: IGooglePrediction[]
 *
 * @returns results array with duplicates removed from locations and favorites and duplicate autocomplete results modified
 */

export const removeDuplicateProperties = ({
  companyChoice = [],
  favorites = [],
  apiResults = [],
  googleResults = [],
}: RemoveDuplicatePropertiesObj) => {
  if (!googleResults?.length && !apiResults?.length) {
    return [...companyChoice, ...favorites, ...googleResults, ...apiResults];
  }

  const autocompleteFavoriteMatches: IGooglePrediction[] = [];
  const autocompletePreferredMatches: IGooglePrediction[] = [];

  const removeDuplicates = (
    arr: ILocationRecord[],
    type: "favorite" | "property" | "preferred"
  ) => {
    return arr.reduce((acc: ILocationRecord[], cur: ILocationRecord) => {
      let matchExists = false;

      googleResults.forEach((match, i) => {
        const propertiesAreTheSame = arePropertiesProbablyTheSame(match, cur);

        /** If a location result matches an autocomplete result, don't return that location result and modify the autocomplete result */
        if (propertiesAreTheSame) {
          if (!match.place_id.includes("property")) {
            const propertyData = JSON.stringify({
              id: cur.id,
              latitude: cur.latitude,
              longitude: cur.longitude,
            });
            match.place_id = `${match.place_id}|property:${propertyData}`;
          }

          const displayRegion = cur.region?.length ? cur.region : cur.country;
          match.structured_formatting.secondary_text = `${cur.streetAddress}, ${cur.city}, ${displayRegion}`;

          if (type === "favorite" || type === "preferred") {
            /** property is necessary in the types array to trigger a property search */
            if (!match.types.includes("property")) {
              match.types.unshift("property");
            }

            /** If a result is a match, move it from the google results to its own matched array to preserve the display order */
            if (type === "favorite" && autocompleteFavoriteMatches.length < 2) {
              const autocompleteMatch = googleResults.splice(i, 1)[0];
              autocompleteFavoriteMatches.push(autocompleteMatch);
              match.types.unshift(type);
            }
            if (type === "preferred" && autocompletePreferredMatches.length < 2) {
              const autocompleteMatch = googleResults.splice(i, 1)[0];
              autocompletePreferredMatches.push(autocompleteMatch);
              match.types.unshift(type);
            }
          } else if (!match.types.includes(type)) {
            match.types.unshift(type);
          }

          matchExists = true;
        }

        /** If the location result is either a favorite or preferred property, don't return that location result */
        if (type === "property") {
          if (
            favorites.some((place) => place.locationName === cur.locationName) ||
            companyChoice.some((place) => place.locationName === cur.locationName)
          ) {
            matchExists = true;
          }
        }
      });

      return matchExists ? acc : [...acc, cur];
    }, []);
  };

  /** These arrays must be created in the following order:
   * companyChoice
   * favorites
   * locationsData
   * This preserves the correct results display dictated by product
   */
  const preferredPropertiesWithDuplicatesRemoved = removeDuplicates(companyChoice, "preferred");

  const favoritePlacesWithDuplicatesRemoved = removeDuplicates(favorites, "favorite");

  const locationsDataWithDuplicatesRemoved = removeDuplicates(apiResults, "property");

  /** This moves any autocomplete results that have been given the property treatment to the top of the list, and any that have the favorite treatment above those */
  const sortedAutocompleteMatches = googleResults.sort((a, b) => {
    if (!a.place_id.includes("property") && b.place_id.includes("property")) {
      return 1;
    } else if (!a.place_id.includes("property") && !b.place_id.includes("property")) {
      return 0;
    }
    return -1;
  });

  /** If there are any preferred or favorite properties deduped from the google results display those arrays otherwise use the full deduped array  */
  const hasPreferredMatches = autocompletePreferredMatches.length === 2;
  const preferredArr = hasPreferredMatches
    ? autocompletePreferredMatches
    : [...autocompletePreferredMatches, ...preferredPropertiesWithDuplicatesRemoved].slice(0, 2);

  const hasFavoriteMatches = autocompleteFavoriteMatches.length === 2;
  const favoritesArr = hasFavoriteMatches
    ? autocompleteFavoriteMatches
    : [...autocompleteFavoriteMatches, ...favoritePlacesWithDuplicatesRemoved].slice(0, 2);

  return [
    ...preferredArr,
    ...favoritesArr,
    ...sortedAutocompleteMatches,
    ...locationsDataWithDuplicatesRemoved,
  ];
};

/**
 * Uses string-similarity package and other location data to compare locations and return whether or not they are, within a reasonable threshold, the same property
 * @param googleResult the autocomplete result from Google that is being compared
 * @param locationResult the specific property or favorite that is being compared
 * @returns boolean - whether the two properties are the same property or not
 */
export const arePropertiesProbablyTheSame = (
  googleResult: IGooglePrediction,
  locationResult: ILocationRecord
): boolean => {
  const areBothProperties =
    locationResult.id.includes("property") &&
    googleResult.types.some((type) => ["lodging", "premise"].includes(type));

  if (!areBothProperties) return false;

  const isSameCityAndRegion =
    googleResult.terms.filter(
      (term) => term.value === locationResult.city || term.value === locationResult.region
    ).length === 2;

  if (!isSameCityAndRegion) return false;

  const isProbablySameStreet =
    fuzzy(locationResult.streetAddress || "", googleResult.terms[1].value) >= SAME_STREET_THRESHOLD;

  if (locationResult.streetAddress && !isProbablySameStreet) return false;

  const isProbablySameProperty =
    fuzzy(locationResult.locationName, googleResult.structured_formatting.main_text) >=
    SAME_PROPERTY_THRESHOLD;

  return isProbablySameProperty;
};

/**
 * Formats returned google object into ILocationRecord
 * @param placeDetails full details of selected location from Google
 * @returns google.maps.places.PlaceResult object as ILocationRecord
 */
export const returnGoogleAddressObj = (prediction: IGooglePrediction) => {
  const hasMainText = !!prediction.structured_formatting.main_text;
  const hasSecondaryText = !!prediction.structured_formatting.secondary_text;
  const mainText = hasMainText ? prediction.structured_formatting.main_text : "";
  const secondaryText = hasSecondaryText
    ? `, ${prediction.structured_formatting.secondary_text}`
    : "";
  const description = `${mainText}${secondaryText}`;

  return {
    city: "",
    region: "",
    country: "",
    streetAddress: description,
    postalCode: "",
    id: prediction.place_id,
    locationName: description,
    types: prediction.types,
    description: description,
    latitude: 0,
    longitude: 0,
    market: "",
  } as IOptionalLatLngLocationRecord;
};

/** Applies specified weight to a substring
 * @param substr substring to match
 * @param mainStr string in which we are looking for matches
 * @returns array with matched text indexes styled with the specified weight
 */
export const weightSubstringMatch = (substr: string, mainStr: string) => {
  if (substr.length === 0)
    return (
      <span key={`no-search-yet`} className="matched" data-testid="matched-text">
        {mainStr}
      </span>
    );

  const cleanSubstr = substr.replace(/[^a-zA-Z0-9 ]/g, "");
  const strRegExp = new RegExp(cleanSubstr, "gi");
  const markMatchedText = mainStr
    // identify substring matches with special chars
    .replace(strRegExp, (match) => "#" + "$" + match + "#");
  // At this point, substring matches will be prefaced with $
  const splitStrByMatch = markMatchedText.split("#");
  const formatStringWithMatches = splitStrByMatch.map((s, index) =>
    s.charAt(0) === "$" ? (
      <span key={`s-${index}`} className="matched" data-testid="matched-text">
        {s.slice(1)}
      </span>
    ) : (
      s
    )
  );

  return formatStringWithMatches;
};

/**
 * Filters worksites for searchInput matches
 * @param worksites Array of worksites
 * @param searchInput The search string for autocomplete matching
 * @returns array of filtered worksites that match searchInput
 */
export const filteredWorksites = (worksites: IWorksite[], searchInput: string | null) => {
  const diacriticRegex = /\p{Diacritic}/gu;

  return !!searchInput
    ? search(searchInput?.normalize("NFD").replace(diacriticRegex, "") || "", worksites, {
        keySelector: (worksite) => worksite.name,
      })
    : worksites;
};

/**
 * Formats favorite property as ILocationRecord
 * @param arr Array of first three favorite properties
 * @returns array of ILocationRecords
 */
export const returnFavoritePlaces = (
  arr: IAutocompleteFavoriteProperty[],
  searchInput: string | null
) => {
  if (!arr.length) return [];

  const locationArr = arr
    .sort((a, b) => a.property.name.localeCompare(b.property.name))
    .map((p) => {
      const {
        property: { name, city, state, id, latitude, longitude, street, country },
      } = p;
      return {
        locationName: name,
        description: `${name}, ${city}, ${state || country || ""}`,
        streetAddress: street,
        city: city,
        region: state || country || "",
        id: `property.${id}`,
        latitude: +latitude,
        longitude: +longitude,
        types: ["favorite", "property"],
      } as ILocationRecord;
    });

  return searchInput
    ? (returnFuzzyFiltered(locationArr, searchInput) as ILocationRecord[])
    : locationArr;
};
/**
 * Formats preferred property as ILocationRecord
 * @param arr Array of first three favorite properties
 * @returns array of ILocationRecords
 */
export const returnPreferredProperties = (
  arr: IPreferredProperty[],
  searchInput: string | null
) => {
  if (!arr.length) return [];

  const locationArr = arr
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((p) => {
      const { name, city, state, id, latitude, longitude, street, country } = p;
      return {
        locationName: name,
        description: `${name}, ${city}, ${state || country || ""}`,
        streetAddress: street,
        city: city,
        region: state || country || "",
        id: `property.${id}`,
        latitude: +latitude,
        longitude: +longitude,
        types: ["preferred", "property"],
      } as ILocationRecord;
    });

  return searchInput
    ? (returnFuzzyFiltered(locationArr, searchInput) as ILocationRecord[])
    : locationArr;
};

/**
 *
 * @param recentSearches
 * @returns an array with recent searches without duplicates and with a max length of 3
 */
export const removeDupesAndTrim = (
  recentSearches: IRecentSearch[],
  arrayToReturn: "coldState" | "autocomplete"
) => {
  const uniqueSearches = recentSearches.filter((item, index) => {
    if (arrayToReturn === "coldState") {
      return (
        recentSearches.findIndex(
          (searchAux) =>
            searchAux.location !== "Current Map Area" &&
            searchAux.location === item.location &&
            searchAux.checkIn === item.checkIn &&
            searchAux.checkOut === item.checkOut
        ) === index
      );
    } else {
      return (
        recentSearches.findIndex(
          (searchAux) =>
            searchAux.location === item.location && searchAux.location !== "Current Map Area"
        ) === index
      );
    }
  });

  if (arrayToReturn === "coldState") {
    return uniqueSearches.length > 3 ? uniqueSearches.slice(0, 3) : uniqueSearches;
  }
  return uniqueSearches;
};

export const filterRecentSearches = (
  recentSearches: IRecentSearch[],
  searchInput: string | null
) => {
  const autocompleteRecentSearches = removeDupesAndTrim(recentSearches || [], "autocomplete");

  const recentArr = (
    returnFuzzyFiltered(autocompleteRecentSearches, searchInput) as IRecentSearch[]
  ).map((item) => ({ ...item, isAutocomplete: true }));

  return !!searchInput ? recentArr : autocompleteRecentSearches;
};

export const returnFuzzyFiltered = (
  arr: ILocationRecord[] | IRecentSearch[],
  searchInput: string | null
) => {
  const diacriticRegex = /\p{Diacritic}/gu;

  // use search from fast-fuzzy to return top two fuzzy matched results on location names
  return search(searchInput?.normalize("NFD").replace(diacriticRegex, "") || "", arr as object[], {
    keySelector: (obj) => {
      const typedObjKey = isRecentSearch(arr[0])
        ? (obj as IRecentSearch).location
        : (obj as ILocationRecord).locationName;
      return typedObjKey.normalize("NFD").replace(diacriticRegex, "");
    },
  });
};
