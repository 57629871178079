import { Button, Typography } from "@hotelengine/atlas-web";
import config from "config";
import * as Styled from "./styles";

const TripsError = () => (
  <Styled.TripsError data-testid="trips-error">
    <img src={`${config.cdnHost}/assets/trips/error-trips.svg`} alt="No Trips" />
    <Typography variant="heading/md" color="foregroundPrimary" data-testid="trips-error">
      There was an issue loading your trips
    </Typography>
    <Button
      id="book-trip"
      onClick={() => {
        location.reload();
      }}
    >
      Try again
    </Button>
  </Styled.TripsError>
);

export default TripsError;
