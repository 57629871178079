import type { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useEffect, useRef, useState } from "react";

import moment from "moment";

import { CustomFieldsForm } from "@hotel-engine/app/CustomFieldsForm";
// TODO: Update this antD, as soon as we have a replacement in Atlas. For now we will continue using the AntD
import { notification } from "@hotel-engine/common/Notifications";
import { useWindowSize } from "@hotel-engine/hooks";
import { toggleSalesForceChat } from "@hotel-engine/scripts/hooks";
import { Reservation } from "@hotel-engine/services";
import { isIErrorResponse } from "@hotel-engine/types/errors";
import type { ICustomField } from "@hotel-engine/types/customField";
import type { IReservationBase, IReservationCustomField } from "@hotel-engine/types/reservation";
import { Dimensions } from "@hotel-engine/utilities";

import * as Styled from "./styles";
import { Form, Formik } from "formik";
import { customFieldsSchema } from "@hotel-engine/app/CustomFieldsForm/helpers";
import { Button } from "@hotelengine/atlas-web";

const EditTripCustomFields: FunctionComponent<IEditTripCustomFieldsProps> = ({
  allCustomFields,
  forceOpen,
  isPreview,
  reservation,
  setReservation,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(forceOpen || false);
  const [editedFields, setEditedFields] = useState<ICustomField[]>(
    getInitialFormData(reservation.customFields, allCustomFields)
  );
  const [formHeight, setFormHeight] = useState(0);
  const { height } = useWindowSize();

  useEffect(() => {
    setEditedFields(getInitialFormData(reservation.customFields, allCustomFields));
  }, [reservation, allCustomFields]);

  const formRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (drawerVisible) {
      toggleSalesForceChat(false);
    } else {
      toggleSalesForceChat(!Dimensions.mobile);
    }
  }, [drawerVisible]);

  useEffect(() => {
    if (formRef.current) {
      setFormHeight(formRef.current.clientHeight);
    }
  }, []);

  const editable =
    reservation.status !== "cancelled" &&
    reservation &&
    moment(new Date()).isBefore(moment(new Date(reservation.checkOut)));

  if (!editable) {
    return null;
  }

  const _handleOpen = () => {
    setDrawerVisible(true);
  };

  const _handleClose = () => {
    setDrawerVisible(false);
    setEditedFields(getInitialFormData(reservation.customFields, allCustomFields));
  };

  let isFooterAbsolute = false;

  if (height && height * 0.5 <= formHeight) {
    isFooterAbsolute = true;
  }

  const handleSubmit = async (values) => {
    const editedCustomFields = values.customFields;
    const payload = editedCustomFields.map((f) => ({
      custom_field_id: f.id,
      value: f.value || "",
    }));

    try {
      const res = await Reservation.updateCustomFields(reservation.id, {
        contract_custom_fields: payload,
      });

      notification.open({
        message: "Edits to Custom Fields Saved",
      });

      const customFields: IReservationCustomField[] = res.map((item) => {
        return {
          id: item.id,
          contractId: reservation.id,
          customFieldId: item.customFieldId,
          name: item.customFieldName,
          value: item.value,
        };
      });

      setReservation({ ...reservation, customFields });
      setDrawerVisible(false);
      setEditedFields(getInitialFormData(customFields, allCustomFields));
    } catch (e: unknown) {
      if (isIErrorResponse(e)) {
        notification.error({
          message: "Error Saving Custom Fields",
          description: e.errors[0],
          duration: 10,
        });
      }
    }
  };

  const initialValues = { customFields: [...editedFields] };

  return (
    <>
      <Styled.EditLink
        variant={`link/${isPreview ? "sm" : "md"}`}
        color="foregroundPrimary"
        onClick={_handleOpen}
      >
        Edit company details
      </Styled.EditLink>

      <Styled.SideDrawer
        destroyOnClose
        visible={drawerVisible}
        onClose={_handleClose}
        title="Edit Custom Fields"
      >
        <div ref={formRef}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleSubmit}
            validationSchema={customFieldsSchema}
            validateOnMount
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <CustomFieldsForm />
                  <Styled.FooterContainer isFooterAbsolute={isFooterAbsolute}>
                    <Button variant="plain" disabled={isSubmitting} onClick={_handleClose}>
                      Cancel
                    </Button>
                    <Button type="submit" disabled={isSubmitting} isLoading={isSubmitting}>
                      Save
                    </Button>
                  </Styled.FooterContainer>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Styled.SideDrawer>
    </>
  );
};

const getInitialFormData = (customFields, allCustomFields) => {
  const data: {
    value?: string;
    withValues: ICustomField[];
    missingRequiredValues: ICustomField[];
    noValues: ICustomField[];
  } = { withValues: [], missingRequiredValues: [], noValues: [] };

  for (const field of allCustomFields) {
    const populated = customFields?.find((f) => field.id === f.customFieldId);
    if (populated) {
      data.withValues.push({ ...field, value: populated.value || undefined });
    } else if (field.required) {
      data.missingRequiredValues.push(field);
    } else {
      data.noValues.push(field);
    }
  }
  return [...data.withValues, ...data.missingRequiredValues, ...data.noValues];
};

export default EditTripCustomFields;

export interface IEditTripCustomFieldsProps {
  allCustomFields: ICustomField[];
  isPreview: boolean;
  reservation: IReservationBase;
  forceOpen: boolean;
  setReservation: Dispatch<SetStateAction<IReservationBase | null>>;
}
