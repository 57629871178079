import { useState } from "react";

import moment from "moment";

import { Modal } from "@hotel-engine/common/Modal";
import { useFormatDate } from "@hotel-engine/hooks/useFormatDate";
import { useCreateSalesforceCase } from "@hotel-engine/react-query/salesforceCase/useCreateSalesforceCase";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import { getAbbreviatedTimezone } from "@hotel-engine/utilities/helpers";

import * as Styled from "./styles";
import { Box, Button, Icon, RadioGroup, RadioGroupItem, Typography } from "@hotelengine/atlas-web";

const LateCheckInModal = ({
  contract,
  visible,
  onClose,
  refetchReservation,
}: ILateCheckInModalProps) => {
  const [checkInTime, setCheckInTime] = useState<string>();
  const [showSuccess, setShowSuccess] = useState(false);
  const requestLateCheckIn = useCreateSalesforceCase();
  const lateCheckInLoading = requestLateCheckIn.isLoading;

  let formattedCheckIn = useFormatDate(contract.checkIn, "ddd, MMM D, YYYY");

  if (!!contract.checkInTime) {
    const propertyTimezone = contract.propertyTimezone
      ? getAbbreviatedTimezone({
          date: contract.checkIn,
          timezoneDatabaseName: contract.propertyTimezone,
        })
      : "";

    formattedCheckIn += ` at ${moment(contract.checkInTime)
      .utc()
      .format("h:mma")} ${propertyTimezone}`;
  }

  const handleSubmit = () => {
    requestLateCheckIn.mutate(
      {
        contractNumber: contract.id,
        lateCheckin: { timeframe: checkInTime },
      },
      {
        onSuccess: () => {
          refetchReservation?.();
          setShowSuccess(true);
        },
        onError: (error) => {
          onClose();
          // Evaluate for an error response resulting from Salesforce not being sync'd on the contract //
          let errorTitle = "Error";
          let errorMsg = "Uh oh! Something went wrong.";

          const reason = error?.response?.data?.invalidParams?.[0]?.reasons;

          if (reason === "Not synced to salesforce") {
            errorTitle = "Just a sec!";
            errorMsg = "Please wait a few minutes before requesting a late check-in.";
          }

          Modal.error({
            title: errorTitle,
            content: <p>{errorMsg}</p>,
          });
        },
      }
    );
  };

  const confirmationModalBody = (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Styled.ModalHeader>
        <Icon name="circle-check--solid" color="accentGreen" size="lg" />
        <Typography
          variant="heading/lg"
          color="foregroundPrimary"
          data-testid="late-checkin-submitted"
        >
          Late check-in submitted!
        </Typography>
      </Styled.ModalHeader>
      <Typography variant="body/md" as="p" style={{ textAlign: "center" }}>
        {`Thank you for making a late check-in request for your booking at ${contract.propertyName}. Our Member Support team will contact the property to forward your request.`}
      </Typography>
      <Box style={{ width: "100%" }} marginTop={12}>
        <Button onClick={onClose} style={{ width: "100%" }}>
          Close
        </Button>
      </Box>
    </Box>
  );

  const lateCheckinOptions = () => {
    const timeFrames: ITimeFrame[] = [
      { title: "Late night", times: "10:00pm - 2:00am" },
      { title: "Early morning", times: "2:00am - 6:00am" },
    ];

    return timeFrames.map((timeFrame) => (
      <RadioGroupItem key={timeFrame.title} value={timeFrame.title + " " + timeFrame.times}>
        {timeFrame.title}
        <Typography variant="body/md" as="div">
          {timeFrame.times}
        </Typography>
      </RadioGroupItem>
    ));
  };

  return (
    <Modal onCancel={onClose} visible={visible} footer={null} title={null}>
      {!!showSuccess ? (
        confirmationModalBody
      ) : (
        <>
          <Styled.ModalHeader>
            <Typography variant="heading/lg" color="foregroundPrimary">
              Late check-in
            </Typography>
            <Typography variant="body/md" color="foregroundPrimary" as="p" marginTop={8}>
              Check-in: {formattedCheckIn}
            </Typography>
            <Typography variant="body/md" as="p">
              When should we expect you?
            </Typography>
          </Styled.ModalHeader>
          <RadioGroup
            name="lateCheckIn"
            onValueChange={(e) => setCheckInTime(e)}
            style={{ width: "100%" }}
            isBoxed
          >
            {lateCheckinOptions()}
          </RadioGroup>
          <Box style={{ width: "100%" }} marginTop={24}>
            <Button
              disabled={lateCheckInLoading || !checkInTime}
              onClick={handleSubmit}
              style={{ width: "100%" }}
            >
              Submit
            </Button>
          </Box>
        </>
      )}
    </Modal>
  );
};

export default LateCheckInModal;
export interface ITimeFrame {
  title: "Late night" | "Early morning";
  times: string;
}
export interface ILateCheckInModalProps {
  visible: boolean;
  onClose: () => void;
  contract: IReservationBase;
  refetchReservation?: () => void;
}
