import { isAfterCancelBy } from "@hotel-engine/utilities/helpers/flexHelpers";
import type { IModificationStatus, IReservationBase } from "@hotel-engine/types/reservation";
import { useBookingCancelCalculatorQuery } from "@hotel-engine/react-query/bookings/useBookingCancelCalculatorQuery";
import { useAppSelector } from "store/hooks";
import type { ICancelRooms } from "./useCancelFormValues";

export enum RefundType {
  OriginalFormOfPayment = "original_form_of_payment",
  TravelCredit = "travel_credit",
}

/** Returns the refund amount and type for all cancellation types (CancellationType)
 * @returns cancelRefundAmount
 * @returns cancelRefundType
 * @returns isError
 * @returns isLoading
 */
export const useCancelRefundCalculator = ({
  reservation,
  cancelRooms,
  modificationStatus,
}: {
  reservation: IReservationBase;
  cancelRooms: ICancelRooms;
  modificationStatus: IModificationStatus;
}) => {
  const user = useAppSelector((state) => state.Auth.user);

  const cancelCalcEnabled =
    !!reservation?.contractNumber &&
    !modificationStatus.isSubmitted &&
    !modificationStatus.isLoading &&
    reservation.roomCount > 1 &&
    Object.values(cancelRooms).some((room) => room.enabled);

  const {
    data: bookingCancelCalcData,
    isError,
    isLoading,
  } = useBookingCancelCalculatorQuery(
    {
      rooms: Object.entries(cancelRooms)
        .filter(([_, values]) => values.enabled)
        .map(([contractNumber]) => ({
          contractNumber,
        })),
      id: reservation?.contractNumber,
    },
    {
      enabled: !!cancelCalcEnabled,
    }
  );

  const legacyRefundType =
    isAfterCancelBy(reservation.cancelBy) && !user?.business.refundToOriginalFormOfPayment
      ? RefundType.TravelCredit
      : RefundType.OriginalFormOfPayment;

  const cancelRefundType =
    reservation.roomCount > 1 ? bookingCancelCalcData?.refundType : legacyRefundType;

  const cancelRefundAmount =
    reservation.roomCount > 1
      ? bookingCancelCalcData?.refundableAmount
      : reservation.cancellationRefundCalculatedAmount;

  return {
    cancelRefundAmount,
    cancelRefundType,
    isError,
    isLoading,
  };
};
