import type { IAutoCompleteMembers } from "@hotel-engine/types/batchEmailAutoComplete";
import type { IMemberRecord } from "@hotel-engine/types/member";
import storage from "@hotel-engine/storage";
import type { IEmailPillValue } from "@hotel-engine/app/BatchEmailInput";
import { capitalize } from "@hotel-engine/utilities/formatters/formatStrings";

export const mergeMembers = (
  membersData: IAutoCompleteMembers[],
  recentMembers: IAutoCompleteMembers[]
): IAutoCompleteMembers[] => {
  const membersDataMap: Record<string, number> = {};
  membersData.forEach((data) => {
    membersDataMap[data.email] = 1;
  });

  const uniqueRecentMembers = recentMembers.filter((member) => !membersDataMap[member.email]);

  return [...membersData, ...uniqueRecentMembers];
};

export const extractValidMembers = (
  data: IMemberRecord[] | undefined,
  recentMembers: IAutoCompleteMembers[]
): IAutoCompleteMembers[] | undefined => {
  const membersData = data
    ?.filter((item) => item.firstName && item.lastName)
    .map(({ email, firstName, lastName, role }) => ({
      email,
      firstName,
      lastName,
      role,
    }));
  if (!membersData) {
    return recentMembers;
  }

  return mergeMembers(membersData, recentMembers);
};

export const extractDisplayNamesFromEmail = (email: string) => {
  const names = email.split("@")[0];
  const [firstName, lastName] = names.split(".");

  return {
    firstName: capitalize(firstName),
    lastName: lastName ? capitalize(lastName) : undefined,
  };
};

export const enrichRecentTargetsWithMemberInfo = (
  rawRecentMembers: IAutoCompleteMembers[],
  data: IMemberRecord[] | undefined
): IAutoCompleteMembers[] => {
  const dataMap: Record<string, number> = {};
  data?.forEach((item, indx) => {
    dataMap[item.email] = indx;
  });

  const enrichedRecentMembers = rawRecentMembers.map((member) => {
    if (dataMap[member.email] !== undefined) {
      const existingMember = data?.[dataMap[member.email]];
      member.firstName = existingMember?.firstName;
      member.lastName = existingMember?.lastName;
      member.role = existingMember?.role;
      return member;
    }
    const { firstName, lastName } = extractDisplayNamesFromEmail(member.email);
    member.firstName = firstName;
    member.lastName = lastName;
    return member;
  });

  return enrichedRecentMembers;
};

export const getRecentEmailsFromLocalStorage = (key: string): string | null => {
  return storage.getItem(key);
};

export const saveEmailsToLocalStorage = (key: string, value: string) => {
  let newItem: string;
  const currentEmails = getRecentEmailsFromLocalStorage(key)?.split(",");
  const newEmails = value.split(",");
  if (!currentEmails) {
    newItem = newEmails.join(",");
    storage.setItem(key, newItem);
    return;
  }
  const uniqueEmailSet = new Set([...newEmails, ...currentEmails]);
  const uniqueEmails = Array.from(uniqueEmailSet).join(",");
  storage.setItem(key, uniqueEmails);
};

export const getNextRecentEmailTargets = (
  selectedEmails: IEmailPillValue[],
  recentMembers: IAutoCompleteMembers[],
  maxNumber: number
) => {
  const selectedEmailsMap: Record<string, number> = {};
  selectedEmails.forEach(({ value }) => {
    selectedEmailsMap[value] = 1;
  });

  const recentMembersNotSelected = recentMembers.filter(({ email }) => !selectedEmailsMap[email]);
  return recentMembersNotSelected.slice(0, maxNumber);
};
