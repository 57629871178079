import type { BoxProps } from "@hotelengine/atlas-web";
import { Box } from "@hotelengine/atlas-web";
import styled from "styled-components";

export const MultiLogoContainer = styled<React.FC<BoxProps>>(Box)<{ index: number }>`
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    border-radius: ${({ theme }) => theme.borderRadius[8]};
    border: ${({ theme }) => `${theme.borderWidth.normal} solid ${theme.colors.borderMinimal}}`};
    margin-left: ${({ index }) => ` ${-8 * index}px`};
    overflow: hidden;
`;
