import { Link } from "@hotel-engine/lib/react-router-dom";
import { Typography } from "@hotelengine/atlas-web";

import { routes } from "@hotel-engine/constants";
import { useTripExtensionContext } from "../../useTripExtensionContext";
import * as Styled from "./styles";

const MultiDateExtensionCopy = () => {
  const { isGroup, subsequentBookings, transformDate } = useTripExtensionContext();

  const extensionText = (booking) => {
    if (isGroup) {
      return `${booking.roomCount} ${booking.roomCount > 1 ? "rooms" : "room"} extended`;
    } else {
      return `Extended`;
    }
  };

  return (
    <Typography variant="body/sm" color="foregroundPrimary">
      {subsequentBookings?.map((booking) => {
        const urlPath = `${routes.itinerary}?contract_number=${booking.id}`;
        const checkOut = transformDate(booking.checkOut);

        return (
          <Styled.BookingLine key={booking.id} data-testid="subsequent-booking">
            {extensionText(booking)} to {<Link to={urlPath}>{checkOut}</Link>}
          </Styled.BookingLine>
        );
      })}
    </Typography>
  );
};

export default MultiDateExtensionCopy;
