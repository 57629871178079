import type { IFlightSegmentBaggage } from "@hotel-engine/types/itinerary";
import { Avatar, Box, Typography } from "@hotelengine/atlas-web";

import * as Styled from "./styles";
import { useCallback } from "react";

interface ITravelerDetailRowProps {
  label: string;
  value: string;
}

const TravelerDetailRow = ({ label, value }: ITravelerDetailRowProps) => {
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Typography variant="body/sm" color="foregroundSecondary">
        {label}
      </Typography>
      <Typography variant="body/sm" color="foregroundPrimary" justifyContent="flex-end">
        {value}
      </Typography>
    </Box>
  );
};

export interface IFormattedTravlerInfo {
  fullName: string;
  eTicketNumber: string;
  type: string;
  baggage: IFlightSegmentBaggage;
  seats: Array<{
    segmentName: string;
    seatDesignator: string;
  }>;
}

interface ITravelerInfoProps {
  traveler: IFormattedTravlerInfo;
  travelerIdx: number;
}

export const TravelerInfo = ({ traveler, travelerIdx }: ITravelerInfoProps) => {
  const parseBaggageData = useCallback((baggage: IFlightSegmentBaggage) => {
    const carryonCount = baggage.carryOn || 0;
    const checkedCount = baggage.checked || 0;

    if (!checkedCount && !carryonCount) {
      return "No baggage allowance for this flight";
    }

    if (!checkedCount && carryonCount) {
      return "Carry-on only";
    }

    if (checkedCount && !carryonCount) {
      return `${checkedCount} checked bags`;
    }

    return `${carryonCount} carry-on, ${checkedCount} checked bag${checkedCount > 1 ? "s" : ""}`;
  }, []);

  return (
    <Styled.FlightDetailsDashedContainer display="flex" flexDirection="column" gap={16}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        gap={12}
        alignItems="center"
      >
        <Avatar name={traveler.fullName} />
        <Typography variant="body/md">{traveler.fullName}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={8}>
        <TravelerDetailRow label={`Traveler ${travelerIdx + 1}`} value={traveler.type} />
        {traveler.seats.map((seatInfoForTravler) => {
          return (
            <TravelerDetailRow
              key={`${traveler.fullName}-seat-${seatInfoForTravler.segmentName}`}
              label={`Seat: ${seatInfoForTravler.segmentName}`}
              value={seatInfoForTravler.seatDesignator}
            />
          );
        })}
        <TravelerDetailRow label="E-ticket #" value={traveler.eTicketNumber} />
        <TravelerDetailRow label="Baggage" value={parseBaggageData(traveler.baggage)} />
      </Box>
    </Styled.FlightDetailsDashedContainer>
  );
};
