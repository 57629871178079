// TODO: We may want to move this to shared spaces.. I don't want duplicate type definitions
import type { IPaymentTypes } from "@hotel-engine/types/reservation";

import type {
  CartCustomFieldParam,
  ICurrencyValue,
  ISurcharge,
} from "pages/Checkout/types/Cart.types";

/*
Also, some of these types are shared with types from FlightCart.types.ts. Ultimately, this data
is coming from engine, so it may be a good idea to have a shared/common engine types file/directory.
*/
export interface IPriceLineItem {
  description: string;
  price: ICurrencyValue;
  group: string;
}

export interface IPrice {
  subTotal: ICurrencyValue;
  total: ICurrencyValue;
  taxes: ISurcharge[];
  fees: ISurcharge[];
  base: ICurrencyValue;
  lineItems: IPriceLineItem[];
}

export interface IPerUnitPrice {
  key: string;
  unit: string;
  price: IPrice;
}

export interface IPriceWithPerUnit {
  price: IPrice;
  perUnitPrice: IPerUnitPrice[];
}

export interface IACRISSCarClassificationCode {
  category: string;
  type: string;
  transmissionAndDrive: string;
  fuelAndAirConditioning: string;
}

export interface IACRISSPassengerVanCode {
  code: string;
}

export enum ACRISSCategory {
  MINI = "M",
  MINI_ELITE = "N",
  ECONOMY = "E",
  ECONOMY_ELITE = "H",
  COMPACT = "C",
  COMPACT_ELITE = "D",
  INTERMEDIATE = "I",
  INTERMEDIATE_ELITE = "J",
  STANDARD = "S",
  STANDARD_ELITE = "R",
  FULLSIZE = "F",
  FULLSIZE_ELITE = "G",
  PREMIMUM = "P",
  PREMIMUM_ELITE = "U",
  LUXURY = "L",
  LUXURY_ELITE = "W",
  OVERSIZE = "O",
  SPECIAL = "X",
}

export interface IVehicleDetails {
  carCode: IACRISSCarClassificationCode;
  vanCode: IACRISSPassengerVanCode;
  description: string;
  numberOfSeats?: number;
  numberOfBags?: number;
  numberOfDoors?: number;
  imageUrl: string;
}

export interface ICondition {
  beforeTimestamp?: string;
  afterTimestamp?: string;
  beforeEvent?: string;
  penalty?: ICurrencyValue;
  isAllowed?: boolean;
}

export interface IConditions {
  modifyConditions: ICondition[];
  cancelConditions: ICondition[];
}

export enum ActionAvailability {
  ACTION_AVAILABILITY_UNKNOWN = "ACTION_AVAILABILITY_UNKNOWN",
  ACTION_AVAILABILITY_UNAVAILABLE = "ACTION_AVAILABILITY_UNAVAILABLE",
  ACTION_AVAILABILITY_AVAILABLE = "ACTION_AVAILABILITY_AVAILABLE",
  ACTION_AVAILABILITY_AVAILABLE_VIA_VENDOR = "ACTION_AVAILABILITY_AVAILABLE_VIA_VENDOR",
  ACTION_AVAILABILITY_AVAILABLE_VIA_SUPPORT = "ACTION_AVAILABILITY_AVAILABLE_VIA_SUPPORT",
}

export enum CarRentalVendorAirportAccessType {
  CAR_RENTAL_VENDOR_LOCATION_TYPE_UNKNOWN = "CAR_RENTAL_VENDOR_LOCATION_TYPE_UNKNOWN",
  CAR_RENTAL_VENDOR_LOCATION_TYPE_IN_AIRPORT = "CAR_RENTAL_VENDOR_LOCATION_TYPE_IN_AIRPORT",
  CAR_RENTAL_VENDOR_LOCATION_TYPE_SHUTTLE_FROM_AIRPORT = "CAR_RENTAL_VENDOR_LOCATION_TYPE_SHUTTLE_FROM_AIRPORT",
  CAR_RENTAL_VENDOR_LOCATION_TYPE_CITY = "CAR_RENTAL_VENDOR_LOCATION_TYPE_CITY",
}

export enum CarNoticeType {
  NOTICE_TYPE_UNKNOWN = "NOTICE_TYPE_UNKNOWN",
  NOTICE_TYPE_IMPORTANT_INFORMATION = "NOTICE_TYPE_IMPORTANT_INFORMATION",
  NOTICE_TYPE_RENTAL_POLICY = "NOTICE_TYPE_RENTAL_POLICY",
  NOTICE_TYPE_FUEL_POLICY = "NOTICE_TYPE_FUEL_POLICY",
  NOTICE_TYPE_CANCELLATION_POLICY = "NOTICE_TYPE_CANCELLATION_POLICY",
}

export interface IAvailableActions {
  cancel: ActionAvailability;
  modify: ActionAvailability;
}

export interface ITextNotice {
  title?: string;
  text: string;
}

export interface ICarNotice {
  type: CarNoticeType;
  textNotice?: ITextNotice;
}
export interface ICarNotices {
  notices: ICarNotice[];
}

export interface ICarContractDetails {
  price: IPriceWithPerUnit;
  vehicleDetails: IVehicleDetails;
  pickupCarRentalVendorLocationId: string;
  pickupLocationBusinessHoursOnDayOfPickup?: ICarRentalVendorHoursOperationDetails;
  dropoffCarRentalVendorLocationId: string;
  dropoffLocationBusinessHoursOnDayOfDropoff?: ICarRentalVendorHoursOperationDetails;
  conditions: IConditions;
  pickupAt: string;
  dropoffAt: string;
  availableActions?: IAvailableActions;
  mileageDetails?: {
    limitedWithNoDetails: unknown | null;
    unlimited: unknown | null;
  };
  pickupLocation: {
    carRentalVendor: ICarRentalVendorDetails;
  };
  dropoffLocation: {
    carRentalVendor: ICarRentalVendorDetails;
  };
  cancelType: string;
  notices: ICarNotices;
}

export interface ICarRentalVendorHoursOperationDetails {
  isClosed?: boolean;
  isOpen24Hours?: boolean;
  windows?: {
    windows?: Array<{
      openTime: string;
      closeTime: string;
    }>;
  };
}

export interface ICarRentalVendorDetails {
  id: string;
  vendorCode: string;
  vendorName: string;
  address: IPostalAddress;
  airportAccessibility: {
    iataAirportCode: string;
    locationType: CarRentalVendorAirportAccessType;
  } | null;
  vendorLogoUrl: string;
  phoneNumber?: string;
}

export interface ICarsCartItem {
  offerToken: string;
  rateOffer: ICartCarRateOffer;
  driverAge: number;
}

export interface ICartCarRateOffer {
  requiresDriverAge?: boolean;
  contractDetails: ICarContractDetails;
}

export interface IPostalAddress {
  recipients: string[];
  organization: string;
  addressLine: string[];
  administrativeArea?: string;
  locality?: string;
  postalCode?: string;
  countryCode: string;
}

export interface ICarRentalVendorAirportAccessibility {
  iataAirportCode: string;
  locationType: CarRentalVendorAirportAccessType;
}

export interface ICarRentalVendor {
  id: string;
  vendorCode: string;
  vendorName: string;
  address: IPostalAddress;
  airportAccessibility: ICarRentalVendorAirportAccessibility;
  vendorLogoUrl: string;
}

export interface ICarsCartSummary {
  total: number;
  subtotal: number;
  taxes: number;
  savings: number;
  convenienceFee: number;
  incidentalsFee: number;
  postpaidFee: number;
  prepaidFee: number;
  publicFee: number;
  flexCost: number;
}

export type CreatePrimaryDriverParam = {
  driverId: number;
  driverType: "Guest" | "User";
  driverAge?: number;
  loyaltyNumbers: Array<{ vendorCode: string; programNumber: string }>;
};

export type CreateCarReservationParams = {
  bookForUserId: number;
  isBillable?: boolean;
  arrivalFlightNumber: string;
  primaryDriver: CreatePrimaryDriverParam;
  contractCustomFieldsAttributes: Array<CartCustomFieldParam>;
  paymentTypes: IPaymentTypes[];
  userAgent: string;
};
