import config from "config";

import { useApi } from "../useApi";
import type { IErrorResponse } from "../../types/errors";
import { endpoints } from "../constants";
import { useDownloadQuery } from "../useDownloadQuery";

export interface IDocumentParams {
  contractNumber: string;
  documentType: "itinerary" | "flightItinerary" | "carItinerary" | "folio";
}

export const useDownloadItineraryDocument = (params: IDocumentParams) => {
  const get = useApi("get");

  const fileName = `${params.documentType}_${params.contractNumber}`;
  const flightCarPath = {
    flightItinerary: endpoints.flightBookings,
    carItinerary: endpoints.carBookings,
  };
  const itineraryUrl =
    params.documentType === "itinerary" || params.documentType === "folio"
      ? `${config.apiHostV2}/${endpoints.contracts}/${params.contractNumber}/${params.documentType}.pdf`
      : `${config.apiHostV2}/${flightCarPath[params.documentType]}/${
          params.contractNumber
        }/download_itinerary`;

  const queryFn = () => get<string>(itineraryUrl, {}, { responseType: "blob" });
  const queryKey = [itineraryUrl, fileName];

  return useDownloadQuery<IErrorResponse>(queryKey, queryFn, {
    fileName,
    mediaType: "pdf",
    // Setting false here allows us to manually trigger the get on click using refetch
    enabled: false,
  });
};
