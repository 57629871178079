import Button from "@hotel-engine/common/Button";
import styled from "styled-components";
import { colors, screenReaderOnly, zIndex } from "@hotel-engine/styles";

export const SkipToLink = styled(Button)`
  position: fixed;
  transition: none;
  z-index: ${zIndex.modal + zIndex.below};
  margin: 3px;

  &:hover,
  &:focus {
    border-color: ${colors.retroBlue};
    color: ${colors.retroBlue};
  }

  &:not(:focus) {
    ${screenReaderOnly};
  }
`;
