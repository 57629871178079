import { useFormikContext } from "formik";
import { useMemo } from "react";

import { useAppSelector } from "store/hooks";
import type { ISearchFormValues } from "@hotel-engine/types/search";

import { Select, SelectItem } from "@hotelengine/atlas-web";

const STATIC_OPTIONS = [1, 5, 10, 15, 25, 50];

export const Radius = ({
  handleAmplitude,
}: {
  handleAmplitude?: (radius: number) => void;
}) => {
  const user = useAppSelector((state) => state.Auth.user);

  const {
    setFieldValue,
    values: { radius },
  } = useFormikContext<ISearchFormValues>();

  const radiusOptions = useMemo(() => {
    const filteredOptions = [...STATIC_OPTIONS, radius].reduce<number[]>((acc, item) => {
      if (item) acc.push(item);

      return acc;
    }, []);

    return Array.from(new Set(filteredOptions)).sort((a, b) => a - b);
  }, [radius]);

  const useMetric = user?.distanceUnit === "km";

  const handleClickRadius = (rad: string) => {
    const numberRad = Number(rad);
    handleAmplitude?.(numberRad);
    setFieldValue("radius", numberRad);
    setFieldValue("radiusUpdated", true);
  };

  const radiusToDisplay = (rad: number | null) => {
    return useMetric
      ? `${rad ?? 0} kilometer${rad === 1 ? "" : "s"}`
      : `${rad ?? 0} mile${rad === 1 ? "" : "s"}`;
  };

  return (
    <Select
      size="lg"
      value={String(radius)}
      onValueChange={handleClickRadius}
      data-testid="radius-button"
      aria-label="Search Radius"
      style={{ width: "100%" }}
    >
      {radiusOptions.map((item, index) => (
        <SelectItem
          key={`${item}${index}`}
          leadingIcon="map"
          value={String(item)}
          data-testid={`radius-${item}`}
        >
          {radiusToDisplay(item)}
        </SelectItem>
      ))}
    </Select>
  );
};
