import { type UseMutationOptions, useMutation } from "react-query";

import { useApi } from "@hotel-engine/react-query/useApi";
import type { IErrorResponse } from "../../types/errors";
import { endpoints } from "@hotel-engine/react-query/constants";

export interface IShareItineraryParams {
  booking_number: string;
}

export interface IShareItineraryBody {
  email: string;
  subject: string;
  note: string;
  hideRates: boolean;
  additionalEmails: string[];
  bookingPath: keyof typeof endpoints;
}

export const useShareItineraryMutation = (
  params: IShareItineraryParams,
  options?: UseMutationOptions<void, IErrorResponse, IShareItineraryBody>
) => {
  const post = useApi("post");

  return useMutation(
    [params],
    ({ bookingPath, ...body }) =>
      post(`${endpoints[bookingPath]}/${params.booking_number}/share_itinerary`, body),
    options
  );
};
