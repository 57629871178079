import styled from "styled-components";

const LoyaltyReminderContainer = styled.div`
  padding: ${({ theme }) => theme.spacing[16]};
  margin: ${({ theme }) => theme.spacing[20]} 0 ${({ theme }) => theme.spacing[8]};
  display: flex;
  column-gap: ${({ theme }) => theme.spacing[12]};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  background-color: ${({ theme }) => theme.colors.accentPurpleLightest};
`;

const LoyaltyReminderContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export { LoyaltyReminderContainer, LoyaltyReminderContent };
