import styled, { css } from "styled-components";

export const carsSearchHeaderHeightDesktop = 88;

export const HeaderContainer = styled.div<{ $compact?: boolean }>`
  display: grid;
  grid-template-columns: 1fr min-content min-content auto;
  gap: ${({ theme }) => theme.spacing[8]};
  align-items: center;
  width: 100%;
  background-color: #FFF;
  border-radius: ${({ theme }) => theme.borderRadius.pill};
  position: relative;

  ${({ $compact }) =>
    !$compact &&
    css`
      border-radius: 0;
      grid-template-columns: 1fr min-content min-content auto;
      padding: 0 ${({ theme }) => theme.spacing[32]};
      min-height: ${carsSearchHeaderHeightDesktop}px;
      max-height: ${carsSearchHeaderHeightDesktop}px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.borderMinimal};
    `};
`;

export const InputWrapper = styled.div<{ $disabled?: boolean; $compact?: boolean }>`
  ${({ $compact }) => css`
    &.age { width: ${$compact ? "90px" : "auto"}; }
    &.date-picker {
      >label {
        padding-left: ${$compact ? "0px" : "16px"};
      }
    }
    &.time-picker {
      width: ${$compact ? "100px" : "auto"};
      .last {
        padding-left: ${$compact ? "0px" : "16px"};
      }
    }
  `};

  ${({ $compact }) => !$compact && css`.ant-input-number-input { padding-left: 40px; }`}

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      opacity: 0.7;
  `};

  &.age input {
    min-width: 32px;
    padding-right: ${({ theme }) => theme.spacing[8]};
  }
`;

export const InputItem = styled.label<{
  type?: "TIME" | "DATE";
  mobile?: boolean;
  marginTop?: string;
  noIconLeft?: boolean;
  small?: boolean;
  error?: boolean;
}>`
  border: 1px solid ${({ theme, error }) =>
    error ? theme.colors.accentRed : theme.colors.inputBorder};
  border-radius: ${({ theme, mobile }) =>
    mobile ? theme.borderRadius.lg : theme.borderRadius.full};
  background-color: ${({ theme }) => theme.colors.inputBackground};
  height: ${({ mobile, small }) => (small ? "40px" : mobile ? "56px" : "48px")};
  width: ${({ mobile }) => mobile && "100%"};
  margin-top: ${({ marginTop }) => marginTop};
  padding-left: ${({ noIconLeft }) => (noIconLeft ? "0" : "16px")};
  display: flex;
  align-items: center;
  flex-grow: ${({ mobile }) => (mobile ? 0 : 1)};

  .ant-select-lg input {
    text-overflow: ellipsis;
    width: 100%;
  }

  .ant-input-number-input {
    font-size: 16px;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-select-selection,
  .ant-input-number,
  .ant-input-number-focused,
  .ant-input-number-input,
  input {
    background: transparent;
    border: none;
    box-shadow: none;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  &:hover {
    &,
    &.results-search {
      border: 1px solid ${({ theme }) => theme.colors.uiPrimary};
    }
  }
  &:focus-within {
    &,
    &.results-search {
      position: relative;
      z-index: ${({ theme }) => theme.zIndex.popover};
      box-shadow: 0 0 0 1px white, 0 0 0 2px ${({ theme }) => theme.colors.uiPrimary};
    }
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
  }
`;

export const InputGroup = styled.div<{ $compact?: boolean; mobile?: boolean }>`
  ${({ mobile, theme }) => {
    return !mobile
      ? css`
      display: grid;
      grid-template-columns: 1fr 1fr;

      ${InputItem} {
        border-radius: 0;
      }

      .ant-select-search__field:has(input:focus-visible) {
        box-shadow: none;
      }

      .ant-select-selection__rendered {
        display: flex;
        align-items: center;
      }

      ul {
        flex-grow: 1;
        display: flex;
        align-items: stretch;
      }

      .ant-select-auto-complete.ant-select .ant-select-search--inline{
        float: none;
        position: unset;
        display: flex;
        align-items: stretch;
      }

      .first {
        &:not(:focus-within) {
          border-right: 0;
        }
        border-radius: ${`${theme.borderRadius.full} 0 0 ${theme.borderRadius.full}`};
        width: 100%;
      }

      .last {
        border-radius: ${`0 ${theme.borderRadius.full} ${theme.borderRadius.full} 0`};
      }
    `
      : css`
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 0;
        width: 100%;

        ${InputItem}:first-child {
          border-right: ${!mobile && 0};
          border-bottom: ${mobile && 0};
          border-radius: ${`${theme.borderRadius.lg} ${theme.borderRadius.lg} 0 0`};
          width: 100%;
        }

        ${InputItem}:last-child {
          border-left: ${!mobile && 0};
          border-radius: ${` 0 0 ${theme.borderRadius.lg} ${theme.borderRadius.lg}`};
        }
      `;
  }}
`;

export const InputIconContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing[4]} !important;
  line-height: unset !important;
  align-self: stretch;
  display: flex;
  align-items: center;
`;

export const Adornment = styled.span`
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
`;
