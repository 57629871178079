import { useGlobalTheme } from "@hotel-engine/contexts/GlobalThemeContext";
import type { IconProps } from "@hotelengine/atlas-web";
import { Box } from "@hotelengine/atlas-web";
import type { MapPosition } from "../../types";
import GoogleMapsCarLocationMarker from "../Markers/CarLocationMarker";
import GoogleMap from "../..";

type GoogleLocationDetailMapProps = {
  /** Map center to be passed to the GoogleMap */
  mapCenter: MapPosition;
  /** Icon to use depending on whether its pick up or dropoff */
  icon: IconProps["name"];
};

const GoogleLocationDetailMap = ({ mapCenter, icon }: GoogleLocationDetailMapProps) => {
  const { tokens } = useGlobalTheme();

  return (
    <Box style={{ height: 200, overflow: "hidden" }}>
      <GoogleMap
        controlOptions={{
          showExpand: true,
          showType: false,
          showZoom: false,
        }}
        mapOptions={{
          center: mapCenter,
          draggable: false,
        }}
        customMapStyle={{ borderRadius: tokens.borderRadius[24], maxHeight: 200 }}
      >
        {(map) => (
          <GoogleMapsCarLocationMarker
            map={map as google.maps.Map}
            position={mapCenter}
            icon={icon}
          />
        )}
      </GoogleMap>
    </Box>
  );
};

export default GoogleLocationDetailMap;
