import styled from "styled-components";

export const Modal = styled.div<{ open: boolean }>`
  position: fixed;
  transition: all 1s ease-in-out;

  opacity: ${({ open }) => (open ? 1 : 0)};
  top: ${({ open }) => (open ? 0 : "100dvh")};
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndex.modal};
  width: 100dvw;
  height: 100dvh;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #fff;
`;

export const ModalHead = styled.div<{ borderBottom?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing[20]} ${theme.spacing[24]}`};
  border-bottom: ${({ theme, borderBottom }) =>
    borderBottom && `solid 2px ${theme.colors.inputBackground}`}
`;

export const ModalBody = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing[20]};
  padding: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[12]}`};
  overflow-y: scroll;
`;
