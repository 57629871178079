import { Icon, Typography } from "@hotelengine/atlas-web";
import * as Styled from "./styles";

const CancelledBanner = ({
  isLodging,
  isRefresh = false,
  isPartiallyCancelled = false,
}: { isLodging: boolean; isRefresh?: boolean; isPartiallyCancelled?: boolean }) => {
  const lodgingCopy = isPartiallyCancelled
    ? "Part of your stay has been cancelled."
    : "Your stay has been cancelled.";

  return (
    <Styled.CancelledBannerContainer data-testid="trip-cancelled-banner" $isRefresh={isRefresh}>
      <Typography
        variant="body/sm-strong"
        color={isRefresh ? "foregroundPrimary" : "foregroundDestructive"}
        display="flex"
        alignItems="center"
        gap={8}
      >
        {!!isRefresh && <Icon size="sm" name="hexagon-exclamation" color="foregroundDestructive" />}
        {isLodging ? lodgingCopy : "This trip has been cancelled"}
      </Typography>
    </Styled.CancelledBannerContainer>
  );
};

export default CancelledBanner;
