import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import GoogleMap from "@hotel-engine/app/GoogleMap";
import GoogleMapsPropertyMarker from "@hotel-engine/app/GoogleMap/components/Markers/GoogleMapsPropertyMarker";
import PropertyMarker from "@hotel-engine/app/GoogleMap/components/Markers/PropertyMarker";
import { GoogleMapStaticImage } from "@hotel-engine/app/GoogleMapStaticImage";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useWindowSize } from "@hotel-engine/hooks";
import { Box } from "@hotelengine/atlas-web";
import { sidebarMdDesktopWidth } from "../../../../../../pages/Trips/components/TripsItineraryPreviewPanel/styles";
import { breakpoints } from "../../../../../styles/variables/sizes";
import { MapStreet } from "./MapStreet";

export interface IGooglePreviewMapProps {
  handleView: () => void;
  longitude: number;
  latitude: number;
  street: string;
  city: string;
  state: string;
  postalCode: string;
}

const GooglePreviewMap = ({
  handleView,
  longitude,
  latitude,
  street,
  city,
  state,
  postalCode,
}: IGooglePreviewMapProps) => {
  const context = useModificationsContext();
  const { width, desktop } = useWindowSize();
  const showStaticMap = useIsFeatureFlagOn("members-show-static-itinerary-map");

  if (!!context?.state?.modificationView) return null;

  if (showStaticMap) {
    const isLargeDesktop = width >= breakpoints.xxxxl;
    const desktopWidth = isLargeDesktop ? 500 : sidebarMdDesktopWidth;

    return (
      <GoogleMapStaticImage
        location={{ longitude, latitude }}
        zoom={13}
        size={{ width: desktop ? desktopWidth : width, height: 260 }}
        title="Property Location"
      >
        <GoogleMapStaticImage.Marker>
          <PropertyMarker />
        </GoogleMapStaticImage.Marker>

        <MapStreet
          handleView={handleView}
          street={street}
          city={city}
          state={state}
          postalCode={postalCode}
        />
      </GoogleMapStaticImage>
    );
  }

  return (
    <Box
      position="relative"
      zIndex="backdropAbove"
      style={{
        height: 260,
        width: "100%",
      }}
    >
      <GoogleMap
        mapOptions={{
          center: {
            lat: latitude,
            lng: longitude,
          },
          draggable: false,
        }}
        controlOptions={{
          showExpand: false,
          showSearchControls: false,
          showType: false,
          showZoom: false,
          isMapExpanded: false,
        }}
        dataTestId="trips-preview-panel-map"
      >
        {(map) => (
          <GoogleMapsPropertyMarker
            map={map as google.maps.Map}
            position={{
              lat: latitude,
              lng: longitude,
            }}
          />
        )}
      </GoogleMap>
      <MapStreet
        handleView={handleView}
        street={street}
        city={city}
        state={state}
        postalCode={postalCode}
      />

      <div data-testid="map test" style={{ display: "none" }} />
    </Box>
  );
};
export default GooglePreviewMap;
