import { Icon, Typography } from "@hotelengine/atlas-web";
import * as Styled from "./styles";

interface IValidationErrorProps {
  isMobileScreen: boolean;
  error?: string;
}

export const ValidationError = ({ isMobileScreen, error }: IValidationErrorProps) => {
  return (
    <>
      {!!isMobileScreen && !!error && (
        <Typography variant="body/xs" color="accentRed" marginTop={8} marginLeft={4}>
          <Icon name="hexagon-exclamation" size="sm" color="accentRed" /> {error}
        </Typography>
      )}
      {!isMobileScreen && !!error && (
        <Styled.ValidationErrorAlert title={error} sentiment="critical" size="sm" />
      )}
    </>
  );
};
