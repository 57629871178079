import styled from "styled-components";

export const SectionDivider = styled.hr`
  width: 100%;
  border-top: ${({ theme }) => `1px dashed ${theme.colors.borderSubtle}`};
`;

export const AlertContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[24]};
  border: ${({ theme }) => `1px solid ${theme.colors.sentimentImportantSubtle}`};
  border-radius: ${({ theme }) => theme.borderRadius[8]};
  min-height: 49px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconHoverContainer = styled.div`
  padding: ${({ theme }) => theme.spacing[4]};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius[8]};
  transition: 0.2s ease all;
`;

export const ToggleContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing[12]} ${({ theme }) => theme.spacing[16]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover {
    ${IconHoverContainer} {
      background-color: ${({ theme }) => theme.colors.backgroundPrimaryHover};
      transition: 0.2s ease all;
    }
  }
`;

export const ListContainer = styled.ul`
  padding: ${({ theme }) => theme.spacing[16]} ${({ theme }) => theme.spacing[16]} ${({ theme }) =>
    theme.spacing[16]} ${({ theme }) => theme.spacing[40]};
  width: 100%;
  text-align: left;
  margin-bottom: 0;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing[16]};
`;
