import styled, { css } from "styled-components";

import Button from "@hotel-engine/common/Button";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { buttonReset, colors } from "@hotel-engine/styles";

export interface IDropdown {
  isOpen: boolean;
}

export const PersonalAccountButton = styled(Button)`
  ${buttonReset};
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  span {
    ${typographyMixin("body-medium")}
    margin-left: 9px;
  }

  img,
  [role="img"] {
    width: 14px;
    height: 14px;
  }
`;

export const DropdownWrapper = styled.div<IDropdown>(({ isOpen }) => [
  css`
    background: white;
    transition: 0.5s ease-in-out;
    left: 0;
    position: absolute;
    top: 50px;
    width: 100%;
    z-index: 15 /* disable theming (do not replicate) */;
    max-height: 0px;
    height: 0px;
    pointer-events: inherit;
    display: inline-block;
    overflow: hidden;
  `,
  isOpen &&
    css`
      height: calc(100vh - 50px);
      max-height: 100%;
      overflow-y: auto;
    `,
]);

export const MenuSection = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${colors.azureHint};
  transition: 0.5s ease-in-out;

  &:last-child {
    border-bottom: none;
  }

  h5 {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 16px;
  }

  > a {
    all: unset;
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 40px;

    span {
      font-size: 14px;
      margin-left: 9px;
    }

    img,
    [role="img"] {
      width: 14px;
      height: 14px;
    }
  }
`;

export const SignOutButton = styled.button`
  ${buttonReset}
  > span {
    margin-left: 9px;
  }
`;

export const MenuIconWrap = styled.div`
  display: flex;
  position: relative;
`;

export const MenuIcon = styled.button`
  ${buttonReset}
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 20px;
  height: 14px;
  position: relative;
  transition: 0.5s ease-in-out;
  margin-left: 9px;
  transition: 0.5s ease-in-out;

  > span {
    background: ${colors.black};
    display: block;
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0;
    transition: 0.5s ease-in-out;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 50%;
    }

    &:nth-child(3) {
      top: 50%;
    }

    &:nth-child(4) {
      top: 100%;
    }
  }

  &.open {
    > span {
      &:nth-child(1),
      &:nth-child(4) {
        top: 4px;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }
`;

export const Indicator = styled.div`
  background-color: ${colors.red[400]};
  border: 1px solid ${colors.white};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.x100};
  height: 12px;
  position: absolute;
  top: -12px;
  right: -8px;
  width: 12px;
`;
