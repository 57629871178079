import styled from "styled-components";

import Image from "@hotel-engine/common/Image";
import { Link } from "@hotel-engine/lib/react-router-dom";

const PropertyContainer = styled.div<{ $isPreview: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
  padding: ${({ $isPreview }) => `${$isPreview ? 0 : 12}px`} 0 0;
`;

const PropertyImage = styled(Image)`
  width: 100%;
  max-height: 206px;
  object-position: "center";
  border-radius: ${({ theme }) => theme.borderRadius[16]};
  cursor: pointer;
`;

const PropertyName = styled(Link)`
  width: 100%;
`;

export { PropertyContainer, PropertyName, PropertyImage };
