import type { IResult } from "@hotel-engine/types/search";
import { createSelector } from "@reduxjs/toolkit";
import type { IReduxStore } from "store";
import { getShortClientProgramName } from "store/Search/SearchResults/helpers";
import { selectSpecificSearchDismissed } from "../SearchPage/selectors";

export const selectSpecificSearchResult = (state: IReduxStore) =>
  state.SearchResults.specificResult;

const selectPropertyIdsToExclude = createSelector(
  [selectSpecificSearchResult, selectSpecificSearchDismissed],
  (specificResult, specificSearchDismissed) => {
    const idsToExclude: number[] = [];

    if (!!specificResult && !specificSearchDismissed) {
      idsToExclude.push(specificResult.propertyId);
    }
    return idsToExclude;
  }
);

export const selectSearchId = (state: IReduxStore) => state.SearchResults.id;

export const selectResults = (state: IReduxStore) => state.SearchResults.results;

export const selectResultsPropertyIds = createSelector([selectResults], (results) =>
  Object.keys(results).map((propertyId) => Number(propertyId))
);

export const selectResultsCount = createSelector(
  selectResultsPropertyIds,
  (propertyIds) => propertyIds.length
);

export const selectResultList = createSelector(
  selectResults,
  (results) => Object.values(results) as IResult[]
);

export const selectStreamLoading = (state: IReduxStore) => state.SearchResults.streamLoading;

export const selectIsSearchLoading = (state: IReduxStore) =>
  state.SearchResults.specificSearchLoading || state.SearchResults.streamLoading;

export const selectSuppliers = (state: IReduxStore) => state.SearchResults.suppliers;

export const selectStreamError = (state: IReduxStore) => state.SearchResults.streamError;

export const selectUnhiddenResultsCount = createSelector(
  selectResultList,
  (resultList) => resultList.filter((result) => !result.hidden).length
);

const selectOriginalVisibleResultIds = (state: IReduxStore) => state.SearchResults.visibleResultIds;
export const selectVisibleResultIds = createSelector(
  [selectOriginalVisibleResultIds, selectPropertyIdsToExclude],
  (visibleResultIds, idsToExclude) => visibleResultIds.filter((id) => !idsToExclude.includes(id))
);

export const selectVisibleResultsCount = createSelector(
  selectVisibleResultIds,
  (visibleResultIds) => visibleResultIds.length
);

const selectOriginalVisibleHiddenResultIds = (state: IReduxStore) =>
  state.SearchResults.visibleHiddenResultIds;
export const selectVisibleHiddenResultIds = createSelector(
  [selectOriginalVisibleHiddenResultIds, selectPropertyIdsToExclude],
  (visibleHiddenResultIds, idsToExclude) =>
    visibleHiddenResultIds.filter((id) => !idsToExclude.includes(id))
);

export const selectVisibleHiddenResultsCount = createSelector(
  selectVisibleHiddenResultIds,
  (visibleHiddenResultIds) => visibleHiddenResultIds.length
);

export const selectPropertyIdsHidden = (state: IReduxStore) =>
  state.SearchResults.propertyIdsHidden;

export const selectPropertyIdsPreferred = (state: IReduxStore) =>
  state.SearchResults.propertyIdsPreferred;

export const selectSpecificSearchLoading = (state: IReduxStore) =>
  state.SearchResults.specificSearchLoading;

export const selectSpecificSearchError = (state: IReduxStore) =>
  state.SearchResults.specificSearchError;

export const selectEffectiveSearchRadius = (state: IReduxStore) =>
  state.SearchResults.effectiveSearchRadius;

export const selectEffectiveSearchRadiusUnit = (state: IReduxStore) =>
  state.SearchResults.effectiveSearchRadiusUnit;

export const selectSearchRadius = (state: IReduxStore) => state.SearchResults.radius;

export const selectPreferedPropertyIds = (state: IReduxStore) =>
  state.SearchResults.propertyIdsPreferred;

export const selectVisibleResults = createSelector(
  [selectResults, selectVisibleResultIds],
  (results, visibleResultIds) => visibleResultIds.map((id) => results[id])
);

export const selectSpotlightPropertyId = createSelector(
  [selectVisibleResults],
  (visibleResults) => visibleResults.find(({ spotlight }) => spotlight)?.propertyId
);

export const selectVisibleHiddenResults = createSelector(
  [selectResults, selectVisibleHiddenResultIds],
  (results, visibleHiddenResultIds) => visibleHiddenResultIds.map((id) => results[id])
);

export const selectAllVisibleResultIds = createSelector(
  [
    selectOriginalVisibleResultIds,
    selectOriginalVisibleHiddenResultIds,
    selectPropertyIdsToExclude,
  ],
  (visibleResultIds, visibleHiddenResultIds, idsToExclude) =>
    visibleResultIds
      .concat(visibleHiddenResultIds)
      .filter((propertyId) => !idsToExclude.includes(propertyId))
);

export const selectAllVisibleResultsCount = createSelector(
  [selectAllVisibleResultIds],
  (allVisibleResultIds) => allVisibleResultIds.length
);

export const selectAllVisibleResults = createSelector(
  [selectResults, selectAllVisibleResultIds],
  (results, allVisibleResultIds) => allVisibleResultIds.map((resultId) => results[resultId])
);

export const selectClientProgramName = createSelector([selectResultList], (results: IResult[]) => {
  const programFound = results.find((result) => result.clientProgramName);

  return programFound ? getShortClientProgramName(programFound.clientProgramName) : null;
});

export const selectHasResults = createSelector(
  selectAllVisibleResultIds,
  (visibleResultIds) => !!visibleResultIds.length
);

export const selectOutOfPolicyCount = (state: IReduxStore) => state.SearchResults.outOfPolicyCount;

export const selecteRateRefreshFailed = (state: IReduxStore) =>
  state.SearchResults.rateRefreshFailed;

export const selecteRateRefreshLoading = (state: IReduxStore) =>
  state.SearchResults.rateRefreshLoading;

export const selecteRateRefreshSuccess = (state: IReduxStore) =>
  state.SearchResults.rateRefreshSuccess;

export const selectIsMapSearch = (state: IReduxStore) => state.SearchResults.isMapSearch;
