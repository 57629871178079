import { Box, Button, DialogIcon, Icon, Typography } from "@hotelengine/atlas-web";
import { useBaseModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";

import { useTripsContextLegacy } from "pages/Trips/contextLegacy";
import { useTripsContext } from "pages/Trips/context";

import ModalWrapper from "../ModalWrapper";
import { RefundInfo } from "./RefundInfo";
import * as Styled from "./styles";
import { useStatusModalCopy } from "./useStatusModalCopy";
import { useIsFeatureFlagOn } from "../../../../../Experiments";

/** Common loading/success modal that can be used across all modification types, will exit modificationView
 * flow upon closing modal
 */
const ModificationStatusModal = () => {
  const {
    state: { modificationInfo, modificationStatus },
    dispatch,
  } = useBaseModificationsContext();

  const isFlightsPosbookingOn = useIsFeatureFlagOn("flights-postbooking");

  const useTripsContextHook = isFlightsPosbookingOn ? useTripsContext : useTripsContextLegacy;
  const { dispatch: tripsDispatch } = useTripsContextHook();
  const { title, subtitle } = useStatusModalCopy();

  const shouldShowRefundInfo = ["cancel", "modifyDates"].includes(
    String(modificationInfo.modificationType)
  );

  const handleCloseStatusModal = () => {
    dispatch({
      type: "SET_SHOW_STATUS_MODAL",
      payload: false,
    });
    dispatch({
      type: "SET_NAVIGATION_ROUTE",
      payload: undefined,
    });
    dispatch({
      type: "SET_MODIFICATION_INFO",
      payload: {
        refundAmount: undefined,
        refundType: undefined,
        roomsModified: 0,
        modificationType: undefined,
        bookingType: null,
      },
    });
    tripsDispatch?.({
      type: "setActiveModification",
      activeModification: { isActive: false, isNavigating: false },
    });
  };

  const modalBody = modificationStatus.isLoading ? (
    <Styled.LoadingContainer>
      <Icon
        name="spinner-third"
        color="foregroundPrimary"
        animationStyle="spin"
        size="lg"
        style={{ marginBottom: 24 }}
        data-testid="itin-content-loader"
      />
      <Typography variant="heading/lg" color="foregroundPrimary">
        We are on it!
      </Typography>
      <Typography variant="body/md" color="foregroundSecondary">
        We are processing your request.
      </Typography>
    </Styled.LoadingContainer>
  ) : (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <DialogIcon sentiment="positive" />
      </Box>
      <Typography
        variant="heading/lg"
        color="foregroundPrimary"
        as="h2"
        style={{ textAlign: "center" }}
      >
        {title}
      </Typography>
      {!!subtitle && (
        <Typography variant="body/md" color="foregroundPrimary" as="p">
          {subtitle}
        </Typography>
      )}
      {!!shouldShowRefundInfo && <RefundInfo />}
      <Button
        onClick={handleCloseStatusModal}
        data-testid="confirm-success-button"
        color="primary"
        style={{ width: "100%", marginTop: 24 }}
      >
        Got it
      </Button>
    </>
  );

  return (
    <ModalWrapper dataTestId="modification-status-modal" onCancel={handleCloseStatusModal}>
      {modalBody}
    </ModalWrapper>
  );
};

export default ModificationStatusModal;
