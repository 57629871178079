import styled from "styled-components";

interface IBullProps {
  bullColor: string;
}

export const ModificationInfo = styled.p`
  font-size: 13px;
  margin: unset;
`;

export const Bull = styled.span<IBullProps>`
  font-weight: bold;
  margin-right: ${({ theme }) => theme.spacing[8]};
  color: ${({ bullColor, theme }) => (bullColor ? bullColor : theme.colors.foregroundPrimary)};
`;
