import type { SyntheticEvent } from "react";
import { useCallback, useEffect, useState } from "react";

import { useCombobox } from "downshift";
import { useFormikContext } from "formik";
import moment from "moment";
import { Box } from "@hotelengine/atlas-web";

import type {
  ISearchFormValues,
  IRecentSearch,
  IGooglePrediction,
} from "@hotel-engine/types/search";
import type { ILocationRecord, V3LocationRecord } from "@hotel-engine/types/locations";
import type { IWorksite } from "@hotel-engine/types/worksite";
import { useBreakpoint } from "@hotel-engine/hooks";

import * as Styled from "./styles";
import {
  isGooglePrediction,
  isSearchLocationRecord,
  returnGoogleAddressObj,
  isWorksite as isWorksiteHelper,
  isRecentSearch,
} from "./helpers";
import { AutoCompleteResults } from "./components/AutocompleteResults";
import { AutoCompleteInput } from "./components/AutocompleteInput";
import { useAutoCompleteResults } from "./hooks/useAutoCompleteResults";
import { MobileAutocompleteWrapper } from "./components/MobileAutocompleteWrapper";

export interface ISearchInputProps {
  className?: string;
  isPortal?: boolean;
  isMobile?: boolean;
  placeholder?: string;
  size?: "md" | "lg";
  defaultIsOpen?: boolean;
  /**
   * When an option is selected from the autocomplete dropdown, the
   * parent component can hook into that event handler to receive
   * the selected option, it's index, and the search term/text that led
   * to those options being presented (which is not the same as
   * the search text that results in selecting one of those options.)
   * If the selected option happens to be one of "Recent searches",
   * then the searchText will be an empty string.
   */
  onSelectAutoCompleteOption?: (
    optionIndex: number,
    searchTextThatLedToOptions: string,
    types: string[]
  ) => void;

  onBlurTextField?: (
    searchTextAtTimeOfBlur: string,
    autoCompleteOptionIndex: number,
    types: string[]
  ) => void;
}

type AutocompleteState = {
  highlightedIndex: number;
  isRecentSearchSelected: boolean;
  searchInput: string;
};

type AutocompleteUpdates = Partial<AutocompleteState>;

const INITIAL_STATE = {
  highlightedIndex: -1,
  isRecentSearchSelected: false,
  searchInput: "",
};

export default function GoogleAutocompleteSearchInput({
  className,
  isPortal = false,
  isMobile,
  size = "md",
  defaultIsOpen = false,
  placeholder,
  onSelectAutoCompleteOption,
  onBlurTextField,
}: ISearchInputProps) {
  // If isMobile is not provided, use the default breakpoint
  // This avoids breaking changes in components that use this component
  const isMobileDefault = useBreakpoint("xl", "max");
  const isMobileScreen = isMobile ?? isMobileDefault;

  const { setFieldValue, values, setValues, submitCount } = useFormikContext<ISearchFormValues>();

  const selectedLocationValue =
    (values.selectedLocation as ILocationRecord)?.description ||
    (values.selectedLocation as IWorksite)?.name ||
    "";

  const [{ highlightedIndex, isRecentSearchSelected, searchInput }, setAutoCompleteState] =
    useState<AutocompleteState>({
      ...INITIAL_STATE,
      searchInput: selectedLocationValue,
    });

  const mergeAutoCompleteState = (incoming: AutocompleteUpdates) => {
    setAutoCompleteState((prev) => {
      return {
        ...prev,
        ...incoming,
      };
    });
  };

  useEffect(() => {
    // If the input is cleared and there is a selectedLocation, reset the selectedLocation
    if (
      searchInput === "" &&
      selectedLocationValue &&
      selectedLocationValue !== "Current Map Area"
    ) {
      setFieldValue("selectedLocation", null);
    }
  }, [searchInput, setFieldValue, selectedLocationValue]);

  /** useAutoCompleteResults Hook
   * Returns the state of the combobox
   * Google prediction and matching property results based on search input
   * Recent searches and favorites
   */
  const { autoCompleteDataState, searchResults, coldStateResults, isResultsLoading } =
    useAutoCompleteResults(searchInput === "Current Map Area" ? "" : searchInput);

  /** Select a worksite from Search */
  const handleSelectWorksite = (selectedItem: IWorksite, selectedIndex?: number) => {
    const { name: _name, location, ...rest } = selectedItem;
    const fullDescription = location;
    setFieldValue("selectedLocation", {
      ...rest,
      description: fullDescription,
    });
    onSelectAutoCompleteOption?.(selectedIndex ?? -1, searchInput, ["worksite"]);
  };

  const handleSelectPrediction = (selectedItem: IGooglePrediction, selectedIndex?: number) => {
    const formattedGoogleItem = returnGoogleAddressObj(selectedItem);
    const index = selectedIndex ?? -1;
    setFieldValue("selectedLocation", formattedGoogleItem);
    mergeAutoCompleteState({
      searchInput: formattedGoogleItem.description,
      highlightedIndex: index,
    });

    onSelectAutoCompleteOption?.(index, searchInput, formattedGoogleItem.types);
  };

  /** Select an API property from results */
  const handleSelectProperty = (selectedItem: ILocationRecord, selectedIndex?: number) => {
    const { description, ...rest } = selectedItem;
    const isWorksite = selectedItem.id.includes("worksite");
    const streetAddress = selectedItem.streetAddress || "";
    const city = selectedItem.city || "";
    const country = selectedItem.country || "";
    const fullDescription = isWorksite
      ? description
      : `${description}, ${streetAddress} ${city} ${country}`;
    setFieldValue("selectedLocation", {
      ...rest,
      description: fullDescription,
    });
    onSelectAutoCompleteOption?.(selectedIndex ?? -1, searchInput, selectedItem.types);
  };

  /** Select a recent search from results */
  const handleSelectRecentSearch = (selectedItem: IRecentSearch, selectedIndex?: number) => {
    const { location, isAutocomplete, types, ...rest } = selectedItem;
    !isAutocomplete &&
      setValues({
        ...values,
        checkIn: moment(selectedItem.checkIn, "YYYY-MM-DD"),
        checkOut: moment(selectedItem.checkOut, "YYYY-MM-DD"),
        roomCount: selectedItem.roomCount,
        guestCount: selectedItem.guestCount,
      });

    setFieldValue("selectedLocation", {
      ...rest,
      description: location,
      types: types ?? [],
    });

    onSelectAutoCompleteOption?.(selectedIndex ?? -1, location, types ?? []);

    mergeAutoCompleteState({
      searchInput: location,
      isRecentSearchSelected: true,
      highlightedIndex: selectedIndex,
    });
  };

  const isColdState = autoCompleteDataState === "show-cold-state";
  const resultsArr = isColdState ? coldStateResults : searchResults;

  const comboBoxStateReducer = useCallback(
    (_, { type, changes }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          /**
           * Prevent the combobox from closing when the input is blurred
           * Allows the user to clear the input with the mobile full screen modal closing
           */
          return {
            ...changes,
            ...(isMobileScreen && { isOpen: true }),
          };
        default:
          return changes;
      }
    },
    [isMobileScreen]
  );

  /** downshift useCombobox Hook */
  const {
    getMenuProps,
    getToggleButtonProps,
    getInputProps,
    getItemProps,
    isOpen: isAutocompleteOpen,
    selectItem,
    closeMenu,
  } = useCombobox<V3LocationRecord>({
    stateReducer: comboBoxStateReducer,
    highlightedIndex: highlightedIndex,
    inputValue: searchInput,
    items: resultsArr,
    defaultIsOpen,
    onIsOpenChange({ isOpen, selectedItem }) {
      if (!isOpen && !selectedItem && searchResults.length) {
        handleSelection(searchResults[0], 0);
      }
    },
    onHighlightedIndexChange(changes) {
      mergeAutoCompleteState({
        highlightedIndex: changes.highlightedIndex ?? -1,
      });
    },
    onSelectedItemChange({ selectedItem }) {
      if (!selectedItem) {
        return;
      }

      handleSelection(selectedItem, highlightedIndex);
    },
    itemToString(item) {
      return (
        (item as IWorksite)?.name ??
        (item as IRecentSearch)?.location ??
        (item as ILocationRecord)?.description
      );
    },
  });

  const handleSelection = (selectedItem: V3LocationRecord, index: number) => {
    mergeAutoCompleteState({
      searchInput:
        (selectedItem as IWorksite)?.name ??
        (selectedItem as IRecentSearch)?.location ??
        (selectedItem as ILocationRecord)?.description,
    });
    if (isGooglePrediction(selectedItem)) {
      handleSelectPrediction(selectedItem, index);
    } else if (isRecentSearch(selectedItem)) {
      handleSelectRecentSearch(selectedItem, index);
    } else if (isSearchLocationRecord(selectedItem)) {
      handleSelectProperty(selectedItem, index);
    } else if (isWorksiteHelper(selectedItem)) {
      handleSelectWorksite(selectedItem, index);
    }
  };

  /**
   * When the input is focused, clear the input if the selected location is "Current Map Area"
   */
  const handleFocused = () => {
    if (searchInput === "Current Map Area") {
      mergeAutoCompleteState({
        searchInput: "",
      });
    }
  };

  const handleBlurred = () => {
    // If the selected location is "Current Map Area" and the input is empty, set the input to "Current Map Area"
    let newSearchInput = searchInput;
    if (selectedLocationValue === "Current Map Area" && searchInput === "") {
      newSearchInput = selectedLocationValue;
      mergeAutoCompleteState({
        searchInput: newSearchInput,
      });
    }

    onBlurTextField?.(
      newSearchInput,
      highlightedIndex,
      (values.selectedLocation as ILocationRecord)?.types || []
    );
  };

  const handleClear = (e?: SyntheticEvent) => {
    if (!!e) {
      e.stopPropagation();
    }
    setFieldValue("selectedLocation", null);
    setAutoCompleteState(INITIAL_STATE);
    selectItem(null);
  };

  /**
   * Use onChange on the input instead of using downshift's onInputValueChange to prevent
   * a race condition with rendering the input value
   * @see {@link https://github.com/downshift-js/downshift/issues/1108#issuecomment-674180157}
   */
  const handleInputValueChange = (value: string) => {
    mergeAutoCompleteState({
      searchInput: value,
    });
    if (!!values.selectedLocation) {
      /**
       * Clear the selected location if user changes the input value manually.
       * If it isn't cleared, the last selected location will be used for the search
       * which can be a confusing experience for the user. We should force them
       * to pick a new location in this instance.
       */
      setFieldValue("selectedLocation", null);
    }
  };

  const hasError =
    submitCount > 0 && !isAutocompleteOpen && !values.selectedLocation && !isRecentSearchSelected;

  const isSearchEmpty = searchInput.length === 0;
  const showLoadingSpinner = isAutocompleteOpen && isResultsLoading;

  return isMobileScreen ? (
    <MobileAutocompleteWrapper
      getToggleButtonProps={getToggleButtonProps}
      handleClear={handleClear}
      handleClose={closeMenu}
      hasError={hasError}
      isPortal={isPortal}
      isSearchEmpty={isSearchEmpty}
      searchInput={searchInput}
      showModal={isAutocompleteOpen}
      placeholder={placeholder}
      size={size}
    >
      <Box marginLeft={12} marginRight={12}>
        <Styled.LocationInput
          hasError={hasError}
          focusStyling={isAutocompleteOpen}
          className={className}
          size={size}
        >
          <Styled.Container>
            <AutoCompleteInput
              getInputProps={getInputProps}
              handleFocused={handleFocused}
              handleBlurred={handleBlurred}
              handleClear={handleClear}
              isDirty={!isSearchEmpty}
              showList={isAutocompleteOpen}
              showLoadingSpinner={showLoadingSpinner}
              placeholder={placeholder}
              onInputValueChange={handleInputValueChange}
            />
          </Styled.Container>
        </Styled.LocationInput>
      </Box>
      <AutoCompleteResults
        activeList={searchResults}
        autoCompleteDataState={autoCompleteDataState}
        coldList={coldStateResults}
        comboBoxProps={{ getMenuProps, getItemProps }}
        highlightedIndex={highlightedIndex}
        searchInput={searchInput}
        showList={isAutocompleteOpen}
        isMobile={isMobileScreen}
      />
    </MobileAutocompleteWrapper>
  ) : (
    <Styled.LocationInput
      hasError={hasError}
      focusStyling={isAutocompleteOpen}
      className={className}
      size={size}
    >
      <Styled.Container>
        <AutoCompleteInput
          getInputProps={getInputProps}
          handleFocused={handleFocused}
          handleBlurred={handleBlurred}
          handleClear={handleClear}
          isDirty={!isSearchEmpty}
          showList={isAutocompleteOpen}
          showLoadingSpinner={showLoadingSpinner}
          placeholder={placeholder}
          onInputValueChange={handleInputValueChange}
        />

        <AutoCompleteResults
          activeList={searchResults}
          autoCompleteDataState={autoCompleteDataState}
          coldList={coldStateResults}
          comboBoxProps={{ getMenuProps, getItemProps }}
          highlightedIndex={highlightedIndex}
          searchInput={searchInput}
          showList={isAutocompleteOpen}
          isMobile={isMobileScreen}
        />
      </Styled.Container>
    </Styled.LocationInput>
  );
}
