import { useState } from "react";
import { useFormikContext } from "formik";
import { Box, Icon, Typography } from "@hotelengine/atlas-web";
import type { ISearchModel } from "pages/Cars/Search/types";
import { SearchForm } from "./SearchForm";
import MobileModalFullscreen from "../../MobileModalFullscreen";

import moment from "moment";
import * as Styled from "./styles";

interface ICarsSearchHeaderProps {
  isFilterOpen: boolean;
  isFiltered: boolean;
  openFilter?: () => void;
}

export enum EModalVariants {
  pickupLocation = "Pick-up location",
  dropoffLocation = "Drop-off location",
  pickupDate = "Pick-up date",
  dropoffDate = "Drop-off date",
  none = 0,
}

export default function CarsSearchHeaderMobile({
  isFilterOpen,
  isFiltered,
  openFilter,
}: ICarsSearchHeaderProps) {
  const { values } = useFormikContext<ISearchModel>();
  const filtersIcon = isFilterOpen ? "xmark" : "sliders";
  const [openMobileEditSearch, setOpenMobileEditSearch] = useState(false);
  const [variant, setVariant] = useState<EModalVariants>(EModalVariants.none);
  const onClose = () => {
    setOpenMobileEditSearch(false);
  };

  return (
    <Styled.Container data-testid="cars-search-header-mobile">
      <Icon name="house" size="md" />
      <Styled.Content
        $twoLocations={values.pickupLocationId !== values.dropoffLocationId}
        onClick={() => setOpenMobileEditSearch((s) => !s)}
      >
        <div>
          <Typography variant="heading/sm">
            {values.pickupLocationName?.trim().replace(/([,.])$/gi, "")}
          </Typography>
          {values.pickupLocationId !== values.dropoffLocationId ? (
            <>
              <Styled.IconArrows name="arrow-right-arrow-left" size="sm" />
              <Typography variant="heading/sm">
                {values.dropoffLocationName?.trim().replace(/([,.])$/gi, "")}
              </Typography>
            </>
          ) : null}
        </div>
        <div>
          <Typography variant="body/xs">
            {moment(values.pickupDate).format("ddd, MMM D")}
          </Typography>
          <Typography variant="body/xs">-</Typography>
          <Typography variant="body/xs">
            {moment(values.dropoffDate).format("ddd, MMM D")}
          </Typography>
        </div>
      </Styled.Content>

      <Box position="relative">
        {!!openFilter && <Icon onClick={openFilter} name={filtersIcon} size="md" />}
        {!!isFiltered && !isFilterOpen && <Styled.FilteredIndicator position="absolute" />}
      </Box>

      <MobileModalFullscreen
        headWithBorderBottom={variant !== EModalVariants.none}
        open={openMobileEditSearch}
        noClose
        onClose={() =>
          variant !== EModalVariants.none
            ? setVariant(EModalVariants.none)
            : setOpenMobileEditSearch(false)
        }
        title={variant !== EModalVariants.none ? variant : "Edit search"}
      >
        <SearchForm onClose={onClose} />
      </MobileModalFullscreen>
    </Styled.Container>
  );
}
