import { Icon as AntIcon } from "@hotel-engine/common/Icon";

import BaseMarker from "../BaseMarker";
import * as Styled from "../styles";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { Icon } from "@hotelengine/atlas-web";

const PropertyMarker = () => {
  const rebrandFlagOn = useIsFeatureFlagOn("rebrand_logo_copy");

  if (rebrandFlagOn) {
    return (
      <Styled.PropertyMarker data-testid="property-marker">
        <Icon color="foregroundInverse" name="hotel--solid" size="lg" />
      </Styled.PropertyMarker>
    );
  }

  return (
    <BaseMarker>
      <Styled.PropertyMarkerLegacy data-testid="property-marker">
        <AntIcon icon={["fas", "bed"]} size="lg" />
      </Styled.PropertyMarkerLegacy>
    </BaseMarker>
  );
};

export default PropertyMarker;
