import { BottomSheet } from "react-spring-bottom-sheet";

import { useBreakpoint } from "@hotel-engine/hooks";

import * as Styled from "./styles";

interface ISignInModalProps {
  /** Indicates that the modal is visible */
  visible: boolean;
  /** Function that closes the modal */
  onCancel: () => void;
  /** Component to go back from the forgot password state */
  header?: JSX.Element | null;
  children: JSX.Element;
}

export const SignInModalWrapper = ({ visible, onCancel, header, children }: ISignInModalProps) => {
  const isMobile = useBreakpoint("md", "max");

  if (isMobile) {
    return (
      <BottomSheet open={visible} onDismiss={onCancel} data-body-scroll-lock-ignore="true">
        <Styled.BottomSheetContainer>
          <Styled.ModalContent>
            {header}
            {children}
          </Styled.ModalContent>
        </Styled.BottomSheetContainer>
      </BottomSheet>
    );
  }

  return (
    <Styled.ModalWrapper title={header} visible={visible} onCancel={onCancel} footer={null}>
      <Styled.ModalContent>{children}</Styled.ModalContent>
    </Styled.ModalWrapper>
  );
};
