import moment from "moment";
import { Icon as AtlasIcon, Box, Typography } from "@hotelengine/atlas-web";
import * as Styled from "../TripsListItem/styles";
import type { IconName } from "@hotelengine/atlas-core";
import { AirlineLogo } from "@hotel-engine/assets/flights/AirlineLogo";

import type { ISlice, ITraveler } from "@hotel-engine/types/trips";
import { TravelersFooter } from "../TravelersFooter";
import { useAirportInfo } from "pages/Flights/hooks/useAirportInfo";

export const FlightCard = ({
  item,
  travelers,
  bookingNumber,
}: {
  item?: ISlice;
  travelers: ITraveler[];
  bookingNumber: string | undefined;
}) => {
  const { getIataCityCodeByIataAirportCode } = useAirportInfo();

  const getCityCode = (airportCode: string) => {
    return getIataCityCodeByIataAirportCode(airportCode);
  };

  if (!item) {
    return null;
  }
  const origin = item.segments[0].origin;
  const destination = item.segments[item.segments.length - 1].destination;

  const carrierCode = origin.carrierCode;
  const originCity = origin.airportCode;
  const destinationCity = destination.airportCode;
  const originFlightTime = moment.parseZone(origin.flightTime).format("h:mm a");
  const destinationFlightTime = moment.parseZone(destination.flightTime).format("h:mm a");
  const originFlightNumber = origin.flightNumber;
  const originCarrierCode = origin.carrierCode;

  const numberOfStops = item.segments.length - 1;
  const nonStop = numberOfStops === 0;
  const stopLabel = nonStop
    ? "nonstop"
    : `${numberOfStops} ${numberOfStops === 1 ? "stop" : "stops"}`;
  const originCityCode = getCityCode(origin.airportCode);
  const destinationCityCode = getCityCode(destination.airportCode);

  const Stops = () => {
    if (item.segments.length <= 2) {
      return null;
    }
    return (
      <>
        {item.segments.map((segment, index) => {
          if (index === item.segments.length - 1) {
            return null;
          }
          return (
            <>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                key={index}
                marginTop={16}
              >
                <AtlasIcon
                  name={"circle-small" as IconName}
                  size="sm"
                  color="foregroundSecondary"
                />
                <Typography variant="body/xs-light" color="foregroundSecondary">
                  {getCityCode(segment.destination.airportCode)}
                </Typography>
              </Box>
              <Styled.HorizontalDivider />
            </>
          );
        })}
      </>
    );
  };

  return (
    <Styled.FlightCard>
      <Styled.OriginDestination>
        <Typography variant="heading/lg" color="foregroundPrimary">
          {originCity}
        </Typography>
        <AtlasIcon name={"connecting-airports" as IconName} size="md" />
        <Typography variant="heading/lg" color="foregroundPrimary">
          {destinationCity}
        </Typography>
      </Styled.OriginDestination>
      <Styled.FlightSegmentsRow>
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Typography variant="body/lg" color="foregroundPrimary">
            {originFlightTime}
          </Typography>
          <Typography variant="body/xs-light" color="foregroundSecondary">
            {originCityCode}
          </Typography>
        </Box>
        <Styled.HorizontalDivider />
        <Stops />
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="body/lg" color="foregroundPrimary">
            {destinationFlightTime}
          </Typography>
          <Typography variant="body/xs-light" color="foregroundSecondary">
            {destinationCityCode}
          </Typography>
        </Box>
      </Styled.FlightSegmentsRow>
      <Styled.CardDivider />
      <Styled.Row>
        <Styled.RowFlightInfo>
          <Box display="flex" flexDirection="row" gap={4}>
            <AirlineLogo iata={carrierCode} size={16} />
            <Typography color="foregroundSecondary" variant="uppercase/2xs-strong">
              {originCarrierCode}
            </Typography>
            <Typography variant="uppercase/2xs-strong" color="foregroundSecondary">
              {originFlightNumber}
            </Typography>
            <AtlasIcon name={"circle-small" as IconName} size="sm" color="neutralN200" />
            <Typography variant="uppercase/2xs-strong" color="foregroundSecondary">
              {stopLabel}
            </Typography>
          </Box>
          <Typography variant="uppercase/2xs-strong" color="foregroundSecondary">
            #{bookingNumber}
          </Typography>
        </Styled.RowFlightInfo>
      </Styled.Row>
      <Styled.TravelersFooter>
        <AtlasIcon name={"user" as IconName} size="md" color="backgroundPrimary" />
        <TravelersFooter travelers={travelers} />
      </Styled.TravelersFooter>
    </Styled.FlightCard>
  );
};
