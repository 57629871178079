import type { IBooking } from "@hotel-engine/types/trips";
import { Box, Card, Chip, Divider, Icon, Typography } from "@hotelengine/atlas-web";
import { TravelersFooter } from "../TravelersFooter";
import { useTheme } from "styled-components";
import { useFormatDate } from "@hotel-engine/hooks/useFormatDate";
import { useAcrissInfo } from "pages/Cars/hooks/useAcrissInfo";

const CarCard = ({ travelers, details }: IBooking) => {
  const { colors, borderRadius, spacing } = useTheme();
  const { getAcrissCategory } = useAcrissInfo();
  const checkIn = useFormatDate(details.startTime, "MMM D");
  const checkOut = useFormatDate(details.endTime, "MMM D");

  if (!details?.car) return null;

  const carType = `${getAcrissCategory(details.car?.type) || "Rental"} car`;
  const carVariant = details.car.variant ? `${details.car.variant} or similar` : "";
  const isFullyCancelled = !!details.isFullyCancelled;

  return (
    <Card display="flex" flexDirection="column" style={{ width: "100%" }}>
      <Box display="flex" flexDirection="column" style={{ padding: "16px 12px 10px" }}>
        <Box display="flex" flexDirection="column" gap={8}>
          {!!isFullyCancelled && (
            <Box>
              <Chip
                label="CANCELLED"
                color="red"
                size="xs"
                leadingIcon="circle-exclamation"
                style={{ borderRadius: "4px" }}
              />
            </Box>
          )}
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="heading/lg" color="foregroundPrimary">
              {carType}
            </Typography>
            <Typography variant="body/sm" color="foregroundPrimary">
              {carVariant}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        as="img"
        height="120px"
        alt="car illustration"
        src={details.car.image}
        style={{ objectFit: "contain", objectPosition: "center", marginBottom: spacing[16] }}
      />
      <Divider style={{ borderColor: colors.neutralN100 }} />
      <Box padding={16} display="flex" flexDirection="row" justifyContent="space-between">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap={8}
        >
          <Box
            as="img"
            src={details.rentalCompany?.logo}
            alt="brand logo"
            height="10px"
            width="auto"
            style={{
              borderRadius: borderRadius[4],
            }}
          />
          <Typography variant="uppercase/2xs-strong" color="foregroundSecondary">
            {checkIn} - {checkOut}
          </Typography>
          <Icon name="circle-small" size="sm" color="neutralN200" />
          <Typography variant="uppercase/2xs-strong" color="foregroundSecondary">
            {`${details.location?.pickUp.city}, ${details.location?.pickUp.state}`}
          </Typography>
        </Box>
        <Typography
          variant="uppercase/2xs-strong"
          color="foregroundSecondary"
          display="flex"
          alignItems="center"
        >
          #{details.carConfirmation}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        backgroundColor="neutralN500"
        paddingLeft={16}
        paddingTop={12}
        paddingBottom={12}
        gap={6}
        color="foregroundInverse"
      >
        <Icon name="user" size="sm" />
        <TravelersFooter travelers={travelers} />
      </Box>
    </Card>
  );
};

export default CarCard;
