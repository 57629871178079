/* eslint-disable import/no-named-as-default-member */
import moment from "moment";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import { getAbbreviatedTimezone } from "@hotel-engine/utilities";
import type { TypographyVariant } from "@hotelengine/atlas-web";
import { Icon, Typography } from "@hotelengine/atlas-web";
import { useBreakpoint, useFormatDate } from "@hotel-engine/hooks";

import * as Styled from "./styles";

export interface IDateRangeProps {
  itinerary: IReservationBase;
  isPreview: boolean;
  roomIndex?: number;
}

const ItineraryDateRange = ({ itinerary, isPreview, roomIndex }: IDateRangeProps) => {
  const isMobile = useBreakpoint("lg", "max");

  type ComponentTypes = "nights" | "body" | "date";
  const typographyVariants: { [key in ComponentTypes]: TypographyVariant } = {
    nights: isPreview || isMobile ? "body/xs" : "body/sm",
    body: isPreview || isMobile ? "body/sm" : "body/md",
    date: isPreview || isMobile ? "heading/md" : "heading/lg",
  };

  const checkInToUse =
    roomIndex !== undefined ? itinerary.rooms[roomIndex].checkIn : itinerary.checkIn;
  const checkOutToUse =
    roomIndex !== undefined ? itinerary.rooms[roomIndex].checkOut : itinerary.checkOut;
  const nightCount =
    roomIndex !== undefined
      ? moment(itinerary.rooms[roomIndex].checkOut).diff(itinerary.rooms[roomIndex].checkIn, "days")
      : itinerary.nightCount;

  const checkinPropertyTimezone = itinerary.propertyTimezone
    ? getAbbreviatedTimezone({
        timezoneDatabaseName: itinerary.propertyTimezone,
        date: checkInToUse,
      })
    : "";

  const checkoutPropertyTimezone = itinerary.propertyTimezone
    ? getAbbreviatedTimezone({
        timezoneDatabaseName: itinerary.propertyTimezone,
        date: checkOutToUse,
      })
    : "";

  const formattedCheckInTime = itinerary.checkInTime
    ? `${moment(itinerary.checkInTime).utc().format("h:mm A")} ${checkinPropertyTimezone}`
    : undefined;

  const formattedCheckoutTime = itinerary.checkOutTime
    ? `${moment(itinerary.checkOutTime).utc().format("h:mm A")} ${checkoutPropertyTimezone}`
    : undefined;

  const [in_year, in_month, in_day] = checkInToUse.split("-");
  const [out_year, out_month, out_day] = checkOutToUse.split("-");

  const dayOfCheckIn = useFormatDate(
    moment.utc([parseInt(in_year), parseInt(in_month) - 1, parseInt(in_day)]).startOf("day"),
    "dddd"
  );
  const dayOfCheckOut = useFormatDate(
    moment.utc([parseInt(out_year), parseInt(out_month) - 1, parseInt(out_day)]).startOf("day"),
    "dddd"
  );

  const dateOfCheckIn = useFormatDate(
    moment.utc([parseInt(in_year), parseInt(in_month) - 1, parseInt(in_day)]).startOf("day"),
    "MMM D YYYY"
  );
  const dateOfCheckOut = useFormatDate(
    moment.utc([parseInt(out_year), parseInt(out_month) - 1, parseInt(out_day)]).startOf("day"),
    "MMM D YYYY"
  );

  const pillCopy = String(nightCount) + ` Night${nightCount > 1 ? "s" : ""}`;

  return (
    <Styled.RangeContainer data-testid="itinerary-date-range">
      <Styled.DateContainer $isCheckout={false}>
        <Typography variant={typographyVariants.body} color="foregroundSecondary">
          Check-In
        </Typography>
        <Styled.DayContainer>
          <Typography variant={typographyVariants.date} color="foregroundPrimary">
            {dayOfCheckIn}
          </Typography>
          <Typography variant={typographyVariants.date} color="foregroundPrimary">
            {dateOfCheckIn}
          </Typography>
        </Styled.DayContainer>
        <Typography variant={typographyVariants.body} color="foregroundSecondary">
          {formattedCheckInTime}
        </Typography>
      </Styled.DateContainer>
      <Styled.NightsPill>
        <Icon name="moon--solid" size="sm" color="foregroundPrimary" />
        <Typography variant={typographyVariants.nights} color="foregroundPrimary">
          {pillCopy}
        </Typography>
      </Styled.NightsPill>
      <Styled.DateContainer $isCheckout>
        <Typography variant={typographyVariants.body} color="foregroundSecondary">
          Checkout
        </Typography>
        <Styled.DayContainer>
          <Typography variant={typographyVariants.date} color="foregroundPrimary">
            {dayOfCheckOut}
          </Typography>
          <Typography variant={typographyVariants.date} color="foregroundPrimary">
            {dateOfCheckOut}
          </Typography>
        </Styled.DayContainer>
        <Typography variant={typographyVariants.body} color="foregroundSecondary">
          {formattedCheckoutTime}
        </Typography>
      </Styled.DateContainer>
    </Styled.RangeContainer>
  );
};

export default ItineraryDateRange;
