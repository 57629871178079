import type { SeverityLevel } from "@sentry/react";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";

import { useBreakpoint } from "@hotel-engine/hooks";
import { useUserQuery } from "@hotel-engine/react-query/users/useUserQuery";
import { useUpdateUserMutation } from "@hotel-engine/react-query/users/useUpdateUserMutation";
import { captureMessage } from "@hotel-engine/utilities";
import { setPersonalToken } from "@hotel-engine/utilities/auth";
import { useAppSelector } from "store/hooks";

import { showErrorModal } from "../../ErrorModal";
import { ModalNavBar } from "../../ModalNavBar";
import { PolicyText } from "../../PolicyText";
import * as Styled from "./styles";

type INameFormValues = {
  firstName: string;
  lastName: string;
};

const NameDataSchema = yup.object().shape({
  firstName: yup.string().required("First name required"),
  lastName: yup.string().required("Last name required"),
});

interface IJustAboutFinishedGoogle {
  onClose: () => void;
  onGoBack: () => void;
  goContinue: () => void;
  /** Google personal account token */
  personalAccountToken: string;
}

export const JustAboutFinishedGoogle = ({
  onClose,
  onGoBack,
  goContinue,
  personalAccountToken,
}: IJustAboutFinishedGoogle) => {
  const businessUser = useAppSelector((state) => state.Auth.user);
  const personalUser = useUserQuery({ accessToken: personalAccountToken }); //the newly created user
  const isDesktop = useBreakpoint("xl");
  const personalAccountEdit = useUpdateUserMutation({
    accessToken: personalAccountToken,
  });

  const onSubmit = (values: INameFormValues) => {
    if (!personalUser?.data) {
      return;
    }

    personalAccountEdit.mutate(
      {
        id: personalUser?.data?.id,
        firstName: values.firstName,
        lastName: values.lastName,
      },
      {
        onSuccess: () => {
          // store the token and continue
          if (businessUser) {
            setPersonalToken(personalAccountToken, businessUser.accountType);
          }
          goContinue();
        },
        onError: (error) => {
          captureMessage(
            "Error sign up personal account user - Google flow",
            { error },
            "critical" as SeverityLevel
          );
          showErrorModal();
        },
      }
    );
  };

  if (!personalUser) {
    return null;
  }

  return (
    <div>
      <ModalNavBar onClose={onClose} onGoBack={onGoBack} />
      <Styled.Title>Just about finished!</Styled.Title>
      <Styled.DescriptionText>We just need a little more information.</Styled.DescriptionText>
      <Formik
        initialValues={
          {
            firstName: businessUser?.firstName ?? "",
            lastName: businessUser?.lastName ?? "",
          } as INameFormValues
        }
        onSubmit={onSubmit}
        validationSchema={NameDataSchema}
      >
        {({ handleSubmit, values, setFieldValue }) => {
          return (
            <Form>
              <Styled.VerticalFlowContainer>
                <Styled.FullNamesWrapper isDesktop={isDesktop}>
                  <Styled.FirstName isDesktop={isDesktop}>
                    <Styled.FieldText htmlFor={"firstName"}>First Name</Styled.FieldText>
                    <Field component={Styled.InputBox} size="default" name="firstName" allowClear />
                  </Styled.FirstName>
                  <Styled.LastName isDesktop={isDesktop}>
                    <Styled.FieldText htmlFor={"lastName"}>Last Name</Styled.FieldText>
                    <Field
                      component={Styled.InputBox}
                      size="default"
                      name="lastName"
                      value={values.lastName}
                      onChange={(ev) => {
                        setFieldValue("lastName", ev.target.value);
                      }}
                      allowClear
                    />
                  </Styled.LastName>
                </Styled.FullNamesWrapper>
                <Styled.Email isDesktop={isDesktop}>
                  <Styled.FieldText htmlFor={"email"}>Email</Styled.FieldText>
                  <Field
                    data-testid="personal-email"
                    component={Styled.InputBox}
                    size="default"
                    name="email"
                    value={personalUser?.data?.email}
                    disabled
                  />
                </Styled.Email>
                <Styled.ContinueButton htmlType="submit" type="primary" onSubmit={handleSubmit}>
                  {!!personalAccountEdit.isLoading && <Styled.JustAboutFinishedLoader />}
                  Continue
                </Styled.ContinueButton>
              </Styled.VerticalFlowContainer>
            </Form>
          );
        }}
      </Formik>
      <PolicyText />
    </div>
  );
};
