import styled, { css } from "styled-components";
import {
  BottomSheetContent,
  BottomSheetFooter,
  BottomSheetHeader,
  Box,
  type BoxProps,
} from "@hotelengine/atlas-web";
import { flightsBreakpoints } from "@hotelengine/core-booking-web";

import { SEARCH_HEIGHT } from "@hotel-engine/constants";
import { buttonReset, colors } from "@hotel-engine/styles";

interface IDateRangeProps {
  $pickupError: boolean;
  $isOneWay: boolean;
  $dropoffError: boolean;
}

interface IInputProps {
  inputWidth?: string;
  margin?: string;
  $shouldDisablePrevArrow?: boolean;
}

// Using a color config for now until we complete building out our new theme
const dateColors = {
  primary: colors.retroBlue,
  hover: colors.denim,
} as const;

const mobileDateSizes = {
  titleHeight: 56,
  dateSectionMargin: 12,
  selectedDatesHeight: 63,
  weekHeight: 40,
} as const;

export const StyledBottomSheetContent = styled(BottomSheetContent)`
    padding: 0;
`;

export const StyledBottomSheetHeader = styled(BottomSheetHeader)`
    padding: ${({ theme }) => `0 ${theme.spacing[16]}`};
`;

export const StyledBottomSheetFooter = styled(BottomSheetFooter)`
    padding: ${({ theme }) =>
      `${theme.spacing[12]} ${theme.spacing[16]} ${theme.spacing[24]} ${theme.spacing[16]}`};
    gap: ${({ theme }) => theme.spacing[20]};
    border-top: ${({ theme }) => `1px solid ${theme.colors.inputBorder}`};
`;

export const FooterInfoContainer = styled<React.FC<BoxProps>>(Box)`
  display: flex;
  gap: 16px;
`;

export const FooterColumnInfo = styled<React.FC<BoxProps>>(Box)`
  width: 160.5px;
  height: 82px;
  flex-grow: 1;
`;

export const LocationInfo = styled<React.FC<BoxProps>>(Box)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};
`;

export const LocationMarker = styled<React.FC<BoxProps>>(Box)`
  height: 10px;
  width: 10px;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  background-color: ${dateColors.primary};
`;

export const DateSelectorContainer = styled.div<{
  $isOneWay: boolean;
}>`
  min-width: ${({ $isOneWay }) => ($isOneWay ? "119px" : "250px")};
  transition: min-width 0.3s ease;
  margin-top: ${({ theme }) => theme.spacing[8]};
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${flightsBreakpoints.lg}px) {
    flex-direction: row;
    margin-top: 0;
    flex: 0;
    height: 100%;
  }
`;

export const DateSelectorInput = styled.div<IInputProps>`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  @media screen and (min-width: ${flightsBreakpoints.lg}px) {
    align-items: stretch;
  }

  ${({ $shouldDisablePrevArrow }) =>
    $shouldDisablePrevArrow &&
    css`
      .DayPickerNavigation_button[aria-label*="previous"] {
        display: none;
      }
  `}
`;

export const MobileDateSelectorInput = styled(DateSelectorInput)`
  border: ${({ theme }) => `solid 1px ${theme.colors.borderSubtle}`};
  border-radius: ${({ theme }) => theme.borderRadius.nsActionMd};
  height: 56px;
  max-width: none;
  padding: ${({ theme }) => `0 ${theme.spacing[4]} 0 ${theme.spacing[12]}`};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
`;

export const ButtonReset = styled.button`
  ${buttonReset}
`;

export const pickupButton = styled(ButtonReset)`
  color: ${({ theme }) => theme.colors.inputForeground};
  font-family: ${({ theme }) => theme.typography.desktop["body/md"].fontFamily};
  font-size: ${({ theme }) => theme.typography.desktop["body/md"].fontSize};
  font-weight: ${({ theme }) => theme.typography.desktop["body/md"].fontWeight};
  line-height: normal;
  margin-left: ${({ theme }) => theme.spacing[8]};
  pointer-events: all;
`;

export const DateCalendar = styled.div<{
  isFocused?: boolean;
}>`
  ${({ theme, isFocused }) => {
    const focused = isFocused ? `height: 100%;` : "";

    return css`
      ${focused}

      .DayPicker_weekHeader {
        height: ${mobileDateSizes.weekHeight}px;
        border-top: 1px solid ${theme.colors.borderSubtle};

        @media (min-width: ${flightsBreakpoints.lg}px) {
          top: 55px;
          height: unset;
          border: none;
        }

        @media (max-width: ${flightsBreakpoints.lg}px) {
          .DayPicker_weekHeader_ul {
            line-height: ${mobileDateSizes.weekHeight - 2}px;
            margin: 0;
          }
        }
      }

      .CalendarMonth_caption {
        color: ${theme.colors.foregroundPrimary};
        font-size: 16px;
      }

      .DateRangePicker_picker__directionLeft {
        transform: translate(0, -${SEARCH_HEIGHT + 20}px);
        border-radius: ${theme.borderRadius[24]};
      }

      .SingleDatePicker > div,
      .SingleDatePickerInput_1 {
        height: 100%;
        position: relative;
      }

      .SingleDatePicker_picker {
        transform: translateY(-${SEARCH_HEIGHT + 22}px);
        border-radius: ${theme.borderRadius[24]};
      }

      .DayPicker_transitionContainer {
        border-radius: ${theme.borderRadius[24]};
        @media (max-width: ${flightsBreakpoints.lg - 1}px) {
          padding-top: 53px;
        }
      }

      .DayPicker__withBorder {
        border-radius: ${theme.borderRadius[24]};
        box-shadow: 0px 8px 24px 1px rgba(0, 0, 0, 0.12),
          0px 1px 4px rgba(0, 0, 0, 0.04);
      }

      // Style the date picker days
      .CalendarDay {
        border: 1px solid transparent;

        &.CalendarDay__default {
          background: ${theme.colors.backgroundPrimary};
          &:hover,
          &:focus {
            background: ${theme.colors.backgroundSelectedSubtle};
            border: 1px solid ${dateColors.primary};
          }
        }

        // The two selected days
        &.CalendarDay__selected {
          background: ${dateColors.primary};
          color: ${theme.colors.backgroundPrimary};

          &:hover,
          &:focus {
            background: ${colors.bavarianBlue};
          }
        }

        // The selected range between check in and check out
        &.CalendarDay__selected_span {
          background: ${theme.colors.backgroundSelectedSubtle};
          color: ${theme.colors.foregroundPrimary};

          &:hover,
          &:focus {
            background: ${theme.colors.backgroundSelectedSubtle};
          }
        }

        // Selected check in
        &.CalendarDay__selected_start {
          border-radius: ${theme.borderRadius.sm} 0 0 ${theme.borderRadius.sm};
        }

        // Selected check out
        &.CalendarDay__selected_end {
          border-radius: 0 ${theme.borderRadius.sm} ${theme.borderRadius.sm} 0;
        }

        // The non-finalized selected range between check in and check out
        &.CalendarDay__hovered_span {
          background: ${theme.colors.backgroundSelectedSubtle};
          color: ${theme.colors.foregroundPrimary};

          &:hover,
          &:focus {
            background: ${theme.colors.backgroundSelectedSubtle};
            border: 1px solid ${dateColors.primary};
            border-radius: ${({ theme: { borderRadius } }) =>
              `0 ${borderRadius.sm} ${borderRadius.sm} 0`};
          }
        }

        // Build the tooltip when hovering over a date
        &[data-active-tooltip="true"] {
          position: relative;

          &:hover::before {
            position: absolute;
            width: 75px;
            top: -36px;
            left: 50%;
            transform: translateX(-50%);
            content: attr(data-number-of-nights);
            background-color: ${theme.colors.foregroundPrimary};
            color: ${theme.colors.backgroundPrimary};
            z-index: 100 /* disable theming (do not replicate) */;
            font-size: ${theme.typography.desktop["body/xs"].fontSize};
            padding: ${theme.spacing[8]};
            border-radius: ${theme.borderRadius.sm};
          }
          &:hover::after {
            position: absolute;
            top: -3px;
            transform: translateX(-50%);
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid ${theme.colors.foregroundPrimary};
            content: " ";
            font-size: 0;
            line-height: 0;
            margin-left: -25%;
            width: 0;
          }
        }
      }

      // Month title
      .CalendarMonth_caption {
        color: ${theme.colors.foregroundPrimary};
        font-size: ${theme.typography.desktop["body/md"].fontSize};
        padding-top: 13px;

        strong {
          font-weight: normal;
        }
      }

      .CalendarMonthGrid_month__hidden {
        visibility: visible;
      }
    `;
  }}

  .DayPickerNavigation_button__default {
    border: none;
  }

  .CalendarMonth_table {
    border-collapse: unset;
  }
`;

export const DateRangePicker = styled(DateCalendar)<IDateRangeProps>`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  .DateRangePicker_1 {
    width: 100%;
  }

  .DateRangePicker {
    &,
    > div {
      height: 100%;
    }
  }

  .DateRangePickerInput {
    display: flex;
    align-items: stretch;
    height: 100%;
    position: relative;
    .DateRangePickerInput_calendarIcon {
        position: absolute;
        z-index: ${({ theme }) =>
          theme.zIndex.backdropAbove + theme.zIndex.backdropAbove + theme.zIndex.backdropAbove};
        top: 1px;
        &:focus,
        &:hover {
         border: none;
      }
    }
  }

  .DateInput_fang {
    display: none;
  }

  .DateInput {
    height: 100%;
    .DateInput_input {
      background-color: ${({ theme }) => theme.colors.backgroundSecondary};
      color: ${({ theme }) => theme.colors.foregroundPrimary};
      font-size: ${({ theme }) => theme.typography.desktop["body/md"].fontSize};
      font-weight: ${({ theme }) => theme.typography.desktop["body/md"].fontWeight};
      margin-bottom: 0;
      text-align: center;
      line-height: unset;
      cursor: pointer;
      height: 100%;
      padding: 0;
      &:focus,
      &:hover {
        border: 1px solid ${({ theme }) => theme.colors.uiPrimary};
      }
      &:focus {
        outline: 2px solid
          ${({ theme }) => theme.colors.backgroundSelectedSubtle};
      }
    }
  }

  .SingleDatePickerInput {
    border: none;
    .DateInput {
      max-width: 119px;
    }
  }

  .DateRangePickerInput_calendarIcon,
  .SingleDatePickerInput_calendarIcon {
    margin: 0;
    border: 1px solid transparent;
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
    width: 40px;
    height: 100%;
    align-self: center;
    position: absolute;
    z-index: ${({ theme }) => theme.zIndex.backdropAbove};
    top: 1px;

    &:hover,
    &:focus {
      border: none;
    }
    &:focus {
      outline: 2px solid ${({ theme }) => theme.colors.backgroundSelectedSubtle};
    }
  }

  .DateInput_input[id="flight-date-range-picker-start"] {
    padding-left: 16px;
    border:  1px solid ${({ theme }) => theme.colors.inputBorder};
    border-radius: ${({ theme }) => theme.borderRadius.nsActionMd};
    border-right: 1px solid transparent;
    /** fixes the gap caused by the transparent border-right/left on the date-range-picker-start and date-range-picker-end */
    position: relative;
    right: -1px;

    &:not(:hover, :focus)  {
      border-right: 1px solid transparent !important;
    }


    &:focus,
    &:hover {
      z-index: ${({ theme }) => theme.zIndex.backdropAbove + theme.zIndex.backdropAbove};
    }

    ${({ theme, $isOneWay }) =>
      (!$isOneWay &&
        `
      padding-left: 34px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      `) ||
      `border-right: 1px solid ${theme.colors.inputBorder};`}
   }

   .DateInput_input[id="flight-date-range-picker-end"]{
    border:  1px solid ${({ theme }) => theme.colors.inputBorder};
    border-radius: ${({ theme }) => theme.borderRadius.nsActionMd};
    border-left: 1px solid transparent;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    /** fixes the gap caused by the transparent border-right/left on the date-range-picker-start and date-range-picker-end */
    position: relative;
    left: -1px;

    &:not(:hover, :focus)  {
      border-left: 1px solid transparent !important;
    }

    &:focus,
    &:hover {
      z-index: ${({ theme }) => theme.zIndex.backdropAbove};
    }
   }

   .DateRangePickerInput_arrow {
    padding: 0 8px;
    border-top: 1px solid ${({ theme }) => theme.colors.inputBorder};
    border-bottom: 1px solid ${({ theme }) => theme.colors.inputBorder};
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.backgroundSecondary};
    /** fixes the gap caused by the transparent border-right/left on the date-range-picker-start and date-range-picker-end */
    z-index: ${({ theme }) => theme.zIndex.backdropAbove};
  }

  ${({ theme, $pickupError, $dropoffError }) =>
    ($pickupError || $dropoffError) &&
    css`
      .DateInput_input[id="flight-date-range-picker-start"], .DateInput_input[id="flight-date-range-picker-end"] {
        border-color: ${theme.colors.sentimentCriticalDefault};
      }
      .DateInput_input[id="flight-date-range-picker-start"] {
        border-right: none;
      }
      .DateInput_input[id="flight-date-range-picker-end"] {
        border-left: none;
      }
      .DateRangePickerInput_arrow {
        border-top: 1px solid ${theme.colors.sentimentCriticalDefault};
        border-bottom: 1px solid ${theme.colors.sentimentCriticalDefault};
      }
    `}

  .DayPickerNavigation_button {
    &:hover,
    &:focus {
      background: ${colors.grey[50]};
    }
  }

  .DayPickerNavigation_button__horizontalDefault {
    padding: 0 9px;
    top: 16px;
  }
`;

export const dropoffButton = styled(pickupButton)<{ $dropoffError?: boolean }>`
  line-height: normal;
  margin-right: 0;
  ${({ theme, $dropoffError }) =>
    $dropoffError &&
    css`
      color: ${theme.colors.foregroundDestructive};
    `}
`;

export const SelectedDatesSection = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: ${mobileDateSizes.selectedDatesHeight}px;
  margin-bottom: ${mobileDateSizes.dateSectionMargin}px;
`;

export const SelectedDate = styled(ButtonReset)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 110px;
  margin-right: ${({ theme }) => theme.spacing[8]};
`;

export const DateType = styled.span`
  color: ${({ theme }) => theme.colors.foregroundPrimary};
  font-size: ${({ theme }) => theme.typography.desktop["body/xs"].fontSize};
`;

export const Date = styled.span<{ isFocused: boolean }>`
  ${({ isFocused }) =>
    isFocused &&
    css`
      color: ${colors.softBlue};
    `}
  font-size: ${({ theme }) => theme.typography.desktop["body/md"].fontSize};
  font-weight: 600;
`;

export const Dates = styled.div`
  margin: auto;
`;

export const Hyphen = styled.span<{ $dateError: boolean }>`
  font-size: ${({ theme }) => theme.typography.desktop["heading/lg"].fontSize};
  font-weight: ${({ theme }) => theme.typography.desktop["heading/lg"].fontSize};
  ${({ theme, $dateError }) =>
    $dateError &&
    css`
      color: ${theme.colors.foregroundDestructive};
    `}
`;
