import { Box, Icon } from "@hotelengine/atlas-web";
import { useTheme } from "styled-components";
import ItineraryWalkScore from "./components/ItineraryWalkscore";

export interface IMapStreetProps {
  handleView: () => void;
  walkscorePercentage: string;
  walkscoreDescription: string;
}

export const MapStreet = ({
  handleView,
  walkscorePercentage,
  walkscoreDescription,
}: IMapStreetProps) => {
  const { borderRadius } = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      position="absolute"
      padding={8}
      backgroundColor="backgroundPrimary"
      role="button"
      onClick={handleView}
      zIndex="backdropAbove"
      style={{
        bottom: 12,
        left: 12,
        right: 12,
        borderRadius: borderRadius.sm,
        cursor: "pointer",
      }}
    >
      <Box
        style={{
          flex: 1,
        }}
      >
        <ItineraryWalkScore percentage={walkscorePercentage} description={walkscoreDescription} />
      </Box>
      <Box
        data-testid="directions-btn"
        marginLeft={16}
        marginRight={8}
        display="flex"
        justifyContent="center"
      >
        <Icon name="diamond-turn-right--solid" color="accentBlueStronger" size="lg" />
      </Box>
    </Box>
  );
};
export default MapStreet;
