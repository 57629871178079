import type { IconName } from "@hotelengine/atlas-core";

export type AmenityTypes = "wifi" | "power";

export interface IAmenityInfo {
  copy: string;
  icon: IconName;
}

const amenityInfo: { [key in AmenityTypes]: IAmenityInfo } = {
  wifi: {
    copy: "Wi-fi for a fee",
    icon: "wifi",
  },
  power: {
    copy: "In-seat power outlet",
    icon: "plug--solid",
  },
};

export const getAmenitiesInfo = (amenities: AmenityTypes[]) => {
  return amenities.map((amenity) => amenityInfo[amenity]);
};
