import styled from "styled-components";

const WarningContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.colors.sentimentImportantMinimal};
  padding: ${({ theme }) => theme.spacing[16]};
  flex-direction: column;
  justify-content: center;
  border-bottom: ${({ theme }) =>
    `${theme.borderWidth.normal} solid ${theme.colors.sentimentImportantSubtle}`}
`;

const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const IconContainer = styled.div`
  width: ${({ theme }) => theme.spacing[24]};
  margin-left: 10px;
  display: flex;
  align-items: baseline;
`;

const DetailContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export { WarningContainer, IconContainer, InfoContainer, DetailContainer };
