import { ampli } from "ampli";
import moment from "moment";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import type { ModificationTypes } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import type { INewDates, IShortenRooms } from "pages/ModifyReservation/types";
import { useAppSelector } from "../../../../store/hooks";

/** Amplitude tracking for ModifyReservation events */
export const useTrackBookingModifications = (reservation?: IReservationBase) => {
  const today = moment();
  const user = useAppSelector((state) => state.Auth?.user);
  const userId = user?.id;

  /** The legacy version of Modifications is still using this hook too, so until that all is removed
   * we need to do this check for reservation here, eventually we can remove the prop and just use the context
   * value
   */
  const { reservation: contextReservation } = useModificationsContext();
  const currentReservation = contextReservation ?? reservation;
  const isInRefundableWindow = today.isBefore(currentReservation?.cancelBy);

  const modificationTrackingPayload = {
    bookingId: currentReservation?.contractNumber,
    propertyId: currentReservation?.propertyId,
  };

  /** Triggered when user clicks a modification option in ItineraryActions */
  const trackEnterModificationFlow = (modificationType: ModificationTypes) => {
    ampli.clickModifyTrip({
      modificationType,
      contractNumber: currentReservation?.contractNumber,
    });
  };

  /** Triggered when a user tries to leave an active modification flow, then when prompted
   * chooses to abandon the modification
   */
  const trackAbandonModificationFlow = (modificationType: ModificationTypes) => {
    ampli.clickAbandonModification({
      modificationType,
      contractNumber: currentReservation?.contractNumber,
    });
  };

  /** Triggered when a user tries to leave an active modification flow, then when prompted
   * chooses to continue the modification
   */
  const trackContinueModificationFlow = (modificationType: ModificationTypes) => {
    ampli.clickContinueModification({
      modificationType,
      contractNumber: currentReservation?.contractNumber,
    });
  };

  /** Used for multi room cancellation submission */
  const trackSubmitCancelRooms = (cancelRoomCount: number) => {
    ampli.clickCancelRoomsReservation({
      bookingId: currentReservation?.contractNumber,
      propertyId: currentReservation?.propertyId,
      roomCount: currentReservation?.roomCount,
      roomsCancelled: cancelRoomCount,
      userId,
    });
  };

  /** Used for single room cancellation submission */
  const trackClickCancelTrip = () => {
    ampli.clickCancelTrip({
      bookingId: currentReservation.contractNumber,
      propertyId: currentReservation.propertyId,
      userId,
    });
  };

  const trackClickShortenTrip = () => {
    ampli.clickShortenTrip({
      bookingId: currentReservation.contractNumber,
      flexType: currentReservation.flexType,
      isInRefundableWindow: currentReservation.flexEnabled
        ? currentReservation.modificationActions.flexShortenable
        : isInRefundableWindow,
      isRefundable: currentReservation.flexEnabled
        ? currentReservation.modificationActions.flexShortenable
        : currentReservation.modificationActions.shortenable,
      propertyId: currentReservation.propertyId,
      roomCount: currentReservation.roomCount,
      userId,
    });
  };

  const trackModificationAdditionalGuest = () => {
    ampli.clickAdditionalGuestInModificationWorkflow(modificationTrackingPayload);
  };

  const trackModificationAddLoyaltyProgram = () => {
    ampli.clickAddHotelLoyaltyProgramInModificationWorkflow(modificationTrackingPayload);
  };

  const trackModificationGuestChange = () => {
    ampli.clickChangeGuestInModificationWorkflow(modificationTrackingPayload);
  };

  const trackSubmitModificationRequest = (newDateValues: INewDates) => {
    ampli.clickSubmitModificationRequest({
      bookingId: currentReservation.contractNumber,
      newCheckIn: String(newDateValues.checkIn),
      newCheckOut: String(newDateValues.checkOut),
      propertyId: currentReservation.propertyId,
    });
  };

  const trackSubmitShortenTrip = (shortenRoomsValues: IShortenRooms) => {
    const newRoomCheckouts = Object.entries(shortenRoomsValues)
      .filter(([_, values]) => values.enabled && values.checkOut)
      .map(([contractNumber, values]) => ({
        checkOutDate: values.checkOut?.format("YYYY-MM-DD") || "",
        contractNumber,
        currentCheckOut: values.currentCheckOut || "",
      }));

    ampli.clickSubmitChangesForShortenTrip({
      bookingId: newRoomCheckouts.map((room) => room.contractNumber).join(","),
      checkOut: newRoomCheckouts.map((room) => room.currentCheckOut).join(","),
      flexType: currentReservation.flexType,
      isRefundable: currentReservation.flexEnabled
        ? currentReservation.modificationActions.flexShortenable
        : currentReservation.modificationActions.shortenable,
      isInRefundableWindow: currentReservation.flexEnabled
        ? currentReservation.modificationActions.flexShortenable
        : isInRefundableWindow,
      newCheckOut: newRoomCheckouts.map((room) => room.checkOutDate).join(","),
      propertyId: currentReservation.propertyId,
      roomCount: currentReservation.roomCount,
      userId,
    });
  };

  return {
    trackEnterModificationFlow,
    trackAbandonModificationFlow,
    trackContinueModificationFlow,
    trackClickCancelTrip,
    trackClickShortenTrip,
    trackModificationAdditionalGuest,
    trackModificationAddLoyaltyProgram,
    trackModificationGuestChange,
    trackSubmitCancelRooms,
    trackSubmitModificationRequest,
    trackSubmitShortenTrip,
  };
};
