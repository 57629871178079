import { createContext, useContext } from "react";

import type { IReservationBase } from "@hotel-engine/types/reservation";

export interface ITripExtensionsContextProps {
  transformDate: (date: string | undefined) => string | undefined;
  previousTrip: IReservationBase | undefined;
  subsequentBookings: IReservationBase[] | undefined;
  isGroup: boolean;
}

const Context = createContext({} as ITripExtensionsContextProps);
const useTripExtensionContext = () => useContext(Context);
const TripExtensionProvider = Context.Provider;

export { useTripExtensionContext, TripExtensionProvider };
