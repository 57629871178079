import type { IReservationBase } from "@hotel-engine/types/reservation";
import type { ICurrency } from "@hotel-engine/constants";
import { formatCurrency } from "@hotel-engine/utilities";
import { Typography } from "@hotelengine/atlas-web";

import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import * as Styled from "./styles";
import BookingDetailsContainer from "./components/BookingDetailsContainer";
import PopoverContent from "./components/PopoverContent";

export interface IFlexLineProps {
  reservation: IReservationBase;
  currencyCode: ICurrency;
  flexCost: number;
}

const FlexLine = ({ reservation, currencyCode, flexCost }: IFlexLineProps) => {
  const { isPreview } = useModificationsContext();
  const { typographyVariants } = useTypographyScaling();

  const showMultiRoomFlexDetails = reservation.roomCount > 1;

  return (
    <Styled.FlexLine data-testid="flex-line-soc">
      <BookingDetailsContainer
        showBookingDetails={showMultiRoomFlexDetails}
        isPreview={isPreview}
        content={<PopoverContent reservation={reservation} currencyCode={currencyCode} />}
      >
        <Typography
          variant={typographyVariants.body}
          color="foregroundSecondary"
          style={
            !!showMultiRoomFlexDetails
              ? {
                  textDecorationLine: "underline",
                  textDecorationStyle: "dotted",
                  cursor: "pointer",
                  width: "fit-content",
                }
              : {}
          }
        >
          Flex coverage
        </Typography>
      </BookingDetailsContainer>
      <Typography variant={typographyVariants.body} color="foregroundPrimary">
        {formatCurrency(flexCost, true, currencyCode)}
      </Typography>
    </Styled.FlexLine>
  );
};

export default FlexLine;
