import { FormItem } from "@hotel-engine/common/Form";
import { getNestedProperty } from "@hotel-engine/common/FormikFields/helpers";
import type { IInputFieldProps } from "@hotel-engine/common/FormikFields/InputField";
import { SelectOption } from "@hotel-engine/common/Select";

import * as Styled from "./styles";

export interface ICustomFieldInput extends IInputFieldProps {
  /** Light text to display when no value entered */
  placeholder: string;
  /** Function to change value */
  onChange: () => void;
  /** List of options for dropdown menu */
  options: string[] | null;
  /** Label to display above select field */
  label: string;
  /** Number of times parent form has been submitted */
  submitAttempts: number;
  /** Whether or not the field is required */
  required?: boolean;
  /** any other props to pass to ant input (e.g. label, placeholder, etc.) */
  [x: string]: unknown;
}

export const CustomFieldInput = ({
  placeholder,
  onChange,
  options,
  field,
  form,
  label,
  submitAttempts,
  required,
  ...props
}: ICustomFieldInput) => {
  const fieldTouched = getNestedProperty(form.touched, field.name, ".");
  const fieldError = getNestedProperty(form.errors, field.name, ".");
  const showValidation = submitAttempts || fieldTouched;
  const validateStatus: undefined | "error" = showValidation && fieldError && "error";
  const help = showValidation && fieldError;

  return (
    <FormItem
      htmlFor={field.name}
      help={help}
      validateStatus={validateStatus}
      label={label}
      required={required}
    >
      <Styled.CustomSelect
        showSearch
        optionFilterProp="children"
        size="large"
        allowClear
        clearIcon={<Styled.InputIcon icon={["fas", "times-circle"]} />}
        placeholder={placeholder || `Select ${field.name}`}
        value={field.value}
        onChange={onChange}
        {...props}
      >
        {options?.map((option, index) => {
          return (
            <SelectOption
              key={`custom-select-${option}-${index}`}
              data-testid={"custom-field-select-option"}
              value={option}
            >
              {option}
            </SelectOption>
          );
        })}
      </Styled.CustomSelect>
    </FormItem>
  );
};

export default CustomFieldInput;
