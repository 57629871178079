import { Icon as AtlasIcon, Typography } from "@hotelengine/atlas-web";

import type { ITripExtensions } from "@hotel-engine/types/reservation";
import { formatDate } from "@hotel-engine/utilities/formatters";

import { useAppSelector } from "store/hooks";
import useTripExtensions from "./useTripExtensions";
import * as Styled from "./styles";
import { TripExtensionProvider } from "./useTripExtensionContext";
import MultiDateExtensionCopy from "./TripExtendedCopy/MultiDateExtensionCopy";
import ExtensionCopy from "./TripExtendedCopy/ExtensionCopy";

interface ITripExtensionsProps {
  extensions: ITripExtensions;
  isGroup: boolean;
}

const TripExtensions: React.FC<ITripExtensionsProps> = ({ extensions, isGroup }) => {
  const business = useAppSelector(({ Auth }) => Auth.user?.business);
  const { previousTrip, subsequentBookings, isLoading } = useTripExtensions(extensions);

  const transformDate = (date: string | undefined) => {
    if (!date) return;

    const preferredFormat = formatDate(date, "M/D/YY", business?.preferredDateFormat ?? "mdy");

    return preferredFormat;
  };

  if (isLoading) return null;

  return (
    <TripExtensionProvider
      value={{
        transformDate,
        previousTrip,
        subsequentBookings,
        isGroup,
      }}
    >
      <Styled.Container>
        {!!previousTrip && (
          <Styled.PreviousBooking data-testid="previous-booking">
            <AtlasIcon name="circle-info--solid" color="accentBlue" />
            <Styled.Content>
              <Styled.Description>
                <Typography variant="heading/sm" color="foregroundPrimary">
                  Previous booking for this trip
                </Typography>
                <ExtensionCopy />
              </Styled.Description>
            </Styled.Content>
          </Styled.PreviousBooking>
        )}
        {!!subsequentBookings?.length && (
          <Styled.ExtensionBookings $isExtension={!!previousTrip} data-testid="extension-bookings">
            <AtlasIcon name="circle-info--solid" color="accentBlue" />
            <Styled.Content>
              <Styled.Description>
                <Typography variant="heading/sm" color="foregroundPrimary">
                  Trip extension
                </Typography>
                <MultiDateExtensionCopy />
              </Styled.Description>
            </Styled.Content>
          </Styled.ExtensionBookings>
        )}
      </Styled.Container>
    </TripExtensionProvider>
  );
};

export default TripExtensions;
