import type { BoxProps } from "@hotelengine/atlas-web";
import { Box, Icon, TextInput } from "@hotelengine/atlas-web";
import styled, { css } from "styled-components";

export const carsSearchHeaderHeightMobile = 76;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing[16]};
  align-items: center;
  width: 100%;
  background-color: #FFF;
  padding: ${({ theme }) => theme.spacing[16]};

  > i {
    flex-shrink: 0;
  }
`;

export const Content = styled.div<{ $twoLocations?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  min-width: 0;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[24]}`};
  border: 1px solid ${({ theme }) => theme.colors.borderMinimal};
  // margin-bottom: ${({ theme }) => theme.spacing[8]};

  > div {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing[8]};
    align-items: center;
    white-space: pre;

    &:first-child {
      > span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        min-width: 0;
        flex-shrink: 1;
        flex-basis: ${({ $twoLocations }) => ($twoLocations ? "50%" : "auto")};

        &:first-child {
          text-align: right;
        }
      }
    }
  }
`;

export const LocationNamesWrapper = styled.div`
  span {
    overflow: hidden;
    line-clamp: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 80px;
  }
`;

export const FilteredIndicator = styled<React.FC<BoxProps>>(Box)`
    position: absolute;
    top: -4px;
    right: -4px;
    width: ${({ theme }) => theme.spacing[8]};
    height: ${({ theme }) => theme.spacing[8]};
    border-radius: ${({ theme }) => theme.borderRadius.circle};
    border: ${({ theme }) => `${theme.borderWidth.normal} solid ${theme.colors.borderInverse}`};
    background-color: ${({ theme }) => theme.colors.backgroundInverse};
`;

export const MobileFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
`;

export const LocationInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconArrows = styled(Icon)`
  margin: 0 ${({ theme }) => theme.spacing[8]};
  flex-shrink: 0;
`;

export const LocationInput = styled(TextInput)<{
  $isSameLocation: boolean;
}>`
  height: 56px;
  border-radius: ${({ theme }) => theme.borderRadius[24]};

  &:focus, &:focus-within {
    z-index: ${({ theme }) => theme.zIndex.popover};
  }

  ${({ $isSameLocation }) => {
    return !$isSameLocation
      ? css`
        :first-of-type {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
        :last-of-type {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      `
      : null;
  }}
`;
