import type { UseQueryOptions } from "react-query";

import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { ILocationRecord } from "@hotel-engine/types/locations";

import { endpoints } from "../constants";
import { useApi } from "../useApi";
import useDebouncedQuery from "../useDebouncedQuery";

export const useSearchLocationsQuery = (
  params: {
    query?: string;
    includeWorksites?: boolean;
    includeAddress?: boolean;
    excludePlaces?: boolean;
  },
  options?: UseQueryOptions<ILocationRecord[], IErrorResponse, ILocationRecord[]>
) => {
  const get = useApi("get");
  return useDebouncedQuery<ILocationRecord[], IErrorResponse>(
    [endpoints.locations, params],
    () =>
      get<ILocationRecord[]>(endpoints.locations, {
        includeWorksites: true,
        includeAddress: true,
        ...params,
      }),
    options
  );
};
