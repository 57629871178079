import type { ReactNode } from "react";
import type { IOccupant } from "@hotel-engine/types/booking";
import { Avatar, Box, Divider, Icon, Typography } from "@hotelengine/atlas-web";
import { TravelerInfoBlock, TravelerInfoBlockContentGrid } from "./styles";
import { CollapsibleContent } from "../../../shared/CollapsibleContent";

interface ITravelerInfoBlockContentProps {
  leftContent: string | undefined;
  iconLogo?: ReactNode;
  rightContent?: string;
  isSheet?: boolean;
}

export const MAX_TRAVELERS_DISPLAYED = 8;

export const dedupeTravelers = (travelers: IOccupant[]) =>
  travelers.reduce((acc: IOccupant[], cur: IOccupant) => {
    const duplicateTraveler = acc.find((traveler) => {
      const idsMatch = traveler.occupantId === cur.occupantId;
      // Checking names in addition to occupantId since non-user guests will not have an occupantId, but it will match because it is null
      const namesMatch =
        `${traveler.firstName} ${traveler.lastName}` === `${cur.firstName} ${cur.lastName}`;

      return idsMatch && namesMatch;
    });

    if (!duplicateTraveler) {
      acc.push(cur);
    }

    return acc;
  }, []);

export const TravelerInfoBlockContent = ({
  iconLogo,
  leftContent,
  rightContent,
  isSheet,
}: ITravelerInfoBlockContentProps) => {
  return (
    <TravelerInfoBlockContentGrid noRightContent={!rightContent} isSheet={isSheet}>
      {!!iconLogo && <Box>{iconLogo}</Box>}
      <Box>{leftContent}</Box>
      {!!rightContent && <Box className="right">{rightContent}</Box>}
    </TravelerInfoBlockContentGrid>
  );
};

export const getTravelerList = (dedupedTravelers: IOccupant[]) => {
  return dedupedTravelers.slice(0, MAX_TRAVELERS_DISPLAYED).map((traveler) => {
    const visibleContent = (
      <Box display="flex" flexDirection="row" alignItems="center" gap={8}>
        <Avatar name={`${traveler.firstName} ${traveler.lastName}`} />
        <Typography variant="body/md" color="foregroundPrimary">
          {`${traveler.firstName} ${traveler.lastName}`}
        </Typography>
      </Box>
    );

    const hideDropdown = !traveler.phone && !traveler.email && !traveler.loyaltyRewardName;

    const travelerRowProps: ITravelerInfoBlockContentProps[] = [
      {
        iconLogo: <Icon name="circle-info" color="foregroundPrimary" />,
        leftContent: traveler.email,
      },
      {
        iconLogo: <Icon name="phone" color="foregroundPrimary" />,
        leftContent: traveler.phone,
      },
      {
        iconLogo: <Icon name="he-loyalty--solid" color="accentPurple" />,
        leftContent: traveler.loyaltyRewardName,
        rightContent: traveler.loyaltyRewardProgramNumber,
      },
    ];

    const hiddenContent = (
      <Box display="flex" flexDirection="column">
        {travelerRowProps.map(
          (row) =>
            !!row.leftContent && (
              <TravelerInfoBlock key={row.leftContent}>
                <TravelerInfoBlockContent {...row} />
              </TravelerInfoBlock>
            )
        )}
      </Box>
    );

    return (
      <CollapsibleContent
        key={`${traveler.firstName}__${traveler.id}`}
        variant="sm"
        toggleBtnLabel={visibleContent}
        hideDropdown={hideDropdown}
      >
        {hiddenContent}
      </CollapsibleContent>
    );
  });
};

export const getTravelerSheet = (dedupedTravelers: IOccupant[]) => {
  return dedupedTravelers.map((traveler) => {
    const travelerRowProps: ITravelerInfoBlockContentProps[] = [
      {
        leftContent: "Email",
        rightContent: traveler.email,
      },
      {
        leftContent: "Phone number",
        rightContent: traveler.phone,
      },
      {
        leftContent: traveler.loyaltyRewardName,
        rightContent: traveler.loyaltyRewardProgramNumber,
      },
    ];

    return (
      <Box
        key={`${traveler.id}-${traveler.firstName}`}
        paddingBottom={24}
        style={{ height: "100%" }}
      >
        <Typography variant="body/md" color="foregroundPrimary" marginBottom={12}>
          {traveler.firstName} {traveler.lastName}
        </Typography>
        <Divider variant="dotted" />
        <Box marginTop={12}>
          {travelerRowProps.map(
            (row, i) =>
              !!row.rightContent && (
                <TravelerInfoBlockContent
                  {...row}
                  key={`${row.leftContent || i}-${traveler.firstName}`}
                  isSheet
                />
              )
          )}
        </Box>
      </Box>
    );
  });
};
