import { useState, type MouseEvent } from "react";

import {
  BottomSheet,
  BottomSheetClose,
  BottomSheetTitle,
  BottomSheetTrigger,
  Button,
  Icon,
  InputIconAdornment,
  Typography,
  TextInput,
} from "@hotelengine/atlas-web";
import type { Moment } from "moment";
import moment from "moment";
import { DayPickerRangeController } from "react-dates";
import { END_DATE, START_DATE, VERTICAL_SCROLLABLE } from "react-dates/constants";

import { useFormatDate } from "@hotel-engine/hooks/useFormatDate";

import * as Styled from "./styles";
import { InputItem } from "../../styles";
import type { ISearchModel } from "../../../../types";
import { useFormikContext } from "formik";
import { defaultPickupDropoffDateTimes } from "pages/Cars/Search/helpers";

type FocusedInput = END_DATE | START_DATE | null;

interface IMobileFlighDateSelectorInput {
  className?: string;
  isInvalid?: boolean;
  focusedInput: FocusedInput;
  setFocusedInput: (focusedInput: FocusedInput) => void;
  calendarChangeHandler: (startDate: Moment | null, endDate: Moment | null) => void;
}

export const MobileDatesSelector = ({
  className,
  focusedInput,
  isInvalid = false,
  calendarChangeHandler,
  setFocusedInput,
}: IMobileFlighDateSelectorInput) => {
  const today = moment();
  const { values, setFieldValue } = useFormikContext<ISearchModel>();

  const [open, setOpen] = useState(focusedInput !== null);

  const defaultDateTimes = defaultPickupDropoffDateTimes(values);
  const pickupDate = useFormatDate(defaultDateTimes.pickupDate, "ddd, MMM D");
  const dropoffDate = useFormatDate(defaultDateTimes.dropoffDate, "ddd, MMM D");

  const clickdropoffDate = (e: MouseEvent) => {
    e.stopPropagation();
    setFocusedInput(END_DATE);
    setOpen(true);
  };

  const dates = (
    <InputItem
      mobile
      error={isInvalid}
      onClick={() => {
        setFocusedInput(START_DATE);
        setOpen(true);
      }}
      className={className}
    >
      <Icon name="calendar--solid" color={"inputForegroundHelper"} />
      <Styled.pickupButton
        onClick={() => {
          setFocusedInput(START_DATE);
          setOpen(true);
        }}
        data-testid="pickupButton"
        type="button"
      >
        {pickupDate}
      </Styled.pickupButton>

      <Styled.dropoffButton onClick={clickdropoffDate} data-testid="dropoffButton" type="button">
        - {dropoffDate}
      </Styled.dropoffButton>
    </InputItem>
  );

  const mobileCalendar = (
    <Styled.DateCalendar isFocused={true}>
      <DayPickerRangeController
        startDate={defaultDateTimes.pickupDate}
        endDate={defaultDateTimes.dropoffDate}
        onDatesChange={({ startDate, endDate }) => {
          calendarChangeHandler(startDate, endDate);
        }}
        focusedInput={focusedInput}
        onFocusChange={(focus: FocusedInput) => {
          setFocusedInput(focus || START_DATE);
        }}
        initialVisibleMonth={() => today}
        noBorder
        orientation={VERTICAL_SCROLLABLE}
        isDayBlocked={(val) => {
          const nextDay = moment().clone().add(1, "day");
          return val.isBefore(nextDay, "day");
        }}
        numberOfMonths={15}
        minimumNights={0}
        hideKeyboardShortcutsPanel
        keepOpenOnDateSelect
        navPrev={<div />}
        navNext={<div />}
      />
    </Styled.DateCalendar>
  );

  const bottomSheetHeader = (
    <Styled.StyledBottomSheetHeader>
      <BottomSheetTitle>Select Dates</BottomSheetTitle>
      <Icon name="xmark" onClick={() => setOpen(false)} />
    </Styled.StyledBottomSheetHeader>
  );

  const bottomSheetFooter = (
    <Styled.StyledBottomSheetFooter gap={20}>
      <Styled.FooterInfoContainer display="flex" justifyContent="space-between">
        <Styled.FooterColumnInfo
          display="flex"
          flexDirection="column"
          onClick={() => setFocusedInput(START_DATE)}
        >
          <Styled.LocationInfo>
            <Typography variant="body/md" color="foregroundSecondary">
              Pick-up
            </Typography>
            {focusedInput === START_DATE && <Styled.LocationMarker />}
          </Styled.LocationInfo>
          <Typography variant="body/md-strong" color="foregroundPrimary">
            {pickupDate}
          </Typography>

          <InputItem noIconLeft marginTop="4px">
            <TextInput
              type="time"
              data-testid="mobile-pickup-time-picker"
              step={900}
              defaultValue={defaultDateTimes.pickupTime.format("HH:mm")}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => setFieldValue("pickupTime", moment(e.target.value, "HH:mm"))}
              trailingAdornment={<InputIconAdornment name="angles-up-down" />}
            />
          </InputItem>
        </Styled.FooterColumnInfo>

        <Styled.FooterColumnInfo
          display="flex"
          flexDirection="column"
          onClick={() => setFocusedInput(END_DATE)}
        >
          <Styled.LocationInfo>
            <Typography variant="body/md" color="foregroundSecondary">
              Drop-off
            </Typography>
            {focusedInput === END_DATE && <Styled.LocationMarker />}
          </Styled.LocationInfo>
          <Typography variant="body/md-strong" color="foregroundPrimary">
            {dropoffDate}
          </Typography>
          <InputItem noIconLeft marginTop="4px">
            <TextInput
              type="time"
              data-testid="mobile-dropoff-time-picker"
              step={900}
              defaultValue={defaultDateTimes.dropoffTime.format("HH:mm")}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                setFieldValue("dropoffTime", moment(e.target.value, "HH:mm"));
              }}
              trailingAdornment={<InputIconAdornment name="angles-up-down" />}
            />
          </InputItem>
        </Styled.FooterColumnInfo>
      </Styled.FooterInfoContainer>
      <BottomSheetClose asChild>
        <Button
          data-testid="mobile-submit-dates"
          onClick={() => {
            setFocusedInput(null);
            setOpen(false);
          }}
        >
          Select dates
        </Button>
      </BottomSheetClose>
    </Styled.StyledBottomSheetFooter>
  );

  return (
    <BottomSheet
      isOpen={open}
      snapPoints={[1]}
      onIsOpenChange={(bottomSheetOpen) => {
        if (!bottomSheetOpen && !focusedInput) {
          setOpen(false);
          setFocusedInput(null);
        }
      }}
      header={bottomSheetHeader}
      footer={bottomSheetFooter}
    >
      <BottomSheetTrigger asChild>{dates}</BottomSheetTrigger>
      <Styled.StyledBottomSheetContent>
        <Styled.DateCalendar isFocused={true}>{mobileCalendar}</Styled.DateCalendar>
      </Styled.StyledBottomSheetContent>
    </BottomSheet>
  );
};
