import { useEffect, useState } from "react";

import PhoneInput from "react-phone-input-2";

import { returnLegacyNumber } from "pages/Settings/components/MyProfile/helpers";

import { countriesPhoneMasksData } from "../InternationalPhoneInput/constants";
import * as Styled from "./styles";

/**
 * FormatInternationalPhoneNumbers leverages the PhoneInput formatter found in the react-phone-input-2 and accepts styling to display as the formatted number as a text element
 * @see {@link https://github.com/bl00mber/react-phone-input-2 React-Phone-Input-2 Documentation}
 */
export const FormatInternationalPhoneNumbers = ({
  countryCode = "us",
  phoneNumber,
}: {
  countryCode?: string;
  phoneNumber?: string;
}) => {
  const [formattedValue, setFormattedValue] = useState("");
  useEffect(() => {
    if (phoneNumber) {
      setFormattedValue(returnLegacyNumber(phoneNumber));
    }
  }, [countryCode, phoneNumber]);

  const masks = {};
  countriesPhoneMasksData.forEach((country) => {
    masks[country.iso.toLowerCase()] = (
      Array.isArray(country.mask) ? country.mask[0] : country.mask
    ).replace(/\d/g, ".");
  });

  return (
    <Styled.DisabledInternationalPhoneInputWrapper>
      <PhoneInput
        country={countryCode}
        disableDropdown
        inputProps={{
          disabled: true,
          "data-testid": "international-phone-format",
          "data-private": true,
        }}
        masks={masks}
        placeholder="---"
        value={formattedValue}
      />
    </Styled.DisabledInternationalPhoneInputWrapper>
  );
};
