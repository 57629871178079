import { useMutation, useQueryClient } from "react-query";
import type { UseMutationOptions } from "react-query";

import { endpoints } from "@hotel-engine/react-query/constants";
import { convertToSnake, useApi } from "@hotel-engine/react-query/useApi";
import type { IAxiosBatchError } from "@hotel-engine/types/errors";
import type {
  ICreateSalesforceCaseParams,
  ISalesforceCase,
} from "@hotel-engine/types/salesforceCase";

const useCreateSalesforceCase = (
  options?: UseMutationOptions<ISalesforceCase, IAxiosBatchError, ICreateSalesforceCaseParams>
) => {
  const post = useApi("post");
  const queryClient = useQueryClient();

  return useMutation((params) => post(endpoints.salesforceCase, convertToSnake(params)), {
    onSuccess: (_, params) =>
      queryClient.invalidateQueries([
        endpoints.salesforceCase,
        { contractNumber: params.contractNumber },
      ]),
    ...options,
  });
};

export { useCreateSalesforceCase };
