import { Box, type BoxProps } from "@hotelengine/atlas-web";

import styled, { css } from "styled-components";

export const CancelledBannerContainer = styled<React.FC<BoxProps>>(Box)<{ $isRefresh?: boolean }>`
  width: 100%;
  height: ${({ theme }) => theme.spacing[40]};
  margin-bottom: ${({ theme, $isRefresh }) => theme.spacing[$isRefresh ? 0 : 12]};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.accentRedLightest};

  ${({ $isRefresh, theme }) =>
    !!$isRefresh &&
    css`
        border-bottom: ${`${theme.borderWidth.normal} solid ${theme.colors.accentRedLight}`}
    `}
`;
