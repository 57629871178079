import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import { useState } from "react";
import { ampli } from "ampli";

import { useAppSelector } from "store/hooks";

import { routes } from "@hotel-engine/constants";
import HidePropertyModal from "@hotel-engine/app/modals/HidePropertyModal";
import UnhidePropertyModal from "@hotel-engine/app/modals/UnhidePropertyModal";
import { useDownloadItineraryDocument } from "@hotel-engine/react-query/downloadItineraryDocument/useDownloadItineraryDocument";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import isStateOfTexasUser from "@hotel-engine/utilities/helpers/isStateOfTexasUser";
import { captureMessage } from "@hotel-engine/utilities";
import { notification } from "@hotel-engine/common/Notifications";
import { Icon } from "@hotelengine/atlas-web";

import type { IButtonListItem } from "../ActionButtonLayout";
import ActionButtonLayout from "../ActionButtonLayout";
import { usePastCancelledActions } from "./usePastCancelledActions";

export type IItineraryActionsProps = {
  itinerary: IReservationBase;
};

const PastCancelledActions = ({ itinerary }: IItineraryActionsProps) => {
  const navigate = useNavigate();
  const [showHidePropertyModal, setShowHidePropertyModal] = useState(false);
  const [showUnhidePropertyModal, setShowUnhidePropertyModal] = useState(false);
  const property = useAppSelector((state) => state.Property.property);
  const isPropertyHidden = property?.hiddenPropertyId || false;

  const user = useAppSelector((state) => state.Auth.user);
  const isSot = isStateOfTexasUser(user);

  const { showHidePropertyButton, isEligibleToReportCheckoutDate } =
    usePastCancelledActions(itinerary);

  const {
    refetch: fetchFolioPdf,
    isLoading: loadingFolioPdf,
    isFetching: fetchingFolioPdf,
  } = useDownloadItineraryDocument({
    contractNumber: itinerary.id,
    documentType: "folio",
  });

  const isLoadingFolio = loadingFolioPdf || fetchingFolioPdf;

  const handleHidePropertyClick = () => {
    if (isPropertyHidden) {
      setShowUnhidePropertyModal(true);
    } else {
      setShowHidePropertyModal(true);
    }
  };

  const handleModifyReservation = () => {
    ampli.clickModifyTripAtCheckout({
      propertyId: Number(itinerary.propertyId),
    });

    navigate(`${routes.modifyReservation}/${itinerary.id}`, {
      state: {
        itinerary,
      },
    });
    ampli.clickModifyThisReservation({
      bookingId: itinerary.id,
    });
  };

  const handleDocument = async () => {
    try {
      await fetchFolioPdf();
    } catch (err) {
      notification.open({
        message: "There was an error getting your document.",
        duration: 8,
        icon: <Icon name="circle-exclamation" />,
        description: "Please try again later or contact support for assistance.",
      });

      captureMessage("Error fetching document", {
        error: new Error(`Could not fetch folio: ${err}`),
      });
    }
  };

  const buttonList: IButtonListItem[] = [
    {
      action: handleHidePropertyClick,
      dataTestId: "hide-property",
      displayCriteria: showHidePropertyButton,
      text: `${isPropertyHidden ? "Unhide" : "Hide"} property`,
    },
    {
      action: handleDocument,
      dataTestId: "action-view-folio",
      displayCriteria: !isSot,
      text: "View folio",
      isLoading: isLoadingFolio,
    },
    {
      action: handleModifyReservation,
      dataTestId: "modify-or-cancel",
      displayCriteria: isEligibleToReportCheckoutDate,
      text: "Report early checkout",
    },
  ];

  if (!property) {
    return null;
  }

  return (
    <>
      <ActionButtonLayout showFlexMessage={false} renderList={buttonList} />
      {!!showHidePropertyModal && (
        <HidePropertyModal
          propertyId={property.id}
          onClose={() => setShowHidePropertyModal(false)}
          onHideSuccessful={() => setShowHidePropertyModal(false)}
          scope="user"
        />
      )}
      {!!showUnhidePropertyModal && (
        <UnhidePropertyModal
          hiddenPropertyId={property.hiddenPropertyId}
          onClose={() => setShowUnhidePropertyModal(false)}
          onUnhideSuccessful={() => setShowUnhidePropertyModal(false)}
          propertyId={property.id}
          scope="user"
        />
      )}
    </>
  );
};

export default PastCancelledActions;
