import type { UseQueryOptions } from "react-query";

import type { IDepartmentRecord } from "@hotel-engine/types/department";
import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { PaginatedData } from "@hotel-engine/types/generic";

import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";
import { cacheKeys, endpoints } from "../constants";

type IDepartmentsResponse = PaginatedData<IDepartmentRecord>;

interface IParams {
  limit?: number;
}

export interface IDepartmentsQueryConfig {
  options?: UseQueryOptions<IDepartmentsResponse, IErrorResponse, IDepartmentRecord[]>;
  params?: IParams;
}

const useDepartmentsQuery = ({ options = {}, params = {} }: IDepartmentsQueryConfig = {}) => {
  const get = useApi("get");
  // set default limit to 5000 to support select dropdown
  // this is for mvp and should be updated to a new UX which doesn't require this
  const { limit = 5000 } = params;

  const queryKey = cacheKeys.useDepartmentsQuery;

  const queryFn = () => get<IDepartmentsResponse>(endpoints.departments, { limit, ...params });

  return useExtendedQuery<IDepartmentsResponse, IErrorResponse, IDepartmentRecord[]>(
    queryKey,
    queryFn,
    options
  );
};

export { useDepartmentsQuery };
