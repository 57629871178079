import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { IMemberRecord } from "@hotel-engine/types/member";
import type { IRole } from "@hotel-engine/types/role";
import type { PaginatedData, PaginatedParams } from "@hotel-engine/types/generic";

import { cacheKeys, endpoints } from "../constants";
import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";

type IMembersResponse = PaginatedData<IMemberRecord>;

export type ISort = "nameOrEmail" | "departmentName" | "lastSignInAt" | "travelPolicyName";

export interface IMembersParams {
  limit?: number;
  offset?: number;
  departmentId?: string;
  travelPolicyId?: string;
  sort?: ISort;
  order?: PaginatedParams["direction"];
  nameOrEmail?: string;
  role?: IRole;
}

const useMembersQuery = (params: IMembersParams = {}) => {
  const get = useApi("get");
  const { departmentId, travelPolicyId, sort, order, role, nameOrEmail, limit, offset } = params;

  const queryKey = [
    cacheKeys.useMembersQuery,
    travelPolicyId,
    departmentId,
    sort,
    order,
    role,
    nameOrEmail,
    limit,
    offset,
  ];
  const queryFn = () => get<IMembersResponse>(endpoints.members, { ...params }, {});

  return useExtendedQuery<IMembersResponse, IErrorResponse[], IMemberRecord[]>(queryKey, queryFn);
};

export { useMembersQuery };
