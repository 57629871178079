import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";

import { Button, InputIconAdornment, TextInput } from "@hotelengine/atlas-web";
import { useFormikContext } from "formik";
import { useIsMobile } from "pages/Cars/hooks/useIsMobile";
import { useEffect } from "react";
import { defaultPickupDropoffDateTimes } from "../../helpers";
import type { ISearchModel } from "../../types";
import { GoogleAutocompleteInput } from "../GoogleAutocompleteInput/GoogleAutocompleteInput";
import { ValidationError } from "../ValidationError";
import CarsSearchHeaderMobile from "./Mobile";
import { DateTimeRangePicker } from "./components/DateRangePicker";
import { Adornment, HeaderContainer, InputGroup, InputItem, InputWrapper } from "./styles";

interface ICarsSearchHeaderProps {
  isFilterOpen?: boolean;
  isFiltered?: boolean;
  openFilter?: () => void;
  isCompact?: boolean;
  isLoading?: boolean;
}

export const SAME_DROP_OFF = "Same drop-off";

export default function CarsSearchHeader({
  isFilterOpen = false,
  isFiltered = false,
  openFilter,
  isCompact = false,
  isLoading,
}: ICarsSearchHeaderProps) {
  const { values, errors, setFieldValue, validateForm, submitForm } =
    useFormikContext<ISearchModel>();
  const isMobile = useIsMobile();
  const allowDifferentDropOffLocation = useIsFeatureFlagOn("cars-different-drop-off-location");
  const onSubmitForm = async () => {
    const formValidation = await validateForm();
    if (Object.keys(formValidation).length) return;

    return submitForm();
  };

  useEffect(() => {
    if (!values.pickupDate && !values.dropoffDate) {
      const defaultDateTimes = defaultPickupDropoffDateTimes();

      setFieldValue("pickupDate", defaultDateTimes.pickupDate);
      setFieldValue("pickupTime", defaultDateTimes.pickupTime);
      setFieldValue("dropoffDate", defaultDateTimes.dropoffDate);
      setFieldValue("dropoffTime", defaultDateTimes.dropoffTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isMobile) {
    return (
      <CarsSearchHeaderMobile
        isFilterOpen={isFilterOpen}
        isFiltered={isFiltered}
        openFilter={openFilter}
      />
    );
  }

  return (
    <HeaderContainer $compact={isCompact} data-testid="cars-search-header">
      <InputGroup>
        <InputWrapper $compact={isCompact}>
          <InputItem className="first">
            <GoogleAutocompleteInput
              id="pickupLocationName"
              iconName="car-circle-arrow-up"
              placeholder="Pick-up location"
              value={values?.pickupLocationName ?? ""}
              onUpdateValue={(value) => setFieldValue("pickupLocationName", value)}
              onSelect={(location) => {
                setFieldValue("pickupLocationName", location.name);
                setFieldValue("pickupLocationId", location.id);

                if (
                  !allowDifferentDropOffLocation ||
                  !values?.dropoffLocationId ||
                  values?.dropoffLocationName === SAME_DROP_OFF
                ) {
                  setFieldValue("dropoffLocationName", SAME_DROP_OFF);
                  setFieldValue("dropoffLocationId", location.id);
                }
              }}
              onClear={() => {
                setFieldValue("pickupLocationName", null);
                setFieldValue("pickupLocationId", null);
              }}
            />
          </InputItem>
          {!!errors.pickupLocationId && (
            <ValidationError isMobileScreen={false} error={errors.pickupLocationId} />
          )}
        </InputWrapper>
        <InputWrapper $disabled={!allowDifferentDropOffLocation} $compact={isCompact}>
          <InputItem className="last">
            <GoogleAutocompleteInput
              id="dropoffLocationName"
              iconName="car-circle-arrow-down"
              placeholder="Drop-off location"
              value={values?.dropoffLocationName ?? ""}
              onUpdateValue={(value) => setFieldValue("dropoffLocationName", value)}
              onSelect={(location) => {
                setFieldValue("dropoffLocationName", location.name);
                setFieldValue("dropoffLocationId", location.id);
              }}
              onBlur={(value) => {
                if (value === "" && !values?.dropoffLocationName)
                  setFieldValue("dropoffLocationName", "Same drop-off");
              }}
              onClear={(e) => {
                e.stopPropagation();
                setFieldValue("dropoffLocationName", null);
                setFieldValue("dropoffLocationId", values?.pickupLocationId ?? null);
              }}
            />
          </InputItem>
          {!!errors.dropoffLocationId && (
            <ValidationError isMobileScreen={false} error={errors.dropoffLocationId} />
          )}
        </InputWrapper>
      </InputGroup>

      <InputWrapper $compact={isCompact} className="date-picker">
        <DateTimeRangePicker />
        {!!errors.age && <ValidationError isMobileScreen={false} error={errors.pickupDate} />}
      </InputWrapper>

      <InputWrapper className="age" $compact={isCompact}>
        <TextInput
          size="lg"
          leadingAdornment={
            <span>
              <Adornment>
                <InputIconAdornment name="id-card" />
              </Adornment>
            </span>
          }
          name="age"
          data-testid="cars-age-input"
          max={110}
          value={values?.age ?? undefined}
          onChange={({ target: { value: age } }) => setFieldValue("age", age)}
          placeholder={isCompact ? "Age" : undefined}
        />
        {!!errors.age && <ValidationError isMobileScreen={false} error={errors.age} />}
      </InputWrapper>

      <div>
        <Button
          color="accent"
          leadingIcon="magnifying-glass"
          type="button"
          size="lg"
          data-testid="cars-search-button"
          style={{ borderRadius: 999 }}
          onClick={onSubmitForm}
          disabled={isLoading}
        >
          Search
        </Button>
      </div>
    </HeaderContainer>
  );
}
