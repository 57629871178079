import { useEffect, useState } from "react";
import { ampli } from "ampli";

import { Box, Icon, Tooltip, Typography, useTheme } from "@hotelengine/atlas-web";
import { useAppSelector } from "store/hooks";

let timeoutId: NodeJS.Timeout;

export interface ICopiableConfirmationProps {
  confirmationNumber: string;
  bookingNumber: string;
}

const CopiableConfirmation = ({
  confirmationNumber,
  bookingNumber,
}: ICopiableConfirmationProps) => {
  const { tokens } = useTheme();
  const user = useAppSelector((state) => state.Auth.user);
  const [isCopied, setIsCopied] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const flushTimeout = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };

  const copyToClipboard = async () => {
    flushTimeout();

    await navigator.clipboard.writeText(confirmationNumber);

    setIsCopied(true);

    timeoutId = setTimeout(() => {
      setIsCopied(false);
    }, 3000);

    if (user) {
      ampli.clickCopyConfirmationNumber({
        flightBookingID: bookingNumber,
        userId: user.id,
      });
    }
  };

  useEffect(() => flushTimeout, []);

  return (
    <Box display="flex" justifyContent="center">
      <Tooltip
        content={isCopied ? "Copied!" : "Copy to clipboard"}
        isOpen={isMouseOver || isCopied}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={8}
          color="accentBlueStronger"
          onClick={copyToClipboard}
          onMouseOver={() => setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
          style={{
            cursor: "pointer",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: tokens.colors.borderMinimal,
            borderRadius: tokens.borderRadius[32],
            padding: "3px 16px",
          }}
        >
          <Box display="flex" gap={4} alignItems="center">
            <Typography as="span" variant="body/md">
              Confirmation
            </Typography>
            <Typography as="span" variant="body/md-strong">
              # {confirmationNumber}
            </Typography>
          </Box>
          <Icon name={isCopied ? "check" : "copy"} color="accentBlueStronger" size="md" />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default CopiableConfirmation;
