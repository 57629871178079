import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import { Icon } from "@hotel-engine/common/Icon";
import { colors } from "@hotel-engine/styles";

export const ModalNavBarContainer = styled.div`
  position: relative;
  height: ${({ theme }) => theme.legacy.space(4)};
  width: 100%;
`;

export const LeftWrapper = styled.div`
  position: absolute;
  left: 0;
`;
export const RightWrapper = styled.div`
  position: absolute;
  right: 0;
`;

export const CloseIconButton = styled(Button)`
  border-radius: ${({ theme }) => theme.legacy.space(4)};
  padding: 8px;
  width: 32px;
  height: 32px;
  position: relative;
`;
export const GoBackIconButton = styled(Button)`
  color: ${colors.retroBlue};
  border: 0px;
  padding-left: 0px;
`;

export const CloseIcon = styled(Icon)`
  position: absolute;
  top: 0px;
  transform: translate(-50%, 50%);
`;
export const GoBackIcon = styled(Icon)``;
