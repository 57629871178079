/**
+ * A formatter to properly handle international and "local"/US property locations
* @remarks This is to handle potentially null country data and preserve US based and International formatting
*/
export interface IFormatPropertyLocation {
  propertyCity?: string | null;
  propertyCountry?: string | null;
  propertyState?: string | null;
}

export const formatPropertyLocation = (property: IFormatPropertyLocation) => {
  const { propertyCountry, propertyState, propertyCity } = property;

  if (propertyCountry === "US" || (!propertyCountry && propertyState)) {
    return `${propertyCity}, ${propertyState}`;
  } else if (propertyCountry) {
    return `${propertyCity}, ${propertyCountry}`;
  }
  return `${propertyCity}`;
};

/**
 * Reformat all occurrences of "sofabed"  to Sofa Bed preserving the casing pattern of the surrounding text
 * @param description a room description containing the word "sofabed"
 * @returns a room description with the word "sofabed" replaced with "Sofa Bed"
 */
export const formatRoomDescription = (description: string) => {
  const possibleBedFormats = [
    { current: "sofabed", replacement: "sofa bed" },
    { current: "Sofabed", replacement: "Sofa Bed" },
    { current: "SOFABED", replacement: "SOFA BED" },
  ];

  let formattedDescription = description;

  possibleBedFormats.forEach((bedFormat) => {
    formattedDescription = formattedDescription.replace(bedFormat.current, bedFormat.replacement);
  });
  return formattedDescription;
};
