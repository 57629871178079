import { type UseMutationOptions, useMutation } from "react-query";

import { useApi } from "@hotel-engine/react-query/useApi";
import { endpoints } from "@hotel-engine/react-query/constants";
import type { IErrorResponse } from "../../types/errors";

export interface ICancelParams {
  id: number;
  bookingType: "flight" | "car";
}

export interface ICancelBody {
  bookingNumber: string;
  bookingType: "flight" | "lodging" | "car";
}

export const useSubmitCancelQuery = (
  params: ICancelParams,
  options?: UseMutationOptions<void, IErrorResponse, ICancelBody>
) => {
  const put = useApi("put");

  return useMutation(
    [endpoints.trips, params],
    (body) => put(`${endpoints.trips}/${params.id}/cancellation`, body),
    options
  );
};
