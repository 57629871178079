import type { ReactNode } from "react";
import config from "config";
import { Auth0Provider } from "@auth0/auth0-react";
import { Auth0ContextConsumer } from "./Auth0ContextConsumer";
import { useAppDispatch } from "store/hooks";
import { handleAuth0Callback } from "./handleAuth0Callback";
import { MFA_SCOPES } from "../../../pages/Settings/components/AccountSecurity";

interface IAuth0ContextProviderProps {
  /** React children */
  children: ReactNode;
}

export const Auth0ContextProvider = ({ children }: IAuth0ContextProviderProps) => {
  const dispatch = useAppDispatch();
  return (
    <Auth0Provider
      domain={config.auth0Domain}
      clientId={config.auth0ClientId}
      // note: any scope that will ever be needed _must_ be included in this provider,
      // even if the scopes are only needed in certain components.
      // otherwise, you will get an error about a "missing refresh token".
      authorizationParams={{
        audience: config.auth0Audience,
        scope: `openid profile email offline_access ${MFA_SCOPES}`,
      }}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      onRedirectCallback={handleAuth0Callback(dispatch)}
    >
      <Auth0ContextConsumer>{children}</Auth0ContextConsumer>
    </Auth0Provider>
  );
};

export default Auth0ContextProvider;
