import type { MapPosition } from "../../../types";
import OverlayView from "../../OverlayView";
import BaseMarker from "../BaseMarker";
import * as Styled from "../styles";

type GoogleMapsItineraryMarkerProps = {
  /** the map object this marker is being applied to */
  map: google.maps.Map;
  /** the lat, lng of the marker */
  position: MapPosition;
  /** Airport city and code */
  airport: string;
  /** Used for inverted flight pin to avoid overlap of flight path */
  inverted: boolean;
};

const AiportMarker = ({ map, position, airport, inverted }: GoogleMapsItineraryMarkerProps) => {
  return (
    <OverlayView position={position} map={map}>
      <BaseMarker inverted={inverted} padded={true}>
        <Styled.AirportMarker data-testid="itinerary-marker" $inverted={inverted}>
          <Styled.OverflowType as="p" variant="xsmall">
            {airport}
          </Styled.OverflowType>
        </Styled.AirportMarker>
      </BaseMarker>
    </OverlayView>
  );
};
export default AiportMarker;
