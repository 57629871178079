import moment from "moment";
import type { IReservationBase } from "../../../types/reservation";
import type { IReservationRoom } from "../../../types/booking";

export const areRoomDatesDifferent = (reservation: IReservationBase) => {
  const allCheckInDatesSame = reservation.rooms?.every((room) =>
    moment(room.checkIn).isSame(moment(reservation.rooms[0].checkIn))
  );
  const allCheckOutDatesSame = reservation.rooms?.every((room) =>
    moment(room.checkOut).isSame(moment(reservation.rooms[0].checkOut))
  );

  return !allCheckInDatesSame || !allCheckOutDatesSame;
};

export const isRoomModified = (room: IReservationRoom) => {
  const isCheckInModified = !moment(room.checkIn).isSame(moment(room.originalCheckIn));
  const isCheckOutModified = !moment(room.checkOut).isSame(moment(room.originalCheckOut));

  return isCheckInModified || isCheckOutModified;
};
