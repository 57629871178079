import type { IGooglePrediction, IRecentSearch } from "@hotel-engine/types/search";
import type { ILocationRecord } from "@hotel-engine/types/locations";
import type { IWorksite } from "@hotel-engine/types/worksite";
import { captureMessage } from "@hotel-engine/utilities";
import ApiSearchResult from "../components/ApiSearchResult";
import GoogleSearchResult from "../components/GoogleSearchResult";
import RecentSearches from "../components/RecentSearches";

/** type guard for ILocationRecords on Autocomplete search */
export function isPreferredProperty(location: unknown): location is ILocationRecord {
  return (
    !!(location as ILocationRecord)?.types &&
    (location as ILocationRecord)?.types.includes("preferred")
  );
}

/** type guard for ILocationRecords on Autocomplete search */
export function isFavoriteProperty(location: unknown): location is ILocationRecord {
  return (
    !!(location as ILocationRecord)?.types &&
    (location as ILocationRecord)?.types.includes("favorite")
  );
}

/** type guard for ILocationRecords on Autocomplete search */
export function isSearchLocationRecord(location: unknown): location is ILocationRecord {
  return (location as ILocationRecord)?.types !== undefined;
}

/** type guard for IGooglePrediction. */
export function isGooglePrediction(location: unknown): location is IGooglePrediction {
  return (location as IGooglePrediction)?.place_id !== undefined;
}

/** type guard for IRecentSearch on Autocomplete search */
export function isRecentSearch(location: unknown): location is IRecentSearch {
  const hasRecentSearchType = ((location as IRecentSearch)?.types ?? []).includes("recentSearch");
  const hasCreatedAtDate = (location as IRecentSearch)?.createdAt !== undefined;

  return hasRecentSearchType || hasCreatedAtDate;
}

/** type guard for IWorksite on Autocomplete search */
export function isWorksite(location: unknown): location is IWorksite {
  return (location as IWorksite)?.name !== undefined;
}

/** return function leveraging type guards to return appropriate search item component from mixed type array */
export function returnSearchItemType(searchItem: unknown, searchInput: string) {
  if (isGooglePrediction(searchItem)) {
    return <GoogleSearchResult item={searchItem} searchInput={searchInput} />;
  } else if (isRecentSearch(searchItem)) {
    return <RecentSearches item={searchItem} searchInput={searchInput} />;
  } else if (
    isWorksite(searchItem) ||
    isSearchLocationRecord(searchItem) ||
    isFavoriteProperty(searchItem) ||
    isPreferredProperty(searchItem)
  ) {
    return <ApiSearchResult item={searchItem} searchInput={searchInput} />;
  } else {
    captureMessage("Unknown object type in search results", {
      error: searchItem,
    });

    return;
  }
}
