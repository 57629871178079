import { useCallback, useMemo } from "react";
import { v4 as uuidV4 } from "uuid";
import { useNavigate, useParams } from "@hotel-engine/lib/react-router-dom";
import moment from "moment";

import { routes } from "@hotel-engine/constants";
import { buildQueryString } from "@hotel-engine/app/Header/helpers";
import type {
  FlightLocation,
  FlightSliceCriteria,
  IFlightSearchFormValues,
  Passengers,
} from "@hotel-engine/types/flights/flights.form.types";
import { useFlightSearchParamsContext } from "pages/Flights/contexts/FlightSearchParamsContext";
import { TripType } from "@hotelengine/core-booking-web";

interface IOnSubmitSearchCriteriaArgs {
  baseUrl?: string;
  path?: string;
  replace?: boolean;
  newValues?: {
    tripType: TripType;
    passengers: Passengers;
    slicesCriteria: FlightSliceCriteria[];
  };
  additionalQueryParams?: {
    [key: string]: unknown;
  };
  newSearch?: boolean;
  keepPreviousParams?: boolean;
}

export const useFlightSearchParams = () => {
  const navigate = useNavigate();
  const urlParams = useFlightSearchParamsContext();
  const { slice = "0" } = useParams();

  const onSubmitSearchCriteria = useCallback(
    ({
      baseUrl = routes.flightsSearch,
      path = "slice/0",
      replace = false,
      newValues,
      additionalQueryParams = {},
      newSearch = false,
      keepPreviousParams = true,
    }: IOnSubmitSearchCriteriaArgs) => {
      const values = newValues || urlParams;

      const mergedSlicesCriteria =
        formatSlicesCriteria(newValues?.slicesCriteria) ||
        JSON.stringify(urlParams?.slicesCriteria);

      const queryString = buildQueryString({
        ...(keepPreviousParams && {
          slicesCriteria: mergedSlicesCriteria,
          passengers: JSON.stringify(values?.passengers),
          tripType: values?.tripType ?? "",
          searchCriteriaId: newSearch ? uuidV4() : urlParams?.searchCriteriaId ?? uuidV4(),
        }),
        ...additionalQueryParams,
      });

      navigate(
        {
          pathname: `${baseUrl}/${path}`,
          search: queryString,
        },
        { replace }
      );
    },
    [urlParams, navigate]
  );

  const { slicesCriteria, passengers, tripType } = urlParams;

  const isMissingFlightsSearchParams =
    !slicesCriteria ||
    !slicesCriteria[slice] ||
    !slicesCriteria[slice].origin ||
    !slicesCriteria[slice].departureDate ||
    !slicesCriteria[slice].destination ||
    !passengers ||
    passengers.adult < 1;

  const currentSliceInfo = useMemo(() => {
    if (!slice || !slicesCriteria || slicesCriteria?.length === 0) {
      return {
        origin: null,
        destination: null,
        departureDate: null,
        returnDate: null,
      };
    }
    const isOneWayTrip = tripType === TripType.oneWay;

    const departureDate = slicesCriteria[slice]?.departureDate as string;

    const returnDate = isOneWayTrip
      ? null
      : slicesCriteria[slicesCriteria.length - 1]?.departureDate;

    const origin: FlightLocation | null = slicesCriteria[slice]?.origin;
    const destination: FlightLocation | null = slicesCriteria[slice]?.destination;

    return {
      origin,
      destination,
      departureDate,
      returnDate,
    };
  }, [slicesCriteria, slice, tripType]);

  return {
    currentSliceInfo,
    isMissingFlightsSearchParams,
    onSubmitSearchCriteria,
  };
};

const formatSlicesCriteria = (slicesCriteria: FlightSliceCriteria[] | undefined) => {
  return slicesCriteria
    ? JSON.stringify(
        slicesCriteria.map((slice: FlightSliceCriteria) => ({
          ...slice,
          departureDate: moment.isMoment(slice.departureDate)
            ? slice.departureDate.toISOString()
            : slice.departureDate,
        }))
      )
    : "";
};

export const getTravelerCount = (passengers?: IFlightSearchFormValues["passengers"]) => {
  if (!passengers) return 0;

  return passengers.adult + passengers.child.count;
};
