import { useEffect } from "react";

import { useAppSelector } from "store/hooks";
import { ampli } from "ampli";

import type { PersonalAccountCreationStatus } from "../../../../../PersonalTravelLanding";
import { ModalNavBar } from "../../ModalNavBar";
import * as Styled from "./styles";

interface IPackYourBagsStep {
  onClose: () => void;
  toggleAccountMode: () => void;
  goSignUpOptions: () => void;
  personalCTAStatus?: PersonalAccountCreationStatus;
}

export const copy = [
  "Make your travel activity totally private",
  "Remove work-related lodging restrictions",
  "Easily switch between personal and business",
  "Use it to book a personal weekend after a work trip",
];

type CopyText = {
  [key in PersonalAccountCreationStatus]: string;
};

export const titleCopies: CopyText = {
  default: "Pack your bags!",
  disabled: "Sorry!",
  "signed-up": "Good news!",
  "signed-in": "Good news!",
};

export const descriptionCopies: CopyText = {
  default: "Book and track your personal trips on a private account.",
  disabled: "You aren’t eligible for a personal travel account.",
  "signed-up": "You're already signed up for a personal account.",
  "signed-in": "You're already signed in with a personal account.",
};

export const ctaCopies: CopyText = {
  default: "Let's Go",
  disabled: "Book Business Travel Now",
  "signed-up": "Book Personal Travel Now",
  "signed-in": "Book Personal Travel Now",
};

export const PackYourBagsStep = ({
  onClose,
  toggleAccountMode,
  goSignUpOptions,
  personalCTAStatus = "default",
}: IPackYourBagsStep) => {
  const user = useAppSelector((state) => state.Auth.user);

  const handleClick = () => {
    if (personalCTAStatus === "disabled" || personalCTAStatus === "signed-in") {
      onClose();
    } else if (personalCTAStatus === "signed-up") {
      toggleAccountMode();
    } else {
      goSignUpOptions();
    }
  };

  useEffect(() => {
    ampli.viewPackYourBags({ userId: user?.id });
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Styled.PackYourBagsContainer>
      <ModalNavBar onClose={onClose} />
      <Styled.Title>{titleCopies[personalCTAStatus]}</Styled.Title>
      <Styled.SubTitle>{descriptionCopies[personalCTAStatus]}</Styled.SubTitle>
      {personalCTAStatus === "default"
        ? copy.map((text, i) => (
            <Styled.Copy key={`personal-account-creation-value-prop-${i}`}>
              <Styled.GreenCheckIcon icon={["far", "check"]} /> {text}
            </Styled.Copy>
          ))
        : null}
      <Styled.SVGContainer />
      <Styled.LetsGoButton type="primary" onClick={handleClick}>
        {ctaCopies[personalCTAStatus]}
      </Styled.LetsGoButton>
    </Styled.PackYourBagsContainer>
  );
};
