import styled from "styled-components";
import {
  DropdownMenuTrigger as AtlasDropdownMenuTrigger,
  Button as AtlasButton,
} from "@hotelengine/atlas-web";

const DropdownMenuTrigger = styled(AtlasDropdownMenuTrigger)`
  padding: initial;
  background-color: initial;

  & .ant-btn-ghost {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    column-gap: ${({ theme }) => theme.spacing[8]};
    padding: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[8]}`};
  }

  &:hover:not(:disabled) {
    background-color: initial;
  }

  & span {
    margin-right: initial;
  }

  & i {
    display: none;
  }
`;

const Button = styled(AtlasButton)`
  border-color: ${({ theme }) => theme.colors.borderSubtle};
  color: ${({ theme }) => theme.colors.foregroundSecondary};
  padding: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[8]}`};

  &:hover {
    color: ${({ theme }) => theme.colors.uiPrimary};
    border-color: ${({ theme }) => theme.colors.uiPrimary};
  }
`;

export { DropdownMenuTrigger, Button };
