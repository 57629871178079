import React from "react";
import * as Styled from "./styles";
import { Typography } from "@hotelengine/atlas-web";
interface IBookingSuccessProps {
  propertyConfirmationNumber: string;
}

const BookingSuccess: React.FC<IBookingSuccessProps> = ({ propertyConfirmationNumber }) => {
  return (
    <Styled.SuccessContainer data-testid="itinerary-booked-banner">
      <Styled.InfoContainer>
        <Styled.IconContainer>
          <Styled.Icon name="circle-check--solid" size="md" />
        </Styled.IconContainer>
        <Styled.DetailContainer>
          <Typography variant="heading/sm" color="foregroundPrimary">
            Booking complete!
          </Typography>
          <Typography variant="body/sm">Confirmation # {propertyConfirmationNumber}</Typography>
        </Styled.DetailContainer>
      </Styled.InfoContainer>
    </Styled.SuccessContainer>
  );
};

export default BookingSuccess;
