// TODO: Update this antD, as soon as we have a replacement in Atlas. For now we will continue using the AntD
import { List } from "@hotel-engine/common/Lists";
import type { IconProps } from "@hotelengine/atlas-web";
import { Icon, Typography } from "@hotelengine/atlas-web";

import * as Styled from "./styles";

export interface IActionButtonLayoutProps {
  onlyOneItemExists: boolean;
  renderList: IButtonListItem[];
  showFlexMessage: boolean;
}

export interface IButtonListItem {
  action: () => void;
  dataTestId: string;
  displayCriteria: boolean;
  iconName: IconProps["name"];
  text: string;
}

const ActionButtonLayout = ({
  onlyOneItemExists,
  renderList,
  showFlexMessage,
}: IActionButtonLayoutProps) => {
  const hasDisplayCriteria = renderList.some((item) => !!item.displayCriteria);

  return (
    <Styled.ModificationActions $onlyOneItemExists={onlyOneItemExists}>
      <Styled.ButtonGroup>
        {!!hasDisplayCriteria && (
          <List bordered>
            {renderList.map((item) => {
              return (
                !!item.displayCriteria && (
                  <Styled.ItineraryListItem
                    onClick={item.action}
                    data-testid={item.dataTestId}
                    key={item.dataTestId}
                  >
                    <div>
                      <Icon name={item.iconName} color="foregroundPrimary" />
                      <Typography variant="body/md" color="foregroundPrimary">
                        {item.text}
                      </Typography>
                    </div>
                    <Icon name="chevron-right" color="foregroundPrimary" />
                  </Styled.ItineraryListItem>
                )
              );
            })}
          </List>
        )}
        {!!showFlexMessage && (
          <Styled.FlexMessage>
            <Typography variant="body/sm">Modifications are covered by </Typography>
            <Icon
              id="shield-check"
              name="he-flex"
              color="foregroundPrimary"
              data-testid="flex-pro-logo"
            />{" "}
            <Typography variant="body/sm-strong">Flex</Typography>
          </Styled.FlexMessage>
        )}
      </Styled.ButtonGroup>
    </Styled.ModificationActions>
  );
};

export default ActionButtonLayout;
