import { formatCurrency } from "@hotel-engine/utilities";

import type { ICurrencyValue, ISurcharge } from "pages/Checkout/types/Cart.types";

export const formattedPrice = (priceValue?: ICurrencyValue) => {
  const value = priceValue?.value || 0;
  const currencyCode = priceValue?.currencyCode || "USD";

  return {
    amount: +value,
    formatted: () => formatCurrency(value, true, currencyCode),
  };
};

export const aggregatedFormattedPrices = (fees?: ISurcharge[]) => {
  const total = fees?.reduce((interimTotal, { amount }) => interimTotal + +amount.value, 0);

  // Assume currency code is shared between all fees
  const priceValue = {
    value: total?.toString() || "0",
    currencyCode: fees?.[0]?.amount.currencyCode || "USD",
  };

  return formattedPrice(priceValue);
};
