import { useMemo } from "react";
import { Box, Icon, Typography } from "@hotelengine/atlas-web";
import type { IFlightItinerarySegment } from "@hotel-engine/types/itinerary";

import * as Styled from "./styles";
import { getAmenitiesInfo } from "@hotel-engine/app/FlightAmenities/getAmenitiesInfo";
import { useAircraftInfo } from "pages/Flights/hooks/useAircraftInfo";

interface IFlightDetailsRowProps {
  label: string;
  value: string;
}
const FlightDetailsRow = ({ label, value }: IFlightDetailsRowProps) => {
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Typography variant="body/sm" color="foregroundSecondary">
        {label}
      </Typography>
      <Typography variant="body/sm" color="foregroundPrimary" justifyContent="flex-end">
        {value}
      </Typography>
    </Box>
  );
};

interface IFlightDetailsBlockProps {
  segment: IFlightItinerarySegment;
}

export const FlightDetailsBlock = ({ segment }: IFlightDetailsBlockProps) => {
  const { getAircraftNameByIataAircraftKey } = useAircraftInfo();

  const amenities = useMemo(() => getAmenitiesInfo(segment.amenities), [segment.amenities]);
  const airplaneDetails = useMemo(
    () => getAircraftNameByIataAircraftKey(segment.planeCode),
    [getAircraftNameByIataAircraftKey, segment.planeCode]
  );
  const formattedCabinClass = useMemo(() => {
    try {
      const [_, cabinMatch] = segment.cabinClass.match(/^Cabin class (.+)$/) || [];
      if (!cabinMatch) {
        return segment.cabinClass;
      }

      return cabinMatch.charAt(0).toUpperCase() + cabinMatch.slice(1);
    } catch (e) {
      return segment.cabinClass;
    }
  }, [segment.cabinClass]);

  return (
    <Styled.FlightDetailsDashedContainer>
      <Box display="flex" flexDirection="column" gap={8}>
        <FlightDetailsRow label="Airplane" value={airplaneDetails} />
        {!!segment.fareDescription && (
          <FlightDetailsRow label="Fare" value={segment.fareDescription} />
        )}
        <FlightDetailsRow label="Cabin" value={formattedCabinClass} />
      </Box>
      {/* TODO: See if we can export the component from `core-booking-web` to use here instead */}
      {amenities.length > 0 && (
        <Styled.FlightDetailsDashedContainer display="flex" flexDirection="column" gap={8}>
          {amenities.map((amenity) => {
            return (
              <Box key={amenity.icon} display="flex" flexDirection="row" gap={12}>
                <Icon name={amenity.icon} />
                <Typography variant="body/sm" color="foregroundPrimary">
                  {amenity.copy}
                </Typography>
              </Box>
            );
          })}
        </Styled.FlightDetailsDashedContainer>
      )}
    </Styled.FlightDetailsDashedContainer>
  );
};
