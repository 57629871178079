import styled from "styled-components";

export const PopoverLine = styled.div`
  display: flex;
  justify-content: space-between;

  &:first-of-type {
    margin-bottom: ${({ theme }) => theme.spacing[8]};
  }

  &:last-of-type {
    margin-top: ${({ theme }) => theme.spacing[8]};
  }
`;

export const FlexLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
