import type { IconName } from "@fortawesome/fontawesome-svg-core";

/** Currently supported ISO currency codes */
export type ICurrency = "USD" | "CAD" | "GBP" | "EUR" | "AUD";

/** Font-awesome icons for supported currencies */
export const currencyIconDictionary: Record<ICurrency, IconName> = {
  USD: "dollar-sign",
  EUR: "euro-sign",
  GBP: "pound-sign",
  CAD: "dollar-sign",
  AUD: "dollar-sign",
};

/** International and domestic support numbers controlled by business currency */
export const supportNumberDictionary: Record<ICurrency, string> = {
  USD: "855-567-4683",
  EUR: "+353 18 00189189",
  GBP: "+44 8000 668697",
  CAD: "855-647-3259",
  AUD: "+61 1800 319 562",
};
