import styled from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";

/** Something weird going on with Atlas version and property-card version, a fix is in
 * progress but for now this fixes the appearance of our buttons */
export const TopActionButton = styled.button`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacing[16]};
  border: 1px solid ${({ theme }) => theme.colors.borderSubtle};
  border-radius: ${({ theme }) => theme.borderRadius[4]};
  color: ${({ theme }) => theme.colors.foregroundPrimary};
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  height: ${({ theme }) => theme.spacing[40]};
  width: 156px;
  padding: ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[16]};
  transition: 0.2s ease all;

  &:hover:enabled,
  &:focus,
  &:focus:disabled {
    background-color: ${({ theme }) => theme.colors.backgroundPrimaryHover};
    transition: 0.2s ease all;
  }

  &:disabled {
    opacity: 0.75;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const TopActionContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing[24]};

  ${flexbox({
    direction: "row",
    justifyContent: "space-between",
  })};
`;
