import { useEffect, useState } from "react";
import { ampli } from "ampli";

import type { IGoogleMapsControlArgs, MapListeners } from "@hotel-engine/app/GoogleMap/types";
import { useBreakpoint } from "@hotel-engine/hooks/useBreakpoint";
import { useAppSelector } from "store/hooks";
import { selectSearchId } from "store/Search/SearchResults/selectors";

import * as Styled from "./styles";
import { useDismissSpecificSearch } from "@hotel-engine/hooks/search/useDismissSpecificSearch";

type GoogleMapsService = {
  addListeners: (map: google.maps.Map, listeners: MapListeners) => void;
  removeListeners: (map: google.maps.Map, listeners: MapListeners) => void;
};

type GoogleMapsSearchResultsUpdateSearchAreaProps = {
  /** Used for managing event listeners in this component */
  googleMapsService: GoogleMapsService;
  /** Callback to asses whether or not to trigger a new search query */
  handleShouldTriggerSearch: (googleMapsControlArgs: IGoogleMapsControlArgs) => void;
  /** Used for loading state on control */
  isLoading: boolean;
  /** Google map instance */
  map: google.maps.Map;
};

const GoogleMapsSearchResultsUpdateSearchArea = ({
  googleMapsService,
  handleShouldTriggerSearch,
  isLoading,
  map,
}: GoogleMapsSearchResultsUpdateSearchAreaProps) => {
  const [mapViewAlteredBy, setMapViewAlteredBy] = useState({ button: false, user: false });
  const searchId = useAppSelector(selectSearchId) ?? undefined;
  const showEditSearchForm = useAppSelector((state) => state.SearchPage.showEditSearchForm);
  const showMapButton = mapViewAlteredBy.user || (mapViewAlteredBy.button && isLoading);

  const dismissSpecificSearch = useDismissSpecificSearch();

  /** Unfortunately our map breakpoints and these controls don't always play nice, so I think something like this is actually necessary... */
  const xxxlDisplay = useBreakpoint("xxxl", "min");
  const xlDisplay = useBreakpoint("xl", "min");
  const lgDisplay = useBreakpoint("lg", "min");

  const getControlPosition = () => {
    if (xxxlDisplay) return "top";
    else if (xlDisplay) return "bottom";
    else if (lgDisplay) return "top";
    else return "bottom";
  };

  const handleOnClick = () => {
    handleShouldTriggerSearch({
      center: map.getCenter(),
      isDragEnd: false,
    });

    ampli.clickUpdateMapSearch({
      searchId,
    });

    setMapViewAlteredBy({ button: true, user: false });
    dismissSpecificSearch();
  };

  /** We only want to display the update search button after a user interacts with the map */
  const listener = {
    dragend: () => {
      handleShouldTriggerSearch({
        center: map.getCenter(),
        isDragEnd: true,
      });
      setMapViewAlteredBy({ button: false, user: true });
    },
  };

  // Removes button if opening/editing a Search
  useEffect(() => {
    if ((mapViewAlteredBy.user && isLoading) || (mapViewAlteredBy.button && showEditSearchForm)) {
      setMapViewAlteredBy({ button: false, user: false });
    }
  }, [isLoading, mapViewAlteredBy, showEditSearchForm]);

  // Handle google map listeners
  useEffect(() => {
    if (!map || !searchId) {
      return;
    }

    googleMapsService.addListeners(map, listener);

    return () => {
      if (map) googleMapsService.removeListeners(map, listener);
    };
    // IGNORE-REASON ENS-2668 This still needs fixing!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, searchId]);

  if (!showMapButton) return null;

  return (
    <Styled.MapStyleButtonsCentered updateSearchControlPosition={getControlPosition()}>
      <Styled.SearchButton
        data-testid="map-area-search-button"
        isDisabled={isLoading}
        isLoading={isLoading}
        size="sm"
        onClick={handleOnClick}
      >
        Search this area
      </Styled.SearchButton>
    </Styled.MapStyleButtonsCentered>
  );
};

export default GoogleMapsSearchResultsUpdateSearchArea;
