import type { Moment } from "moment";

export enum TrendsTabs {
  ALL = "all",
  BOOKERS = "bookers",
  TRAVELERS = "travelers",
  DEPARTMENTS = "departments",
  TRAVEL_POLICIES = "travel-policies",
  TOP_LOCATIONS = "top-locations",
  TOP_PROPERTIES = "top-properties",
}

export enum CategoryTypes {
  department = "department",
  member = "member",
  travel_policy = "travel_policy",
  location = "location",
  hotels = "hotels",
  custom_field = "custom_field",
}

export type TrendsFilterMembers = {
  email: string;
  name?: string;
};

export type TrendsFilterLocation = {
  state: string;
  city: string;
  zip: number;
  country: string;
};

export type TrendsFilterResponse = {
  id: number;
  searchValue: string | TrendsFilterMembers | TrendsFilterLocation;
};

export function isMemberSearch(
  searchType: TrendsFilterMembers | unknown
): searchType is TrendsFilterMembers {
  return (searchType as TrendsFilterMembers).email !== undefined;
}

export type ITrendsGroupBy = "week" | "month" | "day";

export type IReportType = "lodging" | "flights" | "car_rentals" | "all_trips";

export type IFilterType = "member" | "department" | "travel_policy" | "custom_field";

export interface ITrendsParams {
  businessId?: number;
  groupBy: ITrendsGroupBy;
  startDate: string;
  endDate: string;
  query?: string | null;
  category?: string | null;
  customFieldId?: number | null;
}
export interface ITrendsReportParams {
  startDate: string;
  endDate: string;
  userId: string;
  format: "json";
  bcc_emails?: string;
}
export interface ITrendsConsolidatedParams {
  businessId: number;
  startDate: string;
  endDate: string;
  query: string | null;
  category: string | null;
  customFieldId: number | null;
  groupBy: {
    bookingsParams: ITrendsGroupBy;
    spendingQueryParams: ITrendsGroupBy;
    averageRateParams: ITrendsGroupBy;
    businessStatsParams: ITrendsGroupBy;
  };
}

export interface IBookingsData {
  ranges: {
    start: string;
    end: string;
  }[];
  bookings: number[];
  refundableBookings: number[];
  nonRefundableBookings: number[];
  totalBookings?: number;
}

export interface ITrendsBookingsData {
  bookingNumber: string;
  bookingType: string;
  travelers: string[];
  departments: string;
  spending: string;
  savings: string;
  vendor: string;
  dateBooked: string;
}

export interface ITrendsBookersData {
  booker: string;
  role: string;
  department: string;
  spending: number;
  savings: number;
  adr: number;
  bookings: number;
  bookingFor: number;
}

export interface ISpendingData {
  averageSpending: (string | number)[];
  ranges: {
    start: string;
    end: string;
  }[];
  savings: number[];
  spend: number[];
  summary: {
    averageRate: number;
    totalSavings: number;
    totalSpend: number;
  };
}

export interface ITrendsDefaultParams {
  startDate: Moment | null;
  endDate: Moment | null;
  reportType: IReportType;
  filterType?: IFilterType;
  filterResourceId?: number | null;
  filterValue?: string;
  groupBy?: ITrendsGroupBy;
  offset?: number;
  limit?: number;
}

export interface IChartSpendingData {
  bookings: number[];
  totalSpend: string[];
  lodgingSpend: string[];
  flightsSpend: string[];
  carRentalsSpend: string[];
  dates: string[];
  variation: string;
}

export interface IChartSavingsData {
  bookings: number[];
  totalSpend: string[];
  originalCost: string[];
  dates: string[];
  variation: string;
}

export type ReportDateRangeStatus = "default" | "custom";

export type DateRangeFilterValue = "check_in_date" | "date_booked" | "night_of_stay";

export interface ITrendsBookersByQuantityItem {
  fullName: string;
  bookings: number;
  variation: string;
}
export interface ITrendsBySpendingItem {
  fullName: string;
  bookings: number;
  spending: string;
}

export interface ITrendsBookersByADRItem {
  fullName: string;
  adr: string;
}

export type BookersByQuantity = {
  items: ITrendsBookersByQuantityItem[];
  totalBookings: number;
  totalItems: number;
};

export type BookersBySpending = {
  items: ITrendsBySpendingItem[];
  averageSpendingPerBooker: string;
};

export type TravelersBySpending = {
  items: ITrendsBySpendingItem[];
  averageSpendingPerTraveler: string;
};

export type TravelersByADR = {
  items: ITrendsBookersByADRItem[];
  adrPerTraveler: string;
};

export type BookersByADR = {
  items: ITrendsBookersByADRItem[];
  adrPerBooker: string;
};

// The following types are for Trends 3-in-1. The legacy types above should be removed after Trends 3-in1 is roll out

export type MetricsType = "spending" | "savings" | "he_benefits";
export type TopItemsWidgetType = "quantity" | "spend" | "adr";

export interface ISpendingOrSaving {
  value: string;
  variation: string;
}

export interface IHeBenefits {
  total: string;
  heRewards: string;
  flexSavings: string;
  flexProSavings: string;
  travelCredits: string;
}

export interface IMetricsBillboardData {
  spending: ISpendingOrSaving;
  savings: ISpendingOrSaving;
  heBenefits: IHeBenefits;
}

export interface IChartData {
  name: string;
  mainData: string | number;
  secondaryData: string | number;
}

export type DepartmentsByQuantity = {
  items: ITrendsBookersByQuantityItem[];
  totalBookings: number;
  totalItems: number;
};

export type DepartmentsBySpending = {
  items: ITrendsBySpendingItem[];
  averageSpendingPerBooker: string;
};

export type DepartmentsByADR = {
  items: ITrendsBookersByADRItem[];
  adrPerBooker: string;
};
