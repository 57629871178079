import { useEffect, useState } from "react";
import type { ICancelData } from "@hotel-engine/react-query/bookings/usePreviewCancellationQuery";
import { usePreviewCancellationQuery } from "@hotel-engine/react-query/bookings/usePreviewCancellationQuery";

export enum CarRefundType {
  OriginalFormOfPayment = "Original form of payment",
  Unknown = "unknown",
  Nonrefundable = "nonrefundable",
}

export const carRefundTypeMap = {
  car_original_form_of_payment: "Original form of payment",
};

export const usePreviewCarRefund = (tripId: number, bookingNumber: string) => {
  const [cancelData, setCancelData] = useState<ICancelData>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const carCancelCalc = usePreviewCancellationQuery({
    id: tripId,
  });

  useEffect(() => {
    if (!cancelData && !isError && !isLoading) {
      try {
        setIsLoading(true);

        carCancelCalc.mutate(
          { bookingNumber: bookingNumber, bookingType: "car" },
          {
            onSuccess: (data) => {
              setCancelData(data);
              setIsLoading(false);
              setIsError(false);
            },
            onError: () => {
              setIsLoading(false);
              setIsError(true);
            },
          }
        );
      } catch {
        setIsLoading(false);
        setIsError(true);
      }
    }
  }, [bookingNumber, cancelData, carCancelCalc, isError, isLoading]);

  const buildInfoObject = () => {
    if (!cancelData) {
      return null;
    } else {
      const allFees =
        cancelData.refundDetails.fees?.reduce((acc, cur) => {
          return (acc += Number(cur.value));
        }, 0) || 0;

      return {
        refundType: carRefundTypeMap.car_original_form_of_payment as CarRefundType,
        refundAmount: cancelData.refundDetails.total.value,
        cancellationFee: String(allFees),
        isFree: allFees === 0,
      };
    }
  };

  return { refundInfo: buildInfoObject(), isLoading, isError };
};
