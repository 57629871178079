import { Icon, Typography, type IconProps } from "@hotelengine/atlas-web";
import { getAutocompleteIconInfo } from "@hotel-engine/app/SearchForm/helpers";
import type { IGooglePrediction } from "@hotel-engine/types/search";

import { weightSubstringMatch } from "../../helpers";
import * as Styled from "../../styles";

type GoogleSearchResultProps = {
  item: IGooglePrediction;
  searchInput: string;
};

const GoogleSearchResult = ({ item, searchInput }: GoogleSearchResultProps) => {
  const { place_id, structured_formatting } = item;
  const lineOne = structured_formatting?.main_text;
  const lineTwo = structured_formatting?.secondary_text;

  const searchResult = lineOne || "";
  const searchTitle = weightSubstringMatch(searchInput, searchResult);

  const { iconName, iconStyles } = getAutocompleteIconInfo(item.types);

  return (
    <div>
      <Styled.LocationPickerOptionIcon style={iconStyles}>
        <Icon name={iconName as IconProps["name"]} data-testid={place_id} />
      </Styled.LocationPickerOptionIcon>
      <Styled.LocationPickerOptionValue>
        <Typography
          className="description description--primary-ff"
          color="foregroundPrimary"
          data-testid={iconName}
          variant="body/md"
        >
          {searchTitle}
        </Typography>
        {!!lineTwo && (
          <Typography
            className="description description--secondary"
            color="foregroundSecondary"
            variant="body/xs"
          >
            {lineTwo}
          </Typography>
        )}
      </Styled.LocationPickerOptionValue>
    </div>
  );
};

export default GoogleSearchResult;
