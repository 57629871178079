import type { IOccupant } from "@hotel-engine/types/booking";
import { Popover } from "@hotel-engine/common/Popover";

import { viewTypes } from "../../constants";
import { generateGuestList, generateGuestNames } from "./helpers/generateGuestListLegacy";
import * as Styled from "./styles";
import { MAX_GUESTS_SHOW } from "@hotel-engine/constants/trips";
import WrapInStrong from "../WrapInStrong";

interface ITravelerProps {
  guestFirstName: string;
  guestLastName: string;
  occupants: IOccupant[][];
  view: string;
  listRedesign?: boolean;
  searchValue?: string;
}

const TravelersLegacy = ({
  guestFirstName,
  guestLastName,
  occupants,
  view,
  listRedesign = false,
  searchValue = "",
}: ITravelerProps) => {
  const allOccupants = occupants.flat();
  const guestCount = allOccupants.length;
  const guestList = generateGuestList(allOccupants);
  const guestNames = generateGuestNames(allOccupants, MAX_GUESTS_SHOW);
  const otherGuestsCount = guestCount - MAX_GUESTS_SHOW;

  const content = <Styled.PopoverContent>{guestList}</Styled.PopoverContent>;

  return (
    <Popover title="Guests" content={content}>
      {view === viewTypes.SMALL_LIST ? (
        <Styled.PrimaryGuest>
          {!listRedesign && (
            <>
              <span>{`${guestFirstName} ${guestLastName}`}</span>
              <span>{guestCount > 1 && `, +${guestCount - 1}`}</span>
            </>
          )}
        </Styled.PrimaryGuest>
      ) : (
        <div>
          {!!listRedesign && (
            <>
              <div>
                <WrapInStrong term={searchValue} target={guestNames} />
              </div>
              {otherGuestsCount > 0 && (
                <Styled.GuestsLabel>+{otherGuestsCount} others</Styled.GuestsLabel>
              )}
            </>
          )}
          {!listRedesign && (
            <>
              <Styled.PrimaryGuest>{`${guestFirstName} ${guestLastName}`}</Styled.PrimaryGuest>
              <Styled.GuestsLabel>
                {guestCount > 1 ? ` + ${guestCount - 1} Guests` : "Primary Guest"}
              </Styled.GuestsLabel>
            </>
          )}
        </div>
      )}
    </Popover>
  );
};

export default TravelersLegacy;
