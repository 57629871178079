import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";

import UpcomingActiveActions from "./components/UpcomingActiveActions";
import PastCancelledActions from "./components/PastCancelledActions";
import { Box, Typography } from "@hotelengine/atlas-web";

const ItineraryActions = () => {
  const { reservation: itinerary, isPreview } = useModificationsContext();

  const isReservationUpcomingOrActive = ["booked", "visiting"].includes(itinerary.status);

  const isReservationPastOrCancelled = ["completed", "cancelled"].includes(itinerary.status);

  return (
    <Box>
      <Typography variant="body/md" paddingTop={4} paddingBottom={8}>
        Life happens, plans change! Explore your options below and easily modify your stay.
      </Typography>
      <Box paddingTop={24} gap={16}>
        {!!isReservationUpcomingOrActive && (
          <UpcomingActiveActions itinerary={itinerary} isPreview={isPreview} />
        )}
        {!!isReservationPastOrCancelled && <PastCancelledActions itinerary={itinerary} />}
      </Box>
    </Box>
  );
};

export default ItineraryActions;
