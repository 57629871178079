import { useEffect, useRef } from "react";

import { useBreakpoint } from "@hotel-engine/hooks";
import type { ICourierNotificationInbox } from "@hotel-engine/types/courierNotifications";

import EmptyInbox from "../EmptyInbox";
import NotificationItem from "../NotificationItem";
import NotificationAdminActions from "./NotificationAdminActions";
import * as Styled from "./styles";
import {
  Box,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Typography,
} from "@hotelengine/atlas-web";

const NotificationInbox = ({
  fetchMessages,
  hasUnreadMessages,
  isError,
  isLoading,
  markAllAsRead,
  markMessageArchived,
  markMessageRead,
  markMessageUnread,
  messages = [],
  accountType,
}: ICourierNotificationInbox) => {
  const hasMessages = !!messages.length;
  const accountTypeRef = useRef(accountType);

  useEffect(() => {
    if (!hasMessages) {
      fetchMessages();
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUnreadMessages]);

  useEffect(() => {
    if (accountType !== accountTypeRef.current) {
      fetchMessages();
      accountTypeRef.current = accountType;
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType]);

  const isEmpty = !messages.length;
  const isMobile = useBreakpoint("xs", "max");

  return (
    <Styled.NotificationInbox data-testid="notification-inbox">
      <Styled.InboxHeader>
        <Typography as="h4" variant={isMobile ? "heading/sm" : "heading/md"} margin={0}>
          Notifications
        </Typography>
        <Popover>
          <PopoverTrigger>
            <Box>
              <Icon name="ellipsis" style={{ cursor: "pointer" }} />
            </Box>
          </PopoverTrigger>
          <PopoverContent align="end" style={{ maxWidth: "unset" }}>
            <NotificationAdminActions hasMessages={hasMessages} markAllAsRead={markAllAsRead} />
          </PopoverContent>
        </Popover>
      </Styled.InboxHeader>

      {isLoading ? (
        <Styled.LoaderWrap>
          <Styled.StyledLoader height="sm" width="fluid" type="default" />
          <Styled.StyledLoader height="sm" width="fluid" type="default" />
          <Styled.StyledLoader height="sm" width="fluid" type="default" />
        </Styled.LoaderWrap>
      ) : (
        <>
          {isError || isEmpty ? (
            <EmptyInbox isError={isError} />
          ) : (
            <Styled.NotificationWrap>
              {messages.map((message) => {
                const { messageId, trackingIds } = message;
                return trackingIds ? (
                  <NotificationItem
                    key={messageId}
                    markMessageArchived={markMessageArchived}
                    markMessageRead={markMessageRead}
                    markMessageUnread={markMessageUnread}
                    message={message}
                  />
                ) : null;
              })}
            </Styled.NotificationWrap>
          )}
        </>
      )}
    </Styled.NotificationInbox>
  );
};

export default NotificationInbox;
