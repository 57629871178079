import { useCallback } from "react";

import type {
  QueryFunction,
  QueryFunctionContext,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { captureMessage } from "../utilities/logger";
import { useExtendedQuery } from "./useExtendedQuery";

export type DownloadQueryMediaType = "csv" | "pdf";

export type UseDownloadQueryOptions<
  TError,
  TQueryKey extends QueryKey = QueryKey,
> = UseQueryOptions<void, TError, void, TQueryKey> & {
  fileName: string;
  mediaType?: DownloadQueryMediaType;
};
/**
 *
 * Downloads blobs from a query
 *
 * Supports csv and pdf - add type media type parameter if you need something else.
 *
 * make sure to set the responseType header.
 *
 * This is not kept in-cache. use refetch to kick off download.
 *
 * @param fileName - the name of the downloaded file
 * @param queryKey - {@link https://react-query.tanstack.com/guides/query-keys | react-query query keys}
 * @param queryFn - api call
 * @param options - {@link https://react-query.tanstack.com/reference/useQuery | react-query query options}
 */
export function useDownloadQuery<TError, TQueryKey extends QueryKey = QueryKey>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<string, TQueryKey>,
  options: UseDownloadQueryOptions<TError, TQueryKey>
): UseQueryResult<void, TError> {
  const { fileName, mediaType = "csv", ...queryConfig } = options;
  /** this can be expanded to other {@link www.iana.org/assignments/media-types/media-types.xhtml#text media types} */
  const downloadBlob = useCallback(
    (data: string) => {
      const blob = new Blob([data], {
        type: "text/csv;charset=UTF-8",
      });

      // window will only have msCrypto on IE11
      if (globalThis["msCrypto"]) {
        globalThis.navigator.msSaveOrOpenBlob &&
          globalThis.navigator.msSaveOrOpenBlob(blob, `${fileName}.${mediaType}`);
      } else {
        const url = globalThis.URL.createObjectURL(blob);

        // open url in new recently opened window or send a message to sentry if window is null
        if (url) {
          const link = document.createElement("a");
          link.download = `${fileName}.${mediaType}`;
          link.href = url;
          link.click();
        } else {
          captureMessage("Error opening trends download modal", {
            error: new Error("Could not open window with window.open"),
          });
        }
      }
    },
    [fileName, mediaType]
  );

  return useExtendedQuery(
    queryKey,
    async (context: QueryFunctionContext<TQueryKey, string>): Promise<void> => {
      const response = await queryFn(context);
      return downloadBlob(response);
    },
    { ...queryConfig, cacheTime: 0, staleTime: 0, enabled: false }
  );
}
