import type { BoxProps } from "@hotelengine/atlas-web";
import { Box } from "@hotelengine/atlas-web";
import styled from "styled-components";

export const ManageTripContainer = styled<React.FC<BoxProps>>(Box)`
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    padding: ${({ theme }) => theme.spacing[16]};
    padding-bottom: ${({ theme }) => theme.spacing[20]};
    border-top: ${({ theme }) => `1px solid ${theme.colors.borderMinimal}`};
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    z-index: ${({ theme }) => theme.zIndex.modal};
  `;

export const FlexMessage = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[4]};
  margin-top: ${({ theme }) => theme.spacing[8]};
`;
