import type { IRoom } from "@hotel-engine/types/room";
import { captureMessage } from "@hotel-engine/utilities";

export function checkAndHandleDuplicateRooms(rooms: IRoom[]) {
  const dupes = rooms.filter((room) => {
    return rooms.filter((r) => r.title === room.title).length > 1;
  });
  if (dupes.length) {
    captureMessage(
      "Received duplicate room options",
      {
        error: "Received duplicate room options",
        duplicates: dupes,
      },
      "info"
    );
  }
}
