import { PaddedScrollSectionContainer } from "@hotel-engine/app/ItineraryContent/styles";
import { useFlightModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useGlobalTheme } from "@hotel-engine/contexts/GlobalThemeContext";
import { getFlightSlices } from "./getFlightSlices";
import { useMemo } from "react";

const FlightSlices = () => {
  const { tokens } = useGlobalTheme();
  const { isPreview, reservation } = useFlightModificationsContext();

  const slices = useMemo(() => getFlightSlices(reservation.slices), [reservation.slices]);

  return (
    <PaddedScrollSectionContainer
      $isPreview={isPreview}
      style={{ marginTop: tokens.spacing[8], marginBottom: 0 }}
    >
      {slices}
    </PaddedScrollSectionContainer>
  );
};

export default FlightSlices;
