import styled from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const FlexProCheckoutShort = styled.div<{ $margin?: string }>`
  ${flexbox({
    alignItems: "flex-start",
  })};
  z-index: ${({ theme }) => theme.zIndex.backdropAbove};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  padding: 16px;
  margin: ${({ $margin }) => ($margin ? $margin : "12px 16px")};
  background: ${({ theme }) => theme.colors.accentGreenLightest};
`;

export const FlexProResultsLarge = styled.div<{ $margin?: string }>`
  ${flexbox({
    alignItems: "flex-start",
  })};
  z-index: ${({ theme }) => theme.zIndex.backdropAbove};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  padding: 16px;
  margin: ${({ $margin }) => ($margin ? $margin : "12px 16px")};
  background: ${({ theme }) => theme.colors.accentGreenLightest};
`;

export const FlexProResultsSmall = styled.div<{ $margin?: string }>`
  ${flexbox({
    alignItems: "center",
    justifyContent: "space-between",
  })};
  z-index: ${({ theme }) => theme.zIndex.backdropAbove};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  padding: 8px 12px;
  margin: ${({ $margin }) => ($margin ? $margin : "12px 16px")};
  background: ${({ theme }) => theme.colors.accentGreenLightest};
`;

export const FlexProBannerCopyContainer = styled.div`
  width: 100%;
  margin-left: 16px;
`;

export const Img = styled.img`
  width: 56px;
  height: 56px;
  flex: none;
  flex-grow: 0;
`;

export const OptInImg = styled.img`
  grid-area: image;
  margin: auto;
  width: 98px;
  height: 98px;
`;

export const FlexLogo = styled.img`
  margin-left: 3px;
`;

export const CSFlexProBanner = styled.div`
  display: grid;
  grid-template-columns: 158px auto;
  grid-template-areas: "image details";
  align-items: center;
  width: 100%;
  padding: 20px 0;
  margin-bottom: 40px;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: 0px 1px 1px 0px #0000000a, 0px 2px 6px 1px #0000001f;
`;

export const CSFlexProDetails = styled.div`
  grid-area: details;
  ${flexbox({
    direction: "column",
    justifyContent: "center",
  })};
  padding: 0 40px 0 0;
`;

export const OptBackIntoBanner = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.xl};
  margin-top: 40px;
  border: 1px solid ${({ theme }) => theme.colors.borderSubtle};
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  padding: 40px;
  ${flexbox({
    direction: "row",
    justifyItems: "center",
    justifyContent: "space-between",
  })}
`;

export const OptBackIntoContainer = styled.div`
  ${flexbox({
    direction: "column",
    justifyContent: "center",
  })}
  gap: 4px;
`;

export const BtnContainer = styled.div`
  ${flexbox({
    alignItems: "center",
  })}
  flex-wrap: wrap;
  gap: 4px;
`;
