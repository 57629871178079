import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";

import storage from "@hotel-engine/storage";
import type { IShareOptions } from "@hotel-engine/types/search";
import type { IUserPrefsState } from "@hotel-engine/types/user";
import { reduxPersistKey } from "@hotel-engine/utilities/config";

import type { IAuthState } from "./Auth/types";
import Auth from "./Auth/AuthRedux";
import type { IOrders } from "./Orders/types";
import Orders from "./Orders/OrdersRedux";
import type { IReferralBonusState } from "./ReferralBonus/ReferralBonusRedux";
import ReferralBonus from "./ReferralBonus/ReferralBonusRedux";
import SupportCaseDrawer from "./components/SupportCaseDrawer/SupportCaseDrawerRedux";
import Property from "./containers/Property/PropertyRedux";
import type { IPropertyState } from "./containers/Property/PropertyRedux";
import type { IEnvModsState } from "./EnvMods/types";
import EnvMods, { envModsEnabled } from "./EnvMods/EnvModsRedux";
import UserPrefs from "./UserPrefs/UserPrefsRedux";
import type { ISupportCaseDrawer } from "@hotel-engine/types/supportCase";
import ShareOptions from "store/components/ShareOptions/ShareOptionsRedux";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import config from "config";
import ExpressBook from "./ExpressBook/ExpressBookRedux";
import type { IExpressBookState } from "./ExpressBook/types";
import LogRocket from "logrocket";
import SearchFilters from "./Search/SearchFilters";
import FlightsSearchFilters from "./Flights/FlightsFilters";
import FlightsSearchResults from "./Flights/FlightsResults";
import FlightsShop from "./Flights/FlightsShop";
import SearchMetrics from "./Search/SearchMetrics";
import type { ISearchResultsState } from "./Search/SearchResults/types";
import type { ISearchFiltersState } from "./Search/SearchFilters/types";
import type { ISearchMetricsState } from "./Search/SearchMetrics/types";
import type { ISearchPageState } from "./Search/SearchPage/types";
import SearchPage from "./Search/SearchPage";
import SearchResults from "./Search/SearchResults";
import type { IFlightsSearchFilters } from "store/Flights/FlightsFilters/flights.filters.types";
import type { IFlightsShopState } from "./Flights/FlightsShop/flights.shop.types";
import type { IFlightsSearchResultsState } from "./Flights/FlightsResults/flights.results.types";

export interface IReduxStore {
  Auth: IAuthState;
  FlightsSearchFilters: IFlightsSearchFilters;
  FlightsSearchResults: IFlightsSearchResultsState;
  FlightsShop: IFlightsShopState;
  Property: IPropertyState;
  SearchResults: ISearchResultsState;
  SearchFilters: ISearchFiltersState;
  SearchPage: ISearchPageState;
  SearchMetrics: ISearchMetricsState;
  ShareOptions: IShareOptions;
  UserPrefs: IUserPrefsState;
  EnvMods?: IEnvModsState;
  Orders: IOrders;
  ReferralBonus: IReferralBonusState;
  SupportCaseDrawer: ISupportCaseDrawer;
  ExpressBook: IExpressBookState;
}

const rootReducer = combineReducers({
  Auth,
  FlightsSearchFilters,
  FlightsSearchResults,
  FlightsShop,
  Property,
  SearchFilters,
  SearchMetrics,
  SearchPage,
  SearchResults,
  ShareOptions,
  UserPrefs,
  Orders,
  ReferralBonus,
  SupportCaseDrawer,
  ExpressBook,
  ...(envModsEnabled() ? { EnvMods } : {}),
});

const persistConfig = {
  key: reduxPersistKey,
  storage: storage.asyncStorage,
  whitelist: [
    "ShareOptions",
    "Order",
    "UserPrefs",
    "Orders",
    ...(envModsEnabled() ? ["EnvMods"] : []),
  ],
};

export const reducers = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: reducers,
  devTools: config.stage !== "production",
  // when using redux-persist with toolkit it is recommended to ignore all persist actions from being serializable
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });

    return middleware.concat(LogRocket.reduxMiddleware());
  },
});

export type AppDispatch = typeof store.dispatch;

declare module "react-redux" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultRootState extends IReduxStore {}
}
