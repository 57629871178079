import styled from "styled-components";

const BookingLine = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${({ theme }) => theme.spacing[2]};

  > a {
    color: ${({ theme }) => theme.colors.foregroundPrimary};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export { BookingLine };
