// TODO: In Atlas we have the Tooltip, although there's a divergence with the content prop that only accepts string, but we're passing a ReactNode.
import { Popover } from "@hotel-engine/common/Popover";
import { useBreakpoint } from "@hotel-engine/hooks";
import type { ReactNode } from "react";

interface IBookingDetailsContainer {
  showBookingDetails: boolean;
  isPreview: boolean;
  content: ReactNode;
  children: JSX.Element;
}

const BookingDetailsContainer = ({
  showBookingDetails,
  isPreview,
  content,
  children,
}: IBookingDetailsContainer) => {
  const isMobile = useBreakpoint("sm", "max");

  return showBookingDetails ? (
    <Popover
      overlayStyle={{
        width: isMobile ? "210px" : "370px",
      }}
      placement={isPreview ? "topRight" : "topLeft"}
      content={content}
    >
      {children}
    </Popover>
  ) : (
    <div>{children}</div>
  );
};

export default BookingDetailsContainer;
