import { Box, Typography } from "@hotelengine/atlas-web";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";
import { CollapsibleContent } from "../../../../../shared/CollapsibleContent";

interface ICancelPolicyCopyProps {
  policyCopy: string | undefined;
}

export const CancelPolicy = ({ policyCopy }: ICancelPolicyCopyProps) => {
  const { COMPANY_NAME } = useCompanyName();

  return (
    <CollapsibleContent
      toggleBtnLabel={
        <Typography data-testid="cancel-policy" variant="heading/lg" color="foregroundPrimary">
          Cancellation policy
        </Typography>
      }
      testID="cancel-policy-collapsible"
      toggleTestID="cancel-content-toggle-btn"
    >
      <Box padding={24} paddingTop={16}>
        {policyCopy ? (
          <Typography variant="body/md" color="foregroundPrimary" data-testid="policy-text">
            {policyCopy}
          </Typography>
        ) : (
          <Typography
            variant="body/md"
            color="foregroundPrimary"
            data-testid="fallback-policy-text"
          >
            {`The change policies for this flight are unavailable. Contact ${COMPANY_NAME} support for assistance.`}
          </Typography>
        )}
      </Box>
    </CollapsibleContent>
  );
};
