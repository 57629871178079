import type { UseQueryOptions } from "react-query";

import { convertToSnake, useApi } from "@hotel-engine/react-query/useApi";
import { useExtendedQuery } from "@hotel-engine/react-query/useExtendedQuery";
import type { IError } from "@hotel-engine/types/errors";
import type {
  ISalesforceCaseQueryParams,
  ISalesforceCaseQueryResponse,
} from "@hotel-engine/types/salesforceCase";

import { endpoints } from "../constants";

const useSalesforceCaseQuery = (
  params: ISalesforceCaseQueryParams,
  options?: UseQueryOptions<ISalesforceCaseQueryResponse[], IError>
) => {
  const get = useApi("get");

  const queryFn = () =>
    get<ISalesforceCaseQueryResponse[]>(endpoints.salesforceCase, convertToSnake(params));

  return useExtendedQuery([endpoints.salesforceCase, params], queryFn, options);
};

export { useSalesforceCaseQuery };
