import { Fragment } from "react";
import { Typography } from "@hotelengine/atlas-web";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useAppSelector } from "store/hooks";

import * as Styled from "./styles";
import RefundDetailsItem from "./RefundDetailsItem";

const RefundDetails = () => {
  const userBusiness = useAppSelector((state) => state.Auth.user?.business);
  const { reservation } = useModificationsContext();
  const { refundBreakdowns, flexShorteningRefundAmount, cancellationRefundAmount, refundType } =
    reservation;
  const totalRefundAmount = Number(cancellationRefundAmount) + Number(flexShorteningRefundAmount);

  if (!totalRefundAmount && !refundBreakdowns.length) return null;

  return (
    <>
      <Styled.RefundDetailsContainer>
        <Styled.HeaderContainer>
          <Typography variant="uppercase/xs-strong" color="foregroundPrimary">
            Refund details
          </Typography>
        </Styled.HeaderContainer>
        {refundBreakdowns.length ? (
          refundBreakdowns.map((item, index) => (
            <Fragment key={item.createdAt}>
              <RefundDetailsItem
                date={item.createdAt}
                business={userBusiness}
                originalFormOfPayment={item.originalFormOfPayment}
                refundAmount={item.totalRefund}
              />
              {index < refundBreakdowns.length - 1 && <Styled.InnerDivider />}
            </Fragment>
          ))
        ) : (
          <RefundDetailsItem
            business={userBusiness}
            originalFormOfPayment={refundType === "Original form of payment"}
            refundAmount={totalRefundAmount}
          />
        )}
      </Styled.RefundDetailsContainer>
    </>
  );
};

export default RefundDetails;
