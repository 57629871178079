import type { FormikHelpers } from "formik";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { useAppSelector } from "store/hooks";
import { useBreakpoint } from "@hotel-engine/hooks";
import { emailRegEx, passwordRegEx, captureMessage } from "@hotel-engine/utilities";
import { usePersonalAccountUserMutation } from "@hotel-engine/react-query/users/usePersonalAccountMutation";
import type { SeverityLevel } from "@sentry/react";
import * as Styled from "./styles";
import { useState } from "react";
import { useSignUpNavigate } from "..";
import { showErrorModal } from "../../PersonalAccountCreationCta/ErrorModal";
import { Icon } from "@hotelengine/atlas-web";

type IPersonalUserFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

const HTTP_CODE_INTERNAL_ERROR = 500;

const UserFormDataSchema = yup.object().shape({
  firstName: yup.string().required("First name required"),
  lastName: yup.string().required("Last name required"),
  email: yup
    .string()
    .matches(emailRegEx, {
      message: "Please enter a valid email address",
    })
    .required("Please enter a valid email address"),
  password: yup
    .string()
    .matches(passwordRegEx, {
      message: "Please enter a valid password",
    })
    .required("Please enter a valid password")
    .when(["email"], ([email], schema) => {
      return schema.test({
        test: (password) => {
          return email && password && !password.includes(email.split("@")[0]);
        },
        message: "Password must not contain email",
      });
    }),
});

const SignUpForm = () => {
  const { onNavigate, onSetEmail } = useSignUpNavigate();
  const { user } = useAppSelector((state) => state.Auth);
  const personalAccountMutation = usePersonalAccountUserMutation();
  const isDesktop = useBreakpoint("xl");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (
    values: IPersonalUserFormValues,
    { setFieldError }: FormikHelpers<IPersonalUserFormValues>
  ) => {
    setLoading(true);
    personalAccountMutation.mutate(
      {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        passwordConfirmation: values.password,
      },
      {
        onSuccess() {
          onSetEmail(values.email);
          onNavigate("confirm-email-message");
        },
        onError(error) {
          const response = error?.response;
          const isInternalError =
            (response?.status ?? HTTP_CODE_INTERNAL_ERROR) >= HTTP_CODE_INTERNAL_ERROR;
          const errorReason = response?.data?.invalidParams?.[0]?.reasons?.[0];
          const errorDetails = !!errorReason ? ` - ${errorReason}` : "";
          let errorSeverity = "critical" as SeverityLevel;

          if (!isInternalError && errorReason) {
            setFieldError("email", errorReason);

            errorSeverity = "info";
          } else {
            showErrorModal();
          }

          captureMessage(
            `Error sign up personal account user${errorDetails}`,
            { error },
            errorSeverity
          );
        },
        onSettled: () => setLoading(false),
      }
    );
  };

  return (
    <Formik
      initialValues={
        {
          email: "",
          password: "",
          firstName: user?.firstName,
          lastName: user?.lastName,
        } as IPersonalUserFormValues
      }
      onSubmit={handleSubmit}
      validationSchema={UserFormDataSchema}
    >
      {({ values, setFieldValue, errors, touched }) => {
        const formErrors = {
          firstName: !!(errors.firstName && touched.firstName),
          lastName: !!(errors.lastName && touched.lastName),
          email: !!(touched.email && errors.email),
          password: !!(errors.password && touched.password),
        };

        return (
          <Form data-testid="signup-form">
            <Styled.VerticalFlowContainer>
              <Styled.FullNamesWrapper
                isDesktop={isDesktop}
                hasError={formErrors.firstName || formErrors.lastName}
              >
                <Styled.FirstName isDesktop={isDesktop} hasError={formErrors.firstName}>
                  <Styled.FieldText htmlFor={"firstName"}>First name</Styled.FieldText>
                  <Field
                    component={Styled.InputBox}
                    size="default"
                    id="firstName"
                    name="firstName"
                    data-testid="firstName"
                    allowClear
                  />
                </Styled.FirstName>
                <Styled.LastName isDesktop={isDesktop} hasError={formErrors.lastName}>
                  <Styled.FieldText htmlFor={"lastName"}>Last name</Styled.FieldText>
                  <Field
                    component={Styled.InputBox}
                    size="default"
                    id="lastName"
                    name="lastName"
                    data-testid="lastName"
                    value={values.lastName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue("lastName", e.target.value);
                    }}
                    allowClear
                  />
                </Styled.LastName>
              </Styled.FullNamesWrapper>
              <Styled.FieldWrapper hasError={formErrors.email}>
                <Styled.FieldText htmlFor={"email"}>Personal email address</Styled.FieldText>
                <Field
                  autocomplete="username"
                  component={Styled.InputBox}
                  size="default"
                  id="email"
                  name="email"
                  data-testid="email"
                  value={values.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue("email", e.target.value);
                  }}
                />
              </Styled.FieldWrapper>
              <Styled.FieldWrapper hasError={formErrors.password}>
                <Styled.FieldText htmlFor="password">Password</Styled.FieldText>
                <Field
                  autocomplete="new-password"
                  component={Styled.InputBox}
                  size="default"
                  id="password"
                  name="password"
                  data-testid="password"
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue("password", e.target.value);
                  }}
                />
                <Styled.IconContainer onClick={() => setShowPassword(!showPassword)}>
                  <Icon name={showPassword ? "eye" : "eye-slash"} size="md" />
                </Styled.IconContainer>
              </Styled.FieldWrapper>

              <Styled.PasswordRequirementText variant="body/xs">
                Password must be at least 8 characters long containing one uppercase letter, one
                lowercase letter, and one number.
              </Styled.PasswordRequirementText>
              <Styled.SubmitButton htmlType="submit" type={"primary"} data-testid="submit-button">
                {!!loading && <Styled.SignUpStepLoader />}
                Continue
              </Styled.SubmitButton>
            </Styled.VerticalFlowContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SignUpForm;
