import { useCallback, useEffect } from "react";

import { usePostCheckoutActions } from "@hotel-engine/contexts";
import { useCustomFieldsQuery } from "@hotel-engine/react-query/customFields/useCustomFieldsQuery";
import { useReservationQuery } from "@hotel-engine/react-query/reservation/useReservationQuery";
import type { IUser } from "@hotel-engine/types/user";
import ItineraryContent from "@hotel-engine/app/ItineraryContent";
import { Unsafe } from "@hotel-engine/data";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";

import type { ViewType } from "pages/Trips/constants";
import { viewTypes } from "pages/Trips/constants";

import * as Styled from "../styles";
import type { ITripPreview } from "../../../context";
import { useTripsContext } from "../../../context";

export interface IReservationBaseWithId extends IReservationBase {
  tripId: number;
}

export interface ITripsItineraryPreviewPanelProps {
  handleView?: (viewType: ViewType) => void;
  itineraryId: string | number;
  onClose: (tripPreview: ITripPreview) => void;
  user: IUser;
  view: "map" | "list";
}

const LodgingItineraryPanel = ({
  handleView,
  onClose,
  itineraryId,
  user,
  view,
}: ITripsItineraryPreviewPanelProps) => {
  const { tripsRefresh } = useTripsContext();

  const isLodgingRefreshOn = useIsFeatureFlagOn("lodging-refresh");

  const {
    data,
    isLoading: isResLoading,
    refetch: refetchItinerary,
  } = useReservationQuery({
    id: String(itineraryId),
  });

  const tripItinerary = {
    tripId: itineraryId,
    ...data,
    flexEnabled: !!data?.flexEnabled,
  } as IReservationBaseWithId;

  const { isLoading: customFieldsLoading } = useCustomFieldsQuery({
    departmentId: user.department.id,
    role: user.role,
  });

  const { state: postCheckoutActionsState } = usePostCheckoutActions();
  const { completedPostCheckoutActions } = postCheckoutActionsState;

  useEffect(() => {
    if (!!completedPostCheckoutActions?.length) {
      refetchItinerary().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    }
  }, [completedPostCheckoutActions?.length, refetchItinerary]);

  const handleScroll = useCallback(() => {
    const panel = document.getElementById("preview-panel");

    if (!!panel) {
      panel.style.height = !!isLodgingRefreshOn
        ? `calc(100vh - 80px + ${`${globalThis.scrollY}px`})`
        : "";
    }
  }, [isLodgingRefreshOn]);

  useEffect(() => {
    globalThis.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      globalThis.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Styled.TripsPreviewPanel id="preview-panel" data-testid="preview-panel">
      {!!tripItinerary && (
        <Styled.ScrollContainer
          // This id is needed for post-modification scroll to top
          id="itinerary-scroll-container"
        >
          <ItineraryContent
            isLoading={customFieldsLoading || isResLoading}
            itinerary={tripItinerary}
            isPreview={true}
            onClose={onClose}
            handleView={() => handleView?.(viewTypes.MAP)}
            view={view}
            refetchReservation={tripsRefresh}
            bookingType="lodging"
          />
        </Styled.ScrollContainer>
      )}
    </Styled.TripsPreviewPanel>
  );
};

export default LodgingItineraryPanel;
