import Typography from "@hotel-engine/common/Typography";
import type { TimeLineItemProps } from "@hotel-engine/common/Timeline";
import * as Styled from "./styles";
import type { ISupportCase } from "@hotel-engine/types/supportCase";
import moment from "moment-timezone";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";

interface IActivityDetails {
  supportCase: ISupportCase | undefined;
}

const ActivityDetails = ({ supportCase }: IActivityDetails) => {
  const statusClosedAt = supportCase?.statusClosedAt;
  const statusInProgressAt = supportCase?.statusInProgressAt;
  const submittedBy = supportCase?.submittedBy;
  const createdAt = supportCase?.createdAt;
  const timeLineItems: TimeLineItemProps[] = [];
  const { COMPANY_NAME } = useCompanyName();

  if (!!statusClosedAt) {
    const timeFormat = moment(statusClosedAt).tz("America/New_York").format("M/D/YYYY h:mmA z");
    const timeLineItem: TimeLineItemProps = {
      dot: <Styled.TimeLineIconFill />,
      children: (
        <>
          <Styled.ActivityHeader variant="body-medium">
            Case closed by {COMPANY_NAME}
          </Styled.ActivityHeader>
          <Styled.ActivityBody variant="body-small">{timeFormat}</Styled.ActivityBody>
        </>
      ),
    };
    timeLineItems.push(timeLineItem);
  }

  if (!!statusInProgressAt) {
    const timeFormat = moment(statusInProgressAt).tz("America/New_York").format("M/D/YYYY h:mmA z");
    const timeLineItem: TimeLineItemProps = {
      dot: <Styled.TimeLineIconNoFill />,
      children: (
        <>
          <Styled.ActivityHeader variant="body-medium">Case in Progress</Styled.ActivityHeader>
          <Styled.ActivityBody variant="body-small">{timeFormat}</Styled.ActivityBody>
        </>
      ),
    };
    timeLineItems.push(timeLineItem);
  }

  if (!!submittedBy) {
    const timeFormat = moment(createdAt).tz("America/New_York").format("M/D/YYYY h:mmA z");
    const timeLineItem: TimeLineItemProps = {
      dot: <Styled.TimeLineIconNoFill />,
      children: (
        <>
          <Styled.ActivityHeader variant="body-medium">
            Case submitted by {submittedBy}
          </Styled.ActivityHeader>
          <Styled.ActivityBody variant="body-small">{timeFormat}</Styled.ActivityBody>
        </>
      ),
    };
    timeLineItems.push(timeLineItem);
  }

  return (
    <>
      <Typography as="h2" variant="small">
        Activity
      </Typography>

      <Styled.ActivityTimeLine>
        {timeLineItems.map((item, idx) => (
          <Styled.ActivityTimeLine.Item key={idx} {...item} />
        ))}
      </Styled.ActivityTimeLine>
    </>
  );
};

export default ActivityDetails;
