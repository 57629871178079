import { emailRegEx } from "@hotel-engine/utilities";

import type { IEmailPillValue } from "../";

export const INVALID_EMAIL_MESSAGE = `Invalid Email`;
export const MAX_EMAILS_MESSAGE = (maxEmails: number) =>
  `Only ${maxEmails} email addresses allowed`;
export const EMAIL_NOT_UNIQUE_MESSAGE = "Email already entered";
export const NO_ERROR_MESSAGE = "No error";

/** given the entire list of email pills produce validation. quantity validation factored out into useWaterline */
export const validateAll = (
  emailsList: IEmailPillValue[],
  hasEmailObject: (k: string) => boolean,
  maxEmails: number
) => {
  const notUnique = emailsList.map((email, index) => {
    const lengthMeasure = index + 1;

    // validation logic
    const isLessThanMaxEmails = lengthMeasure <= maxEmails;
    const isActualEmailFormat = emailRegEx.test(email.value);
    const isValid = isLessThanMaxEmails && isActualEmailFormat;
    const isUnique = !hasEmailObject(email.value.toLowerCase());

    let lastErrorMessage;
    if (!isActualEmailFormat) {
      lastErrorMessage = INVALID_EMAIL_MESSAGE;
    } else if (!isLessThanMaxEmails) {
      lastErrorMessage = MAX_EMAILS_MESSAGE(maxEmails);
    } else if (!isUnique) {
      lastErrorMessage = EMAIL_NOT_UNIQUE_MESSAGE;
    } else {
      lastErrorMessage = NO_ERROR_MESSAGE;
    }

    return {
      ...email,
      valid: isValid,
      lastErrorMessage,
    };
  });

  const uniqueEmailsObj: { [k: string]: string } = {};
  const unique = notUnique.map((pillValue) => {
    const low = pillValue.value.toLowerCase();
    if (low in uniqueEmailsObj) {
      return {
        ...pillValue,
        valid: false,
        lastErrorMessage: EMAIL_NOT_UNIQUE_MESSAGE,
      };
    }
    uniqueEmailsObj[low] = low;
    return pillValue;
  });
  return unique;
};
