import { ScreenReaderOnly } from "@hotel-engine/app/ScreenReaderOnly/styles";
import { Icon, Typography } from "@hotelengine/atlas-web";
import { useFormatDate } from "@hotel-engine/hooks";
import type { IRecentSearch } from "@hotel-engine/types/search";
import { weightSubstringMatch } from "../../helpers";

import * as Styled from "../../styles";

type SearchResultProps = {
  item: IRecentSearch;
  searchInput: string;
};

const RecentSearches = ({
  item: { location, checkIn, checkOut, roomCount, guestCount, isAutocomplete },
  searchInput,
}: SearchResultProps) => {
  const roomAndGuestCount = `${roomCount} ${roomCount > 1 ? "rooms" : "room"}, ${guestCount} ${
    guestCount > 1 ? "guests" : "guest"
  }`;
  const checkInDate = useFormatDate(checkIn, "M/DD");
  const checkOutDate = useFormatDate(checkOut, "M/DD");

  const autocompleteLineOne = location.split(", ")[0];
  const autocompleteLineTwo = location.split(", ").slice(1).join(", ");
  const titleClass = !!isAutocomplete ? "-ff" : "";

  const recentSearchLineTwo = !!isAutocomplete ? (
    <Typography
      variant="body/xs"
      color="foregroundSecondary"
      className="description description--secondary"
    >
      {autocompleteLineTwo}
    </Typography>
  ) : (
    <Typography
      className="description description--secondary"
      color="foregroundSecondary"
      variant="body/xs"
    >
      <span>
        <span>{checkInDate}</span>
        <span aria-hidden={true}>{" - "}</span>
        <ScreenReaderOnly>to</ScreenReaderOnly>
        <span>{checkOutDate}</span>
      </span>
      <span aria-hidden={true}> | </span>
      <ScreenReaderOnly>&nbsp;</ScreenReaderOnly>
      <span>{roomAndGuestCount}</span>
    </Typography>
  );

  return (
    <div>
      <ScreenReaderOnly>Recent Search &nbsp;</ScreenReaderOnly>
      <Styled.LocationPickerOptionIcon>
        <Icon name="clock--solid" data-testid={`recent-search-${location}`} />
      </Styled.LocationPickerOptionIcon>
      <Styled.LocationPickerOptionValue>
        <Typography
          className={`description description--primary${titleClass}`}
          color="foregroundPrimary"
          data-testid="clock"
          variant="body/md"
        >
          {!!isAutocomplete ? weightSubstringMatch(searchInput, autocompleteLineOne) : location}
        </Typography>
        {recentSearchLineTwo}
      </Styled.LocationPickerOptionValue>
    </div>
  );
};

export default RecentSearches;
