import styled, { css } from "styled-components";

export const NoTripsMessage = styled.div<{ $isCalendarView?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, $isCalendarView }) =>
    $isCalendarView ? theme.colors.backgroundPrimary : theme.colors.backgroundSecondary};
  min-height: 500px;
  height: 100%;
  gap: ${({ theme }) => theme.spacing[24]};

  ${({ $isCalendarView, theme }) =>
    !!$isCalendarView &&
    css`
        border-bottom-left-radius: ${theme.borderRadius[16]};
        border-bottom-right-radius: ${theme.borderRadius[16]};
    `}

  & > svg {
    width: 35px;
  }
`;
