import { useEffect, useState } from "react";
import moment from "moment";

import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";

export const MODIFICATION_CONTRACTS_VIEWED = "modification_contracts_viewed";

type ContractModifications =
  | {
      [key: string]: ContractModification;
    }
  | undefined;

type ContractModification = {
  count: number;
  expiration: moment.Moment;
};

export const useModificationsLocalStorage = (modificationCount: number) => {
  const { reservation } = useModificationsContext();
  const [showList, setShowList] = useState(false);

  useEffect(() => {
    // First get the object, if it doesn't exist, set the initial state
    const viewedModificationContractsString = localStorage.getItem(MODIFICATION_CONTRACTS_VIEWED);

    const viewedModificationContracts: ContractModifications = !!viewedModificationContractsString
      ? JSON.parse(viewedModificationContractsString)
      : undefined;

    const isModificationsCountOutOfDate =
      !Object.keys(viewedModificationContracts || {}).includes(reservation.contractNumber) ||
      viewedModificationContracts?.[reservation.contractNumber].count !== modificationCount;

    if (!viewedModificationContracts) {
      const initialSetup: ContractModifications = {
        [reservation.contractNumber]: {
          count: modificationCount,
          expiration: moment(reservation.checkOut).add(2, "weeks"),
        },
      };

      localStorage.setItem(MODIFICATION_CONTRACTS_VIEWED, JSON.stringify(initialSetup));

      setShowList(true);

      // If the object exists, but the contract info is out of date, update it
    } else if (!!viewedModificationContracts && isModificationsCountOutOfDate) {
      const updatedContractCount = {
        ...viewedModificationContracts,
        [reservation.contractNumber]: {
          count: modificationCount,
          expiration: moment(reservation.checkOut).add(2, "weeks"),
        },
      };

      localStorage.setItem(MODIFICATION_CONTRACTS_VIEWED, JSON.stringify(updatedContractCount));

      setShowList(true);
    }
    // Otherwise, the object exists and is not out of date, so do nothing here since showList starts as false

    /**  Then finally, flush any expired contracts out of the object and reset it to localStorage, this wont
     * necessarily flush all contracts right when they expire, but in theory this will run for other contracts
     * often enough that it will keep the localStorage object relatively clean
     */
    flushExpiredContracts();
  }, [reservation.contractNumber, reservation.checkOut, modificationCount]);

  return { showList, setShowList };
};

const flushExpiredContracts = () => {
  const storedContracts = localStorage.getItem(MODIFICATION_CONTRACTS_VIEWED);
  const contracts: ContractModifications = storedContracts
    ? JSON.parse(storedContracts)
    : undefined;

  if (!contracts) return;

  const filteredContracts = Object.fromEntries(
    Object.entries(contracts).filter(([_, val]) => moment().isBefore(val.expiration))
  );

  localStorage.setItem(MODIFICATION_CONTRACTS_VIEWED, JSON.stringify(filteredContracts));
};
