import { useEffect } from "react";
import { useBreakpoint, usePrevious, useToggle } from "@hotel-engine/hooks";
import type { IOccupant } from "@hotel-engine/types/booking";
import { isStateOfTexasUser } from "@hotel-engine/utilities";
import { formatRoomDescription } from "@hotel-engine/utilities/formatters/formatPropertyInformation";

import type { TypographyVariant } from "@hotelengine/atlas-web";
import { Typography } from "@hotelengine/atlas-web";

import { useGlobalTheme } from "@hotel-engine/contexts/GlobalThemeContext";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { FormatInternationalPhoneNumbers } from "../../../../FormatInternationalPhoneNumbers";
import * as Styled from "./styles";
import { areRoomDatesDifferent, isRoomModified } from "../../../helpers/helpers";
import RoomStatusPill from "./components/RoomStatusPill";
import ItineraryDateRange from "../ItineraryDateRange";
import { useTypographyScaling } from "../../../hooks/useTypographyScaling";

export interface IRoomInfoProps {
  orgId?: number;
}

export const RoomInfo = ({ orgId }: IRoomInfoProps) => {
  const { COMPANY_NAME } = useCompanyName();
  const { tokens } = useGlobalTheme();
  const [showAllRooms, toggleShowAllRooms] = useToggle(false);
  const { reservation: itinerary, isPreview } = useModificationsContext();

  const previousItinerary = usePrevious(itinerary);
  const isMobile = useBreakpoint("lg", "max");
  const showDatesForEachRoom = areRoomDatesDifferent(itinerary) && itinerary.roomCount > 1;

  const { typographyVariants } = useTypographyScaling();

  useEffect(() => {
    const shouldToggleShowAllOff =
      isPreview && itinerary?.id !== previousItinerary?.id && showAllRooms;

    if (shouldToggleShowAllOff) {
      toggleShowAllRooms();
    }
  }, [itinerary, previousItinerary, showAllRooms, toggleShowAllRooms, isPreview]);

  if (!itinerary?.rooms?.[0]?.occupants?.[0]) {
    return null;
  }

  const { rooms, roomCount, roomDescription, roomTitle, product } = itinerary;
  const roomType =
    roomTitle ??
    roomDescription ??
    `Contact your ${COMPANY_NAME} Representative for more information.`;
  const displayedRooms = showAllRooms ? rooms : [rooms[0]];

  const showRateProductName = !isStateOfTexasUser(undefined, orgId);

  const buildLoyaltyInfo = (occupant: IOccupant) => (
    <>
      {!!occupant.loyaltyRewardName && !!occupant.loyaltyRewardProgramNumber && (
        <Styled.ListItem $isPreview={isPreview}>
          <Typography variant={typographyVariants.body} color="foregroundSecondary">
            Loyalty program
          </Typography>
          <Typography
            variant={typographyVariants.body}
            color="foregroundPrimary"
            className="break-wrap"
          >
            {occupant.loyaltyRewardName}
          </Typography>
        </Styled.ListItem>
      )}
      {!!occupant.loyaltyRewardProgramNumber && (
        <Styled.ListItem $isPreview={isPreview}>
          <Typography variant={typographyVariants.body} color="foregroundSecondary">
            Loyalty number
          </Typography>
          <Typography
            variant={typographyVariants.body}
            color="foregroundPrimary"
            className="break-wrap"
          >
            {occupant.loyaltyRewardProgramNumber}
          </Typography>
        </Styled.ListItem>
      )}
    </>
  );

  const roomsList = displayedRooms.map((room, i) => {
    const roomIsModified = isRoomModified(room);
    const roomIsCancelled = room.status === "cancelled";
    const loyaltyInfo = buildLoyaltyInfo(room.occupants[0]);

    return (
      <Styled.DetailsList key={i}>
        <Typography variant={typographyVariants.heading} color="foregroundPrimary">
          Room {i + 1}
          <RoomStatusPill modified={roomIsModified} cancelled={roomIsCancelled} />
        </Typography>
        {!!showDatesForEachRoom && (
          <ItineraryDateRange
            itinerary={itinerary}
            roomIndex={i}
            isPreview={isPreview || isMobile}
          />
        )}
        <Styled.ListItem $isPreview={isPreview}>
          <Typography variant={typographyVariants.body} color="foregroundSecondary">
            Primary guest
          </Typography>
          <Typography
            variant={typographyVariants.body}
            color="foregroundPrimary"
          >{`${room.occupants[0].firstName} ${room.occupants[0].lastName}`}</Typography>
        </Styled.ListItem>
        <Styled.ListItem $isPreview={isPreview}>
          <Typography variant={typographyVariants.body} color="foregroundSecondary">
            Phone
          </Typography>

          <Typography variant={typographyVariants.body} color="foregroundPrimary">
            {room.occupants[0].phone ? (
              <FormatInternationalPhoneNumbers phoneNumber={room.occupants[0].phone} />
            ) : (
              "N/A"
            )}
          </Typography>
        </Styled.ListItem>
        <Styled.ListItem $isPreview={isPreview}>
          <Typography variant={typographyVariants.body} color="foregroundSecondary">
            Email
          </Typography>
          <Typography
            variant={typographyVariants.body}
            style={{ wordWrap: "break-word", overflow: "auto" }}
            color="foregroundPrimary"
            data-testid="guest-email"
            data-private
          >
            {room.occupants[0].email || "N/A"}
          </Typography>
        </Styled.ListItem>
        {loyaltyInfo}
        {!!room.occupants[1] && (
          <Styled.ListItem $isPreview={isPreview}>
            <Typography variant={typographyVariants.body} color="foregroundSecondary">
              Additional guests
            </Typography>
            <Typography
              variant={typographyVariants.body}
              color="foregroundPrimary"
            >{`${room.occupants[1].firstName} ${room.occupants[1].lastName}`}</Typography>
          </Styled.ListItem>
        )}
        {!!itinerary.clientProgramName && (
          <Styled.ListItem $isPreview={isPreview}>
            <Typography variant={typographyVariants.body} color="foregroundSecondary">
              Rate type
            </Typography>
            <Typography
              variant={typographyVariants.body}
              color="foregroundPrimary"
              data-testid="rate-type"
            >
              {itinerary.clientProgramName}
            </Typography>
          </Styled.ListItem>
        )}
        <Styled.ListItem $isPreview={isPreview}>
          <Typography variant={typographyVariants.body} color="foregroundSecondary">
            Room type
          </Typography>
          <Typography variant={typographyVariants.body} color="foregroundPrimary">
            {!!product && !!showRateProductName && (
              <Typography
                variant={`${typographyVariants.body}-strong` as TypographyVariant}
                color="foregroundPrimary"
                style={{ marginRight: tokens.spacing[6] }}
              >
                {product}
              </Typography>
            )}
            {formatRoomDescription(roomType)}
          </Typography>
        </Styled.ListItem>
        <Styled.ListItem $isPreview={isPreview}>
          <Typography variant={typographyVariants.body} color="foregroundSecondary">
            {room.propertyConfirmationNumber ? "Confirmation" : "Contract"}
          </Typography>
          <Typography variant={typographyVariants.body} color="foregroundPrimary">
            {room.propertyConfirmationNumber
              ? room.propertyConfirmationNumber
              : room.contractNumber}
          </Typography>
        </Styled.ListItem>
        {!!showAllRooms && i !== displayedRooms.length - 1 && <Styled.RoomSeparator />}
      </Styled.DetailsList>
    );
  });

  return (
    <Styled.RoomDetails $isPreview={isPreview || isMobile}>
      <Typography variant={typographyVariants.title} color="foregroundPrimary">
        Room details
      </Typography>
      {roomsList}
      {roomCount > 1 && (
        <Styled.ShowRoomsContainer>
          <Styled.ShowRoomsLink variant={typographyVariants.link} onClick={toggleShowAllRooms}>
            {showAllRooms ? "Hide full room list" : `Show all ${roomCount} rooms`}
          </Styled.ShowRoomsLink>
        </Styled.ShowRoomsContainer>
      )}
    </Styled.RoomDetails>
  );
};
