import { useCallback, useEffect, useState } from "react";

import type { ILinkedAuthStrategy } from "@hotel-engine/types/authStrategy";
import { useAppSelector } from "store/hooks";

import type { IEmailLoginDid403 } from "../../../EmailConfirmationWarningModal";
import * as CommonStyled from "../styles";
import Loader from "@hotel-engine/common/Loader";
import BusinessSelect from "./BusinessSelect";
import SSOButton from "./SSOButton";
import * as Styled from "./styles";
import type { AuthorizationParams } from "@auth0/auth0-react";
import * as LoginFormStyled from "../../../../../../pages/Login/components/LoginForm/styles";
import { useAuth0 } from "@auth0/auth0-react";
import Typography from "@hotel-engine/common/Typography";
import { isSingleStrategyWithAuth0 } from "@hotel-engine/utilities/auth/helpers";

interface ISignInWithEmailPlusSSOProps {
  /** Available auth strategies for this account */
  authStrategies: ILinkedAuthStrategy[];
  shouldUseAuth0: boolean | undefined;
  lookupEmail: string | null | undefined;
  queryIsResolved: boolean;
  closeModal: () => void;
  /** Function used for showing the "Whoops" modal for attempting to login to an unverified account */
  setEmailLoginDid403: (data: IEmailLoginDid403) => void;
  /** Optional route used post-login */
  forwardingRoute?: string;
}

const OtherOption = () => (
  <Styled.OtherOptionBox>
    <Styled.Doted />
    <Styled.OtherOptionText>OR</Styled.OtherOptionText>
    <Styled.Doted />
  </Styled.OtherOptionBox>
);

export const SignInWithEmailPlusSSO = ({
  authStrategies,
  shouldUseAuth0,
  lookupEmail,
  queryIsResolved,
}: ISignInWithEmailPlusSSOProps) => {
  const accountType = useAppSelector((state) => state.Auth?.user?.accountType);
  const isPersonalAccount = accountType === "personal";
  const isSAML = (strategy): boolean => strategy.protocol === "SAML";
  const userDomainBusinessStrategies = authStrategies.filter(isSAML);
  const [selectedAuthStrategy, setSelectedAuthStrategy] = useState<ILinkedAuthStrategy | undefined>(
    undefined
  );
  /**
   * If there is only one auth strategy and it has an auth0 connection, use it even if shouldUseAuth0 is false.
   * This is because other apps may not be able to handle the auth0 connection yet
   */
  const singleStrategyWithAuth0 = isSingleStrategyWithAuth0(authStrategies);

  const { loginWithRedirect } = useAuth0();
  const redirectUserToAuth0 = useCallback(async () => {
    const returnTo = encodeURIComponent(globalThis.location.href);
    const redirectPath = `${globalThis.location.origin}/?return=${returnTo}`;
    const authorizationParams: AuthorizationParams = {
      login_hint: lookupEmail || "",
      redirect_uri: redirectPath,
      prompt: "login",
    };
    if (singleStrategyWithAuth0) {
      // if there is only one auth strategy and it has an auth0 connection, use it
      authorizationParams.connection = authStrategies[0].auth0Connection;
    }
    await loginWithRedirect({
      authorizationParams,
    });
  }, [authStrategies, loginWithRedirect, lookupEmail, singleStrategyWithAuth0]);
  /**
   * This effect is because the authStrategies may be undefined
   * waiting for the query to resolve
   */
  useEffect(() => {
    const handleResolved = async () => {
      if (shouldUseAuth0 || (queryIsResolved && singleStrategyWithAuth0)) {
        await redirectUserToAuth0();
      } else if (queryIsResolved && userDomainBusinessStrategies.length === 1) {
        setSelectedAuthStrategy(userDomainBusinessStrategies[0]);
      }
      // Accounts with Auth0 enabled *and* multiple SSO strategies will
      // show those options in a dropdown and an option to login via Auth0
    };

    void handleResolved();
  }, [
    queryIsResolved,
    userDomainBusinessStrategies,
    shouldUseAuth0,
    singleStrategyWithAuth0,
    redirectUserToAuth0,
  ]);
  const handleSignInWithPassword = () => redirectUserToAuth0();

  const handleBusinessSelect = (strategyIndex: number): void =>
    setSelectedAuthStrategy(authStrategies[strategyIndex]);

  const multipleMatchingBusiness = userDomainBusinessStrategies.length > 1 && isPersonalAccount;
  const showOtherOption = (selectedAuthStrategy || multipleMatchingBusiness) && isPersonalAccount;
  const ssoRequired =
    authStrategies.some((strategy) => strategy.isRequired && isSAML(strategy)) && isPersonalAccount;
  const formContainerClassName = showOtherOption ? "" : " fixed-submit-btn";

  if (shouldUseAuth0 || singleStrategyWithAuth0)
    return (
      <Styled.LoadingContainer>
        <Typography variant="medium">Loading...</Typography>
        <Loader size="large" />
      </Styled.LoadingContainer>
    );

  return (
    <>
      {!!ssoRequired && (
        <div className="form-title">
          <CommonStyled.FormTitle>Sign in with SSO</CommonStyled.FormTitle>
        </div>
      )}
      <div className={`form-container${formContainerClassName}`}>
        {multipleMatchingBusiness ? (
          <BusinessSelect
            authStrategies={authStrategies}
            handleBusinessSelect={handleBusinessSelect}
          />
        ) : null}
        {showOtherOption ? (
          <SSOButton
            selectedAuthStrategy={selectedAuthStrategy}
            onlyOneStrategy={!multipleMatchingBusiness}
          />
        ) : null}
        {showOtherOption && !ssoRequired ? <OtherOption /> : null}
        {!ssoRequired ? (
          <LoginFormStyled.AuthButton
            block
            id={"login-submit"}
            htmlType="submit"
            onClick={() => handleSignInWithPassword()}
            size="large"
          >
            {"Sign in with password"}
          </LoginFormStyled.AuthButton>
        ) : null}
      </div>
    </>
  );
};

export default SignInWithEmailPlusSSO;
