import { useTranslation } from "react-i18next";

export const useAirlineCheckInUrl = () => {
  const { t } = useTranslation("iata_airline_checkin_url");

  // If we have a miss, return an empty string so that we can correctly fall back to the homepage
  return {
    getAirlineCheckInUrlFromIataAirlineCode: (iataAirlineKey: string) =>
      t(`iata_airline_checkin_url.url.${iataAirlineKey}`, ""),
  };
};
