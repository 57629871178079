import type { IReservationBase } from "@hotel-engine/types/reservation";
import { useEffect, useState } from "react";

interface ICancelRoomValues {
  enabled: boolean;
}

export type ICancelRooms = Record<string, ICancelRoomValues>;

export interface ICancelValues {
  cancelRooms: ICancelRooms;
}

interface IUseFormValuesParams {
  /** Current contract */
  reservation: IReservationBase;
}

/** Initializes and manages form values for cancellations, primarily the rooms object to be passed into
 * useCancelBooking
 */
export const useCancelFormValues = ({ reservation }: IUseFormValuesParams) => {
  const initialForm = {
    cancelRooms: {},
  };

  const [formValues, setFormValues] = useState<ICancelValues>(initialForm);

  useEffect(() => {
    const enableAllCancelRooms = reservation.rooms.every(
      (room) => room.modificationActions.cancellable
    );

    setFormValues({
      cancelRooms: reservation.rooms.reduce((accum, room) => {
        accum[room.contractNumber] = { enabled: enableAllCancelRooms };
        return accum;
      }, {}),
    });
  }, [reservation.rooms]);

  const genValueSetter = (value: keyof ICancelValues) => {
    return (change: ICancelValues[keyof ICancelValues], roomPosition?: number) => {
      if (roomPosition) {
        setFormValues({
          ...formValues,
        });
      } else {
        setFormValues({ ...formValues, [value]: change });
      }
    };
  };

  const resetValue = (value: keyof ICancelValues) => {
    setFormValues({
      ...formValues,
      [value]: initialForm[value],
    });
  };

  return {
    formValues,
    genValueSetter,
    resetValue,
  };
};
