import { Typography } from "@hotelengine/atlas-web";
import type { IBusiness } from "@hotel-engine/types/business";
import { formatCurrency, formatDate } from "@hotel-engine/utilities";

import * as Styled from "./styles";
import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";

interface IRefundDetailsItem {
  date?: string;
  business: IBusiness | undefined;
  originalFormOfPayment: boolean;
  refundAmount: number | string | null;
}

const RefundDetailsItem = ({
  date,
  business,
  originalFormOfPayment,
  refundAmount,
}: IRefundDetailsItem) => {
  const { typographyVariants } = useTypographyScaling();
  return (
    <Styled.RefundDetailsItem>
      <Styled.FieldLine>
        <Typography variant={typographyVariants.body} color="foregroundSecondary">
          Refund method
        </Typography>
        <Typography variant={typographyVariants.body} color="foregroundPrimary">
          {originalFormOfPayment ? "Original form of payment" : "Travel credit"}
        </Typography>
      </Styled.FieldLine>

      <Styled.FieldLine>
        <Typography variant={typographyVariants.body} color="foregroundSecondary">
          Paid on
        </Typography>
        <Typography variant={typographyVariants.body} color="foregroundPrimary">
          {formatDate(date, "MMM D YYYY", business?.preferredDateFormat || "mdy")}
        </Typography>
      </Styled.FieldLine>

      <Styled.FieldLine>
        <Typography variant={typographyVariants.body} color="foregroundSecondary">
          Refund amount
        </Typography>
        <Typography variant={typographyVariants.body} color="foregroundPrimary">
          {formatCurrency(refundAmount || "", true, business?.currencyCode)}
        </Typography>
      </Styled.FieldLine>
    </Styled.RefundDetailsItem>
  );
};

export default RefundDetailsItem;
