import { useState } from "react";
import { ampli } from "ampli";

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { DatePicker } from "antd";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { RangePickerValue } from "antd/lib/date-picker/interface";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";

import { routes } from "@hotel-engine/constants";
import { useBreakpoint } from "@hotel-engine/hooks";
import type { IUser } from "@hotel-engine/types/user";
import {
  TextInput,
  Icon,
  BottomSheet,
  BottomSheetTrigger,
  BottomSheetContent,
  BottomSheetTitle,
  BottomSheetClose,
  Box,
  Button,
} from "@hotelengine/atlas-web";
import { useGlobalTheme } from "@hotel-engine/contexts/GlobalThemeContext";

import { capitalize } from "../../../helpers";
import { useTripsContext } from "../../context";
import { FilterPills } from "./FilterPills";
import { MobileTripsDropdown } from "./MobileTripsDropdown";
import * as Styled from "./styles";
import omitKeys from "./helpers/omitKeys";
import useSearchForTrips from "./hooks/useSearchForTrips";
import FiltersDropdown from "./FiltersDropdown";
import DropdownContent from "./FiltersDropdown/DropdownContent";

const { RangePicker } = DatePicker;

interface ITripsFiltersProps {
  isCalendarView?: boolean;
  isMapView?: boolean;
  isPreviewOpen?: boolean;
  user: IUser;
}

const TripsFilters = ({
  isCalendarView = false,
  isMapView = false,
  isPreviewOpen,
  user,
}: ITripsFiltersProps) => {
  const { tokens } = useGlobalTheme();

  const {
    state: tripsState,
    dispatch: tripsDispatch,
    searchValue,
    setSearchValue,
  } = useTripsContext();

  const filterStatuses = ["today", "upcoming", "past", "all"];
  const { filters, status, counts } = tripsState;
  const hasUserSearched = !!searchValue;

  const isMobile = useBreakpoint("xxl", "max");
  const isSmallMobileScreen = useBreakpoint("md", "max");
  const hideFiltersBreakpoint = isPreviewOpen ? "xxxxl" : "xxxl";
  const shouldHideFilters = useBreakpoint(hideFiltersBreakpoint, "max");

  const [dumbyDate, setDumbyDate] = useState(undefined);
  const navigate = useNavigate();

  const handleFilterSelection = (e, value?: unknown | unknown[]) => {
    user &&
      ampli.clickCheckboxFilterInTrips({
        filterName: e.key,
        selectedFilter: !filters[e.key],
        userId: user.id,
      });

    const keys = {
      pendingModificationOnly: true,
      contractType: "multi_room", // this value needs to be snake_case
      refundableOnly: true,
      manual_bookings: true,
      unverified: true,
    };

    if (Array.isArray(value)) {
      tripsDispatch({
        type: "arrayFilter",
        key: e.key,
        values: value,
      });
    } else {
      tripsDispatch({
        type: "filter",
        filters: [{ key: e.key, value: keys[e.key] }],
      });
    }
  };

  const handleDateChange = (date: RangePickerValue) => {
    user &&
      ampli.clickDatesInTrips({
        endDate: String(date[1]),
        userId: user.id,
        startDate: String(date[0]),
      });
    if (!date[0] || !date[1]) {
      return;
    }
    setDumbyDate(undefined);

    tripsDispatch({
      type: "filter",
      filters: [
        { key: "startTimeGt", value: date[0].startOf("day").toISOString() },
        { key: "startTimeLt", value: date[1].endOf("day").toISOString() },
      ],
    });
  };

  const handleStatusClick = (nextStatus) => {
    user &&
      ampli.clickStateOfTrip({
        selectedOption: nextStatus,
        userId: user.id,
      });

    if (status !== nextStatus) {
      navigate(`${routes.trips.base}/${nextStatus}`);
    }
  };

  const handleOnSearchChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchValue(event.target.value);
  };

  useSearchForTrips(searchValue);

  const handleClearFilters = () => {
    tripsDispatch({ type: "clearFilters" });
  };

  const showMobileFiltersDot =
    (!isMapView && !!Object.keys(omitKeys(filters, "search")).length) ||
    (isMapView && !!(Object.keys(omitKeys(filters, "search")).length > 1));

  const searchInput = (
    <Styled.MobileSearchContainer>
      <TextInput
        id="searchTrips"
        aria-label="Search trips"
        placeholder="Search trips"
        size="sm"
        trailingAdornment={!hasUserSearched ? <Icon name="magnifying-glass" /> : undefined}
        value={searchValue}
        onChange={handleOnSearchChange}
        isOnColor={!isSmallMobileScreen}
        isDisabled={!!tripsState.error || !!tripsState.loading}
        isClearable
      />
      {!!isSmallMobileScreen && (
        <BottomSheet>
          <BottomSheetTrigger asChild>
            <Box paddingLeft={8} paddingRight={8} position="relative">
              <Icon name="sliders" color="foregroundPrimary" />
              {!!showMobileFiltersDot && <Styled.FiltersDot />}
            </Box>
          </BottomSheetTrigger>
          <BottomSheetContent style={{ padding: tokens.spacing[16] }}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              marginBottom={8}
            >
              <BottomSheetTitle>Filters</BottomSheetTitle>
              <BottomSheetClose />
            </Box>
            <DropdownContent isCalendarView={isCalendarView} isMapView={isMapView} />
            <Button
              color="everdark"
              style={{ width: "100%", marginTop: tokens.spacing[20] }}
              onClick={handleClearFilters}
            >
              Clear
            </Button>
          </BottomSheetContent>
        </BottomSheet>
      )}
    </Styled.MobileSearchContainer>
  );

  return (
    <Styled.TripsFiltersContainer $isMobile={isSmallMobileScreen}>
      {!!isMobile && searchInput}
      <Styled.TripsFilters>
        <Styled.ButtonGroup style={isSmallMobileScreen ? { flex: 1 } : {}}>
          {!!shouldHideFilters ? (
            <MobileTripsDropdown
              counts={counts}
              handleStatusClick={handleStatusClick}
              status={status}
            />
          ) : (
            <>
              {filterStatuses.map((filterStatus) => {
                return (
                  <Styled.StatusButton
                    id={filterStatus}
                    name={filterStatus}
                    key={filterStatus}
                    data-testid={"status-button-" + filterStatus}
                    variant="plain"
                    className={`${"status-button"} ${status === filterStatus ? "active" : ""}`}
                    onClick={() => handleStatusClick(filterStatus)}
                    isDisabled={!!tripsState.error}
                  >
                    {capitalize(filterStatus)} {`(${counts[filterStatus]})`}
                  </Styled.StatusButton>
                );
              })}
            </>
          )}
        </Styled.ButtonGroup>
        <Styled.ButtonGroup columnGap="small">
          {!isMobile && !isCalendarView && (
            <>
              <Styled.RangePickerGlobalStyles />
              <RangePicker
                id="trips_datesPicker"
                placeholder={["Date range", ""]}
                style={{ width: 130 }}
                value={dumbyDate}
                onChange={handleDateChange}
                suffixIcon={<Icon name="calendar" />}
                disabled={!!tripsState.error}
              />
            </>
          )}
          {!isSmallMobileScreen && (
            <FiltersDropdown isCalendarView={isCalendarView} isMapView={isMapView} />
          )}
          {!isMobile && searchInput}
        </Styled.ButtonGroup>
      </Styled.TripsFilters>
      {!isSmallMobileScreen && (
        <FilterPills
          filters={omitKeys(filters, "search")}
          handleFilterSelection={handleFilterSelection}
          setSearchValue={setSearchValue}
          tripsDispatch={tripsDispatch}
          lodgingOnly={isCalendarView || isMapView}
        />
      )}
    </Styled.TripsFiltersContainer>
  );
};

export default TripsFilters;
