import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "@hotel-engine/lib/react-router-dom";
import { useAppSelector } from "store/hooks";

// TODO: Update this antD, as soon as we have a replacement in Atlas. For now we will continue using the AntD
import { Modal } from "@hotel-engine/common/Modal";
import DownloadAppBanner from "@hotel-engine/app/DownloadAppBanner";
import { useCustomFieldsQuery } from "@hotel-engine/react-query/customFields/useCustomFieldsQuery";
import { captureMessage } from "@hotel-engine/utilities/logger";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { Box } from "@hotelengine/atlas-web";

import type { ILocationState, IModification } from "pages/Itinerary";

import MarketingBanners from "../MarketingBanners";
import AdditionalInfo from "../AdditionalInfo";
import CompanyDetails from "../CompanyDetails";
import ItineraryRefundPolicy from "../ItineraryRefundPolicy";
import RefundDetails from "../RefundDetails";
import { RoomInfo } from "../RoomInfo";
import SummaryOfCharges from "../SummaryOfCharges";
import TripInfo from "../TripInfo";
import Location from "../Location";

import { useModificationsContext } from "../../../ModificationsContext";
import ModificationViews from "../../../shared/ModficationViews";
import ModificationStatusModal from "../../../shared/ModficationViews/components/ModificationStatusModal";
import { useNavigationBlocker } from "../../../shared/ModficationViews/components/NavigationModal/useNavigationBlocker";
import Travelers from "../Travelers";
import ReviewProperty from "../ItineraryActions/components/ReviewProperty";
import ModificationStatuses from "../ItineraryActions/components/ModificationStatuses";

export interface IItineraryContentBodyProps {
  billingIssues: IModification[];
}

const ItineraryContentBody = ({ billingIssues }: IItineraryContentBodyProps) => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.Auth?.user);
  const {
    state: { modificationView, showStatusModal },
    reservation,
    isPreview,
  } = useModificationsContext();
  const [currentReservation, setCurrentReservation] = useState(reservation);
  const { state: locationState } = useLocation<ILocationState>();

  const isModificationsNotificationsOn = useIsFeatureFlagOn("modification-notifications-v1");

  useNavigationBlocker();

  useEffect(() => {
    if (reservation.contractNumber !== currentReservation.contractNumber) {
      setCurrentReservation(reservation);
    }
  }, [reservation, currentReservation]);

  const itineraryHasModifications = !!Object.keys(reservation?.modifications?.all).length;

  const showModificationStatuses = isModificationsNotificationsOn && itineraryHasModifications;

  const isViewOnlyTraveler = user?.role === "view_only_traveler";

  const customFields = useCustomFieldsQuery(
    { departmentId: user?.department.id, role: user?.role },
    {
      onError(error) {
        captureMessage("Itinerary: Error loading all custom fields", {
          error,
          requestValue: "custom fields",
        });

        return Modal.error({
          onOk: () => navigate(-1),
          okText: "Return",
          maskClosable: true,
          title: "Something's not right.",
          content: (
            <div>
              <p>There was a problem loading your itinerary. Please try again.</p>
            </div>
          ),
        });
      },
    }
  );

  /** This component makes up the body of the ItineraryContent that we will replace with "internal" modification flows.
   * When a dispatch is made from the ModificationsContext to update ModificationViews (defaults to undefined) we will
   * render the appropriate modification flow instead of the body content.
   */
  return (
    <>
      {!!showStatusModal && <ModificationStatusModal />}

      {!!modificationView ? (
        <Box style={{ minHeight: "100vh", marginBottom: 48 }}>
          <ModificationViews />
        </Box>
      ) : (
        <>
          <ReviewProperty />
          {!!showModificationStatuses && (
            <ModificationStatuses
              modifications={reservation?.modifications}
              propertyName={reservation?.propertyName}
            />
          )}
          <Travelers travelers={reservation?.occupants.flat()} />
          <TripInfo />
          <RoomInfo orgId={user?.business.organizationId} />
          {!isViewOnlyTraveler && (
            <CompanyDetails
              isPreview={isPreview}
              allCustomFields={customFields?.data}
              reservation={currentReservation}
              setReservation={setCurrentReservation}
              openEditCustomFields={!!locationState?.editCustomFields}
              verificationRequired={!!user && user.business.directBillVerificationRequired}
            />
          )}
          <ItineraryRefundPolicy />
          <Location />
          <AdditionalInfo supportPhone={(!!user && user.business.supportPhone) || null} />
          <SummaryOfCharges user={user} billingIssues={billingIssues} />
          {!!reservation.refundBreakdowns?.length && <RefundDetails />}
          <DownloadAppBanner />
          <MarketingBanners />
        </>
      )}
    </>
  );
};

export default ItineraryContentBody;
