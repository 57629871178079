import type { UseQueryOptions } from "react-query";

import { convertToSnake, useApi } from "@hotel-engine/react-query/useApi";
import { endpoints } from "@hotel-engine/react-query/constants";
import { useExtendedQuery } from "@hotel-engine/react-query/useExtendedQuery";
import type { IError } from "@hotel-engine/types/errors";
import type { IWorksiteQueryParams, IWorksiteQueryResponse } from "@hotel-engine/types/worksite";

const useWorksiteQuery = (
  params: IWorksiteQueryParams = {},
  options?: UseQueryOptions<IWorksiteQueryResponse, IError>
) => {
  const get = useApi("get");

  return useExtendedQuery(
    [endpoints.worksites, params],
    () => get<IWorksiteQueryResponse>(endpoints.worksites, convertToSnake(params)),
    options
  );
};

export { useWorksiteQuery };
