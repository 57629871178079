import styled, { css } from "styled-components";
import { Box, type BoxProps } from "@hotelengine/atlas-web";

export const AnimationContainer = styled<React.FC<BoxProps>>(Box)`
  margin: ${({ theme }) => theme.spacing[64]};
  overflow: hidden;
  position: relative;
  margin: ${({ theme }) => `${theme.spacing[4]} 0 0`};

  ${({ theme }) => {
    return css`
      @media screen and (min-width: ${theme.breakpoints.lg}) {
        height: 120px;
        margin: 0;
      }

      .animation {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
      }
    `;
  }}
`;

export const LoadingContainer = styled<React.FC<BoxProps>>(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[12]};
`;
