import { useEffect, useState } from "react";
import type { IUser } from "@hotel-engine/types/user";
import type { ILinkedAuthStrategy } from "@hotel-engine/types/authStrategy";
import { LocalStorageCache } from "@auth0/auth0-react";
import config from "config";
import { routes } from "@hotel-engine/constants";
import type { Auth0ContextInterface, AuthorizationParams } from "@auth0/auth0-react";
import { useGetAccessTokenSilently } from "./getToken";

/**
 *
 * @param token
 * @returns true if token is a json stringified that contains
 * the latestAccountType field
 */
export function isAccessTokenInJson(token: string | null) {
  try {
    const parsed = JSON.parse(token || "");
    return "latestAccountType" in parsed;
  } catch (error) {
    return false;
  }
}

export type AccessTokenStored = {
  business?: string;
  personal?: string;
  unknown?: string;
  latestAccountType: "business" | "personal" | "unknown";
};

export const isAddingNewTokenToExistingJson = (
  currentToken: string | null,
  token?: string,
  userAccountType?: IUser["accountType"]
) => {
  return !!token && !!currentToken && isAccessTokenInJson(currentToken) && !!userAccountType;
};

export const isUpdatingTokenFormatToJSON = (
  currentToken: string | null,
  userAccountType?: IUser["accountType"]
) => {
  return !!currentToken && !isAccessTokenInJson(currentToken) && !!userAccountType;
};

export const isUpdatingAccountTypeFromUnknown = (currentToken: string | null, token?: string) => {
  return (
    !token &&
    !!currentToken &&
    isAccessTokenInJson(currentToken) &&
    (JSON.parse(currentToken) as AccessTokenStored).latestAccountType === "unknown"
  );
};

export const isUpdatingAccountTypeValueInJSON = (currentToken: string | null, token?: string) => {
  return !token && !!currentToken && isAccessTokenInJson(currentToken);
};

/**
 * If there is only one auth strategy and it has an auth0 connection, use it even if shouldUseAuth0 is false.
 * This is because other apps may not be able to handle the auth0 connection yet
 */
export const isSingleStrategyWithAuth0 = (authStrategies: ILinkedAuthStrategy[]) => {
  return authStrategies.length === 1 && !!authStrategies[0].auth0Connection;
};

/**
 * This function is used to determine if the user has an auth0 access token in the local storage cache
 * @returns true if there is an auth0 access token in the cache
 */
export const hasAuth0AccessToken = (audience = config.auth0Audience) => {
  const cache = new LocalStorageCache();
  const keys = cache.allKeys();
  return keys.find((key) => key.includes(audience));
};

/**
 * This function is used to remove all auth0 access tokens from the local storage cache
 */
export const removeAuth0Cache = (audience = config.auth0Audience) => {
  const cache = new LocalStorageCache();
  const keys = cache.allKeys();
  keys.forEach((key) => {
    if (key.includes(audience)) {
      cache.remove(key);
    }
  });
};

export const signUpWithGoogle = async (
  loginWithRedirect: Auth0ContextInterface["loginWithRedirect"],
  referralToken?: string,
  currentUserId?: number
) => {
  const returnTo = encodeURIComponent(`${globalThis.location.origin}${routes.join.finish}`);
  const redirectPath = `${globalThis.location.origin}/?return=${returnTo}`;
  await loginWithRedirect({
    authorizationParams: {
      connection: "Google-OAuth2-Connection",
      redirect_uri: redirectPath,
      prompt: "login",
      intent: "create",
      referralToken,
      currentUserId,
    },
  });
};

/**
 * This react hook is used to verify our auth0 token outside of axios.
 * If our token is invalid, we can either:
 *   a) get a new access token by using a refresh token
 *.  b) redirect the user to the login page to get a new set of access and refresh tokens
 
 * This hook should be used in any hook that requires a bearer token to Nexus or Auth0.
 * @returns loading - true if the token is being verified and false if the token is done being verified
 */
export const useVerifyToken = (authorizationParams: AuthorizationParams) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasValidToken, setHasValidToken] = useState(false);
  const getAccessTokenSilently = useGetAccessTokenSilently();

  useEffect(() => {
    const verifyToken = async () => {
      const token = await getAccessTokenSilently(undefined, {
        authorizationParams,
      });
      if (token) {
        setHasValidToken(true);
      }
      setIsLoading(false);
    };

    void verifyToken();
  }, [authorizationParams, getAccessTokenSilently]);

  return { isLoading, hasValidToken };
};
