import type { BoxProps, ButtonProps } from "@hotelengine/atlas-web";
import { Box, Button } from "@hotelengine/atlas-web";
import styled from "styled-components";

const AirlineLogoContainer = styled<React.FC<BoxProps>>(Box)`
	${({ theme }) => `
		width: 54px;
		height: 36px;
		margin-bottom: ${theme.spacing[4]};
	`}
`;

const AirlineLinkButton = styled<React.FC<ButtonProps>>(Button).attrs({
  variant: "outlined",
  color: "secondary",
  trailingIcon: "arrow-up-right-from-square",
  size: "lg",
})`
	align-items: center;
	display: flex;
	flex: 1;
`;

const Divider = styled.div`
	height: 1px;
	border-width: 1px;
	border-style: dashed;
	border-color: ${({ theme }) => theme.colors.dividerSecondary};
	margin-top: ${({ theme }) => theme.spacing[16]};
	margin-bottom: ${({ theme }) => theme.spacing[24]};
`;

export { AirlineLogoContainer, AirlineLinkButton, Divider };
