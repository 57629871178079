import { useEffect, useMemo, useRef } from "react";
import { stringify } from "qs";

import FocusTrap from "focus-trap-react";
import { useFormikContext } from "formik";
import { useAppSelector } from "store/hooks";

import { Box, Button, IconButton, Typography } from "@hotelengine/atlas-web";
import { useBreakpoint } from "@hotel-engine/hooks";
import type { ISearchFormValues } from "@hotel-engine/types/search";
import { isLocationRecord } from "@hotel-engine/types/locations";
import { ampli } from "ampli";

import { getRoomAndGuestsLabel, stringifyDate } from "../helpers";
import * as Styled from "./styles";
import { routes } from "@hotel-engine/constants";
import { extractLocationId } from "pages/DashboardLegacy/components/Search/helpers";

export const RoomAndGuestsContent = ({ onDone }: { onDone: () => void }) => {
  const { touched, values, setFieldValue } = useFormikContext<ISearchFormValues>();
  const isMobile = useBreakpoint("xl", "max");
  const firstFocusableInputRef = useRef<HTMLButtonElement | null>(null);

  const user = useAppSelector((store) => store.Auth.user);

  useEffect(() => {
    firstFocusableInputRef.current?.focus?.();

    return () => {
      onDone();
    };
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const groupsSearchLink = useMemo(() => {
    // Don't parse if we're not building the link
    if (values.roomCount < 8) return routes.groups.base;
    const tripLocation = values.selectedLocation;
    if (!tripLocation) return routes.groups.base;
    const checkIn = !!touched.checkIn ? stringifyDate(values.checkIn) : undefined;
    const checkOut = !!touched.checkOut ? stringifyDate(values.checkOut) : undefined;
    const location = isLocationRecord(tripLocation) ? tripLocation.description : tripLocation?.name;
    const locationId = extractLocationId(tripLocation.id);
    return (
      routes.groups.base +
      "?" +
      stringify({
        checkIn,
        checkOut,
        location,
        locationId,
      })
    );
  }, [
    touched.checkIn,
    touched.checkOut,
    values.checkIn,
    values.checkOut,
    values.roomCount,
    values.selectedLocation,
  ]);

  const groupBookingRequest = useMemo(
    () => (
      <Styled.GroupBookings aria-live="polite">
        <Typography variant="heading/sm" as="span" color="foregroundPrimary">
          Need more rooms?{" "}
        </Typography>
        <span>
          <Typography
            variant="body/sm"
            as="a"
            href={groupsSearchLink}
            target="groups"
            onClick={() => {
              ampli.clickStartNewSpecialBookingRequest({
                userId: user?.id,
                source: "room-guest-picker",
              });
            }}
            data-testid="groups-link"
          >
            Create a Group Request
          </Typography>
          .
        </span>
      </Styled.GroupBookings>
    ),
    [groupsSearchLink, user?.id]
  );

  const updateFieldValue = (property: "roomCount" | "guestCount", difference: number) => {
    const newValue = values[property] + difference;
    // The minimum amount of guests is equal to the number of rooms
    if (property === "roomCount" && newValue > values.guestCount) {
      setFieldValue("guestCount", newValue);
    }
    // The maximum number of guests is equal to the number of rooms times two
    else if (property === "roomCount" && values.guestCount > newValue * 2) {
      setFieldValue("guestCount", newValue * 2);
    }
    setFieldValue(property, newValue);
  };

  const roomAndGuestsLabel = getRoomAndGuestsLabel({
    rooms: values.roomCount,
    guests: values.guestCount,
  });

  return (
    <FocusTrap
      active={!isMobile}
      focusTrapOptions={{
        fallbackFocus: firstFocusableInputRef.current ?? "input:first-of-type",
      }}
    >
      <Styled.ElevatedContainer aria-label="Modify rooms and guests">
        <Styled.ScreenReadyOnly aria-live="polite">{roomAndGuestsLabel}</Styled.ScreenReadyOnly>
        <Styled.Row>
          <Typography variant="body/sm" color="foregroundPrimary">
            Rooms
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={{ width: "100px" }}
          >
            <IconButton
              aria-label="Subtract Room"
              color="primary"
              data-testid="subtract-room"
              isDisabled={values.roomCount === 1}
              icon="minus"
              onClick={() => updateFieldValue("roomCount", -1)}
              ref={(elm) => {
                values.roomCount > 1 && values.roomCount < 8
                  ? // eslint-disable-next-line
                    // @ts-ignore
                    (firstFocusableInputRef.current = elm?.buttonNode)
                  : null;
              }}
              size="sm"
              type="button"
              variant="outlined"
            />
            <Typography
              aria-hidden={true}
              color="foregroundPrimary"
              data-testid="rooms-count"
              variant="body/sm-strong"
            >
              {values.roomCount}
            </Typography>
            <IconButton
              aria-label="Add Room"
              color="primary"
              data-testid="add-room"
              isDisabled={values.roomCount === 8}
              icon="plus"
              onClick={() => updateFieldValue("roomCount", 1)}
              ref={(elm) =>
                values.roomCount <= 1 || values.roomCount >= 8
                  ? // eslint-disable-next-line
                    // @ts-ignore
                    (firstFocusableInputRef.current = elm?.buttonNode)
                  : null
              }
              size="sm"
              type="button"
              variant="outlined"
            />
          </Box>
        </Styled.Row>
        <Styled.Row>
          <Typography variant="body/sm" color="foregroundPrimary">
            Adults
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={{ width: "100px" }}
          >
            <IconButton
              aria-label="Subtract Guest"
              color="primary"
              data-testid="subtract-guest"
              isDisabled={values.guestCount === values.roomCount}
              icon="minus"
              onClick={() => updateFieldValue("guestCount", -1)}
              size="sm"
              type="button"
              variant="outlined"
            />
            <Typography
              aria-hidden={true}
              color="foregroundPrimary"
              data-testid="guests-count"
              variant="body/sm-strong"
            >
              {values.guestCount}
            </Typography>
            <IconButton
              aria-label="Add Guest"
              color="primary"
              data-testid="add-guest"
              isDisabled={values.guestCount === values.roomCount * 2}
              icon="plus"
              onClick={() => updateFieldValue("guestCount", 1)}
              size="sm"
              type="button"
              variant="outlined"
            />
          </Box>
        </Styled.Row>
        {isMobile || (
          <Button variant="outlined" onClick={onDone} data-testid="guest-picker-done">
            Done
          </Button>
        )}
        {values.roomCount === 8 && groupBookingRequest}
      </Styled.ElevatedContainer>
    </FocusTrap>
  );
};
