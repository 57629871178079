import { useState } from "react";

import config from "config";

import { FlexProSignUpModal } from "./components/FlexProSignUpModal";
import * as Styled from "./styles";

import { routes } from "@hotel-engine/constants";
import { Link } from "@hotel-engine/lib/react-router-dom";
import { useFlexProPricingQuery } from "@hotel-engine/react-query/flexPro/useFlexProPricingQuery";
import { formatCurrency } from "@hotel-engine/utilities";
import { internalLinkNewTabTarget } from "@hotel-engine/utilities/helpers/navigationHelpers";
import { Button, DialogTrigger, IconButton, Typography } from "@hotelengine/atlas-web";
import { ampli } from "ampli";
import moment from "moment";
import { useNotificationVisibility } from "pages/CompanySettings/components/hooks/useNotificationVisibility";
import { useAppSelector } from "store/hooks";
import { useIsFeatureFlagOn } from "../Experiments/FeatureFlag";

type FlexProBannerPageAndSizes =
  | "results-large"
  | "results-small"
  | "checkout-long"
  | "checkout-short"
  | "company-settings"
  | "opt-back-into";

export interface IFlexProBannerProps {
  /** Page and size of banner */
  pageAndSize: FlexProBannerPageAndSizes;
  /** Custom margin (default is "10px 16px") */
  margin?: string;
}

const JoinButton = () => (
  <Button variant="plain" color="secondary" size="sm" id="joinFlexPro">
    <DialogTrigger>
      <Typography variant="link/sm">
        <Typography variant="body/sm-strong">Join FlexPro</Typography>
      </Typography>
    </DialogTrigger>
  </Button>
);

export const FlexProBanner = ({ pageAndSize, margin }: IFlexProBannerProps) => {
  const user = useAppSelector((state) => state.Auth.user);
  const [flexProCtaVisible, setFlexProCtaVisible] = useState(true);
  const canViewFlexProViewTrial = useIsFeatureFlagOn("flexpro_free_trial");
  const currentUserRole = user?.role;
  const currencyCode = user?.business.currencyCode || "USD";
  const { status, updateVisibilityStatus } = useNotificationVisibility("flexProUpsellBanner");
  const flexProPricing = useFlexProPricingQuery();
  const monthlyCost = flexProPricing?.data?.month.discountAmount || 200;
  const bannerExpiryDate = moment().add(7, "days");

  const handleDismissBanner = () => {
    updateVisibilityStatus("dismissed", 0, bannerExpiryDate);
  };

  // banner only visible to admin users
  if (currentUserRole !== "admin") return null;

  if (pageAndSize === "results-large" && status !== "show") return null;
  if (pageAndSize === "company-settings" && !flexProCtaVisible) return null;

  switch (pageAndSize) {
    case "company-settings":
      return (
        <Styled.CSFlexProBanner>
          <Styled.OptInImg alt="Flex" src={`${config.cdnHost}/assets/flex-pro/FlexPro-optin.svg`} />
          <Styled.CSFlexProDetails>
            <Typography as="h2" variant="heading/md" color="foregroundPrimary">
              {canViewFlexProViewTrial
                ? "Get unlimited flexibility, first month on us."
                : "Opt in to ultimate peace of mind"}
            </Typography>
            <Typography as="p" variant="body/sm" color={"foregroundPrimary"}>
              {canViewFlexProViewTrial
                ? `For only ${formatCurrency(
                    monthlyCost,
                    false,
                    currencyCode
                  )} a month cancel or modify any reservation, no questions asked.`
                : "Cancel or shorten any trip, at any time when you subscribe to FlexPro."}
            </Typography>
            <Styled.BtnContainer>
              <FlexProSignUpModal>
                <Button size="sm" id="joinFlexPro">
                  Get Started
                </Button>
              </FlexProSignUpModal>

              <Button onClick={() => setFlexProCtaVisible(false)} size="sm" color="secondary">
                Dismiss
              </Button>
            </Styled.BtnContainer>
          </Styled.CSFlexProDetails>
        </Styled.CSFlexProBanner>
      );

    case "checkout-long":
      return (
        <Styled.FlexProResultsLarge data-testid="flex-pro-banner" $margin={margin}>
          <Styled.Img alt="Flex Pro" src={`${config.cdnHost}/assets/flex-pro/FlexPro-optin.svg`} />
          <Styled.FlexProBannerCopyContainer>
            <Typography as="h1" variant="heading/md" color="foregroundPrimary">
              Opt in to ultimate peace of mind
            </Typography>
            <Typography variant="body/sm" color="foregroundPrimary">
              Cancel or shorten any trip, at any time when you subscribe to FlexPro.{" "}
              <FlexProSignUpModal>
                <JoinButton />
              </FlexProSignUpModal>
            </Typography>
          </Styled.FlexProBannerCopyContainer>
          <IconButton
            onClick={handleDismissBanner}
            aria-label="dismissFlexProBanner"
            id="dismissFlexProModal"
            icon="xmark"
            variant="plain"
            size="sm"
          />
        </Styled.FlexProResultsLarge>
      );

    case "checkout-short":
      return (
        <Styled.FlexProCheckoutShort data-testid="flex-pro-banner" $margin={margin}>
          <Styled.Img
            alt="Flex Pro"
            src={`${config.cdnHost}/assets/flex-pro/flexProShieldCheck.svg`}
          />
          <Styled.FlexProBannerCopyContainer>
            <Typography as="h1" variant="heading/md" color="foregroundPrimary">
              FlexPro has you covered!
            </Typography>
            <Typography variant="body/sm" color="foregroundPrimary">
              Cancel or shorten your trips and receive travel credit for unused nights.{" "}
            </Typography>
            <FlexProSignUpModal>
              <JoinButton />
            </FlexProSignUpModal>
          </Styled.FlexProBannerCopyContainer>
        </Styled.FlexProCheckoutShort>
      );

    case "results-large":
      return (
        <Styled.FlexProResultsLarge data-testid="flex-pro-banner" $margin={margin}>
          <Styled.Img alt="Flex Pro" src={`${config.cdnHost}/assets/flex-pro/FlexPro-optin.svg`} />
          <Styled.FlexProBannerCopyContainer>
            <Typography as="h1" variant="heading/md" color="foregroundPrimary">
              Total flexibility for just{" "}
              {`${formatCurrency(monthlyCost || 200, false, currencyCode)}/month.`}
            </Typography>
            <Typography variant="body/sm" color="foregroundPrimary">
              Cancel or shorten any trip, at any time when you subscribe to FlexPro.{" "}
              <FlexProSignUpModal>
                <JoinButton />
              </FlexProSignUpModal>
            </Typography>
          </Styled.FlexProBannerCopyContainer>
          <IconButton
            onClick={handleDismissBanner}
            aria-label="dismissFlexProBanner"
            id="dismissFlexProModal"
            icon="xmark"
            variant="plain"
            size="sm"
          />
        </Styled.FlexProResultsLarge>
      );

    case "results-small":
      return (
        <Styled.FlexProResultsSmall data-testid="flex-pro-banner" $margin={margin}>
          <Typography as="h1" variant="heading/md" color="foregroundPrimary" margin={0}>
            Cover every trip with
            <Styled.FlexLogo
              alt="Flex Logo"
              src={`${config.cdnHost}/assets/flex-pro/flex-pro-logo.svg`}
            />
          </Typography>
          <Link
            to={routes.flexPassFAQ + "?int=true"}
            target={internalLinkNewTabTarget()}
            onClick={() => {
              ampli.viewFlexProOverviewPage({
                businessId: user?.business.id,
                userId: user?.id,
                previousPage: "Flex Pro Banner CTA",
              });
            }}
          >
            <Typography variant="link/sm" color="brandSecondary">
              Learn More
            </Typography>
          </Link>
        </Styled.FlexProResultsSmall>
      );

    case "opt-back-into":
      return (
        <Styled.OptBackIntoBanner data-testid="flex-pro-banner-opt-back-into">
          <Styled.OptBackIntoContainer>
            <Typography variant="body/lg" color="foregroundPrimary">
              Change your mind?
            </Typography>
            <Typography variant="body/md" color="foregroundPrimary">
              All it takes is the click of a button to re-enable FlexPro for you and your entire
              team!
            </Typography>
          </Styled.OptBackIntoContainer>
          <Styled.OptBackIntoContainer style={{ flexShrink: 0 }}>
            <FlexProSignUpModal>Opt Back Into FlexPro</FlexProSignUpModal>
          </Styled.OptBackIntoContainer>
        </Styled.OptBackIntoBanner>
      );

    default:
      return null;
  }
};
