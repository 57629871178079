import { stringify } from "qs";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";

import { routes } from "@hotel-engine/constants";
import type { ISearchQueryParams } from "@hotel-engine/types/search";
import config from "config";

import * as Styled from "../styles";
import { Box, Button, Typography } from "@hotelengine/atlas-web";

export interface IPropertySoldOutModalProps {
  /** function that handles closing modal */
  handleCancel: () => void;
  /** params used for the search url */
  searchParams: Omit<ISearchQueryParams, "locationId"> | null;
}

const PropertySoldOutModal = ({ handleCancel, searchParams }: IPropertySoldOutModalProps) => {
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={8}>
      <Styled.ModalImage
        src={`${config.cdnHost}/assets/trip-extensions/property-is-full.svg`}
        alt={`property-is-full`}
      />
      <Typography
        variant="heading/lg"
        color="foregroundPrimary"
        data-testid="property-sold-out-modal-header"
      >
        Bummer! This property is full.
      </Typography>
      <Typography variant="body/md">Let us help you find available properties nearby.</Typography>
      <Box marginTop={24} display="flex" flexDirection="column" gap={12} style={{ width: "100%" }}>
        <Button
          onClick={() =>
            navigate({
              pathname: `${routes.search}`,
              search: `?locationId=&${stringify(searchParams)}`,
            })
          }
          data-testid="property-sold-out-search-button"
        >
          Start new trip
        </Button>
        <Button variant="outlined" onClick={handleCancel}>
          Go back
        </Button>
      </Box>
    </Box>
  );
};

export default PropertySoldOutModal;
