import styled from "styled-components";

import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors } from "@hotel-engine/styles";

export const SignUpOptionsStepContainer = styled.div`
  position: relative;
`;

export const TermsText = styled.p`
  ${typographyMixin("xsmall")};
  font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
  color: ${colors.grey[400]};
  margin-top: ${({ theme }) => theme.legacy.space(1)};
`;

export const SmallLink = styled.a`
  ${typographyMixin("body-small")};
  color: ${colors.retroBlue};
`;
