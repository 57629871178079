import { useEffect } from "react";
import { useAppSelector } from "store/hooks";
import { triggerAmplitudeEvent } from "@hotel-engine/scripts/hooks";
import { ModalNavBar } from "../../ModalNavBar";
import * as Styled from "./styles";
import { CreateAccountOptions } from "@hotel-engine/app/Join";

interface ISignUpOptionsStep {
  onClose: () => void;
  goContinueWithEmail: () => void;
}

export const SignUpOptionsStep = ({ onClose, goContinueWithEmail }: ISignUpOptionsStep) => {
  const user = useAppSelector((state) => state.Auth.user);

  useEffect(() => {
    triggerAmplitudeEvent("viewPersonalAccountSignupOption", {
      userId: user?.id,
    });
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Styled.SignUpOptionsStepContainer>
      <ModalNavBar onClose={onClose} />
      <CreateAccountOptions
        goContinueWithEmail={goContinueWithEmail}
        currentUserId={user?.id}
        showPolicyText
        svgSrc="/assets/personal-travel/images/lady.svg"
        title="Create Your Personal Account"
      />
    </Styled.SignUpOptionsStepContainer>
  );
};
