import { useEffect, useState } from "react";

import type { SeverityLevel } from "@sentry/react";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";

import { useBreakpoint } from "@hotel-engine/hooks";
import { usePersonalAccountUserMutation } from "@hotel-engine/react-query/users/usePersonalAccountMutation";
import { triggerAmplitudeEvent } from "@hotel-engine/scripts/hooks";
import { captureMessage, emailRegEx, passwordRegEx } from "@hotel-engine/utilities";
import { useAppSelector } from "store/hooks";

import { showErrorModal } from "../../ErrorModal";
import { ModalNavBar } from "../../ModalNavBar";
import { PolicyText } from "../../PolicyText";
import * as Styled from "./styles";

type IPersonalUserFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
};

const UserFormDataSchema = yup.object().shape({
  firstName: yup.string().required("First name required"),
  lastName: yup.string().required("Last name required"),
  email: yup
    .string()
    .matches(emailRegEx, {
      message: "Please enter a valid email address",
    })
    .required("Please enter a valid email address"),
  password: yup
    .string()
    .matches(passwordRegEx, {
      message: "Please enter a valid password",
    })
    .required("Please enter a valid password")
    .when(["email"], ([email], schema) => {
      return schema.test({
        test: (password) => {
          return email && password && !password.includes(email.split("@")[0]);
        },
        message: "Password must not contain email",
      });
    }),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Please confirm password"),
});

interface ISignUpWithEmailStep {
  onClose: () => void;
  onGoBack: () => void;
  goConfirmEmailMessage: (email: string) => void;
}

export const SignUpWithEmailStep = ({
  onClose,
  onGoBack,
  goConfirmEmailMessage,
}: ISignUpWithEmailStep) => {
  const user = useAppSelector((state) => state.Auth.user);
  const isDesktop = useBreakpoint("xl");
  const personalAccountMutation = usePersonalAccountUserMutation();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    triggerAmplitudeEvent("viewPersonalAccountSignup", { userId: user?.id });
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values, showError) => {
    setLoading(true);
    personalAccountMutation.mutate(
      {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        passwordConfirmation: values.passwordConfirmation,
      },
      {
        onSuccess: () => goConfirmEmailMessage(values.email),
        onError: (error) => {
          const isInternalError = (error?.response?.status ?? 500) >= 500;
          const errorReason = error?.response?.data?.invalidParams?.[0]?.reasons?.[0];
          const errorDetails = !!errorReason ? ` - ${errorReason}` : "";
          let errorSeverity = "critical" as SeverityLevel;

          if (!isInternalError && errorReason) {
            showError(errorReason);

            errorSeverity = "info";
          } else {
            showErrorModal();
          }

          captureMessage(
            `Error sign up personal account user${errorDetails}`,
            { error },
            errorSeverity
          );
        },
        onSettled: () => setLoading(false),
      }
    );
  };

  return (
    <div>
      <ModalNavBar onClose={onClose} onGoBack={onGoBack} />
      <Styled.Title>Create Your Personal Account</Styled.Title>
      <Formik
        initialValues={
          {
            email: "",
            password: "",
            passwordConfirmation: "",
            firstName: user?.firstName,
            lastName: user?.lastName,
          } as IPersonalUserFormValues
        }
        onSubmit={(e, { setFieldError }) => onSubmit(e, (msg) => setFieldError("email", msg))}
        validationSchema={UserFormDataSchema}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <Styled.VerticalFlowContainer>
                <Styled.FullNamesWrapper isDesktop={isDesktop}>
                  <Styled.FirstName isDesktop={isDesktop}>
                    <Styled.FieldText htmlFor={"firstName"}>First Name</Styled.FieldText>
                    <Field
                      component={Styled.InputBox}
                      size="default"
                      id="firstName"
                      name="firstName"
                      allowClear
                    />
                  </Styled.FirstName>
                  <Styled.LastName isDesktop={isDesktop}>
                    <Styled.FieldText htmlFor={"lastName"}>Last Name</Styled.FieldText>
                    <Field
                      component={Styled.InputBox}
                      size="default"
                      id="lastName"
                      name="lastName"
                      value={values.lastName}
                      onChange={(ev) => {
                        setFieldValue("lastName", ev.target.value);
                      }}
                      allowClear
                    />
                  </Styled.LastName>
                </Styled.FullNamesWrapper>
                <Styled.FieldWrapper>
                  <Styled.FieldText htmlFor={"email"}>Personal Email Address</Styled.FieldText>
                  <Field
                    autocomplete="username"
                    component={Styled.InputBox}
                    size="default"
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={(ev) => {
                      setFieldValue("email", ev.target.value);
                    }}
                    allowClear
                  />
                </Styled.FieldWrapper>
                <Styled.FieldWrapper>
                  <Styled.FieldText htmlFor="password">Password</Styled.FieldText>
                  <Field
                    autocomplete="new-password"
                    component={Styled.InputBox}
                    size="default"
                    id="password"
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={(ev) => {
                      setFieldValue("password", ev.target.value);
                    }}
                    allowClear
                  />
                </Styled.FieldWrapper>
                <Styled.FieldWrapper>
                  <Styled.FieldText htmlFor="passwordConfirmation">
                    Confirm Password
                  </Styled.FieldText>
                  <Field
                    component={Styled.InputBox}
                    size="default"
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    type="password"
                    data-testid="account-password-confirm"
                    value={values.passwordConfirmation}
                    onChange={(ev) => {
                      setFieldValue("passwordConfirmation", ev.target.value);
                    }}
                    allowClear
                  />
                </Styled.FieldWrapper>
                <Styled.PasswordRequirementText>
                  Password must be at least 8 characters long containing one uppercase letter, one
                  lowercase letter, and one number.
                </Styled.PasswordRequirementText>
                <Styled.SubmitButton htmlType="submit" type={"primary"}>
                  {!!loading && <Styled.SignUpStepLoader />}
                  Create Personal Account
                </Styled.SubmitButton>
              </Styled.VerticalFlowContainer>
            </Form>
          );
        }}
      </Formik>
      <PolicyText />
    </div>
  );
};
