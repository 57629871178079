import type { IFlightItinerary } from "@hotel-engine/types/itinerary";

import FlightMarkers from "../Markers/FlightMarkers";
import useGoogleFlightLocations from "./useGoogleFlightLocations";
import { SPECIFIC_PROPERTY_ZOOM } from "../../constants";
import * as Styled from "./styles";
import GoogleMap from "../..";

interface IFlightMapProps {
  itinerary: IFlightItinerary;
  isMobile?: boolean;
}

const GoogleFlightMap = ({ itinerary, isMobile }: IFlightMapProps) => {
  const originCode = itinerary.slices[0].segments[0].origin.airportCode;
  const destinationCode =
    itinerary.slices[0].segments[itinerary.slices[0].segments.length - 1].destination.airportCode;

  const {
    locations: { origin, destination },
    originLocation,
    destinationLocation,
    isError,
    isLoading,
  } = useGoogleFlightLocations(originCode, destinationCode);

  if (!origin || !destination) {
    return null;
  }

  const originLabel = !!originLocation ? `${originLocation} - ${originCode}` : originCode;
  const destinationLabel = !!destinationLocation
    ? `${destinationLocation} - ${destinationCode}`
    : destinationCode;

  const Container = ({ children }) => {
    if (isMobile) {
      return <Styled.MobileWrapper>{children}</Styled.MobileWrapper>;
    } else {
      return children;
    }
  };

  return (
    <Container>
      {!!isLoading && !isError ? (
        <Styled.Spinner size="large" />
      ) : (
        <GoogleMap
          dataTestId="itinerary-google-map"
          mapLocation="itinerary"
          mapOptions={{
            center: origin,
            zoom: SPECIFIC_PROPERTY_ZOOM,
            // This allows us to zoom out enough to show any full flight path across the world
            minZoom: 2,
          }}
          controlOptions={{
            showExpand: !isMobile,
            showZoom: !isMobile,
            showType: !isMobile,
          }}
        >
          {(map) => (
            <FlightMarkers
              map={map}
              origin={origin}
              originInfo={isMobile ? originCode : originLabel}
              destination={destination}
              destinationInfo={isMobile ? destinationCode : destinationLabel}
              isMobile={isMobile}
            />
          )}
        </GoogleMap>
      )}
    </Container>
  );
};

export default GoogleFlightMap;
