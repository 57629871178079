import type { BoxProps, ButtonProps, IconProps } from "@hotelengine/atlas-web";
import { Box, Button, Icon } from "@hotelengine/atlas-web";
import styled from "styled-components";

export const CollapsibleContainer = styled<React.FC<BoxProps>>(Box)`
  ${({ theme, $hasBottomMargin, $isMobile }) => `
    background-color: ${
      $isMobile ? theme.colors.backgroundSecondary : theme.colors.backgroundPrimary
    };

    border: solid ${theme.borderWidth.normal} ${theme.colors.borderMinimal};
    border-radius: ${theme.borderRadius.lg};

    overflow: hidden;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    margin-bottom: ${$hasBottomMargin ? theme.spacing[32] : 0};
  `}
`;

export const CollapsibleToggleBtn = styled<
  React.FC<ButtonProps & { $small?: boolean; $isMobile?: boolean }>
>(Button).attrs(() => ({
  color: "everlight",
}))`
  ${({ theme, $small }) => `
    display: inline-block;

    width: 100%;

    padding: ${$small ? theme.spacing[16] : theme.spacing[24]};
    padding-right: ${theme.spacing[24]};

    border-radius: 0 !important;

    > span {
      width: 100%;
    }

    &:hover {
        background-color: ${theme.colors.backgroundPrimaryHover} !important;
    }
  `}
  ${({ theme, $isMobile }) =>
    $isMobile
      ? `
      &:hover {
          background-color: ${theme.colors.backgroundSecondary} !important;
      }

      background-color: ${theme.colors.backgroundSecondary};
    `
      : ""}
`;

export const CollapsibleToggleIcon = styled<React.FC<IconProps & { $isOpen: boolean }>>(Icon).attrs(
  () => ({
    name: "chevron-down",
  })
)`
  transform: ${(props) => (props.$isOpen ? "rotate(180deg);" : "rotate(0deg);")};
`;
