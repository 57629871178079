import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import { useState } from "react";
import { ampli } from "ampli";

import { useAppSelector } from "store/hooks";

import { routes } from "@hotel-engine/constants";
import { usePostCheckoutActions } from "@hotel-engine/contexts/PostCheckoutActionsContext";
import HidePropertyModal from "@hotel-engine/app/modals/HidePropertyModal";
import UnhidePropertyModal from "@hotel-engine/app/modals/UnhidePropertyModal";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import { Reservation } from "@hotel-engine/services";

import type { IButtonListItem } from "../ActionButtonLayout";
import ActionButtonLayout from "../ActionButtonLayout";
import { usePastCancelledActions } from "./usePastCancelledActions";
import isStateOfTexasUser from "@hotel-engine//utilities/helpers/isStateOfTexasUser";

export type IItineraryActionsProps = {
  itinerary: IReservationBase;
};

const PastCancelledActions = ({ itinerary }: IItineraryActionsProps) => {
  const navigate = useNavigate();
  const { dispatch: dispatchPostCheckoutActions } = usePostCheckoutActions();
  const [showHidePropertyModal, setShowHidePropertyModal] = useState(false);
  const [showUnhidePropertyModal, setShowUnhidePropertyModal] = useState(false);
  const property = useAppSelector((state) => state.Property.property);
  const isPropertyHidden = property?.hiddenPropertyId || false;

  const user = useAppSelector((state) => state.Auth.user);
  const isSot = isStateOfTexasUser(user);

  const {
    showHidePropertyButton,
    showReviewPropertyButton,
    showVOTViewFolio,
    onlyOneItemExists,
    isEligibleToReportCheckoutDate,
  } = usePastCancelledActions(itinerary);

  const handleHidePropertyClick = () => {
    if (isPropertyHidden) {
      setShowUnhidePropertyModal(true);
    } else {
      setShowHidePropertyModal(true);
    }
  };

  const handlePropertyReviewClick = () => {
    dispatchPostCheckoutActions({
      type: "INIT_POST_CHECKOUT_ACTION",
      payload: [
        {
          propertyReviewRequestId: itinerary.activePropertyReviewRequestId,
          contractNumber: itinerary.contractNumber,
        },
      ],
    });
  };

  const handleModifyReservation = () => {
    ampli.clickModifyTripAtCheckout({
      propertyId: Number(itinerary.propertyId),
    });

    navigate(`${routes.modifyReservation}/${itinerary.id}`, {
      state: {
        itinerary,
      },
    });
    ampli.clickModifyThisReservation({
      bookingId: itinerary.id,
    });
  };

  const handleDocument = () => {
    void Reservation.getDocument(itinerary.id, "folio");
  };

  const buttonList: IButtonListItem[] = [
    {
      action: handleModifyReservation,
      dataTestId: "modify-or-cancel",
      displayCriteria: isEligibleToReportCheckoutDate,
      iconName: "calendar-pen",
      text: "Report early checkout",
    },
    {
      action: handlePropertyReviewClick,
      dataTestId: "review-property",
      displayCriteria: showReviewPropertyButton,
      iconName: "star",
      text: "Review property",
    },
    {
      action: handleHidePropertyClick,
      dataTestId: "hide-property",
      displayCriteria: showHidePropertyButton,
      iconName: isPropertyHidden ? "eye" : "eye-slash",
      text: `${isPropertyHidden ? "Unhide" : "Hide"} property`,
    },
    {
      action: handleDocument,
      dataTestId: "action-view-folio",
      displayCriteria: showVOTViewFolio && !isSot,
      iconName: "file-lines",
      text: "View folio",
    },
  ];

  if (!property) {
    return null;
  }

  return (
    <>
      <ActionButtonLayout
        onlyOneItemExists={onlyOneItemExists}
        showFlexMessage={false}
        renderList={buttonList}
      />
      {!!showHidePropertyModal && (
        <HidePropertyModal
          propertyId={property.id}
          onClose={() => setShowHidePropertyModal(false)}
          onHideSuccessful={() => setShowHidePropertyModal(false)}
          scope="user"
        />
      )}
      {!!showUnhidePropertyModal && (
        <UnhidePropertyModal
          hiddenPropertyId={property.hiddenPropertyId}
          onClose={() => setShowUnhidePropertyModal(false)}
          onUnhideSuccessful={() => setShowUnhidePropertyModal(false)}
          propertyId={property.id}
          scope="user"
        />
      )}
    </>
  );
};

export default PastCancelledActions;
