import { TripType } from "@hotelengine/core-booking-web";
import { isValidPhoneNumber } from "libphonenumber-js";
import type { Moment } from "moment";
import moment from "moment";
import * as Yup from "yup";
import type { AnySchema } from "yup";

import { earliestAllowedCheckinDate } from "@hotel-engine/constants";
import { flightLocationSchema } from "@hotel-engine/types/flights/flights.form.types";
import config from "config";

export const passwordRegEx = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}/;

export const password = (text?: string) => typeof text === "string" && !!text.match(passwordRegEx);

export const email = (text?: string) => typeof text === "string" && /^.+@.+\..+$/.test(text);

export const phone = (text?: string) => {
  const pattern = /s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*/;
  const valid = typeof text === "string" && !!text.match(pattern);
  return valid;
};

// Allows all international & US formats, including (),-,+ in the right places, and extensions
// Requires at least 10 characters and no more than 6 identical characters in a row
export const phoneRegEx =
  /^\s*(?:\+?(\d{1,3}))?\s?([-. (]{0,1})(\d{3})([-. )]{0,1})\s?(\d{3})([-. ]{0,1})(\d{4})(?: *x(\d+))?\s*$/;

// Allows only US formats, including (),- in the right places
// Maximum of 10 digits
export const phoneRegExUS = /^\(?([0-9]{3})\)?([-. ]{0,1})([0-9]{3})([-. ]{0,1})([0-9]{4})$/;

export const PhoneNumberSchema = (message: string) =>
  Yup.object().shape({
    phone: Yup.string()
      .matches(phoneRegEx, { message })
      .required("Please enter a valid phone number"),
  });

export const IntlPhoneNumberSchema = () =>
  Yup.object().shape({
    phone: Yup.string()
      .test("invalidPhoneNumber", "Please enter a valid phone number", (value) => {
        if (!value) return false;
        return isValidPhoneNumber(value);
      })
      .required("Please enter a valid phone number"),
  });

// This RegEx matches the backend validation, but allows uppercase characters
export const emailRegEx =
  // eslint-disable-next-line no-useless-escape
  /^([-a-z0-9!\#$%&'*+\/=?^_`{|}~]+\.)*[-a-z0-9!\#$%&'*+\/=?^_`{|}~]+@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
export const strictEmail = (text?: string) => typeof text === "string" && emailRegEx.test(text);
export const GuestSelectSchema = Yup.object().shape({
  email: Yup.string().matches(emailRegEx, { message: "Invalid email" }).required("Required"),
  name: Yup.string()
    .matches(/(\w.+\s).+/, {
      message: "First and last name required",
      excludeEmptyString: true,
    })
    .required("Guest name required"),
  phone: Yup.string().matches(phoneRegEx, { message: "Invalid phone number" }).required("Required"),
});

export const SearchFormSchema = {
  shape: {
    selectedLocation: Yup.object()
      .shape({
        description: Yup.string().required(),
        latitude: Yup.mixed().test(
          "latitude",
          "Latitude must be a number or string",
          (latitude) => {
            // Can be either number or string according to existing interfaces
            // Will update as the requirements become more clear
            return (
              (typeof latitude === "string" && latitude.length > 0) || typeof latitude === "number"
            );
          }
        ),
        longitude: Yup.mixed().test(
          "longitude",
          "Longitude must be a number or string",
          (longitude) => {
            return (
              (typeof longitude === "string" && longitude.length > 0) ||
              typeof longitude === "number"
            );
          }
        ),
        types: Yup.array().of(Yup.string()),
      })
      .required(),
    checkIn: Yup.object<Record<keyof Moment, AnySchema>>()
      .required()
      .test("checkIn", "Check In date must not be earlier than today", (checkIn, values) => {
        const superLateCheckinsEnabled = values.parent.superLateCheckinsEnabled;
        // Allow users to select a super late check-in if behind the feature flag, otherwise defaults to same date or later
        const earliestCheckinDate = superLateCheckinsEnabled
          ? earliestAllowedCheckinDate
          : moment();
        return (checkIn as Moment)?.isSameOrAfter(earliestCheckinDate, "day");
      })
      .test("checkIn", "Dates must not exceed the max range", (checkIn) => {
        return (
          (checkIn &&
            (checkIn as Moment)?.isSameOrBefore(
              moment().add(config.maxSearchRange, "days"),
              "day"
            )) ||
          new Yup.ValidationError("Dates must not exceed the max range", null, "checkIn")
        );
      }),
    checkOut: Yup.object<Record<keyof Moment, AnySchema>>().required(),
    roomCount: Yup.number().required().min(1).max(8),
    guestCount: Yup.number().required().min(1),
  },
  rules: {
    checkInBeforeCheckout: () => ({
      name: "checkOut",
      message: "Check In date must precede Check Out date",
      test: (values) => {
        return (
          (values.checkIn as Moment)?.isBefore(values.checkOut) ||
          new Yup.ValidationError("Check In date must precede Check Out date", null, "checkOut")
        );
      },
    }),
    maxNights: (maxNights: number) => ({
      name: "checkOut",
      message: "Selected dates must not exceed the maximum number of nights",
      test: (values) => {
        return (
          (values.checkIn &&
            values.checkOut &&
            values.checkOut.diff(values.checkIn, "days") <= maxNights) ||
          new Yup.ValidationError(
            "Selected dates must not exceed the maximum number of nights",
            null,
            "checkOut"
          )
        );
      },
    }),
    guestCountCantBeMoreThanRooms: () => ({
      name: "guestCount",
      message: "Guest Count must at least be equal to the number of rooms",
      test: (values) => {
        return (
          values.guestCount >= values.roomCount ||
          new Yup.ValidationError(
            "Guest Count must at least be equal to the number of rooms",
            null,
            "guestCount"
          )
        );
      },
    }),
    guestCountCantBeMoreThanTwiceTheRoomCount: () => ({
      name: "guestCount",
      message: "Guest Count at most must be equal to twice the number of rooms",
      test: (values) => {
        return (
          values.guestCount <= values.roomCount * 2 ||
          new Yup.ValidationError(
            "Guest Count at most must be equal to twice the number of rooms",
            null,
            "guestCount"
          )
        );
      },
    }),
  },
};

const SliceCriteria = Yup.object().shape({
  origin: flightLocationSchema.required("Departure location is required"),
  departureDate: Yup.object<Record<keyof Moment, AnySchema>>()
    .required("Date is required")
    .test(
      "departureDate in the future",
      "Departure date must not be earlier than today",
      (departureDate) => {
        const earliestDepartureDate = moment();
        return (departureDate as Moment).isSameOrAfter(earliestDepartureDate, "day");
      }
    ),
  destination: flightLocationSchema.required("Arrival location is required"),
});

/**
 * Schema for flight search form
 */
export const FlightSearchFormSchema = {
  shape: {
    tripType: Yup.string()
      .oneOf([TripType.roundTrip, TripType.oneWay, TripType.multiCity])
      .required(),
    slicesCriteria: Yup.array()
      .of(
        SliceCriteria.test(
          "different locations",
          "Departure and arrival locations must be different",
          (sliceCriteria, { path }) => {
            /**
             * This rule is impacted by onSelectedItemChange method in useFlightLocationAutocomplete hook.
             * It can prevent a location from being selected.
             */
            const { origin, destination } = sliceCriteria;

            const isOriginDifferentFromDestination =
              origin?.iataCode !== destination?.iataCode || origin?.type !== destination?.type;

            return (
              isOriginDifferentFromDestination ||
              new Yup.ValidationError(
                ["origin", "destination"].map(
                  (propertyName) =>
                    new Yup.ValidationError(
                      "Departure and arrival locations must be different",
                      null,
                      `${path}.${propertyName}`
                    )
                )
              )
            );
          }
        )
      )
      .test(
        "sequential departure dates",
        "Date must be later than previous flight",
        (slicesCriteria = [], { path }) => {
          const invalidDates = slicesCriteria.reduce((accInvalidDates, currentSlice, index) => {
            if (index === 0) return accInvalidDates;

            const previousSlice = slicesCriteria[index - 1];
            const isValid = (previousSlice.departureDate as Moment).isSameOrBefore(
              currentSlice.departureDate as Moment
            );

            if (!isValid) accInvalidDates.push(index);

            return accInvalidDates;
          }, [] as number[]);

          return (
            !invalidDates.length ||
            new Yup.ValidationError(
              invalidDates.map(
                (sliceNumber) =>
                  new Yup.ValidationError(
                    `Date must be later than previous flight`,
                    null,
                    `${path}[${sliceNumber}].departureDate`
                  )
              )
            )
          );
        }
      ),
    passengers: Yup.object()
      .shape({
        adult: Yup.number().required().min(0),
        child: Yup.object().shape({
          count: Yup.number().required().min(0),
          childrenAges: Yup.array()
            .of(Yup.number().required().min(0))
            .test("childrenAges", "Age is required", (childAges) =>
              childAges?.every((age) => age !== null)
            ),
        }),
        infant: Yup.number()
          .required()
          .min(0)
          .test("infant", "Number of infants cannot exceed number of adults", function (value) {
            const adultCount = this.parent.adult;
            return value <= adultCount;
          }),
      })
      .test(
        "passengers count",
        "Travelers count must be greater than 0 and less than 10",
        (passengers, { path }) => {
          const passengersCount = passengers.adult + passengers.child.count + passengers.infant;
          return (
            (passengersCount <= 9 && passengersCount >= 1) ||
            new Yup.ValidationError(
              "Travelers count must be greater than 0 and less than 10",
              null,
              path
            )
          );
        }
      ),
  },
};

/**
 * Schema for cars search form
 */
export const CarsSearchFormSchema = {
  shape: {
    age: Yup.number().required("Driver's age required"),
    pickupLocationId: Yup.string().required("Pick-up location required"),
    pickupLocationName: Yup.string().required("Pick-up location required"),
    pickupDate: Yup.object<Record<keyof Moment, AnySchema>>().required("Pick-up date required"),
    pickupTime: Yup.object<Record<keyof Moment, AnySchema>>().required("Pick-up time required"),
    dropoffLocationId: Yup.string().required("Drop-off location required"),
    dropoffLocationName: Yup.string().required("Drop-off location required"),
    dropoffDate: Yup.object<Record<keyof Moment, AnySchema>>().required("Drop-off date required"),
    dropoffTime: Yup.object<Record<keyof Moment, AnySchema>>().required("Drop-off time required"),
  },
  rules: {
    pickupValidLocation: () => ({
      test: (values) =>
        !!values.pickupLocationId ||
        new Yup.ValidationError("Valid pick-up location required.", null, "pickupLocationId"),
    }),
    dropoffValidLocation: () => ({
      test: (values) =>
        !!values.dropoffLocationId ||
        new Yup.ValidationError("Valid drop-off location required", null, "dropoffLocationId"),
    }),
    pickupDateTimeMin: () => ({
      test: (values) => {
        if (!values.pickupTime) {
          return false;
        }

        const pickupDateTime = values.pickupDate?.set({
          hour: values.pickupTime.hour(),
          minute: values.pickupTime.minute(),
        });

        return (
          pickupDateTime?.isSameOrAfter(moment().add({ hour: 1 })) ||
          new Yup.ValidationError(
            "Pick-up time must be at least 1 hour from now.",
            null,
            "pickupTime"
          )
        );
      },
    }),
    dropoffBeforePickup: () => ({
      test: (values) => {
        if (!values.pickupTime || values.dropoffDate) {
          return false;
        }

        const pickupDateTime = values.pickupDate?.set({
          hour: values.pickupTime.hour(),
          minute: values.pickupTime.minute(),
        });
        const dropoffDateTime = values.dropoffDate?.set({
          hour: values.dropoffTime.hour(),
          minute: values.dropoffTime.minute(),
        });

        return (
          pickupDateTime?.isSameOrBefore(dropoffDateTime) ||
          new Yup.ValidationError("Drop-off time must be after pick-up.", null, "dropoffDate")
        );
      },
    }),
    validAge: () => ({
      test: ({ age }) => {
        return age >= 19 || new Yup.ValidationError("Driver must be 19 or older.", null, "age");
      },
    }),
  },
};
