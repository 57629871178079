import moment from "moment";
import { Formik } from "formik";

import type { IEmailPillValue } from "@hotel-engine/app/BatchEmailInput";
import { notification } from "@hotel-engine/common/Notifications";
import Reservation from "@hotel-engine/services/Reservation";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import { captureMessage, formatDate } from "@hotel-engine/utilities";
import type { ICarItinerary, IFlightItinerary } from "@hotel-engine/types/itinerary";
import { isCar, isFlight, isLodging } from "@hotel-engine/app/ItineraryContent/shared/isLodging";
import { useShareItineraryMutation } from "@hotel-engine/react-query/shareItinerary";
import { getFullQueryAddress } from "@hotel-engine/app/GoogleMap/components/GoogleCarMap/helpers";

import { useAppSelector } from "store/hooks";
import { useAirportInfo } from "pages/Flights/hooks/useAirportInfo";
import { useCityInfo } from "pages/Flights/hooks/useCityInfo";

import { MAX_EMAILS, SendItineraryModalSchema } from "./helpers";
import * as Styled from "./styles";
import BatchEmailAutoComplete from "../../BatchEmailAutoCompleteInput";
import { saveEmailsToLocalStorage } from "../../BatchEmailAutoCompleteInput/helpers";
import { BATCH_EMAIL_LOCAL_STORAGE_KEY } from "../../BatchEmailAutoCompleteInput/constants";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  TextArea,
  TextInput,
  Typography,
} from "@hotelengine/atlas-web";

export interface ISendItineraryModalProps {
  /** Contract object used to set subject */
  contract: IReservationBase | IFlightItinerary | ICarItinerary;
  /** callback on parent to handle cancelling/closing the modal  */
  onCancel: () => void;
  /** is the modal visible */
  visible: boolean;
}

interface IFormValues {
  email: string;
  additionalEmails?: string[];
  emails: IEmailPillValue[];
  note: string;
  hideRates: boolean;
  subject: string;
}

const SendItineraryModal = ({ contract, onCancel, visible }: ISendItineraryModalProps) => {
  const { getIataCityCodeByIataAirportCode } = useAirportInfo();
  const { getCityNameByIataCityCode } = useCityInfo();
  const preferredDateFormat =
    useAppSelector((state) => state.Auth.user?.business.preferredDateFormat) || "mdy";

  const getCityName = (airportCode: string) => {
    const cityCode = getIataCityCodeByIataAirportCode(airportCode);
    const cityName = getCityNameByIataCityCode(cityCode);
    return cityName;
  };

  const getFlightDate = (originTime: string) => {
    return moment(originTime).format("M/D/YY");
  };

  const subject = isLodging(contract)
    ? `Your Itinerary for ${[contract.propertyName, contract.propertyCity, contract.propertyState]
        .filter(Boolean)
        .join(", ")}`
    : isFlight(contract)
      ? `Confirmation for your flight to ${getCityName(
          contract.slices[0].segments[contract.slices[0].segments.length - 1].destination
            .airportCode
        )} on ${getFlightDate(contract.slices[0].segments[0].origin.flightTime)}`
      : isCar(contract)
        ? `Your rental car reservation is confirmed! Pick-up at ${getFullQueryAddress(
            contract.location.pickUp
          )}, ${formatDate(
            moment.parseZone(contract.startTime),
            "ddd, MMM D, YYYY",
            preferredDateFormat
          )}`
        : "";

  const shareItinerary = useShareItineraryMutation({
    booking_number: (contract as IFlightItinerary).bookingNumber,
  });

  const user = useAppSelector((state) => state.Auth.user);
  const initialValues: IFormValues = {
    emails: [],
    email: "",
    additionalEmails: [],
    note: "",
    hideRates: false,
    subject,
  };

  const handleSubmitLodging = async (
    newValues: IFormValues,
    additionalEmails: string[],
    stringEmails: string[],
    values: IFormValues,
    resetForm: () => void
  ) => {
    const newContract = new Reservation(contract);

    // remove unnecessary prop
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { emails, ...rest } = newValues;

    try {
      await newContract.email(rest);

      handleSuccess(newValues, additionalEmails, stringEmails, resetForm);
    } catch (error) {
      handleError(error, values, newContract);
    }
  };

  const handleSubmitItinerary = async (
    newValues: IFormValues,
    additionalEmails: string[],
    stringEmails: string[],
    values: IFormValues,
    resetForm: () => void
  ) => {
    try {
      shareItinerary.mutate(
        {
          email: newValues.email,
          subject: newValues.subject,
          note: newValues.note,
          hideRates: newValues.hideRates,
          additionalEmails: newValues.additionalEmails || [],
          bookingPath: isFlight(contract) ? "flightBookings" : "carBookings",
        },
        {
          onSuccess: () => handleSuccess(newValues, additionalEmails, stringEmails, resetForm),
          onError: (error) => {
            handleError(error, values);
          },
        }
      );
    } catch (error) {
      handleError(error, values);
    }
  };

  const handleSuccess = (
    newValues: IFormValues,
    additionalEmails: string[],
    stringEmails: string[],
    resetForm: () => void
  ) => {
    notification.open({
      message: "Success!",
      description: `Itinerary sent to ${newValues.email}${
        0 < additionalEmails.length
          ? ` and ${additionalEmails.length} other email${additionalEmails.length === 1 ? "" : "s"}`
          : ``
      }`,
    });

    if (!!user) {
      saveEmailsToLocalStorage(
        `${BATCH_EMAIL_LOCAL_STORAGE_KEY}-${user.id}`,
        stringEmails.join(",")
      );
    }

    resetForm();
    onCancel();
  };

  const handleError = (error: unknown, values: IFormValues, newContract?: Reservation) => {
    notification.error({
      message:
        "There was a problem sending this itinerary. Please contact support if the problem persists.",
    });

    captureMessage("Send Itinerary Error", {
      error,
      newContract: newContract ?? null,
      values,
    });
  };

  const onSubmit = async (values: IFormValues, { resetForm }) => {
    const stringEmails: string[] = values.emails.map(({ value }) => value);
    const additionalEmails: string[] = [...stringEmails];
    const newSingleEmail = additionalEmails.shift();
    const newValues: IFormValues = {
      ...values,
      email: String(newSingleEmail),
      additionalEmails,
    };

    if (isLodging(contract)) {
      await handleSubmitLodging(newValues, additionalEmails, stringEmails, values, resetForm);
    } else {
      await handleSubmitItinerary(newValues, additionalEmails, stringEmails, values, resetForm);
    }
  };

  return (
    <Styled.SendItineraryModal footer={null} title={null} onCancel={onCancel} visible={visible}>
      <Typography variant="heading/lg" color="foregroundPrimary" as="h2">
        Send itinerary
      </Typography>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={SendItineraryModalSchema}
      >
        {({ isSubmitting, handleSubmit, values, setFieldValue, errors }) => {
          return (
            <Box display="flex" flexDirection="column" gap={24}>
              <BatchEmailAutoComplete
                name="emails"
                values={values}
                label="Email addresses"
                placeholder="Separate by commas or by pressing space"
                setFieldValue={setFieldValue}
                maxEmails={MAX_EMAILS}
                isRefresh
              />
              <FormControl
                label="Email subject"
                isRequired
                status={errors.subject ? "error" : "default"}
                errorText="Please enter subject line for email"
              >
                <TextInput
                  name="subject"
                  data-testid="subject-input"
                  placeholder="Email subject"
                  value={values.subject}
                  onChange={(e) => setFieldValue("subject", e.target.value)}
                />
              </FormControl>
              <FormControl label="Message">
                <TextArea
                  name="note"
                  placeholder="Message (optional)"
                  onChange={(e) => setFieldValue("note", e.target.value)}
                />
              </FormControl>
              {/* Hiding this for cars until the hideRates functionality is fixed for cars on the BE */}
              {!isCar(contract) && (
                <Checkbox
                  name="hideRates"
                  onCheckedChange={(state) => setFieldValue("hideRates", state)}
                >
                  Hide price details
                </Checkbox>
              )}
              <Button
                onClick={() => handleSubmit()}
                id="send"
                color="everdark"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                data-testid="send-itinerary-modal-btn"
                style={{ width: "100%" }}
                type="submit"
              >
                Send
              </Button>
            </Box>
          );
        }}
      </Formik>
    </Styled.SendItineraryModal>
  );
};

export default SendItineraryModal;
