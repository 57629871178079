import { Box, Button, Typography, useTheme } from "@hotelengine/atlas-web";
import { ErrorMessage } from "@hotel-engine/app/ErrorMessage";
import { message } from "@hotel-engine/common/Message";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import { usePropertyQuery } from "@hotel-engine/react-query/property/usePropertyQuery";
import { openNewTab } from "@hotel-engine/utilities/helpers/navigationHelpers";
import { formatPhoneNumber } from "@hotel-engine/utilities";
import { routes } from "@hotel-engine/constants";
import { Unsafe } from "@hotel-engine/data";

import { useModificationsContext } from "../../../ModificationsContext";
import { CollapsibleContent } from "../../../shared/CollapsibleContent";
import GooglePreviewMap from "../GooglePreviewMap";

const Location = () => {
  const { tokens } = useTheme();
  const { reservation } = useModificationsContext();
  const navigate = useNavigate();

  const { data: property } = usePropertyQuery(Number(reservation.propertyId), {
    onError: () => {
      message.error(<ErrorMessage />, 0.5).then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    },
  });

  if (!reservation) return null;

  const displayLocation = [
    reservation.propertyStreet,
    reservation.propertyCity,
    reservation.propertyState,
    reservation.propertyCountry,
    reservation.propertyPostalCode,
  ]
    .filter(Boolean)
    .join(", ");

  const searchLocation = [reservation.propertyName, displayLocation].filter(Boolean).join(", ");

  const handleMapAction = () => {
    const url = `https://www.google.com/maps/dir//${searchLocation}`;
    openNewTab(url, "_blank", navigate);
  };

  const handleCallProperty = () => {
    globalThis.open(`tel:${reservation.propertyPhone.replace(/\D/g, "")}`);
  };

  const handleViewProperty = () => {
    navigate({
      pathname: `${routes.properties}/${reservation.propertyId}`,
    });
  };

  return (
    <CollapsibleContent
      toggleTestID="toggle-location-section"
      toggleBtnLabel={
        <Box display="flex" gap={12} alignItems="center">
          <Typography variant="heading/lg">Location</Typography>
        </Box>
      }
    >
      <Box paddingLeft={24} paddingRight={24} paddingBottom={24} style={{ overflow: "hidden" }}>
        <GooglePreviewMap
          handleView={handleMapAction}
          longitude={Number(reservation.propertyLongitude)}
          latitude={Number(reservation.propertyLatitude)}
          borderRadius={tokens.borderRadius.md}
          walkscorePercentage={property?.walkscore || ""}
          walkscoreDescription={property?.walkscoreDescription || ""}
        />
        <Box display="flex" flexDirection="column" paddingTop={16} gap={16}>
          <Typography variant="body/sm" color="foregroundPrimary">
            {displayLocation}
          </Typography>
          <Typography
            variant="link/sm"
            color="foregroundPrimary"
            onClick={handleCallProperty}
            data-testid="call-property"
            style={{ cursor: "pointer" }}
          >
            {formatPhoneNumber(reservation.propertyPhone, true)}
          </Typography>
          <Button variant="outlined" size="lg" onClick={handleViewProperty}>
            View property
          </Button>
        </Box>
      </Box>
    </CollapsibleContent>
  );
};

export default Location;
