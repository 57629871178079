export enum FiltersActionTypes {
  SET_FILTER = "SET_FILTER",
  SET_FULL_STATE = "SET_FULL_STATE",
  CLEAR_ALL = "CLEAR_ALL",
}

export type FilterKeyValue = {
  key: string;
  value?: boolean | string[];
};

export interface IFilterState {
  refundable: boolean;
  loyaltyEligible: boolean;
  gsaOrLower: boolean;
  clientPrograms: string[];
  bedType: string[];
}
export interface IFilterAction {
  type: FiltersActionTypes;
  payload?: FilterKeyValue;
  fullState?: IFilterState;
}

export const initialFilterState: IFilterState = {
  refundable: false,
  loyaltyEligible: false,
  gsaOrLower: false,
  bedType: [],
  clientPrograms: [],
};

export const keysLabelDict = {
  refundable: "Refundable",
  loyaltyEligible: "Hotel Loyalty eligible",
  gsaOrLower: "GSA or Lower",
};

export default function reducer(state: IFilterState, action: IFilterAction) {
  switch (action.type) {
    case FiltersActionTypes.SET_FILTER:
      if (action.payload) {
        return {
          ...state,
          [action.payload.key]: action.payload.value || null,
        };
      } else {
        throw new Error("Missing Action Payload");
      }
    case FiltersActionTypes.SET_FULL_STATE:
      return action.fullState || state;
    case FiltersActionTypes.CLEAR_ALL:
      return initialFilterState;
    default:
      throw new Error("Invalid Action Type");
  }
}
