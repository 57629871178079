import type { AxiosError, AxiosResponse } from "axios";

export interface IError {
  key: string;
  value: string;
  error: string[];
}
export function isIError(e: unknown): e is IError {
  return (e as IError).error !== undefined && Array.isArray((e as IError).error);
}

interface IErrorMessageResponse {
  error?: { message?: string };
}

interface IErrorResponseBody extends IErrorMessageResponse {
  fieldErrors?: { name: string; reasons: string[] }[];
  error?: { message?: string };
}

export interface IErrorResponse {
  status: number;
  statusText?: string;
  errors: IError[] | IBatchError[];
  response?: {
    data?: IErrorResponseBody;
    response?: IErrorMessageResponse;
  };
  error?: AxiosError;
}

export interface IBulkMemberErrorResponse {
  title: string;
  invalidParams: {
    key: string;
    reasons: string[];
    value: unknown;
  }[];
}
export function isIErrorResponse(e: unknown): e is IErrorResponse {
  return (e as IErrorResponse).errors !== undefined;
}

export function isError(e: unknown): e is Error {
  return Boolean((e as Error).message && (e as Error).name);
}

export type IAxiosBatchError = AxiosError<IBatchError>;

export type AxiosErrorResponse = AxiosError<{ errors: string[] }>;
export function isAxiosErrorResponse(e: unknown): e is AxiosErrorResponse {
  return (e as AxiosErrorResponse).response?.data?.errors !== undefined;
}

/** This is how V2 endpoints typically return a single error, this will usually be transformed
 * by our api helpers but can be used to build MSW error types */
export interface IV2Error {
  statusCode: number;
  error: string;
  message: string;
}
export const isIV2Error = (e: unknown): e is IV2Error => {
  return (e as IV2Error)?.message !== undefined;
};

// This is how Auth0 Authentication API endpoints typically return a single error
// Source: https://auth0.com/docs/api/authentication#standard-error-responses
export interface IAuth0ErrorResponseBody {
  error?: string;
  error_description?: string;
}

export const isAuth0Error = (e: unknown): e is IAuth0ErrorResponseBody => {
  return (e as IAuth0ErrorResponseBody)?.error_description !== undefined;
};

export interface IBatchError {
  title: string;
  invalidParams: {
    key: string;
    value: string | null;
    reasons: string | string[];
  }[];
}

export const isIBatchError = (e: unknown): e is IBatchError => {
  return (e as IBatchError).title !== undefined && (e as IBatchError).invalidParams !== undefined;
};

/**
 * defined in he-api in payment_profiles_controller:
  result.failure do |error|
    render json: {
      error: "Bad Request",
      message: "Client error",
      client_messages: error,
    }, status: :unprocessable_entity
  end
 */
export type IPaymentProfileError = {
  error: string;
  message: string;
  clientMessages: string;
};
export const isIPaymentProfileError = (e: unknown): e is IPaymentProfileError => {
  return (e as IPaymentProfileError).clientMessages !== undefined;
};

export interface ISupplyError {
  error: {
    type: string;
    message: string;
    /**
     * this is just any - coordinate with supply or treat with gloves
     */
    data?: AxiosResponse["data"];
    stack?: string | string[];
    issues?: {
      message: string;
      /**
       * zodError{@link https://github.com/colinhacks/zod/blob/master/ERROR_HANDLING.md}
       */
      [key: string]: unknown;
    };
  };
}
export function isSupplyError(e: unknown): e is ISupplyError {
  return (
    (e as ISupplyError)?.error?.message !== undefined &&
    (e as ISupplyError)?.error?.type !== undefined &&
    typeof (e as ISupplyError).error.message === "string"
  );
}

export interface ISupplyInternalServerError {
  detail?: string;
  message: string;
  httpStatusCode: number;
}
export function isSupplyInternalServerError(e: unknown): e is ISupplyInternalServerError {
  return (
    (e as ISupplyInternalServerError)?.httpStatusCode !== undefined &&
    (e as ISupplyInternalServerError)?.message !== undefined
  );
}
