import { Box } from "@hotelengine/atlas-web";
import { MultiAirlineLogo } from "@hotel-engine/assets/flights/MultiAirlineLogo";

import SlicesHeader from "./components/SlicesHeader";

import { useTypographyScaling } from "../../../hooks/useTypographyScaling";
import { useFlightModificationsContext } from "../../../ModificationsContext";
import CopiableConfirmation from "../../../shared/CopiableConfirmation";

const FlightInfo = () => {
  const { isPreview } = useTypographyScaling();
  const { reservation } = useFlightModificationsContext();

  const airlines = reservation?.airlineCodes;

  return (
    <Box
      paddingTop={16}
      paddingBottom={16}
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={12}
    >
      <MultiAirlineLogo airlineCodes={airlines.slice(0, 2)} size={isPreview ? 40 : 48} />
      <SlicesHeader />
      {!!reservation.flightConfirmation && (
        <CopiableConfirmation
          confirmationNumber={reservation.flightConfirmation}
          bookingNumber={reservation.bookingNumber}
        />
      )}
    </Box>
  );
};

export default FlightInfo;
