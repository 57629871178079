import type { UseMutationOptions } from "react-query";
import { useMutation } from "react-query";

import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { IUser } from "@hotel-engine/types/user";

import { endpoints } from "../constants";
import { useApi } from "../useApi";

export interface IPersonalAccountUserEditParams {
  id: number;
  firstName: string;
  lastName: string;
}

interface IUpdateUserOptions {
  accessToken?: string;
}

export const useUpdateUserMutation = (
  params?: IUpdateUserOptions,
  options?: UseMutationOptions<IUser, IErrorResponse, IPersonalAccountUserEditParams>
) => {
  const put = useApi("put");

  return useMutation<IUser, IErrorResponse, IPersonalAccountUserEditParams>(
    (data) =>
      put(`${endpoints.users}/${data.id}`, data, {
        ...(params?.accessToken && {
          headers: {
            Authorization: `Bearer ${params?.accessToken}`,
          },
        }),
      }),
    options
  );
};
