import type { OverlayViewState } from ".";
import type { MapPosition } from "../../types";

const MAP_CARD_BASE_HEIGHT = 300;

export const getPreviewPanelPosition = (
  overlay: OverlayViewState,
  position: MapPosition,
  verticalPanelOffset: number
) => {
  const visibleRegion = overlay?.getProjection()?.getVisibleRegion();

  /** PREVIEW PANEL SIDES -- +/- offsets represent the size of the panel relative to the position lat/lng */
  const panelEast = Number(overlay?.getProjection()?.fromLatLngToDivPixel(position)?.x) + 300;

  const panelWest = Number(overlay?.getProjection()?.fromLatLngToDivPixel(position)?.x) - 50;

  const panelSouth =
    Number(overlay?.getProjection()?.fromLatLngToDivPixel(position)?.y) + MAP_CARD_BASE_HEIGHT;

  const panelNorth = Number(overlay?.getProjection()?.fromLatLngToDivPixel(position)?.y);

  /** MAP BOUNDS -- We don't need to check North since the default positioning of the panel is always below the pin */
  const eastPixelBound =
    !!visibleRegion?.farRight &&
    overlay?.getProjection()?.fromLatLngToDivPixel(visibleRegion?.farRight)?.x;

  const westPixelBound =
    !!visibleRegion?.nearLeft &&
    overlay?.getProjection()?.fromLatLngToDivPixel(visibleRegion?.nearLeft)?.x;

  const southPixelBound =
    !!visibleRegion?.nearLeft &&
    overlay?.getProjection()?.fromLatLngToDivPixel(visibleRegion?.nearLeft)?.y;

  const doAllBoundsExist = !!eastPixelBound && !!westPixelBound && !!southPixelBound;

  const isPanelWithinBounds = () => {
    if (doAllBoundsExist) {
      const isPanelWithinEastBound = panelEast < eastPixelBound;
      const isPanelWithinWestBound = panelWest > westPixelBound;
      const isPanelWithinSouthBound = panelSouth < southPixelBound;

      return {
        eastPixelBound,
        westPixelBound,
        southPixelBound,
        isPanelWithinEastBound,
        isPanelWithinWestBound,
        isPanelWithinSouthBound,
      };
    }
    return;
  };

  const getPanelPosition = () => {
    const panelBounds = isPanelWithinBounds();
    const panelCoords = {
      x: panelWest,
      // We want to push the panel down just a bit for default positioning to have a bit of padding
      y: panelNorth + 8,
    };

    if (!panelBounds) {
      return position;
    } else {
      // If the panel is overflowing the bounds of the map, we need to adjust the position
      // For each bound that is being overflowed, the panel should be hugging but not overflowing the bound
      if (!panelBounds.isPanelWithinEastBound) {
        panelCoords.x = panelBounds.eastPixelBound - 350;
      }
      if (!panelBounds.isPanelWithinWestBound) {
        panelCoords.x = panelBounds.westPixelBound + 8;
      }
      if (!panelBounds.isPanelWithinSouthBound) {
        panelCoords.y = panelBounds.southPixelBound - MAP_CARD_BASE_HEIGHT - verticalPanelOffset;
      }

      const panelPosition = new google.maps.Point(panelCoords.x, panelCoords.y);

      return overlay?.getProjection()?.fromDivPixelToLatLng(panelPosition);
    }
  };

  return getPanelPosition();
};
