import type { ISummaryOfChargesItemProps } from "@hotel-engine/app/ItineraryContent/shared/SummaryOfChargesItem";
import { useFormatDate } from "@hotel-engine/hooks";
import type { ICarSummaryOfCharges } from "@hotel-engine/types/itinerary";
import { formatCurrency } from "@hotel-engine/utilities";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";
import { useMemo } from "react";
import { useAppSelector } from "store/hooks";

export const useCarSummaryOfCharges = (charges?: ICarSummaryOfCharges) => {
  const { COMPANY_ABBREVIATION } = useCompanyName();
  const user = useAppSelector((state) => state.Auth?.user);
  const currencyCode = user?.business?.currencyCode || "USD";
  const paidOn = useFormatDate(charges?.paidOn, "MMM Do, YYYY", true) ?? "";
  const hidePaidOn = !paidOn || paidOn === "Invalid date";

  const items: ISummaryOfChargesItemProps[] = useMemo(
    () =>
      [
        {
          label: "Billing name",
          value: charges?.billingName || "",
        },
        {
          label: "Payment method",
          value: charges?.paymentMethod || "",
        },
        {
          label: "Paid on",
          value: paidOn || "",
          hideItem: hidePaidOn,
        },
        {
          label: "Base rental",
          value: formatCurrency(charges?.baseRental || "0", true, currencyCode),
        },
        {
          label: "Taxes and fees",
          value: formatCurrency(charges?.taxesFees || "0", true, currencyCode),
        },
        {
          label: `${COMPANY_ABBREVIATION} Rewards`,
          value: `-${formatCurrency(charges?.redeemedRewardsPoints || "0", true, currencyCode)}`,
          labelColor: "sentimentPositiveDefault" as ISummaryOfChargesItemProps["labelColor"],
          valueColor: "sentimentPositiveDefault" as ISummaryOfChargesItemProps["valueColor"],
          hideItem: charges?.redeemedRewardsPoints === "0.0",
        },
        {
          label: "Travel credits",
          value: `-${formatCurrency(charges?.redeemedTravelCredits || "0", true, currencyCode)}`,
          labelColor: "sentimentPositiveDefault" as ISummaryOfChargesItemProps["labelColor"],
          valueColor: "sentimentPositiveDefault" as ISummaryOfChargesItemProps["valueColor"],
          hideItem: charges?.redeemedTravelCredits === "0.0",
        },
      ].filter((item) => item.value),
    [
      charges?.billingName,
      charges?.paymentMethod,
      charges?.baseRental,
      charges?.taxesFees,
      charges?.redeemedRewardsPoints,
      charges?.redeemedTravelCredits,
      paidOn,
      hidePaidOn,
      currencyCode,
      COMPANY_ABBREVIATION,
    ]
  );

  return {
    items,
    totalCharge: formatCurrency(charges?.totalCustomerCharge || "0", true, currencyCode),
  };
};
