import type { ITripExtensions } from "@hotel-engine/types/reservation";
import usePreviousTrip from "./usePreviousTrip";
import useSubsequentBookings from "./useSubsequentBookings";

function useTripExtensions(extensions: ITripExtensions) {
  const subsequentBookings = useSubsequentBookings(extensions);
  const previousTrip = usePreviousTrip({
    id: extensions.previousContractNumber as string,
  });

  return {
    previousTrip: previousTrip.data,
    subsequentBookings: subsequentBookings.data,
    isLoading: previousTrip.isLoading || subsequentBookings.isLoading,
  };
}

export default useTripExtensions;
