import { useEffect, useMemo, useState } from "react";

import { ampli } from "ampli";
import { equals, isEmpty } from "ramda";
import { useAppSelector } from "store/hooks";

import { useDebounce } from "@hotel-engine/hooks";

import type { IAction } from "../../../context";
import { useTripsContext } from "../../../context";
import preventPathTraversal from "../helpers/preventPathTraversal";

function createSearchAction(value: string): IAction {
  if (value === "") {
    return {
      type: "filter",
      filters: [{ key: "search" }],
    };
  }
  return {
    type: "filter",
    filters: [{ key: "search", value }],
  };
}

function createResetPageAction(currentPageSize: number): IAction {
  return {
    type: "pagination",
    page: 1,
    pageSize: currentPageSize,
  };
}

function useSearchForTrips(typedText: string) {
  const deferredValue = useDebounce(typedText, 800);
  const user = useAppSelector((state) => state.Auth.user);
  const { state, dispatch } = useTripsContext();
  const currentPageSize = state.limit;
  const [currentQuery, setCurrentQuery] = useState(state.filters.search ?? "");

  const updatedQuery = useMemo(() => {
    const trimmedInput = deferredValue.trim();
    const sanitizedQuery = preventPathTraversal(trimmedInput);

    if (isEmpty(sanitizedQuery)) return "";

    if (user) {
      ampli.editSearchByNameOrLocationInTrips({
        typedText: sanitizedQuery,
        userId: user.id,
      });
    }

    return sanitizedQuery;
  }, [deferredValue, user]);

  useEffect(() => {
    const queryDiffers = !equals(updatedQuery, currentQuery);

    if (queryDiffers) {
      const performSearch = createSearchAction(updatedQuery);
      const performResetPage = createResetPageAction(currentPageSize);

      dispatch(performSearch);
      dispatch(performResetPage);
      setCurrentQuery(updatedQuery);
    }
  }, [updatedQuery, currentQuery, dispatch, currentPageSize]);
}

export { createSearchAction, createResetPageAction };
export default useSearchForTrips;
