import type { ICarItinerary, IFlightItinerary } from "@hotel-engine/types/itinerary";
import type { IReservationBase } from "@hotel-engine/types/reservation";

export const isLodging = (
  itinerary: IReservationBase | IFlightItinerary | ICarItinerary
): itinerary is IReservationBase => !!(itinerary as IReservationBase).propertyId;

export const isFlight = (
  itinerary: IReservationBase | IFlightItinerary | ICarItinerary
): itinerary is IFlightItinerary => !!(itinerary as IFlightItinerary).slices;

export const isCar = (
  itinerary: IReservationBase | IFlightItinerary | ICarItinerary
): itinerary is ICarItinerary => !!(itinerary as ICarItinerary).car;
