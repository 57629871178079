import type { BoxProps } from "@hotelengine/atlas-web";
import { Box } from "@hotelengine/atlas-web";
import styled, { css } from "styled-components";

export const TravelerInfoBlock = styled<React.FC<BoxProps>>(Box)`
  border-top: ${({ theme }) => `dashed ${theme.borderWidth.normal} ${theme.colors.borderMinimal}`};

  padding: ${({ theme }) => `${theme.spacing[16]} ${theme.spacing[24]}`};
`;

export const TravelerInfoBlockContentGrid = styled<React.FC<BoxProps>>(Box)<{
  noRightContent?: boolean;
  isSheet?: boolean;
}>`
  width: 100%;

  ${({ isSheet, theme }) =>
    !!isSheet &&
    css`
    margin-bottom: ${theme.spacing[8]};
    `}

  display: grid;
  grid-template-columns: ${({ noRightContent, isSheet }) =>
    isSheet ? "1fr 1fr" : noRightContent ? "16px 1fr" : "16px 3fr 2fr"};
  grid-template-rows: auto;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[8]};

  // correct for icons aligning
  i {
    margin-top: ${({ theme }) => theme.spacing[2]};
  }

  .right {
    text-align: right;
  }
`;

export const TravelerCollapsibleContainer = styled<React.FC<BoxProps>>(Box)<{ $padTop: boolean }>`
  margin-top: ${({ theme, $padTop }) => theme.spacing[$padTop ? 32 : 0]};
  margin-bottom: ${({ theme }) => theme.spacing[32]};

  .flights-collapsible {
    margin-bottom: 0;
    border-radius: 0;
    border-bottom: none;
  }

  .flights-collapsible:first-of-type {
    border-top-right-radius: ${({ theme }) => theme.borderRadius.lg};
    border-top-left-radius: ${({ theme }) => theme.borderRadius.lg};
  }

  .flights-collapsible:last-of-type {
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.lg};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius.lg};
    border-bottom: ${({ theme }) =>
      `solid ${theme.borderWidth.normal} ${theme.colors.borderMinimal}`};
  }
`;
