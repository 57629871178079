import { useState } from "react";

export interface IWaterline<T> {
  resetWaterline: () => void;
  incrementWaterline: (incr: number) => void;
  validUnderwater: (myEmails: T[]) => T[];
  invalidUnderwater: (myEmails: T[]) => T[];
  invalidOverTheWater: (myEmails: T[]) => T[];
}

/**
 * Uses house flooding terms as an analogy to items in a list as being above water or under water.
 * In email pills context, myEmails is the walls of the house and waterline is the level of water
 * such that anything sticking out can be reduced into the `# More` button.
 * It is an imperfect analogy because being under water is good in that those original 25 emails are valid in terms of email population,
 * and that anything sticking out triggers the "no more than 25 emails" validation message
 * @returns
 */
export function useWaterLine<T>(maxEmails: number) {
  const [waterline, setWaterline] = useState(maxEmails);

  /**
   * will be used by the `# More` button in EmailsField
   * @param myEmails
   * @returns IEmailPillValue[]
   */
  const invalidOverTheWater = (myEmails: T[]) => {
    // inclusive since all 25 emails will be valid in terms of email population
    if (myEmails.length <= waterline) {
      return [];
    }
    return myEmails.slice(waterline, myEmails.length);
  };

  /**
   * invalid pills that should be marked red but are fully defined email pills and will not be reduced to the `# More` button
   * @param myEmails
   * @returns IEmailPillValue[]
   */
  const invalidUnderwater = (myEmails: T[]) => {
    if (myEmails.length < maxEmails) {
      return [];
    }
    return myEmails.slice(maxEmails, waterline);
  };

  /**
   * valid fully defined pills under maxEmails population
   * @param myEmails
   * @returns IEmailPillValue[]
   */
  const validUnderwater = (myEmails: T[]) => {
    if (myEmails.length < maxEmails) {
      return myEmails;
    }
    return myEmails.slice(0, maxEmails);
  };

  // increases height of water line by increment
  const incrementWaterline = (incr: number) => {
    if (incr < 0) {
      throw new Error("No negative numbers allowed. Use resetToMaxEmails instead");
    }
    setWaterline(waterline + incr);
  };

  // resets to 25 emails
  const resetWaterline = () => {
    setWaterline(maxEmails);
  };

  const results: IWaterline<T> = {
    resetWaterline,
    incrementWaterline,
    validUnderwater,
    invalidUnderwater,
    invalidOverTheWater,
  };
  return results;
}
