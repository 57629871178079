import styled from "styled-components";

import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors, ellipsisText } from "@hotel-engine/styles";

export const PrimaryGuest = styled.div`
  ${ellipsisText};
  ${typographyMixin("body-medium")};
`;

export const GuestsLabel = styled(PrimaryGuest)`
  ${typographyMixin("body-small")};
  color: ${colors.newGrey500};
`;

export const PopoverContent = styled.span`
  color: ${colors.newGrey500};
`;
