import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import InputField from "@hotel-engine/common/FormikFields/InputField";
import Loader from "@hotel-engine/common/Loader";
import type { TypographyProps } from "@hotelengine/atlas-web";
import { Typography } from "@hotelengine/atlas-web";

const VerticalFlowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FullNamesWrapper = styled.div<{ isDesktop: boolean; hasError: boolean }>`
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? "row" : "column")};
  width: 100%;
  margin-bottom: ${({ hasError, isDesktop }) => (hasError && isDesktop ? "16px" : "0px")};
`;

const FirstName = styled.div<{ isDesktop: boolean; hasError: boolean }>`
  width: ${({ isDesktop }) => (isDesktop ? `50%` : `100%`)};
  margin-right: ${({ isDesktop, theme }) => (isDesktop ? theme.spacing[12] : `0px`)};
  margin-bottom: ${({ hasError, isDesktop }) => (hasError && !isDesktop ? "16px" : "0px")};
`;

const LastName = styled.div<{ isDesktop: boolean; hasError: boolean }>`
  width: ${({ isDesktop }) => (isDesktop ? `50%` : `100%`)};
  margin-bottom: ${({ hasError, isDesktop }) => (hasError && !isDesktop ? "16px" : "0px")};
`;

const FieldText = styled.label`
  margin: 0px 0px 0px 0px;
  color: ${({ theme }) => theme.colors.foregroundPrimary};
  font-size: ${({ theme }) => theme.typography.desktop["body/md"]};
`;

const InputBox = styled(InputField)<{ invalid: boolean }>`
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  height: 40px;
  .ant-input-clear-icon {
    display: none;
  }
  .has-error .ant-input-clear-icon {
    color: ${({ theme }) => theme.colors.accentRed};
    display: block;
  }

  .has-error .ant-form-explain {
    margin-top: 4px;
  }

  .ant-form-item-control-wrapper {
    margin-top: 4px;
  }
`;

const FieldWrapper = styled.div<{ hasError: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: ${({ hasError }) => (hasError ? "16px" : "0px")};
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;

const SubmitButton = styled(Button)`
  width: 100%;
  height: 48px;
  margin: ${({ theme }) => theme.spacing[12]} 0px 0px 0px;
  color: ${({ theme }) => theme.colors.uiPrimary};
`;

const PasswordRequirementText = styled<React.FC<TypographyProps>>(Typography)`
  color: ${({ theme }) => theme.colors.foregroundTertiary};
  margin-bottom: 20px;
  font-size: 11px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;

const SignUpStepLoader = styled(Loader)`
  color: ${({ theme }) => theme.colors.neutralN00};
  margin-right: ${({ theme }) => theme.spacing[8]};
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  top: 36px;
  right: 20px;
  position: absolute;
  right: 10px;
`;

export {
  VerticalFlowContainer,
  FullNamesWrapper,
  FirstName,
  LastName,
  FieldText,
  InputBox,
  FieldWrapper,
  SubmitButton,
  PasswordRequirementText,
  SignUpStepLoader,
  IconContainer,
};
