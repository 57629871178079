import styled from "styled-components";

import { Modal } from "@hotel-engine/common/Modal";
import { colors } from "@hotel-engine/styles";

export const NuclearErrorModal = styled(Modal)`
  max-width: 438px;
  .ant-modal-content {
    .ant-modal-header {
      border-bottom: none;
    }

    .ant-modal-body {
      padding: 0 32px 32px;
    }
  }
  header {
    display: flex;
    margin-top: ${({ theme }) => theme.legacy.space(2)};
    svg {
      color: ${colors.carrotCurl};
      margin-right: ${({ theme }) => theme.legacy.space(2)};
    }

    h2 {
      font-size: ${({ theme }) => theme.legacy.fontSize.md};
      font-weight: ${({ theme }) => theme.legacy.fontWeight.bold};
      line-height: ${({ theme }) => theme.legacy.lineHeight.sm};
    }
  }
  p {
    padding-left: 30px;
    margin-bottom: ${({ theme }) => theme.legacy.space(2)};
  }

  footer {
    display: flex;
    justify-content: flex-end;
  }
`;
