import styled from "styled-components";

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing[24]};

  > button,
  a {
    margin: 0;
    flex: 1;

    &:hover {
        color: ${({ theme }) => theme.colors.foregroundInverse};
    }
  }
`;
