const generateGuestList = (allOccupants) =>
  allOccupants.map((guest, i) => {
    return (
      <div key={`${guest.firstName}-${i}`}>
        {guest.firstName} {guest.lastName} {i === 0 ? "(Primary)" : ""}
      </div>
    );
  });

const generateGuestNames = (allOccupants, maxNames) => {
  const occupants = maxNames ? [...allOccupants].slice(0, maxNames) : allOccupants;

  return occupants.map((guest) => `${guest.firstName} ${guest.lastName}`).join(", ");
};

export { generateGuestList, generateGuestNames };
