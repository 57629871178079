import styled, { css } from "styled-components";

import { List } from "@hotel-engine/common/Lists";
import Tag from "@hotel-engine/common/Tag";
import { colors, visuallyHidden } from "@hotel-engine/styles";

export const EmailTag = styled(Tag)<{
  $highlight: boolean;
}>`
  background-color: ${colors.grey[50]};
  color: ${colors.grey[700]};
  border-color: ${colors.grey[400]};
  border-radius: 12px /* disable theming (do not replicate) */;
  margin: 4px;
  height: 24px;
  display: flex;
  align-items: center;
  ${({ color }) =>
    color === "invalid-emails" &&
    css`
      border-color: ${colors.red[400]};
      background-color: ${colors.red[50]};
    `};

  ${({ $highlight }) =>
    $highlight &&
    css`
      background-color: ${colors.blue[50]};
      border-color: ${colors.widowMaker};
    `}
`;

export const ListItem = styled.span<{ isHidden?: boolean }>`
  transition: all 0.3s;
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
  ${({ isHidden }) => isHidden && visuallyHidden}
`;

// antd input has too much decorations to turn off. can't turn some of them off.
export const NewEmailInput = styled.input<{ isHidden: boolean }>`
  transition: all 0.3s;
  line-height: ${({ theme }) => theme.legacy.lineHeight.md};
  height: ${({ theme }) => theme.legacy.lineHeight.md};
  border: 0;
  padding: 2px;
  width: 100%;
  align-items: center;
  ::placeholder {
    color: ${colors.kiwiGrey};
  }
  color: ${colors.grey[700]};
  &:focus {
    max-height: ${({ theme }) => theme.legacy.lineHeight.md};
    border: 0;
  }
  ${({ isHidden }) => isHidden && visuallyHidden}
`;

interface IListLike {
  tabIndex: number;
  onClick: (ev: React.MouseEvent) => void;
  onMouseDown: (ev: React.MouseEvent) => void;
  onBlur: (ev: React.MouseEvent) => void;
  onKeyUp: (ev: React.KeyboardEvent) => void;
  onFocus: (ev: React.FocusEvent) => void;
  $hasFocus: boolean;
  $isValid: boolean;
}

export const PillContainer = styled(List)<IListLike>`
  transition: all 0.3s;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.md};
  border: 1px solid ${colors.grey[300]};
  overflow: hidden;
  &:hover {
    ${({ $isValid }) =>
      css`
        border: 1px solid ${$isValid ? colors.antdBlue : colors.tomato};
      `}
  }
  & > div {
    width: 100%;
    min-height: 40px;
    padding: 8px;
  }
  & > div > div {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0 !important;
  }
  & > div > div > span:last-child {
    flex-grow: 1;
  }
  ${({ $hasFocus, $isValid }) =>
    $isValid &&
    $hasFocus &&
    css`
      box-shadow: 0px 0px 0px 2px ${colors.antdBoxShadow};
      border: 1px solid ${colors.antdBlue};
    `}

  ${({ $isValid }) =>
    !$isValid &&
    css`
      border: 1px solid ${colors.tomato};
    `}
  ${({ $hasFocus, $isValid }) =>
    $hasFocus &&
    !$isValid &&
    css`
      box-shadow: 0px 0px 0px 2px ${colors.antdErrorBoxShadow};
    `}
`;

// we are treating this dom element as an interactive element and antd does not easily provide that for its list item
export const Pill = styled.li`
  max-height: ${({ theme }) => theme.legacy.lineHeight.md};
  margin-bottom: 8px;
  list-style: none;
  position: relative;
  background-color: ${colors.grey[50]};
  border: 1px solid ${colors.grey[300]};
  border-radius: ${({ theme }) => theme.legacy.lineHeight.md};
`;

// custom behavior
export const IconButton = styled.button`
  margin-left: 8px;
  padding: 0;
  cursor: pointer;
  z-index: 204 /* disable theming (do not replicate) */;
  border: 0;
  background-color: transparent;
  color: ${colors.kiwiGrey};
  font-size: ${({ theme }) => theme.legacy.fontSize.md};
  display: flex;
  align-items: center;
`;

// used to track width of 14px font size width so that input can expand / contract based on value
// must match PillInput
export const InvisibleText = styled.span`
  font-size: ${({ theme }) => theme.legacy.fontSize.sm};
  padding: 0px 8px 2px 8px;
  color: black;
  z-index: ${({ theme }) => theme.legacy.zIndex.belowBackdrop};
  position: absolute;
`;

// antd inputs do not allow programmatic selection of text
export const PillInput = styled.input`
  max-height: ${({ theme }) => theme.legacy.lineHeight.md};
  font-size: ${({ theme }) => theme.legacy.fontSize.sm};
  background-color: transparent;

  box-shadow: 0px 0px 0px 2px transparent;
  border: 1px solid transparent;
  list-style: none;
  margin: 4px;
  vertical-align: top;
`;

export const PillInputMimic = styled.span`
  font-size: ${({ theme }) => theme.legacy.fontSize.xs};
  margin: auto;
`;
