import { Field } from "formik";
import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import { Modal } from "@hotel-engine/common/Modal";
import { buttonReset, colors, ellipsisText, sizes } from "@hotel-engine/styles";

export const InviteUserModal = styled(Modal)`
  .ant-modal-body {
    padding: 0 !important;
  }

  .ant-modal-content {
    border-radius: 10px !important /* disable theming (do not replicate) */;
  }
`;

export const ModalSectionHeading = styled.h3`
  font-size: 16px;
  padding: 17px 16px;
  border-bottom: 1px solid ${colors.grey[100]};
  line-height: 20px;
`;

export const ModalBody = styled.div`
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
  padding: 24px 16px 8px;
  border-bottom: 1px solid ${colors.grey[100]};
`;

export const CopyLabel = styled.p`
  padding: 0;
  margin: 0 0 4px;
`;

export const CopyLink = styled.button<{ isLinkCopied: boolean }>`
  ${buttonReset}
  padding: 12px 16px;
  background-color: ${colors.chefsHat};
  border-radius: 5px /* disable theming (do not replicate) */;
  width: 100%;
  text-align: start;
  line-height: 16px;
  font-weight: ${({ theme }) => theme.legacy.fontWeight.bold};
  ${ellipsisText}

  &:hover {
    cursor: ${({ isLinkCopied }) => (!isLinkCopied ? "pointer" : "not-allowed")};
  }

  span {
    font-weight: 400;
    color: ${colors.retroBlue};
    margin-left: auto;
    float: right;
    opacity: ${({ isLinkCopied }) => (!isLinkCopied ? "1" : ".5")};

    @media screen and (max-width: ${sizes.breakpoints.md}) {
      display: none;
    }
  }
`;

export const OrDivider = styled.div`
  margin-top: 32px;
  border-top: 1px solid ${colors.grey[100]};
  display: flex;
  justify-content: center;
`;

export const OrText = styled.span`
  font-weight: ${({ theme }) => theme.legacy.fontWeight.bold};
  display: inline-block;
  background: ${colors.white};
  margin: 0;
  transform: translateY(-55%);
  padding: 8px;
`;

export const EmailField = styled(Field)`
  min-height: 100px !important;
`;

export const FooterButtons = styled.div`
  padding: 10px 16px;
  display: flex;
  justify-content: end;
`;

export const CancelButton = styled(Button)`
  margin-right: 8px;
`;
