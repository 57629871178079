import * as Styled from "../TripsListItem/styles";

import moment from "moment";

import { Typography, Icon as AtlasIcon, Box } from "@hotelengine/atlas-web";
import type { IBooking, IDetails, ITraveler } from "@hotel-engine/types/trips";
import type { IconName } from "@hotelengine/atlas-core";
import { TravelersFooter } from "../TravelersFooter";

export const LodgingCard = ({
  booking,
  travelers,
}: {
  booking: IBooking;
  travelers: ITraveler[];
}) => {
  return (
    <>
      <Styled.LodgingCard>
        <LodgingCardImage imageUrl={booking?.details?.property?.imageUrl} />
        <LodgingCardInfo details={booking.details} roomCount={booking.roomCount || 0} />
        <Styled.TravelersFooter>
          <AtlasIcon name={"user" as IconName} size="md" color="backgroundPrimary" />
          <TravelersFooter travelers={travelers} />
        </Styled.TravelersFooter>
      </Styled.LodgingCard>
    </>
  );
};

const LodgingCardImage = ({
  imageUrl,
}: {
  imageUrl?: string;
}) => {
  return (
    <Styled.LodgingCardImage>
      {!!imageUrl && <Styled.Image image={imageUrl}></Styled.Image>}
    </Styled.LodgingCardImage>
  );
};

const LodgingCardInfo = ({ details, roomCount }: { details: IDetails; roomCount: number }) => {
  const roomCountCopy = `${roomCount} rooms`;
  const locationCopy = [
    details?.property?.city,
    details?.property?.state,
    details?.property?.country,
  ]
    .filter(Boolean)
    .join(", ");
  const datesCopy = `${moment(details.checkIn).format("MMM D")} - ${moment(details.checkOut).format(
    "MMM D"
  )}`;

  return (
    <Styled.LodgingCardInfo>
      <Typography variant="heading/lg" color="foregroundPrimary" padding={16}>
        {details?.property?.name}
      </Typography>
      <Styled.CardDivider />
      <Box padding={16} display="flex" flexDirection="row" justifyContent="space-between">
        <Styled.LodgingCardRow>
          <Typography variant="uppercase/2xs-strong" color="foregroundSecondary">
            {datesCopy}
          </Typography>
          <AtlasIcon name={"circle-small" as IconName} size="sm" color="neutralN200" />
          <Typography variant="uppercase/2xs-strong" color="foregroundSecondary">
            {locationCopy}
          </Typography>
        </Styled.LodgingCardRow>
        <Typography variant="uppercase/2xs-strong" color="foregroundSecondary">
          {roomCount > 1 ? roomCountCopy : "#" + details.internalConfirmationNumber}
        </Typography>
      </Box>
    </Styled.LodgingCardInfo>
  );
};
