import { useEffect, useState } from "react";

import { Field, Form, Formik } from "formik";
import * as yup from "yup";

import InputField from "@hotel-engine/common/FormikFields/InputField";
import { useTwoFactorVerificationCodeSubmit } from "@hotel-engine/react-query/twoFactorAuth/useTwoFactorVerificationCodeSubmit";
import { triggerAmplitudeEvent } from "@hotel-engine/scripts/hooks";
import { captureMessage } from "@hotel-engine/utilities";
import { useAppSelector } from "store/hooks";

import { ModalNavBar } from "../../ModalNavBar";
import { ResendButton } from "./ResendButton";
import * as Styled from "./styles";
import { is422ErrorResponse } from "@hotel-engine/react-query/errors/response";

interface IEnterVerificationCode {
  onClose: () => void;
  onSubmit: () => void;
  phone: string;
}
export interface ICodeVerification {
  /** Authentication code texted to user's phone */
  code: string;
}

const initialValues: ICodeVerification = {
  code: "",
};

const errorMessage = "Please enter valid code";
const CodeVerificationValidation = yup.object().shape({
  code: yup
    .string()
    .matches(/^\d+$/, errorMessage)
    .test("", errorMessage, (code) => code?.length === 6)
    .required(errorMessage),
});

// resend code, setup code submission
export const EnterVerificationCode = ({ onClose, onSubmit, phone }: IEnterVerificationCode) => {
  const { mutate } = useTwoFactorVerificationCodeSubmit();
  const [resendError, setResendError] = useState<string | null>(null);
  const user = useAppSelector((state) => state.Auth.user);

  useEffect(() => {
    triggerAmplitudeEvent("viewSetup2FaModal", {
      userId: user?.id,
    });
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values: ICodeVerification, actions) => {
    mutate(
      { phone, code: values.code },
      {
        onSuccess: () => {
          onSubmit();
        },
        onError: (error) => {
          if (is422ErrorResponse(error)) {
            actions.setFieldError(
              "code",
              "Verification code was not accepted. Please check that the number is valid and try again."
            );
            return;
          }
          captureMessage("Enter Verification Code error", { error });

          actions.setFieldError("code", "Sorry, there was a problem. Try again later.");
        },
      }
    );
  };

  const last4Digits = phone.slice(-4);

  return (
    <>
      <ModalNavBar onClose={onClose} />
      <Styled.Title>Enter authentication code</Styled.Title>
      <Styled.DescriptionText>
        We've sent a verification code to{" "}
        <Styled.PhoneNumberText>xxx-xxx-{last4Digits}</Styled.PhoneNumberText>. Please enter the
        code you received in the field below.
      </Styled.DescriptionText>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={CodeVerificationValidation}
      >
        <>
          <Styled.DescriptionText>
            Didn't receive code?{" "}
            <ResendButton phone={phone as string} setResendError={setResendError} />{" "}
            {!!resendError && <Styled.ResendErrorMessage>{resendError}</Styled.ResendErrorMessage>}
          </Styled.DescriptionText>
          <Form>
            <Field
              component={InputField}
              name="code"
              placeholder="Enter Verification Code"
              required={true}
            />
            <Styled.SubmitButton htmlType="submit" type="primary">
              Verify
            </Styled.SubmitButton>
          </Form>
        </>
      </Formik>
    </>
  );
};
