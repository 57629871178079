import styled from "styled-components";

const RefundDetailsContainer = styled.div<{ $isPreview: boolean }>`
  margin-top: ${({ $isPreview, theme }) => (!$isPreview ? theme.spacing[40] : theme.spacing[24])};
  margin-bottom: ${({ $isPreview, theme }) => (!$isPreview ? theme.spacing[40] : theme.spacing[24])};
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing[20]};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InnerDivider = styled.div`
  height: 1px;
  border-top: 1px dashed ${({ theme }) => theme.colors.borderMinimal};
`;

export { RefundDetailsContainer, HeaderContainer, InnerDivider };
