import { formattedPrice } from "pages/Checkout/utils/formatters";
import type { ICarSummaryOfCharges } from "@hotel-engine/types/itinerary";

export const useTaxesAndFees = (charges: ICarSummaryOfCharges) => {
  const taxesFromCharges = Array.isArray(charges?.taxes) ? charges.taxes : [];
  const feesFromCharges = Array.isArray(charges?.fees) ? charges.fees : [];

  const taxes = taxesFromCharges.map(({ amount, description }) => {
    return { description: description, formattedPrice: formattedPrice(amount) };
  });
  const fees = feesFromCharges.map(({ amount, description }) => {
    return { description: description, formattedPrice: formattedPrice(amount) };
  });

  return {
    fees,
    taxes,
  };
};
