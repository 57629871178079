import * as Styled from "./styles";

/**
 * This component returns the styled JSX for the error message that is passed in as a prop to the `antd` `message` function
 */
export const ErrorMessage = () => {
  return (
    <Styled.ErrorMessageWrapper>
      An error occurred, please
      <Styled.ReloadLink onClick={() => globalThis.location.reload()}>
        reload the page
      </Styled.ReloadLink>
    </Styled.ErrorMessageWrapper>
  );
};
