import styled, { createGlobalStyle, css } from "styled-components";

import Button from "@hotel-engine/common/Button";
import { Icon } from "@hotel-engine/common/Icon";
import { Input } from "@hotel-engine/common/Inputs";
import { buttonReset, colors } from "@hotel-engine/styles";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { isIE11 } from "@hotel-engine/utilities";

import { Tag } from "./Tag/styles";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";

interface IButtonGroup {
  columnGap?: React.CSSProperties["columnGap"];
}

const iconButton = css`
  &:last-child {
    margin-right: 0;
  }

  & > ${Tag}, & > .icon {
    margin-left: 8px;
  }
`;

export const TripsFiltersContainer = styled.div`
  margin: 0;
`;

export const MobileSearchInput = styled(Input)`
  height: 40px;
`;

export const SearchButton = styled(Button)`
  ${buttonReset};
  ${flexbox({
    alignItems: "center",
    justifyContent: "center",
  })}
  height: 38px;

  &:hover svg,
  &:focus svg {
    color: ${({ theme }) => theme.legacy.colors.blue[500]};
    background-color: ${({ theme }) => theme.legacy.colors.grey[50]};
  }
`;

export const SearchIcon = styled(Icon)`
  padding: 4px;
  border-radius: 12px /* disable theming (do not replicate) */;
  height: 16px;
  font-size: 22px;

  &:hover {
    background-color: ${colors.grey[50]};
  }
`;

export const TripsFilters = styled.div`
  ${flexbox({
    justifyContent: "space-between",
  })}

  & > button {
    ${iconButton};
  }

  ${mediaQuery("lg")(`
    align-items: baseline;
  `)}
`;

const ButtonGroup = styled.div<IButtonGroup>`
  ${flexbox({ alignItems: "center" })}
  column-gap: ${({ columnGap = "20px" }) => columnGap};

  & button.status-button:hover:enabled,
  & button.status-button:focus,
  & button.status-button:focus:disabled {
    color: initial;
    border-color: initial;
  }

  & .ant-checkbox-wrapper {
    overflowe: hidden;
  }

  & .ant-checkbox-wrapper span {
    padding: 0;
  }

  & .ant-checkbox-wrapper span.ant-checkbox {
    padding: 0;
    margin-right: 8px;
  }

  & .ant-checkbox-wrapper span,
  & #trips_filterDropdown span {
    ${typographyMixin("small")}
    font-weight: 400;
    padding-bottom: 0.5px;
  }

  & #trips_filterDropdown:hover span {
    color: ${({ theme }) => theme.legacy.colors.blue[500]};
  }

  & #trips_filterDropdown,
  & .ant-calendar-picker {
    width: fit-content;
    transition-property: all;
    transition-timing-function: ease;
  }

  & .ant-input-affix-wrapper {
    width: 168px;
    height: 32px;
    > input {
      border-color: ${({ theme }) => theme.legacy.colors.grey[300]};
    }
    :hover {
      > input {
        border-color: ${({ theme }) => theme.legacy.colors.blue[500]};
      }
    }
  }

  & #searchTrips {
    padding: 0 8px;
  }

  & #trips_filterDropdown,
  & .ant-calendar-picker-input,
  & #searchTrips {
    height: 32px;
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.md};
    border-width: 1px;
  }

  .ant-calendar-picker-input:hover {
    border-color: ${({ theme }) => theme.legacy.colors.grey[300]};
  }

  &.ant-calendar-picker-input {
    color: inherit;
    border-color: inherit;
    background-color: inherit;
  }

  & #trips_filterDropdown,
  & .ant-calendar-picker-input {
    padding: 0 15px;
  }

  & #trips_filterDropdown > img,
  & .ant-calendar-picker-icon {
    position: relative;
    width: 16px;
    height: 16px;
  }

  & .ant-calendar-picker-icon {
    top: 3px;
    right: initial;
  }

  & #trips_filterDropdown,
  & .ant-calendar-picker-input.ant-input {
    ${flexbox({ alignItems: "center", direction: "row-reverse" })}
    column-gap: 8px;
  }

  & .search-button {
    height: 30px;
  }

  ${mediaQuery(
    "xxl",
    "max"
  )(`
    margin-top: 12px;
  `)}
`;

const StatusButton = styled(Button)`
  ${typographyMixin("body-medium")}
  height: 36px;
  border: none;
  padding: 8px 0px;
  border-radius: 0;
  transition-duration: 100ms;
  transition-timing-function: ease-in;
  & > span {
    color: ${colors.grey[800]};
  }
  &:hover,
  &.active {
    border-bottom: 2px solid ${colors.grey[800]};
    border-radius: 0;
    &[ant-click-animating-without-extra-node="true"]::after {
      display: none;
    }
  }
`;

export const RangePickerGlobalStyles = createGlobalStyle`
  .ant-calendar-input-wrap,
  .ant-calendar-range-middle {
    display: none;
  }

  .ant-calendar-picker-container {
    font-family: ${({ theme }) => theme.legacy.fontFamily.primary};
  }
  .ant-input,
  .ant-input::placeholder,
  .ant-calendar-picker-icon {
    color: rgba(0, 0, 0, 0.65);
    height: 40px;
  }

  .ant-calendar-picker-input {
    height: 40px;

    &:hover {
      color: ${colors.retroBlue};
      border-color: ${colors.retroBlue};
      & svg {
        color: ${colors.retroBlue};
      }

      i,
      .ant-calendar-range-picker-input,
      input {
        color: ${colors.retroBlue};

        &::placeholder {
          color: ${colors.retroBlue};
        }
      }
    }
  }

  .ant-calendar-range-picker-separator {
    display: none;
  }

  .ant-calendar-range-picker-input {
    cursor: pointer;
    width: 100%;
    text-align: left;
    padding-bottom: 0.5px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-calendar-picker-input > :nth-child(3) {
    display: none;
  }
`;

export const SearchInput = styled(Input)`
  width: 225px;
  height: 40px;

  .ant-input::placeholder {
    line-height: ${isIE11 ? 0 : "40px"};
    color: rgba(0, 0, 0, 0.25);
  }
`;

export { ButtonGroup, StatusButton };
