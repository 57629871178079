import { useLocation } from "@hotel-engine/lib/react-router-dom";

import { useBreakpoint } from "@hotel-engine/hooks";

import type { IModification } from "pages/Itinerary";
import type { ViewType } from "pages/Trips/constants";
import { viewTypes } from "pages/Trips/constants";
import type { IReservationBase } from "@hotel-engine/types/reservation";

import type { IReservationBaseWithId } from "pages/Trips/components/TripsItineraryPreviewPanel/lodging";
import type { ITripPreview } from "pages/Trips/context";

import BookingSuccess from "../shared/BookingSuccess";
import CancelledBanner from "../shared/CancelledBanner";
import SameDayBanner from "./components/SameDayBanner";
import GooglePreviewMap from "./components/GooglePreviewMap";
import HeaderActions from "../shared/HeaderActions";
import ItineraryContentBody from "./components/ItineraryContentBody";
import PropertyDetails from "./components/PropertyDetails";

import { ModificationsProvider } from "../ModificationsContext";
import * as Styled from "../styles";
import moment from "moment";
import { Loader } from "../styles";

export interface ILocationState {
  editCustomFields?: boolean;
  confirmation?: boolean;
  propertyQuery?: {
    propertyId: number;
    location: string;
    checkIn: string;
    checkOut: string;
    guestCount: string;
    roomCount: string;
    s: string;
  };
}

export interface ILodgingItineraryContentProps {
  /** Used to manage location specific logic depending on whether the user is on the Trips page or Itinerary page */
  isPreview: boolean;
  /** The reservation being displayed */
  itinerary: IReservationBase | IReservationBaseWithId;
  /** Used to refetch the reservation after modifications are made within the panel */
  refetchReservation: () => Promise<void> | void;
  /** Used to render loader while full reservation is being fetched on the Trips page */
  isLoading?: boolean;
  /** Only used when on Trips page preview panel close button is pressed */
  onClose?:
    | ((tripPreview: ITripPreview) => void)
    | ((
        reservation: IReservationBase
      ) => void) /** Only used when on Trips page for map press action */;
  handleView?: (viewType: ViewType) => void;
  /** Reservation modifications */
  billingIssues?: IModification[];
  /** Only used on Trips page to determine whether to show map */
  view?: "map" | "list";
}

const LodgingItineraryContent = ({
  isPreview,
  itinerary,
  refetchReservation,
  isLoading,
  onClose,
  handleView,
  billingIssues = [],
  view,
}: ILodgingItineraryContentProps) => {
  const isMobile = useBreakpoint("md", "max");
  const { state: locationState } = useLocation<ILocationState>();

  const isReservationCancelled = /cancelled/.test(itinerary.status);
  const isReservationPast = /completed/.test(itinerary.status);
  const shouldShowBookingConfirmation = !isReservationCancelled && !!locationState?.confirmation;
  const isPastCheckIn = moment().isAfter(moment(itinerary.checkIn).endOf("day"));
  const shouldShowSameDayWarning =
    !isReservationCancelled &&
    !isReservationPast &&
    !!itinerary.sameDayReservation &&
    !isPastCheckIn;
  const shouldShowPreviewMap = ((isPreview && view !== viewTypes.MAP) || isMobile) && !isLoading;
  const showLoader = isPreview && isLoading;

  const handlePushMapView = () => {
    handleView?.(viewTypes.MAP);
  };

  const bookingConfirmationId = itinerary.propertyConfirmationNumber || itinerary.contractNumber;

  return (
    <ModificationsProvider
      reservation={itinerary}
      isPreview={isPreview}
      refetchReservation={refetchReservation}
    >
      {!!showLoader ? (
        <Loader data-testid="itin-content-loader" size="large" />
      ) : (
        <>
          <HeaderActions
            onClose={onClose}
            itinerary={isPreview ? (itinerary as IReservationBaseWithId) : undefined}
          />
          {!!isReservationCancelled && <CancelledBanner isLodging />}
          {!!shouldShowBookingConfirmation && (
            <Styled.SuccessWrapperContainer>
              <BookingSuccess propertyConfirmationNumber={bookingConfirmationId} />
            </Styled.SuccessWrapperContainer>
          )}
          {!!shouldShowSameDayWarning && <SameDayBanner />}
          <Styled.PaddedItineraryContainer $isPreview={isPreview || isMobile}>
            <PropertyDetails
              onPushMapView={handlePushMapView}
              canSwitchToMapView={shouldShowPreviewMap}
            />
            <ItineraryContentBody billingIssues={billingIssues} />
          </Styled.PaddedItineraryContainer>
          {!!shouldShowPreviewMap && (
            <GooglePreviewMap
              handleView={() => handleView?.(viewTypes.MAP)}
              longitude={Number(itinerary.propertyLongitude)}
              latitude={Number(itinerary.propertyLatitude)}
              street={itinerary.propertyStreet}
              city={itinerary.propertyCity}
              state={itinerary.propertyState}
              postalCode={itinerary.propertyPostalCode}
            />
          )}
        </>
      )}
    </ModificationsProvider>
  );
};

export default LodgingItineraryContent;
