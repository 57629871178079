import {
  Box,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  Typography,
} from "@hotelengine/atlas-web";
import { ErrorMessage } from "@hotel-engine/app/ErrorMessage";
import { message } from "@hotel-engine/common/Message";
import { queryClient } from "@hotel-engine/contexts";
import { Unsafe } from "@hotel-engine/data";
import { useBreakpoint } from "@hotel-engine/hooks";
import { endpoints } from "@hotel-engine/react-query/constants";
import { useDeleteHiddenProperty } from "@hotel-engine/react-query/hiddenProperties/useDeleteHiddenProperty";
import type { HidePropertyScope } from "@hotel-engine/types/hiddenProperty";
import type { IPropertyLegacy } from "@hotel-engine/types/property";
import { captureException } from "@hotel-engine/utilities";
import { PropertyActions } from "store/containers/Property/PropertyRedux";
import { useAppDispatch } from "store/hooks";

import * as Styled from "./styles";

/**
 * `modalConfig` contains the differences in UI text that is
 * displayed by the modal. It corresponds to the
 * {@link IUnhideModalProps.scope} prop passed to {@link UnhidePropertyModal}.
 */
const modalConfig: {
  [key in HidePropertyScope]: {
    titleText: string;
    contentText: string;
    primaryBtnText: string;
  };
} = {
  business: {
    titleText: "Unhide Property For All",
    contentText:
      "Are you sure you want to unhide this property? This property will appear in your search results for your entire organization.",
    primaryBtnText: "Unhide For All",
  },
  user: {
    titleText: "Unhide Property",
    contentText:
      "Are you sure you want to unhide this property? This property will appear in your search results.",
    primaryBtnText: "Unhide",
  },
} as const;

interface IUnhideModalProps {
  /**
   * The `propertyId` and `hiddenPropertyId` are used by the
   * network request, and for invalidating the react-query
   * cache afterward.
   */
  propertyId: number;
  hiddenPropertyId?: number | null;
  /**
   * `onUnhideSuccessful` will be invoked after the network
   * request returns successfully.
   */
  onUnhideSuccessful: () => void;
  /**
   * `onClose` will be invoked if the user clicks off of
   * the modal, or if they click the "Cancel" button.
   */
  onClose: () => void;
  /**
   * The "Unhide Property Modal" has multiple possible variations.
   * Each variation simply determines which text is displayed
   * in the modal.
   */
  scope?: HidePropertyScope;
}

/**
 * This modal should be used to confirm that a user wants
 * to unhide an already-hidden property.
 *
 * This modal can be used for both cases when a property
 * is hidden from the logged-in user, or from the
 * logged-in-user's entire business.
 */
const UnhidePropertyModal = ({
  propertyId,
  hiddenPropertyId,
  onUnhideSuccessful,
  onClose,
  scope = "user",
}: IUnhideModalProps) => {
  const dispatch = useAppDispatch();
  const isMobile = useBreakpoint("md", "max");
  const unhideProperty = useDeleteHiddenProperty();

  /**
   * The server determines which scope the property should be
   * unhidden for. It's based on the logged-in user's role.
   * Therefor, there is no difference between the api call to
   * unhide a property at the "user" level, versus unhiding it at
   * the "business" level.
   */
  const onUnhideClick = async () => {
    try {
      if (hiddenPropertyId) {
        await unhideProperty.mutateAsync(hiddenPropertyId, {
          onSuccess: () => {
            queryClient.setQueryData<IPropertyLegacy>(
              [endpoints.properties, propertyId],
              (cachedProperty) =>
                (cachedProperty
                  ? {
                      ...cachedProperty,
                      hiddenPropertyId: null,
                      hiddenPropertyHiddenFromType: null,
                      hiddenPropertyReason: null,
                    }
                  : cachedProperty) as IPropertyLegacy
            );
          },
        });
        onUnhideSuccessful();
        dispatch(PropertyActions.setHiddenPropertyId(null));
      }
    } catch (e) {
      message.error(<ErrorMessage />, 0).then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
      captureException(e);
    }
  };

  return (
    <Dialog isModal isOpen>
      <DialogContent>
        <Box padding={isMobile ? 0 : 16}>
          <DialogTitle>
            <Box display="flex" justifyContent="center" marginBottom={16}>
              <Typography
                as="h2"
                color="foregroundPrimary"
                marginBottom={0}
                variant="heading/xl"
                style={{ textAlign: "center" }}
              >
                {modalConfig[scope].titleText}
              </Typography>
            </Box>
          </DialogTitle>
          <DialogDescription>
            <Typography
              as="p"
              color="foregroundPrimary"
              marginBottom={24}
              style={{ textAlign: "center" }}
              variant="body/sm"
            >
              {modalConfig[scope].contentText}
            </Typography>
          </DialogDescription>
          <Box display="flex" gap={16} justifyContent="center">
            <DialogClose>
              <Styled.ActionButton
                color="secondary"
                isDisabled={unhideProperty.isLoading}
                key="btn-cancel"
                onClick={onClose}
                variant="outlined"
              >
                Close
              </Styled.ActionButton>
            </DialogClose>
            <Styled.ActionButton
              isDisabled={unhideProperty.isLoading}
              isLoading={unhideProperty.isLoading}
              key="btn-primary"
              onClick={onUnhideClick}
              data-testid="property-modal-action-button"
            >
              {modalConfig[scope].primaryBtnText}
            </Styled.ActionButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UnhidePropertyModal;
