import type { ReactNode } from "react";

import { Box, Icon, Typography } from "@hotelengine/atlas-web";
import { useToggle } from "@hotel-engine/hooks";

import * as Styled from "./styles";

type CollapsibleItemProps = {
  title: string;
  children: JSX.Element;
  isFirstItem?: boolean;
  isLastItem?: boolean;
};

const CollapsibleItem = ({
  title,
  children,
  isFirstItem = true,
  isLastItem = true,
}: CollapsibleItemProps) => {
  const [collapsed, toggle] = useToggle(true);

  const toggleCollapse = () => {
    toggle();
  };

  return (
    <Styled.Container isFirstItem={isFirstItem} isLastItem={isLastItem} isCollapsed={collapsed}>
      <Styled.Header variant="plain" onClick={toggleCollapse}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Icon
            name={collapsed ? "caret-right--solid" : "caret-down--solid"}
            color="foregroundPrimary"
          />
          <Typography variant="heading/md" marginLeft={8}>
            {title}
          </Typography>
        </Box>
      </Styled.Header>
      {!collapsed && <Styled.Content>{children}</Styled.Content>}
    </Styled.Container>
  );
};

export type Item = {
  title: string;
  content: string | ReactNode;
};

export const CollapsibleList = ({ list }: { list: Item[] }) => {
  return (
    <Styled.ListContainer>
      {list.map((item, index) => {
        if (!item.content) {
          return null;
        }

        const getContent = () => {
          if (typeof item.content === "string") {
            return <Typography variant="body/md">{item.content}</Typography>;
          } else {
            return <Box>{item.content}</Box>;
          }
        };

        return (
          <CollapsibleItem
            key={item.title}
            title={item.title}
            isFirstItem={index === 0}
            isLastItem={index === list.length - 1}
          >
            {getContent()}
          </CollapsibleItem>
        );
      })}
    </Styled.ListContainer>
  );
};
