import * as Styled from "./styles";
import { Icon as AtlasIcon, Typography } from "@hotelengine/atlas-web";

const LoyaltyReminder = () => (
  <Styled.LoyaltyReminderContainer>
    <div>
      <AtlasIcon color="accentPurple" name="he-loyalty--solid" />
    </div>
    <Styled.LoyaltyReminderContent>
      <Typography variant="heading/sm" color="foregroundPrimary">
        Congratulations! You've applied your hotel loyalty number
      </Typography>
      <Typography variant="body/sm" color="foregroundPrimary">
        Upon check-in, please double check that the hotel has entered your loyalty number correctly
        so you earn your well-deserved points!
      </Typography>
    </Styled.LoyaltyReminderContent>
  </Styled.LoyaltyReminderContainer>
);

export default LoyaltyReminder;
