import { useCallback } from "react";
import moment from "moment";
import { ampli } from "ampli";

import { Box, Button, Typography } from "@hotelengine/atlas-web";
import { useSearchParams } from "@hotel-engine/hooks";
import type { ICarItineraryCompany, ICarLocation } from "@hotel-engine/types/itinerary";
import ResponsiveAtlasDialog from "@hotel-engine/app/ResponsiveAtlasDialog";
import { useCarModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";

import { useAirportAccess } from "pages/Cars/hooks/useAirportAccess";
import { useAppSelector } from "store/hooks";

import { SectionSubtitle } from "./components/SectionSubtitle";
import * as Styled from "./styles";
import { RentalCompanyImage } from "../../styles";

export type LocationType = "pick-up" | "drop-off" | "both";
export interface IContactInformation {
  location: ICarLocation;
  hours?: {
    pickUp: string;
    dropOff: string;
  };
  rentalCompany: ICarItineraryCompany;
  locationType: LocationType;
  handleGetDirections: () => void;
  startTime: string;
  endTime: string;
}

export interface ICarLocationParams {
  show_hours?: string;
}

export function ContactInformation({
  location,
  hours,
  rentalCompany,
  locationType,
  handleGetDirections,
  startTime,
  endTime,
}: IContactInformation) {
  const { show_hours } = useSearchParams<ICarLocationParams>();
  const isOpenByDefault = !!show_hours && show_hours === "true";

  const { reservation } = useCarModificationsContext();
  const user = useAppSelector((state) => state.Auth.user);
  const { getAirportAccess } = useAirportAccess();
  const pickUpDay = moment(startTime).parseZone().format("dddd");
  const dropOffDay = moment(endTime).parseZone().format("dddd");

  const onCall = useCallback(() => {
    globalThis.open(`tel:${location.contactInfo.phoneNumber.replace(/\D/g, "")}`);
  }, [location.contactInfo.phoneNumber]);

  if (!location || !rentalCompany) return null;

  const hoursTitle =
    locationType === "pick-up"
      ? "Pick-up hours"
      : locationType === "drop-off"
        ? "Drop-off hours"
        : "Pick-up & drop-off hours";

  const fullAddress = [location.street, location.city, location.state, location.country]
    .filter(Boolean)
    .join(", ");

  const hoursTable = (
    <>
      {!!(locationType === "pick-up" || locationType === "both") && (
        <Styled.TimeTable key={`row-pickup`}>
          <Typography variant="body/sm" color="foregroundPrimary" style={{ width: 100 }}>
            {pickUpDay}
          </Typography>
          <Typography variant="body/sm" color="foregroundPrimary">
            {hours?.pickUp}
          </Typography>
        </Styled.TimeTable>
      )}
      {!!(locationType === "drop-off" || locationType === "both") && (
        <Styled.TimeTable key={`row-dropoff`}>
          <Typography variant="body/sm" color="foregroundPrimary" style={{ width: 100 }}>
            {dropOffDay}
          </Typography>
          <Typography variant="body/sm" color="foregroundPrimary">
            {hours?.dropOff}
          </Typography>
        </Styled.TimeTable>
      )}
    </>
  );

  const content = (
    <>
      <Box marginTop={24}>
        {!rentalCompany?.logo ? (
          <SectionSubtitle
            icon={{ name: "location-dot--solid" }}
            title={
              !!location.pickUpType ? String(getAirportAccess(location.pickUpType)) : location.name
            }
          >
            {!!location.pickUpType && (
              <Typography variant="body/sm" color="foregroundSecondary">
                {location.name}
              </Typography>
            )}
            <Typography variant="body/sm" color="foregroundSecondary" marginBottom={16}>
              {fullAddress}
            </Typography>
          </SectionSubtitle>
        ) : (
          <RentalCompanyImage src={rentalCompany?.logo} $size={146} width={146} />
        )}
        <Styled.ButtonsContainer padTop={!!rentalCompany?.logo}>
          <Button
            size="lg"
            color="primary"
            variant="outlined"
            leadingIcon="diamond-turn-right"
            data-testid="open-directions"
            onClick={handleGetDirections}
          >
            Get directions
          </Button>
          {!!location.contactInfo?.phoneNumber && (
            <Button
              size="lg"
              color="primary"
              variant="outlined"
              leadingIcon="phone"
              data-testid="call-location"
              onClick={onCall}
            >
              Call location
            </Button>
          )}
        </Styled.ButtonsContainer>
        <Styled.SectionsContainer>
          {!!rentalCompany?.logo && (
            <SectionSubtitle
              icon={{ name: "location-dot--solid" }}
              title={
                !!location.pickUpType
                  ? String(getAirportAccess(location.pickUpType))
                  : location.name
              }
            >
              {!!location.pickUpType && (
                <Typography variant="body/sm" color="foregroundSecondary">
                  {location.name}
                </Typography>
              )}
              <Typography variant="body/sm" color="foregroundSecondary" marginBottom={16}>
                {fullAddress}
              </Typography>
            </SectionSubtitle>
          )}
          {!!location.contactInfo?.phoneNumber && (
            <SectionSubtitle
              icon={{ name: "phone--solid" }}
              title={location.contactInfo.phoneNumber}
            />
          )}
          {!!hours?.pickUp && !!hours?.dropOff && (
            <SectionSubtitle icon={{ name: "clock--solid" }} title={hoursTitle}>
              {hoursTable}
              <Typography
                variant="body/xs"
                color="foregroundSecondary"
                marginTop={8}
                marginBottom={8}
              >
                Pick-ups or drop-offs outside of general opening hours may be subject to an
                out-of-hours fee, payable locally at the rental desk.
              </Typography>
            </SectionSubtitle>
          )}
        </Styled.SectionsContainer>
      </Box>
    </>
  );

  const handleViewHoursAndContactInfo = () => {
    if (user) {
      ampli.clickViewHoursAndContact({
        bookingId: reservation.bookingNumber,
        userId: user.id,
      });
    }
  };

  return (
    <ResponsiveAtlasDialog
      title="Rental location"
      content={content}
      triggerComponent={
        <Button size="lg" variant="outlined" onClick={handleViewHoursAndContactInfo}>
          View hours and contact info
        </Button>
      }
      isOpenByDefault={isOpenByDefault}
      isModal
    />
  );
}
