import { Button, Typography } from "@hotelengine/atlas-web";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";

import { useTripsContextLegacy } from "pages/Trips/contextLegacy";
import { useTripsContext } from "pages/Trips/context";

import { useTrackBookingModifications } from "@hotel-engine/app/ItineraryContent/hooks/useTrackBookingModifications";
import * as Styled from "./styles";
import ModalWrapper from "../ModalWrapper";

const NavigationModal = () => {
  const {
    dispatch,
    state: { modificationView },
  } = useModificationsContext();

  const isFlightsPostbookingOn = useIsFeatureFlagOn("flights-postbooking");

  const { dispatch: tripsLegacy } = useTripsContextLegacy();
  const { dispatch: trips } = useTripsContext();

  const tripsDispatch = isFlightsPostbookingOn ? trips : tripsLegacy;

  const { trackAbandonModificationFlow, trackContinueModificationFlow } =
    useTrackBookingModifications();

  const abandonModification = () => {
    dispatch({ type: "ABANDON_MODIFICATION" });

    /** If we are on the Trips page (hence the conditional function call) and the user chooses to abandon,
     * we need to reset isActive to false, which will kick off a reset of the modification state in the
     * TripsContext, and will push any deferredTripSelection onto the current showPreview trip.
     */
    tripsDispatch?.({
      type: "setActiveModification",
      activeModification: { isActive: false, isNavigating: false },
    });

    !!modificationView && trackAbandonModificationFlow(modificationView);
  };

  const continueModification = () => {
    dispatch({
      type: "SET_SHOW_NAVIGATION_MODAL",
      payload: false,
    });

    /** We need to unset any deferred navigation route so we don't have unintended side-effect if the user
     * abandons the modification in a different way. */
    dispatch({
      type: "SET_NAVIGATION_ROUTE",
      payload: undefined,
    });

    /** If we are on the Trips page (hence the conditional function call) we need to reset isNavigating if the
     * use chooses to continue so that the NavigationModal will not indefinitely render from the useEffect in our
     * Provider. It will be set back to true any time a user clicks another Trip and we go around the cycle. We also need to
     * unset any deferredTripSelection so we don't have unintended side-effect if the user abandons the modification
     * in a different way.
     */
    tripsDispatch?.({
      type: "setActiveModification",
      activeModification: {
        isActive: true,
        isNavigating: false,
        deferredTripSelection: undefined,
      },
    });

    !!modificationView && trackContinueModificationFlow(modificationView);
  };

  const modalBody = (
    <>
      <Typography variant="heading/lg" color="foregroundPrimary" as="h2">
        Are you sure you want to exit?
      </Typography>
      <Typography variant="body/md" color="foregroundPrimary" as="p">
        You have not submitted your trip modifications yet.
      </Typography>
      <Styled.ButtonContainer>
        <Button color="secondary" variant="outlined" onClick={continueModification}>
          No
        </Button>
        <Button color="primary" variant="outlined" onClick={abandonModification}>
          Yes
        </Button>
      </Styled.ButtonContainer>
    </>
  );

  return <ModalWrapper dataTestId="navigation-confirm-modal">{modalBody}</ModalWrapper>;
};

export default NavigationModal;
