import styled, { css } from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";
// TODO: Update this antD, as soon as we have a replacement in Atlas. For now we will continue using the AntD
import { ListItem } from "@hotel-engine/common/Lists";

export const ModificationActions = styled.div<{
  $onlyOneItemExists: boolean;
}>`
  ${flexbox({
    direction: "column",
  })}
  row-gap: ${({ theme }) => theme.spacing[24]};

  .ant-list {
    border-radius: ${({ theme }) => theme.borderRadius[8]};
    border-color: ${({ theme }) => theme.colors.borderSubtle};
    background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    font-size: ${({ theme }) => theme.spacing[16]};
  }

  .ant-list-item {
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
    padding: ${({ theme }) => theme.spacing[12]} ${({ theme }) => theme.spacing[16]};
    transition: 0.2s ease all;

    &:hover {
      transition: 0.2s ease all;
      background-color: ${({ theme }) => theme.colors.backgroundPrimaryHover};
    }

    &:first-child {
      ${({ theme, $onlyOneItemExists }) => {
        if ($onlyOneItemExists) {
          return css`
            border-radius: ${theme.borderRadius[8]};
          `;
        } else {
          return css`
            border-radius: ${theme.borderRadius[8]};
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          `;
        }
      }}
    }

    &:last-child {
      ${({ theme, $onlyOneItemExists }) => {
        if ($onlyOneItemExists) {
          return css`
            border-radius: ${theme.borderRadius[8]};
          `;
        } else {
          return css`
            border-radius: ${theme.borderRadius[8]};
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          `;
        }
      }}
    }
  }

  .fa-chevron-right {
    margin-right: 0;
  }

  a {
    color: inherit;
  }
`;

export const ButtonGroup = styled.div`
  ${flexbox({
    direction: "column",
  })}
  row-gap: ${({ theme }) => theme.spacing[24]};

  & #shield-check {
    margin-right: initial;
  }
`;

export const FlexMessage = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[4]};
`;

export const ItineraryListItem = styled(ListItem)`
  > div:first-child {
    display: flex;
    column-gap: ${({ theme }) => theme.spacing[12]};
    align-items: center;
    > span {
      font-weight: 500;
    }
  }
`;
