import { ampli } from "ampli";

import { Button, Icon, Typography } from "@hotelengine/atlas-web";
import ResponsiveAtlasDialog from "@hotel-engine/app/ResponsiveAtlasDialog";

import { useAppSelector } from "store/hooks";

import { useModificationsContext } from "../../../ModificationsContext";
import * as Styled from "./styles";
import ItineraryActions from "../ItineraryActions";

const ManageTrip = () => {
  const user = useAppSelector((state) => state.Auth?.user);

  const {
    reservation,
    state: { modificationView },
  } = useModificationsContext();

  const isViewOnlyTraveler = user?.role === "view_only_traveler";
  const showFlexMessage = reservation?.flexEnabled && !isViewOnlyTraveler;

  const openManageTripOptions = () => {
    ampli.clickManageTrip({
      userId: user?.id,
      flightBookingID: reservation?.contractNumber,
    });
  };

  return (
    <>
      {!modificationView && (
        <>
          <Styled.ManageTripContainer>
            <ResponsiveAtlasDialog
              title="Manage trip"
              content={<ItineraryActions />}
              triggerComponent={
                <Button style={{ width: "100%" }} size="xl" onClick={openManageTripOptions}>
                  Manage trip
                </Button>
              }
              isModal
            />
            {!!showFlexMessage && (
              <Styled.FlexMessage>
                <Typography variant="body/sm">Modifications are covered by </Typography>
                <Icon
                  id="shield-check"
                  name="he-flex"
                  color="foregroundPrimary"
                  data-testid="flex-pro-logo"
                />{" "}
                <Typography variant="body/sm-strong">Flex</Typography>
              </Styled.FlexMessage>
            )}
          </Styled.ManageTripContainer>
        </>
      )}
    </>
  );
};

export default ManageTrip;
