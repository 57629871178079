import styled, { createGlobalStyle } from "styled-components";

import Button from "@hotel-engine/common/Button";
import { Icon } from "@hotel-engine/common/Icon";
import { Modal } from "@hotel-engine/common/Modal";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { buttonReset, colors } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

import type { IPersonalAccountCreationScreen } from "./PersonalAccountCreationModal";

export const GlobalBottomSheetStyles = createGlobalStyle`
  :root {  
    --rsbs-overlay-rounded: 14px;
  }
`;

export const BottomSheetContent = styled.div`
  padding: 24px;
  margin-top: 4px;
`;

// Note: uses temporary placeholder dark color
export const PersonalAccountCreationCta = styled.div`
  position: relative;
  max-width: 1120px;
  margin: 56px auto 0;
  background-color: ${colors.aqua[50]};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};
  height: 242px;
  overflow: hidden;

  ${mediaQuery(
    "lg",
    "max"
  )(`
    padding: 16px;
    height: auto;
    text-align: center;
    background: linear-gradient(to top, ${colors.aqua[50]} 40%, ${colors.yellow[50]});
  `)}
`;

export const PersonalAccountCreationContainer = styled.div`
  margin: 0px 40px;
  ${flexbox({
    alignItems: "flex-start",
    direction: "column",
    justifyContent: "center",
  })};
  height: 100%;
  position: relative;

  ${mediaQuery(
    "lg",
    "max"
  )(`
    margin: 0;
    align-items: center;
  `)}
`;

export const PersonalAccountCreationModal = styled(Modal)<{
  $screen: IPersonalAccountCreationScreen;
}>`
  max-width: 500px;
  width: auto !important;
  ${mediaQuery("lg", "max")(`margin: ${({ theme }) => theme.legacy.space(2)} !important`)};

  .ant-modal-content {
    padding: ${({ theme }) => theme.legacy.space(3)};

    div.ant-modal-body {
      padding: 0;
    }
  }

  .ant-modal-header {
    padding: 0;
    margin-bottom: ${({ theme }) => theme.legacy.space(2)};
  }

  .ant-modal-title {
    ${typographyMixin("xlarge", false)};
    color: ${colors.black};
    text-align: center;
  }

  .ant-modal-footer {
    border: none;
    display: flex;
    padding: 0;

    > button {
      margin: 0;
      flex: 1;

      &:first-of-type {
        color: ${colors.black};
        margin-right: 16px;
      }
    }
  }
`;

// Can't find any other way to increase specificity of color attribute.
export const PACtaTitle = styled.h2`
  ${typographyMixin("xlarge", false)};
  margin-bottom: ${({ theme }) => theme.legacy.space(2)};
  color: ${colors.black};

  ${mediaQuery(
    "lg",
    "max"
  )(`
    margin-bottom: 8px;
  `)}
`;

export const PACtaDescription = styled.p`
  ${typographyMixin("body-large")};
  max-width: 480px;
  margin-bottom: ${({ theme }) => theme.legacy.space(2)};
  color: ${colors.grey[700]};

  ${mediaQuery(
    "lg",
    "max"
  )(`
    ${typographyMixin("body-medium")};
    margin-bottom: 24px;
    color: ${colors.black};
  `)}
`;

export const PACAddPersonalAccountButton = styled(Button)`
  margin-right: ${({ theme }) => theme.legacy.space(1)};
  margin-bottom: ${({ theme }) => theme.legacy.space(1)};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.md};
  height: 40px;
  font-size: ${({ theme }) => theme.legacy.fontSize.md};

  ${mediaQuery(
    "lg",
    "max"
  )(`
    width: 100%;
  `)}
`;

export const CloseIconButton = styled(Button)`
  ${buttonReset};
  box-shadow: none;
  background-color: transparent;
  height: ${({ theme }) => theme.legacy.space(3)};
  width: ${({ theme }) => theme.legacy.space(3)};
  top: 12px;
  right: 12px;
  position: absolute;

  svg {
    transition: 0.4s ease all;
  }

  &:hover,
  &:focus {
    svg {
      color: ${colors.black};
    }
  }
`;

export const CloseIcon = styled(Icon)`
  color: ${colors.white};
  margin: 0;
  padding: 0;
`;

export const ErrorModalTitle = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: ${colors.carrotCurl};
    margin-right: 16px;
    font-size: 22px;
  }
`;

export const ErrorModalBody = styled.div`
  a {
    &:hover,
    &:focus {
      color: ${colors.fadedRetroBlue};
    }
  }
`;

export const MobileIllustration = styled.img`
  max-width: 257px;
  margin-bottom: ${({ theme }) => theme.legacy.space(1)};
`;

export const BannerBackgroundIllustration = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
`;
