import { Link } from "@hotel-engine/lib/react-router-dom";
import styled, { css } from "styled-components";

import { Checkbox } from "@hotel-engine/common/Checkbox";
import { Icon } from "@hotel-engine/common/Icon";
import TypographyCommon from "@hotel-engine/common/Typography";
import Tag from "@hotel-engine/common/Tag";
import { colors } from "@hotel-engine/styles";
import Image from "@hotel-engine/common/Image";
import { FlexProBanner } from "@hotel-engine/app/FlexProBanner";

export const Service = styled.div<{
  $bgColor?: string;
  $hasBanner?: boolean;
  $borderColor?: string;
}>`
  background-color: ${({ $bgColor }) => $bgColor};
  padding: ${({ $hasBanner }) => ($hasBanner ? "0 0 16px" : "16px")};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xl};
  border: ${({ $borderColor }) => ($borderColor ? `1px solid ${$borderColor};` : null)};
  margin-top: 16px;
  width: 100%;
`;

export const FlexRow = styled.div<{ $margin?: string; $hasBanner?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  margin: ${({ $margin }) => $margin || "0 0 8px 0"};
  ${({ $hasBanner }) => $hasBanner && `padding: 16px 16px 8px`};
`;

const interactive = (disabled?: boolean) => css`
  cursor: ${disabled ? "not-allowed" : "pointer"};
  &:hover,
  &:focus {
    ${!disabled && `color: ${colors.fadedRetroBlue};`}
  }
`;

const incidentalsInteractive = (disabled?: boolean) => css`
  cursor: ${disabled ? "not-allowed" : "pointer"};
`;

export const Typography = styled(TypographyCommon)<{
  $color?: string;
  $margin?: string;
  $interactive?: boolean;
  $disabled?: boolean;
}>`
  ${({ $color }) => $color && `color: ${$color};`}
  ${({ $margin }) => $margin && `margin: ${$margin};`}
  ${({ $interactive, $disabled }) => $interactive && incidentalsInteractive($disabled)}
`;

export const Check = styled(Checkbox)`
  margin-right: 16px;
`;

export const StyledLink = styled(Link)`
  ${interactive()}
`;

export const Anchor = styled.a`
  ${interactive()}
`;

export const FlexTextContent = styled.div`
  margin-left: 10px;
`;

export const IncidentalTextContent = styled.div`
  margin-left: 32px;
`;

export const HeaderBadge = styled(Icon)`
  margin-right: 10px;
  color: ${colors.green[500]};
  text-align: center;
`;

export const StrikeRate = styled.span`
  text-decoration: line-through;
  font-size: ${({ theme }) => theme.legacy.fontSize.xs};
  line-height: 14px;
  margin-right: 10px;
`;

export const PriorityCustomerTag = styled(Tag)`
  border-radius: 100px /* disable theming (do not replicate) */;
  background-color: ${colors.retroBlue};
  color: ${colors.white};
  font-weight: 400;
  margin-left: 5px;
  padding: 4px 8px;
`;

export const CompanySettingsTag = styled(Tag)`
  border-radius: 100px /* disable theming (do not replicate) */;
  background-color: ${colors.grey};
  color: ${colors.black};
  font-weight: 400;
  margin-left: 5px;
  padding: 4px 8px;
`;

export const ImgContainer = styled.div`
  margin-left: 32px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-left: 0;
  }
`;

export const Img = styled(Image)`
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.md};
`;

export const FlexEnrollBanner = styled(FlexProBanner)`
  .flex-pro-banner {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
`;
