import { useCallback, useEffect, useState } from "react";

// BE AWARE: This is used for WINDOW resize events and not for the element resizing.
// If you want the updated size of an element use a ResizeObserver
export function useElementSize(ref: React.RefObject<HTMLElement> | undefined) {
  const [size, setSize] = useState({ width: 0, height: 0 });

  const handleResize = useCallback(() => {
    if (!ref || !ref.current) return;

    const { offsetWidth, offsetHeight } = ref.current;
    setSize({ width: offsetWidth, height: offsetHeight });
  }, [ref]);

  useEffect(() => {
    handleResize();

    globalThis.addEventListener("resize", handleResize);

    return () => {
      globalThis.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return size;
}
