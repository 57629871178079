import { useFlightModificationsContext } from "../../ModificationsContext";

const useIsFlightCancelled = () => {
  const { reservation } = useFlightModificationsContext();

  if (!reservation) {
    return { isFlightCancelled: undefined };
  }

  const isFlightCancelled = !!reservation.isFullyCancelled;

  return { isFlightCancelled };
};

export default useIsFlightCancelled;
