import styled from "styled-components";
import { Alert } from "@hotelengine/atlas-web";

export const ValidationErrorAlert = styled(Alert)`
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 54px;
  z-index: ${({ theme }) => theme.zIndex.popover};

  p {
    white-space: nowrap;
  }
`;
