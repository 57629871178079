import styled from "styled-components";

export const RangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const DateContainer = styled.div<{ $isCheckout: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: ${({ $isCheckout }) => ($isCheckout ? "right" : "left")};
`;

export const DayContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing[2]};
  margin: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[0]}`};
`;

export const NightsPill = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[12]}`};
  border-radius: ${({ theme }) => theme.borderRadius.pill};
`;
