import styled from "styled-components";

import { colors } from "@hotel-engine/styles";
import type { TypographyProps } from "@hotelengine/atlas-web";
import { Typography } from "@hotelengine/atlas-web";

export const Tag = styled<React.FC<TypographyProps>>(Typography)<{
  active: boolean;
}>`
  padding: 0 5px;
  min-width: 16px;
  color: ${colors.white};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xs};
  background-color: ${({ active }) => (active ? colors.retroBlue : colors.newGrey500)};
  text-align: center;
`;
