import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import Typography from "@hotel-engine/common/Typography";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const ThankYouContainer = styled.div`
  text-align: center;
  width: 100%;
  ${flexbox({ direction: "column", justifyContent: "center" })};
`;

export const ThankYouHeadline = styled(Typography)<{ variant: string }>`
  margin: 0 auto 0.5em;
  color: ${colors.black};
`;

export const ThankYouCopy = styled(Typography)<{ variant: string }>`
  margin: 0 4em;
  color: ${colors.black};
`;

export const ThankYouImage = styled.img`
  max-width: 100px;
  margin: 40px auto 1em;
`;

export const ModalFooter = styled.footer`
  margin: 4em 0 0;
  text-align: right;
`;

export const CloseButton = styled(Button)`
  font-size: ${typographyMixin("body-large")};
  height: auto;
  padding: 8px 16px;
`;
