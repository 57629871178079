import { useLocation } from "@hotel-engine/lib/react-router-dom";
import { routes } from "../../../constants";
import { useBreakpoint } from "../../../hooks";
import type { TypographyVariant } from "@hotelengine/atlas-web";

export const useTypographyScaling = () => {
  const location = useLocation();
  const isPreview = !location.pathname.includes(routes.itinerary);
  const isMobile = useBreakpoint("sm", "max");

  type ComponentTypes =
    | "finePrint"
    | "body"
    | "bodyStrong"
    | "heading"
    | "title"
    | "largeTitle"
    | "link";

  const typographyVariants: { [key in ComponentTypes]: TypographyVariant } = {
    finePrint: isPreview || isMobile ? "body/xs" : "body/sm",
    body: isPreview || isMobile ? "body/sm" : "body/md",
    bodyStrong: isPreview || isMobile ? "body/sm-strong" : "body/md-strong",
    heading: isPreview || isMobile ? "heading/sm" : "heading/md",
    title: isPreview || isMobile ? "heading/md" : "heading/lg",
    largeTitle: isPreview || isMobile ? "heading/lg" : "heading/xl",
    link: isPreview || isMobile ? "link/sm" : "link/md",
  };

  return { typographyVariants, isPreview };
};
