import { Icon } from "@hotel-engine/common/Icon";
import Loader from "@hotel-engine/common/Loader";

import * as Styled from "../../styles";

type SearchActionIconProps = {
  handleClear: () => void;
  showLoadingSpinner?: boolean;
  isDirty?: boolean;
};

export const SearchActionIcon = ({
  showLoadingSpinner = false,
  isDirty = false,
  handleClear,
}: SearchActionIconProps) => {
  if (showLoadingSpinner) {
    return <Loader data-testid="loading-spinner" />;
  }
  if (isDirty) {
    return (
      <Styled.Clear onClick={handleClear} aria-label="Clear the search field" role="button">
        <Icon icon={["fal", "times"]} />
      </Styled.Clear>
    );
  }

  return null;
};
