import config from "config";
import { useAppSelector } from "store/hooks";
import { ampli } from "ampli";

import { Box, Button, Divider, Typography } from "@hotelengine/atlas-web";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import { openNewTab } from "@hotel-engine/utilities/helpers/navigationHelpers";
import type { ICarItineraryCompany } from "@hotel-engine/types/itinerary";
import {
  ModificationTypes,
  useCarModificationsContext,
} from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";

import { useCarVendorUrl } from "pages/Cars/hooks/useCarVendorUrl";
import { useTripsContext } from "pages/Trips/context";

import ModalWrapper from "../../../shared/ModficationViews/components/ModalWrapper";
import { RentalCompanyImage } from "../../styles";
import moment from "moment";

export interface IManageTripModalProps {
  onCancel: () => void;
  rentalCompany: ICarItineraryCompany;
}

const ManageTripModal = ({ onCancel, rentalCompany }: IManageTripModalProps) => {
  const user = useAppSelector((state) => state.Auth.user);
  const navigate = useNavigate();
  const { getVendorUrl } = useCarVendorUrl();
  const { reservation, isPreview, dispatch: modificationsDispatch } = useCarModificationsContext();
  const { dispatch: tripsDispatch } = useTripsContext();

  const isCarCancellable =
    !!moment().isBefore(reservation.startTime) &&
    !reservation.isFullyCancelled &&
    // The || true can be removed once this field is added to the endpoint, will always show cancel option for now, some might just error if there is no preview
    (reservation.modificationActions?.cancellable === undefined
      ? true
      : reservation.modificationActions?.cancellable);
  const { COMPANY_NAME } = useCompanyName();

  const currencyCode = user?.business?.currencyCode || "USD";

  const rentalCompanyLink = getVendorUrl(rentalCompany.companyCode);
  const cannotFindUrl = rentalCompanyLink === "";
  const goToRentalCompanyWebsite = () => {
    openNewTab(rentalCompanyLink, "_blank", navigate);

    if (user) {
      ampli.clickVisitCarRentalWebsite({
        userId: user.id,
        carBookingID: reservation.bookingNumber,
        baseUrl: rentalCompanyLink,
      });
    }
  };

  const clickCancelCar = () => {
    ampli.clickNeedToCancel({
      userId: user?.id,
      flightBookingID: reservation?.bookingNumber,
    });

    modificationsDispatch({
      type: "SET_MODIFICATION_VIEW",
      payload: ModificationTypes.Cancel,
    });

    /** If we are within the TripsContext we need to set isActive to set up the management flow for clicking other
     * Trips while a modification is active.
     */
    if (isPreview) {
      tripsDispatch({
        type: "setActiveModification",
        activeModification: {
          isActive: true,
          isNavigating: false,
        },
      });
    }

    document.getElementById("itinerary-scroll-container")?.scrollTo(0, 0);
    onCancel();
  };

  return (
    <ModalWrapper
      dataTestId="manage-trip-modal"
      closable={true}
      onCancel={onCancel}
      title="Manage trip"
    >
      <>
        <Box display="flex" flexDirection="column" gap={16} marginBottom={16}>
          {!rentalCompany?.logo ? (
            <Typography variant="body/md-strong" color="foregroundPrimary">
              {rentalCompany?.name}
            </Typography>
          ) : (
            <RentalCompanyImage src={rentalCompany?.logo} $size={60} width={60} />
          )}
          <Typography variant="body/md" color="foregroundPrimary">
            Contact the rental company directly for special requests, roadside assistance, and
            loyalty program questions.
          </Typography>
          <Typography variant="body/md" color="foregroundPrimary">
            For other assistance, please contact {COMPANY_NAME} support at{" "}
            <Box
              as="a"
              href={`tel:+${config.supportNumber(currencyCode)}`}
              color="foregroundPrimary"
              style={{ textDecorationLine: "underline", display: "inline" }}
            >
              {config.supportNumber(currencyCode)}
            </Box>
            .
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={16}>
          {!cannotFindUrl && (
            <Button
              variant="outlined"
              color="secondary"
              trailingIcon="arrow-up-right-from-square"
              style={{ width: "100%" }}
              onClick={goToRentalCompanyWebsite}
            >
              Visit {rentalCompany.name}
            </Button>
          )}
          {!!isCarCancellable && (
            <>
              <Divider variant="dotted" />
              <Button
                variant="filled"
                color="primary"
                style={{ width: "100%" }}
                onClick={clickCancelCar}
              >
                <Typography variant="body/md-strong">Need to cancel?</Typography>
              </Button>
            </>
          )}
        </Box>
      </>
    </ModalWrapper>
  );
};

export default ManageTripModal;
