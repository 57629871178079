import styled, { css } from "styled-components";

import { Modal } from "@hotel-engine/common/Modal";
import { colors } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

export const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    padding: "24px";
    width: 500px;
    ${({ title }) =>
      Boolean(title) &&
      css`
        .ant-modal-body {
          padding-top: 0;
        }
      `}
  }
  .ant-modal-close {
    ${flexbox({ alignItems: "center", justifyContent: "center" })};
    top: 24px;
    right: 24px;
    width: 32px;
    height: 32px;
    border-radius: 100px /* disable theming (do not replicate) */;
    border: 1px solid ${colors.grey[200]};
  }
  .anticon-close {
    color: ${colors.grey[700]};
  }
`;

export const BottomSheetContainer = styled.div`
  padding: 16px;
  height: 80vh;
`;

export const ModalContent = styled.main`
  height: 100%;
  margin-top: 16px;
  ${flexbox({ direction: "column", alignItems: "flex-start" })};
  ${mediaQuery(
    "md",
    "max"
  )(`
    .fixed-submit-btn {
      flex-grow: 1;

      form {
        flex-grow: 1;

        button[type="submit"] {
          margin: auto 0 80px;
        }
      }
    }
  `)}
  ${mediaQuery("lg")(`
    flex: 1 1 auto;
    height: auto;
  `)}
  .form-container {
    ${flexbox({ direction: "column", alignItems: "flex-start" })};
    width: 100%;
    height: 100%;
    overflow-y: auto;
    ${mediaQuery("lg")(`
      padding: 0;
    `)}

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  .form-title {
    width: 100%;
    ${flexbox({ direction: "column", alignItems: "flex-start" })};
    margin-bottom: 24px;
    text-align: left;
  }
`;
