import moment from "moment";
import type {
  ISupportBillingInquiry,
  ISupportCase,
  ISupportCancelRequest,
  ISupportSpecialRequest,
} from "@hotel-engine/types/supportCase";
import type {
  ICaseTypeDetails,
  ICaseTypes,
  ISupportAlertBannerIcon,
  ISupportAlertBannerStatus,
} from "./types";

export const getCaseTypeDetails = (supportCase: ISupportCase): ICaseTypeDetails => {
  const requestStatus = supportCase?.requestStatus;
  const status: string | undefined = supportCase?.status;

  const subtype = supportCase.subType as ICaseTypes;

  let alertBannerIcon: ISupportAlertBannerIcon;
  let alertBannerStatus: ISupportAlertBannerStatus;

  if (status === "Active") {
    alertBannerIcon = "info-circle";
    alertBannerStatus = "pending";
  } else if (requestStatus === "Approved") {
    alertBannerIcon = "check-circle";
    alertBannerStatus = "success";
  } else if (requestStatus === "Denied") {
    alertBannerIcon = "times-circle";
    alertBannerStatus = "error";
  } else {
    alertBannerIcon = "";
    alertBannerStatus = "";
  }

  switch (subtype) {
    case "Cancel Request":
      const cancelRequestDetails = supportCase.details as ISupportCancelRequest;
      return {
        alertBannerIcon,
        alertBannerStatus,
        ...cancelRequestCase(cancelRequestDetails, alertBannerStatus),
      };

    case "Date Change":
      return {
        alertBannerIcon,
        alertBannerStatus,
        ...dateChange(alertBannerStatus),
      };

    case "Name Change":
      return {
        alertBannerIcon,
        alertBannerStatus,
        ...nameChange(alertBannerStatus),
      };

    case "Billing Inquiry":
      const billingInquiryDetails = supportCase.details as ISupportBillingInquiry;
      return {
        alertBannerIcon,
        alertBannerStatus,
        ...billingInquiry(billingInquiryDetails, alertBannerStatus),
      };

    case "Special Request":
      const specialRequestDetails = supportCase.details as ISupportSpecialRequest;
      return {
        alertBannerIcon,
        alertBannerStatus,
        ...specialRequest(specialRequestDetails, alertBannerStatus),
      };

    default:
      return {
        alertBannerIcon,
        alertBannerStatus,
        message: "",
        description: "",
      };
  }
};

const specialRequest = (
  details: ISupportSpecialRequest,
  alertBannerStatus: ISupportAlertBannerStatus
): {
  message: string;
  description: string;
} => {
  const valuesCount = Object.values(details).reduce((acc, curr) => {
    if (curr !== false) return acc + 1;
    return acc;
  }, 0);
  const isPlural = valuesCount > 1;

  let message = "";
  let description = "";

  if (alertBannerStatus === "pending") {
    message = `We are processing ${isPlural ? "these requests" : "this request"}`;
    description = `The status of your ${
      isPlural ? "cases" : "case"
    } will be updated as soon as we hear from the property.`;
  } else if (alertBannerStatus === "error") {
    message = `${isPlural ? "These requests" : "This request"} could not be fulfilled`;
    description = `Unfortunately, due to the property's policy we are unable to fulfill ${
      isPlural ? "these requests" : "this request"
    }.`;
  } else if (alertBannerStatus === "success") {
    message = `Great news - ${
      isPlural ? "these requests" : "this request"
    } was successfully fulfilled`;
    description = "";
  }

  return { message, description };
};

const billingInquiry = (
  details: ISupportBillingInquiry,
  alertBannerStatus: ISupportAlertBannerStatus
): {
  message: string;
  description: string;
} => {
  const { refundedTo, refundedAmount } = details;
  let message = "";
  let description = "";

  const refundedAmountNumber = Number(refundedAmount);

  if (alertBannerStatus === "pending") {
    message = "";
    description = "";
  } else if (alertBannerStatus === "error" || refundedAmountNumber === 0) {
    message = "";
    description = "";
  } else if (alertBannerStatus === "success") {
    message = "Great news - this request was successfully fulfilled";
    if (refundedTo === "Travel Credits") {
      description = "Your Travel Credit is available to use immediately.";
    } else {
      description = "Please allow up to 10 business days for refunds to appear in your account.";
    }
  }

  return { message, description };
};

const nameChange = (
  alertBannerStatus: ISupportAlertBannerStatus
): {
  message: string;
  description: string;
} => {
  let message = "";
  let description = "";

  if (alertBannerStatus === "pending") {
    message = "We are processing your request";
    description = "The status of your case will be updated as soon as we hear from the property.";
  } else if (alertBannerStatus === "error") {
    message = "This request could not be fulfilled";
    description =
      "Unfortunately, due to the property's policy we are unable to fulfill this request.";
  } else if (alertBannerStatus === "success") {
    message = "Great news - this request was successfully fulfilled";
  }

  return { message, description };
};

const dateChange = (
  alertBannerStatus: ISupportAlertBannerStatus
): {
  message: string;
  description: string;
} => {
  let message = "";
  let description = "";

  if (alertBannerStatus === "pending") {
    message = "We are processing your request";
    description = "The status of your case will be updated as soon as we hear from the property.";
  } else if (alertBannerStatus === "error") {
    message = "This request could not be fulfilled";
    description =
      "Unfortunately, due to the property's policy we are unable to fulfill this request.";
  } else if (alertBannerStatus === "success") {
    message = "Great news - this request was successfully fulfilled";
  }

  return { message, description };
};

const cancelRequestCase = (
  details: ISupportCancelRequest,
  alertBannerStatus: ISupportAlertBannerStatus
): {
  message: string;
  description: string;
} => {
  const { cancelBy, nonRefundable, refundType } = details;

  let message = "";
  let description = "";

  const currentDate = moment();
  const hasPassed = moment(cancelBy).isBefore(currentDate);

  if (alertBannerStatus === "pending") {
    message = "Cancel request approvals are subject to the property’s policy";
    if (!!nonRefundable) {
      description =
        "You have booked a non-refundable room. While we cannot guarantee a refund, we will make every effort to coordinate with the room provider to secure one for you.";
    } else if (!nonRefundable && hasPassed) {
      description =
        "You've exceeded the refundable period for this booking. While we cannot guarantee a refund, we will make every effort to coordinate with the room provider to secure one for you.";
    } else {
      description =
        "While we cannot guarantee a refund, we will make every effort to coordinate with the room provider to secure one for you.";
    }
  } else if (alertBannerStatus === "error") {
    message = "Your trip could not be refunded";
    description =
      "Due to the property’s policy, we were unable to cancel your booking and issue a refund.";
  } else if (alertBannerStatus === "success") {
    message = "Great news - this request was successfully fulfilled";
    if (refundType === "Travel Credits") {
      description = "Your Travel Credit is available to use immediately.";
    } else {
      description = "Please allow up to 10 business days for refunds to appear in your account.";
    }
  }

  return { message, description };
};
