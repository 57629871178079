import { useEffect, useMemo, useState } from "react";

import { ampli } from "ampli";
import { equals, isEmpty, reject } from "ramda";
import { useAppSelector } from "store/hooks";

import type { IAction } from "../../contextLegacy";
import { useTripsContextLegacy } from "../../contextLegacy";
import useDeferredValue from "./useDeferredValue";
import preventPathTraversal from "./preventPathTraversal";

function deduplicateQueries(...queries: string[]) {
  const distinctQueries = [...new Set(queries)];

  return distinctQueries;
}

function createSearchAction(value: string[]): IAction {
  return {
    type: "filter",
    filters: [{ key: "search", value }],
  };
}

function createResetPageAction(): IAction {
  return {
    type: "pagination",
    page: 1,
  };
}

function useSearchForTrips(typedText: string) {
  const deferredValue = useDeferredValue(typedText);
  const user = useAppSelector((state) => state.Auth.user);
  const { state, dispatch } = useTripsContextLegacy();
  const [currentQuery, setCurrentQuery] = useState(state.filters.search ?? []);

  const updatedQueries = useMemo(() => {
    const trimmedInput = deferredValue.trim();
    const sanitizedQuery = preventPathTraversal(trimmedInput);

    if (isEmpty(sanitizedQuery)) return [];

    const tokens = sanitizedQuery.split(" ");
    const mergedQueries = deduplicateQueries(...tokens);

    if (user) {
      ampli.editSearchByNameOrLocationInTrips({
        typedText: sanitizedQuery,
        userId: user.id,
      });
    }

    return reject(isEmpty, mergedQueries);
  }, [deferredValue, user]);

  useEffect(() => {
    const queryDiffers = !equals(updatedQueries, currentQuery);

    if (queryDiffers) {
      const performSearch = createSearchAction(updatedQueries);
      const performResetPage = createResetPageAction();

      dispatch(performSearch);
      dispatch(performResetPage);
      setCurrentQuery(updatedQueries);
    }
  }, [updatedQueries, currentQuery, dispatch]);
}

export { createSearchAction, createResetPageAction };
export default useSearchForTrips;
