/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
// copied from https://raw.githubusercontent.com/LogRocket/logrocket-fuzzy-search-sanitizer/master/src/index.ts
// Recommended by LogRocket here https://docs.logrocket.com/reference/network
// The types were out of date and it was small so I inlined it.

import deparam from "deparam";

interface ILogRocketRequest {
  reqId: string;
  url: string;
  headers: { [key: string]: string | undefined };
  body?: string;
  method: string;
  referrer?: string;
  mode?: string;
  credentials?: string;
}

interface ILogRocketResponse {
  reqId: string;
  status?: number;
  headers: { [key: string]: string | undefined };
  body?: string;
  method: string;
}

type INetworkRequestResponse = {
  reqId: string;
  headers: { [key: string]: string | undefined };
  body?: string;
  method: string;
};

export class LogrocketFuzzySearch {
  public static setup(fieldsToRedact: string[]) {
    const instance = new LogrocketFuzzySearch(fieldsToRedact);

    return {
      requestSanitizer: instance.requestSanitizer.bind(instance),
      responseSanitizer: instance.responseSanitizer.bind(instance),
    };
  }

  public fields: string[] = [];

  constructor(privateFields: string[]) {
    this.fields = privateFields;
  }

  public requestSanitizer(request: ILogRocketRequest): object | any {
    // avoid parsing GET requests as there will be no body
    if (request.method === "GET") {
      return request;
    }

    return this._networkHandler(request);
  }

  public responseSanitizer(response: ILogRocketResponse): object | any {
    return this._networkHandler(response);
  }

  private _networkHandler(networkRequestResponse: INetworkRequestResponse) {
    const { body, headers } = networkRequestResponse;
    const requestContentType: string = headers && (headers["Content-Type"] || "");
    const isUrlEncodedRequest: boolean = requestContentType.includes("form-urlencoded");
    let parsedBody: object;

    if (headers) {
      this._search(headers);
      networkRequestResponse.headers = headers;
    }

    if (!body) {
      return networkRequestResponse;
    }

    try {
      parsedBody = isUrlEncodedRequest ? deparam(body) : JSON.parse(body);

      this._search(parsedBody);
    } catch (error) {
      return networkRequestResponse;
    }

    networkRequestResponse.body = JSON.stringify(parsedBody);

    return networkRequestResponse;
  }

  private _search(toSearch: any = {}) {
    // iterate over collection of objects ex. [{}, ...]
    if (Array.isArray(toSearch)) {
      toSearch.forEach((item) => this._search(item));
    } else {
      for (const key in toSearch) {
        if (Object.prototype.hasOwnProperty.call(toSearch, key)) {
          const keyName = toSearch[key];

          /*
            Objects with the following shape:
              {
                type: 'email',
                value: 'secret@ex.com'
              }
            where type/value keynames are generic and instead
            the value matching the type keyname should be masked.
          */
          const isTypeValuePair = key === "type" && "value" in toSearch;

          if (typeof keyName === "object") {
            if (!isTypeValuePair) {
              this._search(keyName);
            }
          }

          if (isTypeValuePair) {
            this._mask(toSearch, toSearch.type, "value");
          } else {
            this._mask(toSearch, key);
          }
        }
      }
    }
  }

  private _mask(toMask: object, searchKeyName: string, maskKeyName?: string) {
    maskKeyName = maskKeyName || searchKeyName;

    const isSensitiveFieldName = this._match(searchKeyName);

    if (isSensitiveFieldName) {
      toMask[maskKeyName] = "*";
    }
  }

  private _match(keyName = ""): boolean {
    const { fields } = this;
    const normalizedKeyName = keyName.toLowerCase();

    return fields.some((field) => normalizedKeyName.indexOf(field.toLowerCase()) > -1);
  }
}
