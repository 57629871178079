import { Box, Icon, Typography } from "@hotelengine/atlas-web";
import { useAppSelector } from "store/hooks";
import { flexCoverageStartDate, formatDisplayDate } from "@hotel-engine/app/RefundPolicy/helpers";
import { getCancelDateInfo } from "@hotel-engine/utilities/helpers/getCancelDateInfo";

import * as Styled from "./styles";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import moment from "moment";
import { useTypographyScaling } from "../../../hooks/useTypographyScaling";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { useFlex } from "@hotel-engine/hooks";
import { useFlexCutoffTime } from "../../../../../hooks/useFlexCutoffTime";

const ItineraryRefundPolicy = () => {
  const user = useAppSelector((state) => state.Auth.user);
  const { flexShortenCutoff, buildDateMarkers } = useFlex();
  const { flexCutoffTime } = useFlexCutoffTime();
  const isFlexPolicy24hEnabled = useIsFeatureFlagOn("flex-policy-24h");
  const preferredDateFormat = user?.business.preferredDateFormat || "mdy";
  const { reservation, isPreview } = useModificationsContext();
  const { typographyVariants } = useTypographyScaling();

  const dateRange = buildDateMarkers(reservation);

  const isFlexAfterExpiration =
    reservation.flexEnabled &&
    moment().startOf("day").isAfter(flexShortenCutoff(reservation.checkOut));

  const noRefundAvailableCopy = isFlexAfterExpiration
    ? "Flex has expired for this trip."
    : reservation.flexEnabled && !reservation.modificationActions.flexShortenable
      ? "Flex coverage is limited to one trip length change per booking."
      : "No refund available for this reservation.";

  const { refundPolicy } = getCancelDateInfo({
    flexEnabled: false,
    cancelBy: reservation.cancelBy,
    propertyTimezone: reservation.propertyTimezone,
    checkIn: reservation.checkIn,
    preferredDateFormat,
    flexProEnrolled: reservation.flexType === "flex_pro",
    flexCutoffTime:
      dateRange?.policyExpiration || (reservation.flexEnabled && dateRange)
        ? flexCutoffTime
        : undefined,
  });

  const IconContainer = ({ iconName, backgroundColor, changeColor = false }) => {
    return (
      <Styled.IconContainer backgroundColor={backgroundColor}>
        <Icon
          name={iconName}
          size="md"
          color={changeColor ? "sentimentPositiveDefault" : "foregroundPrimary"}
        />
      </Styled.IconContainer>
    );
  };

  const RefundNotAvailable = () => {
    return !dateRange ? (
      <Styled.RefundContent>
        <Styled.RefundRow>
          <IconContainer iconName={"minus"} backgroundColor={"accentBlueLightest"} />
          <Styled.InfoContainer>
            <Typography variant={typographyVariants.bodyStrong}>Refund not available</Typography>
            <Typography variant={typographyVariants.finePrint} color="foregroundTertiary">
              {noRefundAvailableCopy}
            </Typography>
          </Styled.InfoContainer>
        </Styled.RefundRow>
      </Styled.RefundContent>
    ) : null;
  };

  const RefundAvailable = () => {
    return !!dateRange?.policyExpiration || (!!reservation.flexEnabled && !!dateRange) ? (
      <Styled.RefundContent>
        {!!dateRange?.policyExpiration && (
          <>
            <Styled.RefundRow>
              <IconContainer
                iconName={"dollar-sign"}
                backgroundColor={"sentimentPositiveMinimal"}
              />
              <Styled.InfoContainer>
                <Typography variant={typographyVariants.bodyStrong} color="foregroundPrimary">
                  Refundable
                </Typography>
                <Typography variant={typographyVariants.body} color="foregroundPrimary">
                  Before {refundPolicy}
                </Typography>
                <Typography variant={typographyVariants.finePrint} color="foregroundTertiary">
                  Receive a full refund to your payment method.
                </Typography>
              </Styled.InfoContainer>
            </Styled.RefundRow>
            {!!reservation.flexEnabled && <Styled.Divider />}
          </>
        )}

        {!!reservation.flexEnabled && !!dateRange && (
          <Styled.RefundRow>
            <IconContainer
              iconName={"he-flex"}
              changeColor={true}
              backgroundColor={"sentimentPositiveMinimal"}
            />
            <Styled.InfoContainer>
              <Typography variant={typographyVariants.bodyStrong} color="foregroundPrimary">
                Flex Coverage
              </Typography>
              {!!dateRange && (
                <Typography variant={typographyVariants.body} color="foregroundPrimary">
                  {`${formatDisplayDate(
                    flexCoverageStartDate(reservation, dateRange),
                    preferredDateFormat
                  )} - ${formatDisplayDate(dateRange?.end, preferredDateFormat)}`}
                </Typography>
              )}
              <Typography variant={typographyVariants.finePrint} color="foregroundTertiary">
                Receive travel credit for unused nights with Flex Coverage,{" "}
                {isFlexPolicy24hEnabled
                  ? "even up to 24 hours after you check out"
                  : "even up to 48 hours after a trip"}
                .
              </Typography>
            </Styled.InfoContainer>
          </Styled.RefundRow>
        )}
      </Styled.RefundContent>
    ) : null;
  };

  return (
    <Box marginTop={isPreview ? 24 : 40} marginBottom={isPreview ? 24 : 40}>
      <Styled.ContainerTitle>
        <Typography variant={typographyVariants.title} as="h3">
          Refund policy
        </Typography>
      </Styled.ContainerTitle>

      <RefundNotAvailable />
      <RefundAvailable />
    </Box>
  );
};

export default ItineraryRefundPolicy;
