import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";
import * as Styled from "./styles";

export const PrivacyPolicyDisclaimer = ({
  $top,
  $right,
  $bottom,
  $left,
}: Styled.IPrivacyPolicyDisclaimer) => {
  const { COMPANY_URL } = useCompanyName();
  return (
    <Styled.PrivacyPolicyDisclaimer $top={$top} $right={$right} $bottom={$bottom} $left={$left}>
      <p>
        See how Hotel Engine uses your data in our
        <a href={`https://www.${COMPANY_URL}/privacy-policy/`} target="_blank" rel="noreferrer">
          Privacy Policy.
        </a>
      </p>
    </Styled.PrivacyPolicyDisclaimer>
  );
};
