import * as Styled from "./styles";

export interface IModalNavBar {
  onGoBack?: () => void;
  onClose?: () => void;
}

export const ModalNavBar = ({ onGoBack, onClose }: IModalNavBar) => {
  return (
    <Styled.ModalNavBarContainer>
      {!!onGoBack && (
        <Styled.LeftWrapper>
          <Styled.GoBackIconButton onClick={() => onGoBack()}>
            <Styled.GoBackIcon icon={["far", "arrow-left"]} />
            &nbsp; Go Back
          </Styled.GoBackIconButton>
        </Styled.LeftWrapper>
      )}
      {!!onClose && (
        <Styled.RightWrapper>
          <Styled.CloseIconButton onClick={() => onClose()} data-testid="close-modal">
            <Styled.CloseIcon icon={["fal", "times"]} />
          </Styled.CloseIconButton>
        </Styled.RightWrapper>
      )}
    </Styled.ModalNavBarContainer>
  );
};
