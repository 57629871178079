import styled from "styled-components";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const OptionContainer = styled.div`
  ${flexbox({ alignItems: "center" })}
  height: 56px;
  padding: 10px;
  box-sizing: border-box;
  color: inherit;
  margin-top: 2px;
  transition: background-color 0.15s;
  border-radius: ${({ theme }) => theme.borderRadius.xs};

  &:active {
    background-color: ${({ theme }) => theme.colors.backgroundStrong} ;
  }
`;

export const OptionIcon = styled.div`
  ${flexbox({ justifyContent: "center", alignItems: "center" })}
  padding: 10px;
  min-width: 35px;
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  background-color: ${({ theme }) => theme.colors.backgroundStrong};
`;

export const OptionDescription = styled.div`
  padding: 10px;
  &:hover {
    overflow-x: auto;
  }
`;
