import type { FormEvent, MouseEvent } from "react";
import { useEffect, useState } from "react";

import { useFormikContext } from "formik";
import type { Moment } from "moment";
import moment from "moment";
import { CalendarDay, DateRangePicker, DayPickerRangeController } from "react-dates";
import {
  END_DATE,
  HORIZONTAL_ORIENTATION,
  START_DATE,
  VERTICAL_SCROLLABLE,
} from "react-dates/constants";

import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import FullscreenModal from "@hotel-engine/common/FullscreenModal";
import { earliestAllowedCheckinDate } from "@hotel-engine/constants";
import { dateRangePickerShortFormats } from "@hotel-engine/constants/dateRangePickerSelections";
import { useBreakpoint } from "@hotel-engine/hooks";
import { useFormatDate } from "@hotel-engine/hooks/useFormatDate";
import { useTimeZoneQuery } from "@hotel-engine/react-query/timezone/useTimeZoneQuery";
import config from "config";
import { useAppSelector } from "store/hooks";
import { selectSearchId } from "store/Search/SearchResults/selectors";

import * as Styled from "./styles";
import type { IExpressBookDatesForm } from "@hotel-engine/types/expressBook";
import { useExpressBook } from "pages/Checkout/LegacyLodging/ExpressBookModal/hooks/useExpressBook";
import { EB_DATE_PICKER_TOGGLE_BTN_ID } from "pages/Checkout/LegacyLodging/ExpressBookModal/constants";
import { ampli } from "ampli";

type FocusedInput = END_DATE | START_DATE | null;

interface IDateSelectorInputProps {
  className?: string;
  inputWidth?: string;
  margin?: string;
  originalDates: {
    checkIn: string | null;
    checkOut: string | null;
  };
  handleMobileSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
}

export const ExpressBookDateSelectorInput = ({
  className,
  inputWidth,
  margin,
  originalDates,
  handleMobileSubmit,
}: IDateSelectorInputProps) => {
  const { expressBookContractData } = useExpressBook();
  const [focusedInput, setFocusedInput] = useState<FocusedInput>(null);
  const searchId = useAppSelector(selectSearchId) ?? undefined;
  const [today, setToday] = useState(moment());
  const {
    business: { preferredDateFormat },
  } = useAppSelector((state) => state.Auth.user) || {
    business: { preferredDateFormat: "mdy" },
  };

  const isMobile = useBreakpoint("md", "max");

  const { values, setFieldValue, errors, setFieldTouched, submitCount } =
    useFormikContext<IExpressBookDatesForm>();
  const checkInError = !!errors.checkin && submitCount > 0;
  const checkOutError = !!errors.checkout && submitCount > 0;

  const checkInDate = useFormatDate(values.checkin, "ddd, MMM D, YYYY");
  const checkOutDate = useFormatDate(values.checkout, "ddd, MMM D, YYYY");
  const mobileCheckInDate = useFormatDate(
    values.mobileIntermediateCheckin || values.checkin,
    "ddd, MMM D, YYYY"
  );
  const mobileCheckOutDate = useFormatDate(
    values.mobileIntermediateCheckout || values.checkout,
    "ddd, MMM D, YYYY"
  );
  const triggerClickSelectDatesInExpressBook = (startDate, endDate) => {
    ampli.clickSelectDatesInExpressBook({
      searchId: searchId,
      newCheckIn: startDate?.format("YYYY-MM-DD"),
      newCheckOut: endDate?.format("YYYY-MM-DD"),
      originalCheckIn: originalDates.checkIn || "",
      originalCheckOut: originalDates.checkOut || "",
    });
  };

  const triggerClickEditDatesInExpressBook = () => {
    ampli.clickEditDatesInExpressBook({
      searchId: searchId,
      checkIn: checkInDate || "",
      checkOut: checkOutDate || "",
    });
  };

  const superLateCheckinsEnabled = useIsFeatureFlagOn("super_late_checkins");

  const { data: timeZone } = useTimeZoneQuery({
    latitude: expressBookContractData?.property?.latitude,
    longitude: expressBookContractData?.property?.longitude,
  });

  useEffect(() => {
    // Allow users to select a super late check-in based on the earliest allowed check-in date
    if (superLateCheckinsEnabled) {
      setToday(earliestAllowedCheckinDate);
      // Otherwise, set to the property's local time if available
    } else if (timeZone) {
      setToday(moment(timeZone.localTime));
    }
  }, [superLateCheckinsEnabled, timeZone]);

  /* Disable any dates that are:
    - Before today's date
    - Outside of the max search range
    - Any checkout date past the max number of nights
    */
  const isDateOutOfRange = (day: Moment, arrow?: "checkOut" | "checkIn") => {
    let maxDate: moment.Moment;
    if (values.checkin && (focusedInput === END_DATE || arrow === "checkOut")) {
      maxDate = values.checkin.clone().add(config.maxNights, "days");
    } else {
      maxDate = today.clone().add(config.maxSearchRange, "days");
    }

    // For the checkIn arrow, ensure that the new date will not cause the number of nights to exceed our maximum
    if (
      arrow === "checkIn" &&
      values.checkout &&
      values.checkout.diff(day, "days") > config.maxNights
    ) {
      return true;
    }

    const dayIsBeforeRange = moment(day.format("MM-DD-YYYY")).isBefore(
      moment(today).format("MM-DD-YYYY"),
      "day"
    );
    const dayIsAfterRange = day.isAfter(maxDate, "day");

    return dayIsBeforeRange || dayIsAfterRange;
  };

  /*
   * Note: Since we don't apply changes from the mobile modal until
   * the done button is clicked, we just cache the values in
   * the "intermediate" fields and apply them to the true
   * date values when "done" is clicked
   */
  const calendarChangeHandler = (
    startDate: Moment | null,
    endDate: Moment | null,
    usingMobileModal?: boolean
  ) => {
    if (startDate && endDate && endDate.diff(startDate, "days") > config.maxNights) {
      if (!usingMobileModal) {
        setFieldValue("checkout", null);
      }
      setFieldValue("mobileIntermediateCheckout", null);
    } else {
      if (!usingMobileModal) {
        setFieldValue("checkout", endDate);
      }
      setFieldValue("mobileIntermediateCheckout", endDate);
      // Here we must setFieldTouched in a timeout so that the value isn't out of date
      // https://github.com/formium/formik/issues/2059#issuecomment-612733378
      setTimeout(() => {
        if (!usingMobileModal) {
          setFieldTouched("checkout", true);
        }
        setFieldTouched("mobileIntermediateCheckout", true);
      });
    }
    if (!usingMobileModal) {
      setFieldValue("checkin", startDate);
    }
    setFieldValue("mobileIntermediateCheckin", startDate);
    setTimeout(() => {
      if (!usingMobileModal) {
        setFieldTouched("checkin", true);
      }
      setFieldTouched("mobileIntermediateCheckin", true);
    });
  };

  const handleDateClick = (input: START_DATE | END_DATE) => {
    if (!focusedInput) {
      triggerClickEditDatesInExpressBook();
    }
    setFocusedInput(input);
  };

  if (isMobile) {
    const clickCheckOut = (e: MouseEvent) => {
      e.stopPropagation();
      setFocusedInput(END_DATE);
    };

    const dates = (
      <Styled.MobileDateSelectorInput
        onClick={() => handleDateClick(START_DATE)}
        className={className}
        inputWidth={inputWidth}
        margin={margin}
        id={EB_DATE_PICKER_TOGGLE_BTN_ID}
      >
        <Styled.MobileCalendarIcon icon={["far", "calendar"]} />
        <Styled.ButtonReset
          onClick={() => setFocusedInput(START_DATE)}
          data-testid="checkInButton"
          type="button"
        >
          {checkInDate ?? "Check In"}
        </Styled.ButtonReset>
        <Styled.SeparatorArrow />
        <Styled.CheckOutDate
          onClick={clickCheckOut}
          $checkOutError={checkOutError}
          data-testid="checkOutButton"
          type="button"
        >
          {checkOutDate ?? "Check Out"}
        </Styled.CheckOutDate>
      </Styled.MobileDateSelectorInput>
    );

    const mobileCalendar = (
      <Styled.DateCalendar isFocused={true}>
        <DayPickerRangeController
          startDate={values.mobileIntermediateCheckin}
          endDate={values.mobileIntermediateCheckout}
          onDatesChange={({ startDate, endDate }) => {
            triggerClickSelectDatesInExpressBook(startDate, endDate);
            calendarChangeHandler(startDate, endDate, true);
          }}
          focusedInput={focusedInput}
          onFocusChange={(focus: FocusedInput) => setFocusedInput(focus)}
          initialVisibleMonth={() => today}
          isOutsideRange={isDateOutOfRange}
          noBorder
          orientation={VERTICAL_SCROLLABLE}
          numberOfMonths={15}
          hideKeyboardShortcutsPanel
          keepOpenOnDateSelect
          navPrev={<div />}
          navNext={<div />}
        />
      </Styled.DateCalendar>
    );

    return (
      <>
        {dates}
        <FullscreenModal
          visible={!!focusedInput}
          title="Select dates"
          hasDoneButton
          modifiedZIndex={99999}
          onClose={(closeFromDone) => {
            if (closeFromDone) {
              setFieldValue("checkin", values.mobileIntermediateCheckin);
              setFieldValue("checkout", values.mobileIntermediateCheckout);
              handleMobileSubmit();
            } else {
              setFieldValue("mobileIntermediateCheckin", values.checkin);
              setFieldValue("mobileIntermediateCheckout", values.checkout);
            }
            setFocusedInput(null);
          }}
        >
          <Styled.SelectedDatesSection>
            <Styled.CalendarIcon icon={["far", "calendar"]} />
            <Styled.SelectedDate onClick={() => handleDateClick(START_DATE)}>
              <Styled.DateType>Check-in</Styled.DateType>
              <Styled.Date isFocused={focusedInput === START_DATE}>
                {mobileCheckInDate ?? "Select"}
              </Styled.Date>
            </Styled.SelectedDate>
            <Styled.Hyphen>-</Styled.Hyphen>
            <Styled.SelectedDate onClick={() => handleDateClick(END_DATE)}>
              <Styled.DateType>Checkout</Styled.DateType>
              <Styled.Date isFocused={focusedInput === END_DATE}>
                {mobileCheckOutDate ?? "Select"}
              </Styled.Date>
            </Styled.SelectedDate>
          </Styled.SelectedDatesSection>
          {mobileCalendar}
        </FullscreenModal>
      </>
    );
  }

  /* Custom rendering of calendar days, this logic matches  our legacy dashboard search datepicker and
    is needed to render the number of days tooltip. */
  const renderCalendarDay = (day) => {
    const onDayMouseEnter = (hoveredDay: Moment, e: MouseEvent<HTMLTableDataCellElement>) => {
      let nights = 0;

      if (values.checkin) {
        nights = hoveredDay.diff(values.checkin, "days");
      }

      if (nights > 0 && focusedInput === END_DATE && !isDateOutOfRange(hoveredDay)) {
        (e.target as HTMLTableDataCellElement).setAttribute(
          "data-number-of-nights",
          `${nights} night${nights > 1 ? "s" : ""}`
        );

        (e.target as HTMLTableDataCellElement).setAttribute("data-active-tooltip", "true");
      }

      return day.onDayMouseEnter(hoveredDay);
    };

    const onDayMouseLeave = (notHoveredDay: Moment, e: MouseEvent<HTMLTableDataCellElement>) => {
      (e.target as HTMLTableDataCellElement).setAttribute("data-active-tooltip", "false");

      return day.onDayMouseLeave(notHoveredDay);
    };

    return (
      <CalendarDay {...day} onDayMouseEnter={onDayMouseEnter} onDayMouseLeave={onDayMouseLeave} />
    );
  };

  return (
    <Styled.DateSelectorInput className={className} inputWidth={inputWidth} margin={margin}>
      <Styled.DateRangePicker checkInError={checkInError} checkOutError={checkOutError}>
        <DateRangePicker
          startDate={values.checkin}
          startDateId="date-range-picker-start"
          startDatePlaceholderText="Check In"
          endDatePlaceholderText="Check Out"
          endDate={values.checkout}
          endDateId="date-range-picker-end"
          renderCalendarDay={(day) => renderCalendarDay(day)}
          hideKeyboardShortcutsPanel
          isOutsideRange={isDateOutOfRange}
          displayFormat={
            focusedInput ? dateRangePickerShortFormats[preferredDateFormat] : "ddd, MMM D, YYYY"
          }
          noBorder
          onDatesChange={({ startDate, endDate }) => {
            triggerClickSelectDatesInExpressBook(startDate, endDate);
            calendarChangeHandler(startDate, endDate);
          }}
          focusedInput={focusedInput}
          onFocusChange={(focus: FocusedInput) => {
            if (!!focus && focusedInput === null) {
              triggerClickEditDatesInExpressBook();
            }
            setFocusedInput(focus);
          }}
          verticalSpacing={84}
          orientation={HORIZONTAL_ORIENTATION}
          customArrowIcon={<Styled.SeparatorArrow />}
          customInputIcon={
            <Styled.DateSelectorIcon id={EB_DATE_PICKER_TOGGLE_BTN_ID} icon={["far", "calendar"]} />
          }
        />
      </Styled.DateRangePicker>
    </Styled.DateSelectorInput>
  );
};
