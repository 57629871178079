import { Box, Select, SelectItem, Typography } from "@hotelengine/atlas-web";
import { useTripsContext } from "pages/Trips/context";
import { sortCalendarOptions } from "pages/Trips/TripsCalendarView/helpers";

const SortingBy = () => {
  const { dispatch } = useTripsContext();

  const handleSorting = (value: string) => {
    dispatch({
      type: "sortCalendar",
      sortCalendar: `${value}`,
    });
  };

  return (
    <Box>
      <Typography variant="uppercase/xs-strong" color="foregroundPrimary">
        Sort by
      </Typography>
      <Box display="flex" gap={12} flexDirection="column" marginTop={8}>
        <Select
          aria-label="Sort trip results"
          data-testid="calendar-sort"
          placeholder="First Name: A-Z"
          style={{ width: "100%" }}
          onValueChange={(value) => handleSorting(value)}
        >
          {sortCalendarOptions.map(({ label, sort }) => (
            <SelectItem data-testid={sort} value={sort} key={label}>
              {label}
            </SelectItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

export default SortingBy;
