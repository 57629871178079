import { Alert, Typography } from "@hotelengine/atlas-web";
import { useCurrencyCode } from "@hotel-engine/hooks";
import { formatCurrency } from "@hotel-engine/utilities";
import {
  type IModificationInfo,
  useBaseModificationsContext,
} from "@hotel-engine/app/ItineraryContent/ModificationsContext";

import { RefundType } from "../CancelRouter/hooks/useCancelRefundCalculator";
import {
  CANCELLATION_REFUND_TITLE,
  CAR_REFUND_TITLE,
  FLIGHT_REFUND_CREDIT,
  FLIGHT_REFUND_ORIGINAL,
  FLIGHT_REFUND_TITLE,
  FLIGHT_REFUND_UNKNOWN,
  REFUND_INFO_ORIGINAL,
  REFUND_INFO_TRAVEL_CREDIT,
} from "../CancelRouter/constants";
import { FlightRefundType } from "../CancelRouter/hooks/usePreviewFlightRefund";
import { CarRefundType } from "../CancelRouter/hooks/usePreviewCarRefund";

export const RefundInfo = () => {
  const {
    state: { modificationInfo },
  } = useBaseModificationsContext();
  const currencyCode = useCurrencyCode();

  const formattedRefundAmout = formatCurrency(
    String(modificationInfo.refundAmount),
    true,
    currencyCode
  );

  const penaltyFee = modificationInfo.penaltyFee;

  const penaltyFeeFormatted = formatCurrency(String(penaltyFee), true, currencyCode);

  const penaltyDescription =
    penaltyFee && penaltyFee > 0
      ? `, deducting a ${penaltyFeeFormatted} penalty as per the cancellation policy`
      : "";

  const REFUND_CAR_DESCRIPTION = `
          We've refunded your reservation amount to your original payment method${penaltyDescription}. Please allow 7 to 10 business days for the refund to be credited to your account.
        `;

  const refundTypeMap = {
    flight: {
      [FlightRefundType.AirlineCredit]: FLIGHT_REFUND_CREDIT,
      [FlightRefundType.OriginalFormOfPayment]: FLIGHT_REFUND_ORIGINAL,
      [FlightRefundType.Unknown]: FLIGHT_REFUND_UNKNOWN,
    },
    lodging: {
      [RefundType.TravelCredit]: REFUND_INFO_TRAVEL_CREDIT,
      [RefundType.OriginalFormOfPayment]: REFUND_INFO_ORIGINAL,
    },
    car: {
      [CarRefundType.OriginalFormOfPayment]: REFUND_CAR_DESCRIPTION,
      [CarRefundType.Unknown]: REFUND_CAR_DESCRIPTION,
      [CarRefundType.Nonrefundable]: REFUND_CAR_DESCRIPTION,
    },
  };

  type RefundTitleMap = {
    [K in NonNullable<IModificationInfo["bookingType"]>]: {
      [key: string]: string;
    };
  };

  const refundTitleMap: RefundTitleMap = {
    lodging: {
      [RefundType.TravelCredit]: CANCELLATION_REFUND_TITLE,
      [RefundType.OriginalFormOfPayment]: CANCELLATION_REFUND_TITLE,
    },
    flight: {
      [FlightRefundType.AirlineCredit]: FLIGHT_REFUND_TITLE,
      [FlightRefundType.OriginalFormOfPayment]: FLIGHT_REFUND_TITLE,
      [FlightRefundType.Unknown]: "",
    },
    car: {
      [CarRefundType.OriginalFormOfPayment]: CAR_REFUND_TITLE,
      [CarRefundType.Unknown]: CAR_REFUND_TITLE,
      [CarRefundType.Nonrefundable]: CAR_REFUND_TITLE,
    },
  };

  const getPaymentDescription = () => {
    if (modificationInfo.refundType === FlightRefundType.AirlineCredit) {
      return " in airline credit";
    } else {
      return modificationInfo.refundType === RefundType.TravelCredit ? " in travel credit" : "";
    }
  };

  const bookingType = modificationInfo.bookingType;

  if (!modificationInfo.refundType || bookingType === null) return null;

  const title = refundTitleMap[bookingType][modificationInfo.refundType];
  const message = refundTypeMap[bookingType][modificationInfo.refundType];

  return (
    <Alert sentiment="positive" title={title}>
      <Typography variant="body/md" color="foregroundPrimary" as="p" marginBottom={6}>
        {message}
      </Typography>
      {!(modificationInfo.refundType === FlightRefundType.Unknown) && (
        <Typography variant="body/md" color="foregroundPrimary">
          Total refund:{" "}
          <Typography
            variant="body/md-strong"
            color="foregroundPrimary"
            data-testid="refund-total-success"
          >
            {formattedRefundAmout}
            {getPaymentDescription()}
          </Typography>
        </Typography>
      )}
    </Alert>
  );
};
