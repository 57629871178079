import { Select, SelectOption } from "@hotel-engine/common/Select";
import type { ILinkedAuthStrategy } from "@hotel-engine/types/authStrategy";

import * as Styled from "./styles";

interface IBusinessSelectProps {
  authStrategies: ILinkedAuthStrategy[];
  handleBusinessSelect: (strategyIndex: number) => void;
}

const BusinessSelect = ({ authStrategies, handleBusinessSelect }: IBusinessSelectProps) => (
  <Styled.FormItem colon={false} htmlFor="business-select" label="Which business do you work with?">
    <Select
      id="business-select"
      data-testid="business-select"
      getPopupContainer={(trigger) => trigger}
      onChange={handleBusinessSelect}
      placeholder="Select business"
    >
      {authStrategies.map((strategy, _index) => (
        <SelectOption value={_index} key={`${strategy.businessId}_${strategy.displayName}`}>
          {strategy.displayName}
        </SelectOption>
      ))}
    </Select>
  </Styled.FormItem>
);

export default BusinessSelect;
