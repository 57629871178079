import styled, { css } from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;

  ${({ theme }) => {
    return css`
      @media screen and (max-width: ${theme.breakpoints.md}) {
        flex-direction: column;
        align-items: center;

        button {
          max-width: 350px;
          width: 100%;
        }
      }
    `;
  }}
`;
