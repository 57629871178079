import { Typography } from "@hotelengine/atlas-web";
import * as Styled from "./styles";
import { useBreakpoint } from "@hotel-engine/hooks";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";

const MarketingBanners = () => {
  const isExtraLarge = useBreakpoint("xl");
  const { COMPANY_NAME } = useCompanyName();

  return (
    <a target="_blank" rel="noreferrer" href="https://www.hotelengine.com/social-responsibility/">
      <Styled.MarketingBannerContainer>
        <Styled.CSRLogo
          src={"/assets/HE_CSR_logo.svg"}
          alt={`${COMPANY_NAME} Corporate Social Responsibility Logo`}
        />
        {/* hold on till rebrand rollout */}
        {/* <Box display="flex" justifyContent="center" marginBottom={16}>
          <BrandLogo name={COMPANY_LOGO} />
        </Box> */}
        <Typography
          as="h1"
          display="block"
          variant={isExtraLarge ? "heading/sm" : "heading/xs"}
          color="foregroundPrimary"
          style={{ textAlign: "center" }}
        >
          Your booking can make a positive impact.
        </Typography>
        <Typography
          as="p"
          display="block"
          variant={isExtraLarge ? "body/sm" : "body/xs"}
          color="foregroundPrimary"
          style={{ textAlign: "center" }}
        >
          We support non-profit organizations in the areas of environment, women and children, and
          education. Together, with each booking, we can continue making a difference.
        </Typography>
      </Styled.MarketingBannerContainer>
    </a>
  );
};

export default MarketingBanners;
