import type { ITripExtensions, IReservationBase } from "@hotel-engine/types/reservation";
import { useExtendedQuery } from "@hotel-engine/react-query/useExtendedQuery";
import { endpoints } from "@hotel-engine/react-query/constants";
import { useApi } from "@hotel-engine/react-query/useApi";
import useAccountToken from "./useAccountToken";

function useSubsequentBookings({ subsequentBookings }: ITripExtensions) {
  const get = useApi("get");
  const token = useAccountToken();

  return useExtendedQuery([endpoints.reservations, subsequentBookings], async () => {
    const reservations = [] as IReservationBase[];

    for await (const id of subsequentBookings) {
      const reservation = await get<IReservationBase>(
        `${endpoints.reservations}/${id}`,
        { id },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      reservations.push(reservation);
    }

    return reservations;
  });
}

export default useSubsequentBookings;
