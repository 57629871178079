import { useState } from "react";

import { formatCurrency } from "@hotel-engine/utilities";
import { Box, Typography } from "@hotelengine/atlas-web";

import { useTripsContext } from "pages/Trips/context";

import * as Styled from "./styles";

const PriceRange = () => {
  const {
    state: { mostExpensiveTrip, leastExpensiveTrip },
    dispatch,
  } = useTripsContext();

  const [displayMin, setDisplayMin] = useState(leastExpensiveTrip || 0);
  const [displayMax, setDisplayMax] = useState(mostExpensiveTrip);

  const handleSliderChange = (value: [number, number]) => {
    const [min, max] = value;
    setDisplayMin(min);
    setDisplayMax(max);
  };

  const handleAfterSliderChange = (value: [number, number]) => {
    const [min, max] = value;
    setDisplayMin(min);
    setDisplayMax(max);

    dispatch({
      type: "filter",
      filters: [
        { key: "totalGt", value: min },
        { key: "totalLt", value: max },
      ],
    });
  };

  const formattedPriceRange = `${formatCurrency(displayMin, false)} - ${formatCurrency(
    displayMax,
    false
  )}`;

  return (
    <Box>
      <Typography variant="uppercase/xs-strong" color="foregroundPrimary">
        Price
      </Typography>
      <Box display="flex" flexDirection="column" marginTop={8}>
        <Box display="flex" gap={8} justifyContent="space-between">
          <Typography variant="heading/md" color="foregroundPrimary">
            {formattedPriceRange}
          </Typography>
        </Box>
        <Styled.Slider
          defaultValue={[displayMin, displayMax]}
          minValue={leastExpensiveTrip || 0}
          maxValue={mostExpensiveTrip}
          onValueChange={handleSliderChange}
          onValueCommit={handleAfterSliderChange}
          variant="pill"
        />
      </Box>
    </Box>
  );
};

export default PriceRange;
