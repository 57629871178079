import { Box, Icon } from "@hotelengine/atlas-web";
import { usePostCheckoutActions } from "@hotel-engine/contexts";

import { useModificationsContext } from "../../../../../ModificationsContext";
import { usePastCancelledActions } from "../PastCancelledActions/usePastCancelledActions";
import * as Styled from "./styles";

const ReviewProperty = () => {
  const { reservation } = useModificationsContext();
  const { dispatch: dispatchPostCheckoutActions } = usePostCheckoutActions();

  const { showReviewPropertyButton } = usePastCancelledActions(reservation);

  const handlePropertyReviewClick = () => {
    dispatchPostCheckoutActions({
      type: "INIT_POST_CHECKOUT_ACTION",
      payload: [
        {
          propertyReviewRequestId: reservation.activePropertyReviewRequestId,
          contractNumber: reservation.contractNumber,
        },
      ],
    });
  };

  if (!showReviewPropertyButton) return null;

  return (
    <Box paddingTop={32}>
      <Styled.ReviewButton
        onClick={handlePropertyReviewClick}
        trailingIcon="arrow-right"
        size="xl"
        color="everlight"
        data-testid="review-property"
      >
        <Box display="flex" alignItems="center" gap={12}>
          <Box display="flex" alignItems="center" gap={2}>
            <Icon name="star" />
            <Icon name="star" />
            <Icon name="star" />
            <Icon name="star" />
          </Box>
          Review property
        </Box>
      </Styled.ReviewButton>
    </Box>
  );
};

export default ReviewProperty;
