import { useCallback, useEffect, useState } from "react";

import { useGoogleGeocoder, usePrevious } from "@hotel-engine/hooks";
import GooglePlacesService, { type Response } from "@hotel-engine/services/GooglePlacesService";

interface IMapPosition {
  lat: number;
  lng: number;
}

type Locations = {
  pickUp: void | IMapPosition | undefined;
  dropOff: void | IMapPosition | undefined;
};

const useGoogleCarLocations = (pickupAddress: string, dropoffAddress: string) => {
  const placesService = GooglePlacesService();
  const { getLocation } = useGoogleGeocoder();

  const previousPickup = usePrevious(pickupAddress);
  const previousDropoff = usePrevious(dropoffAddress);

  const isNewListItem = previousPickup !== pickupAddress || previousDropoff !== dropoffAddress;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pickupLocationResult, setPickupLocation] = useState<Response>({
    predictions: [],
  });
  const [dropoffLocationResult, setDropoffLocation] = useState<Response>({
    predictions: [],
  });
  const [locations, setLocations] = useState<Locations>({
    pickUp: undefined,
    dropOff: undefined,
  });

  /** If the pick up or dropoff has no results and there is no error from Google, hit the Places
   * service to get results for the origin and destination airports
   */
  useEffect(() => {
    if (
      !pickupLocationResult.predictions.length ||
      !dropoffLocationResult.predictions.length ||
      (isNewListItem && !isError)
    ) {
      placesService.request(pickupAddress, setIsLoading, setPickupLocation);
      placesService.request(dropoffAddress, setIsLoading, setDropoffLocation);
    }
  }, [
    dropoffAddress,
    dropoffLocationResult.predictions.length,
    isError,
    isNewListItem,
    pickupAddress,
    pickupLocationResult.predictions.length,
    placesService,
  ]);

  const getLocations = useCallback(async () => {
    const pickupLocation = await getLocation(pickupLocationResult.predictions[0].place_id);
    const dropoffLocation = await getLocation(dropoffLocationResult.predictions[0].place_id);

    return { pickUp: pickupLocation, dropOff: dropoffLocation };
  }, [dropoffLocationResult.predictions, getLocation, pickupLocationResult.predictions]);

  /** Once we have predictions for origin and destination, we will grab the first one, which if we search by
   * the exact name of the airport from the bundles should be the correct airport nearly 100% of the time,
   * and hit the Geocoder API using the above helper to get the lat/lng of each airport which gets returned
   * from the hook
   */
  useEffect(() => {
    if (pickupLocationResult.predictions.length && dropoffLocationResult.predictions.length) {
      getLocations()
        .then((res) => {
          setLocations({
            pickUp: {
              lat: res.pickUp?.latitude || 0,
              lng: res.pickUp?.longitude || 0,
            },
            dropOff: {
              lat: res.dropOff?.latitude || 0,
              lng: res.dropOff?.longitude || 0,
            },
          });
        })
        .catch(() => setIsError(true));
    }
  }, [
    getLocations,
    pickupLocationResult.predictions.length,
    dropoffLocationResult.predictions.length,
  ]);

  return {
    locations,
    isLoading,
    isError,
  };
};

export default useGoogleCarLocations;
