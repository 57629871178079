import type { IHiddenFromScope } from "./property";

export interface ICreateHiddenProperty {
  id: number;
}

export const HiddenPropertyReasons = [
  "Poor experience",
  "Health and safety concerns",
  "Inaccurate or incorrect information",
  "Does not fit my needs",
  "Another reason",
] as const;

export type IHiddenPropertyReason = (typeof HiddenPropertyReasons)[number];

export interface ICreateHiddenPropertyParams {
  propertyId: number;
  reason: (typeof HiddenPropertyReasons)[number];
  comments?: string;
  scope?: HidePropertyScope;
}

export type HidePropertyScope = "user" | "business";

export type HidePropertyScreen = "form" | "confirmation";

export interface IHiddenProperty {
  id: number;
  comments?: string;
  createdAt: string;
  hiddenFromType: IHiddenFromScope;
  property: {
    id: number;
    city: string;
    country: string;
    name: string;
    rating: number | null;
    reviewsCount: number;
    state?: string;
    thumbnailUrl: string | null;
  };
  reason: IHiddenPropertyReason;
  updatedAt: string;
}
