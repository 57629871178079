import styled from "styled-components";

const ContainerTitle = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[20]};
`;

const RefundContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
`;

const RefundRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing[16]};
`;

const IconContainer = styled.div<{ backgroundColor: string }>`
  display: flex;
  background-color: ${({ theme, backgroundColor }) => theme.colors[backgroundColor]};
  width: ${({ theme }) => theme.spacing[40]};
  height: ${({ theme }) => theme.spacing[40]};
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.circle};
`;

const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const Divider = styled.div`
  height: 1px;
  border-top: 2px dotted ${({ theme }) => theme.colors.borderMinimal};
`;

export { ContainerTitle, RefundContent, RefundRow, IconContainer, InfoContainer, Divider };
