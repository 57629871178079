import styled from "styled-components";

import type { TypographyProps } from "@hotelengine/atlas-web";
import { Typography } from "@hotelengine/atlas-web";

export const Tag = styled<React.FC<TypographyProps>>(Typography)<{
  active: boolean;
}>`
  padding: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[6]}`};
  min-width: 16px;
  color: ${({ theme }) => theme.colors.backgroundPrimary};
  border-radius: ${({ theme }) => theme.borderRadius["2xs"]};
  background-color: ${({ theme, active }) =>
    active ? theme.colors.accentBlue : theme.colors.neutralN500};
  text-align: center;
`;
