import { forwardRef, useState } from "react";

import { useFormikContext } from "formik";

import {
  Box,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Typography,
} from "@hotelengine/atlas-web";
import FullscreenModal from "@hotel-engine/common/FullscreenModal";
import type { ISearchFormRoomValues } from "@hotel-engine/types/search";

import { getRoomAndGuestsLabel } from "./helpers";
import { RoomAndGuestsContent } from "./RoomAndGuestsContent";
import * as Styled from "./styles";

interface IRoomsAndGuestsInputProps {
  isMobile?: boolean;
  isDisabled?: boolean;
  handleAmplitude?: (roomCount: number, guestCount: number) => void;
}

/**
 * This component creates a room and guests button which upon click displays a room and guests popover with add and subtract buttons.
 * At mobile widths, clicking the button instead will display a fullscreen modal.
 *
 * This component needs to be nested inside a formik context with {@link ISearchFormRoomValues} values available
 *
 * @param popoverYOffset - A number used to override the y offset from dom-align which is used by antd's popover.
 * @param handleAmplitude - A function used to collect room and guest info for specific user events.
 */
export const RoomsAndGuestsInput = forwardRef<HTMLButtonElement, IRoomsAndGuestsInputProps>(
  function RoomsAndGuestsInput({ isMobile, isDisabled, handleAmplitude }, triggerInputRef) {
    const [isOpen, setIsOpen] = useState(false);
    const { values } = useFormikContext<ISearchFormRoomValues>();

    const popoverContent = (
      <RoomAndGuestsContent
        onDone={() => {
          handleAmplitude?.(values.roomCount, values.guestCount);
          setIsOpen(false);
        }}
      />
    );

    const roomAndGuestsLabel = getRoomAndGuestsLabel({
      rooms: values.roomCount,
      guests: values.guestCount,
    });

    const roomAndGuests = (
      <Styled.DisplayGuestsAndRooms
        aria-label={`Modify ${roomAndGuestsLabel} count`}
        aria-haspopup="dialog"
        aria-expanded={isOpen}
        ref={triggerInputRef}
        onClick={() => setIsOpen(true)}
        disabled={!!isDisabled}
        data-testid={"guest-and-room-picker"}
        isMobile={isMobile}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Icon
            name={isMobile ? "bed--solid" : "user-group"}
            size="md"
            color="foregroundSecondary"
          />
          <Typography variant="body/md" color="inputForeground" marginLeft={8} marginRight={8}>
            {roomAndGuestsLabel}
          </Typography>
          {!isMobile && <Icon name="chevron-down" size="md" />}
        </Box>
      </Styled.DisplayGuestsAndRooms>
    );

    if (isMobile) {
      return (
        <>
          {roomAndGuests}
          <FullscreenModal
            visible={isOpen}
            title="Rooms and Guests"
            hasDoneButton
            onClose={() => setIsOpen(false)}
            headerReverse
            padding={"16px"}
          >
            {popoverContent}
          </FullscreenModal>
        </>
      );
    }

    return (
      <Popover isOpen={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
        <PopoverTrigger>{roomAndGuests}</PopoverTrigger>
        <PopoverContent style={{ width: 220 }}>{popoverContent}</PopoverContent>
      </Popover>
    );
  }
);

export default RoomsAndGuestsInput;
