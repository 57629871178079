import { Typography } from "@hotelengine/atlas-web";
import type { ITraveler } from "@hotel-engine/types/trips";

export const TravelersFooter = ({
  travelers,
}: {
  travelers: ITraveler[];
}) => {
  const isMoreThenTwoTravelers = travelers.length > 2;

  if (isMoreThenTwoTravelers) {
    return (
      <>
        <Typography variant="body/xs" color="backgroundPrimary">
          {travelers[0].name} • + {travelers.length - 1} more
        </Typography>
      </>
    );
  } else {
    return (
      <>
        {travelers.map((traveler, index) => (
          <Typography key={traveler.name + index} variant="body/xs" color="backgroundPrimary">
            {traveler.name}
          </Typography>
        ))}
      </>
    );
  }
};
