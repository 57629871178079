import styled from "styled-components";
import { colors } from "@hotel-engine/styles";
import Typography from "@hotel-engine/common/Typography";
import { Timeline } from "@hotel-engine/common/Timeline";

export const ActivityTimeLine = styled(Timeline)`
  margin-top: 16px;
`;

export const ActivityHeader = styled(Typography)`
  display: block;
  color: ${colors.black};
`;

export const ActivityBody = styled(Typography)`
  color: ${colors.grey[500]};
`;

export const TimeLineIconFill = () => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="4" cy="4" r="3" fill="#2F58EB" stroke="#2F58EB" strokeWidth="2" />
  </svg>
);

export const TimeLineIconNoFill = () => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="4" cy="4" r="3" fill="white" stroke="#2F58EB" strokeWidth="2" />
  </svg>
);
