import type { IReservationBase } from "@hotel-engine/types/reservation";
import { Chip, Typography } from "@hotelengine/atlas-web";

import * as Styled from "./styles";
import { useDatesInfo } from "./useDatesInfo";

export interface IDateRangeProps {
  itinerary: IReservationBase;
}

const ItineraryDateRange = ({ itinerary }: IDateRangeProps) => {
  const {
    dateOfCheckIn,
    formattedCheckInTime,
    checkinPropertyTimezone,
    dateOfCheckOut,
    formattedCheckoutTime,
    checkoutPropertyTimezone,
    nightCount,
  } = useDatesInfo(itinerary);

  const pillCopy = String(nightCount) + ` Night${nightCount > 1 ? "s" : ""}`;

  return (
    <Styled.RangeContainer data-testid="itinerary-date-range">
      <Styled.DateContainer>
        <Typography variant="uppercase/2xs-strong" color="foregroundDisabled">
          Check-in
        </Typography>
        <Styled.DayContainer>
          <Typography variant="heading/md" color="foregroundPrimary">
            {dateOfCheckIn}
          </Typography>
          <Typography variant="heading/md" color="foregroundPrimary">
            {formattedCheckInTime || "4:00 pm"}{" "}
            <Typography variant="body/xs">({checkinPropertyTimezone})</Typography>
          </Typography>
        </Styled.DayContainer>
      </Styled.DateContainer>
      <Styled.DateContainer>
        <Typography variant="uppercase/2xs-strong" color="foregroundDisabled">
          Checkout
        </Typography>
        <Styled.DayContainer>
          <Typography variant="heading/md" color="foregroundPrimary">
            {dateOfCheckOut}
          </Typography>
          <Typography variant="heading/md" color="foregroundPrimary">
            {formattedCheckoutTime || "11:00 am"}{" "}
            <Typography variant="body/xs">({checkoutPropertyTimezone})</Typography>
          </Typography>
        </Styled.DayContainer>
      </Styled.DateContainer>
      <Chip
        label={pillCopy}
        leadingIcon="moon--solid"
        color="everdark"
        data-testid="number-of-nights"
      />
    </Styled.RangeContainer>
  );
};

export default ItineraryDateRange;
