import type { UseMutationOptions } from "react-query";
import { useMutation } from "react-query";

import { useApi } from "@hotel-engine/react-query/useApi";
import { endpoints } from "@hotel-engine/react-query/constants";
import type { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { getAccessToken } from "@hotel-engine/utilities/auth";
import { createConsumer } from "@rails/actioncable";
import config from "config";
import type { IReservationResponse } from "@hotel-engine/types/reservation";
import type { IBookingCancelParams } from "@hotel-engine/types/booking";
import { queryClient } from "@hotel-engine/contexts";
import { Unsafe } from "@hotel-engine/data";

const useBookingCancellationQuery = (
  options: UseMutationOptions<AxiosResponse<null>, AxiosError, IBookingCancelParams>
) => {
  const post = useApi("post");
  return useMutation<AxiosResponse<null>, AxiosError, IBookingCancelParams>(
    (params) =>
      post(`${endpoints.bookings}/${params.id}/cancellation`, {
        rooms: params.rooms,
      }),
    options
  );
};

const setupActionCable = async () => {
  const token = (await getAccessToken()) || "";
  const consumerUrl = new URL(config.apiHost);
  consumerUrl.pathname = "/cable";
  consumerUrl.searchParams.set("user_token", token);
  return createConsumer(String(consumerUrl));
};

export const useBookingCancellationActionCable = ({
  id,
  onSuccess,
  onError,
}: {
  /** The Id of the booking to cancel rooms on */
  id: string;
  /** This callback is called upon a successful status response from the reservation action cable */
  onSuccess: () => void;
  /** This callback is called upon a failed status response from the reservation action cable */
  onError: (error) => void;
}) => {
  const [reservationResponse, setReservationResponse] = useState<IReservationResponse | null>(null);

  const { mutate } = useBookingCancellationQuery({
    onError: (error) => onError(error),
  });

  const runMutation = async (mutateOptions) => {
    const consumer = await setupActionCable();
    consumer.subscriptions.create(
      {
        channel: "ContractChannel",
        room_id: id,
      },
      {
        connected() {
          mutate(mutateOptions);
        },
        received(data: IReservationResponse) {
          setReservationResponse(data);
          if (!data.status || data.status === "failed") {
            const errorResponse = {
              ...data,
              errors: data?.status_message ? [data.status_message] : [],
              status: "failed",
            };
            setReservationResponse(errorResponse);
            onError(errorResponse);
            consumer.disconnect();
          }

          if (!data.initial_message && data.status !== "failed") {
            queryClient
              .invalidateQueries(endpoints.contracts)
              .then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
            queryClient
              .invalidateQueries(endpoints.reservations)
              .then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
            onSuccess();
            consumer.disconnect();
          }
        },
      }
    );
  };

  return { mutate: runMutation, response: reservationResponse };
};
