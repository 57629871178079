import type { NavigateFunction } from "@hotel-engine/lib/react-router-dom";
import {
  BILLING,
  COMPANY_SETTINGS,
  DASHBOARD,
  GROUPS,
  MEMBERS,
  TRENDS,
  TRIPS,
  SUPPORT_CENTER,
  routes,
} from "@hotel-engine/constants";
import { Auth } from "@hotel-engine/services";
import type { IRewardsProfileNextTier, IUser } from "@hotel-engine/types/user";

import { stringify } from "qs";

import type { ActionCreator, AnyAction } from "redux";

import type { ILinks } from "./constants";
import { LinkValues, roleLinks } from "./constants";
import { hasDirectBillEnabled } from "@hotel-engine/utilities";

export const getCurrentState: (pathname: string) => ILinks["id"] | "" = (pathname) => {
  switch (true) {
    case pathname === "/":
      return DASHBOARD;
    case Boolean(pathname.includes(TRIPS)):
      return TRIPS;
    case Boolean(
      pathname === routes.billing ||
        pathname === routes.balance ||
        pathname === routes.paymentMethods ||
        pathname === routes.creditLineIncrease ||
        pathname === routes.creditCardTransactions ||
        pathname === routes.paymentHistory ||
        pathname === routes.paymentDetails
    ):
      return BILLING;
    case Boolean(pathname.includes(TRENDS)):
      return TRENDS;
    case Boolean(pathname.includes(COMPANY_SETTINGS)):
      return COMPANY_SETTINGS;
    case Boolean(pathname.includes(MEMBERS)):
      return MEMBERS;
    case Boolean(pathname.includes(GROUPS)):
      return GROUPS;
    case Boolean(pathname.includes(SUPPORT_CENTER)):
      return SUPPORT_CENTER;
    default:
      return "";
  }
};

export const getNavLinks: (user: IUser) => ILinks[] = (user) => {
  const findLinkValues = (link) => LinkValues.find((element) => element.id === link);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return roleLinks[user.role].reduce((links: ILinks[], link) => {
    const originalLinkVal = findLinkValues(link);
    if (!originalLinkVal) return links;

    // Deep clone to avoid mutating the original LinkValues object
    const linkVal = { ...originalLinkVal };
    const hasDBEnabled = hasDirectBillEnabled(user);

    // allows Admin to see `Billing` tab link in the nav bar when Direct Bill is not enabled
    if (!hasDBEnabled && linkVal.id === BILLING) {
      // change route for BILLING to creditCardTransactions
      linkVal.route = routes.creditCardTransactions;
    }
    links.push(linkVal);
    if (user.role === "coordinator" && !user.hasReportingAccess)
      links = links.filter((item) => item.id !== TRENDS);

    return links;
  }, []);
};

export const handleSignOut = async (
  navigate: NavigateFunction,
  signOut: ActionCreator<AnyAction>
) => {
  await Auth.signOut("helpers.handleSignOut");
  signOut(); // Remove user from Auth redux state
  navigate(routes.login);
};

export const setRewardsBarWidth = (
  type: "spend" | "bookings",
  nextTier: IRewardsProfileNextTier | null,
  spend: number | string,
  bookings: number | string
) => {
  const min = 0;
  const max = 100;

  const bookingCountUnlock = nextTier?.bookingCountUnlock ? nextTier.bookingCountUnlock : 0;
  const spendUnlock = nextTier?.spendUnlock ? Number(nextTier.spendUnlock) : 0;

  let width = 0;

  if (type === "spend") {
    width = +spend / spendUnlock;
  }
  if (type === "bookings") {
    width = +bookings / bookingCountUnlock;
  }

  width = width * 100;

  if (width < 0) {
    width = min;
  } else if (width > 100) {
    width = max;
  }

  return Math.ceil(width);
};

/**
 * Remove a given key from a given object
 * @param params object to modify
 * @param toRemove key to remove from params
 * @returns a new object withou the specific key provided in toRemove
 */
export const removeParameter = (params: { [key: string]: string }, toRemove: string) =>
  Object.keys(params).reduce(
    (acc, currentKey) =>
      currentKey === toRemove ? acc : { ...acc, [currentKey]: params[currentKey] },
    {}
  );

export const buildQueryString = (params: { [key: string]: string }) => stringify(params);

export const getUserRoleEnabledFeatures = (user: IUser) => {
  const personalTravelEnabled = user.business.personalTravelEnabled;
  const userIsActuallyPersonalTraveler = !!(user.businessTravelUserId && user.role === "user");
  const canCreatePersonalAccount =
    user.accountType === "business" &&
    !user.business.isPerkAccount &&
    !user.personalTravelUserId &&
    user.business.personalTravelEnabled;
  const canSwitchToPersonal = !!(
    user.accountType === "business" &&
    !user.business.isPerkAccount &&
    user.personalTravelUserId &&
    user.business.personalTravelEnabled
  );
  const canSwitchToBusiness = !!(
    user.accountType === "personal" &&
    user.businessTravelUserId &&
    user.business.personalTravelEnabled
  );
  const notViewOnlyTraveler = user.role !== "view_only_traveler";
  const shouldShowReferAFriend = Boolean(
    user?.business?.referFriendsEnabled &&
      (user?.business?.isPerkAccount || user.accountType === "personal") &&
      notViewOnlyTraveler
  );
  const shouldShowTravelCredits =
    user.business.travelCreditsAllowed &&
    (!user.business.hasPooledTravelCredits || user.role !== "user") &&
    notViewOnlyTraveler;

  return {
    personalTravelEnabled,
    userIsActuallyPersonalTraveler,
    canCreatePersonalAccount,
    canSwitchToPersonal,
    canSwitchToBusiness,
    shouldShowReferAFriend,
    shouldShowMyProperties: notViewOnlyTraveler,
    shouldShowTravelCredits,
  };
};
