import config from "config";
import * as Styled from "./styles";
import { useAuth0 } from "@auth0/auth0-react";
import { signUpWithGoogle } from "@hotel-engine/utilities/auth/helpers";

interface ICreateAccountOptions {
  goContinueWithEmail: () => void;
  title: string;
  /** For referrals that sign up with google, passed to the callback */
  referralToken?: string;
  currentUserId?: number;
  showPolicyText?: boolean;
  svgSrc: string;
}

export const CreateAccountOptions = ({
  goContinueWithEmail,
  title,
  referralToken,
  currentUserId,
  svgSrc,
}: ICreateAccountOptions) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <>
      <img src={svgSrc} alt="Woman with suitcase" />
      <Styled.Title className="createAccountTitle">{title}</Styled.Title>
      <Styled.ContinueWithGoogleButton
        className="continueWithButton"
        type="ghost"
        onClick={() => signUpWithGoogle(loginWithRedirect, referralToken, currentUserId)}
      >
        <Styled.ContinueWithGoogleIcon
          src={`${config.cdnHost}/assets/personal-travel/images/google-icon.svg`}
          alt=""
          width="20px"
          height="20px"
        />
        <span>Continue with Google</span>
      </Styled.ContinueWithGoogleButton>
      <Styled.ContinueWithEmailButton
        className="continueWithButton"
        type="ghost"
        onClick={goContinueWithEmail}
      >
        <Styled.ContinueWithEmailIcon icon={["fal", "envelope"]} size="lg" />
        <span>Continue with Email</span>
      </Styled.ContinueWithEmailButton>
    </>
  );
};
