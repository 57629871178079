import { useEffect, useRef, useState } from "react";

import type { FormikProps } from "formik";
import { Field, Form, Formik, useFormikContext } from "formik";

import type { ICardUpdateValues } from "@hotel-engine/app/EditPaymentProfileForm/components/CardInfo";
import InputField from "@hotel-engine/app/EditPaymentProfileForm/components/InputField";
import { buildPaymentProfileSchema } from "@hotel-engine/app/EditPaymentProfileForm/helpers";
import AlertBanner from "@hotel-engine/common/AlertBanner";
import Button from "@hotel-engine/common/Button";
import CheckboxField from "@hotel-engine/common/FormikFields/CheckboxField";
import { Icon } from "@hotel-engine/common/Icon";
import { useURLParams } from "@hotel-engine/hooks";
import type { ISearchAndRoomRateID } from "@hotel-engine/hooks";
import { useCurrencyCode } from "@hotel-engine/hooks/useCurrencyCode";
import { useCreatePaymentProfile } from "@hotel-engine/react-query/paymentProfile/useCreatePaymentProfile";
import { useUpdatePaymentProfile } from "@hotel-engine/react-query/paymentProfile/useUpdatePaymentProfile";
import { AmplitudeFormObserver } from "@hotel-engine/scripts/hooks";
import type { IErrorResponse, IPaymentProfileError } from "@hotel-engine/types/errors";
import type { IPaymentProfile } from "@hotel-engine/types/paymentProfile";
import { isIPaymentProfileError } from "@hotel-engine/types/errors";
import { captureMessage } from "@hotel-engine/utilities/logger";
import { ampli } from "ampli";
import config from "config";
import type { ICheckoutFormData } from "pages/Checkout/validation";
import { useAppSelector } from "store/hooks";

import { AmplitudePaymentMethodWrapper } from "./AmplitudePaymentMethodWrapper";
import * as Styled from "./styles";
import { Unsafe } from "@hotel-engine/data";

export interface IPaymentMethodFormProps {
  /** current user payment profile */
  existingPayment?: IPaymentProfile;
  /** Array of payment options */
  paymentProfiles?: IPaymentProfile[];
  /** callback that gets called when cancel button is clicked */
  onCancel?: () => void;
  /** callback that gets called when form is submitted */
  onSubmit: (payment: IPaymentProfile) => void;
}

export interface IPaymentMethodFormValues {
  authorized: boolean;
  billingPostalCode: string;
  default: boolean;
  name: string;
  nickname?: string | null;
}

const AMPLI_EVENT_LIST = {
  name: { title: "editCardholderName", additionalValue: "typedText" },
  default: { title: "clickMakeDefaultCard", additionalValue: "selectedFilter" },
};

export const PaymentMethodForm = ({
  existingPayment,
  paymentProfiles,
  onCancel,
  onSubmit,
}: IPaymentMethodFormProps) => {
  const currencyCode = useCurrencyCode();

  // The following 3 pieces of state are needed for the spreedly fields that don't play well with formik
  const [expirationMonth, setExpirationMonth] = useState<string | undefined>("");
  const [expirationYear, setExpirationYear] = useState<string | undefined>("");
  const [cvvInvalid, setCvvInvalid] = useState<boolean | undefined>(false);
  const updatePaymentProfile = useUpdatePaymentProfile();
  const createPaymentProfile = useCreatePaymentProfile();
  const loading = updatePaymentProfile.isLoading || createPaymentProfile.isLoading;
  const checkoutForm = useFormikContext<ICheckoutFormData>();
  const formRef = useRef<FormikProps<IPaymentMethodFormValues>>(null);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const user = useAppSelector((state) => state.Auth.user);
  const hasDirectBill = user?.directBill && user?.business?.directBillEnabled;

  const {
    params: { propertyId },
    search: { s: searchId, roomRateId },
  } = useURLParams<ISearchAndRoomRateID>();

  const amplitudeSubmissionObj = {
    propertyId: Number(propertyId),
    searchId: Number(searchId),
    roomRateId: Number(roomRateId),
  };

  useEffect(() => {
    // Run validation on payment form when submitting from the checkout form
    if (checkoutForm.submitCount > 0) {
      formRef.current?.setTouched({ billingPostalCode: true, name: true });
      formRef.current?.validateForm().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
      if (!existingPayment?.id) {
        globalThis.Spreedly.validate();
      }
    }
  }, [checkoutForm.submitCount, existingPayment?.id]);

  const isEditMode = Boolean(existingPayment?.id);

  const initialValues: IPaymentMethodFormValues = {
    authorized: false,
    billingPostalCode: existingPayment?.billingPostalCode ?? "",
    default: existingPayment?.default ?? false,
    name: existingPayment?.name ?? "",
    nickname: existingPayment?.nickname ?? "",
  };

  const handleCardUpdate = (updatedValues: ICardUpdateValues) => {
    const {
      cvvInvalid: cvvInvalidNewVal,
      expirationMonth: expirationMonthNewVal,
      expirationYear: expirationYearNewVal,
    } = updatedValues;

    setCvvInvalid(cvvInvalidNewVal);
    setExpirationMonth(expirationMonthNewVal);
    setExpirationYear(expirationYearNewVal);
  };

  /** Make the api request to update/create the payment profile */
  const handleSave = (data) => {
    const paymentProfile = {
      expirationMonth,
      expirationYear,
      ...existingPayment,
      ...data,
    };

    const duplicate =
      paymentProfiles?.length &&
      !paymentProfile.id &&
      paymentProfiles.find((p) => p.spreedlyFingerprint === paymentProfile.spreedlyFingerprint);

    // if new card info is a duplicate don't save card.
    if (duplicate) {
      onSubmit({ ...duplicate, duplicate: true });
      return;
    }

    const onError = (error: IPaymentProfileError | IErrorResponse) => {
      globalThis.Spreedly.removeHandlers();
      ampli.creditCardDeclined(amplitudeSubmissionObj);

      captureMessage("Payment profile form: Save error", {
        data,
        error,
      });
      // first preference: specific error message
      // second preference: general error message
      // third preference: default error message
      setErrorMessage(
        (isIPaymentProfileError(error) && error.clientMessages?.[0]) ||
          ("message" in error && error.message) ||
          config.defaultErrorMessage
      );
    };

    if (existingPayment?.id) {
      // This result coming back from the PUT update, brings a different data structure
      updatePaymentProfile.mutate(paymentProfile, {
        onSuccess: (res) => {
          onSubmit({ ...res, type: res.creditCardType ?? res.type });
        },
        onError: onError,
      });

      return;
    }

    createPaymentProfile.mutate(paymentProfile, {
      onSuccess: (res) => {
        onSubmit(res);
      },
      onError: onError,
    });
  };

  const handleSubmit = (formValues: IPaymentMethodFormValues) => {
    const expirationYearVal = existingPayment?.expirationYear || expirationYear;
    const expirationMonthVal = existingPayment?.expirationMonth || expirationMonth;

    // generate profile payment with form values
    const tokenData = {
      full_name: formValues.name,
      month: String(expirationMonthVal),
      year: String(expirationYearVal),
      zip: formValues.billingPostalCode,
    };

    // don't submit if any of the required fields are missing or if the cvv is invalid
    if (cvvInvalid) {
      return;
    }

    // if is an existing payment the card information will stay the same. Only form values will update.
    if (existingPayment?.id) {
      handleSave(formValues);
      ampli.clickSaveCard(amplitudeSubmissionObj);
      return;
    }

    // Spreedly returns paymentMethod with masked card information ready to save it in paymentProfiles.
    globalThis.Spreedly.on("paymentMethod", (token, paymentMethod) => {
      const data = {
        ...formValues,
        cardType: paymentMethod.card_type,
        last4: paymentMethod.last_four_digits,
        maskedCardNumber: paymentMethod.number,
        spreedlyFingerprint: paymentMethod.fingerprint,
        spreedlyToken: token,
      };

      Spreedly.validate();
      handleSave(data);
    });

    globalThis.Spreedly.on("errors", (errors) => {
      setErrorMessage(errors[0]["message"]);
    });

    globalThis.Spreedly.tokenizeCreditCard(tokenData);

    ampli.clickSaveCard(amplitudeSubmissionObj);
  };

  const shouldHideButton = !onCancel || (!!!paymentProfiles?.length && !hasDirectBill);

  return (
    <div>
      <Styled.Title as="h2" variant="xlarge" isResponsive={false}>
        {isEditMode ? "Edit" : "Add"} Card
      </Styled.Title>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={buildPaymentProfileSchema(currencyCode)}
        innerRef={formRef}
      >
        {({ submitCount, values }) => (
          <Form data-private>
            <AmplitudeFormObserver
              eventList={AMPLI_EVENT_LIST}
              initialValues={initialValues as IPaymentMethodFormValues}
              submissionObj={amplitudeSubmissionObj}
              values={values as IPaymentMethodFormValues}
            />
            <Styled.FromSection>
              <Styled.CardholderName
                name="name"
                placeholder="Full name on card"
                label="Cardholder name"
                colon={false}
                submitted={!!submitCount}
              />
              <AmplitudePaymentMethodWrapper
                payment={existingPayment}
                onCardUpdate={handleCardUpdate}
              />
            </Styled.FromSection>
            <Styled.FromSection>
              <InputField
                name="nickname"
                placeholder="Ex: Personal Travel"
                label="Card nickname (optional)"
                colon={false}
                submitted={!!submitCount}
              />
              <Field name="default" component={CheckboxField} label="Make default card" />
            </Styled.FromSection>
            {errorMessage?.length > 0 && (
              <Styled.ErrorContainer>
                <div>
                  <Icon icon={["fal", "exclamation-circle"]} size="lg" />
                  <span>{errorMessage.includes("decline") ? "Credit Card Declined" : "Uh Oh"}</span>
                </div>
                <ul>
                  <li>
                    {errorMessage.includes("decline")
                      ? "That credit card didn’t work. Please re-enter your information or try a different card."
                      : errorMessage}
                  </li>
                </ul>
              </Styled.ErrorContainer>
            )}
            {errorMessage?.length === 0 && !!checkoutForm.errors.selectedPaymentId && (
              <div className="formik-error-alert-banner">
                <AlertBanner
                  iconWeightVariant="fas"
                  message="Please add your card to complete the booking."
                  marginBottom="lg"
                  data-testid="no-payment-alert"
                />
              </div>
            )}
            <Styled.ButtonWrapper>
              <Button
                id="saveCard"
                type="primary"
                htmlType="submit"
                disabled={loading}
                loading={loading}
                onClick={() => {
                  if (existingPayment?.id) return;
                  globalThis.Spreedly.validate();
                }}
              >
                Save Card
              </Button>
              {!shouldHideButton && (
                <Button id="cancel" htmlType="button" disabled={loading} onClick={onCancel}>
                  Cancel
                </Button>
              )}
            </Styled.ButtonWrapper>
          </Form>
        )}
      </Formik>
    </div>
  );
};
