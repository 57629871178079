import { Icon } from "@hotel-engine/common/Icon";

import * as Styled from "./styles";

export interface INotificationLinkProps {
  hasUnread: boolean;
  onClick: () => void;
}

const NotificationsLink = ({ hasUnread, onClick }: INotificationLinkProps) => {
  return (
    <Styled.LinkContainer onClick={onClick}>
      <Styled.LinkIconContainer>
        {!!hasUnread && <Styled.Indicator />}
        <Icon icon={["fas", "bell"]} />
      </Styled.LinkIconContainer>
      <Styled.LinkTitle as="span" variant="body-medium">
        Notifications
      </Styled.LinkTitle>
    </Styled.LinkContainer>
  );
};

export default NotificationsLink;
