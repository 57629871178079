import styled from "styled-components";

import Avatar from "@hotel-engine/common/Avatar";
import { colors } from "@hotel-engine/styles";
import { Badge } from "../../../common/Badge";
import { typographyMixin } from "../../../common/Typography/styles";
import { DropdownMenuItem } from "@hotelengine/atlas-web";

export const UserNavWrapper = styled.button`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-left: 16px;
  background-color: transparent;
  border: none;
  position: relative;
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 375px solid transparent;
  border-right: 375px solid transparent;
  border-bottom: 375px solid transparent;
  position: absolute;
  top: 0px;
  left: -355px;
`;

export const AvatarBadge = styled(Avatar)`
  margin-right: 6px;
  > div {
    height: 32px;
    width: 32px;
    font-size: 14px;
  }
`;

export const NotificationBadge = styled(Badge)`
  ${typographyMixin("body-small")}
  height: 24px;
  min-width: 24px;
  padding: 5px;
  margin-left: 6px;
  color: ${colors.white};
  background-color: ${colors.red[500]};
  border-radius: ${({ theme }) => theme.legacy.lineHeight.xxl};
  text-align: center;
`;

export const ContentWrapper = styled.ul`
  width: 375px;
`;

export const UserInfoItem = styled.li`
  list-style: none;
  padding: 16px;
`;

export const InternalSettingsList = styled.ul`
  padding: 16px;
  background: ${colors.chefsHat};
  display: flex;
  flex-direction: column;
  margin-bottom: -22px;

  > span {
    font-weight: bold;
  }
`;

export const InternalSettingsItem = styled(DropdownMenuItem)`
  padding: 0;
`;
