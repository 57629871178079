import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import InputField from "@hotel-engine/common/FormikFields/InputField";
import Loader from "@hotel-engine/common/Loader";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors } from "@hotel-engine/styles";

export const VerticalFlowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FullNamesWrapper = styled.div<{ isDesktop: boolean }>`
  display: flex;
  flex-direction: ${({ isDesktop }) => (isDesktop ? "row" : "column")};
  width: 100%;
`;

export const FirstName = styled.div<{ isDesktop: boolean }>`
  width: ${({ isDesktop }) => (isDesktop ? `50%` : `100%`)};
  margin-right: ${({ isDesktop, theme }) => (isDesktop ? theme.legacy.space(2) : `0px`)};
`;
export const LastName = styled.div<{ isDesktop: boolean }>`
  width: ${({ isDesktop }) => (isDesktop ? `50%` : `100%`)};
`;
export const Email = styled.div<{ isDesktop: boolean }>`
  width: 100%;
`;
export const FieldText = styled.label`
  margin: 0px 0px 4px 0px;
`;
export const InputBox = styled(InputField)<{ invalid: boolean }>`
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.lg};
  .ant-input-clear-icon {
    display: none;
  }
  .has-error .ant-input-clear-icon {
    color: ${colors.red[400]};
    display: block;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContinueButton = styled(Button)`
  width: 100%;
  margin: ${({ theme }) => theme.legacy.space(2)} 0px 0px 0px;
`;

export const ValidationErrorCopy = styled.p`
  ${typographyMixin("body-medium")}
  color: ${colors.grey[800]};
`;
export const Title = styled.p`
  ${typographyMixin("xlarge")}
  margin-bottom: 0px;
`;

export const DescriptionText = styled.p`
  ${typographyMixin("xsmall")}
  color: ${colors.grey[500]};
  font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
  margin-top: ${({ theme }) => theme.legacy.space(2)};
`;

export const JustAboutFinishedLoader = styled(Loader)`
  color: ${colors.white};
  margin-right: ${({ theme }) => theme.legacy.space(1)};
`;
