import { useEffect, useMemo } from "react";

import { useGlobalTheme } from "@hotel-engine/contexts/GlobalThemeContext";

import AirportMarker from "../AirportMarker";

/** Used to render markers for an origin and destination with a flight path */
const FlightMarkers = ({ map, origin, originInfo, destination, destinationInfo, isMobile }) => {
  const theme = useGlobalTheme();
  const bounds = useMemo(() => new google.maps.LatLngBounds(), []);

  /** Set the bounds based on the origin and destination so that the map is at the correct zoom level to show both */
  [origin, destination].forEach((marker) => {
    bounds.extend(new google.maps.LatLng(marker.lat, marker.lng));
  });

  const invertOrigin = origin.lat < destination.lat;
  const invertDestination = destination.lat < origin.lat;

  /** Set the bounds of the map once we have used the airport locations to extend them */
  useEffect(() => {
    if (!!map && !!bounds) {
      map.fitBounds(bounds, 75);
    }
  }, [map, bounds, isMobile]);

  /** Generate the flight path line between the two airport pins and add it to the map */
  useEffect(() => {
    if (!!map) {
      const dot = {
        path: google.maps.SymbolPath.CIRCLE,
        fillOpacity: 1,
        strokeOpacity: 1,
        strokeWeight: isMobile ? 1 : 2,
        fillColor: theme.tokens.colors.backgroundPrimary,
        strokeColor: theme.tokens.colors.neutralN800,
        scale: isMobile ? 3 : 4,
      };

      const flightPath = new google.maps.Polyline({
        path: [origin, destination],
        geodesic: true,
        strokeColor: theme.tokens.colors.neutralN800,
        strokeOpacity: 1.0,
        strokeWeight: 2,
        icons: [
          {
            icon: dot,
            offset: "0%",
          },
          {
            icon: dot,
            offset: "100%",
          },
        ],
      });

      flightPath.setMap(map);
    }
  }, [
    destination,
    isMobile,
    map,
    origin,
    theme.tokens.colors.backgroundPrimary,
    theme.tokens.colors.neutralN800,
  ]);

  return (
    <>
      <AirportMarker
        map={map as google.maps.Map}
        position={origin}
        airport={originInfo}
        inverted={invertOrigin}
      />
      <AirportMarker
        map={map as google.maps.Map}
        position={destination}
        airport={destinationInfo}
        inverted={invertDestination}
      />
    </>
  );
};

export default FlightMarkers;
