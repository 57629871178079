import { useState } from "react";

import { Field, Formik } from "formik";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import * as yup from "yup";

import InputField from "@hotel-engine/common/FormikFields/InputField";
import { notification } from "@hotel-engine/common/Notifications";
import { useTwoFactorVerificationCodeSubmit } from "@hotel-engine/react-query/twoFactorAuth/useTwoFactorVerificationCodeSubmit";
import { useAccountMode } from "@hotel-engine/react-query/users/useAccountMode";
import { User } from "@hotel-engine/services";
import { captureMessage } from "@hotel-engine/utilities";
import { getPersonalToken } from "@hotel-engine/utilities/auth";
import { ModalNavBar } from "pages/DashboardLegacy/components/PersonalAccountCreationCta/ModalNavBar";
import { AuthActions } from "store/Auth/AuthRedux";
import { useAppSelector, useAppDispatch } from "store/hooks";

import { ResendButton } from "./ResendButton";
import * as Styled from "./styles";
import { Unsafe } from "@hotel-engine/data";
import { is422ErrorResponse } from "@hotel-engine/react-query/errors/response";

interface IEnterVerificationCode {
  /** Phone number used to request the code */
  phone: string;
  /** Indicates the component is rendering in a modal on the Dashboard on a Business account */
  isInModal?: boolean;
  /** Close the modal */
  onClose?: () => void;
  /** Go back to the phone number submission */
  onGoBack?: () => void;
}
export interface ICodeVerification {
  /** Authentication code texted to user's phone */
  code: string;
}

const initialValues: ICodeVerification = {
  code: "",
};

const errorMessage = "Please enter valid code";
const CodeVerificationValidation = yup.object().shape({
  code: yup
    .string()
    .matches(/^\d+$/, errorMessage)
    .test("", errorMessage, (code) => code?.length === 6)
    .required(errorMessage),
});

// resend code, setup code submission
export const EnterVerificationCode = ({
  phone,
  isInModal,
  onClose,
  onGoBack,
}: IEnterVerificationCode) => {
  const user = useAppSelector((store) => store.Auth.user);
  const isBusinessAccount = user?.accountType === "business";
  const personalToken = getPersonalToken();
  const authToken = isBusinessAccount && personalToken ? { token: personalToken } : undefined;

  const { mutate } = useTwoFactorVerificationCodeSubmit(authToken);
  const [resendError, setResendError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [_, toggleAccountMode] = useAccountMode({
    onFailure: () =>
      notification.error({
        message: "Sorry, there was a problem switching you to your personal account.",
      }),
  });

  const handleSubmit = (values: ICodeVerification, actions) => {
    mutate(
      { phone, code: values.code, accountType: "personal" },
      {
        onSuccess: () => {
          User.getSelf()
            .then((fetchedUser) => {
              dispatch(AuthActions.setUser(fetchedUser));
            })
            .then(() => {
              if (isBusinessAccount && personalToken) {
                // set user to the associated personal account
                toggleAccountMode().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
              }
              // replacing history so users don't back into the search param or 2fV page //
              navigate("/", {
                replace: true,
              });
            })
            .then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
        },
        onError: (error) => {
          if (is422ErrorResponse(error)) {
            actions.setFieldError(
              "code",
              "Verification code was not accepted. Please check that the number is valid and try again."
            );
            return;
          }
          captureMessage("Enter Verification Code error", { error });

          actions.setFieldError("code", "Sorry, there was a problem. Try again later.");
        },
      }
    );
  };

  const last4Digits = phone.slice(-4);

  return (
    <>
      {!!isInModal && !!onClose && <ModalNavBar onClose={onClose} onGoBack={onGoBack} />}

      <Styled.Title $isInModal={!!isInModal}>Enter Authentication Code</Styled.Title>
      <Styled.DescriptionText $isInModal={!!isInModal}>
        We've sent a verification code to{" "}
        <Styled.PhoneNumberText $isInModal={!!isInModal}>
          xxx-xxx-{last4Digits}
        </Styled.PhoneNumberText>
        . Please enter the code you received in the field below.
      </Styled.DescriptionText>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={CodeVerificationValidation}
      >
        <>
          <Styled.DescriptionText $isInModal={!!isInModal}>
            Didn't receive code?{" "}
            <ResendButton phone={phone as string} setResendError={setResendError} />{" "}
            {!!resendError && <Styled.ResendErrorMessage>{resendError}</Styled.ResendErrorMessage>}
          </Styled.DescriptionText>
          <Styled.CodeForm>
            <Field
              component={InputField}
              name="code"
              placeholder="Enter Verification Code"
              required={true}
            />
            <Styled.SubmitButton htmlType="submit" type="primary">
              Verify
            </Styled.SubmitButton>
          </Styled.CodeForm>
        </>
      </Formik>
    </>
  );
};
