import { Modal } from "@hotel-engine/common/Modal";
import { useBookingCancellationActionCable } from "@hotel-engine/react-query/bookings/useBookingCancellationQuery";
import Reservation from "@hotel-engine/services/Reservation";
import { useNavigate } from "react-router";
import { queryClient } from "@hotel-engine/contexts";
import { endpoints } from "@hotel-engine/react-query/constants";
import { captureMessage } from "@hotel-engine/utilities";
import type { ICancelRooms } from "pages/ModifyReservation/types";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import {
  useModificationsContext,
  type Dispatch,
} from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useTrackBookingModifications } from "@hotel-engine/app/ItineraryContent/hooks/useTrackBookingModifications";
import type { RefundType } from "./useCancelRefundCalculator";

interface IUseCancelBookingParams {
  /** Rooms on contract. Enabled rooms can be cancelled */
  cancelRoomsValue: ICancelRooms;
  /** Current contract */
  reservation: IReservationBase;
  /** Dispatch from ModificationsContext */
  dispatch: Dispatch;
  /** shows success modal if multi-room cancellation */
  showStatusModal: () => void;
  /** Total refund amount and type updated as rooms are selected for cancellation */
  refundInfo: {
    totalRefundAmount: number | null;
    refundType: RefundType | undefined;
  };
}

/** Manages cancellation API actions for all cancellation types (CancellationType)
 * @returns submitCancelSingleRoomBooking
 * @returns submitCancelMultiRoomBooking
 */
export const useCancelBooking = ({
  cancelRoomsValue,
  reservation,
  dispatch,
  showStatusModal,
  refundInfo,
}: IUseCancelBookingParams) => {
  const navigate = useNavigate();
  const {
    state: { modificationView },
  } = useModificationsContext();

  const { trackClickCancelTrip, trackSubmitCancelRooms } = useTrackBookingModifications();

  const cancelRooms = Object.entries(cancelRoomsValue).filter(([_, values]) => values.enabled);

  const setLoading = () => {
    showStatusModal();
    dispatch({
      type: "SET_MODIFICATION_STATUS",
      payload: { isLoading: true, isError: false },
    });
  };

  const handleSuccess = () => {
    dispatch({
      type: "SET_MODIFICATION_INFO",
      payload: {
        refundAmount: Number(refundInfo.totalRefundAmount),
        refundType: refundInfo.refundType,
        roomsModified: cancelRooms.length,
        modificationType: modificationView,
        bookingType: "lodging",
      },
    });
    dispatch({
      type: "SET_MODIFICATION_STATUS",
      payload: { isLoading: false, isSubmitted: true },
    });
  };

  const handleError = (error?: unknown) => {
    dispatch({
      type: "SET_MODIFICATION_STATUS",
      payload: { isLoading: false, isError: true },
    });
    dispatch({ type: "SET_SHOW_STATUS_MODAL", payload: false });
    Modal.error({
      title: "Error cancelling reservation",
      content: "An error occurred while trying to cancel this reservation. Try again later.",
      okText: "Ok",
    });

    if (error) {
      captureMessage("Error cancelling reservation", {
        contractId: reservation.id,
        error,
      });
    }
  };

  //////////////
  // MULTI ROOM
  const cancelMultiRoomBooking = useBookingCancellationActionCable({
    id: reservation.id,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const submitCancelMultiRoomBooking = async () => {
    setLoading();

    const roomsToCancel = cancelRooms.map(([contractNumber, _]) => ({
      contractNumber,
    }));

    await cancelMultiRoomBooking.mutate({
      id: reservation.id,
      rooms: roomsToCancel,
    });

    trackSubmitCancelRooms(roomsToCancel.length);
  };

  //////////////
  // SINGLE ROOM
  const submitCancelSingleRoomBooking = async () => {
    setLoading();
    trackClickCancelTrip();

    try {
      const res = await new Reservation(reservation).cancel();
      if (res.status === "booked") {
        return Modal.warning({
          content: res.status_message,
          onOk: () => navigate(-1),
        });
      }

      if (res.status === "cancelled") {
        handleSuccess();
        await queryClient.invalidateQueries(endpoints.contracts);
        await queryClient.invalidateQueries([endpoints.calendarTrips]);
        await queryClient.invalidateQueries(endpoints.reservations);
        return;
      }

      // If we don't hit either of the above blocks we assume some unknown error was hit
      return handleError();
    } catch (error) {
      return handleError(error);
    }
  };

  return {
    submitCancelSingleRoomBooking,
    submitCancelMultiRoomBooking,
  };
};
