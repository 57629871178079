import type { IFlightRefund } from "@hotel-engine/types/itinerary";
import SummaryOfChargesItem from "../../../shared/SummaryOfChargesItem";
import { Box, Divider, Typography } from "@hotelengine/atlas-web";
import { useFlightRefundDetails } from "./useFlightRefundDetails";
import { PaddedScrollSectionContainer } from "../../../styles";
import { useFlightModificationsContext } from "../../../ModificationsContext";

export interface IRefundBreakdownsProps {
  refunds: IFlightRefund[];
}

const RefundBreakdowns = ({ refunds }: IRefundBreakdownsProps) => {
  const { details, hasAirlineCredit } = useFlightRefundDetails(refunds);
  const { isPreview } = useFlightModificationsContext();

  return (
    <PaddedScrollSectionContainer $isPreview={isPreview}>
      <Typography variant="uppercase/xs-strong" marginBottom={8}>
        Refund details
      </Typography>
      {details.map((group, i) => (
        <Box key={`refund-group-${group.key}`} data-testid={`refund-group-${i}`}>
          {group.items.map((item) => (
            <Box
              key={`refund-${item.label}-${item.value}`}
              data-testid={`refund-${item.label}-${item.value}`}
              display="flex"
              flexDirection="column"
            >
              <SummaryOfChargesItem
                {...item}
                style={{ marginTop: 4, marginBottom: 4 }}
                labelColor="foregroundPrimary"
                valueColor="foregroundPrimary"
              />
            </Box>
          ))}

          {i < details.length - 1 && (
            <Box marginTop={16} marginBottom={16}>
              <Divider variant="dotted" />
            </Box>
          )}
        </Box>
      ))}

      {!!hasAirlineCredit && (
        <Box marginTop={16}>
          <Typography variant="body/md">
            Airline credit can only be redeemed through the airline directly.
          </Typography>
        </Box>
      )}
    </PaddedScrollSectionContainer>
  );
};

export default RefundBreakdowns;
