import type { PayloadAction } from "@reduxjs/toolkit";
import type { IFlightResult } from "@hotelengine/core-booking-web";
import {
  type FlightsSortOptions,
  type ShelfBlockKey,
  StopFilterOptions,
  initialFlightsFilterState,
  AllAirlinesFilter,
} from "store/Flights/FlightsFilters/flights.filters.constants";
import { getAvailableFiltersFromResults } from "store/Flights/FlightsFilters/flights.filters.helpers";
import type { IFlightsSearchFilters } from "store/Flights/FlightsFilters/flights.filters.types";

export const resetFilters = () => initialFlightsFilterState;

export const initFlightsFilters = (
  state: IFlightsSearchFilters,
  action: PayloadAction<{
    results: IFlightResult[];
    departureDate: string;
    passengersCount: number;
  }>
) => {
  const { results, departureDate, passengersCount } = action.payload;
  return getAvailableFiltersFromResults({ state, results, departureDate, passengersCount });
};

export const clearSelectedFilters = (state: IFlightsSearchFilters) => {
  // clear stops filter
  state.stops.selected = StopFilterOptions.any;
  // clear airlines filter
  const allAirlinesSelected = Object.keys(state.airlines).reduce((acc, key) => {
    acc[key] = { selected: false };
    return acc;
  }, {});
  state.airlines = allAirlinesSelected;
  // clear price range
  state.priceRange.userMin = state.priceRange.floor;
  state.priceRange.userMax = state.priceRange.ceil;
  // clear takeoff time
  state.takeoffTime.userMin = state.takeoffTime.floor;
  state.takeoffTime.userMax = state.takeoffTime.ceil;
  // clear landing time
  state.landingTime.userMin = state.landingTime.floor;
  state.landingTime.userMax = state.landingTime.ceil;
  // clear duration
  state.duration.userMin = state.duration.floor;
  state.duration.userMax = state.duration.ceil;
  // clear in policy
  state.inPolicy.selected = true;
};

export const setFlightsFilterStopOption = (
  state: IFlightsSearchFilters,
  action: PayloadAction<{ option: StopFilterOptions }>
) => {
  state.stops.selected = action.payload.option;
};

export const setUserPriceFilter = (
  state: IFlightsSearchFilters,
  action: PayloadAction<{ userMin: number; userMax: number }>
) => {
  return {
    ...state,
    priceRange: {
      ...state.priceRange,
      userMin: action.payload.userMin,
      userMax: action.payload.userMax,
    },
  };
};

export const setDurationFilter = (
  state: IFlightsSearchFilters,
  action: PayloadAction<{ userMin: number; userMax: number }>
) => {
  return {
    ...state,
    duration: {
      ...state.duration,
      userMin: action.payload.userMin,
      userMax: action.payload.userMax,
    },
  };
};

export const setSortByOption = (
  state: IFlightsSearchFilters,
  action: PayloadAction<{ option: FlightsSortOptions }>
) => {
  state.selectedSort = action.payload.option;
};

export const setTakeOffTimesFilter = (
  state: IFlightsSearchFilters,
  action: PayloadAction<{ userMin: number; userMax: number }>
) => {
  state.takeoffTime.userMin = action.payload.userMin;
  state.takeoffTime.userMax = action.payload.userMax;
};

export const setLandingTimesFilter = (
  state: IFlightsSearchFilters,
  action: PayloadAction<{ userMin: number; userMax: number }>
) => {
  state.landingTime.userMin = action.payload.userMin;
  state.landingTime.userMax = action.payload.userMax;
};

export const setSelectedShelfBlock = (
  state: IFlightsSearchFilters,
  action: PayloadAction<ShelfBlockKey | "">
) => {
  Object.keys(state.shelf).forEach((key) => {
    state.shelf[key].selected = action.payload === state.shelf[key].value;
  });
};

export const toggleAirlineFilter = (
  state: IFlightsSearchFilters,
  action: PayloadAction<{ airlineIataCode: string }>
) => {
  const { airlineIataCode } = action.payload;
  if (airlineIataCode === AllAirlinesFilter) {
    const selectedAll = !state.airlines[AllAirlinesFilter].selected;
    Object.keys(state.airlines).forEach((key) => {
      state.airlines[key].selected = selectedAll;
    });
  } else {
    state.airlines[airlineIataCode].selected = !state.airlines[airlineIataCode].selected;
    state.airlines[AllAirlinesFilter].selected = Object.keys(state.airlines).every(
      (key) => key === AllAirlinesFilter || state.airlines[key].selected
    );
  }
};

export const setInPolicyFilter = (state: IFlightsSearchFilters, action: PayloadAction<boolean>) => {
  state.inPolicy.selected = action.payload;
};

export const setSearchFilters = (
  state: IFlightsSearchFilters,
  action: PayloadAction<IFlightsSearchFilters>
) => {
  const {
    stops,
    selectedSort,
    airlines,
    inPolicy,
    landingTime,
    takeoffTime,
    duration,
    priceRange,
  } = action.payload;

  state.stops = stops;
  state.selectedSort = selectedSort;
  state.airlines = airlines;
  state.inPolicy = inPolicy;
  state.landingTime = landingTime;
  state.takeoffTime = takeoffTime;
  state.duration = duration;
  state.priceRange = priceRange;
};
