import styled from "styled-components";

import { buttonReset, colors } from "@hotel-engine/styles";
import { globalTheme } from "@hotel-engine/styles/themes/global.theme";

export const ErrorMessageWrapper = styled.span`
  color: ${colors.grey[800]};
  font-size: ${globalTheme.legacy.fontSize["sm"]};
`;

export const ReloadLink = styled.button`
  ${buttonReset};

  color: inherit;
  margin-left: 4px;
  border-bottom: 1px solid ${colors.grey[800]};

  &:hover,
  &:focus {
    color: inherit;
  }
`;
