import { useCurrencyCode } from "@hotel-engine/hooks";
import { formatCurrency } from "@hotel-engine/utilities";
import config from "config";

import * as Styled from "../styles";
import { Box, Button, Typography } from "@hotelengine/atlas-web";

export interface IRoomPriceIncreaseModalProps {
  /** handles routing to the Checkout screen */
  goToCheckout: () => void;
  /** handles routing to the Property screen */
  goToProperty: () => void;
  /** roomRates object declaring both original and newly selected room rates */
  roomRates: { chosenRoomRate: number; originalRoomRate: number };
}

const RoomPriceIncreaseModal = ({
  goToCheckout,
  goToProperty,
  roomRates,
}: IRoomPriceIncreaseModalProps) => {
  const currencyCode = useCurrencyCode();
  const roomRateDifference =
    Math.round(roomRates.chosenRoomRate) - Math.round(roomRates.originalRoomRate);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={8}>
      <Styled.ModalImage
        src={`${config.cdnHost}/assets/trip-extensions/room-rate-increased.svg`}
        alt={`room-rate-increased`}
      />
      <Typography
        variant="heading/lg"
        as="h3"
        color="foregroundPrimary"
        data-testid="rate-increased-modal-header"
      >
        {`The nightly rate increased by ${formatCurrency(
          roomRateDifference,
          false,
          currencyCode
        )}.`}
      </Typography>
      <Typography variant="body/md" color="foregroundPrimary">
        {`Unfortunately, the nightly rate went from ${formatCurrency(
          Math.round(roomRates.originalRoomRate),
          false,
          currencyCode
        )} to ${formatCurrency(
          Math.round(roomRates.chosenRoomRate),
          false,
          currencyCode
        )}, based on demand. Still want to book?`}
      </Typography>
      <Box marginTop={24} display="flex" flexDirection="column" gap={12} style={{ width: "100%" }}>
        <Button onClick={goToCheckout} data-testid="rate-increased-book-now-button">
          Book now
        </Button>
        <Button variant="outlined" onClick={goToProperty}>
          Go back to search results
        </Button>
      </Box>
    </Box>
  );
};

export default RoomPriceIncreaseModal;
