import styled from "styled-components";

const Container = styled.div`
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.borderSubtle};
  border-radius: ${({ theme }) => `calc(${theme.borderRadius[8]} + ${theme.borderRadius[2]})`};
  i {
    margin-top: ${({ theme }) => theme.spacing[4]};
  }
`;

const PreviousBooking = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing[16]};
  column-gap: ${({ theme }) => theme.spacing[12]};
`;

const ExtensionBookings = styled.div<{ $isExtension: boolean }>`
  display: flex;
  padding: ${({ theme }) => theme.spacing[16]};
  column-gap: ${({ theme }) => theme.spacing[12]};
  border-width: 1px;
  border-top: ${({ theme, $isExtension }) =>
    $isExtension ? `1px solid ${theme.colors.borderSubtle}` : `none`};
`;

const Content = styled.div`
  display: flex;
  row-gap: ${({ theme }) => theme.spacing[12]};
  flex-direction: column;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing[8]};
`;

export { Container, PreviousBooking, ExtensionBookings, Content, Description };
