import type { ISupportSpecialRequest } from "@hotel-engine/types/supportCase";
import * as Styled from "../../styles";
import Typography from "@hotel-engine/common/Typography";

export interface ISupportSpecialRequestProps {
  details: ISupportSpecialRequest;
}

function SpecialRequest({ details }: ISupportSpecialRequestProps) {
  const {
    adjacentRooms,
    connectingRooms,
    earlyCheckIn,
    floorRequest,
    lateCheckIn,
    lateCheckOut,
    other,
    petFriendly,
    rollawayBed,
    upgradeRequest,
  } = details;

  const isFloorRequestString = typeof floorRequest === "string";
  return (
    <>
      {!!adjacentRooms && (
        <>
          <Styled.FieldDivider />
          <Styled.Row>
            <Typography variant="small">Adjacent Rooms</Typography>
          </Styled.Row>
        </>
      )}
      {!!connectingRooms && (
        <>
          <Styled.FieldDivider />
          <Styled.Row>
            <Typography variant="small">Connecting Rooms</Typography>
          </Styled.Row>
        </>
      )}
      {!!earlyCheckIn && (
        <>
          <Styled.FieldDivider />
          <Styled.Row>
            <Typography variant="small">Early Check-In</Typography>
          </Styled.Row>
        </>
      )}
      {!!floorRequest && (
        <>
          <Styled.FieldDivider />
          <Styled.Row>
            <Typography variant="small">Floor Request</Typography>
          </Styled.Row>
          {!!isFloorRequestString && (
            <Styled.Row>
              <Typography variant="body-medium">Floor Number:</Typography>
              <Typography variant="body-medium">{floorRequest}</Typography>
            </Styled.Row>
          )}
        </>
      )}
      {!!lateCheckIn && (
        <>
          <Styled.FieldDivider />
          <Styled.Row>
            <Typography variant="small">Late Check-In</Typography>
          </Styled.Row>
        </>
      )}
      {!!lateCheckOut && (
        <>
          <Styled.FieldDivider />
          <Styled.Row>
            <Typography variant="small">Late Check-Out</Typography>
          </Styled.Row>
        </>
      )}
      {!!other && (
        <>
          <Styled.FieldDivider />
          <Styled.Row>
            <Typography variant="body-medium">Request Details:</Typography>
          </Styled.Row>
          <Styled.Row>
            <Typography variant="body-medium">"{other}"</Typography>
          </Styled.Row>
        </>
      )}
      {!!petFriendly && (
        <>
          <Styled.FieldDivider />
          <Styled.Row>
            <Typography variant="small">Pet Friendly</Typography>
          </Styled.Row>
        </>
      )}
      {!!rollawayBed && (
        <>
          <Styled.FieldDivider />
          <Styled.Row>
            <Typography variant="small">Rollaway Bed</Typography>
          </Styled.Row>
        </>
      )}
      {!!upgradeRequest && (
        <>
          <Styled.FieldDivider />
          <Styled.Row>
            <Typography variant="small">Upgrade Request</Typography>
          </Styled.Row>
        </>
      )}
    </>
  );
}

export default SpecialRequest;
