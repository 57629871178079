import { Box, type BoxProps } from "@hotelengine/atlas-web";
import styled from "styled-components";

export const ListItem = styled<React.FC<BoxProps>>(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const InfoContainer = styled<React.FC<BoxProps>>(Box)<{
  $pad?: boolean;
}>`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[16]};
  padding: ${({ theme, $pad }) => ($pad ? theme.spacing[16] : theme.spacing[0])};
`;

export const StickyContainer = styled.div<{ $isPreview: boolean }>`
position: sticky;
  bottom: 0;
  left: 0;
  right: 0;

  margin-top: ${({ theme }) => theme.spacing[16]};
  /* Disable theming, needed to counter container padding so we don't to make major adjustments to component data flow */
  margin-left: ${({ $isPreview }) => `${$isPreview ? -24 : -40}px`};
  margin-right: ${({ $isPreview }) => `${$isPreview ? -24 : -40}px`};
  padding-left: ${({ theme }) => theme.spacing[24]};
  padding-right: ${({ theme }) => theme.spacing[24]};
  padding-bottom: ${({ theme }) => theme.spacing[24]};
  border-top: ${({ theme }) => `1px solid ${theme.colors.borderMinimal}`};
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  z-index: ${({ theme }) => theme.zIndex.modal};
`;
