import { Box, Icon, Typography } from "@hotelengine/atlas-web";
import { useAppSelector } from "store/hooks";
import { flexCoverageStartDate, formatDisplayDate } from "@hotel-engine/app/RefundPolicy/helpers";
import { getCancelDateInfo } from "@hotel-engine/utilities/helpers/getCancelDateInfo";

import * as Styled from "./styles";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import moment from "moment";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { useFlex } from "@hotel-engine/hooks";
import { CollapsibleContent } from "../../../shared/CollapsibleContent";

const ItineraryRefundPolicy = () => {
  const user = useAppSelector((state) => state.Auth.user);
  const { flexShortenCutoff, buildDateMarkers } = useFlex();
  const isFlexPolicy24hEnabled = useIsFeatureFlagOn("flex-policy-24h");
  const preferredDateFormat = user?.business.preferredDateFormat || "mdy";
  const { reservation } = useModificationsContext();

  const dateRange = buildDateMarkers(reservation);
  const dateDisplay = !dateRange
    ? undefined
    : `(${formatDisplayDate(
        flexCoverageStartDate(reservation, dateRange),
        preferredDateFormat
      )} - ${formatDisplayDate(dateRange?.end, preferredDateFormat)})`;

  const isFlexAfterExpiration =
    reservation.flexEnabled &&
    moment().startOf("day").isAfter(flexShortenCutoff(reservation.checkOut));

  const noRefundAvailableCopy = isFlexAfterExpiration
    ? "Flex has expired for this trip."
    : reservation.flexEnabled && !reservation.modificationActions.flexShortenable
      ? "Flex coverage is limited to one trip length change per booking."
      : "No refund available for this reservation.";

  const { refundPolicy } = getCancelDateInfo({
    flexEnabled: false,
    cancelBy: reservation.cancelBy,
    propertyTimezone: reservation.propertyTimezone,
    checkIn: reservation.checkIn,
    preferredDateFormat,
    flexProEnrolled: reservation.flexType === "flex_pro",
  });

  const RefundNotAvailable = () => {
    return !dateRange ? (
      <Styled.RefundContent>
        <Styled.RefundRow>
          <Box paddingTop={4}>
            <Icon name="circle-exclamation" color="sentimentCautionaryDefault" />
          </Box>
          <Styled.InfoContainer>
            <Typography variant="body/md" color="sentimentCautionaryDefault">
              Non-refundable
            </Typography>
            <Typography variant="body/xs" color="foregroundSecondary">
              {noRefundAvailableCopy}
            </Typography>
          </Styled.InfoContainer>
        </Styled.RefundRow>
      </Styled.RefundContent>
    ) : null;
  };

  const RefundAvailable = () => {
    return !!dateRange?.policyExpiration || (!!reservation.flexEnabled && !!dateRange) ? (
      <Styled.RefundContent>
        {!!dateRange?.policyExpiration && (
          <>
            <Styled.RefundRow>
              <Box paddingTop={4}>
                <Icon name="circle-check--solid" color="accentGreenStrong" />
              </Box>
              <Styled.InfoContainer>
                <Typography variant="body/md" color="accentGreenStrong">
                  Fully refundable
                </Typography>
                <Typography variant="body/xs" color="foregroundSecondary">
                  before {refundPolicy}
                </Typography>
              </Styled.InfoContainer>
            </Styled.RefundRow>
          </>
        )}
        {!!reservation.flexEnabled && !!dateRange && (
          <Styled.RefundRow>
            <Box paddingTop={4}>
              <Icon name="he-flex" color="foregroundPrimary" />
            </Box>
            <Styled.InfoContainer>
              <Typography variant="body/md" color="foregroundPrimary">
                Flex Coverage {dateDisplay}
              </Typography>
              <Typography variant="body/xs" color="foregroundSecondary">
                Receive travel credit for unused nights with Flex Coverage,{" "}
                {isFlexPolicy24hEnabled
                  ? "even up to 24 hours after you check out"
                  : "even up to 48 hours after a trip"}
                .
              </Typography>
            </Styled.InfoContainer>
          </Styled.RefundRow>
        )}
      </Styled.RefundContent>
    ) : null;
  };

  return (
    <CollapsibleContent
      toggleTestID="toggle-refund-policy"
      toggleBtnLabel={
        <Box display="flex" gap={12} alignItems="center">
          <Typography variant="heading/lg">Refund policy</Typography>
        </Box>
      }
    >
      <Box paddingLeft={24} paddingRight={24} marginBottom={24}>
        <RefundNotAvailable />
        <RefundAvailable />
      </Box>
    </CollapsibleContent>
  );
};

export default ItineraryRefundPolicy;
