import type { V3LocationRecord } from "@hotel-engine/types/locations";
import type {
  GetPropsCommonOptions,
  UseComboboxGetItemPropsOptions,
  UseComboboxGetMenuPropsOptions,
} from "downshift";
import {
  isFavoriteProperty,
  isRecentSearch,
  isWorksite,
  returnSearchItemType,
} from "../../helpers";
import * as Styled from "../../styles";

export type AutoCompleteResultsProps = {
  autoCompleteDataState: string;
  comboBoxProps: {
    getMenuProps: (
      options?: UseComboboxGetMenuPropsOptions,
      otherOptions?: GetPropsCommonOptions
    ) => object;
    getItemProps: (options: UseComboboxGetItemPropsOptions<V3LocationRecord>) => object;
  };
  highlightedIndex: number;
  activeList: V3LocationRecord[];
  coldList: V3LocationRecord[];
  searchInput: string;
  showList?: boolean;
  isMobile?: boolean;
};

export const AutoCompleteResults = ({
  autoCompleteDataState,
  comboBoxProps: { getMenuProps, getItemProps },
  highlightedIndex,
  activeList,
  coldList,
  searchInput,
  showList = false,
  isMobile = false,
}: AutoCompleteResultsProps) => (
  <Styled.List
    {...getMenuProps(
      {
        id: "downshift-0-menu",
        "aria-label": "Search by city or address",
      },
      { suppressRefError: true }
    )}
    isMobile={isMobile}
  >
    {!!showList && (
      <div>
        {(() => {
          switch (autoCompleteDataState) {
            case "no-results":
              return <Styled.NoResultsListItem>No results found</Styled.NoResultsListItem>;
            case "show-cold-state":
              let hasFavorite = false;
              let hasRecent = false;
              let hasWorksites = false;

              return coldList.map((item, index) => {
                const isFirstFavorite = isFavoriteProperty(item) && !hasFavorite;
                const isFirstRecent = isRecentSearch(item) && !hasRecent;
                const isFirstWorksite = isWorksite(item) && !hasWorksites;

                if (isFirstFavorite) hasFavorite = isFirstFavorite;
                if (isFirstRecent) hasRecent = isFirstRecent;
                if (isFirstWorksite) hasWorksites = isFirstWorksite;

                const highlighted = highlightedIndex === index;
                const SearchItemComponent = returnSearchItemType(item, searchInput);

                const sectionTitle = isFirstFavorite
                  ? "Favorites"
                  : isFirstRecent
                    ? "Recent searches"
                    : isFirstWorksite
                      ? "Saved locations"
                      : null;
                return (
                  <Styled.ListItem
                    key={`${JSON.stringify(item)}-${index}`}
                    data-testid="location-item"
                    $sectionTitle={!!sectionTitle}
                  >
                    {!!sectionTitle && <Styled.ListItemTitle>{sectionTitle}</Styled.ListItemTitle>}
                    <Styled.ListItemContent
                      $isSelected={highlighted}
                      $isHighlighted={highlighted}
                      $sectionTitle={!!sectionTitle}
                      {...getItemProps({
                        item,
                        index,
                      })}
                    >
                      {SearchItemComponent}
                    </Styled.ListItemContent>
                  </Styled.ListItem>
                );
              });
            case "show-locations":
              return activeList.map((item, index) => {
                const highlighted = highlightedIndex === index;
                const SearchItemComponent = returnSearchItemType(item, searchInput);
                return (
                  <Styled.ListItem
                    key={`${JSON.stringify(item)}-${index}`}
                    data-testid="search-location-item"
                  >
                    <Styled.ListItemContent
                      $isSelected={highlighted}
                      $isHighlighted={highlighted}
                      {...getItemProps({
                        item,
                        index,
                      })}
                    >
                      {SearchItemComponent}
                    </Styled.ListItemContent>
                  </Styled.ListItem>
                );
              });
            default:
              return null;
          }
        })()}
      </div>
    )}
  </Styled.List>
);
