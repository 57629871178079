import styled from "styled-components";

export const TripsPreviewPanel = styled.div`
  position: absolute;
  left: ${({ theme }) => theme.spacing[8]};
  top: ${({ theme }) => theme.spacing[8]};
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  z-index: ${({ theme }) => theme.zIndex.modal};
  height: calc(100vh - 315px);
  width: 350px;
  max-height: 1110px;
`;

export const SectionLabel = styled.div<React.CSSProperties>`
  margin-bottom: ${({ theme }) => theme.spacing[16]};
  text-transform: capitalize;

  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing[16]};
  }
`;
