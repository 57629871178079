import { useFlightModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { Alert, Box, Divider, Typography } from "@hotelengine/atlas-web";
import SummaryOfChargesItem from "../../../shared/SummaryOfChargesItem";
import { useFlightSummaryOfCharges } from "./useFlightSummaryOfCharges";

import * as Styled from "./styles";

const SummaryOfCharges = () => {
  const { isPreview, reservation } = useFlightModificationsContext();
  const { items } = useFlightSummaryOfCharges(reservation?.charges);

  if (!reservation?.charges) {
    return null;
  }

  return (
    <Styled.SummaryOfChargesPaddedContainer $isPreview={isPreview}>
      <Typography variant="uppercase/xs-strong" marginBottom={8}>
        Payment Info
      </Typography>

      {items.map((item) => {
        if (item.label === "Travel credit comp description" && !item?.hideItem) {
          return (
            <div key={`basic-${item.label}`}>
              <Box marginTop={4} marginBottom={4}>
                <Alert
                  sentiment="helpful"
                  variant="outlined"
                  size="sm"
                  leadingIcon={null}
                  title="A travel credit comp has been issued for this booking."
                >
                  {item.value}
                </Alert>
              </Box>
            </div>
          );
        }

        if (item.label === "Total charges")
          return (
            <>
              <Box marginTop={8} marginBottom={8}>
                <Divider />
              </Box>
              <SummaryOfChargesItem
                {...item}
                labelColor="foregroundPrimary"
                typography="body/md-strong"
                isBold
              />
            </>
          );

        return (
          <div key={`basic-${item.label}`}>
            <Box marginTop={4} marginBottom={4}>
              <SummaryOfChargesItem
                labelColor="foregroundPrimary"
                valueColor="foregroundPrimary"
                {...item}
              />
            </Box>
          </div>
        );
      })}
    </Styled.SummaryOfChargesPaddedContainer>
  );
};

export default SummaryOfCharges;
