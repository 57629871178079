import type { Moment } from "moment";
import moment from "moment";

import type { IDateFormat } from "@hotel-engine/types/business";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import { formatDate } from "@hotel-engine/utilities";

import type { IDateMarker } from "./types";

export const calculateRangePercentage = (dateRange: IDateMarker, date: Moment) => {
  return (dateRange.start.diff(date, "days") / dateRange.start.diff(dateRange.end, "days")) * 100;
};

export const formatDisplayDate = (date: Moment, preferredDateFormat: IDateFormat) =>
  formatDate(date, "MMM D", preferredDateFormat);

export const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

export const flexCoverageStartDate = (reservation: IReservationBase, dateRange: IDateMarker) => {
  if (reservation.flexEnabled && !reservation.cancelBy) return dateRange.start;
  if (reservation.status === "visiting" && reservation.flexEnabled)
    return moment(reservation.checkIn);
  if (reservation.cancelBy) return moment(reservation.cancelBy);
  return dateRange.start;
};
