import styled, { css } from "styled-components";

import { List } from "@hotel-engine/common/Lists";
import Tag from "@hotel-engine/common/Tag";
import { visuallyHidden } from "@hotel-engine/styles";

export const EmailTag = styled(Tag)<{
  $highlight: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.neutralN50};
  color: ${({ theme }) => theme.colors.neutralN700};
  border-color: ${({ theme }) => theme.colors.neutralN400};
  border-radius: ${({ theme }) => theme.borderRadius.pill};
  margin: ${({ theme }) => theme.spacing[4]};
  height: 24px;
  display: flex;
  align-items: center;
  ${({ color }) =>
    color === "invalid-emails" &&
    css`
      border-color: ${({ theme }) => theme.colors.accentRed};
      background-color: ${({ theme }) => theme.colors.accentRedLightest};
    `};

  ${({ $highlight }) =>
    $highlight &&
    css`
      background-color: ${({ theme }) => theme.colors.accentBlueLightest};
      border-color: ${({ theme }) => theme.colors.accentBlue};
    `}
`;

export const ListItem = styled.span<{ isHidden?: boolean }>`
  transition: all 0.3s;
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
  ${({ isHidden }) => isHidden && visuallyHidden}
`;

// antd input has too much decorations to turn off. can't turn some of them off.
export const NewEmailInput = styled.input<{ isHidden: boolean }>`
  transition: all 0.3s;
  border: 0;
  padding: ${({ theme }) => theme.spacing[2]};
  width: 100%;
  align-items: center;
  ::placeholder {
    color: ${({ theme }) => theme.colors.inputForegroundPlaceholder};
  }
  color: ${({ theme }) => theme.colors.foregroundPrimary};
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  &:focus {
    border: 0;
  }
  ${({ isHidden }) => isHidden && visuallyHidden}
`;

interface IListLike {
  tabIndex: number;
  onClick: (ev: React.MouseEvent) => void;
  onMouseDown: (ev: React.MouseEvent) => void;
  onBlur: (ev: React.MouseEvent) => void;
  onKeyUp: (ev: React.KeyboardEvent) => void;
  onFocus: (ev: React.FocusEvent) => void;
  $hasFocus: boolean;
  $isValid: boolean;
}

export const PillContainer = styled(List)<IListLike>`
margin-top: 6px;
  transition: all 0.3s;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  border: ${({ theme }) => `${theme.borderWidth.normal} solid ${theme.colors.borderDefault}`};
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  overflow: hidden;
  &:hover {
    ${({ $isValid, theme }) =>
      css`
        border: 1px solid ${$isValid ? theme.colors.foregroundPrimary : theme.colors.accentRed};
      `}
  }
  & > div {
    width: 100%;
    min-height: 40px;
    padding: 8px;
  }
  & > div > div {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0 !important;
  }
  & > div > div > span:last-child {
    flex-grow: 1;
  }
  ${({ $hasFocus, $isValid }) =>
    $isValid &&
    $hasFocus &&
    css`
      border: ${({ theme }) =>
        `${theme.borderWidth.normal} solid ${theme.colors.foregroundPrimary}`};
    `}

  ${({ $isValid, theme }) =>
    !$isValid &&
    css`
      border: ${`${theme.borderWidth.normal} solid ${theme.colors.accentRed}`};
    `}
`;

// we are treating this dom element as an interactive element and antd does not easily provide that for its list item
export const Pill = styled.li`
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  position: relative;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  border: ${({ theme }) => `${theme.borderWidth.normal} solid ${theme.colors.neutralN300}`};
  border-radius: ${({ theme }) => theme.borderRadius.pill};
`;

// custom behavior
export const IconButton = styled.button`
  margin-left: ${({ theme }) => theme.spacing[8]};
  padding: 0;
  cursor: pointer;
  z-index: 204 /* disable theming (do not replicate) */;
  border: 0;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.foregroundPrimary};
  display: flex;
  align-items: center;
`;

// used to track width of 14px font size width so that input can expand / contract based on value
// must match PillInput
export const InvisibleText = styled.span`
  font-size: ${({ theme }) => theme.legacy.fontSize.sm};
  padding: 0px 8px 2px 8px;
  color: black;
  z-index: ${({ theme }) => theme.legacy.zIndex.belowBackdrop};
  position: absolute;
`;

// antd inputs do not allow programmatic selection of text
export const PillInput = styled.input`
  max-height: ${({ theme }) => theme.legacy.lineHeight.md};
  font-size: ${({ theme }) => theme.legacy.fontSize.sm};
  background-color: transparent;

  box-shadow: 0px 0px 0px 2px transparent;
  border: 1px solid transparent;
  list-style: none;
  margin: 4px;
  vertical-align: top;
`;

export const PillInputMimic = styled.span`
  font-size: ${({ theme }) => theme.legacy.fontSize.xs};
  margin: auto;
`;
