import styled from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const sidebarMdDesktopWidth = 380;

export const TripsPreviewPanel = styled.div<{ $isFlight?: boolean }>`
  ${flexbox({
    direction: "column",
  })}

  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  border-radius: 0;
  box-shadow: none;
  max-height: 100vh;
  height: ${({ $isFlight }) => !$isFlight && "100%"};
  min-width: 100%;
  position: relative;
  width: ${sidebarMdDesktopWidth}px;
  transition: all 0.175s linear;
`;

export const ScrollContainer = styled.div`
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
`;
