import { Link } from "@hotel-engine/lib/react-router-dom";
import styled from "styled-components";

import { colors } from "@hotel-engine/styles";

export const Container = styled(Link)`
  display: flex;
  position: relative;
  z-index: 10 /* disable theming (do not replicate) */;
  .count-wrapper {
    display: flex;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
    width: max-content;
    border: 1px solid ${colors.retroBlue};
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
    height: 32px;
    padding: 5px 12px;
    align-self: center;

    .text {
      color: ${colors.retroBlue};
    }

    .count {
      background: ${colors.retroBlue};
      padding: 1px 6px;
      color: ${colors.white};
      margin-left: 8px;
      border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.xs};
      min-width: 20px;
      line-height: 17px;
      height: 22px;

      span {
        height: 16px;
        font-size: 12px;
        align-items: center;
        text-align: center;
      }
    }
  }

  &:hover {
    .select-tooltip {
      transition: opacity 0.25s ease-in-out;
      opacity: 1;
    }
  }
`;

export const Tooltip = styled.div`
  background: ${colors.black};
  color: ${colors.white};
  position: absolute;
  top: calc(100% + 12px);
  right: -8px;
  width: 169px;
  padding: 16px;
  z-index: ${({ theme }) => theme.legacy.zIndex.aboveBackdrop};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
  opacity: 0;
  pointer-events: none;
  font-weight: 600;

  p {
    margin-bottom: 0;
  }

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-bottom: solid 10px #000000;
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
    position: absolute;
    top: -10px;
    right: 140px;
  }
`;
