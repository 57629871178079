import { Alert } from "@hotelengine/atlas-web";
import { formatCurrency } from "@hotel-engine/utilities";
import * as Styled from "./styles";

const FullRefundBanner = ({ penaltyFee }: { penaltyFee?: string }) => {
  const formattedPenalty = formatCurrency(penaltyFee || 0);
  const title =
    !penaltyFee || Number(penaltyFee) === 0
      ? "Cancel today free of charge"
      : `Cancel today for a fee of ${formattedPenalty}`;

  return (
    <Styled.Container>
      <Alert
        leadingIcon="circle-check"
        title={title}
        variant="outlined"
        sentiment="positive"
        size="sm"
        className="refund-alert"
      />
    </Styled.Container>
  );
};

export default FullRefundBanner;
