import styled from "styled-components";
import { Box, type BoxProps } from "@hotelengine/atlas-web";

export const CheckboxWrapper = styled<React.FC<BoxProps>>(Box)`
  padding: ${({ theme }) => theme.spacing[16]};
`;

export const StateContainer = styled.div`
  display: flex;
  margin-top: 4px;
  justify-content: center;
`;
