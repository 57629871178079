import { Typography } from "@hotelengine/atlas-web";
import moment from "moment";

import { useBreakpoint, useFormatDate } from "@hotel-engine/hooks";
import { useCompanyName } from "@hotel-engine/hooks/useCompanyName";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import ItineraryDateRange from "../ItineraryDateRange";
import { areRoomDatesDifferent } from "../../../helpers/helpers";
import TripExtensions from "./TripExtensions";
import { useTypographyScaling } from "../../../hooks/useTypographyScaling";
import * as Styled from "./styles";

const TripInfo = () => {
  const { COMPANY_NAME } = useCompanyName();
  const isMobile = useBreakpoint("lg", "max");
  const { reservation: itinerary, isPreview } = useModificationsContext();

  const isMobileOrPreview = isPreview || isMobile;
  const { typographyVariants } = useTypographyScaling();
  const enableDatesForTrip = itinerary.roomCount === 1 || !areRoomDatesDifferent(itinerary);
  const enableTripExtensions =
    !!itinerary.tripExtensions?.previousContractNumber ||
    !!itinerary.tripExtensions?.subsequentContractNumber ||
    !!itinerary.tripExtensions?.subsequentBookings.length;
  const dateBooked = moment(itinerary.dateBooked).utc().startOf("day");
  const bookedOn = useFormatDate(dateBooked, "MMM D YYYY");
  const isGroup = itinerary.roomCount > 1;

  return (
    <Styled.TripDetails $isPreview={isMobileOrPreview}>
      <Typography variant={typographyVariants.title} color="foregroundPrimary">
        Trip details
      </Typography>
      {!!enableTripExtensions && (
        <TripExtensions extensions={itinerary.tripExtensions} isGroup={isGroup} />
      )}
      {!!enableDatesForTrip && <ItineraryDateRange itinerary={itinerary} isPreview={isPreview} />}
      <Styled.DetailsList>
        <Styled.ListItem>
          <Typography variant={typographyVariants.body} color="foregroundSecondary">
            Rooms
          </Typography>
          <Typography variant={typographyVariants.body} color="foregroundPrimary">
            {itinerary.roomCount}
          </Typography>
        </Styled.ListItem>
        <Styled.ListItem>
          <Typography variant={typographyVariants.body} color="foregroundSecondary">
            Guests
          </Typography>
          <Typography variant={typographyVariants.body} color="foregroundPrimary">
            {itinerary.guestCount}
          </Typography>
        </Styled.ListItem>
        <Styled.ListItem>
          <Typography variant={typographyVariants.body} color="foregroundSecondary">
            Booked by
          </Typography>
          <Typography
            variant={typographyVariants.body}
            color="foregroundPrimary"
            data-testid="booked-by"
          >
            {itinerary.bookedBy}
          </Typography>
        </Styled.ListItem>
        <Styled.ListItem>
          <Typography variant={typographyVariants.body} color="foregroundSecondary">
            Booked on
          </Typography>
          <Typography
            variant={typographyVariants.body}
            color="foregroundPrimary"
            data-testid="booked-on"
          >
            {bookedOn}
          </Typography>
        </Styled.ListItem>
        <Styled.ListItem>
          <Typography variant={typographyVariants.body} color="foregroundSecondary">
            {itinerary.propertyConfirmationNumber ? "Confirmation #" : `${COMPANY_NAME} #`}
          </Typography>
          <Typography variant={typographyVariants.body} color="foregroundPrimary">
            {itinerary.propertyConfirmationNumber || itinerary.id}
          </Typography>
        </Styled.ListItem>
      </Styled.DetailsList>
    </Styled.TripDetails>
  );
};

export default TripInfo;
