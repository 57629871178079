import type { Dispatch } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useCarModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useSubmitCancelQuery } from "@hotel-engine/react-query/bookings/useSubmitCancelQuery";
import { captureMessage } from "@hotel-engine/utilities";
import { Modal } from "@hotel-engine/common/Modal";
import { endpoints } from "@hotel-engine/react-query/constants";
import { queryClient } from "@hotel-engine/contexts";

import type { ICarItineraryWithId } from "pages/Trips/components/TripsItineraryPreviewPanel/car";

import type { CarRefundType } from "./usePreviewCarRefund";

interface IUseCancelCarParams {
  /** Current contract */
  reservation: ICarItineraryWithId;
  /** Dispatch from ModificationsContext */
  dispatch: Dispatch;
  /** shows success modal if multi-room cancellation */
  showStatusModal: () => void;
  /** Total refund amount and type updated as rooms are selected for cancellation */
  refundInfo: {
    totalRefundAmount: number | null;
    refundType: CarRefundType | undefined;
    penaltyFee?: string;
  };
}

export const useCancelCar = ({
  dispatch,
  showStatusModal,
  refundInfo,
  reservation,
}: IUseCancelCarParams) => {
  const {
    state: { modificationView },
  } = useCarModificationsContext();

  const setLoading = () => {
    showStatusModal();
    dispatch({
      type: "SET_MODIFICATION_STATUS",
      payload: { isLoading: true, isError: false },
    });
  };

  const handleSuccess = () => {
    dispatch({
      type: "SET_MODIFICATION_INFO",
      payload: {
        refundAmount: Number(refundInfo.totalRefundAmount),
        refundType: refundInfo.refundType,
        modificationType: modificationView,
        penaltyFee: Number(refundInfo?.penaltyFee),
        bookingType: "car",
      },
    });
    dispatch({
      type: "SET_MODIFICATION_STATUS",
      payload: { isLoading: false, isSubmitted: true },
    });
  };

  const handleError = (error?: unknown) => {
    dispatch({
      type: "SET_MODIFICATION_STATUS",
      payload: { isLoading: false, isError: true },
    });
    dispatch({ type: "SET_SHOW_STATUS_MODAL", payload: false });
    Modal.error({
      title: "Error cancelling car",
      content: "An error occurred while trying to cancel this car. Try again later.",
      okText: "Ok",
    });

    if (error) {
      captureMessage("Error cancelling car", {
        contractId: reservation.tripId,
        error,
      });
    }
  };

  const cancelCar = useSubmitCancelQuery({ id: reservation.tripId, bookingType: "car" });

  const submitCancelCar = () => {
    setLoading();

    try {
      cancelCar.mutate(
        {
          bookingNumber: reservation.bookingNumber,
          bookingType: "car",
        },
        {
          onSuccess: async () => {
            handleSuccess();
            await queryClient.invalidateQueries(endpoints.contracts);
            await queryClient.invalidateQueries(endpoints.trips);
            await queryClient.invalidateQueries(endpoints.reservations);
            return;
          },
          onError: async () => {
            return handleError();
          },
        }
      );
    } catch (error) {
      return handleError(error);
    }
  };

  return { submitCancelCar };
};
