import moment from "moment";
import { ACRISSCategory } from "pages/Checkout/Cars/types/car.types";
import type { ICarsEntry, ICarsFiltersSet, ICarsVendor } from "../types";

export const getAllAcrissSimilarCategories = (
  acrissCategoryCodes: Array<string>
): Array<ACRISSCategory> => {
  return acrissCategoryCodes
    .map((acrissCategoryCode) => {
      switch (acrissCategoryCode) {
        case ACRISSCategory.MINI:
        case ACRISSCategory.MINI_ELITE:
          return [ACRISSCategory.MINI, ACRISSCategory.MINI_ELITE];
        case ACRISSCategory.ECONOMY:
        case ACRISSCategory.ECONOMY_ELITE:
          return [ACRISSCategory.ECONOMY, ACRISSCategory.ECONOMY_ELITE];
        case ACRISSCategory.COMPACT:
        case ACRISSCategory.COMPACT_ELITE:
          return [ACRISSCategory.COMPACT, ACRISSCategory.COMPACT_ELITE];
        case ACRISSCategory.INTERMEDIATE:
        case ACRISSCategory.INTERMEDIATE_ELITE:
          return [ACRISSCategory.INTERMEDIATE, ACRISSCategory.INTERMEDIATE_ELITE];
        case ACRISSCategory.STANDARD:
        case ACRISSCategory.STANDARD_ELITE:
          return [ACRISSCategory.STANDARD, ACRISSCategory.STANDARD_ELITE];
        case ACRISSCategory.FULLSIZE:
        case ACRISSCategory.FULLSIZE_ELITE:
          return [ACRISSCategory.FULLSIZE, ACRISSCategory.FULLSIZE_ELITE];
        case ACRISSCategory.PREMIMUM:
        case ACRISSCategory.PREMIMUM_ELITE:
          return [ACRISSCategory.PREMIMUM, ACRISSCategory.PREMIMUM_ELITE];
        case ACRISSCategory.LUXURY:
        case ACRISSCategory.LUXURY_ELITE:
          return [ACRISSCategory.LUXURY, ACRISSCategory.LUXURY_ELITE];
        case ACRISSCategory.OVERSIZE:
          return [ACRISSCategory.OVERSIZE];
        case ACRISSCategory.SPECIAL:
          return [ACRISSCategory.SPECIAL];
        default:
          return [];
      }
    })
    .reduce((acc, curr) => acc.concat(curr));
};

export function getVendor(vendorId: string, vendors: ICarsVendor[]): ICarsVendor | undefined {
  return vendors.find((vendor) => vendor.id === vendorId);
}

export function getVehicleTypes(cars: ICarsEntry[], vendors: ICarsVendor[]) {
  if (!cars?.length || !vendors?.length) return [];

  const vehicleTypes: ICarsFiltersSet = cars.reduce((acc: ICarsFiltersSet, vehicle: ICarsEntry) => {
    const type = vehicle.vehicleDetails.categoryCode;
    if (acc[vehicle.vehicleDetails.categoryCode] === undefined) {
      return {
        ...acc,
        [type]: {
          id: type,
          label: type,
        },
      };
    }
    return acc;
  }, {} as ICarsFiltersSet);

  const vehicleTypesArray = Object.values(vehicleTypes);

  // Alphabetic sorting the vehicle types
  return vehicleTypesArray.sort((a, b) => a.label.localeCompare(b.label));
}

export function getRentalCompanies(cars: ICarsEntry[], vendors: ICarsVendor[]) {
  if (!cars?.length || !vendors?.length) return [];

  const companies = cars.reduce((acc: ICarsFiltersSet, vehicle: ICarsEntry) => {
    if (acc[vehicle.pickupVendorId] === undefined) {
      const vendor = getVendor(vehicle.pickupVendorId, vendors);

      // Vendors
      if (!vendor) return acc;

      return {
        ...acc,
        [vendor.code]: {
          id: vendor.code,
          label: vendor.name,
        },
      };
    }
    return acc;
  }, {} as ICarsFiltersSet);

  const companiesArray = Object.values(companies);

  // Alphabetic sorting the vehicle types
  return companiesArray.sort((a, b) => a.label.localeCompare(b.label));
}

export function getExpensiveCar(cars: ICarsEntry[]) {
  if (cars.length === 0) return 0;

  const expensiveCar = cars.sort(
    (a, b) => parseFloat(a.pricing.total) - parseFloat(b.pricing.total)
  );

  return Math.ceil(parseFloat(expensiveCar[cars.length - 1].pricing.total));
}

// pick-up date to soonest Monday to Thursday and drop-off date to next day
export function defaultPickupDropoffDateTimes(values?) {
  if (
    values &&
    values.pickupDate &&
    values.pickupTime &&
    values.dropoffDate &&
    values.dropoffTime
  ) {
    return {
      pickupDate: values.pickupDate,
      pickupTime: values.pickupTime,
      dropoffDate: values.dropoffDate,
      dropoffTime: values.dropoffTime,
    };
  }

  const minDate = moment().add(2, "hours");
  const weekdate = minDate.isoWeekday();
  const isMinDateToday = weekdate < 5;
  const pickup = isMinDateToday ? minDate : minDate.clone().add(8 - weekdate, "days");
  const dropoff = pickup.clone().add(1, "days");
  const defaultTime =
    isMinDateToday && minDate.hour() >= 11 ? parseInt(minDate.format("HH"), 10) : 11;

  return {
    pickupDate: pickup,
    pickupTime: pickup.clone().hour(defaultTime).minute(0),
    dropoffDate: dropoff,
    dropoffTime: dropoff.clone().hour(defaultTime).minute(0),
  };
}

export function getIconType({ types }: google.maps.places.AutocompletePrediction) {
  if (!types) {
    return "location-dot--solid";
  }

  return types.includes("airport") ? "plane--solid" : "location-dot--solid";
}
