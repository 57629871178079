import { mergeDeepRight } from "ramda";

import storage from "@hotel-engine/storage";
import type { WithoutHosts } from "config";

/**
 * Sets the key redux-persist stores the redux store under in localStorage. Increment
 * this value to be today's date if you need redux-persist to ignore prior saved values.
 */
export const reduxPersistKey = "2021-12-01";

/**
 * Selects the correct config values to use based on the environment.
 */
export const select = (config: WithoutHosts) => {
  try {
    switch (import.meta.env.VITE_STAGE) {
      case "dev":
      case "preview":
        return createModifiedConfig(config);
      case "production":
      case "test":
      default:
        return config;
    }
  } catch (e) {
    console.error(e);

    return config;
  }
};

/*
 * Attempts to use redux stored environment values to override config values.
 */
export const createModifiedConfig = <T extends WithoutHosts>(config: T) => {
  let modifications = {};

  try {
    const { EnvMods } = JSON.parse(storage.getItem("persist:" + reduxPersistKey) || "");

    modifications = JSON.parse(EnvMods);
  } catch {
    return config;
  }

  return mergeDeepRight(config, modifications);
};
