import { useAppSelector } from "store/hooks";
import AlertBanner from "@hotel-engine/common/AlertBanner";

export const ImpersonationBanner = () => {
  const user = useAppSelector((state) => state.Auth?.user);

  if (!user?.impersonatorId) {
    return null;
  }

  return (
    <AlertBanner
      description={`User ID: ${user?.id} User Name: ${user?.firstName} ${user?.lastName}`}
      message={"You are logged in as an impersonated user. Log out to end session."}
      banner
      showIcon
      type="warning"
      iconWeightVariant={"fas"}
    />
  );
};
