import styled from "styled-components";

import { Button } from "@hotelengine/atlas-web";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";

export const SwitchButton = styled(Button)`
  ${typographyMixin("small")}
  border-radius: 100px /* disable theming (do not replicate) */;
  margin-left: ${({ theme }) => theme.legacy.space(2)};
  font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
  color: ${({ theme }) => theme.colors.foregroundPrimary};
`;
