import { Icon, Typography, type IconProps } from "@hotelengine/atlas-web";
import { getAutocompleteIconInfo } from "@hotel-engine/app/SearchForm/helpers";
import type { ILocationRecord } from "@hotel-engine/types/locations";
import type { IWorksite } from "@hotel-engine/types/worksite";

import { getLocationDescription, weightSubstringMatch } from "../../helpers";
import { isWorksite } from "../../helpers/typeHelpers";

import * as Styled from "../../styles";

export type ApiSearchResultProps = {
  item: ILocationRecord | IWorksite;
  searchInput: string;
};
const ApiSearchResult = ({ item, searchInput }: ApiSearchResultProps) => {
  const { lineOne, lineTwo } = getLocationDescription(item);

  const searchResult = lineOne || "";
  const searchTitle = weightSubstringMatch(searchInput, searchResult);

  const { iconName, iconStyles } = getAutocompleteIconInfo(
    isWorksite(item) ? ["worksite"] : (item as ILocationRecord).types
  );

  return (
    <div>
      <Styled.LocationPickerOptionIcon style={iconStyles}>
        <Icon name={iconName as IconProps["name"]} data-testid={item.id} />
      </Styled.LocationPickerOptionIcon>
      <Styled.LocationPickerOptionValue>
        <Typography
          className="description description--primary-ff"
          color="foregroundPrimary"
          data-testid={iconName}
          variant="body/md"
        >
          {searchTitle}
        </Typography>
        {!!lineTwo && (
          <Typography
            className="description description--secondary"
            color="foregroundSecondary"
            variant="body/xs"
          >
            {lineTwo}
          </Typography>
        )}
      </Styled.LocationPickerOptionValue>
    </div>
  );
};

export default ApiSearchResult;
