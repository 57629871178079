import { useEffect, useRef } from "react";
import config from "config";
import Logrocket from "logrocket";
import { LogrocketFuzzySearch } from "@hotel-engine/scripts/ThirdPartyScripts/logRocketPlugin";
import { useAppSelector } from "store/hooks";
import { useGetAppVersion } from "@hotel-engine/scripts/hooks/useGetAppVersion";

const redactedFields = ["password", "api_key", "accessToken", "authorization"];

const { requestSanitizer, responseSanitizer } = LogrocketFuzzySearch.setup(redactedFields);

export const useLogrocketInit = () => {
  const hasIdentified = useRef<boolean>(false);
  const userId = useAppSelector((store) => store.Auth.user?.id);
  const userEmail = useAppSelector((store) => store.Auth.user?.email);
  const userRole = useAppSelector((store) => store.Auth.user?.role);

  const { fetchedValue } = useGetAppVersion();

  useEffect(() => {
    const release = fetchedValue?.substring(fetchedValue?.indexOf("@") + 1);
    const releaseFetched = !!release;

    if (config.stage === "production" && releaseFetched) {
      Logrocket.init("c8gpr6/members-client", {
        network: { requestSanitizer, responseSanitizer },
        release,
      });
    } else if (!globalThis.Cypress && releaseFetched) {
      Logrocket.init("c8gpr6/integration-test", {
        network: { requestSanitizer, responseSanitizer },
        release,
      });
    }
  }, [fetchedValue]);

  useEffect(() => {
    if (!!userId && hasIdentified.current === false) {
      Logrocket.identify(String(userId), {
        email: String(userEmail),
        role: String(userRole),
      });
      hasIdentified.current = true;
    }
  }, [userId, userEmail, userRole]);

  useEffect(() => {
    const LRHandleVisibilityChange = () => {
      if (Logrocket && Logrocket.info) {
        Logrocket.info("visibility-change", {
          visibilityState: document.visibilityState,
        });
      }
    };
    document.addEventListener("visibilitychange", LRHandleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", LRHandleVisibilityChange);
    };
  }, []);
};
