import type { PropsWithChildren } from "react";
import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import GoogleMapsService from "@hotel-engine/services/GoogleMapsService";
import type { MapPosition } from "../../types";
import { getPreviewPanelPosition } from "./getPreviewPanelPosition";
import { usePrevious } from "@hotel-engine/hooks";

export type OverlayViewState = void | google.maps.OverlayView;

type OverlayProps = PropsWithChildren<{
  /** Used to position overlay on the map */
  position: MapPosition;
  /** Which layer/pane of the map to put the overlay into */
  pane?: keyof google.maps.MapPanes;
  /** Google map instance */
  map: google.maps.Map;
  /** If this overlay is for the preview panel */
  isPreviewPanel?: boolean;
  /** Used for positioning the preview panel in the map when it is close to the bottom */
  verticalPanelOffset?: number;
  /** zIndex to be applied to the overlay, used for closely grouped pins, showing active pin over others */
  zIndex?: number;
}>;

export default function OverlayView({
  position,
  pane = "floatPane",
  map,
  children,
  isPreviewPanel = false,
  verticalPanelOffset = 0,
  zIndex = 3,
}: OverlayProps) {
  const [overlay, setOverlay] = useState<OverlayViewState>();
  const googleMapsService = GoogleMapsService();
  /** Create container for custom markers */
  const container = useMemo(() => {
    const div = document.createElement("div");
    div.style.position = "absolute";
    div.style.zIndex = String(zIndex);
    return div;
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const previousPosition = usePrevious(position);
  const previouszIndex = usePrevious(zIndex);

  /** If a new zIndex is passed in, update the style on the container but don't re-init.
   * Used for search result pins where we want a selected pin to be in front then go back
   * after de-selection
   */
  useEffect(() => {
    if (!!container && zIndex !== previouszIndex) {
      container.style.zIndex = String(zIndex);
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zIndex]);

  /** Initialize the overlay and set it to state -- we only want to do this if it has not happened yet,
   * or there is information that would require a re-draw, otherwise we run into some strange flickering
   * issues due to excessive re-draws
   */
  useEffect(() => {
    if (
      position.lat !== previousPosition?.lat ||
      position.lng !== previousPosition?.lng ||
      isPreviewPanel
    ) {
      googleMapsService.initOverlay({ container, pane, position }, setOverlay);
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container, pane, position]);

  /** Once the overlay is created apply it to the map
   * If the overlay is for the property preview panel, calculate its position so that it never goes off the map
   * Remove it on unmount
   */
  useEffect(() => {
    overlay?.setMap(map);

    if (isPreviewPanel && !!overlay) {
      const previewPanelPosition = getPreviewPanelPosition(overlay, position, verticalPanelOffset);

      overlay?.setValues({ position: previewPanelPosition });
    }

    return () => overlay?.setMap(null);
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, overlay]);

  return createPortal(children, container);
}
