import * as Styled from "./styles";
import Button from "@hotel-engine/common/Button";
import { openSalesForceChat } from "@hotel-engine/scripts/hooks";
import type {
  ISupportBillingInquiry,
  ISupportCase,
  ISupportCancelRequest,
  ISupportDateChange,
  ISupportNameChange,
  ISupportSpecialRequest,
  ISubStatus,
  ILodgingBooking,
  BookingDetailsType,
  IFlightBooking,
} from "@hotel-engine/types/supportCase";
import BookingDetails from "./components/BookingDetails";
import ActivityDetails from "./components/ActivityDetails";
import Typography from "@hotel-engine/common/Typography";
import { getCaseTypeDetails } from "./helpers";
import CancelRequest from "./components/CancelRequest";
import type { ICaseTypes } from "./types";
import DateChange from "./components/DateChange";
import NameChange from "./components/NameChange";
import BillingInquiry from "./components/BillingInquiry";
import SpecialRequest from "./components/SpecialRequest";
import { ampli } from "ampli";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { useSingleSupportCaseQuery } from "@hotel-engine/react-query/supportCenter/useSingleSupportCaseQuery";
import { useEffect, useState } from "react";
import SupportCaseSpinner from "./components/SupportCaseSpinner";
import { SupportCaseDrawerActions } from "store/components/SupportCaseDrawer/SupportCaseDrawerRedux";
import { useExperiment } from "@hotel-engine/react-query/amplitude/useExperimentQuery";
import { initI18n } from "@hotel-engine/i18n";

export interface ISupportCaseDrawer {
  caseId: number | undefined;
  showDrawer: boolean;
}

initI18n();

const SupportCaseDrawer = ({ caseId, showDrawer }: ISupportCaseDrawer) => {
  const MIAWRollout = useExperiment("sf-miaw-rollout", { value: "off" });
  const sfMIAWFeatureFlagEnabled = MIAWRollout.data.value === "on";
  const user = useAppSelector((state) => state.Auth.user);
  const dispatch = useAppDispatch();

  const [fetchSingleSupportCase, setFetchSingleSupportCase] = useState(false);
  const [supportCase, setSupportCase] = useState<ISupportCase | undefined>(undefined);

  const isFlightBooking = supportCase?.bookingType === "flight";
  // IGNORE-REASON ENS-2599 ⭐ fix me for good karma
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const singleSupportCaseQuery = useSingleSupportCaseQuery(caseId!, {
    enabled: fetchSingleSupportCase,
    onSuccess: () => {
      setFetchSingleSupportCase(false);
    },
    onError: () => {
      setFetchSingleSupportCase(false);
    },
  });

  useEffect(() => {
    if (caseId) {
      setFetchSingleSupportCase(true);
    }
  }, [caseId]);

  /**
   * open a support case on the drawer when clicked from notifications
   */
  useEffect(() => {
    const supportCaseFromNotification = singleSupportCaseQuery.data;
    if (!!supportCaseFromNotification) {
      setSupportCase(supportCaseFromNotification);

      dispatch(
        SupportCaseDrawerActions.setSupportCaseDrawerState({
          caseId,
          showDrawer: true,
        })
      );
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSingleSupportCase]);

  if (!supportCase) {
    return (
      <Styled.SupportCaseDrawer visible={showDrawer} destroyOnClose title="Case Details">
        <SupportCaseSpinner />
      </Styled.SupportCaseDrawer>
    );
  }

  const subType = supportCase.subType as ICaseTypes;

  const caseNumber = supportCase.caseNumber;

  const status: string = supportCase.status;
  const subStatus: ISubStatus | undefined = supportCase.subStatus;

  const { alertBannerIcon, alertBannerStatus, description, message } =
    getCaseTypeDetails(supportCase);

  const closeDrawer = () => {
    dispatch(
      SupportCaseDrawerActions.setSupportCaseDrawerState({
        caseId: undefined,
        showDrawer: false,
      })
    );
  };

  const salesForceChat = () => {
    ampli.clickCaseWannaTalkLink({ caseId: supportCase.id, userId: user?.id });
    openSalesForceChat();
  };

  const flightBookingData: BookingDetailsType = {
    data: supportCase.booking as IFlightBooking,
    isFlightBooking: true,
  };
  const lodgingBookingData: BookingDetailsType = {
    data: supportCase.booking as ILodgingBooking,
    isFlightBooking: false,
  };

  return (
    <Styled.SupportCaseDrawer
      visible={showDrawer}
      destroyOnClose
      onClose={closeDrawer}
      title="Case Details"
      zIndex={3000} // we want the drawer to be above the notification dropdown when it appears
    >
      {singleSupportCaseQuery.isFetching ? (
        <SupportCaseSpinner />
      ) : (
        <>
          <Styled.Row>
            <div>
              <Typography as="h1" variant="xlarge" data-testid="support-case-drawer-subtype">
                {subType}
              </Typography>
              {!!caseNumber && (
                <Typography as="p" variant="body-medium">
                  Case #{caseNumber}
                </Typography>
              )}
            </div>
            <Styled.StatusLabel isActive={status === "Active"}>
              {subStatus || status}
            </Styled.StatusLabel>
          </Styled.Row>

          {!isFlightBooking &&
            (() => {
              switch (subType) {
                case "Cancel Request":
                case "Cancellation Request":
                  return (
                    <CancelRequest
                      details={supportCase.details as ISupportCancelRequest}
                      booking={supportCase.booking as ILodgingBooking}
                      bannerStatus={alertBannerStatus}
                    />
                  );
                case "Date Change":
                  return <DateChange details={supportCase.details as ISupportDateChange} />;
                case "Name Change":
                  return (
                    <NameChange
                      details={supportCase.details as ISupportNameChange}
                      booking={supportCase.booking as ILodgingBooking}
                    />
                  );
                case "Billing Inquiry":
                  return (
                    <BillingInquiry
                      details={supportCase.details as ISupportBillingInquiry}
                      bannerStatus={alertBannerStatus}
                    />
                  );
                case "Special Request":
                  return <SpecialRequest details={supportCase.details as ISupportSpecialRequest} />;
                default:
                  return null;
              }
            })()}

          {!!message && (
            <Styled.SupportAlertBanner
              data-testid="alertBanner"
              status={alertBannerStatus}
              message={<Typography variant="small">{message}</Typography>}
              description={<p>{description}</p>}
              customIcon={alertBannerIcon}
            />
          )}

          <Styled.FieldDivider />

          <ActivityDetails supportCase={supportCase} />

          <Styled.FieldDivider />

          {!!isFlightBooking ? (
            <BookingDetails booking={flightBookingData} closeDrawer={closeDrawer} />
          ) : (
            <BookingDetails booking={lodgingBookingData} />
          )}

          <Styled.FieldDivider />

          {!sfMIAWFeatureFlagEnabled && (
            <>
              <Typography as="h2" variant="small">
                Want to talk?
              </Typography>

              <Button
                className="size-adjustment"
                type="link"
                onClick={salesForceChat}
                data-testid="support-case-drawer-live-chat"
              >
                Live Chat
              </Button>
            </>
          )}
        </>
      )}
    </Styled.SupportCaseDrawer>
  );
};

export default SupportCaseDrawer;
