import { Box, Divider, Typography, useTheme } from "@hotelengine/atlas-web";
import moment from "moment";

import { useFormatDate } from "@hotel-engine/hooks";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import ItineraryDateRange from "../ItineraryDateRange";
import { areRoomDatesDifferent } from "../../../helpers/helpers";
import TripExtensions from "./TripExtensions";
import * as Styled from "./styles";
import { CollapsibleContent } from "../../../shared/CollapsibleContent";

const TripInfo = () => {
  const { tokens } = useTheme();
  const { reservation: itinerary } = useModificationsContext();

  const enableDatesForTrip = itinerary.roomCount === 1 || !areRoomDatesDifferent(itinerary);
  const enableTripExtensions =
    !!itinerary.tripExtensions?.previousContractNumber ||
    !!itinerary.tripExtensions?.subsequentContractNumber ||
    !!itinerary.tripExtensions?.subsequentBookings.length;
  const dateBooked = moment(itinerary.dateBooked).utc().startOf("day");
  const bookedOn = useFormatDate(dateBooked, "MMM D YYYY");
  const isGroup = itinerary.roomCount > 1;

  const content = (
    <Styled.TripDetails>
      {!!enableTripExtensions && (
        <TripExtensions extensions={itinerary.tripExtensions} isGroup={isGroup} />
      )}
      {!!enableDatesForTrip && (
        <>
          <ItineraryDateRange itinerary={itinerary} />
          <Divider variant="dotted" style={{ borderColor: tokens.colors.dividerSecondary }} />
        </>
      )}
      <Styled.DetailsList>
        <Styled.ListItem>
          <Typography variant="body/sm" color="foregroundSecondary">
            Booked by
          </Typography>
          <Typography variant="body/sm" color="foregroundPrimary" data-testid="booked-by">
            {itinerary.bookedBy}
          </Typography>
        </Styled.ListItem>
        <Styled.ListItem>
          <Typography variant="body/sm" color="foregroundSecondary">
            Booked on
          </Typography>
          <Typography variant="body/sm" color="foregroundPrimary" data-testid="booked-on">
            {bookedOn}
          </Typography>
        </Styled.ListItem>
        <Styled.ListItem>
          <Typography variant="body/sm" color="foregroundSecondary">
            Rooms
          </Typography>
          <Typography variant="body/sm" color="foregroundPrimary">
            {itinerary.roomCount}
          </Typography>
        </Styled.ListItem>
      </Styled.DetailsList>
    </Styled.TripDetails>
  );

  return (
    <CollapsibleContent
      toggleTestID="toggle-booking-details"
      toggleBtnLabel={
        <Box display="flex" gap={12} alignItems="center">
          <Typography variant="heading/lg">Your booking</Typography>
        </Box>
      }
      defaultOpen
    >
      {content}
    </CollapsibleContent>
  );
};

export default TripInfo;
