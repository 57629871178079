import { Navigate } from "@hotel-engine/lib/react-router-dom";

import type { StandardFlagKey } from "@hotel-engine/types/featureFlag";
import { useAmplitudeContext } from "@hotel-engine/hooks";
import { useAllExperimentsQuery } from "@hotel-engine/react-query/amplitude/useExperimentQuery";

import { useHydrateUser } from "../helpers/useHydrateUser";
import type { ReactElement } from "react";

export interface IAmpliAuthRouteProps {
  /**
   * Render Props
   */
  children: (isUserLoggedInAndFFEnabled: boolean) => ReactElement;
  featureFlag: StandardFlagKey;
  /**
   * Probably "on"
   */
  ffValueWhenEnabled?: string;
}

/**
 * If user not logged in, redirects to "/".
 * If user is logged in:
 *  If feature flag enabled, passes `true` to render
 *  If feature flag disabled, passes `false` to render
 */
export const RouteWithFeatureFlag = ({
  children,
  featureFlag,
  ffValueWhenEnabled = "on",
}: IAmpliAuthRouteProps) => {
  const { user, loading: hydrateUserIsLoading } = useHydrateUser();
  const { experimentSdk } = useAmplitudeContext();
  const { data, isLoading: featureFlagIsLoading } = useAllExperimentsQuery(experimentSdk);

  const treatment = data === undefined ? "control" : data[featureFlag]?.value;

  if (hydrateUserIsLoading || featureFlagIsLoading) {
    return null;
  }

  if (user) {
    return children(treatment === ffValueWhenEnabled);
  }

  return <Navigate replace to="/" />;
};
