import styled from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import type { ButtonProps } from "@hotelengine/atlas-web";
import { Button, IconButton } from "@hotelengine/atlas-web";
import type React from "react";

export const HeaderIconWrap = styled(IconButton)`
  height: 32px;
  width: 32px;
  margin-top: ${({ theme }) => theme.spacing[12]};
  position: relative;
  ${flexbox({
    alignItems: "center",
    direction: "row",
    justifyContent: "center",
  })};

  svg {
    display: flex;
    height: 16px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  padding-bottom: 6px;
`;

export const Indicator = styled.span<{ isTrigger?: boolean }>`
  background-color: ${({ theme }) => theme.colors.accentRed};
  border: ${({ theme }) => `${theme.borderWidth.normal} solid ${theme.colors.foregroundInverse}`};
  border-radius: ${({ theme }) => theme.borderRadius.circle};
  height: 12px;
  position: absolute;
  top: ${({ theme, isTrigger }) => (isTrigger ? theme.spacing[12] : theme.spacing[0])};
  right: 0;
  width: 12px;
  z-index: ${({ theme }) => theme.zIndex.backdropAbove};
`;

export const NotificationActions = styled.aside`
background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const NotificationAction = styled<React.FC<ButtonProps>>(Button).attrs({
  variant: "plain",
  size: "sm",
})`
justify-content: flex-start;
  background: transparent;
  border: 0;
  border-radius: 0;
  padding: ${({ theme }) => `${theme.spacing[8]}} ${theme.spacing[16]}`};
`;
