import type { BoxProps, ButtonProps } from "@hotelengine/atlas-web";
import { Box, Button } from "@hotelengine/atlas-web";
import type React from "react";
import styled from "styled-components";

export const TravelerRow = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderMinimal};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px 0;
`;

export const InfoContainer = styled.div`
  padding: 0 0 24px;
`;

export const SliceDetailsContainer = styled<React.FC<BoxProps>>(Box)`
  border-top: ${({ theme }) => `dashed ${theme.borderWidth.normal} ${theme.colors.borderMinimal}`};

  padding: ${({ theme }) => `${theme.spacing[12]} ${theme.spacing[0]} ${theme.spacing[24]}`};
  margin: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[24]} ${theme.spacing[0]}`};
`;

export const SliceDetailsButton = styled<React.FC<ButtonProps>>(Button)`
  width: 100%;
`;
