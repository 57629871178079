import moment from "moment";
import { getAbbreviatedTimezone } from "@hotel-engine/utilities";
import { useFormatDate } from "@hotel-engine/hooks";
import type { IReservationBase } from "@hotel-engine/types/reservation";

export const useDatesInfo = (itinerary: IReservationBase, roomIndex?: number) => {
  const checkInToUse =
    roomIndex !== undefined ? itinerary.rooms[roomIndex].checkIn : itinerary.checkIn;
  const checkOutToUse =
    roomIndex !== undefined ? itinerary.rooms[roomIndex].checkOut : itinerary.checkOut;
  const nightCount =
    roomIndex !== undefined
      ? moment(itinerary.rooms[roomIndex].checkOut).diff(itinerary.rooms[roomIndex].checkIn, "days")
      : itinerary.nightCount;

  const checkinPropertyTimezone = itinerary.propertyTimezone
    ? getAbbreviatedTimezone({
        timezoneDatabaseName: itinerary.propertyTimezone,
        date: checkInToUse,
      })
    : "";

  const checkoutPropertyTimezone = itinerary.propertyTimezone
    ? getAbbreviatedTimezone({
        timezoneDatabaseName: itinerary.propertyTimezone,
        date: checkOutToUse,
      })
    : "";

  const formattedCheckInTime = itinerary.checkInTime
    ? `${moment(itinerary.checkInTime).utc().format("h:mm A")}`
    : undefined;

  const formattedCheckoutTime = itinerary.checkOutTime
    ? `${moment(itinerary.checkOutTime).utc().format("h:mm A")}`
    : undefined;

  const [inYear, inMonth, inDay] = checkInToUse.split("-");
  const [outYear, outMonth, outDay] = checkOutToUse.split("-");

  const dateOfCheckIn = useFormatDate(
    moment.utc([parseInt(inYear), parseInt(inMonth) - 1, parseInt(inDay)]).startOf("day"),
    "ddd, MMM D, YYYY"
  );
  const dateOfCheckOut = useFormatDate(
    moment.utc([parseInt(outYear), parseInt(outMonth) - 1, parseInt(outDay)]).startOf("day"),
    "ddd, MMM D, YYYY"
  );

  return {
    dateOfCheckIn,
    formattedCheckInTime,
    checkinPropertyTimezone,
    dateOfCheckOut,
    formattedCheckoutTime,
    checkoutPropertyTimezone,
    nightCount,
  };
};
