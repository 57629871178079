import styled from "styled-components";

import Typography from "@hotel-engine/common/Typography";
import { colors } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const LinkContainer = styled.div`
  height: 40px;
  ${flexbox({
    alignItems: "center",
    direction: "row",
  })};

  &:hover {
    cursor: pointer;
  }
`;

export const LinkIconContainer = styled.figure`
  margin: 0;
  position: relative;

  img,
  [role="img"] {
    width: 14px;
    height: 14px;
  }
`;

export const Indicator = styled.span`
  background-color: ${colors.red[400]};
  border: 1px solid ${colors.white};
  border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.x100};
  height: 12px;
  position: absolute;
  right: -8px;
  top: -4px;
  width: 12px;
`;

export const LinkTitle = styled(Typography)`
  margin-left: 9px;
`;

export const LoaderWrap = styled.div`
  padding: 16px;
  text-align: center;
`;
