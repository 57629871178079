import { useState } from "react";

import Button from "@hotel-engine/common/Button";
import { useBreakpoint } from "@hotel-engine/hooks/useBreakpoint";
import storage from "@hotel-engine/storage";

import * as Styled from "./CookieBanner.styles";
import { CookieSettings } from "./CookieSettings";

export const CookieBanner = (args: {
  onClose: () => void;
  setAmplitudeCookieAccepted: () => void;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isMobile = useBreakpoint("xl", "max");

  return (
    <>
      <Styled.CookieBannerContainer data-testid="cookie-banner">
        <Styled.CookieBannerContent $isMobile={isMobile}>
          <Styled.CookieText>
            Our site uses cookies to ensure you get the best experience while using it. By clicking
            'OK' you agree to our use of cookies. Please see our{" "}
            <a href={"https://www.hotelengine.com/privacy-policy/"}>privacy policy</a> or click
            'Manage' to review your cookie preferences.{" "}
          </Styled.CookieText>
          <Styled.ButtonContainer>
            <Styled.Approve
              onClick={() => {
                storage.setSecureItem(
                  "cookiePerms",
                  JSON.stringify({ Statistics: true, Marketing: true })
                );
                args.setAmplitudeCookieAccepted();
                args.onClose();
              }}
            >
              OK
            </Styled.Approve>
            <Button onClick={() => setIsModalVisible(true)}>Manage</Button>
          </Styled.ButtonContainer>
        </Styled.CookieBannerContent>
      </Styled.CookieBannerContainer>
      <Styled.CookieModal
        visible={isModalVisible}
        footer={null}
        closable={false}
        width={isMobile ? "100%" : "70vw"}
      >
        <CookieSettings
          setAmplitudeCookieAccepted={args.setAmplitudeCookieAccepted}
          onOk={() => (setIsModalVisible(false), args.onClose())}
        />
      </Styled.CookieModal>
    </>
  );
};
