import React from "react";
import * as Styled from "./styles";
import { Icon, Typography } from "@hotelengine/atlas-web";

const SameDayBanner: React.FC = () => {
  return (
    <Styled.WarningContainer>
      <Styled.InfoContainer>
        <Styled.IconContainer>
          <Icon name="circle-exclamation--solid" color="sentimentImportantStrong" size="md" />
        </Styled.IconContainer>
        <Styled.DetailContainer>
          <Typography variant="heading/sm" color="foregroundPrimary">
            Same day reservation
          </Typography>
          <Typography variant="body/sm">
            Please allow at least 30 minutes for processing before your check-in so that we can get
            your reservation ready.
          </Typography>
        </Styled.DetailContainer>
      </Styled.InfoContainer>
    </Styled.WarningContainer>
  );
};

export default SameDayBanner;
