import * as yup from "yup";
import { emailRegEx } from "@hotel-engine/utilities";
export const getBatchEmailValidationSchema = (
  maxEmails: number,
  emailRequired?: boolean,
  isForInvites?: boolean
) =>
  yup
    .array()
    .of(yup.object({ value: yup.string() }))
    .test(
      "len",
      `You have reached the max number of recipients, you may send to ${maxEmails} recipients at a time.`,
      function testMaxEmail(val) {
        return !!val && val.length <= maxEmails;
      }
    )
    .test("format", "That email address is not correct.", (vals) => {
      if (vals) {
        const result = vals.filter((item) => !emailRegEx.test(item.value || ""));

        return result.length == 0;
      }
      return false;
    })
    .test("unique", "You already entered that email address.", (vals) => {
      const emails: { [k: string]: string } = {};
      const unique = vals?.filter(({ value }) => {
        // guard against undefined that can happen with race condition
        if (!value) {
          return true;
        }
        const isUnique = !emails[value.toLowerCase()];
        if (isUnique) {
          emails[value.toLowerCase()] = value.toLowerCase();
        }
        return isUnique;
      });
      return unique?.length === vals?.length;
    })
    .test("minlen", "Please enter valid email addresses", (vals = []) =>
      emailRequired ? 0 < vals.length : true
    )
    .test(
      "numberOfInvites",
      "You have reached your maximum number of invites.",
      function testMaxEmail(val) {
        return isForInvites ? !!val && val.length <= this.options.context?.maxEmails : true;
      }
    );
