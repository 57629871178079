import styled from "styled-components";

import { ellipsisText } from "@hotel-engine/styles";
import type { TypographyProps } from "@hotelengine/atlas-web";
import { Typography } from "@hotelengine/atlas-web";

export const GuestsLabel = styled<React.FC<TypographyProps>>(Typography).attrs({
  variant: "body/sm",
  color: "foregroundPrimary",
})`
    ${ellipsisText};
`;
