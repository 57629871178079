import { useState, useEffect } from "react";
import { useDebounce, usePrevious } from "@hotel-engine/hooks";

import { GoogleAutoCompleteService } from "@hotel-engine/services";
import {
  MAX_SUGGESTIONS_PER_TYPE,
  MIN_CHARACTERS_TO_SEARCH,
  RESULTS_LIMIT,
} from "@hotel-engine/constants";
import { useSearchLocationsQuery } from "@hotel-engine/react-query/locations/useSearchLocationsQuery";
import { useSearchesQuery } from "@hotel-engine/react-query/searches/useSearchesQuery";
import { useAutocompleteFavoritePropertiesQuery } from "@hotel-engine/react-query/favoriteProperties/useAutocompleteFavoritePropertiesQuery";
import { useWorksiteQuery } from "@hotel-engine/react-query/worksite/useWorksiteQuery";

import type { ILocationRecord, IColdStateResult } from "@hotel-engine/types/locations";
import type {
  IAutocompleteFavoriteProperty,
  IPreferredProperty,
} from "@hotel-engine/types/favoriteProperty";

import {
  filteredWorksites,
  filterRecentSearches,
  removeDupesAndTrim,
  returnFavoritePlaces,
  returnPreferredProperties,
  returnSearchResultsArr,
  updateRecentSearchesForPropertySearch,
} from "../helpers";

const autoCompleteService = GoogleAutoCompleteService();

export const useAutoCompleteResults = (searchInput: string) => {
  const [{ predictions }, setAutocompleteState] = useState<google.maps.places.AutocompleteResponse>(
    {
      predictions: [],
    }
  );

  useEffect(() => {
    const subscription = autoCompleteService.register(mergeState);
    return () => {
      subscription.unregister();
    };
  }, []);

  const mergeState = (incoming: google.maps.places.AutocompleteResponse) => {
    setAutocompleteState((prev) => {
      return {
        ...prev,
        ...incoming,
      };
    });
  };

  /** debouncedSearchInput
   * Update search input on 500 ms delay
   * Minimize number of calls made to Google's api
   */
  const debouncedSearchInput = useDebounce(searchInput, 500);

  /** previousDebouncedSearchInput
   * Keep previous version of input for comparisons
   */
  const previousDebouncedSearchInput = usePrevious(debouncedSearchInput);

  const isSearchEnabled = searchInput.length >= MIN_CHARACTERS_TO_SEARCH;
  const isSearchEmpty = searchInput.length === 0;

  /** setIsAutocompleteLoading
   * Add autocomplete loading state setting method here and pass it to service
   * since google does not provide us a loading status
   */
  const [isAutocompleteLoading, setIsAutocompleteLoading] = useState(false);

  /** Compare input text for updates and make request to google with new term */
  useEffect(() => {
    if (previousDebouncedSearchInput !== debouncedSearchInput) {
      autoCompleteService.request(debouncedSearchInput, setIsAutocompleteLoading);
    }
  }, [debouncedSearchInput, previousDebouncedSearchInput]);

  const {
    isLoading: isRecentSearchesLoading,
    data: recentSearchData = [],
    isError,
  } = useSearchesQuery(6);

  const recentSearches = isError ? [] : updateRecentSearchesForPropertySearch(recentSearchData);
  const coldStateRecentSearches = removeDupesAndTrim(
    recentSearches.filter((search) => !!search.location),
    "coldState"
  );
  const filteredAutocompleteRecentSearches = filterRecentSearches(
    recentSearches,
    isSearchEnabled ? searchInput : null
  );

  const {
    isLoading: isWorksitesLoading,
    data: { results: worksiteResults = [] } = {},
  } = useWorksiteQuery({ limit: RESULTS_LIMIT });

  const worksites = filteredWorksites(worksiteResults, isSearchEnabled ? searchInput : null);

  const {
    isLoading: isFavoritesLoading,
    data: { favorites = [], preferredProperties = [] } = {},
  } = useAutocompleteFavoritePropertiesQuery<{
    favorites: IAutocompleteFavoriteProperty[];
    preferredProperties: IPreferredProperty[];
  }>({
    options: {
      cacheTime: 0,
    },
    params: {
      include_preferred: true,
      limit: RESULTS_LIMIT,
    },
  });

  /** favoritePlaces
   * Creates array of favorites to allow for property specific search
   * if search is enabled filter array for matching items
   */
  const favoritePlaces: ILocationRecord[] = returnFavoritePlaces(
    favorites,
    isSearchEnabled ? searchInput : null
  );

  const preferred: ILocationRecord[] = returnPreferredProperties(
    preferredProperties,
    isSearchEnabled ? searchInput : null
  );

  const filteredFavorites = favoritePlaces.filter((f) => !preferred.some((p) => p.id === f.id));

  const { data: searchLocations = [], isLoading: isLocationsLoading } = useSearchLocationsQuery(
    { query: searchInput, excludePlaces: true, includeWorksites: false },
    {
      enabled: isSearchEnabled,
    }
  );

  /** resultsArr
   * Combined arr of favorite properties, matching properties, and google predictions
   * Displayed on focus when input is empty
   */
  const searchResults = !isSearchEmpty
    ? returnSearchResultsArr({
        recentSearches: filteredAutocompleteRecentSearches.slice(0, MAX_SUGGESTIONS_PER_TYPE),
        worksites: worksites.slice(0, MAX_SUGGESTIONS_PER_TYPE),
        companyChoice: preferred.slice(0, 2),
        favorites: filteredFavorites.slice(0, 2),
        apiResults: searchLocations,
        googleResults: predictions,
      })
    : [];

  /** coldStateResults
   * Combined arr of worksites, favorite properties and recent searches
   * Displayed on focus when input is empty
   */
  const coldStateResults: IColdStateResult[] = [
    ...coldStateRecentSearches.slice(0, MAX_SUGGESTIONS_PER_TYPE),
    ...worksites.slice(0, MAX_SUGGESTIONS_PER_TYPE),
    ...favoritePlaces.slice(0, MAX_SUGGESTIONS_PER_TYPE),
  ];

  /** autoCompleteDataState
   * if the input is empty or search is enabled do not return idle
   * if search is enabled check array length to determine display
   * if input is empty and has recent searches show them
   * if the search is not empty but not yet enabled return idle
   */
  const autoCompleteDataState = (() => {
    const isDirty = !isSearchEmpty && !isSearchEnabled;
    const hasColdStateResults =
      coldStateRecentSearches?.length || worksites?.length || favorites?.length;
    const hasSearchResults = !!searchResults?.length;
    if (isSearchEmpty || isSearchEnabled || isDirty || !isLocationsLoading) {
      if (isSearchEnabled || (isDirty && hasSearchResults)) {
        return hasSearchResults ? "show-locations" : "no-results";
      }
      if (isSearchEmpty && hasColdStateResults) {
        return "show-cold-state";
      }
    }
    return "idle";
  })();

  const isResultsLoading =
    isRecentSearchesLoading ||
    isLocationsLoading ||
    isAutocompleteLoading ||
    isWorksitesLoading ||
    isFavoritesLoading;

  return {
    autoCompleteDataState,
    searchResults,
    coldStateResults,
    isResultsLoading,
  };
};
