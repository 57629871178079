import styled from "styled-components";

import { Icon } from "@hotel-engine/common/Icon";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { colors } from "@hotel-engine/styles";

export const ConfirmEmailMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ConfirmIcon = styled(Icon)`
  margin: 0 auto ${({ theme }) => theme.spacing[24]};
  width: 40px;
  height: 40px;
`;

export const ResendButton = styled.span`
  color: ${({ theme }) => theme.colors.linkPrimary};
  cursor: pointer;
`;

export const ResendResponse = styled.p<{ $status: "error" | "success" }>`
  ${typographyMixin("body-medium")};
  text-align: center;
  margin: auto;
  max-width: 315px;
  margin-top: 8px;
  color: ${({ $status }) => ($status === "error" ? colors.red[400] : colors.green[500])};
`;
