import type { UseMutationOptions } from "react-query";
import { useMutation, useQueryClient } from "react-query";

import { endpoints } from "@hotel-engine/react-query/constants";
import { useApi } from "@hotel-engine/react-query/useApi";
import type {
  IManageTripExtensionRequestsParams,
  IManageTripExtensionRequestsResponse,
} from "@hotel-engine/types/tripExtensionRequests";

import type { IErrorResponse } from "@hotel-engine/types/errors";
import { captureMessage, convertEmptyStrValsToNull } from "@hotel-engine/utilities";

/** Query to add multi-room trip extension requests */
export const useManageTripExtensionRequests = (
  options?: UseMutationOptions<
    IManageTripExtensionRequestsResponse,
    IErrorResponse,
    IManageTripExtensionRequestsParams
  >
) => {
  const post = useApi("post");
  const queryClient = useQueryClient();

  return useMutation(
    (params) => post(endpoints.tripExtensionRequestsManage, convertEmptyStrValsToNull(params)),
    {
      onSuccess: () => queryClient.invalidateQueries(endpoints.tripExtensionRequestsManage),
      onError: (error) => captureMessage("Failed to send trip extension data", { error }),
      ...options,
    }
  );
};
