import type React from "react";
import styled from "styled-components";

import { buttonReset } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { Box, IconButton, type IconButtonProps, type BoxProps } from "@hotelengine/atlas-web";

export const ItineraryPanelActions = styled.nav<{ isLodgingItinerary: boolean }>`
  padding: ${({ theme }) => theme.spacing[8]};

  background-color: ${({ theme, isLodgingItinerary }) =>
    isLodgingItinerary ? undefined : theme.colors.backgroundSecondary};

  height: ${({ theme, isLodgingItinerary }) => (isLodgingItinerary ? theme.spacing[48] : undefined)};

  ${flexbox({
    alignItems: "center",
    direction: "row",
    justifyContent: "space-between",
  })};
`;

export const CloseButton = styled.button`
  ${buttonReset};

  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing[4]};

  &:hover {
    text-decoration: underline;
  }
`;

export const NewReturnLink = styled<React.FC<BoxProps>>(Box)`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing[4]};
  padding-left: ${({ theme }) => theme.spacing[8]};
  & > span {
    color: ${({ theme }) => theme.colors.neutralN1000};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ButtonGroup = styled<React.FC<BoxProps>>(Box)`
  ${flexbox({
    alignItems: "center",
    direction: "row",
    justifyContent: "center",
  })}
  gap: ${({ theme }) => theme.spacing[4]};
`;

export const ActionItem = styled<React.FC<IconButtonProps>>(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-of-type {
    padding-right: 0;
  }
`;
