import type { IGooglePreviewMapProps } from ".";
import { Box, Icon, Typography } from "@hotelengine/atlas-web";
import { useTheme } from "styled-components";

export type MapStreet = Omit<IGooglePreviewMapProps, "longitude" | "latitude">;

export const MapStreet = ({ handleView, street, city, state, postalCode }: MapStreet) => {
  const { colors } = useTheme();

  const area = `${city}, ${state} ${postalCode}`;

  return (
    <Box
      display="flex"
      alignItems="center"
      position="absolute"
      padding={8}
      backgroundColor="foregroundEverdark"
      color="foregroundEverlight"
      role="button"
      onClick={handleView}
      zIndex="backdropAbove"
      style={{
        bottom: 24,
        left: 16,
        right: 16,
      }}
    >
      <Box
        style={{
          flex: 1,
          borderRightWidth: 1,
          borderRightStyle: "solid",
          borderRightColor: colors.foregroundInverse,
          cursor: "pointer",
        }}
      >
        <Typography variant="body/sm" as="div">
          {street}
        </Typography>
        <Typography variant="body/sm" as="div">
          {area}
        </Typography>
      </Box>
      <Box
        data-testid="directions-btn"
        marginLeft={16}
        marginRight={8}
        style={{ cursor: "pointer" }}
      >
        <Icon name="map--solid" />
      </Box>
    </Box>
  );
};
export default MapStreet;
