import { InputNumber as antdInputNumber } from "antd";
import type { DefaultTheme, StyledComponent } from "styled-components";
import styled from "styled-components";

import { colors } from "@hotel-engine/styles";

type InputNumberProps = {
  prefix?: string;
};

export const InputNumber: StyledComponent<
  typeof antdInputNumber,
  DefaultTheme,
  InputNumberProps,
  never
> = styled(antdInputNumber)`
  .ant-input-number-input {
    color: ${colors.grey[800]};
    &::placeholder {
      color: ${colors.newGrey500};
    }
    position: relative;
    padding-left: ${({ prefix }) => (prefix ? "36px" : "7px")};
  }
`;

export const InputNumberContainer = styled.div`
  position: relative;
`;

export const Prefix = styled.span`
  pointer-events: none;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  z-index: ${({ theme }) => theme.legacy.zIndex.aboveBackdrop};
`;
