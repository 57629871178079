import { useFlightModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";

import GetAssistance from "./components/GetAssistance";
import { CancelPolicy } from "./components/CancelPolicy";

import * as Styled from "./styles";

const AdditionalInfo = () => {
  const { isPreview, reservation } = useFlightModificationsContext();

  if (!reservation) return null;

  const modifyCancelPolicy = reservation.slices[0].modifyCancelPolicy;

  return (
    <Styled.AdditionalInfoPaddedContainer $isPreview={isPreview}>
      <GetAssistance itinerary={reservation} />
      <CancelPolicy policyCopy={modifyCancelPolicy} />
    </Styled.AdditionalInfoPaddedContainer>
  );
};

export default AdditionalInfo;
