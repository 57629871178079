import type { IUser } from "@hotel-engine/types/user";

import { type ViewType } from "pages/Trips/constants";

import LodgingItineraryPanel from "./lodging";
import FlightItineraryPanel from "./flights";
import type { ITripPreview } from "../../context";
import type { BookingType } from "@hotel-engine/types/itinerary";
import CarItineraryPanel from "./car";
import useHideCars from "pages/Trips/useHideCars";

export interface ITripsItineraryPreviewPanelProps {
  handleView?: (viewType: ViewType) => void;
  tripId: string | number;
  onClose: (tripPreview: ITripPreview) => void;
  user: IUser;
  view: "map" | "list";
  bookingType: BookingType;
}

const TripsItineraryPreviewPanel = ({
  handleView,
  onClose,
  tripId,
  user,
  view,
  bookingType,
}: ITripsItineraryPreviewPanelProps) => {
  const shouldHideCars = useHideCars();

  if (bookingType === "flight") {
    return (
      <FlightItineraryPanel
        itineraryId={tripId}
        handleView={handleView}
        onClose={onClose}
        view={view}
      />
    );
  }

  if (bookingType === "car") {
    if (shouldHideCars) return null;

    return (
      <CarItineraryPanel
        itineraryId={tripId}
        handleView={handleView}
        onClose={onClose}
        view={view}
      />
    );
  }

  return (
    <LodgingItineraryPanel
      itineraryId={tripId}
      handleView={handleView}
      onClose={onClose}
      user={user}
      view={view}
    />
  );
};

export default TripsItineraryPreviewPanel;
