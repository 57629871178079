import styled from "styled-components";

import Button from "@hotel-engine/common/Button";
import type { InputProps } from "@hotel-engine/common/Inputs";
import { Input } from "@hotel-engine/common/Inputs";
import { typographyMixin } from "@hotel-engine/common/Typography/styles";
import { buttonReset, colors } from "@hotel-engine/styles";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

interface IEmailInputProps extends InputProps {
  $hasAddon?: boolean;
}

export const LoginForm = styled.main`
  ${flexbox({ direction: "column", alignItems: "center" })};
  margin: 40px 0;
  ${mediaQuery("lg")(`
    flex: 1 1 auto;
    margin: 10vh 0 0;
  `)}
  .form-container {
    width: 100vw;
    padding: 0 24px;
    ${mediaQuery("lg")(`
      width: 300px;
      padding: 0;
    `)}

    .remember-me {
      ${flexbox({ alignItems: "center" })};
      label {
        font-size: 14px;
        color: #1a1a1a;
      }
    }
    .forgot-password {
      ${flexbox({ justifyContent: "center" })};
      button {
        height: auto;
      }
    }
  }
  .form-title {
    ${flexbox({ direction: "column", alignItems: "center" })};
    margin-bottom: 36px;
    text-align: center;
    h1 {
      font-weight: 300;
    }
  }
  .auth-error {
    min-width: 300px;
    margin-bottom: 24px;
    .ant-alert-message {
      margin-right: 20px;
    }
  }
`;

export const EmailInput = styled(Input)<IEmailInputProps>`
  .ant-input-group-addon {
    padding: 0;
  }
  button {
    ${buttonReset};
    background-color: ${colors.grey[50]};
    color: ${colors.retroBlue};
    height: 38px;
    padding: 0 11px;
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.sm};
    transition: all 0.3s;
    &:focus,
    &:hover {
      color: ${colors.denim};
    }
  }
  ${({ $hasAddon }) =>
    $hasAddon &&
    `
      input {
        background-color: ${colors.grey[50]};
        cursor: not-allowed;
        pointer-events: none;
        color: ${colors.disabledBlack};
      }
    `};
`;

export const AuthButton = styled(Button)`
  margin-top: 8px;
  min-width: 100%;
  ${({ loading }) =>
    loading &&
    `
      padding-left: 15px !important;
    `};
`;

export const OtherOption = styled.div`
  margin: 24px 0;
  text-align: center;
`;

export const PasswordButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #2f58eb;
  width: 45px;
`;

export const ContinueWithButton = styled(Button)`
  width: 300px;
  height: 44px;
  display: flex;
  position: relative;
  align-items: center;
`;

export const ContinueWithGoogleIconPlaceholder = styled.img``;

export const ButtonTypography = styled.span`
  ${typographyMixin("body-medium")}
  line-height: 24px;
  margin: auto;
`;
