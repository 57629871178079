import styled, { css } from "styled-components";
import { colors } from "@hotel-engine/styles";
import type { SearchResultMarkerAttributes } from "../../../types";

export const Marker = styled.div<{
  $loaded: boolean;
  $loading: boolean;
  $selected: boolean;
  $inverted: boolean;
  $padded: boolean;
  $markerAttr?: SearchResultMarkerAttributes;
}>`
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  ${({ $inverted, $padded }) => {
    return $inverted
      ? css`
        border-bottom: 12px solid;
        margin-top: ${$padded ? 7 : 0}px;
        `
      : css`
        border-top: 12px solid;
        margin-top: ${$padded ? -19 : -12}px;
        `;
  }}
  border-top-color: ${({
    $selected,
    $markerAttr = { color: colors.grey[800], hoverColor: colors.retroBlue },
  }) => ($selected ? $markerAttr?.hoverColor : $markerAttr?.color)};
  border-bottom-color: ${({
    $selected,
    $markerAttr = { color: colors.grey[800], hoverColor: colors.retroBlue },
  }) => ($selected ? $markerAttr?.hoverColor : $markerAttr?.color)};
  height: 0;
  margin-left: -12px;
  opacity: ${({ $loaded, $loading }) => {
    if ($loading) {
      return 0.5;
    } else if ($loaded) {
      return 1;
    }
    return 0;
  }};
  position: relative;
  width: 0;
`;
