import { Table as AntTable } from "antd";
import styled from "styled-components";

import { colors } from "@hotel-engine/styles";

export const Table = styled(AntTable)`
  .ant-table-thead > tr > th {
    color: ${colors.blackPanther};
    background-color: ${colors.white};
    font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
    white-space: nowrap;
  }
  .ant-table-column-title {
    white-space: nowrap;
  }
  .ant-pagination {
    display: flex;
    justify-content: flex-end;
    padding-top: 18px;
    width: 100%;
  }
  .ant-pagination-options {
    .ant-pagination-options-size-changer .ant-select .ant-select-enabled {
      margin-right: 0;
    }
  }
  .ant-pagination-item,
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link,
  .ant-select-selection {
    border: 1px solid ${colors.kiwiGrey};
    border-radius: ${({ theme }) => theme.legacy.shape.borderRadius.lg};
  }
  .ant-pagination-item a,
  .ant-select,
  .ant-select-arrow {
    color: ${colors.grey[800]};
  }
  .ant-pagination-item:focus,
  .ant-pagination-item:hover,
  .ant-select-selection:focus,
  .ant-select-selection:hover,
  .ant-pagination-item-active:focus,
  .ant-pagination-item-active:hover {
    border-color: ${colors.black};
  }
  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a,
  .ant-pagination-item-active:focus,
  .ant-pagination-item-active:hover {
    color: ${colors.black};
  }
  .ant-pagination-item-active {
    background: ${colors.black};
    border-color: ${colors.black};
  }
  .ant-pagination-item-active a,
  .ant-pagination-item-active:focus a,
  .ant-pagination-item-active:hover a {
    color: ${colors.white};
  }
  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: ${colors.grey[800]};
    border-color: ${colors.black};
  }
`;

export const TableColumn = styled(AntTable.Column)``;
