import Typography from "@hotel-engine/common/Typography";
import * as Styled from "../../styles";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import type { BookingDetailsType } from "@hotel-engine/types/supportCase";
import { routes } from "@hotel-engine/constants";
import { useIsFeatureFlagOn } from "../../../Experiments";
import { useAirlineInfo } from "pages/Flights/hooks/useAirlineInfo";

export interface IBookingDetails {
  booking: BookingDetailsType;
  closeDrawer?: () => void;
}

const BookingDetails = ({ booking, closeDrawer }: IBookingDetails) => {
  const { data: bookingData, isFlightBooking } = booking;

  const isFlightsOn = useIsFeatureFlagOn("flights-postbooking");
  const navigate = useNavigate();
  const { getAirlineNameFromIataAirlineCode } = useAirlineInfo();

  if (isFlightBooking) {
    const itineraryNumber = bookingData?.bookingNumber;
    const airlineConfirmationNumber = bookingData?.firstSegment?.pnrCode;
    const flightNumber = bookingData?.firstSegment?.flightNumber;

    const airlineName = bookingData?.firstSegment?.airlineCode
      ? getAirlineNameFromIataAirlineCode(bookingData.firstSegment.airlineCode)
      : null;

    const flightDetail = `${airlineName} ${flightNumber}`;
    const bookedBy = bookingData?.bookerFullName;

    return (
      <>
        <Styled.Row>
          <Typography as="h2" variant="small">
            Booking Details
          </Typography>
        </Styled.Row>

        {!!itineraryNumber && (
          <Styled.Row>
            <Typography variant="body-medium">Itinerary Number:</Typography>
            <Typography variant="body-medium">{itineraryNumber}</Typography>
          </Styled.Row>
        )}

        {!!airlineConfirmationNumber && (
          <Styled.Row>
            <Typography variant="body-medium">Airline Confirmation:</Typography>
            <Typography variant="body-medium">{airlineConfirmationNumber}</Typography>
          </Styled.Row>
        )}

        {!!flightDetail && (
          <Styled.Row>
            <Typography variant="body-medium">Flight:</Typography>
            <Typography variant="body-medium">{flightDetail}</Typography>
          </Styled.Row>
        )}

        {!!bookedBy && (
          <Styled.Row>
            <Typography variant="body-medium">Booked By:</Typography>
            <Typography variant="body-medium">{bookedBy}</Typography>
          </Styled.Row>
        )}

        {!!isFlightsOn && (
          <Styled.ItineraryButton
            data-testid="support-case-drawer-view-flight-itinerary"
            onClick={() => {
              closeDrawer && closeDrawer();
              navigate({
                pathname: routes.itinerary,
                search: `?contract_number=${bookingData.tripId}&type=flight`,
              });
            }}
          >
            <Typography variant="body-medium">View Itinerary</Typography>
          </Styled.ItineraryButton>
        )}
      </>
    );
  }

  const contractNumber =
    bookingData?.groupContractNumber ?? bookingData?.rooms?.[0]?.contractNumber;

  const propertyName = bookingData?.propertyName;

  const propertyAddress = bookingData?.propertyAddress;

  const bookedBy = bookingData?.bookedBy;

  return (
    <>
      <Styled.Row>
        <Typography as="h2" variant="small">
          Booking Details
        </Typography>
      </Styled.Row>

      {!!contractNumber && (
        <Styled.Row>
          <Typography variant="body-medium">Contract Number:</Typography>
          <Typography variant="body-medium">{contractNumber}</Typography>
        </Styled.Row>
      )}

      {!!propertyName && (
        <Styled.Row>
          <Typography variant="body-medium">Property:</Typography>
          <Typography variant="body-medium">{propertyName}</Typography>
        </Styled.Row>
      )}

      {!!propertyAddress && (
        <Styled.Row>
          <Typography variant="body-medium">Address:</Typography>
          <Typography variant="body-medium">{propertyAddress}</Typography>
        </Styled.Row>
      )}

      {!!bookedBy && (
        <Styled.Row>
          <Typography variant="body-medium">Booked By:</Typography>
          <Typography variant="body-medium">{bookedBy}</Typography>
        </Styled.Row>
      )}

      <Styled.ItineraryButton
        data-testid="support-case-drawer-view-itinerary"
        onClick={() => {
          navigate({
            pathname: routes.itinerary,
            search: `?contract_number=${contractNumber}`,
          });
        }}
      >
        <Typography variant="body-medium">View Itinerary</Typography>
      </Styled.ItineraryButton>
    </>
  );
};

export default BookingDetails;
