import { Button } from "@hotelengine/atlas-web";
import styled from "styled-components";

export const GroupButton = styled(Button)<{ $isSelected: boolean }>`
    flex: 1;
    border-radius: 0;
    padding-top: ${({ theme }) => theme.spacing[12]};
    padding-bottom: ${({ theme }) => theme.spacing[8]};
    border-bottom: ${({ theme, $isSelected }) =>
      `${theme.borderWidth.wide} solid ${
        $isSelected ? theme.colors.foregroundPrimary : theme.colors.borderTransparent
      }`};

      &:active, &:focus {
        background-color: ${({ theme }) => theme.colors.backgroundPrimary} !important;
      }
`;
