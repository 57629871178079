import type { NavigateFunction } from "@hotel-engine/lib/react-router-dom";
import { Link } from "@hotel-engine/lib/react-router-dom";
import type { ActionCreator, AnyAction } from "redux";

import { Icon } from "@hotel-engine/common/Icon";
import type { ITokenMode } from "@hotel-engine/react-query/users/useAccountMode";
import type { IUser } from "@hotel-engine/types/user";

import { handleSignOut } from "../../helpers";
import * as Styled from "../mobileStyles";
import NotificationsLink from "./components/NotificationsLink";
import { returnMainNav, returnSettings } from "./utils";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { useAuth0 } from "@auth0/auth0-react";

export interface IMobileDropdownProps {
  handleNotificationOpen: () => void;
  hasUnreadNotifications: boolean;
  isOpen: boolean;
  user: IUser;
  navigate: NavigateFunction;
  signOut: ActionCreator<AnyAction>;
  onClick: () => void;
  /** boolean that determines whether to show travel credits */
  shouldShowTravelCredits: boolean;
  /** is Refer A Friend enabled for this business */
  shouldShowReferAFriend: boolean;
  /** create personal account button */
  onPersonalAccountCreation?: () => void;
  /** members app should switch to business token*/
  onSwitchToPersonal: () => void;
  /** members app should switch to personal token*/
  onSwitchToBusiness: () => void;
  /** is it a personal or business token we are currently using? */
  userTokenMode: ITokenMode;
  /** determines whether to show the switching logic */
  showSwitch: boolean;
  /** determines whether to the user can create a personal account */
  canCreatePersonalAccount: boolean;
  /** Clears user preferences on log out */
  clearUserPrefs: ActionCreator<AnyAction>;
}

const MobileDropdown = ({
  handleNotificationOpen,
  hasUnreadNotifications,
  isOpen,
  user,
  navigate,
  signOut,
  onClick,
  shouldShowTravelCredits,
  shouldShowReferAFriend,
  onPersonalAccountCreation,
  onSwitchToBusiness,
  onSwitchToPersonal,
  userTokenMode,
  showSwitch,
  canCreatePersonalAccount,
  clearUserPrefs,
}: IMobileDropdownProps) => {
  const { isAuthenticated } = useAuth0();
  const isAccountSecurityTabEnabled = useIsFeatureFlagOn("personal-settings-account-security");

  return (
    <Styled.DropdownWrapper isOpen={isOpen}>
      <Styled.MenuSection>
        {returnMainNav(shouldShowReferAFriend, shouldShowTravelCredits, user).map(
          ({ icon, img, route, title }) => {
            return (
              <Link to={route} onClick={onClick} key={route}>
                {!!img && <img src={img.src} alt={img.alt} />}
                {!!icon && <Icon icon={icon} />}

                <span>{title}</span>
              </Link>
            );
          }
        )}

        <NotificationsLink hasUnread={hasUnreadNotifications} onClick={handleNotificationOpen} />

        {!!canCreatePersonalAccount && (
          <Styled.PersonalAccountButton onClick={onPersonalAccountCreation}>
            <Icon icon={["fas", "plus"]} />
            <span>Add Personal Account</span>
          </Styled.PersonalAccountButton>
        )}

        {!!showSwitch && (
          <Styled.PersonalAccountButton
            onClick={userTokenMode === "personal" ? onSwitchToBusiness : onSwitchToPersonal}
          >
            <Icon icon={["far", "repeat"]} />
            <span>
              {`Switch To ${userTokenMode === "personal" ? "Business" : "Personal"} Account`}
            </span>
          </Styled.PersonalAccountButton>
        )}
      </Styled.MenuSection>

      <Styled.MenuSection>
        <h5>My Settings</h5>
        {returnSettings(isAuthenticated, isAccountSecurityTabEnabled).map((setting) => {
          return (
            <Link to={setting.route} onClick={onClick} key={setting.route}>
              <Icon icon={setting.icon} />
              <span>{setting.title}</span>
            </Link>
          );
        })}
      </Styled.MenuSection>

      <Styled.MenuSection>
        <h5>Support</h5>
        <a href="https://www.hotelengine.com/contact" target="_self">
          <Icon icon={["far", "paper-plane"]} />
          <span>Contact</span>
        </a>
      </Styled.MenuSection>
      <Styled.MenuSection>
        <Styled.SignOutButton
          onClick={async () => {
            await handleSignOut(navigate, signOut);
            clearUserPrefs();
          }}
        >
          <Icon icon={["far", "sign-out"]} />
          <span>Sign Out</span>
        </Styled.SignOutButton>
      </Styled.MenuSection>
    </Styled.DropdownWrapper>
  );
};
export default MobileDropdown;
