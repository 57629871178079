import { createContext, useContext, useEffect, useReducer, useState } from "react";

import { useNavigate, useLocation } from "@hotel-engine/lib/react-router-dom";
import { useTripsQuery } from "@hotel-engine/react-query/trips/useTripsQuery";
import { usePostCheckoutActions } from "@hotel-engine/contexts/PostCheckoutActionsContext";
import { routes } from "@hotel-engine/constants";
import { Unsafe } from "@hotel-engine/data";
import type { ITrip } from "@hotel-engine/types/trips";
import type { BookingType } from "@hotel-engine/types/itinerary";

import { viewTypes } from "./constants";

export type FilterBookingType = BookingType | "all";

export const viewTripTypes = {
  all: "all",
  today: "today",
  past: "past",
  upcoming: "upcoming",
} as const;

export type TripsFilterStatus = (typeof viewTripTypes)[keyof typeof viewTripTypes];

interface ICounts {
  today: number;
  all: number;
  past: number;
  upcoming: number;
}

export interface ISort {
  column: string;
  direction: "asc" | "desc";
}

export interface IFilters {
  startTimeGt?: string;
  startTimeLt?: string;
  contractType?: string;
  department?: string[];
  onlyMyTrips?: boolean;
  onlyCancelledTrips?: boolean;
  pendingModificationOnly?: boolean;
  refundableOnly?: boolean;
  search?: string;
  totalGt?: number;
  totalLt?: number;
  unverified?: boolean;
  manual_bookings?: boolean;
  booking_type?: FilterBookingType;
}

export interface ITripPreview {
  id: string | number | undefined;
  bookingType: BookingType;
}

export interface ITripsStateContext {
  trips: ITrip[];
  loading: boolean;
  error: string | null;
  canViewOthersTrips: boolean;
  counts: ICounts;
  mostExpensiveTrip: number;
  leastExpensiveTrip: number;
  filters: IFilters;
  showPreview: ITripPreview | null;
  selectedRows: unknown[];
  limit: number;
  page: number;
  offset: number;
  sort: ISort;
  sortCalendar: string;
  reset: boolean;
  status: TripsFilterStatus;
  activeModification: {
    isActive: boolean;
    isNavigating: boolean;
    deferredTripSelection?: ITrip | undefined;
  };
}

export interface ITripsContext {
  state: ITripsStateContext;
  dispatch: TTripsDispatchContext;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  tripsRefresh: () => void;
}

export const TripsContext = createContext({} as ITripsContext);

export interface IAction {
  type: string;
  filters?: Array<{ key: keyof IFilters; value?: IFilters[keyof IFilters] }>;
  status?: TripsFilterStatus;
  limit?: number;
  record?: ITripPreview;
  column?: "type" | "traveler" | "start_time" | "end_time" | "location" | "details" | "price";
  trips?: ITrip[];
  rows?: unknown[];
  page?: number;
  pageSize?: number;
  key?: string;
  total?: number;
  today?: number;
  upcoming?: number;
  past?: number;
  cancelled?: number;
  sortCalendar?: string;
  values?: unknown[];
  activeModification?: {
    isActive: boolean;
    isNavigating: boolean;
    deferredTripSelection?: string | number | undefined;
  };
}

export type TTripsDispatchContext = (action: IAction) => void;

export const defaultState = {
  trips: [],
  loading: false,
  canViewOthersTrips: false,
  error: null,
  counts: {
    today: 0,
    all: 0,
    past: 0,
    upcoming: 0,
  },
  mostExpensiveTrip: 0,
  leastExpensiveTrip: 0,
  filters: {},
  showPreview: null,
  selectedRows: [],
  status: "upcoming",
  limit: 25,
  page: 1,
  offset: 0,
  sort: { column: "start_time", direction: "asc" },
  sortCalendar: "first_name",
  reset: true,
  activeModification: {
    isActive: false,
    isNavigating: false,
    deferredTripSelection: undefined,
  },
};

/** These are the statuses and columns where it makes the most sense to sort differently than everywhere else
 *  b/c here we want to show the most recent trips/ latest dates instead of the future dates that are the closest
 */
const defaultDescSorts = ["past", "all"];
const defaultDescColumns = ["start_time", "end_time"];

const ignoreFilters = [
  "status",
  "totalGt",
  "totalLt",
  "startTimeGt",
  "startTimeLt",
  "search",
  "booking_type",
];

const handleFilterChange = (state, action) => {
  const filtersToUpdate = action.filters.reduce((acc, filter) => {
    const { [filter.key]: value, ...rest } = acc;

    /**
     * only clear filter if value is empty or
     * its one of the ignore filters
     */
    if (!value || ignoreFilters.includes(filter.key)) {
      rest[filter.key] = filter.value;
    }

    return rest;
  }, state.filters);

  const cleanFilters = Object.keys(filtersToUpdate).reduce((acc, key) => {
    if (
      filtersToUpdate[key] === undefined ||
      (Array.isArray(filtersToUpdate[key]) && !filtersToUpdate[key].length)
    ) {
      return acc;
    }

    acc[key] = filtersToUpdate[key];

    return acc;
  }, {});

  return {
    ...state,
    filters: cleanFilters,
    selectedRows: [],
    showPreview: state.showPreview?.bookingType === "lodging" ? state.showPreview : null,
  };
};

const handleArrayFilterChange = (state, { key, values }: { key: string; values: unknown[] }) => {
  const currentFilterValues: unknown[] = state.filters[key];
  let updatedFilterValues: unknown[] = values;

  if (!!currentFilterValues) {
    if (values.some((value: unknown) => currentFilterValues.includes(value))) {
      updatedFilterValues = currentFilterValues.filter(
        (currentValue) => !values.includes(currentValue)
      );
    } else {
      updatedFilterValues = [...currentFilterValues, ...values];
    }
  }

  return {
    ...state,
    filters: { ...state.filters, [key]: updatedFilterValues },
    selectedRows: [],
    showPreview: null,
  };
};

const handleSort = (state, action, status = state.status) => {
  const isDefaultDescOption =
    defaultDescSorts.includes(status) && defaultDescColumns.includes(action.column);

  const newSort = {
    column: action.column,
    direction: isDefaultDescOption ? "desc" : "asc",
  };

  /** This handles the "toggle" case where we want to change the sort of the already selected column */
  if (state.sort.column === action.column && state.sort.direction === newSort.direction) {
    newSort.direction = newSort.direction === "asc" ? "desc" : "asc";
  }

  return {
    ...state,
    sort: newSort,
  };
};

const handleStatusChange = (state, action) => {
  const baseObj = {
    status: action.status,
    offset: 0,
    page: 1,
    showPreview: null,
  };

  const shouldSortWhenChangingStatus = defaultDescColumns.includes(state.sort.column);

  const sortDirectionForStatus = defaultDescSorts.includes(action.status) ? "desc" : "asc";

  if (shouldSortWhenChangingStatus) {
    /** If the sort is already in the correct direction, return the base object */
    if (state.sort.direction === sortDirectionForStatus) {
      return { ...state, ...baseObj };
    }

    /** Otherwise, change the sort based on the status being selected */
    return {
      ...handleSort(
        state,
        { column: state.sort.column, direction: sortDirectionForStatus },
        action.status
      ),
      ...baseObj,
    };
  }

  /** If the column is a default column, skip everything above and return the base object */
  return { ...state, ...baseObj };
};

const handleRowSelection = (state, action) => {
  const isModificationActive = state.activeModification.isActive;
  // If we send the same trip again, or the id of the showPreview trip, unselect that trip
  const unselect = state.showPreview?.id === action.record.id;

  /** If there is an active modification, we want to defer the selection of a new trip, but keep reference to it,
   * then set isNavigating to true, to communicate the attempted trip selection to the ModificationsContext
   */
  if (isModificationActive) {
    return {
      ...state,
      activeModification: {
        isActive: true,
        isNavigating: true,
        deferredTripSelection: action.record ?? state.activeModification.deferredTripSelection,
      },
    };
  }

  /** Either a normal trip selection (no active modification), or a modification has been abandoned and here we will
   * finish resetting modification state and update showPreview with the deferredTripSelection
   */
  return {
    ...state,
    activeModification: {
      isActive: false,
      isNavigating: false,
      deferredTripSelection: undefined,
    },
    showPreview: unselect ? null : action.record,
    selectedRows: unselect ? state.selectedRows : [],
  };
};

const handleSetActiveModification = (state, action) => {
  const updatedState = {
    ...state,
    activeModification: action.activeModification,
  };

  /** If the modification has been abandoned but there is a deferredTripSelection, we will push the
   * updatedState to handleRowSelection along with the deferred trip, rather than just updating state here
   */
  if (!action.activeModification.isActive && !!state.activeModification.deferredTripSelection) {
    return handleRowSelection(updatedState, {
      record: state.activeModification.deferredTripSelection,
    });
  }

  return updatedState;
};

export const tripsReducer = (state, action) => {
  switch (action.type) {
    case "tripsRequest":
      return {
        ...state,
        ...action,
        loading: true,
      };
    case "tripsError": {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    case "tripsReceived":
      const counts = {
        today: action.totalToday,
        upcoming: action.totalUpcoming,
        past: action.totalPast,
        all: action.totalAll,
      };

      return {
        ...state,
        loading: false,
        trips: state.reset ? action.trips : [...state.trips, ...action.trips],
        counts,
        mostExpensiveTrip: state.initial
          ? Math.ceil(action.mostExpensiveTrip)
          : Math.ceil(state.mostExpensiveTrip),
        leastExpensiveTrip: state.initial
          ? Math.floor(action.leastExpensiveTrip)
          : Math.floor(state.leastExpensiveTrip),
        page: Math.ceil((action.trips.length + state.offset) / state.limit),
        reset: true,
        initial: false,
        error: null,
      };
    case "updateStatusCounts":
      return {
        ...state,
        counts: {
          today: action.today,
          upcoming: action.upcoming,
          past: action.past,
          all: action.total,
        },
      };
    case "status":
      return handleStatusChange(state, action);
    case "filter":
      return handleFilterChange(state, action);
    case "arrayFilter":
      return handleArrayFilterChange(state, action);
    case "pagination":
      return {
        ...state,
        page: action.page,
        offset: (action.page - 1) * action.pageSize,
      };
    case "limit":
      return {
        ...state,
        limit: action.limit,
        offset: 0,
      };
    case "sortCalendar":
      return {
        ...state,
        sortCalendar: action.sortCalendar,
      };
    case "sort":
      return handleSort(state, action);
    case "rowSelection":
      return handleRowSelection(state, action);
    case "setActiveModification":
      return handleSetActiveModification(state, action);
    case "rowChecked":
      return {
        ...state,
        showPreview: null,
        selectedRows: action.rows,
      };
    case "loadMore":
      return {
        ...state,
        offset: state.trips.length,
        reset: false,
      };
    case "clearFilters":
      return {
        ...state,
        filters: {},
      };
    default:
      return state;
  }
};

const tripsRequest = async (dispatch, refetchTrips) => {
  dispatch({ type: "tripsRequest" });

  const res = await refetchTrips();

  if (!!res.data) {
    dispatch({ type: "tripsReceived", ...res.data });
  } else if (!!res.isError) {
    dispatch({ type: "tripsError" });
  }
};

const TripsProvider = ({ params, user, children, view }) => {
  const navigate = useNavigate();
  const rrLocation = useLocation();
  const { state: postCheckoutActionsState } = usePostCheckoutActions();
  const [searchValue, setSearchValue] = useState("");

  const [state, dispatch] = useReducer(tripsReducer, {
    ...defaultState,
    status: params.status || defaultState.status,
    initial: true,
    canViewOthersTrips:
      user &&
      ((user.role === "coordinator" && user.business.showCoordinatorDashboard) ||
        user.role === "admin"),
  });

  const filtersObject = Object.keys(state.filters).reduce((acc, cur) => {
    acc[`filter[${cur}]`] = state.filters[cur];
    return acc;
  }, {});

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { refetch: refetchTrips, isStale } = useTripsQuery({
    params: {
      ...filtersObject,
      "filter[group]": state.status as TripsFilterStatus,
      "filter[timezone]": timezone,
      "filter[onlyCancelledTrips]": state.filters.onlyCancelledTrips,
      limit: state.limit,
      offset: state.offset,
      sort: `${state.sort.direction === "desc" ? "-" : ""}${state.sort.column}`,
    },
  });

  const tripsRefresh = () => {
    if (view !== viewTypes.CALENDAR && (!state.loading || isStale)) {
      void tripsRequest(dispatch, refetchTrips).then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    }
  };

  // This is to keep the below useEffect from running constantly trying to shallow compare filters objects
  const stringifiedFilters = JSON.stringify(state.filters);

  useEffect(() => {
    tripsRefresh();
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.status,
    stringifiedFilters,
    state.offset,
    state.limit,
    state.sort,
    postCheckoutActionsState.completedPostCheckoutActions,
    view,
  ]);

  useEffect(() => {
    if (rrLocation.pathname.includes("active")) {
      navigate(`${routes.trips.base}/today`, { replace: true });
    }
    if (state.status !== params.status) {
      dispatch({ type: "status", status: params.status });
    }
  }, [navigate, params, params.status, rrLocation, state.status]);

  return (
    <TripsContext.Provider
      value={{
        state,
        dispatch,
        searchValue,
        setSearchValue,
        tripsRefresh,
      }}
    >
      {children}
    </TripsContext.Provider>
  );
};

const useTripsContext = () => {
  const context = useContext(TripsContext);
  if (context === undefined) {
    throw new Error("useTripsContext must be used within the TripsProvider");
  }

  return context;
};

export { TripsProvider, useTripsContext };
