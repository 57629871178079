import type { IReservationBase, IReservationQueryParams } from "@hotel-engine/types/reservation";
import { useExtendedQuery } from "@hotel-engine/react-query/useExtendedQuery";
import { endpoints } from "@hotel-engine/react-query/constants";
import { useApi } from "@hotel-engine/react-query/useApi";
import useAccountToken from "./useAccountToken";

function usePreviousTrip(params: IReservationQueryParams) {
  const get = useApi("get");
  const token = useAccountToken();

  return useExtendedQuery(
    [endpoints.reservations, params],
    () =>
      get<IReservationBase>(`${endpoints.reservations}/${params.id}`, params, {
        headers: { Authorization: `Bearer ${token}` },
      }),
    { enabled: !!params?.id }
  );
}

export default usePreviousTrip;
