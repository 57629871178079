import { Icon } from "@hotelengine/atlas-web";
import * as Styled from "./styles";

import { sanitizeHtml } from "@hotel-engine/utilities";

export const boldQuery = (description: string, query: string) => {
  const regex = new RegExp(query, "ig");
  return description?.replace(regex, (match) => `<strong>${match}</strong>`);
};

export default function AutoCompleteSuggestion({ location, query, onClick }) {
  return (
    <div
      role="button"
      tabIndex={0}
      key={`searchResults@${location?.description}`}
      data-testid="dropdown-option"
      style={{ width: "100%" }}
      onClick={onClick}
    >
      <Styled.OptionContainer>
        <Styled.OptionIcon>
          <Icon name={location?.icon} />
        </Styled.OptionIcon>
        <Styled.OptionDescription>
          <span
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(boldQuery(location.description || "", query)),
            }}
          />
        </Styled.OptionDescription>
      </Styled.OptionContainer>
    </div>
  );
}
